<div class="input-group" [ngClass]="inputGroupClass">
	<input type="text"
		#input
		class="form-control"
		[ngClass]="controlClass"
		placeholder="Search Clientele Groups"
		[matAutocomplete]="auto"
		[formControl]="searchControl"
		aria-label="Search Clientele Groups" role="search">
	<div class="input-group-addon" (click)="clear()" [hidden]="!selected">
		<i class="fa fa-times" style="cursor: pointer;"></i>
	</div>
</div>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith">
	<mat-option *ngFor="let cg of clienteleGroups" [value]="cg">
		{{cg.title}} 
		<span class="text-muted">({{ClienteleGroupType[cg.type]}})</span>
	</mat-option>
</mat-autocomplete>
