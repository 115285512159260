import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorService } from '../../widgets/color-picker/color.service';

@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent {
	@Input() myColor: string = '';
	@Output() myColorChange = new EventEmitter<string>();

	public colors: string[];

	constructor(private colorSvc: ColorService){
		this.colors = colorSvc.getAll();
	}

	isSelected(color: string): boolean {
		return this.myColor === color;
	}

	select(color: string) {
		this.myColor = color;
		this.myColorChange.emit(this.myColor);
	}
}
