<div mat-dialog-title>
	Editing County FTE
	<!-- <small class="ml-3">
		(<current-state-fiscal-year dateFormat="longDate" separator=" through "></current-state-fiscal-year>)
	</small> -->
</div>
<mat-dialog-content style="padding:0 !important;">
	<div class="my-3 text-sm">
		<div class="row" style="margin:0 !important;">
			<div class="col-6">
				<h5>Instructions</h5>
				<p>
					New hires: Estimate the amount of time you intend to spend working in each county.
				</p>
				<p>
					For July 1 reporting due date, estimate the amount of time you spent working in each county during the state fiscal year that just ended.
				</p>
				<p>
					Include programmatic and administrative effort into a single county total. If the list of counties below does not accurately reflect your 
					position description, please contact Academic Human Resources.
				</p>
				<div class="alert alert-info">
					<i class="fa fa-info-circle mr-1"></i>
					Your county FTE breakdown may change year to year based on county needs.
				</div>
			</div>
			<div class="col-6">
				<h5>How this information will be used</h5>
				<p>
					The FTE you provide will determine how you are displayed on UC ANR's Programmatic Footprint Maps. Additionally, this data will 
					indicate the portion of your salary that ANR contributes to each county in the county fund source reports. It may be used for other accountability 
					and advocacy efforts. 
				</p>
			</div>
		</div>
	</div>

	<table class="table table-bordered table-flush" [formGroup]="form" style="border-top:1px solid #ddd;">
		<thead>
			<tr>
				<th>County</th>
				<th style="width:140px;"
				[matTooltip]="tooltipSvc.YearlyFTETooltip('working for each county',false)">
					Yearly FTE
					<i class="fa fa-fw fa-info-circle text-muted"></i>
				</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let a of assignments">
				<td>
					{{a.county.name}}

					<i class="fa fa-fw fa-home"
						[hidden]="!a.isHeadquarters"
						title="Headquarters"></i>

					<i class="fa fa-fw fa-user" 
						[hidden]="!a.isDirector"
						title="County Director"></i>
				</td>
				<td>
					<div class="input-group">
						<input type="number" [formControlName]="a.county.id"
							min="0" step="1" max="100" class="form-control">
						<span class="input-group-addon">%</span>
					</div>
				</td>
			</tr>
			<tr>
				<th class="text-right">
					Total
				</th>
				<td>
					<div class="input-group">
						<input type="text" [value]="(sum | number : '1.0-2') + '%'"
						class="form-control" disabled>

						<div class="input-group-addon" [ngSwitch]="validSum">
							<span *ngSwitchCase="true" matTooltip="Great job!">
								<i class="fa fa-fw fa-check text-success"></i>
							</span>
							<span *ngSwitchCase="false" matTooltip="Total FTE must equal 100%">
								<i class="fa fa-fw fa-warning text-warning"></i>
							</span>
						</div>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</mat-dialog-content>
<mat-dialog-actions>
	<button (click)="cancel()" class="btn btn-link" [disabled]="closing">
		Cancel
	</button>
	<button (click)="save()" class="btn btn-primary" [disabled]="closing || !validSum">
		Save
	</button>
</mat-dialog-actions>
