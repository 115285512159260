<div>
	<section-header
		sectionTitle="Academic Program Review"
		navPath="/program-review"
		containerClass="container"
		[navLinks]="[]"></section-header>

	<div id="wrapper" class="container" [ngSwitch]="!!cases">
		<div *ngSwitchCase="false" class="text-muted">
			Loading...
		</div>
		<div class="row" *ngSwitchCase="true">
			<div class="col-md-3">
				<div class="list-group">
					<a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
						*ngFor="let case of cases" [class.active]="!!selectedCase && selectedCase.yearId == case.yearId"
						routerLink="/program-review/{{case.yearId}}">
						<div>
							<h5 class="mb-1">
								{{case.year}}
							</h5>
							{{case.description}}
						</div>
						<i class="fa fa-chevron-right" ></i>
					</a>
				</div>
				<div class="mt-3 text-sm">
					Access to reviews prior to 2017-2018 is provided through the
					<a href="https://ucanr.edu/portal" target="_blank">ANR Portal</a>.
				</div>
			</div>
			<div class="col-md-9" [ngSwitch]="!!selectedCase">
				<div *ngSwitchCase="true">
					<academic-case-detail [case]="selectedCase" [resultsVisible]="resultsVisible"></academic-case-detail>
				</div>
				<div *ngSwitchCase="false">
					<i class="fa fa-fw fa-info-circle"></i>
					Select a review period to view your case for that period.
				</div>
			</div>
		</div>
	</div>
	<anr-footer></anr-footer>
</div>