export interface HasHashString {
	readonly hashString: string;
}

export class SortedSet<T extends HasHashString> implements Iterable<T> {
	protected _hashes: Map<string, T> = new Map<string,T>();
	constructor(protected _items: T[] = []) { 
		_items.forEach(item => 
		{
			this._hashes.set(this.getHashString(item), item);
		});
	}

	public duplicate(): SortedSet<T> {
		return new SortedSet<T>(this.toArray().slice(0));
	}

	private getHashString(item: T): string {
		return item.hashString;
	}

	public map<TOutput>(f: (T) => TOutput): TOutput[] {
		return this._items.map(f);
	}

	[Symbol.iterator]() {
		// This special method allows SortedSet to be used via NgFor and other looping constructs.
		let index = 0;
		let items = this._items;
		return {
			next(): IteratorResult<T> {
				return index < items.length
					? {
						done: false,
						value: items[index++]
					} : {
						done: true,
						value: null
					};
				}
		};
	}

	clear(){
		this._items.splice(0);
		this._hashes.clear();
	}

	resetWith(items: T[]) {
		this.clear();
		this.setItems(items);
	}

	setItems(items: T[] | SortedSet<T>){
		let actualItems: T[];
		if(Array.isArray(items)){
			actualItems = items;
		}
		if(items instanceof SortedSet){
			actualItems = (<SortedSet<T>> items).toArray();
		}
		this._items = actualItems.slice(0);
		actualItems.forEach(item => 
		{
			this._hashes.set(this.getHashString(item), item);
		});
	}

	addRange(items: T[]): void {
		items.forEach(item => 
		{
			this._items.push(item);
			this._hashes.set(this.getHashString(item), item);
		});
	}

	any(): boolean {
		return this._items.length > 0;
	}	

	get count(): number {
		return this._items.length;
	}

	contains(item: T): boolean {
	   	return this._hashes.has(item.hashString);
	}

	containsExactly(items: T[]): boolean {
	   	return this._hashes.size === items.length
			&& items.every(item => this._hashes.has(item.hashString));
	}

	toArray(): T[] {
		return this._items.slice(0);
	}

	add(item: T): boolean {
		let hashString = this.getHashString(item);
		if(this._hashes.has(hashString)){
			return false;
		}
		this._hashes.set(hashString, item);
		this._items.push(item);
		return true;
	}

	remove(item: T): boolean {
		let hashString = this.getHashString(item);
		let storedItem = this._hashes.get(hashString);
		this._hashes.delete(hashString);
		let index = this._items.indexOf(storedItem);
		if(index === -1){
			return false;
		}
		this._items.splice(index, 1);
		return true;
	}

	equals(other: SortedSet<T>): boolean {
		let keys = Array.from(this._hashes.keys());
		let otherKeys = Array.from(other._hashes.keys());
		if(keys.length !== otherKeys.length){
			return false;
		}
		for(var k in keys){
			if(!other._hashes.has(k)){
				return false;
			}
		}
		for(var k in otherKeys){
			if(!this._hashes.has(k)){
				return false;
			}
		}
		return true;
	}
}
