<div class="input-group" [ngClass]="inputGroupClass">
	<input type="text"
		#input
		class="form-control"
		[ngClass]="controlClass"
		placeholder="Search Themes"
		[auto-complete]="auto"
		[formControl]="searchControl"
		[displayWith]="displayWith"
		aria-label="Search Themes" role="search">
	<div class="input-group-addon" (click)="clear()" [hidden]="!selectedTheme">
		<i class="fa fa-times" style="cursor: pointer;"></i>
	</div>
</div>

<auto-complete #auto="auto-complete">
	<auto-complete-option *ngFor="let t of themes" [value]="t">
		<dot [color]="t.color" class="mr-1"></dot>
		{{t.name}}
	</auto-complete-option>
</auto-complete>