import { Component, OnInit, Output } from '@angular/core';
import { SortedSet, User, UserService } from '../../../domain';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'admin-proxy',
  templateUrl: './spoof-user.component.html',
  styleUrls: ['./spoof-user.component.scss']
})
export class SpoofUserComponent implements OnInit {
  message: string;
  usersControl: FormControl;
  @Output() enableNonANRCollaborator: boolean = false;
  constructor(private userService: UserService,
    private readonly snackbar: MatSnackBar) { 
      this.usersControl = new FormControl(new SortedSet<User>());
    }

  ngOnInit(): void {
  }
  Spoof(){
    this.message =null;
    let usreId = this.usersControl.value.toArray()[0]?.id;
    this.userService.spoofUser(usreId).then(result =>{
            this.snackbar.open(result);
            this.message = result;
            this.usersControl.value.clear();
          },
         error=> {
           this.message = error?.status=== 404 || error?.status=== 401 ? error?.error : null;
            this.snackbar.open('Unable to proxy: ' + error?.message);
          });
  }
}
