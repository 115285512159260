import { Pipe, PipeTransform } from '@angular/core';
import { TagTypeName, TagTypeNamePlural } from '../domain';

@Pipe({
	name: 'tagTypeName'
})
export class TagTypeNamePipe implements PipeTransform {
	transform(value: any, args?: any): any {
		return TagTypeName(value);
	}
}

@Pipe({
	name: 'tagTypeNamePlural'
})
export class TagTypeNamePluralPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		return TagTypeNamePlural(value);
	}
}
