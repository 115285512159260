import { THEMES } from './THEMES';
import { PROJECTS } from './PROJECTS';
import { Theme, Project, ActivityParent, ThemeActivityParent, ProjectActivityParent } from '../../domain';

export const THEME_ACTIVITY_PARENTS: ActivityParent[] = 
	THEMES.map(t => new ThemeActivityParent(t.id, t.name));

export const PROJECT_ACTIVITY_PARENTS: ActivityParent[] = 
	PROJECTS.map(p => new ProjectActivityParent(p.id, p.name, p.programArea.name));

export const ACTIVITY_PARENTS: ActivityParent[] =
	Array.prototype.concat.call(
		[],
		THEME_ACTIVITY_PARENTS,
		PROJECT_ACTIVITY_PARENTS);
