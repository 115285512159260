import {
	AfterContentInit,
	ContentChildren,
	QueryList,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
   	Component,
   	ElementRef,
   	EventEmitter,
   	HostListener,
   	Input,
	OnChanges,
   	Output,
} from '@angular/core';
import { AutoCompleteOptionComponent } from './auto-complete-option.component';
import { CircularList } from './circular-list';
import { Observable } from 'rxjs';


@Component({
	selector: 'auto-complete',
	templateUrl: './auto-complete.component.html',
	styleUrls: ['./auto-complete.component.scss'],
	exportAs: 'auto-complete',
})
export class AutoCompleteComponent implements OnChanges, AfterContentInit {

	menuOpen: boolean = false;
	@ContentChildren(AutoCompleteOptionComponent)
	options: QueryList<AutoCompleteOptionComponent>;
	optionsManager: CircularList<AutoCompleteOptionComponent>;
	@ViewChild('menu') private _menu;

	constructor(private _element: ElementRef) { }

	public adjustPositionAccordingToWindow(){
		let m = this._menu.nativeElement;
		let cl = m.classList;
		let w = window;
		let msg = `menu.bottom: ${m.getBoundingClientRect().bottom}, w.innerHeight:${w.innerHeight} -> `;
		if(m.getBoundingClientRect().bottom > w.innerHeight){
			msg += 'open up';
			cl.remove('open-down');
			cl.add('open-up');
		} else {
			msg += 'open down';
			cl.remove('open-up');
			cl.add('open-down');
		}
		//console.log(msg);
	}

	public scrollTo(o: AutoCompleteOptionComponent){
		let optionTop = o._element.nativeElement.offsetTop;
		let optionBottom = optionTop + o._element.nativeElement.clientHeight;
		let menuScrollBottom = this._menu.nativeElement.scrollTop + this._menu.nativeElement.clientHeight;
		// We only want to scroll if (some part of) the active option is beyond the limit of our viewport.
		if(optionBottom > menuScrollBottom){
			this._menu.nativeElement.scrollTop += (optionBottom - menuScrollBottom);
		} else if(optionTop < this._menu.nativeElement.scrollTop){
			this._menu.nativeElement.scrollTop = optionTop;
		}
	}

	@HostListener('keydown', ['$event'])
	keyDown(e){
		e.preventDefault();
		//console.log(e);
	}

	public ngOnChanges(){
	}

	public ngAfterContentInit(){
		this.optionsManager = new CircularList<AutoCompleteOptionComponent>(this.options);
	}
}
