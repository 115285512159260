import { Component, OnInit, Inject  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AHRService, PositionFacts, ProgressionTable, Option, ProgressionTableStep } from '../../../domain';
import { FormBuilder, FormGroup } from '@angular/forms';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { PositionClickEvent } from '../progression-table/progression-table.component';
import { ConsoleService } from "../../../widgets/console.service";
import { TermId, TitleRankStepAndTermId } from '../../../domain';
import { TermClickEvent } from '../term-table/term-table.component';

@Component({
  selector: 'progression-table-workshop-modal',
  templateUrl: './progression-table-workshop-modal.component.html',
  styleUrls: ['./progression-table-workshop-modal.component.scss']
})
export class ProgressionTableWorkshopModalComponent implements OnInit {
	titles;
	table: Option<ProgressionTable> = Option.create<ProgressionTable>();
	currentPosition: TitleRankStepAndTermId;
	selectedStep: ProgressionTableStep;
	selectedTerm: TermId;
	positionClick(e: PositionClickEvent){
		this.selectedStep = e.step;
		let termId = !!this.currentPosition ? this.currentPosition.termId : TermId.Term1;
		if(e.step.determination == 'Regression') {
			termId = this.previousTerm(termId);
		} else {
			let hasTermChanged = this.currentPosition.termId != this.selectedTerm
			termId = this.nextTerm(termId, hasTermChanged);
		}
		this.currentPosition = new TitleRankStepAndTermId(e.table.id, e.rank.id, e.step.id, termId);
		if(e.step.determination == 'Next Step'){
			this.selectedStep = new ProgressionTableStep(
				this.currentPosition.stepId + 1,
        		"Next Step",
        		e.step.relationships,
        		e.step.durationInMonths);
			this.currentPosition = new TitleRankStepAndTermId(
				e.table.id,
				e.rank.id,
				this.currentPosition.stepId + 1,
				termId);
			this.console.log("Next Position: ", this.currentPosition);
		}
		this.form.controls['position'].setValue(e.table.id + '/' + e.rank.id + '/' + e.step.id);
	}
	termClick(e: TermClickEvent){
		this.selectedTerm = e.termId;
	}
	nextTerm(term: TermId, termChanged: boolean) {
		if(!termChanged){
			if(term == TermId.Term1) return TermId.Term2;
			else if(term == TermId.Term2) return TermId.Term3;
			else if(term == TermId.Term3) return TermId.Term3;
			else if(term == TermId.IndefiniteStatus) return TermId.IndefiniteStatus;
			return TermId.Term1
		}
		return term;
	}
	previousTerm(term: TermId) {
		if(term == TermId.Term3) return TermId.Term2;
		else if(term == TermId.Term2) return TermId.Term1;
		else if(term == TermId.Term1) return TermId.Term1;
		else if(term == TermId.IndefiniteStatus) return TermId.IndefiniteStatus;
		return TermId.Term1
	}
	form: FormGroup;

	public static open(matDialog: MatDialog): MatDialogRef<ProgressionTableWorkshopModalComponent>{
		return matDialog.open(ProgressionTableWorkshopModalComponent, {
			width: '1250px',
			disableClose: true,
			data: { },
			hasBackdrop: true
		});
	}

	constructor(
		public dialogRef: MatDialogRef<ProgressionTableWorkshopModalComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private svc: AHRService,
		fb: FormBuilder,
		private readonly console: ConsoleService
	){ 
		this.form = fb.group({
			position: '1/1/1',
			titleId:1,
			rankId:1,
			stepId:1,
			monthsServedInCurrentTitle: 13,
			monthsServedInCurrentRank: 13,
			monthsServedInCurrentStep: 13
		});

		this.selectedTerm = null;

		this.titles = this.svc.getAllTitlesRanksAndSteps();

		this.form.valueChanges
		.pipe(debounce(() => interval(50)))
		.subscribe(_ => this.getProgressionTable());
	}

	getProgressionTable(){
		let fv = this.form.value;
		let p = fv.position.split('/');
		let facts = new PositionFacts(
			p[0], p[1], p[2],
			fv.monthsServedInCurrentTitle,
			fv.monthsServedInCurrentRank,
			fv.monthsServedInCurrentStep
			);
		this.svc.getProgressionTable(facts).then(table => this.table = table);
		let termId = !!this.currentPosition ? this.currentPosition.termId : TermId.Term1;
		this.currentPosition = new TitleRankStepAndTermId(p[0], p[1], p[2], termId);
	}

	clearProgressionTable(){
		this.table = Option.create();
	}

	ngOnInit() {
		this.getProgressionTable();
	}
}