export class LandGrantInstitution
{
	constructor(
		public readonly id: number,
		public readonly stateId: string,
		public readonly name: string,
		public readonly institutionType) { }

	public static fromJson(o: any): LandGrantInstitution {
		return new LandGrantInstitution(
			o.id,
			o.stateId,
			o.name,
			o.institutionType);
	}
}
