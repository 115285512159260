import { Component, OnInit, Input } from '@angular/core';
import { Project, ProjectProperty } from '../../domain';
import { TooltipService } from '../tooltip.service';

@Component({
  selector: 'project-invalid-reason-icon',
  templateUrl: './project-invalid-reason-icon.component.html',
  styleUrls: ['./project-invalid-reason-icon.component.scss']
})
export class ProjectInvalidReasonIconComponent implements OnInit {
	public StaticText: any;

	@Input() project: Project;
	@Input() property: ProjectProperty;
	invalid: boolean = false;
	messages: Map<ProjectProperty,string>;

	constructor(
		public tooltipSvc: TooltipService
		) { 
			this.StaticText = tooltipSvc.StaticText;
		}

	ngOnInit() {
		this.messages = new Map<ProjectProperty,string>([]);

		if(!this.project.theme.hasValue()) {
			this.messages.set(ProjectProperty.Theme, this.StaticText.ProjectThemeRequiredForDossierTooltip);
		}
		if(this.project.role == null || this.project.role.length == 0) {
			this.messages.set(ProjectProperty.Role, this.StaticText.ProjectRoleRequiredForDossierTooltip);
		}
		this.invalid = this.messages.size > 0;
	}

	renderAllMessages() {
		let messages = [];
		let c = 1;
		this.messages.forEach(message => { 
			messages.push(c.toString() + '. ' + message);
			c++;
		});
		return messages.join("\n");
	}

	propertyInvalid(property: ProjectProperty) {
		return this.messages.has(property);
	}

	renderMessageForProperty(property: ProjectProperty){
		return this.messages.get(property);
	}
}
