import { Component, Input, OnChanges } from '@angular/core';
import { SortIcon } from '../column-sort';

@Component({
    selector: 'sort-icon',
    templateUrl: './sort-icon.component.html',
    styleUrls: ['./sort-icon.component.scss']
})
export class SortIconComponent implements OnChanges {
    @Input() direction: string = '';
	@Input() icon: SortIcon = SortIcon.Default;
    iconClass: string = '';

    ngOnChanges() {
        this.iconClass = this.iconClasses.get(this.icon)[this.direction];
    }

    private iconClasses: Map<SortIcon, any> = new Map([
		[SortIcon.Default, {
			"asc" : "fa fa-fw fa-long-arrow-up",
			"desc": "fa fa-fw fa-long-arrow-down"
		}],
		[SortIcon.Alpha, {
			"asc" : "fa fa-fw fa-sort-alpha-asc",
			"desc": "fa fa-fw fa-sort-alpha-desc"
		}],
		[SortIcon.Numeric, {
			"asc" : "fa fa-fw fa-sort-numeric-asc",
			"desc": "fa fa-fw fa-sort-numeric-desc"
		}],
		[SortIcon.Amount, {
			"asc" : "fa fa-fw fa-sort-amount-asc",
			"desc": "fa fa-fw fa-sort-amount-desc"
		}]
	]);
}
