import { Option } from './option';

export class Location {
	constructor(
		public readonly type: string,
		public readonly id: number,
		public readonly name: string,
		public readonly parentLocationName: Option<string>){ }

	public static fromJson(o): Location {
		return new Location(
			o.type,
			o.id,
			o.name,
			Option.fromJson<string>(o.parentLocationName, str => str));
	}
}
