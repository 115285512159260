<div>
	<hr class="hr-dashed">
	<div class="{{formGroupClass}}">
		<label class="{{labelClass}}">
			Clientele Group
		</label>
		<div class="{{formControlClass}}" *ngIf="hasClienteleGroup(originalActivity)">
			{{originalActivity.clientele.groupName}}
		</div>
		<div class="{{formControlClass}}" *ngIf="!hasClienteleGroup(originalActivity)">
			No Group Selected
		</div>
	</div>
</div>

<div class="{{formGroupClass}}" *ngIf="hasClienteleGroup(originalActivity)">

	<label class="{{labelClass}}">
		Demographic Groups
	</label>

	

	<div class="{{formControlClass}}">
		<table class="table table-bordered table-condensed">

			<ng-container *ngFor="let type of applicableDemographicTypes(originalActivity.clientele.groupTypeId)">
				<thead>
					<tr>
						<th colspan="2" class="text-sm"  [matTooltip]="typeToolTip(type.label)">
							{{type.label}} ({{originalActivity.clienteleContactCount}})
						</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let d of type.demographics">
						<td>{{originalActivity.clientele.contacts.get(d.id)}}</td>
						<td [matTooltip]="d.description">{{d.label}}</td>
					</tr>
				</tbody>
			</ng-container>
			
		</table>
	</div>
</div>
