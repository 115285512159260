import { Theme } from './theme';
import { Project } from './project';

export enum ActivityParentType {
    Project = 10,
    Theme = 20
}

export class ActivityParentIdDto {
	constructor(
		public readonly id: number,
		public readonly type: ActivityParentType){ }
}

export abstract class ActivityParent {
	public readonly hashString: string;
	public readonly fullId: ActivityParentIdDto;
    protected constructor(
		public readonly type: ActivityParentType, 
        public readonly id: number,
        public readonly name: string,
        public readonly programArea: string)
	{
		this.hashString = ActivityParentType[type] + '-' + id;
		this.fullId = new ActivityParentIdDto(this.id, this.type);
	}

	public static fromProject(p: Project): ActivityParent {
		return new ProjectActivityParent(p.id, p.name, p.programArea.name);
	}

	public static fromTheme(t: Theme): ActivityParent {
		return new ThemeActivityParent(t.id, t.name);
	}

	public static fromJson(o: any): ActivityParent {
		switch(<ActivityParentType>o.type){
			case ActivityParentType.Theme: 
				return new ThemeActivityParent(
					<number> o.id,
					<string> o.name);
			case ActivityParentType.Project: 
				return new ProjectActivityParent(
					<number> o.id,
					<string> o.name,
					<string> o.programArea);
		}
	}
}

export class ThemeActivityParent extends ActivityParent {
    constructor(
		id: number,
		name: string)
	{
	   	super(ActivityParentType.Theme, id, name, '');
	}

	public toTheme(): Theme {
		return new Theme(this.id, this.name);
	}
}

export class ProjectActivityParent extends ActivityParent {
    constructor(
		id: number,
		name: string,
		programArea: string)
	{
		super(ActivityParentType.Project, id, name, programArea);
	}
}
