<span (click)="toggleMenu()">
	<i class="fa fa-filter" aria-hidden="true"></i>
</span>
<div class="menu-container" [hidden]="!menuOpen">
	<div class="menu">
		<div class="caret"></div>
		<div class="card">
			<div class="card-block">
				<ng-content></ng-content>
			</div>
		</div>
	</div>
</div>
