import { OperationsService as SVC, Result } from '../domain';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../app-settings';
import { Injectable } from '@angular/core';

@Injectable()
export class OperationsService implements SVC {

    constructor (
        private http: HttpClient, 
        private appSettings: AppSettings
    )
    {
    }

    public executeCommand(caseId: string, cmd: Object): Promise<Result<null,string>>{
        return this.http.post(this.appSettings.APIURL + '/program-review/case/' + caseId + '/execute', cmd)
        .toPromise()
        .then(r => Result.fromJsonFSharp(r, _ => null, msg => msg));
    }

    public resetReadModels(): void 
    {
        this.http.get(this.appSettings.APIURL + '/program-review/reset_read_models')
        .subscribe(
            msg => alert(msg),
            error => {
                alert('error! See console for details');
                console.error(error);
            });
    }

    public tryMovePacket(academicId: string): void
    {
        this.http.put(this.appSettings.APIURL + '/program-review/case/' + academicId + '/wfa_case/try_advance', null)
        .subscribe(
            msg => alert(msg),
            error => {
                alert('error! See console for details');
                console.error(error);
            });
    }
    public downloadUserLoginReportExcel() : void {
        
        this.http.get(this.appSettings.APIURL + '/reports/userLogin_Report', {responseType: 'blob'})
        .subscribe(
            blob => {
                this.downloadViaDomClick(window.URL.createObjectURL(blob), `UserLoginReport.xlsx`);
            },
            err => {
                let r = new FileReader();
                r.onloadend = _ => alert('Unable to download login report: ' + r.result);
                r.readAsText(err.error);
            }
        );
    }
    public downloadUsageReport(): void
    {
        let d = new Date();
        let periodStart = '2018-03-01';
        let periodEnd = `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()+1}`;
        let periodEndForName = `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}`;
        let qs = `periodStart=${periodStart}&periodEnd=${periodEnd}`;
        this.http.get(this.appSettings.APIURL + '/operations/usage/excel_export?'+qs, {responseType: 'blob'})
            .subscribe(
                blob => {
                    this.downloadViaDomClick(window.URL.createObjectURL(blob), `UsageReport_${periodStart}_${periodEndForName}.xlsx`);
                },
                err => {
                    //The PB back end places an error message intended for the user in the body of the error response.  
                    //Reference: https://stackoverflow.com/questions/48500822/how-to-handle-error-for-response-type-blob-in-httprequest
                    let r = new FileReader();
                    r.onloadend = _ => alert('Unable to download usage report: ' + r.result);
                    r.readAsText(err.error);
                });
	}
    public downloadViaDomClick(url: string, filename: string){
        let anchor = window.document.createElement('a');
        anchor.href = url;
        anchor.download = filename;

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }
}