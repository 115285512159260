import { Component, OnInit } from '@angular/core';
import { Theme as DossierTheme, Dossier, DossierIdType, SelectThemeItem } from '../../../domain/types/dossier';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
	Option,
	Activity,
   	DossierService,
	TagType,
	ProjectService,
	Project,
	Theme,
	ProjectActivityParent
} from '../../../domain';

import { ConsoleService } from '../../../widgets/console.service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ActivityModalService } from '../../activity-modal.service';
import { ProjectModalService } from '../../project-modal.service';
import { ConfirmModalService } from '../../../widgets/confirm-modal/confirm-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'themes',
  templateUrl: './themes.component.html',
  styleUrls: ['./themes.component.scss']
})
export class ThemesComponent implements OnInit {
	TagType = TagType;
	themes: DossierTheme[] = undefined
	expandAllObserver: Observable<boolean>;
	trimObserver: Observable<boolean>;
	trim = false;
	saving = false;
	AllExpanded = true;
	themeSituations = {};
	themeNarratives = {};
	dossier: Dossier;
	DossierIdType = DossierIdType;

	constructor(
		private svc: DossierService,
		private snackBar: MatSnackBar,
		private console: ConsoleService,
		private readonly matDialog: MatDialog,
		private readonly activityModals: ActivityModalService,
		private projectModalService: ProjectModalService,
		private readonly confirm: ConfirmModalService,
		private projectService: ProjectService,
		private router: Router,
	){ }

	activityClick(activity: Activity, clickEvent: MouseEvent){
		this.activityModals.openActivityInModalById(this.matDialog, activity.id);
		clickEvent.preventDefault();
		clickEvent.stopPropagation();
	}

	saveSituation(t: DossierTheme, situation: string): () => Promise<boolean> {
		return () => {
			this.console.log('saving theme situation for id = ' + t.id);
			return new Promise((resolve,reject) => 
				this.svc.situateTheme(t.id, situation).then(result => 
					result.matchDo(
						_ => {
							this.snackBar.open('Changes saved', null, {duration:3000});
							t.situation = situation;
							resolve(true)
						},
						msg => {
							this.snackBar.open('Unable to save your changes:' + msg, null, {duration:10000});
							resolve(false);
						})));
		};
	}

	saveNarrative(t: DossierTheme, narrative: string): () => Promise<boolean> {
		return () => {
			this.console.log('saving theme narrative for id = ' + t.id);
			return new Promise((resolve,reject) => 
				this.svc.narrateTheme(t.id, narrative).then(result => 
					result.matchDo(
						_ => {
							this.snackBar.open('Changes saved', null, {duration:3000});
							t.narrative = narrative;
							resolve(true)
						},
						msg => {
							this.snackBar.open('Unable to save your changes:' + msg, null, {duration:10000});
							resolve(false);
						})));
		};
	}

	expanded = {
		themeProjects: {},
		themeActivities: {},
		themeSituation: {},
		themeNarrative: {},
		projectActivities: {}
	}

	toggleExpanded(key, id){
		this.expanded[key][id] = !this.expanded[key][id];
	}

	toggleIcon(key, id){
		return this.expanded[key][id] 
			? 'fa-chevron-down' 
			: 'fa-chevron-up';
	}

	expandAll(expanded: boolean){
		this.AllExpanded = expanded;
		for(var k in this.expanded){
			for(var id in this.expanded[k]){
				this.expanded[k][id] = expanded;
			}
		}
	}

	isAllSelected(){
		let selected = true;
		(this.themes || []).forEach(t => {
			selected = selected && t.includeInDossier;
			t.projects.forEach(p => { 
				selected = selected && p.includeInDossier;
				p.activities.forEach(a => selected = selected && a.includeInDossier);
			});
		});
		return selected;
	}

	editProject(id: number): void {
		this.projectService.getProject(id)
		.subscribe(project => {
			this.projectService.editProjectInModal(this.matDialog, project);		
		}).unsubscribe();
		
	}

	newActivityForProject(p: Project){
		this.activityModals.openActivityMenu(
			this.matDialog,
			Option.create(new ProjectActivityParent(p.id, p.name, ''))).then(a => {
				this.console.log('new activity:', a);
			});
	}

	openProjectModal(dossierTheme: DossierTheme) {
		this.projectModalService.openNew(
			new Theme(dossierTheme.id, dossierTheme.name)
		)
		.afterClosed()
		.subscribe(result => {
			this.console.log('new project result: ', result);
			if(isNaN(result)){
				return;
			}
			this.svc.refreshDossier();
			this.confirm.open('Is this a multi-state project?')
			.pipe(filter(isMultiStateProject => isMultiStateProject))
			.subscribe(_ => {
				this.router.navigate(['/academic/fte/project/' + result]);
			});
		});
	}

	saveSingleChange(isSelected: boolean, id: number, idType: DossierIdType){
		this.saving = true;
		this.svc.selectThemeItem(new SelectThemeItem(isSelected, id, idType))
			.then(result => result.matchDo(
				_ => {
					this.snackBar.open('Selection saved', null, {duration: 5000});
					this.saving = false;
				},
				errorMsg => {
					this.snackBar.open('Selection NOT saved: ' + errorMsg, null, {duration: 10000});
					this.saving = false;
				}
			))
			.catch(error => {
				this.snackBar.open(error.statusText, null, {duration: 10000});
				this.saving = false;
			});
	}

	save(){
		//this.console.log("save triggered");
		this.saving = true;
		let data = this.dossier.dto(this.trim);
		this.svc.selectThemeItems(data)
			.then(result => result.matchDo(
				_ => {
					this.snackBar.open('Selections saved', null, {duration: 5000});
					this.saving = false;
				},
				errorMsg => {
					this.snackBar.open('Selections NOT saved: ' + errorMsg, null, {duration: 10000});
					this.saving = false;
				}))
			.catch(error => {
				this.snackBar.open(error.statusText, null, {duration: 10000});
				this.saving = false;
			});
	}

	ngOnInit() {
		this.expandAllObserver = this.svc.getExpand();
		this.expandAllObserver.subscribe(e => this.expandAll(e));

		this.trimObserver = this.svc.getTrim();
		this.trimObserver.subscribe(trim => this.trim = trim);

		this.svc.getDossier().subscribe(dossier => {
			this.dossier = dossier;
			if(!!this.dossier && !!this.dossier.Themes){
				this.themes = this.dossier.Themes;
				this.dossier.Themes.forEach(t => {
					this.themeSituations[t.id] = t.situation;
					this.themeNarratives[t.id] = t.narrative;
					this.expanded.themeProjects[t.id] = this.AllExpanded;
					this.expanded.themeActivities[t.id] = this.AllExpanded;
					this.expanded.themeNarrative[t.id] = this.AllExpanded;
					this.expanded.themeSituation[t.id] = this.AllExpanded;
					t.projects.forEach(p => {
						this.expanded.projectActivities[p.id] = this.AllExpanded;
					});
				});
			}
		});
	}
}
