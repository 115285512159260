export class Page<T> {
    constructor(
        public readonly totalPageCount: number,
        public readonly totalItemCount: number,
        public readonly pageNumber: number,
        public readonly pageItemCount: number,
        public readonly items: T[]
    ){}

    public static fromJson<T>(o: any, itemFromJson: (o:any) => T): Page<T> {
        return new Page(
            o.totalPageCount,
            o.totalItemCount,
            o.pageNumber,
            o.pageItemCount,
            (<any[]>o.items).map(itemFromJson));
    }
}