import { Component, ViewChildren, ViewChild, QueryList, ElementRef } from '@angular/core'; import { NavigationEnd,  Router, RouterState } from '@angular/router';
import { Option, AuthenticationService, CurrentAcademicUser, HumanResources, ActivityParent, ProjectService, AuthenticatedIdentity, Academic } from '../../domain';
import { ConsoleService } from '../../widgets/console.service';
import { ActivitiesComponent } from '../activities/activities.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivityModalService } from '../activity-modal.service';

@Component({
	selector: 'app-academic',
	templateUrl: './academic.component.html',
	styleUrls: ['./academic.component.scss']
})
export class AcademicComponent {
	@ViewChildren('NavigationLink') navigationLinks: QueryList<ElementRef>;
	@ViewChild('routeOutlet') routeOutlet;
	@ViewChild(ActivitiesComponent) activitiesComponent: ActivitiesComponent;
	private activeRoute: RouterState | null = null;
	navLinks = [];

	constructor(
		authSvc: AuthenticationService, 
		private router: Router,
		private console: ConsoleService,
		private matDialog: MatDialog,
		private activityModals: ActivityModalService,
		private projectService: ProjectService,
	) {
		authSvc.currentIdentity.subscribe(identity => {
			this.navLinks = [
				{
					url: 'themes',
					label: 'Themes',
					iconClass: 'fa fa-file-text-o',
					requiresHr: [HumanResources.ANR, HumanResources.Campus],
					requiresExtension: false
				},
				{
					url: 'projects',
					label: 'Projects',
					iconClass: 'fa fa-folder',
					requiresHr: [HumanResources.ANR, HumanResources.Campus],
					requiresExtension: false
				},
				{
					url: 'activities',
					label: 'Activities',
					iconClass: 'fa fa-list',
					requiresHr: [HumanResources.ANR],
					requiresExtension: false
				},
				{
					url: 'clientele',
					label: 'Clientele',
					iconClass: 'fa fa-users',
					requiresHr: [HumanResources.ANR],
					requiresExtension: true
				},
				{ 
					url: 'fte',
					label: 'F.T.E.',
					iconClass: 'fa fa-clock-o',
					requiresHr: [HumanResources.ANR, HumanResources.Campus],
					requiresExtension: false
				},
				{
					url: 'review-and-compile',
					label: 'Review and Compile',
					iconClass: 'fa fa-files-o',
					requiresHr: [HumanResources.ANR],
					requiresExtension: false
				},
				{
					url: 'explore',
					label: 'Explore',
					iconClass: 'fa fa-search',
					requiresHr: [HumanResources.ANR, HumanResources.Campus],
					requiresExtension: false
				},
				{
					url: 'SPECIAL___ACADEMIC_REPORT_SUBMISSION',
					label: '',
					iconClass: '',
					requiresHr: [HumanResources.Campus],
					requiresExtension: false
				}
			];
			identity.doWithValue((i: AuthenticatedIdentity) => 
				i.user.roles.academic.doWithValue((a: Academic) =>
				{
					this.navLinks = this.navLinks.filter(l => l.requiresHr.indexOf( a.meritAndPromotionHr) != -1);
				}));

		});
		router.events.subscribe(e => {
			if(NavigationEnd.prototype.isPrototypeOf(e)){
				this.activeRoute = router.routerState;
			}
		});
	}

	urlIsActive(url: string){
		if(!this.activeRoute){
			return false;
		}
		return this.activeRoute.snapshot.url.indexOf('/academic/' + url) == 0;
	}

	newActivity = () => {
		this.router.navigateByUrl('/academic/activities');
		this.activityModals.openActivityMenu(this.matDialog, Option.create<ActivityParent>());
	}

	newProject = () => {
		this.router.navigateByUrl('/academic/projects');
		this.projectService.newProjectModal(this.matDialog);
	}

	newClienteleGroup = () => 
		this.console.log("New Clientele Group");

	newTheme = () =>
		this.console.log("New Theme");

	ngAfterContentChecked() {
		this.tryFocusActiveLink();
	}

	private activeLinkWasFocused = false;

	private tryFocusActiveLink(){
		if(this.activeLinkWasFocused || !this.activeRoute || !this.navigationLinks || !this.navigationLinks.first){
			return;
		}
		let activeLink = this.navigationLinks.find(link => {
			let href = link.nativeElement.href.replace(/https?:\/\/[^\/]+/,'');
			return this.activeRoute.snapshot.url.indexOf(href) > -1;
		});
		if(!!activeLink) {
			activeLink.nativeElement.focus();
		} else {
			this.navigationLinks.first.nativeElement.focus();
		}
		this.activeLinkWasFocused = true;
	}
}