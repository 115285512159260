<div mat-dialog-title>Editing Activity</div>
<mat-dialog-content>
	<activity-form-guts 
		[activity]="activity"
		[wideLayout]="wideLayout" 
		[types]="activityTypes"
		(activityChange)="onActivityChange($event)" 
		(formStatusChange)="onStatusChange($event)"></activity-form-guts>
</mat-dialog-content>
<mat-dialog-actions>
	<ng-container [ngSwitch]="wideLayout">
		<ng-container *ngSwitchCase="true">
			<button (click)="archive()" 
				class="btn btn-link text-danger float-left" 
				[title]="StaticText.ArchiveActivity"
				*ngIf="canArchive()">
				<i class="fa fa-fw fa-archive"></i>
				Archive (Delete)
			</button>
		
			<button (click)="cancel()" class="btn btn-link" [disabled]="closing">
				<span [ngSwitch]="hasChanges()">
					<span *ngSwitchCase="true">
						Cancel
					</span>
					<span *ngSwitchCase="false">
						Close
					</span>
				</span>
			</button>
			<button class="btn btn-success" [disabled]="!canCopy()" (click)="copy()">
				Copy
			</button>
			<button class="btn btn-primary" [disabled]="!canSave()" (click)="save()">
				Save Activity
			</button>
		</ng-container>
		<ng-container *ngSwitchCase="false">
			<div class="btn-group" role="group" aria-label="Save, Cancel, Copy, and Archive Buttons">
				<button (click)="archive()" 
					class="btn btn-link text-danger float-left" 
					[title]="StaticText.ArchiveActivity"
					*ngIf="canArchive()">
					<i class="fa fa-fw fa-archive"></i>
					Archive (Delete)
				</button>
			
				<button (click)="cancel()" class="btn btn-link" [disabled]="closing">
					<span [ngSwitch]="hasChanges()">
						<span *ngSwitchCase="true">
							Cancel
						</span>
						<span *ngSwitchCase="false">
							Close
						</span>
					</span>
				</button>
				<button class="btn btn-success" [disabled]="!canCopy()" (click)="copy()">
					Copy
				</button>
				<button class="btn btn-primary" [disabled]="!canSave()" (click)="save()">
					Save Activity
				</button>
			</div>
		</ng-container>
	</ng-container>
	
</mat-dialog-actions>