<span [ngSwitch]="!!max && tags.length > max">
	<span *ngSwitchCase="false">
		<tag *ngFor="let tag of tags" [value]="tag" [keyword]="keyword"></tag>
	</span>
	<span *ngSwitchCase="true">
		<span [ngSwitch]="showAll">
			<span *ngSwitchCase="false">
				<tag *ngFor="let tag of maxTags()" [value]="tag" [keyword]="keyword"></tag>
				<span
				  class="fa fa-eye"
				  (click)="showAll = true"
				  title="Show {{tags.length - max}} more tags"
				  triggers="mouseenter:mouseleave"></span>
			</span>
			<span *ngSwitchCase="true">
				<tag *ngFor="let tag of tags" [value]="tag" [keyword]="keyword"></tag>
				<span 
				  class="fa fa-eye-slash"
				  (click)="showAll = false"
				  title="Hide {{tags.length - max}} of {{tags.length}} tags"
				  triggers="mouseenter:mouseleave"></span>
			</span>
		</span>
	</span>
</span>
