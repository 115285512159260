import { BehaviorSubject, Subject, Observable } from "rxjs";

export class CircularArray<T>{
    private _currentIndex: number = 0;
    private readonly _items: T[];
    private readonly _subject: BehaviorSubject<T>;
    constructor(items: T[])
    {
        if(!items || items.length == 0){
            throw "only a non-empty array is acceptable for use in a CircularArray";
        }
        /* We copy the array so that nobody can mutate it under our feet,
           allowing the above invariant to continue to be enforced. */
        this._items = items.slice(0); 
        this._subject = new BehaviorSubject<T>(items[0]);
        this._currentIndex = 0;
    }

    hasLinearPrev(): boolean {
        return this._currentIndex - 1 >= 0;
    }
    hasLinearNext(): boolean {
        return this._currentIndex + 1 < this._items.length;
    }

    get changes(): Observable<T> {
        return this._subject.asObservable();
    }

    get currentItem(): T {
        return this._items[this._currentIndex];
    }

    reset() {
        this._currentIndex = 0;
    }

    moveTo(item: T): boolean {
        let index = this._items.indexOf(item);
        if(index == -1){
            return false;
        }
        this._currentIndex = index;
        this._notifyObservers();
        return true;
    }

    move(offset: number){
        this._currentIndex += offset;
        this._ensureIndexWithinBounds();
        this._notifyObservers();
    }

    moveNext(){
        this._currentIndex += 1;
        this._ensureIndexWithinBounds();
        this._notifyObservers();
    }
    movePrev(){
        this._currentIndex -= 1;
        this._ensureIndexWithinBounds();
        this._notifyObservers();
    }

    tryMoveToIndex(index: number): boolean {
        if(index==null || index < 0 || index >= this._items.length){
            return false;
        }
        console.log('tryMoveToIndex succeeded');
        this._currentIndex = index;
        this._notifyObservers();
        return true;
    }

    private _notifyObservers(){
        this._subject.next(this._items[this._currentIndex]);
    }

    private _ensureIndexWithinBounds(){
        if (this._currentIndex >= this._items.length)
        {
            this._currentIndex = this._currentIndex % this._items.length;
        }
        if (this._currentIndex < 0)
        {
            let modIndex = this._currentIndex % this._items.length;
            this._currentIndex = modIndex == 0 ? 0 : modIndex + this._items.length;
        }
    }

	[Symbol.iterator]() {
		// This special method allows this class to be used via NgFor and other looping constructs.
		let index = 0;
		let items = this._items;
		return {
			next(): IteratorResult<T> {
				return index < items.length
					? {
						done: false,
						value: items[index++]
					} : {
						done: true,
						value: null
					};
				}
		};
	}
}

window['CircularArray'] = CircularArray;