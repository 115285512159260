import { NgModule } from '@angular/core';
import { MainCoreModule } from './../main-core/main-core.module';
import { AppComponent } from '../main-core/app.component';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthModalSettings, AuthType } from '../main-core/authentication-modal/authentication-modal.component';
import { AuthenticationInterceptor } from './authenticationInterceptor';
import * as DA from '../data-access';
import * as D from '../domain';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { M } from '@angular/cdk/keycodes';

const routes: Routes = [ ];

@NgModule({
	bootstrap: [AppComponent],
	imports: [
		MainCoreModule,
		RouterModule.forRoot(routes, { useHash: false }),
	],
	declarations: [ ],
	providers: [
		{ provide: D.AuthenticationService,         useClass: DA.AuthenticationService },
		{ provide: D.AcademicReportSubmissionService,useClass: DA.AcademicReportSubmissionService },
		{ provide: AuthModalSettings,               useValue: new AuthModalSettings(AuthType.Main) },
		{ provide: D.AppVersionService,             useClass: DA.AppVersionService },
		{ provide: D.ActivityService,               useClass: DA.ActivityService },
		{ provide: D.AHRService,                    useClass: DA.AHRService },
		{ provide: D.ClienteleService,              useClass: DA.ClienteleService },
		{ provide: D.ExploreWhatsHappeningService,  useClass: DA.ExploreWhatsHappeningService },
		{ provide: D.DossierService,                useClass: DA.DossierService },
		{ provide: D.FTEService,                    useClass: DA.FTEService },
		{ provide: D.OperationsService,             useClass: DA.OperationsService },
		{ provide: D.ProjectService,                useClass: DA.ProjectService },
		{ provide: D.TagService,                    useClass: DA.TagService  },
		{ provide: D.UserService,                   useClass: DA.UserService },
		{ provide: D.ProfileService,                useClass: DA.ProfileService },
		{ provide: D.UserPreferenceService,         useClass: DA.UserPreferenceService },
		{ provide: HTTP_INTERCEPTORS,               useClass: AuthenticationInterceptor, multi: true },
		{ provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, 	useValue: {duration: 3000}}
	]
})
export class MainModule { }