<div [ngSwitch]="date.typeId">
    <div *ngSwitchCase="'Single'">
        {{fmt(date.date)}}
    </div>
    <div *ngSwitchCase="'Range'">
        {{fmt(date.startDate)}}
        <div>
            - {{fmt(date.endDate)}}
        </div>
    </div>
    <div *ngSwitchCase="'Ongoing'">
        {{fmt(date.startDate)}}
        <div class="text-sm text-muted">
            (Ongoing)
        </div>
    </div>
</div>