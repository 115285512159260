export class Theme {
    constructor(
        public id: number,
        public name: string
    ) { }

	public static fromJson(o: any): Theme {
		return new Theme(
			<number>o.id,
			<string>o.name);
	}
}
