import { Component, OnInit, Input } from '@angular/core';
import { ICountySelection } from '../../domain';

@Component({
  selector: 'county-selection',
  templateUrl: './county-selection.component.html',
  styleUrls: ['./county-selection.component.scss']
})
export class CountySelectionComponent implements OnInit {
	@Input() countySelection: ICountySelection;
	
	constructor() {}

	ngOnInit() {}

}
