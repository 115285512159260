import { Theme, CreateTheme, SelectThemeItems, Dossier, SelectThemeItem, ThemePublicationType } from '../types/dossier';
import { Result, TagDto } from '../types';
import { Observable } from 'rxjs';

export abstract class DossierService {
	public abstract getThemes(): Promise<Theme[]>;
	public abstract createTheme(cmd: CreateTheme): Promise<Result<number,string>>;
	public abstract renameTheme(themeId: number, name: string): Promise<Result<null,string>>;
	public abstract countThemeActivities(themeId: number, counts: { [key: number]: number}): Promise<Result<null,string>>;
	public abstract situateTheme(themeId: number, situation: string): Promise<Result<null,string>>;
	public abstract narrateTheme(themeId: number, narrative: string): Promise<Result<null,string>>;

	public abstract saveThemePubs(themeId: number, pt: ThemePublicationType, pubs: string): Promise<Result<null,string>>;

	public abstract tagTheme(themeId: number, tags: TagDto[]): Promise<Result<null,string>>;
	public abstract selectThemeItems(cmd: SelectThemeItems): Promise<Result<null,string>>;
	public abstract selectThemeItem(cmd: SelectThemeItem): Promise<Result<null,string>>
	public abstract downloadDoc(): Promise<Result<void,string>>;
	public abstract downloadExcel(): Promise<Result<void,string>>;
	public abstract downloadThemesAndProjectsDoc(): Promise<Result<void,string>>;
	public abstract archive(themeId: number): Promise<Result<null,string>>;
	public abstract unarchive(themeId: number): Promise<Result<null,string>>;
	public abstract getDossier(): Observable<Dossier>;
	public abstract refreshDossier(): void;

	public abstract setExpand(expand: boolean);
	public abstract setTrim(trim: boolean);
	public abstract getExpand(): Observable<boolean>;
	public abstract getTrim(): Observable<boolean>;

	public abstract selectAll(selection: boolean);

	public abstract set dossierPeriod(p: DossierExportPeriod);
	public abstract get dossierPeriod(): DossierExportPeriod;
}

export interface DossierExportPeriodOption {
	readonly label: string;
	readonly value: DossierExportPeriod;
}

export interface DossierExportPeriod {
	readonly type: DossierExportPeriodType;
	readonly startDate?: Date;
	readonly endDate?: Date;
}

export enum DossierExportPeriodType {
	All = "All",
	Only = "Only",
	OnOrAfter = "OnOrAfter",
	OnOrBefore = "OnOrBefore"
}