import { ActivitySubType, ActivityType, ActivitySubTypeId, ActivityTypeId, HumanResources as HR } from '../../domain';
export const ACTIVITY_TYPES: ActivityType[] = [
    {
        "id": ActivityTypeId.Extension,
        "name": "Extension",
        "subTypes": [
            {
                "id": ActivitySubTypeId.MeetingOrganized,
                "name": "Meeting Organized",
                "appliesToHR": [HR.ANR, HR.Campus],
                "nameLabel": "Name / Type",
                "properties": {
                    "Topic": "Required",
                    "Role": "Required",
                    "Location": "Required",
					"AreaOfService":"NA",
					"Parent": "Optional",
                    "TargetedOutreach": "NA",
                    "ClienteleContacts": "Required",
                    "Collaborators": "Optional",
                    "Tags": "Optional",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            },
            {
                "id": ActivitySubTypeId.TrainTheTrainer,
                "name": "Train the Trainer",
                "appliesToHR": [HR.ANR, HR.Campus],
                "nameLabel": "Name / Description",
                "properties": {
                    "Topic": "Required",
                    "Role": "Required",
                    "Location": "Required",
					"AreaOfService":"NA",
					"Parent": "Optional",
                    "TargetedOutreach": "NA",
                    "ClienteleContacts": "Required",
                    "Collaborators": "Optional",
                    "Tags": "Optional",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            },
            {
                "id": ActivitySubTypeId.EducationalPresentation,
                "name": "Educational Presentation at a Meeting",
                "appliesToHR": [HR.ANR, HR.Campus],
                "nameLabel": "Name / Description",
                "properties": {
                    "Topic": "Required",
                    "Role": "Required",
                    "Location": "Required",
					"AreaOfService":"NA",
					"Parent": "Optional",
                    "TargetedOutreach": "NA",
                    "ClienteleContacts": "Required",
                    "Collaborators": "Optional",
                    "Tags": "Optional",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            },
            {
                "id": ActivitySubTypeId.ExternalCollaboration,
                "name": "Ongoing Collaboration with Other Agency or Organization",
                "appliesToHR": [HR.ANR],
                "nameLabel": "Description",
                "properties": {
                    "Topic": "NA",
                    "Role": "Optional",
                    "Location": "Optional",
					"AreaOfService":"NA",
					"Parent": "Optional",
                    "TargetedOutreach": "NA",
                    "ClienteleContacts": "Optional",
                    "Collaborators": "Optional",
                    "Tags": "Optional",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            },
            {
                "id": ActivitySubTypeId.PolicyEngagement,
                "name": "Policy Engagement",
                "appliesToHR": [HR.ANR, HR.Campus],
                "nameLabel": "Description",
                "properties": {
                    "Topic": "NA",
                    "Role": "Optional",
                    "Location": "Optional",
					"AreaOfService":"NA",
					"Parent": "Optional",
                    "TargetedOutreach": "NA",
                    "ClienteleContacts": "Optional",
                    "Collaborators": "Optional",
                    "Tags": "Optional",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            },
            {
                "id": ActivitySubTypeId.OtherExtension,
                "name": "Other Extension Activity",
                "appliesToHR": [HR.ANR],
                "nameLabel": "Name / Description",
                "properties": {
                    "Topic": "NA",
                    "Role": "Optional",
                    "Location": "Optional",
					"AreaOfService":"NA",
					"Parent": "Optional",
                    "TargetedOutreach": "NA",
                    "ClienteleContacts": "Optional",
                    "Collaborators": "Optional",
                    "Tags": "Optional",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            },
            {
                "id": ActivitySubTypeId.IndividualClienteleContacts,
                "name": "Individual Clientele Contacts",
                "appliesToHR": [HR.ANR],
                "nameLabel": "Description",
                "properties": {
                    "Topic": "NA",
                    "Role": "Optional",
                    "Location": "Optional",
					"AreaOfService":"NA",
					"Parent": "Optional",
                    "TargetedOutreach": "NA",
                    "ClienteleContacts": "Required",
                    "Collaborators": "Optional",
                    "Tags": "Optional",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            }
        ]
    },
    {
        "id": ActivityTypeId.Outreach,
        "name": "A.R.E. Effort",
        "subTypes": [
            {
                "id": ActivitySubTypeId.IndividualOutreach,
                "name": "Individual Effort",
                "appliesToHR": [HR.ANR],
                "nameLabel": "Description",
                "properties": {
                    "Topic": "NA",
                    "Role": "NA",
                    "Location": "NA",
					"AreaOfService":"NA",
					"Parent": "Optional",
                    "TargetedOutreach": "Required",
                    "ClienteleContacts": "NA",
                    "Collaborators": "NA",
                    "Tags": "Optional",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            },
            {
                "id": ActivitySubTypeId.MassMediaOutreach,
                "name": "Mass Media Effort",
                "appliesToHR": [HR.ANR],
                "nameLabel": "Description",
                "properties": {
                    "Topic": "NA",
                    "Role": "NA",
                    "Location": "NA",
					"AreaOfService":"NA",
					"Parent": "Optional",
                    "TargetedOutreach": "Required",
                    "ClienteleContacts": "NA",
                    "Collaborators": "NA",
                    "Tags": "Optional",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            },
            {
                "id": ActivitySubTypeId.NewsletterOutreach,
                "name": "Promotional Material Effort",
                "appliesToHR": [HR.ANR],
                "nameLabel": "Description",
                "properties": {
                    "Topic": "NA",
                    "Role": "NA",
                    "Location": "NA",
					"AreaOfService":"NA",
					"Parent": "Optional",
                    "TargetedOutreach": "Required",
                    "ClienteleContacts": "NA",
                    "Collaborators": "NA",
                    "Tags": "Optional",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            },
            {
                "id": ActivitySubTypeId.PersonalLetterOutreach,
                "name": "Personal Letter Effort",
                "appliesToHR": [HR.ANR],
                "nameLabel": "Description",
                "properties": {
                    "Topic": "NA",
                    "Role": "NA",
                    "Location": "NA",
					"AreaOfService":"NA",
					"Parent": "Optional",
                    "TargetedOutreach": "Required",
                    "ClienteleContacts": "NA",
                    "Collaborators": "NA",
                    "Tags": "Optional",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            },
            {
                "id": ActivitySubTypeId.OtherOutreach,
                "name": "Other Effort Activity",
                "appliesToHR": [HR.ANR],
                "nameLabel": "Description",
                "properties": {
                    "Topic": "NA",
                    "Role": "NA",
                    "Location": "NA",
					"AreaOfService":"NA",
					"Parent": "Optional",
                    "TargetedOutreach": "Required",
                    "ClienteleContacts": "NA",
                    "Collaborators": "NA",
                    "Tags": "Optional",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            }
        ]
    },
    {
        "id": ActivityTypeId.ExtensionAndOrOutreach,
        "name": "Extension and/or A.R.E. Effort",
        "subTypes": [
            {
                "id": ActivitySubTypeId.WebsiteOrSocialMedia,
                "name": "Website/Social Media",
                "appliesToHR": [HR.ANR, HR.Campus],
                "nameLabel": "Description",
                "properties": {
                    "Topic": "NA",
                    "Role": "Optional",
                    "Location": "NA",
					"AreaOfService":"NA",
					"Parent": "Optional",
                    "TargetedOutreach": "Optional",
                    "ClienteleContacts": "NA",
                    "Collaborators": "Optional",
                    "Tags": "Optional",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            },
            {
                "id": ActivitySubTypeId.MediaOutletProgramOrInterview,
                "name": "Media Outlet Program/Interview",
                "appliesToHR": [HR.ANR, HR.Campus],
                "nameLabel": "Name of Media or Publication",
                "properties": {
                    "Topic": "NA",
                    "Role": "Optional",
                    "Location": "NA",
					"AreaOfService":"NA",
					"Parent": "Optional",
                    "TargetedOutreach": "Optional",
                    "ClienteleContacts": "NA",
                    "Collaborators": "Optional",
                    "Tags": "Optional",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            }
        ]
    },
    {
        "id": ActivityTypeId.AppliedResearchAndCreative,
        "name": "Applied Research & Creative",
        "subTypes": [
            {
                "id": ActivitySubTypeId.Research,
                "name": "Research",
                "appliesToHR": [HR.ANR, HR.Campus],
                "nameLabel": "Name / Description",
                "properties": {
                    "Topic": "NA",
                    "Role": "Optional",
                    "Location": "Optional",
					"AreaOfService":"NA",
					"Parent": "Optional",
                    "TargetedOutreach": "NA",
                    "ClienteleContacts": "NA",
                    "Collaborators": "Optional",
                    "Tags": "Optional",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            },
            {
                "id": ActivitySubTypeId.DigitalMedia,
                "name": "Digital Media",
                "appliesToHR": [HR.ANR, HR.Campus],
                "nameLabel": "Name / Description",
                "properties": {
                    "Topic": "NA",
                    "Role": "Optional",
                    "Location": "NA",
					"AreaOfService":"NA",
					"Parent": "Optional",
                    "TargetedOutreach": "NA",
                    "ClienteleContacts": "NA",
                    "Collaborators": "Optional",
                    "Tags": "Optional",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            },
            {
                "id": ActivitySubTypeId.EducationalMaterials,
                "name": "Educational Materials",
                "appliesToHR": [HR.ANR],
                "nameLabel": "Name / Description",
                "properties": {
                    "Topic": "NA",
                    "Role": "Optional",
                    "Location": "NA",
					"AreaOfService":"NA",
					"Parent": "Optional",
                    "TargetedOutreach": "NA",
                    "ClienteleContacts": "NA",
                    "Collaborators": "Optional",
                    "Tags": "Optional",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            },
            {
                "id": ActivitySubTypeId.OtherAppliedResearchOrCreative,
                "name": "Other Applied Research / Creative Activity",
                "appliesToHR": [HR.ANR],
                "nameLabel": "Name / Description",
                "properties": {
                    "Topic": "NA",
                    "Role": "Optional",
                    "Location": "Optional",
					"AreaOfService":"NA",
					"Parent": "Optional",
                    "TargetedOutreach": "NA",
                    "ClienteleContacts": "NA",
                    "Collaborators": "Optional",
                    "Tags": "Optional",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            }
        ]
    },
    {
        "id": ActivityTypeId.ProfessionalCompetence,
        "name": "Professional Competence",
        "subTypes": [
            {
                "id": ActivitySubTypeId.ProfessionalDevelopmentAndTraining,
                "name": "Professional Development and Training",
                "appliesToHR": [HR.ANR],
                "nameLabel": "Name / Description",
                "properties": {
                    "Topic": "NA",
                    "Role": "NA",
                    "Location": "Required",
					"AreaOfService":"NA",
					"Parent": "NA",
                    "TargetedOutreach": "NA",
                    "ClienteleContacts": "NA",
                    "Collaborators": "NA",
                    "Tags": "NA",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            },
            {
                "id": ActivitySubTypeId.EvidenceOfProfessionalCompetence,
                "name": "Evidence of Professional Competence",
                "appliesToHR": [HR.ANR],
                "nameLabel": "Name / Description", //Tooltip: "Name and /or Description of Award/Recognition/Professional Presentation/Office/Activity
                "properties": {
                    "Topic": "NA",
                    "Role": "NA",
                    "Location": "Required",
					"AreaOfService":"NA",
					"Parent": "NA",
                    "TargetedOutreach": "NA",
                    "ClienteleContacts": "NA",
                    "Collaborators": "NA",
                    "Tags": "NA",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            }
        ]
    },
    {
        "id": ActivityTypeId.UniversityAndPublicService,
        "name": "University and Public Service",
        "subTypes": [
            {
                "id": ActivitySubTypeId.UniversityService,
                "name": "University Service",
                "appliesToHR": [HR.ANR],
                "nameLabel": "Name / Description",
                "properties": {
                    "Topic": "NA",
                    "Role": "Required",
                    "Location": "NA",
					"AreaOfService":"Required",
					"Parent": "NA",
					// + Org Level (consider discrete set: County/local program, regional, state, national)
                    "TargetedOutreach": "NA",
                    "ClienteleContacts": "NA",
                    "Collaborators": "NA",
                    "Tags": "NA",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            },
            {
                "id": ActivitySubTypeId.PublicService,
                "name": "Public Service",
                "appliesToHR": [HR.ANR],
                "nameLabel": "Name / Description",
                "properties": {
                    "Topic": "NA",
                    "Role": "Required",
                    "Location": "NA",
					"AreaOfService":"Required",
					"Parent": "NA",
					// + Org Level (consider discrete set: County/local program, regional, state, national)
                    "TargetedOutreach": "NA",
                    "ClienteleContacts": "NA",
                    "Collaborators": "NA",
                    "Tags": "NA",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            },
        ]
    },
    {
        "id": ActivityTypeId.Other,
        "name": "Other",
        "subTypes": [
            {
                "id": ActivitySubTypeId.GenericNonAcademic,
                "name": "Generic Activity for Non-Academic Staff",
                "appliesToHR": [HR.ANR],
                "nameLabel": "Name / Description",
                "properties": {
                    "Topic": "Optional",
                    "Role": "Optional",
                    "Location": "Optional",
					"AreaOfService":"NA",
					"Parent": "Optional",
                    "TargetedOutreach": "NA",
                    "ClienteleContacts": "Optional",
                    "Collaborators": "Optional",
                    "Tags": "Optional",
                    "RepetitionCount": "Optional",
                    "InstanceCount": "Optional"
                }
            }
        ]
    }
].map(ActivityType.fromJson);
