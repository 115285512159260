import { Component, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Activity, ActivityService, ActivityTypeCollection, ActivityTypeId } from '../../domain';
import { ConsoleService } from '../../widgets/console.service';
import { GoogleAnalyticsService } from '../google-analytics.service';

@Component({
	templateUrl: './new-activity-modal.component.html',
	styleUrls: ['./new-activity-modal.component.scss']
})
export class NewActivityModalComponent {
	wideLayout: boolean;
	titleVerb: string = "Adding";
	activity: Activity;
	changedActivity: Activity;
	closing = false;
	formStatus: string = "INVALID";
	activityTypes: ActivityTypeCollection = new ActivityTypeCollection([]);
	constructor(
		public dialogRef: MatDialogRef<NewActivityModalComponent>,
		private svc: ActivityService,
		private snackBar: MatSnackBar,
		private gaService: GoogleAnalyticsService,  
		private console: ConsoleService, 
		@Inject(MAT_DIALOG_DATA) data: any
	){
		this.activity = data.activity.duplicate();
		this.changedActivity = this.activity;
		this.wideLayout = data.wideLayout;

		if (!!data.activityTypes){
			this.activityTypes = data.activityTypes
		} else {
			this.activityTypes = svc.getActivityTypes();
		}

		// restrict activityTypes to type for Professional Competence (60) / University Public Service (70)
		var activityTypeId = this.activityTypes.getTypeIdBySubTypeId(this.activity.subTypeId)
		if (activityTypeId == ActivityTypeId.ProfessionalCompetence || activityTypeId == ActivityTypeId.UniversityAndPublicService){
			this.activityTypes = new ActivityTypeCollection(
				this.activityTypes.all.filter(type => {
					if (type.id == activityTypeId){
						return true;
					}
					return false;
				})
			);
		}

		// change the modal title verb when copying
		if (data.purpose == "copy"){
			this.titleVerb = "Copying";

			this.onActivityChange(this.activity);
			this.onStatusChange("VALID"); // assuming the copied activity is valid
		}
	}

	onActivityChange(a: Activity){
		this.changedActivity = a;
		this.console.log('activity changed in modal:', a);
	}

	onClose(e){
		this.console.log('closed: ',e);
	}

	onStatusChange(status: string){
		this.console.log("Form Status Change: ", status);
		this.formStatus = status;
	}

	canSave(){
		//console.log("Form Status: ", this.formStatus);
		if (this.formStatus == "VALID"){
			return this.hasChanges();
		}
		return false;
	}

	hasChanges(){
		return !!this.changedActivity;
	}

	cancel(){
		this.dialogRef.close();
	}

	save(){
		if (this.canSave()){
			this.closing = true;
			this.svc
				.createActivity(this.changedActivity.toDto())
				.then(result => result.matchDo(
					newActivity => {
						this.snackBar.open('Activity saved', null, {duration: 3000});
						this.gaService.eventEmitter("Activity Created", "Activity", "Create");
						this.dialogRef.close(newActivity);
					},
					errorMsg => {
						this.closing = false;
						this.snackBar.open('Activity not saved: ' + errorMsg, null, {duration: 10000});
					}))
				.catch(errorResponse => {
					this.closing = false;
					this.console.log('errorResponse:', errorResponse);
					this.snackBar.open('Activity not saved: ' + errorResponse.statusText, null, {duration: 10000});
				});
		} else {
			this.snackBar.open('Please complete the form with appropriate data.', null, {duration: 3000});
		}
	}
}
