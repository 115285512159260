import * as kh from '../keyword-highlight/keywordHighlighter';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'keyword-underline',
  templateUrl: './keyword-underline.component.html',
  styleUrls: ['./keyword-underline.component.scss']
})
export class KeywordUnderlineComponent {
	@Input() keyword: string = '';
	@Input() text: string = '';
	segments(){
	   return kh.getKeywordMatchSegments(this.text, this.keyword);
	}
}
