import { Pipe, PipeTransform } from '@angular/core';
import { ActivityTypeId, ActivityService, ActivityTypeCollection, ActivitySubTypeId } from '../domain';

@Pipe({
  name: 'activitySubTypeName'
})
export class ActivitySubTypeNamePipe implements PipeTransform {
  private _activityTypes: ActivityTypeCollection;

  constructor(private svc: ActivityService) 
  { 
    this._activityTypes = svc.getActivityTypes();
  }

  transform(value: any, args?: any): any {
    return this._activityTypes.getSubTypeById(<ActivitySubTypeId>value).name;
  }

}
