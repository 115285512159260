import { Tag } from '../tag'
import { ActivitySubTypeId, AllActivitySubTypeIds } from '../activity-type';
import { Activity } from './activity';
import { Project } from './project';
import { ChildSelector } from '../child-selector';

export class PublicationCounts {
	constructor(
		public readonly peerReviewed: number,
		public readonly nonPeerReviewed: number){ }
}

export class CreateTheme {
	constructor(
		public name: string
	) { }
}

export class SelectThemeItems {
	constructor(
		public readonly deselectUnreachableItems: boolean,
		public readonly themeIds: number[],
		public readonly projectIds: number[],
		public readonly activityIds: number[]
	) { }
}

export class SelectThemeItem {
	constructor(
		public readonly isSelected: boolean,
		public readonly id: number,
		public readonly idType: DossierIdType
	) { }
}

export enum DossierIdType {
	ThemeId = 0,
	ProjectId = 1,
	ActivityId = 2
}

export enum ThemePublicationType {
	PopularPress = "PopularPress",
	PeerReviewed = "PeerReviewed",
	OtherPeerReviewed = "OtherPeerReviewed"
}

export class Theme {
	projectSelector: ChildSelector<Project>;
	themeActivitySelector: ChildSelector<Activity>;
    constructor(
        public readonly id: number,
        public name: string,
        public situation: string,
		public narrative: string,
		public publications: {[type: string]: string},
		public tags: Tag[],
		public readonly projects: Project[],
		public readonly themeActivities: Activity[],
		public readonly publicationCounts: PublicationCounts,
		public readonly activityCounts: Map<ActivitySubTypeId,number>,
		public includeInDossier: boolean,
		public readonly lastUpdated: Date | null,
		public readonly lastActivity: Date | null
    ) {
		this.projectSelector = new ChildSelector<Project>(
			this.projects,
			p => p.includeInDossier,
			(p,s) => p.includeInDossier = s);

		this.themeActivitySelector = new ChildSelector<Activity>(
			this.themeActivities,
			a => a.includeInDossier,
			(a,s) => a.includeInDossier = s);
	}

	public static fromJson(o: any): Theme {
		let activityCountEntries = 
			AllActivitySubTypeIds.map(astid => 
				[astid, o.abbreviatedMaterials.activityCounts[ActivitySubTypeId[astid]] || 0] as [ActivitySubTypeId,number]);
		
		return new Theme(
			<number>o.id,
			<string>o.name,
			<string>o.situation,
			<string>o.narrative,
			o.publications,
			(<any[]>o.tags).map(Tag.fromJson),
			(<any[]>o.projects).map(Project.fromJson),
			(<any[]>o.themeActivities).map(Activity.fromJson),
			new PublicationCounts(
				<number>o.abbreviatedMaterials.publicationCounts.peerReviewed,
				<number>o.abbreviatedMaterials.publicationCounts.nonPeerReviewed),
			new Map<ActivitySubTypeId,number>(activityCountEntries),
			<boolean>o.includeInDossier,
			<Date|null>o.lastUpdated,
			<Date|null>o.lastActivity);
	}
}