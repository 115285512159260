import { Component, OnInit } from '@angular/core';
import { AcademicProfessionalAssociation, ProfileService } from '../../domain';
import { ConsoleService } from '../../widgets/console.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

@Component({
	selector: 'academic-professional-association',
	templateUrl: './academic-professional-association.component.html',
	styleUrls: ['./academic-professional-association.component.scss']
})
export class AcademicProfessionalAssociationComponent implements OnInit {
	public editMode: boolean = false;
	private nameLabel = "Disciplinary Society/Prof. Assoc Name";
	private descriptionLabel = "Membership/Meetings Attended/Activities";
	private _associationCache: AcademicProfessionalAssociation[];
	academicProfessionalAssociations: AcademicProfessionalAssociation[];

	constructor(
		private console: ConsoleService,
		private snackBar: MatSnackBar,
		private svc: ProfileService) {}

	ngOnInit() {
		this.svc.getAcademicProfessionalAssociations()
			.subscribe(apas => {
			this.academicProfessionalAssociations = apas;
			this.cacheAssociations();
			//this.console.log("AcademicProfessionalAssociations: ", this.academicProfessionalAssociations);
		});

		this.svc.loadAcademicProfessionalAssociations();
	}

	changeEditMode(isEditMode: boolean){
		this.editMode = isEditMode;
		if(this.academicProfessionalAssociations.length == 0){
			this.addRow();
		}
	}

	deleteRow(index: number){
		if(confirm("Remove the " + this.nameLabel + " entry: \"" + this.academicProfessionalAssociations[index].name + "\"?\nAre you Sure?")){
			this.academicProfessionalAssociations.splice(index, 1);

			this.snackBar.open('Entry Removed', null, {duration:3000});
		}
	}

	save() {
		this.cacheAssociations();
		this.svc.SaveAcademicProfessionalAssociations(this.academicProfessionalAssociations)
			.then(result => result.matchDo(
				_ => {
					this.snackBar.open('Associations saved', null, {duration:3000});
					this.changeEditMode(false);
				},
				errorMsg => {
					this.snackBar.open('Associations not saved: ' + errorMsg, null, {duration: 10000});
				}));
		
	}

	addRow() {
		this.academicProfessionalAssociations.push(new AcademicProfessionalAssociation("",""));
	}

	cacheAssociations(){
		this._associationCache = this.academicProfessionalAssociations.map(apa => Object.assign({}, apa));
		this.console.log("Association Cache: ", this._associationCache);
	}

	cancel() {
		this.academicProfessionalAssociations = this._associationCache.map(apa => Object.assign({}, apa));
		this.changeEditMode(false);
	}
}
