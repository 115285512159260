import { Component, Input, OnInit } from '@angular/core';
import { CaseProgress } from 'app/domain';

@Component({
  selector: 'case-progress-bar',
  templateUrl: './case-progress-bar.component.html',
  styleUrls: ['./case-progress-bar.component.scss']
})
export class CaseProgressBarComponent implements OnInit {
  @Input() progress: CaseProgress;

  constructor() { }

  ngOnInit() {
  }


}
