import { Component, OnInit } from '@angular/core';
import { AHRService, Title, HRAcademic, HRUnit, SetAcademic, FTE, HumanResources, PayrollCampus, Rank, Step, IdAndName, Option, HRCommand, User, SortedSet, AcademicStatuses } from '../../domain';
import { DecimalPipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { TitleRankAndStepSelector } from './title-rank-and-step-selector';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ConsoleService } from '../../widgets/console.service';
import { AHROnboardingModalComponent } from '../ahr-onboarding-modal/ahr-onboarding-modal.component';
import { AHRTerms } from './terms';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../google-analytics.service';

@Component({
	templateUrl: './ahr.component.html',
	styleUrls: ['./ahr.component.scss']
})
export class AHRComponent implements OnInit {
	titles: Title[] = [];
	units: HRUnit[] = [];
	academics: HRAcademic[] = [];
	campuses: PayrollCampus[] = [];
	statuses: IdAndName[] = [];
	terms = AHRTerms.terms;

	cmds: {[userId:number ]: boolean} = {};
	saving: {[userId: number]: boolean} = {};
	titleRankAndStepByUserId: {[userId: number]: TitleRankAndStepSelector} = {};
	ahrForm: FormGroup;
	academicFormItems: FormArray;

	HumanResources = HumanResources;

	navLinks = [];

	constructor(
		private readonly svc: AHRService,
		private dp: DecimalPipe,
		private readonly snackbar: MatSnackBar,
		private console: ConsoleService,
		private formBuilder: FormBuilder,
		private matDialog: MatDialog,
		private gaService: GoogleAnalyticsService,
	) {
		this.titles = this.svc.getAllTitlesRanksAndSteps();
	}

	ngOnInit() {
		this.ahrForm = this.formBuilder.group({
			academics: this.formBuilder.array([])
		});
		this.loadAcademics();

		this.svc.getCampuses().then(cs => this.campuses = cs);
		this.statuses = AcademicStatuses;
	}

	loadAcademics(){
		// remove all academics from the form before adding them back again
		this.academics.forEach(a => this.removeAcademic(a));
		this.svc.getAllAcademics().then(xs => {
			this.academics = xs;
			this.academics.map(a => this.addAcademicToForm(a));
		});
	}

	createAcademicFormRow(a: HRAcademic): FormGroup {
		return this.formBuilder.group({
			userId: a.userId,
			titleId: a.title.id,
			statusId: a.status.id,
			rankId: a.rank.getValueOrDefault(new IdAndName(0,'')).id,
			stepId: a.step.getValueOrDefault(new IdAndName(0,'')).id,
			termId: a.term.getValueOrDefault(new IdAndName(null,'')).id,
			totalFte: a.totalFte,
			payrollCampusId: a.payrollCampus,
			hr: a.hr,
			supervisors: !!a.supervisors ? <SortedSet<User>>a.supervisors : new SortedSet<User>(),
			appointmentUnits: !!a.appointmentUnits ? <SortedSet<HRUnit>>a.appointmentUnits : new SortedSet<HRUnit>()
		});
	}

	addAcademicToForm(a: HRAcademic): void {
		this.academicFormItems = this.ahrForm.get('academics') as FormArray;
		this.academicFormItems.push(this.createAcademicFormRow(a));
		//this.console.log("Academic Added to Form Array: ", a);
		//this.console.log("Form Array: ", this.academicFormItems);
	}

	removeAcademic(a: HRAcademic): void {
		this.academicFormItems = this.ahrForm.get('academics') as FormArray;
		this.academicFormItems.removeAt(
			this.academicFormItems.value.findIndex(
				(academic: HRAcademic) => academic.userId == a.userId
		));
		//this.console.log("Academic Removed to Form Array: ", a);
		//this.console.log("Form Array: ", this.academicFormItems);
	}

	cancel(a: HRAcademic){
		delete this.cmds[a.userId];
		//this.removeAcademic(a);
	}

	changeTitle(a: HRAcademic): void {
		let academicFormData = this.getFormDataByAcademic(a);
		this.titleRankAndStepByUserId[a.userId] = new TitleRankAndStepSelector(
			this.titles,
			academicFormData.controls['titleId'].value,
			a.rank.map(t => t.id).getValueOrDefault(null),
			a.step.map(s => s.id).getValueOrDefault(null)
		);
		this.changeRank(a);
	}

	changeRank(a: HRAcademic): void {
		let academicFormData = this.getFormDataByAcademic(a);
		this.titleRankAndStepByUserId[a.userId] = new TitleRankAndStepSelector(
			this.titles,
			academicFormData.controls['titleId'].value,
			academicFormData.controls['rankId'].value,
			a.step.map(s => s.id).getValueOrDefault(null)
		);
	}

	getCMDFromHRAcademic(a: HRAcademic, academicFormData: FormGroup): SetAcademic {
		//this.console.log("Academic Form Data: ", academicFormData);
		let termId = academicFormData.controls['termId'].value;
		let cmd = new SetAcademic(
			a.userId,
			null,
			termId == "null" ? null : <number>termId,
			a.supervisors.map(s => s.id),
			a.appointmentUnits.map(au => au.id),
			a.totalFte.value,
			a.status.id,
			a.payrollCampus.id,
			a.hr
		);
		this.titleRankAndStepByUserId[a.userId] = new TitleRankAndStepSelector(
			this.titles,
			academicFormData.controls['titleId'].value,
			academicFormData.controls['rankId'].value,
			academicFormData.controls['stepId'].value
		);
		cmd.statusId = academicFormData.controls['statusId'].value;
		cmd.stepId = academicFormData.controls['stepId'].value;
		cmd.supervisorUserIds = academicFormData.controls['supervisors'].value.map((s: User) => s.id);
		cmd.appointmentUnitIds = academicFormData.controls['appointmentUnits'].value.map((u: HRUnit) => u.id);
		return cmd;
	}

	private getFormDataByAcademic(a: HRAcademic): FormGroup {
		let i = this.academicFormItems.value.findIndex(
			(academic: HRAcademic) => academic.userId == a.userId
		);
		//this.console.log("Academic Index: ", i);
		let academicFormData = this.academicFormItems.at(i) as FormGroup;
		//this.console.log("Academic Form Data: ", academicFormData);
		return academicFormData;
	}

	save(a: HRAcademic){
		let academicFormData = this.getFormDataByAcademic(a);

		let cmd = this.getCMDFromHRAcademic(a, academicFormData);
		let trs = this.titleRankAndStepByUserId[a.userId];
		//this.console.log("Saving Command: ", cmd);

		this.saving[a.userId] = true;
		this.svc.execute(cmd).then(r => r.matchDo(
			_ => 
			{ //success
				let index = this.academics.indexOf(a);
				let updatedAcademic = new HRAcademic(
					a.userId,
					a.fullName,
					this.statuses.find(s => s.id == academicFormData.controls['statusId'].value),
					trs.title,
					/* In this workflow, rank is only a 'stepping stone' to step.
					** If they select Rank but not Step, disregard Rank. */
					!!trs.rank ? trs.rank : Option.create<IdAndName>(), 
					trs.step,
					Option.create<IdAndName>(
						this.terms.filter(t => t.id == cmd.termId)[0]),
					academicFormData.controls['supervisors'].value, 
					academicFormData.controls['appointmentUnits'].value,
					new FTE(cmd.totalFte, 2),
					cmd.hr,
					this.campuses.filter(c => c.id == cmd.payrollCampusId)[0]
				);
				this.academics.splice(index, 1, updatedAcademic);
				delete this.cmds[a.userId];
				this.snackbar.open("Changes saved");
				this.gaService.eventEmitter("Academic Updated", "Academic", "Update");
				this.saving[a.userId] = false;
			},
			errorMsg => 
			{ //failure
				this.snackbar.open("Unable to save changes: " + errorMsg, null, {duration:10000});
				this.saving[a.userId] = false;
			}),
			errorMsg =>
			{
				this.snackbar.open("Unable to save changes: " + errorMsg, null, {duration:10000});
				this.saving[a.userId] = false;
			});
	}

	edit(a: HRAcademic){
		this.cmds[a.userId] = true;
		this.console.log("Edit Academic: ", a);

		this.titleRankAndStepByUserId[a.userId] = new TitleRankAndStepSelector(
			this.titles,
			a.title.id,
			a.rank.map(t => t.id).getValueOrDefault(null),
			a.step.map(s => s.id).getValueOrDefault(null)
		);
	}

	removeItem<T>(collection: T[], item: T){
		let index = collection.indexOf(item);
		if(index == -1){
			return;
		}
		collection.splice(index,1);
	}

	formattedFte(fte: FTE): string {
		return this.dp.transform(
			fte.value,
			'1.' + fte.precision);
	}

	openAHROnboardingModal(){
		this.matDialog.open(AHROnboardingModalComponent, {
			width: '650px',
			disableClose: true,
			data: {
				title:'New Academic',
				academics: this.academics   
		  	},
			hasBackdrop: true
		}).afterClosed().subscribe(_ => {
			this.loadAcademics();
		});
	}
}
