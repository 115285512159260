import { Component, Input, OnInit } from '@angular/core';
import { TimeServed, Option } from 'app/domain';

@Component({
  selector: 'time-served-table',
  templateUrl: './time-served-table.component.html',
  styleUrls: ['./time-served-table.component.scss']
})
export class TimeServedTableComponent implements OnInit {

	@Input() data: Option<TimeServed>;
  constructor() { }

  ngOnInit() {
  }

	monthFormat(m: number): string {
		return m <= 36 
			? `${m}mo` 
			: `${(Math.floor(m / 12))}yr, ${(Math.floor(m % 12))}mo`;
	}
}
