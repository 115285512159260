import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Case, ActionType, NewsStory, CaseState, Action, nextTerm, IdAndName, ExceptionalTiming, Supervisor, TermId, Position } from '../../../domain/types/ahr';
import { AHRService, Option, ActionProposal, TitleRankStepAndTermId, ActionProposalType, ProgressionTable, Advancement, User, SortedSet, WFACaseInfo } from '../../../domain';
import { ConsoleService } from '../../../widgets/console.service';
import { AdvancementSelectorModalComponent } from '../advancement-selector-modal/advancement-selector-modal.component';
import { FormControl } from '@angular/forms';
import { HtmlContentModalComponent } from '../../../main-core/html-content-modal/html-content-modal.component';
import { map, filter } from 'rxjs/operators';
import { GoogleAnalyticsService } from '../../../main-core/google-analytics.service';

export class CaseModalNavigator {
	static noop(): CaseModalNavigator {
		return new CaseModalNavigator(
			() => false,
			() => false,
			_ => {},
			_ => {});
	}
	constructor(
		readonly hasPrev: () => boolean,
		readonly hasNext: () => boolean,
		readonly goPrev: (cmc: CaseModalComponent) => void,
		readonly goNext: (cmc: CaseModalComponent) => void
	){ }
	public tryGoNext(cmc: CaseModalComponent): void {
		if(this.hasNext()){
			this.goNext(cmc);
		}
	}
	public tryGoPrev(cmc: CaseModalComponent): void {
		if(this.hasPrev()){
			this.goPrev(cmc);
		}
	}
}

const ObjectfromEntries = entries => {
	//native Object.fromEntries was not available from the current version of TS in this project at the time of writing. 2021-02-24
	let o = {};
	for(let [k,v] of entries){
		o[k] = v;
	}
	return o;
};

@Component({
	selector: 'case-modal',
	templateUrl: './case-modal.component.html',
	styleUrls: ['./case-modal.component.scss']
})
export class CaseModalComponent {
	ActionProposalType = ActionProposalType;
	ExceptionalTiming = ExceptionalTiming;
	usersControl: FormControl;
	supervisorReviewInvolvementControl: FormControl;
	sayHi = () => alert('hi');
	nonacademic_tabs = [
		{ label: "Case Details"       , id: "case" }
	   ,{ label: "County Assignments" , id: "county_assignments" }
   	];
	academic_tabs = [
		 { label: "Case Details"       , id: "case" }
		,{ label: "Candidate Documents", id: "candidate_docs" }
		,{ label: "Supervisors"        , id: "supervisors" }
		,{ label: "County Assignments" , id: "county_assignments" }
	];
	tabs = this.academic_tabs;
	activeTab = this.tabs[0];
	activateTab(tab){
		this.console.log('activating tab: ', tab);
		this.activeTab = tab;
	}
	navigationHotkeys: object;
	

	//TODO: get counties
	private allCounties = [{"id":1,"name":"Alameda"},{"id":2,"name":"Alpine"},{"id":3,"name":"Amador"},{"id":4,"name":"Butte"},{"id":5,"name":"Calaveras"},{"id":6,"name":"Colusa"},{"id":7,"name":"Contra Costa"},{"id":8,"name":"Del Norte"},{"id":9,"name":"El Dorado"},{"id":10,"name":"Fresno"},{"id":11,"name":"Glenn"},{"id":12,"name":"Humboldt"},{"id":13,"name":"Imperial"},{"id":14,"name":"Inyo"},{"id":15,"name":"Kern"},{"id":16,"name":"Kings"},{"id":17,"name":"Lake"},{"id":18,"name":"Lassen"},{"id":19,"name":"Los Angeles"},{"id":20,"name":"Madera"},{"id":21,"name":"Marin"},{"id":22,"name":"Mariposa"},{"id":23,"name":"Mendocino"},{"id":24,"name":"Merced"},{"id":25,"name":"Modoc"},{"id":26,"name":"Mono"},{"id":27,"name":"Monterey"},{"id":28,"name":"Napa"},{"id":29,"name":"Nevada"},{"id":30,"name":"Orange"},{"id":31,"name":"Placer"},{"id":32,"name":"Plumas"},{"id":33,"name":"Riverside"},{"id":34,"name":"Sacramento"},{"id":35,"name":"San Benito"},{"id":36,"name":"San Bernardino"},{"id":37,"name":"San Diego"},{"id":38,"name":"San Francisco"},{"id":39,"name":"San Joaquin"},{"id":40,"name":"San Luis Obispo"},{"id":41,"name":"San Mateo"},{"id":42,"name":"Santa Barbara"},{"id":43,"name":"Santa Clara"},{"id":44,"name":"Santa Cruz"},{"id":45,"name":"Shasta"},{"id":46,"name":"Sierra"},{"id":47,"name":"Siskiyou"},{"id":48,"name":"Solano"},{"id":49,"name":"Sonoma"},{"id":50,"name":"Stanislaus"},{"id":51,"name":"Sutter"},{"id":52,"name":"Tehama"},{"id":53,"name":"Trinity"},{"id":54,"name":"Tulare"},{"id":55,"name":"Tuolumne"},{"id":56,"name":"Ventura"},{"id":57,"name":"Yolo"},{"id":58,"name":"Yuba"}];

	selectedCounty = null;
    wfaUrl:string;
	get availableCounties(){
		let countyIdMap = this.case.countyAssignments.reduce((dict,c) => {
			dict[c.id] = c.id;
			return dict;
		},{});
		return this.allCounties.filter(c => !(c.id in countyIdMap));
	}

	removeCountyAssignment(c){
		let i = this.case.countyAssignments.indexOf(c);
		if(i < 0){
			this.snackBar.open('Unable to remove county assignment');
			return;
		}
		if(!confirm('Are you sure?')){
			return;
		}
		this.svc.removeCountyAssignment(this.case.id, c.id).then(r =>
			r.matchDo(
				_ => {
					this.snackBar.open('County assignment removed: ' + c.name);
					this.gaService.eventEmitter("Case County Removed", "Case County", "Removed");
				},
				(msg: string) => this.snackBar.open('Unable to remove county assignment: ' + msg)));
	}
	addCountyAssignment(id){
		let c = this.availableCounties.filter(c => c.id == id)[0];
		if(!c){
			this.snackBar.open('Unable to add county assignment');
			return;
		}
		this.svc.addCountyAssignment(this.case.id, id).then(r =>
			r.matchDo(
				_ => {
					this.snackBar.open('County assignment added: ' + c.name);
					this.gaService.eventEmitter("Case County Added", "Case County", "Added");
					this.selectedCounty = null;
				},
				(msg: string) => this.snackBar.open('Unable to add county assignment: ' + msg)));
	}
	getId(o){
		return o.id;
	}
	actionProposalTypes = [
		{
			value: ActionProposalType.AdvancementTo,
			label: "Advancement"
		},
		//{ value: ActionProposalType.TermAdvancement, label: "Term Advancement" },
		{
			value: ActionProposalType.AnnualEvaluation,
			label: "Annual Evaluation"
		},
		{
			value: ActionProposalType.Goals,
			label: "Goals"
		},
		{
			value: ActionProposalType.Deferral,
			label: "Deferral"
		},
		{
			value: ActionProposalType.FiveYearReview,
			label: "Five Year Review"
		}
	];
	Action = ActionType;
	case: Case;
	news: NewsStory[];
	closing = false;
	proposedAction: Option<Action> = Option.create<Action>();
	proposedActionType: ActionType = null;
	availableProposedActions: Option<Action>[] = [];
	eligibleActionTypes: ActionType[] = [];
	eligibleActionByType: Map<ActionType,Action> = new Map();
	wfaCase = Option.create<WFACaseInfo>();
	navigator: CaseModalNavigator;

	preview(html: string){
		HtmlContentModalComponent.open(this.matDialog, "Letters Of Evaluation Request Message", html, '50vw', 'text-sm');
	}

	supervisorReviewParticipationOptions = [];

	supervisorReviewParticipationLabelById = {};

	promoteSupervisor(s: Supervisor, index: number){
	  if(this.validatePromoteReadWriteAccess(s.reviewParticipation, index)){
		if(!confirm('Are you sure?')){
			return;
		}
		this.svc.promoteSupervisor(this.case.id, s.details.id).then(r =>
			r.matchDo(
				_ => {
					this.snackBar.open('Supervisor promoted');
					this.gaService.eventEmitter("Case Supervisor Promoted", "Case Supervisor", "Promoted");
				},
				(msg: string) => this.snackBar.open('Supervisor not promoted: ' + msg)));
	   }
	}
	demoteSupervisor(s: Supervisor, index: number){
	  if(this.validateDemoteReadWriteAccess(s.reviewParticipation, index)){
		if(!confirm('Are you sure?')){
			return;
		}
		this.svc.demoteSupervisor(this.case.id, s.details.id).then(r =>
			r.matchDo(
				_ => {
					this.snackBar.open('Supervisor demoted');
					this.gaService.eventEmitter("Case Supervisor Demoted", "Case Supervisor", "Demoted");
				},
				(msg: string) => this.snackBar.open('Supervisor not demoted: ' + msg)));
	  }
	}
	removeSupervisor(s: Supervisor, index: number){
	  if(this.validateRemoveReadWriteAccess(index)){
		if(!confirm('Are you sure?')){
			return;
		}
		this.svc.removeSupervisor(this.case.id, s.details.id).then(r =>
			r.matchDo(
				_ => {
					this.snackBar.open('Supervisor removed');
					this.gaService.eventEmitter("Case Supervisor Removed", "Case Supervisor", "Removed");
				},
				(msg: string) => this.snackBar.open('Supervisor not removed: ' + msg)));
	  }
	}
	supervisorReviewParticipationChange(index, $event){
		if(!confirm('Are you sure?')){
			$event.preventDefault();
			return;
		}
		var s = this.case.supervisors[index];
		if(!s){
			alert('supervisor not found');
		}
		this.console.log('supervisor review participation change:', s, $event);
		if(this.validateReadWriteAccessOnChange($event.target.value,index)){
			this.svc.addSupervisor(this.case.id, s.details.id, $event.target.value).then(r =>
			r.matchDo(
				_ => {
					this.snackBar.open('Supervisor updated');
					this.gaService.eventEmitter("Case Supervisor Updated", "Case Supervisor", "Updated");
				},
				(msg: string) => this.snackBar.open('Supervisor not updated: ' + msg)));
		}
	}
	addSupervisor(s: User, reviewParticipationId: string){
		if(this.validateReadWriteAccess(reviewParticipationId)){
		 this.svc.addSupervisor(this.case.id, s.id, reviewParticipationId).then(r =>
			r.matchDo(
				_ => {
					this.snackBar.open('Supervisor added');
					this.gaService.eventEmitter("Case Supervisor Added", "Case Supervisor", "Added");
				},
				(msg: string) => this.snackBar.open('Supervisor not added: ' + msg)));
		}
	}
	public IsIndefiniteStatusOnly(){
		var proposedAction = this.case.proposedAction.getValueOrDefault(null);
		return Position.titleRankStepEqual(this.case.currentPosition, proposedAction.endingPosition)
			&& this.indefiniteStatusProposed();
	}

	public indefiniteStatusProposed() {
		var proposedAction = this.case.proposedAction.getValueOrDefault(null);
		return this.case.currentPosition.term != 'Indefinite Status' && proposedAction.endingPosition.term == 'Indefinite Status';
	}

    public n_ary(n: number): string {
        switch(n){
            case 1: return 'Primary';
            case 2: return 'Secondary';
            case 3: return 'Tertiary';
            default: return 'Tertiary';
        }
    }

	public static open(matDialog: MatDialog, c: Case, navigator?: CaseModalNavigator): MatDialogRef<CaseModalComponent>{
		var instance = matDialog.open(CaseModalComponent, {
			width: '1200px',
			disableClose: false,
			data: {
				navigator
		  	},
			hasBackdrop: true
		});

		instance.componentInstance.viewCase(c);

		return instance;
	}

	public viewCase(c: Case): void {
		this.case = c;
		this.console.log("Case: ", this.case);
		this.proposedAction = this.case.proposedAction;
		this.proposedActionType = this.case.proposedAction.map(pa => pa.type).getValueOrDefault(null);
		this.console.log("Proposed Action: ", this.proposedAction);
		this.availableProposedActions = this.case.eligibleActions.map(Option.create);

		this.tabs = this.isNonAcademic(c) ? this.nonacademic_tabs: this.academic_tabs;
		this.activeTab = this.tabs[0];

		this.eligibleActionTypes = 
			this.case.eligibleActions.map(a => a.type);
		this.eligibleActionByType = 
			new Map<ActionType,Action>(
				this.case.eligibleActions.map(a => [a.type, a] as [ActionType, Action]));
		this.svc
			.newsStoriesByCaseId(this.case.id)
			.subscribe(news => this.news = news);
		this.svc
			.cases
			.pipe(map(cases => cases.find(c => c.id == this.case.id)))
			.pipe(filter(c => c != undefined))
			.subscribe(c => this.case = c);

		this.svc.tryGetWFACaseInfo(this.case.id).then(c =>{
			this.wfaCase = c;
			const userClaims = localStorage.getItem("projectboard.user.claims");
            this.wfaUrl = this.wfaCase.value?.url +'?projectBoard='+encodeURI(userClaims);
		});
	}

	constructor(
		public dialogRef: MatDialogRef<CaseModalComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private console: ConsoleService,
		private svc: AHRService,
		private snackBar: MatSnackBar,
		private gaService: GoogleAnalyticsService,
		private readonly matDialog: MatDialog
	){ 
		this.usersControl = new FormControl(new SortedSet<User>());

		this.supervisorReviewParticipationOptions = [
			{
				id:"WritesReview",
				label: "Writes Review",
				description: "This supervisor writes a review"
			},
			{
				id:"ReadOnly",
				label: "Read-only access",
				description: "This supervisor will have read-only access to candidate materials but will not be asked to write a review"
			}
		];

		this.supervisorReviewInvolvementControl = new FormControl(this.supervisorReviewParticipationOptions[0].id);
		this.supervisorReviewParticipationLabelById = ObjectfromEntries(this.supervisorReviewParticipationOptions.map(o => [o.id, o.label]));

		if(!!data.navigator){
			this.navigator = <CaseModalNavigator>data.navigator;
			this.console.log('Case navigator provided:', this.navigator);
		} else {
			this.navigator = CaseModalNavigator.noop();
			this.console.log('No case navigator provided');
		}
		this.console.log(this.svc);
		this.navigationHotkeys = {
			'ArrowLeft': this.navigator.tryGoPrev.bind(this.navigator,this),
			'ArrowRight': this.navigator.tryGoNext.bind(this.navigator,this),
			'a': this.sayHi.bind(this)
		};
	}

	private tryOpenAdvancementSelectorModalComponent(): Option<MatDialogRef<AdvancementSelectorModalComponent>>
	{
		return this.case.progressionTable.match(
			() => {
				alert('Unable to load progression table for this person');
				return Option.create<MatDialogRef<AdvancementSelectorModalComponent>>();
			},
			(table: ProgressionTable) => {
				let selectedPosition = this.case.proposedAction.map((a:Action) => 
					new TitleRankStepAndTermId(
						a.endingPosition.id.titleId,
						a.endingPosition.id.rankId,
						a.endingPosition.id.step,
						a.endingPosition.id.term.getValueOrDefault(null))).getValueOrDefault(null);
				let currentPosition = new TitleRankStepAndTermId(
					this.case.currentPosition.id.titleId,
					this.case.currentPosition.id.rankId,
					this.case.currentPosition.id.step,
					this.case.currentPosition.id.term.getValueOrDefault(null)
				);
				return Option.create(
					AdvancementSelectorModalComponent.open(
						this.matDialog,
						table,
						currentPosition,
						selectedPosition,
						this.case.timeServed))
			});
	}

	propose(t: ActionProposalType){
		if(t == ActionProposalType.AdvancementTo){
			this.tryOpenAdvancementSelectorModalComponent().doWithValue(
				(dialogRef: MatDialogRef<AdvancementSelectorModalComponent>) =>
				{
					dialogRef.afterClosed().subscribe(result => {
						let position = result as TitleRankStepAndTermId;
						if(!position){
							return;
						}
						let hasTermChanged = Case.isProposedTermDifferentFromCurrentTerm(this.case.currentPosition, position.termId);
						this.send(new ActionProposal(
							ActionProposalType.AdvancementTo,
							new Advancement(
									position.toTitleRanksAndStepId(),
									Option.create(position.termId).map(t => nextTerm(t, hasTermChanged))
									//this.case.currentPosition.id.term.map(nextTerm)
								)));
					})
				});
		} else if (t == ActionProposalType.Deferral) {
			let reason = prompt('Reason for Deferral (be brief):');
			if(!reason){
				alert('You must provide a reason for the deferral');
				return;
			}
			this.svc.proposeDeferral(this.case.id, reason).then(result => 
				result.matchDo(
					_   => this.snackBar.open('Deferral Proposed', null, {duration:3000}),
					msg => this.snackBar.open('Unable to defer review: ' + msg, null, {duration:10000})));
		} else {
			this.send(new ActionProposal(t,null));
		}
	}

	private send(p: ActionProposal){
		this.console.log('proposal: ', p);
		this.svc.proposeAction(this.case.id, p).then(result => 
			result.matchDo(
				_ => {
					this.snackBar.open('Proposed Action updated', null, {duration:3000});
					this.gaService.eventEmitter("Case Proposed Action Updated", "Case Proposed Action", "Updated");
					//TODO: merge new proposal back into the case read model
				},
				msg => {
					this.snackBar.open('Proposed Action not updated: ' + msg, null, {duration:10000});
				}));
	}
 
	private sendDenial(secondaryAction: any){
		this.svc.denyProposedAdvancement(this.case.id, secondaryAction,this.proposedActionType)
		.then(result => 
			result.matchDo(
				_   => {
					this.snackBar.open('Proposed advancement denied', null, {duration:10000});
					this.gaService.eventEmitter("Case Proposed Advancement Denied", "Case Proposed Advancement", "Denied");
				},
				msg => this.snackBar.open('Unable to deny advancement:' + msg, null, {duration:10000})));
	}
	private sendFiveyearDenial(secondaryAction: any){
		this.svc.denyProposedAdvancement(this.case.id, secondaryAction,this.proposedActionType)
		.then(result => 
			result.matchDo(
				_   => {
					this.snackBar.open('Proposed five year review denied', null, {duration:10000});
					this.gaService.eventEmitter("Case Proposed five year review Denied", "Case Proposed five year review", "Denied");
				},
				msg => this.snackBar.open('Unable to deny five year review:' + msg, null, {duration:10000})));
	}
	public approve(){
		this.svc.approveProposedAdvancement(this.case.id, this.case.yearId,this.proposedActionType)
		.then(result => 
			result.matchDo(
				_   => {
					this.snackBar.open('Proposed advancement approved', null, {duration:10000});
					this.gaService.eventEmitter("Case Proposed Advancement Approved", "Case Proposed Advancement", "Approved");
				},
				msg => this.snackBar.open('Unable to approve advancement:' + msg, null, {duration:10000})));
	}
	
	public denialOptions = [
		{
			label: 'Dismiss/Demote',
			value: 'DismissOrDemote'
		},
		{
			label: 'Extend Term',
			value: 'ExtendedTerm'
		},
		{
			label: 'Advance with term extension',
			value: 'AdvancementWithTermExtension'
		},
		{
			label: 'Advance Term',
			value: 'TermAdvancement'
		},
		{
			label: 'Just \'No\'',
			value: 'None'
		},
		{
			label: 'Alternative Advancement',
			value: 'AlternativeAdvancement'
		}
	];

	public deny(t){
		function some(value){ return {"case":"Some","fields":[value]}; }
		function none(){ return {"case":"None"}; }

		switch(t){
			case "DismissOrDemote":
				if(confirm('Begin dismissal/demotion? Are you sure?')){
					this.sendDenial(some({'case': 'DismissOrDemote'}));
				}
				break;
			case "ExtendedTerm":
				let years = prompt('How many years would you like to extend the current term?','1');
				if(years && parseInt(years)){
					this.sendDenial(some({'case': 'ExtendedTerm', "fields":[parseInt(years)]}));
				} else {
					alert('Invalid years selection.  Please try again.');
				}
				break;
			case "TermAdvancement":
				if(confirm('Begin term advancement? Are you sure?')){
					this.sendDenial(some({'case': 'TermAdvancement'}));
				}
				break;
			case "TermAdvancement":
				if(confirm('Begin term advancement? Are you sure?')){
					this.sendDenial(some({'case': 'TermAdvancement'}));
				}
				break;
			case "AdvancementWithTermExtension":
				if(confirm('Advance to the proposed position and extend the current term?')){
					let newPositionId = this.case.proposedAction.map((a:Action) => 
						new TitleRankStepAndTermId(
							a.endingPosition.id.titleId,
							a.endingPosition.id.rankId,
							a.endingPosition.id.step,
							a.endingPosition.id.term.getValueOrDefault(null))).getValueOrDefault(null);
					this.sendDenial(some({'case': 'AdvancementWithTermExtension','fields':[newPositionId]}));
				}
				break;
			case "AlternativeAdvancement":
				this.tryOpenAdvancementSelectorModalComponent().doWithValue(
					(dialogRef: MatDialogRef<AdvancementSelectorModalComponent>) =>
						dialogRef.afterClosed().subscribe(result => {
							let position = result as TitleRankStepAndTermId;
							if(!position){
								//alert('Invalid advancement selection.  Please try again.')
								return;
							}
							let hasTermChanged = Case.isProposedTermDifferentFromCurrentTerm(this.case.currentPosition, position.termId);
							let adv = 
								{
									positionId: position.toTitleRanksAndStepId(),
									term: Option.create(position.termId).map(t => nextTerm(t, hasTermChanged))
										.map(t => ({"case":t}))
										.toFSharp()
								};
							this.sendDenial(some({'case': 'AlternativeAdvancement', "fields":[adv]}));
						}));
				break;
			case "None":
				if(confirm('Just say \'No\'? Are you sure?')){
					this.sendDenial(none());
				}
				break;
			default:
				alert('Unrecognized denial type: ' + t);
				break;
		}
	}
    public denyFiveyearReview(){
		function none(){ return {"case":"None"}; }
		if(confirm('Just say \'No\'? Are you sure?')){
			this.sendFiveyearDenial(none());
		}
	}
	public canDenyFiveyearReview(){
		return (this.case.state == CaseState.WithCandidate || this.case.state == CaseState.InAppeal)
			&& this.proposedActionType === ActionType.FiveYearReview;
	}
	public get approvalLabel(){
		return this.case.state == CaseState.InAppeal
		? "Approve appeal"
		: "Approve proposed action";
	}

	public get denialLabel(){
		return this.case.state == CaseState.InAppeal
		? "Deny appeal"
		: "Deny proposed action";
	}

	public canApprove(){
		return (this.case.state == CaseState.WithCandidate || this.case.state == CaseState.InAppeal)
			&& (this.proposedAnAdvancement || this.proposedActionType === ActionType.FiveYearReview);
	}
	public canDenyProposedAction(){
		return (this.case.state == CaseState.WithCandidate || this.case.state == CaseState.InAppeal)
			&& this.proposedAnAdvancement;
	}
	public canDenyResult(){
		return this.case.hasPreviousYear 
			&& this.case.state == CaseState.AwaitingProposal;
	}
	private get proposedAnAdvancement(){
		return this.case.proposedAction
			.map(a => a.type == ActionType.Merit || a.type == ActionType.Promotion)
			.getValueOrDefault(false);
	}

	canSendToCandidate(){
		return this.case.state == CaseState.ReadyToSendToCandidate;
	}

	canAppealResult(){
		return this.case.state == CaseState.Complete 
			|| (this.case.hasPreviousYear && this.case.state == CaseState.AwaitingProposal)
			|| (!this.case.hasPreviousYear && this.proposedAnAdvancement);
	}

	canRecall(){
		return this.case.state == CaseState.WithCandidate;
	}

	canApproveDeferral(){
		return this.case.state == CaseState.ReadyToApproveDeferral;
	}

	canPropose(){
		return this.case.state != CaseState.WithCandidate;
	}

	appealResult(){
		this.svc.appealResult(this.case.id).then(result => 
			result.matchDo(
				_   => {
					this.snackBar.open('Result appealed', null, {duration:3000});
					this.gaService.eventEmitter("Case Result Appealed", "Case Result", "Appealed");
				},
				msg => this.snackBar.open('Unable to appeal case: ' + msg, null, {duration:10000})));
	}

	sendToCandidate(){
		this.svc.sendToCandidate(this.case.id).then(result => 
			result.matchDo(
				_   => {
					this.snackBar.open('Case sent to candidate', null, {duration:3000});
					this.gaService.eventEmitter("Case Sent", "Case", "Sent");
				},
				msg => this.snackBar.open('Unable to send case to candidate: ' + msg, null, {duration:10000})));
	}

	recall(){
		let reason = prompt('Why do you wish to recall the case from the candidate? (be brief)');
		if(!reason){
			alert('You must provide a reason for the recall');
			return;
		}
		this.svc.recallFromCandidate(this.case.id, reason).then(result => 
			result.matchDo(
				_   => {
					this.snackBar.open('Case recalled', null, {duration:3000});
					this.gaService.eventEmitter("Case Recalled", "Case", "Recalled");
				},
				msg => this.snackBar.open('Unable to recall case: ' + msg, null, {duration:10000})));
	}

	approveDeferral(){
		this.svc.approveDeferral(this.case.id).then(result => 
			result.matchDo(
				_   => {
					this.snackBar.open('Deferral Approved', null, {duration:3000});
					this.gaService.eventEmitter("Case Deferral Approved", "Case Deferral", "Approved");
				},
				msg => this.snackBar.open('Unable to approve deferral: ' + msg, null, {duration:10000})));
	}

	close(){
		this.closing = true;
		this.dialogRef.close('cancelled');
	}
	validateReadWriteAccess(reviewParticipationId:string){
		let lastSupervisor= this.case?.supervisors && this.case?.supervisors[this.case?.supervisors.length-1];
		if((reviewParticipationId === "ReadOnly" &&  this.case?.supervisors.length === 0)
		   || ( reviewParticipationId ===  "WritesReview" && lastSupervisor?.reviewParticipation === "ReadOnly"))
		{
		 this.openValidationMessage();
		 return false;
		}
		else {
		 return true;
		}
	 }
	 validateReadWriteAccessOnChange(reviewParticipationId:string, index: number){
		 let priorSupervisor = this.case?.supervisors && this.case?.supervisors[index-1];
		 let nextSupervisor = this.case?.supervisors && this.case?.supervisors[index+1];
		 if((reviewParticipationId === "WritesReview" && priorSupervisor?.reviewParticipation === "ReadOnly")
		   || (reviewParticipationId === "ReadOnly" && nextSupervisor?.reviewParticipation === "WritesReview")
		   || (index === 0 && reviewParticipationId === "ReadOnly")){
			 this.openValidationMessage();
			 return false;
		 }
		 else{
			return true;
		 }
	 }
	 validatePromoteReadWriteAccess(reviewParticipationId:string, index: number){
		let priorSupervisor= this.case?.supervisors && this.case?.supervisors[index-1];
		if(reviewParticipationId !==  priorSupervisor?.reviewParticipation)
		{
		 this.openValidationMessage();
		 return false;
		}
		else {
		 return true;
		}
	 }
	 validateDemoteReadWriteAccess(reviewParticipationId:string, index: number){
		let nextSupervisor = this.case?.supervisors && this.case?.supervisors[index+1];
		if(reviewParticipationId !==  nextSupervisor?.reviewParticipation)
		{
		 this.openValidationMessage();
		 return false;
		}
		else {
		 return true;
		}
	 }
	 validateRemoveReadWriteAccess(index: number){
		let nextSupervisor = this.case?.supervisors && this.case?.supervisors[index+1];
		if(index === 0 && nextSupervisor?.reviewParticipation === "ReadOnly")
		{
		 this.openValidationMessage();
		 return false;
		}
		else {
		 return true;
		}
	 }
	 openValidationMessage(){
		 let html =`<ul><li>Write, readonly, readonly, readonly, readonly…</li>`+
				 `<li>Write, write, readonly, readonly, readonly…</li>`+
				 `<li>Write, write, write, readonly, readonly…</li></ul>`
		 HtmlContentModalComponent.open(this.matDialog, "Acceptable orders:", html, '30vw', 'text-sm');
	 }

	 public isNonAcademic(c: Case) : boolean {
		const nonAcademicTitle = this.svc.getAllTitlesRanksAndSteps().find(t => t.name === 'Staff Director');
		return c.currentPosition.id.titleId === nonAcademicTitle?.id;
	}
}