export function ObjectValuesShim(){
	console.log('Applying shim for Object.values');
	if (!Object['values']) {
		Object['values'] = (function() {
			'use strict';
			return function(o) {
				if (typeof o !== 'function' && (typeof o !== 'object' || o === null)) {
					throw new TypeError('Object.values called on non-object');
				}
				var keys = Object.keys(o);
				var values = [];
				for(var i=0; i<keys.length; i++){
					values.push(o[keys[i]]);
				}
				return values;
			};
		}());
	}
}
