
import {first} from 'rxjs/operators';
import {
	AfterContentInit,
	Directive,
	HostListener,
	ElementRef,
	NgZone,
	Input,
	OnInit
} from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutoCompleteComponent } from './auto-complete.component';


import { merge ,  Subscription } from 'rxjs';

@Directive({
	selector: 'input[auto-complete]',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: AutoCompleteDirective,
			multi: true
		}
	]
})
export class AutoCompleteDirective implements ControlValueAccessor, OnInit, AfterContentInit {
	constructor(private _element: ElementRef, private _zone: NgZone) { }

	@Input('auto-complete') autoComplete: AutoCompleteComponent;
	@Input('displayWith') displayWith: ((any) => string) = null;
	private _closingActionsSubscription: Subscription;

	@HostListener('click', ['$event'])
	click(e){
		this._toggleMenu();
	}

	ngOnInit(){
	}

	ngAfterContentInit(){
	}

	@HostListener('keydown', ['$event'])
	keyboardInput(e){
		this._openMenu();
	}

	@HostListener('keydown.Escape', ['$event'])
	keyboardEscape(e){
		this._closeMenu();
		e.preventDefault();
	}

	@HostListener('keydown.Enter', ['$event'])
	keyboardEnter(e){
		e.preventDefault();
		this._setValueAndClose(this.autoComplete.optionsManager.activeItem);
	}

	private _setValueAndClose(option): void{
		if(!option){
			return;
		}
		this.autoComplete.optionsManager.clearActiveItem();
		this.autoComplete.options.forEach(o => {
			o.active = false;
		});
		option.active = false;
		if(!!option){
			let value = option.value;
			let strValue = this.displayWith ? this.displayWith(value) : (value || '').toString();
			this._element.nativeElement.value = strValue;
			this._onChange(value);
		} else {
			this._onChange();
		}
		this._closeMenu();
	}

	@HostListener('input')
	onInput(){ this._onChange(this._element.nativeElement.value); }

	@HostListener('keydown.ArrowDown', ['$event'])
	keyboardArrowDown(e){ this.keyboardNavigate(e, 1); }

	@HostListener('keydown.ArrowUp', ['$event'])
	keyboardArrowUp  (e){ this.keyboardNavigate(e,-1); }

	private keyboardNavigate(e, delta: number){
		e.preventDefault();
		var prevActiveItem = this.autoComplete.optionsManager.activeItem;
		this.autoComplete.optionsManager.changeIndexByDelta(delta);
		var nextActiveItem = this.autoComplete.optionsManager.activeItem;
		if(!!prevActiveItem && prevActiveItem != this.autoComplete.optionsManager.activeItem){
			prevActiveItem.active = false;
		}
		if(!!nextActiveItem){
			nextActiveItem.active = true;
			this.autoComplete.scrollTo(nextActiveItem);
		}
	}

	@HostListener('document:click', ['$event.target'])
	public onClick(targetElement) {
		const clickedInside = this._element.nativeElement.contains(targetElement);
		if (!clickedInside) {
			this._closeMenuIfOpen();
		}
	}

	private _closeMenu (){
		this.autoComplete.menuOpen = false;
		this._element.nativeElement.classList.remove('auto-complete-input-active');
		this._closingActionsSubscription.unsubscribe();
	}

	private _closeMenuIfOpen (){
		if(!this.autoComplete.menuOpen){
			return;
		}
		this._closeMenu();
	}

	private _openMenu  (){
		this.autoComplete.menuOpen = true;
		this._element.nativeElement.classList.add('auto-complete-input-active');
		this._closingActionsSubscription = this._subscribeToClosingActions();
		this.autoComplete.adjustPositionAccordingToWindow();
	}

	private _subscribeToClosingActions(): Subscription {
		return merge.call(null, this._zone.onStable.pipe(first()), this.autoComplete.options.changes)
			// create a new stream of panelClosingActions, replacing any previous streams
			// that were created, and flatten it so our stream only emits closing events...
			.switchMap(() => {
				let optionSelectEmitters = this.autoComplete.options.map(o => o.select);
				return merge.apply(null, optionSelectEmitters);
			})
			.first() // only the first closing event matters
			.subscribe(selectedOption => {
				/* For some reason: this block gets called multiple times
				 * (after we've opened and selected more than once). */
				//console.log('selectedOption:',selectedOption);
				this._setValueAndClose(selectedOption);
			});
	}

	private _toggleMenu(){
		if(this.autoComplete.menuOpen){
			this._closeMenu();
		} else {
			this._openMenu();
		}
	}

	//ControlValueAccessor implementation:
	private _onChange: any = () => {};
	private _onTouch:  any = () => {};
	writeValue(val: any): void {
		//console.log('writeValue:', val);
		var value = val || '';
		this._element.nativeElement.value = value;
		this._onChange(value);
	}
	registerOnTouched(fn): void { this._onTouch  = fn; }
	registerOnChange(fn) : void { this._onChange = fn; }
}
