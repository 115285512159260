import { Pipe, PipeTransform } from '@angular/core';
import { timeAgo } from './time-ago.fn';

@Pipe({
	name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		return timeAgo(value);
	}
}