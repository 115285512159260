import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dot',
  templateUrl: './dot.component.html',
  styleUrls: ['./dot.component.scss']
})
export class DotComponent implements OnInit {

	@Input() color: string = '';
	@Input() checked: boolean = false;

  constructor() { }

  iconClass(){
	  return this.checked
		  ? 'fa-check-circle'
		  : 'fa-circle';
  }

  ngOnInit() {
		//console.log("color: ", this.color);
  }

}
