import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'position-filter',
	templateUrl: './position-filter.component.html',
	styleUrls: ['./position-filter.component.scss']
})
export class PositionFilterComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
