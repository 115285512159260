import { Injectable } from '@angular/core';
import { ProgramArea, Result, Project, ProjectDto, ProjectService as PS, UserPreferenceService, WideModal } from '../domain';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../app-settings';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConsoleService } from '../widgets/console.service';
import { MatDialog } from '@angular/material/dialog';
import { ProjectModalComponent } from '../main-core/project-modal/project-modal.component';
import { Router } from '@angular/router';

@Injectable()
export class ProjectService implements PS {
	private _projects: ReplaySubject<Project[]>;
	private _modalWidth = 650;
	private _useWideLayout: boolean;

	constructor(
		private http: HttpClient, 
		private appSettings: AppSettings,
		private console: ConsoleService,
		private readonly userPreferenceService: UserPreferenceService,
		private router: Router
	) { 
		this._projects = <ReplaySubject<Project[]>>new ReplaySubject(1);
		this.getWideModalSettings();
	}

	getWideModalSettings(): WideModal {
		if(this.userPreferenceService.getWideModalPreference()){
			this._modalWidth = 650;
			this._useWideLayout = true;
		} else {
			this._modalWidth = 500;
			this._useWideLayout = false;
		}

		return new WideModal(this._modalWidth, this._useWideLayout);
	}

	getAllProgramAreas(): Promise<ProgramArea[]> {
		return this.http.get(this.appSettings.APIURL + '/tag/program_areas')
			.toPromise()
			.then(json => json as ProgramArea[]);
	}

	get projects(): Observable<Project[]> {
		return this._projects.asObservable();
	}

	getProject(id: number): Observable<Project> {
		return this._projects.asObservable()
			.pipe(map(projects =>
				projects.find(p => p.id == id)));
	}

	create(data: ProjectDto): Promise<Result<number, string>> {
		return this.http.post(this.appSettings.APIURL + '/project/', data)
			.toPromise()
			.then(json => {
				this.loadProjects();
				return this.resultFromJson(json);
			});
	}

	update(id: number, data: ProjectDto): Promise<Result<number, string>> {
		return this.http.put(this.appSettings.APIURL + '/project/' + id, data)
			.toPromise()
			.then(json => {
				this.loadProjects();
				return this.resultFromJson(json);
			});
	}

	archive(id: number): Promise<Result<number, string>> {
		return this.http.delete(this.appSettings.APIURL + '/project/' + id)
			.toPromise()
			.then(json => {
				this.loadProjects();
				return this.resultFromJson(json);
			});
	}

	unarchive(id: number): Promise<Result<number, string>> {
		return this.http.put(this.appSettings.APIURL + '/project/unarchive/' + id, null)
			.toPromise()
			.then(json => {
				this.loadProjects();
				return this.resultFromJson(json);
			});
	}

	loadProjects(): void {
		this.http.get(this.appSettings.APIURL + '/project')
			.subscribe(
				json => this._projects.next((<any[]>json).map(Project.fromJson))
				,() => this.console.log('Could not load Projects.')
			);
	}

	private resultFromJson(json: any): Result<number, string> {
		return Result.fromJson<number,string>(
			json,
			o => <number>o,
			o => <string>o);
	}

	/******** Modals *********/
	newProjectModal(matDialog: MatDialog): void {
		this.getWideModalSettings(); // this is here to check for changes between each modal use.
		matDialog.open(ProjectModalComponent, {
			width:this._modalWidth.toString() + 'px',
			disableClose: true,
			data: { 
				title:'Adding a Project', 
				wideLayout: this._useWideLayout 
			},
			hasBackdrop: true
		});
	}
	
	editProjectInModal(matDialog: MatDialog, project: Project): void {
		this.getWideModalSettings();
		let dialogRef = matDialog.open(ProjectModalComponent, {
			width: this._modalWidth.toString() + 'px',
			disableClose: true,
			data: {
				title:'Editing a Project',
				project: project,
				wideLayout: this._useWideLayout
			},
			hasBackdrop: true
		});
	}
}
