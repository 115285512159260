import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../app-settings';
import { AcademicProfessionalAssociation, Result, ProfileService as PS } from '../domain';
import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { ConsoleService } from '../widgets/console.service';

@Injectable()
export class ProfileService implements PS {
    private _academicProfessionalAssociations: BehaviorSubject<AcademicProfessionalAssociation[]>;

    constructor(
        private http: HttpClient, 
        private appSettings: AppSettings,
        private console: ConsoleService)
    { 
        this._academicProfessionalAssociations = <BehaviorSubject<AcademicProfessionalAssociation[]>>new BehaviorSubject([]);
    }

    public getAcademicProfessionalAssociations(): Observable<AcademicProfessionalAssociation[]> {
        return this._academicProfessionalAssociations.asObservable();
    }

    public SaveAcademicProfessionalAssociations(academicProfessionalAssociations: AcademicProfessionalAssociation[]): Promise<Result<null,string>> {
        return this.http.put(this.appSettings.APIURL + '/profile/professional_associations', academicProfessionalAssociations)
			.toPromise()
			.then(json => Result.fromJson<null,string>(
				json,
				o => null,
				o => <string>o));
    }

    public loadAcademicProfessionalAssociations(): void {
        this.http.get(this.appSettings.APIURL + '/profile/professional_associations')
			.subscribe(
				json => this._academicProfessionalAssociations.next(json as AcademicProfessionalAssociation[])
				,error => this.console.log('Could not load Associations.')
			);
    }
}