<div mat-dialog-title>
	Add Activity
	<!-- this intercepts the initial focus and the user needs to press tab to pass the focus to the activity-type-menu so other key commands can be recognized.
		<button id="menuCloseButton" class="btn btn-sm btn-link float-right"
		(click)="close()"
		style="cursor: pointer;">
			<i class="fa fa-fw fa-times"></i>
			<span class="sr-only">Close</span>
	</button>
	-->
</div>
<mat-dialog-content>
	<activity-type-menu
		(subTypeClick)="startNewActivity($event)"
		[activityTypes]="activityTypes"
		[activityCategories]="activityCategories"
		[startingActivityCategoryId]="startingActivityCategoryId"></activity-type-menu>
</mat-dialog-content>