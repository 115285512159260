import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { UserService } from 'app/domain/data-access-interfaces/user.service';
import { Subscription, of } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Directive({
    selector: '[appUserAvatar]'
})
export class UserAvatarDirective implements OnInit, OnChanges, OnDestroy {
    @Input('appUserAvatar') userId: number;
    @Input() userName: string;
    @Input() showTooltip: boolean = true;

    //If the userid is changed asynchrously, we have to check for changes.
    //A good example is the reviewer dashboard details
    @Input() checkChanges: boolean = false;

    private defaultAvatarUrl: string = '/assets/blank-avatar_light-on-dark.png';
    private currentSubscription : Subscription = new Subscription();
  
    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private userService: UserService
    ) {}

    ngOnDestroy(): void {
        this.currentSubscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.checkChanges)
            return;

        if (changes.userId && !changes.userId.firstChange && changes.userId.currentValue) {
            this.userId = changes.userId.currentValue;
            this.userName = changes.userName.currentValue;
            this.loadAvatar();
        }        
    }

    ngOnInit() {
        this.loadDefaultAvatar();
        this.loadAvatar();
    }

    private loadDefaultAvatar() {
        this.renderer.setAttribute(this.el.nativeElement, 'src', this.defaultAvatarUrl);
        this.renderer.setAttribute(this.el.nativeElement, 'alt', this.userName);
        if (this.showTooltip)
            this.renderer.setAttribute(this.el.nativeElement, 'title', this.userName);
    }

    private loadAvatar() {

        this.currentSubscription.unsubscribe();
        this.currentSubscription = new Subscription();
    
        const initialsAvatar$ = this.userService.getInitialsAvatarUrl(this.userId).pipe(
            catchError(() => of(this.defaultAvatarUrl))
        );

        const avatar$ = this.userService.getAvatarUrlAsync(this.userId).pipe(
            catchError(() => of(null))
        );

        //Load the initials first since we know that's quick and then overwrite
        //with the actual avatar when loaded (if available)
        const initialsSubscription = initialsAvatar$.subscribe(initialsUrl => {

            this.renderer.setAttribute(this.el.nativeElement, 'src', initialsUrl);

            const avatarSubscription = avatar$.subscribe(avatarUrl => {
                if (avatarUrl) {
                    this.renderer.setAttribute(this.el.nativeElement, 'src', avatarUrl);
                }
            });

            this.currentSubscription.add(avatarSubscription);
        });

        this.currentSubscription.add(initialsSubscription);
    }
}
