import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd, RouterState } from '@angular/router';

@Component({
	selector: 'section-header',
	templateUrl: './section-header.component.html',
	styleUrls: ['./section-header.component.scss']
})
export class SectionHeaderComponent implements OnInit {
	@Input() sectionTitle: string;
	@Input() navPath: string;
	@Input() navLinks: any[];
	@Input() containerClass: string = "container";
	private activeRoute: RouterState | null = null;

	constructor(private router: Router) { 
		router.events.subscribe(e => {
			if(NavigationEnd.prototype.isPrototypeOf(e)){
				this.activeRoute = router.routerState;
			}
		});
	}

	ngOnInit() {
	}

	urlIsActive(url: string){
		if(!this.activeRoute){
			return false;
		}
		return this.activeRoute.snapshot.url.indexOf(this.navPath + '/' + url) == 0;
	}

}
