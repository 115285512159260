<div mat-dialog-title>
	{{title}}
</div>
<mat-dialog-content>
	<form [formGroup]="form" novalidate>
		<div class="form-group">
			<label for="cg-title">Title</label>
			<input type="text"
				id="cg-title"
				placeholder="Group Title"
				class="form-control"
				formControlName="title"
				aria-label="Title">
		</div>
		<div class="form-group">
			<label for="cg-type">Type</label>
			<select 
				id="cg-type"
				class="form-control"
				formControlName="type"
				aria-label="Type">
				<option *ngFor="let t of types" [value]="t">
					{{ClienteleGroupType[t]}}
				</option>
			</select>
		</div>

		<!--<div class="form-group">
			<label for="cg-counties">Counties</label>
			<select class="form-control" formControlName="countyIds" multiple>
				<option *ngFor="let c of counties" [value]="c.id">
					{{c.name}}
				</option>
			</select>
		</div>-->

		<div class="form-group">
			<label for="cg-counties">Counties</label>
			<county-selection-picker
				[countySelection]="selectedCounties"
				formControlName="countySelection"
				></county-selection-picker>
		</div>

		<div class="form-group">
			<label for="cg-source">Source</label>
			<select 
			    id="cg-source"
				class="form-control"
				formControlName="source"
				aria-label="Source">
				<option *ngFor="let s of sources" [value]="s.id">
					{{s.name}}
				</option>
			</select>
		</div>

		<div class="form-group">
			<label for="cg-description">Description / Other Source</label>
			<textarea autoGrow
				id="cg-description"
				class="form-control"
				formControlName="description"
				aria-label="Description"></textarea>
		</div>

		<div *ngIf="!!form.value.type" formGroupName="demographicCounts">
			<label>{{ClienteleGroupType[form.value.type]}} Baseline Data</label>
			<div *ngFor="let dt of applicableDemographicTypes" class="mb-2">
				<div *ngIf="dt.id!='40'" class="text-sm">{{dt.label}} ({{demographicTypeSum(dt)}} | {{demographicTypeSumAll(dt)}})
					<i class="fa fa-fw fa-info-circle" matTooltip="Sum w/o Prefer not to state/Undetermined | Total Sum" ></i>
				</div>
				<div class="mt-1">
					<div class="form-group form-group-tight" *ngFor="let d of dt.demographics">
						<div class="media">
							<div class="pull-left">
								<input class="form-control form-control-sm mr-2 text-center"
									type="number"
									style="width:120px;"
									[formControlName]="d.id"
									min="0"
									numericOnly>
							</div>
							<label class="media-body text-sm">
								{{d.label}}
								<i *ngIf="!!d.description"
									class="fa fa-fw fa-info-circle text-muted"
									[matTooltip]="d.description"></i>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<input type="hidden" formControlName="archived">
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<button (click)="archive()" 
		class="btn btn-link text-danger float-left" 
		[title]="StaticText.ArchiveClienteleGroup"
		*ngIf="canArchive()">
		<i class="fa fa-fw fa-archive"></i>
		Archive (Delete)
	</button>
	<button (click)="cancel()" class="btn btn-link" [disabled]="closing">
		Cancel
	</button>
	<button (click)="save()" class="btn btn-primary" [disabled]="closing">
		Save
	</button>
</mat-dialog-actions>
