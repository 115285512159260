export class Academic {
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly title: string,
        public readonly email: string,
        public readonly headquartersUnit: string,
        public readonly allUnits: string[]
    ) { }

    public static fromJson(o: any): Academic {
        return new Academic(
            o.id,
            o.name,
            o.title,
            o.email,
            o.headquartersUnit,
            o.allUnits);
    }
}