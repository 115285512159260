
<div class="row mb-2" *ngIf="currentUser.value.roles.academicHumanResources"> 
    <div class="col-md-12 text-right">
        <button type="button" class="btn btn-outline-primary btn-sm mr-1" style="width:35%" (click)="selectAllCases(ADVANCEMENT_CASES)">{{selectAdvancementCasesText}}</button>
        <button type="button" class="btn btn-outline-primary btn-sm mr-1" style="width:35%" (click)="selectAllCases(AE_CASES)">{{selectAnnualEvaluationCasesText}}</button>
        <button type="button" class="btn btn-outline-primary btn-sm" style="width:25%" [disabled]="selectedFileCount <= 0" (click)="downloadSelectedCases()">
            Download <span *ngIf="selectedFileCount > 0">({{selectedFileCount}})</span>
        </button>
    </div>
</div>

<div class="card d-lg-none d-block">
    <div class="card-header">
        Your program review cases
    </div>
    <select class="form-control" [(ngModel)]="selectedCaseIdForMenu">
        <option *ngFor="let c of filteredCases" [ngValue]="c.id">
            {{c.candidateName}}
            - {{c.roles}} 
        </option>
    </select>
</div>
<div class="card d-lg-block d-none">
    <div class="card-header" *ngIf="cases.length > 0" style="padding-left: 0.9rem;">
        Your program review cases
        <mat-button-toggle-group *ngIf="currentUser.value.roles.academicHumanResources" #toggleBtn="matButtonToggleGroup" (change)="onValChange($event.value);reset($event.value)" [value]="reviewerType">
            <mat-button-toggle value="AHR">
                AHR
            </mat-button-toggle>
            <mat-button-toggle value="Supervisor">
                Supervisor
            </mat-button-toggle>
        </mat-button-toggle-group>  
        <span *ngIf="!currentUser.value.roles.academicHumanResources && containsPRCReviewerStep()">
            <button mat-button [matMenuTriggerFor]="filterDropdown" class="prc-filter">
              Filter <i class="fa fa-caret-down" aria-hidden="true"></i>
            </button>
            <mat-menu #filterDropdown="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let filterOption of prcFilterOptions; let i = index"
                        (click)="filterCasesBy(i)" [class.activeFilter]="activeFilter === i">
                    {{ filterOption.label }}
                </button>
            </mat-menu>
        </span>       
    </div>
    <div>
        <div class="input-group">
            <input type="text"
                #input
                class="form-control"
                placeholder="Search for Candidate Case"
                [matAutocomplete]="auto"
                [formControl]="searchCase"
                aria-label="Search Candidate Case" role="search">
            <div class="input-group-addon" (click)="clear()" [hidden]="!selected">
                <i class="fa fa-times" style="cursor: pointer;"></i>
            </div>
        </div>
        
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="filterCase($event.option.value)" [displayWith]="displayWith">
            <mat-option *ngFor="let t of filteredCases" [value]="t">
                {{t.candidateName}} 
            </mat-option>
        </mat-autocomplete>
    </div>
    <div class="list-group scroll-y">
        <div *ngIf="filteredCases.length === 0 && !loading" class="list-group-item text-center">
            You have no cases to review
        </div>
        <div *ngIf="loading">
            <i class="fa fa-fw fa-spin fa-refresh"></i>
            Loading...
        </div>
        <div
            *ngFor="let c of filteredCases"
            [ngClass]="{
                'text-sm':true,
                'list-group-item list-group-item-action': true,
                'active': !!c.id.equals(selectedCaseId)
            }"           
            style="cursor: pointer"
        >
            <span class="media">
                <!-- <span   
                    class="fa {{c.readyForYourReview ? 'fa-exclamation-circle' : 'fa-exclamation-circle'}} float-left mr-3"
                    style="font-size:24px;"
                    [ngClass]="{invisible: !c.readyForYourReview}"
                    title="{{c.readyForYourReview ? 'This case is ready for your review' : ''}}">
                </span> -->
                <span class="media-body" [routerLink]="[c.id.academicId]">
                    {{c.candidateName}}
                    <br>
                    <span class="text-sm">
                        {{c.roles}}
                        <br>
                        {{c.progress}}
                    </span>
                </span>
                <span *ngIf="currentUser.value.roles.academicHumanResources && isSelectableCase(c.proposedAction.type)">
                    <input class="form-check-input" id="checkboxNoLabel" [(ngModel)]="c.isChecked" (ngModelChange)="checkedChanged()" type="checkbox" value={{c.id.academicId} >
                </span>
            </span>

        </div>
    </div>
</div>