import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ConsoleService } from './console.service';

@Directive({
	selector: '[hotkey]'
})
export class HotkeyDirective {
	@Input('hotkey') keys: { [hotkey: string]: () => null };

	constructor(
		private console: ConsoleService,
		public el: ElementRef
	) {
		this.console.log("HotKeys Initialized");
	}

	@HostListener('keydown', ['$event']) 
	keyDown(event: KeyboardEvent) {
		//this.console.log("hotkey element: ", this.el);
		//this.console.log("hotkey event: ", event);

		if (event.key in this.keys) {
			//this.console.log("Hotkey pressed!");
			this.keys[event.key]();
			
			return false;
		}
		return true;
	}

}

@Directive({
	selector: '[nohotkey]'
})
export class NoHotkeyDirective {
	constructor(
		private console: ConsoleService,
		public el: ElementRef
	) {
		//this.console.log("No-Hotkeys Initialized");
	}

	@HostListener('keydown', ['$event']) 
	keyDown(event: KeyboardEvent) {
		event.stopPropagation();
		//this.console.log("hotkey blocked", event);
		return true;
	}

}