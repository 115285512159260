<div [hotkey]="{'a': newActivity, 'c': newClienteleGroup, 'p': newProject, 't': newTheme}">
	<section-header
		sectionTitle="Project Board"
		navPath="/academic"
		containerClass="container"
		[navLinks]="navLinks"></section-header>

	<div id="wrapper" class="container">
		<router-outlet #routeOutlet></router-outlet>
	</div>

	<anr-footer></anr-footer>
</div>
