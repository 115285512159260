
export function getKeywordMatchSegments(str: string, keyword: string): string {
	if(!keyword){
		return str;
	}
	var keywords =keyword.split(',');
	var transformText = getRegexMatches(keywords, str);
	return transformText;
}

export class KeywordMatch {
	constructor(
		public readonly text: string,
		public readonly match: boolean) { }
}

function getRegexMatches(keywords:string[], str:string){
	keywords.forEach(function(keyword){
	var regex = new RegExp(keyword?.trim(), 'gi');
    str = str.replace(regex, (match: string) => {
		return '<span class="highlightText">' + match + '</span>';
	  });
    });
	return str;
}
