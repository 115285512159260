import { Tag } from "..";
import { Academic } from './academic';
export class Theme
{
    constructor(
        public readonly name: string,
        public readonly owner: Academic,
        public readonly tags: Tag[],
        public readonly humanReadableDate: string,
        public readonly createdDate: Date,
        public readonly themelastUpdatedDate: Date,
    ){}

    public static fromJson(o: any): Theme {
        return new Theme(
            o.name,
            Academic.fromJson(o.owner),
            (<any[]>o.tags).map(Tag.fromJson),
            o.humanReadableDate,
            o.createdDate,
            o.themelastUpdatedDate);
    }
}