<div mat-dialog-title>
	Non-ANR Collaborator
		<button id="menuCloseButton" class="btn btn-sm btn-link float-right"
		(click)="close()"
		style="cursor: pointer;">
			<i class="fa fa-fw fa-times"></i>
			<span class="sr-only">Close</span>
	</button>
</div>
<mat-dialog-content [formGroup]="form">
  <div class="form-group row">
    <label [class]="labelClass">
      Full Name
    </label>
    <div [class]="controlClass">
      <input type="text" formControlName="name" class="form-control">
    </div>
  </div>
  <div class="form-group row">
    <label [class]="labelClass">
      Institution
    </label>
    <div [class]="controlClass">
      <div class="input-group">
        <select class="form-control" formControlName="ucCampus" (change)="validateForm('ucCampus')">
          <option value="" disabled hidden>UC Campus</option>
          <option *ngFor="let ucCampus of ucCampusList" [value]="ucCampus.id">
            {{ucCampus.name}}
          </option>
        </select>
        <div class="input-group-addon" [hidden]="!form.value.ucCampus ">
          <i class="fa fa-times text-danger" style="cursor:pointer;"
            title="Clear" 
            (click)="form.controls['ucCampus'].setValue('');validateForm('ucCampus')"></i>
        </div>
      </div>
      <div class="py-2 px-3">
        - or -
      </div>
      <div class="input-group">
        <select class="form-control" formControlName="lgi" (change)="validateForm('lgi')">
          <option value="" disabled hidden>Other Land Grant Institution</option>
          <optgroup label="Land Grant Institutions">
            <option *ngFor="let institution of landGrantInstitutions" [value]="institution.id">
              {{institution.stateId}}:
              {{institution.name}}
            </option>
          </optgroup>
          <optgroup label="Tribal Institutions">
            <option *ngFor="let institution of tribalInstitutions" [value]="institution.id">
              {{institution.name}}
            </option>
          </optgroup>
        </select>
        <div class="input-group-addon" [hidden]="!form.value.lgi ">
          <i class="fa fa-times text-danger" style="cursor:pointer;"
            title="Clear" 
            (click)="form.controls['lgi'].setValue('');validateForm('lgi')"></i>
        </div>
      </div>
      <div class="py-2 px-3">
        - or -
      </div>
      <input type="text" (change)="validateForm('otherInstitutionName')" formControlName="otherInstitutionName" class="form-control" placeholder="Other Institution Name">
    </div>
  </div>
  <div class="form-group row">
    <label [class]="labelClass">
      Contact Information
    </label>
    <div [class]="controlClass">
      <div class="input-group">
        <input type="text" formControlName="emailAddress" class="form-control" placeholder="Email Address (optional)">
        <div class="input-group-addon">
          <i class="fa fa-envelope"></i>
        </div>
      </div>
      <div class="input-group">
        <input type="text" formControlName="websiteAddress" class="form-control" placeholder="Website Address (optional)">
        <div class="input-group-addon">
          <i class="fa fa-link"></i>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
	<button (click)="close()" class="btn btn-link" [disabled]="closing">
		Cancel
	</button>
	<button (click)="save()" class="btn btn-primary" [disabled]="closing || !form.valid">
		Save
	</button>
</mat-dialog-actions>