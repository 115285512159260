<div class="card" *ngIf="project">
	<div class="card-block">
		<button (click)="editProject(project)"
			class="btn btn-sm btn-link pull-right">
				<i class="fa fa-fw fa-pencil"></i>
				Edit
		</button>
		<div class="row">
			<div class="col-md-5">
				<small class="text-muted">{{project.startDate | date}}</small>
				<h5 class="mb-3">{{project.name}}</h5>

				<!--
				<div class="form-group">
					<h6>Status</h6>
					<div>
						{{project.status}}
					</div>
				</div>
				-->

				<div class="project-label">Theme</div>
				<div [ngSwitch]="!!project.theme.value" class="form-group">
					<div *ngSwitchCase="true">
						{{project.theme.value.name}}
					</div>
					<div *ngSwitchCase="false" class="text-muted" [matTooltip]="StaticText.ProjectThemeRequiredForDossierTooltip">
						<i class="fa fa-warning text-muted"></i>
						No Theme Linked
					</div>
				</div>

				<div class="form-group">
					<div class="project-label">Program Area</div>
					<div>
						{{project.programArea.name}} <span *ngIf="project.programArea.disabled" class="text-xs">(No longer active)</span>
					</div>
				</div>

				<div class="project-label">Role</div>
				<div [ngSwitch]="!!project.role" class="form-group">
					<div *ngSwitchCase="true">
						{{project.role}}
					</div>
					<div *ngSwitchCase="false" class="text-muted" [matTooltip]="StaticText.ProjectRoleRequiredForDossierTooltip">
						<i class="fa fa-warning text-muted"></i>
						No Role
					</div>
				</div>

				<div>
					<div class="project-label">Tags</div>
					<div class="mt-1">
						<tag-list [tags]="project.tags"></tag-list>
						<span *ngIf="!project.tags.length" class="text-muted text-sm">None</span>
					</div>
				</div>
			</div>
		
			<div class="col-md-7">
				<div class="form-group">
					<div class="project-label">Collaborators</div>
					<div
						*ngIf="project.ANRCollaborators.length == 0 && project.nonANRCollaborators.length == 0"
						class="text-muted">
						None
					</div>
					<div class="mt-1" [hidden]="project.ANRCollaborators.length == 0">
						ANR:
						<span *ngFor="let c of project.ANRCollaborators">
							<img appUserAvatar="{{c.id}}" [userName]="c.firstName + ' ' + c.lastName"
							style="width: 40px;"
							class="img-round img-fluid user-avatar-sm mr-2">
						</span>
					</div>
					<div class="mt-1" [hidden]="project.nonANRCollaborators.length == 0">
						Non-ANR: {{project.nonANRCollaborators.length}}
					</div>
				</div>
				<div class="form-group">
					<ul class="nav nav-tabs mb-2 text-sm">
						<li class="nav-item">
							<a class="nav-link" [class.active]="activeTab=='description'" 
								(click)="activeTab='description'">
								Description
							</a>
						</li>
						<!--
						<li class="nav-item">
							<a class="nav-link" [class.active]="activeTab=='fte'" 
								(click)="activeTab='fte'">
								Multi-State FTE
							</a>
						</li>
						<li class="nav-item">
							<a class="nav-link disabled" [class.active]="activeTab=='notes'">
								Notes
							</a>
						</li>
						<li class="nav-item">
							<a class="nav-link disabled" [class.active]="activeTab=='documents'">
								Documents
							</a>
						</li>
						-->
					</ul>
					<div [ngSwitch]="activeTab">
						<div *ngSwitchCase="'description'" class="text-sm" [innerHtml]="project.description"></div>
						<div *ngSwitchCase="'fte'">
							Coming Soon!
						</div>
						<div *ngSwitchDefault>
							Coming Soon
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Removed per PROJB-253 -->
	<!-- <div class="card-block project-activities-container" *ngIf="!abbreviatedActivities">
		<app-activities [parent]="projectAsParent" [tabsVisible]="false"></app-activities>
	</div> -->
</div>
