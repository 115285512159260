import { ClienteleGroupType, DemographicType } from '../../domain';
export const DEMOGRAPHIC_TYPES: DemographicType[] =
	[
		{
			"id": "Ethnicity",
			"label": "Ethnicity",
			"applicableClienteleGroupTypeIds": [
				ClienteleGroupType.Individual,
			   	ClienteleGroupType.Family
			],
			"demographics": [
				{
					"id": "EthnicityHispanic",
					"label": "Hispanic or Latino",
					"description": "A person of Cuban, Mexican, Puerto Rican, South or Central American, or other Spanish culture or origin, regardless of race.",
					"canBeTargetedForOutreach": true
				},
				{
					"id": "EthnicityNotHispanic",
					"label": "Not Hispanic or Latino",
					"description": "A person NOT of Cuban, Mexican, Puerto Rican, South or Central American, or other Spanish culture or origin, regardless of race.",
					"canBeTargetedForOutreach": true
				},
				{
					"id": "EthnicityUndetermined",
					"label": "Prefer not to state / Undetermined",
					"description": "This is category is not included in Civil Rights Compliance determination.",
					"canBeTargetedForOutreach": false
				}
			]
		},
		{
			"id": "Race",
			"label": "Race",
			"applicableClienteleGroupTypeIds": [
				ClienteleGroupType.Individual,
			   	ClienteleGroupType.Family
			],
			"demographics": [
				{
					"id": "RaceAmericanIndian",
					"label": "American Indian or Alaska Native",
					"description": "A person having origins in any of the original peoples of North and South America (including Central America), and who maintains tribal affiliation or community attachment.",
					"canBeTargetedForOutreach": true
				},
				{
					"id": "RaceAsian",
					"label": "Asian",
					"description": "A person having origins in any of the original peoples of the Far East, Southeast Asia, or the Indian subcontinent including, for example, Cambodia, China, India, Japan, Korea, Malaysia, Pakistan, the Philippines Islands, Thailand, and Vietnam",
					"canBeTargetedForOutreach": true
				},
				{
					"id": "RaceBlack",
					"label": "Black or African American",
					"description": "A person having origins in any of the black racial groups of Africa.",
					"canBeTargetedForOutreach": true
				},
				{
					"id": "RacePacificIslander",
					"label": "Hawaiian or other Pacific Islander",
					"description": "A person having origins in any of the original peoples of Hawaii, Guam, Samoa, or other Pacific Islands.",
					"canBeTargetedForOutreach": true
				},
				{
					"id": "RaceWhite",
					"label": "White",
					"description": "A person having origins in any of the original people of Europe, the Middle East, or North Africa.",
					"canBeTargetedForOutreach": true
				},
				{
					"id": "RaceMultiple",
					"label": "More than one race",
					"description": "For reporting purposes, you may aggregate the total number individuals who identify with more than one of the races above. However, please note that when asking individuals to self-report their racial identify, individuals should be able to choose/select one or more races and not a \"more than one race\" or \"multiracial\" category.",
					"canBeTargetedForOutreach": false
				},
				{
					"id": "RaceOther",
					"label": "Race not listed above",
					"description": "This is category is not included in Civil Rights Compliance determination.",
					"canBeTargetedForOutreach": false
				},
				{
					"id": "RaceUndetermined",
					"label": "Prefer not to state / Undetermined",
					"description": "This is category is not included in Civil Rights Compliance determination.",
					"canBeTargetedForOutreach": false
				}
			]
		},
		{
			"id": "Gender",
			"label": "Gender",
			"applicableClienteleGroupTypeIds": [ClienteleGroupType.Individual],
			"demographics": [
				{
					"id": "GenderMale",
					"label": "Male",
					"description": "",
					"canBeTargetedForOutreach": true
				},
				{
					"id": "GenderFemale",
					"label": "Female",
					"description": "",
					"canBeTargetedForOutreach": true
				},
				{
					"id": "GenderOther",
					"label": "Gender identity not listed above",
					"description": "This is category is not included in Civil Rights Compliance determination.",
					"canBeTargetedForOutreach": false
				},
				{
					"id": "GenderUndetermined",
					"label": "Prefer not to state / Undetermined",
					"description": "This is category is not included in Civil Rights Compliance determination.",
					"canBeTargetedForOutreach": false
				}
			]
		},
		{
			"id": "Other",
			"label": "Other",
			"applicableClienteleGroupTypeIds": [ClienteleGroupType.Organization],
			"demographics": [
				{
					"id": "Organization",
					"label": "Organization",
					"description": "",
					"canBeTargetedForOutreach": false
				}
			]
		}
	].map(DemographicType.fromJson);
