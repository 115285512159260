<div mat-dialog-title>Add Activity (as a collaborator)</div>
<mat-dialog-content>
	<p>
		To add the following activity, please specify your role(s) in the collaboration.
	</p>
	<p *ngIf="invite.originalActivity.clienteleContactCount > 0">
		Also, since this activity includes clientele contacts,
		please specify which of your clientele groups you served during this activity.
	</p>

	<div class="card mb-3">
		<div class="card-block">
			<div class="row">
				<div class="col-md-8">
					<h6>{{invite.originalActivity.name}}</h6>
				</div>
				<div class="col-md-4 text-center text-sm">
					<activity-date [activityDate]="invite.originalActivity.date"></activity-date>
				</div>
			</div>
			<div class="text-sm">
				<em>Added by</em>
				<div class="media mt-1">

					<img appUserAvatar="{{invite.addedByUser.id}}" [userName]="invite.addedByUser.name"
						style="width: 40px;"
						class="img-round img-fluid user-avatar-sm mr-2">				

					<div class="media-body">
						<div>{{invite.addedByUser.name}}</div>
						<div class="text-sm">{{invite.addedByUser.academicTitle}}</div>
						<!-- TODO: location -->
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="form-group row">
		<div class="col-md-12">
			<clientele-contact-summary
				[originalActivity]="invite.originalActivity"
				></clientele-contact-summary>
		</div>
	</div>

	<div class="form-group row">
		<label class="col-md-4">
			Role(s)
			<div class="text-sm text-muted">(Required)</div>
		</label>
		<div class="col-md-8">
			<input type="text" placeholder="Your Role(s)" [(ngModel)]="dto.role" class="form-control">
		</div>
	</div>
	<div class="form-group row" *ngIf="invite.originalActivity.clienteleContactCount > 0">
		<label class="col-md-4">
			Clientele Group
			<div class="text-sm text-muted">(Required)</div>
		</label>
		<div class="col-md-8">
			<select placeholder="Select a Clientele Group" [(ngModel)]="dto.clienteleGroupId" class="form-control">
				<option *ngFor="let cg of clienteleGroups" [value]="cg.id">{{cg.title}}</option>
			</select>
		</div>
	</div>
	<div class="form-group row">
		<label class="col-md-4">
			Theme / Project
			<div class="text-sm text-muted">(Required for Dossier)</div>
		</label>
		<div class="col-md-8">
			<select [(ngModel)]="dto.parentId" class="form-control">
				<option *ngFor="let p of parents" [ngValue]="p.fullId">{{ActivityParentType[p.type]}}: {{p.name}}</option>
			</select>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<label class="text-sm">
		<input type="checkbox" [(ngModel)]="editAfterSaving" value="1"> Edit Activity in full after saving 
	</label>
	<button class="btn btn-primary" (click)="save()">
		Save
	</button>
	<button class="btn btn-link" (click)="cancel()">
		Cancel
	</button>
</mat-dialog-actions>