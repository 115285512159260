import { Component, OnInit, Input } from '@angular/core';
import { ActivityService, ClienteleGroup, ClienteleService, DemographicInfo, ClienteleGroupType, Activity, OriginalActivity, DemographicType } from '../../domain';
import { ConsoleService } from '../console.service';

@Component({
	selector: 'clientele-contact-summary',
	templateUrl: './clientele-contact-summary.component.html',
	styleUrls: ['./clientele-contact-summary.component.scss']
})
export class ClienteleContactSummaryComponent implements OnInit {
	@Input() formGroupClass: string = "form-group row";
	@Input() labelClass: string = "col-form-label bold-label col-5";
	@Input() formControlClass: string = "col-7";
	@Input() originalActivity: OriginalActivity;
	demographicInfo: DemographicInfo;
	clienteleGroup: ClienteleGroup = null;

	constructor(
		private clienteleSvc: ClienteleService,
		private console: ConsoleService
	) { }

	ngOnInit() {
		this.console.log("original activity: ", this.originalActivity);

		this.demographicInfo = this.clienteleSvc.getDemographicInfo();		
	}

	applicableDemographicTypes(selectedGroupType) {
		let sgt = <ClienteleGroupType>(selectedGroupType);
		//this.console.log("demographicInfo: ", this.demographicInfo.applicableTypesByClienteleGroupTypeId.get(sgt));
		return this.demographicInfo.applicableTypesByClienteleGroupTypeId.get(sgt);
	}

	typeToolTip(type: string): string {
		switch(type){
			case "Ethnicity": {
				return "Federal guidance for aggregating race and ethnicity: When recording an ethnically Hispanic contact, count them under the 'Race not listed above' race, regardless of any race they may have claimed.";
			}
		}
		
		return "";
	}

	hasClienteleGroup(originalActivity: OriginalActivity) {
		if(!!originalActivity && !!originalActivity.clientele && !!originalActivity.clientele.groupId){
			return true;
		}
		return false;
	}
}
