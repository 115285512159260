import { Component } from '@angular/core';

@Component({
	selector: 'uc-delivers-story-link',
	templateUrl: './uc-delivers-story-link.component.html',
	styleUrls: ['./uc-delivers-story-link.component.scss']
})
export class UcDeliversStoryLinkComponent {
	href: string;
	constructor() {
		this.href="https://ucanr.edu/impact/authorlogin.cfm"; // (UC Delivers home screen)
		/* The following does not work unless the auther has already visited the page above during their session.
		** while it would be NICE to be taken right to the story authorship screen, we'll have to settle for the
		** entry screen instead.  If the below were to work, UC Delivers would need to redirect to the portal 
		** log-in screen instead of displaying the ugly "no authorized" message it currently displays. */
		//
		/** Take user to New UC Delivers Story directly */
		this.href="https://ucanr.edu/blogs/blogcore/page.cfm?pagenum=173"; // (new story screen)
	}
}
