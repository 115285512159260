import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[numericOnly]'
})
export class NumericDirective {
  
    constructor(private el: ElementRef) { }

    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
        const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab', 'Delete'];
        if (allowedKeys.includes(event.key) || this.isNumberKey(event.key)) {
            return; // Allow normal behavior
        } else {
            event.preventDefault(); // Prevent character input
        }
    }

    private isNumberKey(key: string): boolean {
        // Check if it's a number key on the top row or numpad
        return (key >= '0' && key <= '9') || (key >= 'Numpad0' && key <= 'Numpad9'); 
	}
}