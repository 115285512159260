<div class="alert alert-info" *ngIf="hasInstructions()">
	<span [innerHTML]="getInstructions()"></span>	
</div>

<div>
	<div *ngIf="!projects">
		Loading...
	</div>

	<empty-state *ngIf="!!projects && projects.length == 0"
	imgSrc="/assets/empty/project.png"
	title="You don&apos;t have any projects yet"
	description="A Project is a way to organize your Activities.">
		<button class="btn btn-primary btn-sm"
			(click)="newProject()">
			+ Add Project
		</button>
	</empty-state>

	<div *ngIf="!!projects && projects.length > 0">
		<div class="row">
			<div class="col-md-4 align-items-center" style="display:flex;">
				<span class="h5" style="margin-bottom: 0;">Projects</span>
				<button class="btn btn-primary btn-sm" style="margin-left:1em;" (click)="newProject()">
					+ Add Project
				</button>
			</div>

			<div class="col-md-4">
				<div class="icon-addon addon-sm">
					<input type="search" 
						nohotkey
						placeholder="Search Projects" 
						class="form-control form-control-sm"
						[formControl]="searchInput" 
						aria-label="Search Projects" role="search">
					<span class="fa fa-search"></span>
				</div>
			</div>

			<div class="col-md-4 text-right text-sm">
				<div class="btn-group btn-group-sm">
					<button aria-label="View projects as tiles" title="View projects as tiles"
					[class.active]="layout == 'tiles'" class="btn btn-secondary"
					(click)="layout = 'tiles'">
						<i class="fa fa-fw fa-th-large"></i>
					</button>

					<button aria-label="View projects in a list" title="View projects in a list"
					[class.active]="layout == 'list'" class="btn btn-secondary"
					(click)="layout = 'list'">
						<i class="fa fa-fw fa-th-list"></i>
					</button>
				</div>
			</div>
		</div>

		<br>

		<div *ngIf="search(projects || [], searchTerm).length == 0"
			id="no-projects-found"
			class="text-center text-muted"
			style="padding:1rem; font-size:1rem;">
				<i class="fa fa-fw fa-search"></i>
				No Projects Found
		</div>

		<div [ngSwitch]="layout">
			<table *ngSwitchCase="'list'" class="table table-white table-hover">
				<thead>
					<tr>
						<th>
							<sort-column-header
								[column]="sortColumns.name"
								[(currentSorting)]="currentSorting"></sort-column-header>
						</th>
						<th style="width:8em;">
							<sort-column-header
								[column]="sortColumns.startDate"
								[(currentSorting)]="currentSorting"></sort-column-header>
						</th>
						<th>
							<sort-column-header
								[column]="sortColumns.theme"
								[(currentSorting)]="currentSorting"></sort-column-header>
						</th>
						<th>
							<sort-column-header
								[column]="sortColumns.programArea"
								[(currentSorting)]="currentSorting"></sort-column-header>
						</th>
						<th  *ngIf="showActivities" style="width:9em;" class="text-center">
							<sort-column-header
								[column]="sortColumns.activityCount"
								[(currentSorting)]="currentSorting"></sort-column-header>
						</th>
						<th style="width:9em;">
							<sort-column-header
								[column]="sortColumns.lastUpdated"
								[(currentSorting)]="currentSorting"></sort-column-header>
						</th>
					</tr>
				</thead>
				<tbody class="text-sm">
					<tr *ngFor="let project of search(projects, searchTerm)"
					(click)="gotoDetail(project)"
					class="project-row">
						<td>
							<keyword-highlight
								[keyword]="searchTerm"
								[text]="project.name"></keyword-highlight>
						</td>
						<td>
							{{project.startDate | date}}
						</td>
						<td [ngSwitch]="!!project.theme.value">
							<div *ngSwitchCase="true">
								<keyword-highlight
									[keyword]="searchTerm"
									[text]="project.theme.value.name"></keyword-highlight>
							</div>
							<div *ngSwitchCase="false" class="text-muted">
								-
							</div>
						</td>
						<td>
							<keyword-highlight
								[keyword]="searchTerm"
								[text]="project.programArea.name"></keyword-highlight>
							<div *ngIf="project.programArea.disabled" class="text-xs text-nowrap">(No longer active)</div>
						</td>
						<td  *ngIf="showActivities" class="text-center">
							{{project.activityCount}}
						</td>
						<td [ngSwitch]="!!project.lastUpdated">
							<span *ngSwitchCase="true">
								{{project.lastUpdated | date}}
							</span>
							<span *ngSwitchCase="false" class="text-muted">N/A</span>
						</td>
					</tr>
				</tbody>
			</table>

			<div *ngSwitchCase="'tiles'">
				<div class="mb-3 d-flex justify-content-center">
					<form novalidate class="form-inline">
						<label class="mr-2">
							Sort order:
						</label>
						<div class="form-group mr-2">
							<select class="form-control form-control-sm form-control-original"
							(change)="onSortColumnChange(ss.value)" #ss>
								<option *ngFor="let c of Object.values(sortColumns)" [value]="c.id"
								[selected]="c.id == currentSorting.column.id">
									{{c.label}}
								</option>
							</select>
						</div>
						<div class="form-group">
							<div class="btn-group btn-group-sm">
								<button *ngFor="let dir of directions" title="Sort {{dir}}"
								[class.active]="currentSorting.direction == dir" class="btn btn-secondary"
								(click)="withDirection(dir)">
									<sort-icon [icon]="currentSorting.column.icon" [direction]="Direction[dir]"></sort-icon>
								</button>
							</div>
						</div>
					</form>
				</div>

				<div class="row">
					<div class="col-md-4 mb-3" *ngFor="let project of search(projects, searchTerm)">
						<!-- Removed project detail per PROJB-253 -->
						<!-- <div class="card project-card" (click)="gotoDetail(project)"> -->
						<div class="card project-card">	
							<div class="card-header">
								<button (click)="editProject(project)"
									class="btn btn-sm btn-link pull-right">
										<i class="fa fa-fw fa-pencil"></i>
										Edit
								</button>
								<div class="text-muted text-xs">
									{{project.startDate | date}}
								</div>
								<div class="font-weight-semi-bold">
									<keyword-highlight
										[keyword]="searchTerm"
										[text]="project.name"></keyword-highlight>
								</div>
							</div>
							<div class="card-block">
								<div class="card-text">
									<div class="mb-3">
										<div class="project-label">Theme</div>
										<div [ngSwitch]="!!project.theme.value">
											<div *ngSwitchCase="true">
												{{project.theme.value.name}}
											</div>
											<div *ngSwitchCase="false" class="text-muted" [matTooltip]="themeToolTip[meritAndPromotionHR]">
												<i class="fa fa-warning text-muted"></i>
												None
											</div>
										</div>
									</div>
									<div class="mb-3">
										<div class="project-label">Program Area</div>
										<keyword-highlight
											[keyword]="searchTerm"
											[text]="project.programArea.name"></keyword-highlight>
										<div *ngIf="project.programArea.disabled" class="text-xs">(No longer active)</div>
									</div>
									<div class="mb-3">
										<div class="project-label">Role</div>
										<div [ngSwitch]="!!project.role">
											<div *ngSwitchCase="true">
												{{project.role}}
											</div>
											<div *ngSwitchCase="false" class="text-muted" [matTooltip]="rolesToolTip[meritAndPromotionHR]">
												<i class="fa fa-warning text-muted"></i>
												None
											</div>
										</div>
									</div>
									<div class="mb-3">
										<div class="project-label">
											Tags ({{project.tags.length}})
										</div>
										<div [hidden]="!project.tags.length" class="mt-1">
											<span *ngFor="let tag of project.tags">
												<tag [value]="tag" [keyword]="searchTerm"></tag>
											</span>
										</div>
									</div>
									<div>
										<div class="project-label">
											Collaborators (ANR: {{project.ANRCollaborators.length}}, Non-ANR: {{project.nonANRCollaborators.length}})
										</div>
										<div [hidden]="!project.ANRCollaborators.length" class="mt-1">
											<span *ngFor="let c of project.ANRCollaborators">
												<img appUserAvatar="{{c.id}}" [userName]="c.firstName + ' ' + c.lastName"
													style="width: 40px;"
													class="img-round img-fluid user-avatar-sm mr-2">
											</span>
										</div>
									</div>
								</div>
							</div>
							<div class="card-footer">
								<div class="text-sm" *ngIf="showActivities">
									Activities:
									<span class="font-weight-semi-bold">{{project.activityCount}}</span>
								</div>
								<div class="text-sm">
									Last Edited:
									<span [ngSwitch]="!!project.lastUpdated">
										<span *ngSwitchCase="true" class="font-weight-semi-bold">{{project.lastUpdated | date}}</span>
										<span *ngSwitchCase="false" class="text-muted">N/A</span>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
