
import {map} from 'rxjs/operators';
import { Component, OnInit, Injectable, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
  message = "";
  constructor(
    private readonly dialogRef: MatDialogRef<ConfirmModalComponent>,
		@Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.message = data.message || "Are you sure?";
  }
}

@Injectable()
export class ConfirmModalService {
  constructor(private readonly md: MatDialog) { }

  open(message: string): Observable<boolean> {
    return this.md.open(ConfirmModalComponent,{
      data: {
        message
      }
    })
    .afterClosed().pipe(
    map(result => !!result));
  }
}