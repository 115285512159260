import { ActivitySubTypeId } from '../domain';
import { AppSettings } from '../app-settings';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HttpHandler, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes, Router } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { WidgetsModule } from './../widgets/widgets.module';
// Components
import { AppComponent } from './app.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { NonANRCollaboratorModalComponent } from '../widgets/non-anr-collaborator-modal/non-anr-collaborator-modal.component';
import { ActivitiesComponent } from './activities/activities.component';
import { ActivityEditorComponent } from './activity-editor/activity-editor.component';
import { ActivityEditorModalComponent } from './activity-editor-modal/activity-editor-modal.component';
import { AcademicComponent } from './academic/academic.component';
import { AuthGuardService } from './auth-guard.service';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { NewActivityModalComponent } from './new-activity-modal/new-activity-modal.component';
import { ProjectModalComponent } from './project-modal/project-modal.component';
import { ClienteleComponent } from './clientele/clientele.component';
import { ThemesComponent } from './themes/themes.component';
import { FteComponent } from './fte/fte.component';
import { ClienteleGroupModalComponent } from './clientele-group-modal/clientele-group-modal.component';
import { CountyFteModalComponent, MultiStateProjectModalComponent, ConditionChangeFteModalComponent }  from './fte/modals/';
import * as Shims from '../shims';
import { NewThemeModalComponent } from './new-theme-modal/new-theme-modal.component';
import { ReviewAndCompileComponent } from './review-and-compile/review-and-compile.component';
import { ThemesComponent as ReviewAndCompileThemesComponent } from './review-and-compile/themes/themes.component';
import { ActivitiesComponent as ReviewAndCompileActivitiesComponent } from './review-and-compile/activities/activities.component';
import { UcDeliversStoryLinkComponent } from './uc-delivers-story-link/uc-delivers-story-link.component';
import { ConsoleService } from '../widgets/console.service';
import * as D from '../domain';
import { UserPreferenceComponent } from './user-preference/user-preference.component';
import { ActivityTypeMenuModalComponent } from '../widgets/activity-type-menu-modal/activity-type-menu-modal.component';
import { ActivityModalService } from './activity-modal.service';
import { ProfileComponent } from './profile/profile.component';
import { AcademicProfessionalAssociationComponent } from './academic-professional-association/academic-professional-association.component';
import { ExploreWhatsHappeningComponent } from './explore-whats-happening/explore-whats-happening.component';
import { UserRolesSwitchComponent } from './user-roles-switch/user-roles-switch.component';
import { AHRComponent } from './ahr/ahr.component';
import { ActivityCollaborationConfirmationModalComponent } from './activity-collaboration-confirmation-modal/activity-collaboration-confirmation-modal.component';
import { CRCComponent } from './crc/crc.component';
import { AHROnboardingModalComponent } from './ahr-onboarding-modal/ahr-onboarding-modal.component';
import { ProjectModalService } from './project-modal.service';
import { SectionHeaderComponent } from './section-header/section-header.component';
import { DefaultLoggedInUserComponent } from './default-logged-in-user/default-logged-in-user.component';
import { AuthenticationModalComponent } from './authentication-modal/authentication-modal.component';
import { CaseModalComponent } from './ahr/case-modal/case-modal.component';
import { CaseListComponent, CaseStatePipe } from './ahr/case-list/case-list.component';
import { ActionTypeNamePipe } from './ahr/action-type-name.pipe';
import { TitleRankStepTermPipe } from './ahr/title-rank-step-term.pipe';
import { NewsStoryListComponent } from './ahr/news-story-list/news-story-list.component';
import { AHRWrapperComponent } from './ahr/ahr-wrapper/ahr-wrapper.component';
import { PositionFilterComponent } from './ahr/position-filter/position-filter.component';
import { CommitteesComponent } from './ahr/committees/committees.component';
import { SelectANRPersonModalComponent } from './select-anrperson-modal/select-anrperson-modal.component';
import { AcademicCaseListComponent } from './ahr/academic-case-list/academic-case-list.component';
import { AcademicCaseDetailComponent } from './ahr/academic-case-detail/academic-case-detail.component';
import { ProgressionTableWorkshopModalComponent } from './ahr/progression-table-workshop-modal/progression-table-workshop-modal.component';
import { ReviewRequirementsWorkshopModalComponent } from './ahr/review-requirements-workshop-modal/review-requirements-workshop-modal.component';
import { ProgressionTableComponent } from './ahr/progression-table/progression-table.component';
import { AdvancementSelectorModalComponent } from './ahr/advancement-selector-modal/advancement-selector-modal.component';
import { NewAcademicModalComponent } from './ahr/new-academic-modal/new-academic-modal.component';
import { HtmlContentModalComponent } from './html-content-modal/html-content-modal.component';
import { AcademicReportSubmissionComponent } from './academic-report-submission/academic-report-submission.component';
import { PdfviewerModalComponent } from './pdfviewer-modal/pdfviewer-modal.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { AHRSettingsComponent } from './ahr-settings/ahr-settings.component';
import { TimeServedTableComponent } from './ahr/time-served-table/time-served-table.component';
import { ReviewerDashboardComponent } from './program-review/reviewer-dashboard/reviewer-dashboard.component';
import { ReviewerCaseDetailComponent } from './program-review/reviewer-case-detail/reviewer-case-detail.component';
import { CaseProgressBarComponent } from './program-review/case-progress-bar/case-progress-bar.component';
import { CaseFilesComponent } from './program-review/case-files/case-files.component';
import { ReviewerCaseListComponent, ReviewerCaseNotSelected } from './program-review/reviewer-case-list/reviewer-case-list.component';
import { GoogleAnalyticsService } from './google-analytics.service';
import { TooltipService } from '../widgets/tooltip.service';
import { TermTableComponent } from './ahr/term-table/term-table.component';
import { ProposedActionComponent } from './ahr/proposed-action/proposed-action.component';

import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { BrowserCacheLocation, Configuration, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication  } from '@azure/msal-browser';
import { SpoofUserComponent } from './admin/spoof-user/spoof-user.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

Shims.ObjectKeysShim();
Shims.ObjectValuesShim();

export const APP_INITIALIZER_DEPS = [
	AppSettings,
	D.AppVersionService,
	D.ActivityService,
	D.ClienteleService,
	D.FTEService,
	D.AHRService,
	ConsoleService,
	TooltipService
];

export function APP_INITIALIZER_FACTORY(
	appSettings: AppSettings,
	appVersionSvc: D.AppVersionService,
	activitySvc: D.ActivityService,
	clienteleSvc: D.ClienteleService,
	fteSvc: D.FTEService,
	ahrService: D.AHRService,
	consoleService: ConsoleService,
	tooltipSevice: TooltipService
){
	return () => {
		consoleSVC = consoleService;
		console.log('APP_INITIALIZER started...');
		return appSettings.init()
			.then(_ => 
				Promise.all([
					appVersionSvc.config(),
					activitySvc.config(),
					clienteleSvc.config(),
					fteSvc.config(),
					ahrService.config()
				]))
			.then(
				_ => {

					msalConfig.auth.clientId = appSettings.WFAClientId;
					msalConfig.auth.authority = appSettings.WFAAuthority + appSettings.WFATenantId;
					msalConfig.auth.redirectUri = appSettings.WFARedirectUri;

					tooltipSevice.init();
					consoleService.init(appSettings.enableConsoleLogging);

					consoleSVC = consoleService;
					console.log('...APP_INITIALIZER finished.');
				},
				err => {
					console.error('...APP_INITIALIZER FAILED', err);
					alert('Something went wrong.\nAttempting to re-initialize by reloading your browser window...')
					window.location.reload();
				});
	};
}

let msalConfig: any = {
	auth: {
		clientId: '', // This is your Application ID
		authority: '', // 'Cloud_instance_ID'/'Tenant_Info' this is your tenant ID
		redirectUri: '',// This is your redirect URI
	},
	cache: {
		cacheLocation: 'localStorage',
		storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
	}
};

let consoleSVC: ConsoleService;

// msal configuration example from: https://stackblitz.com/edit/angular-ivy-mek7p9?file=src%2Fapp%2Fapp.module.ts

export function MSALInstanceFactory(): IPublicClientApplication {
	consoleSVC.log('initializing MSALInstanceFactory instance');
	consoleSVC.log('msalconfig: ', msalConfig);
	return new PublicClientApplication({
		auth: msalConfig.auth,
		cache: msalConfig.cache,
		/*
		system: {
			loggerOptions: {
			loggerCallback,
			logLevel: LogLevel.Info,
			piiLoggingEnabled: false
			}
		}*/
	});
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	consoleSVC.log('initializing MSALInterceptorConfigFactory instance');
	const protectedResourceMap = new Map<string, Array<string>>();
  	protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
	protectedResourceMap.set('api://db5009ab-297d-4e1b-bb49-52eef780148d/api_user_access', ['api://api_user_access']);
	consoleSVC.log(protectedResourceMap);

	return {
		interactionType: InteractionType.Popup,
		protectedResourceMap: protectedResourceMap
	};
}
  
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	consoleSVC.log('initializing MSALGuardConfigFactory instance');
	return {
		interactionType: InteractionType.Popup,
		authRequest: {
			scopes: ['user.read']
		},
		loginFailedRoute: '/login-failed'
	};
}

export const routes: Routes = [
	{
		path: '',
		component: UserRolesSwitchComponent,
		canActivate: [AuthGuardService],
		children: [
			{
				path: 'ahr',
				component: AHRWrapperComponent,
				canActivate: [AuthGuardService],
				children: [
					{
						path: '',
						redirectTo: 'cases',
						pathMatch: 'full'
					},
					{
						path: 'cases',
						component: CaseListComponent
					},
					{
						path: 'committees',
						component: CommitteesComponent
					},
					{
						path: 'news',
						component: NewsStoryListComponent
					},
					{
						path: 'settings',
						component: AHRSettingsComponent
					}
				]
			},
			{
				path: 'ahr_old',
				component: AHRComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'program-review/:yearId',
				component: AcademicCaseListComponent,
				children: [
					{
						path: ':yearId',
						component: AcademicCaseDetailComponent
					}
				]
			},
			{
				path: 'program-review',
				component: AcademicCaseListComponent,
				children: [
					{
						path: ':yearId',
						component: AcademicCaseDetailComponent
					}
				]
			},
			{
				path: 'review',
				component: ReviewerDashboardComponent,
				data: {autoSelectFirstCase: false},
				children: [
					{
						path: ':academicId',
						component: ReviewerCaseDetailComponent,
					},
					{
						path: '',
						component: ReviewerCaseNotSelected
					}
				]
			},
			{
				path: 'admin-proxy',
				component: SpoofUserComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'academic',
				component: AcademicComponent,
				canActivate: [AuthGuardService],
				children: [
					{
						path: '',
						redirectTo: 'projects',
						pathMatch: 'full'
					},
					{
						path: 'fte',
						component: FteComponent
					},
					{
						path: 'fte/project/:editProjectId',
						component: FteComponent
					},
					{
						path: 'clientele',
						component: ClienteleComponent
					},
					{
						path: 'themes',
						component: ThemesComponent
					},
					{
						path: 'explore',
						component: ExploreWhatsHappeningComponent
					},
					{
						path: 'review-and-compile',
						component: ReviewAndCompileComponent,
						children: [
							{
								path: '',
								redirectTo: 'themes',
								pathMatch: 'full'
							},
							{
								path: 'themes',
								component: ReviewAndCompileThemesComponent
							},
							{
								path: 'university-service',
								component: ReviewAndCompileActivitiesComponent,
								data: { activitySubTypeId: ActivitySubTypeId.UniversityService }
							},
							{
								path: 'public-service',
								component: ReviewAndCompileActivitiesComponent,
								data: { activitySubTypeId: ActivitySubTypeId.PublicService }
							},
							{
								path: 'professional-development',
								component: ReviewAndCompileActivitiesComponent,
								data: { activitySubTypeId: ActivitySubTypeId.ProfessionalDevelopmentAndTraining }
							},
							{
								path: 'professional-competences',
								component: ReviewAndCompileActivitiesComponent,
								data: { activitySubTypeId: ActivitySubTypeId.EvidenceOfProfessionalCompetence }
							}
						]
					},
					{
						path: 'preferences',
						component: UserPreferenceComponent
					},
					{
						path: 'profile',
						component: ProfileComponent
					},
					{
						path: 'projects',
						component: ProjectListComponent
					},
					{
						path: 'projects/:id',
						component: ProjectDetailComponent
					},
					{
						path: 'activities',
						component: ActivitiesComponent
					},
					{
						path: 'activities/:id',
						component: ActivitiesComponent
					}
				]
			},
			{
				path: 'crc',
				component: CRCComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'explore',
				component: DefaultLoggedInUserComponent,
				canActivate: [AuthGuardService]
			}
			
		]
	},
];

@NgModule({
	bootstrap: [MsalRedirectComponent],
	exports: [
		WidgetsModule,
		CountyFteModalComponent,
		ConditionChangeFteModalComponent,
		ActionTypeNamePipe,
		TitleRankStepTermPipe,
	],
	imports: [
		CommonModule,
		FormsModule,
		WidgetsModule,
		BrowserModule,
		HttpClientModule,
		ReactiveFormsModule,
		MatButtonToggleModule,
		MatExpansionModule,
		RouterModule.forRoot(routes, {useHash: false}),
		MsalModule,
	],
	declarations: [
		AppComponent,
		ProjectListComponent,
		ProjectDetailComponent,
		ActivitiesComponent,
		ActivityEditorComponent,
		ActivityEditorModalComponent,
		AcademicComponent,
		AHRComponent,
		AuthenticationComponent,
		AuthenticationModalComponent,
		CRCComponent,
		UserMenuComponent,
		NewActivityModalComponent,
		ProjectModalComponent,
		ClienteleComponent,
		ThemesComponent,
		FteComponent,
		ClienteleGroupModalComponent,
		CountyFteModalComponent,
		ConditionChangeFteModalComponent,
		NewThemeModalComponent,
		ReviewAndCompileComponent,
		ReviewAndCompileActivitiesComponent,
		ReviewAndCompileThemesComponent,
		UcDeliversStoryLinkComponent,
		UserPreferenceComponent,
		ProfileComponent,
		AcademicProfessionalAssociationComponent,
		ExploreWhatsHappeningComponent,
		UserRolesSwitchComponent,
		ActivityCollaborationConfirmationModalComponent,
		CRCComponent,
		AHROnboardingModalComponent,
		MultiStateProjectModalComponent,
		SectionHeaderComponent,
		DefaultLoggedInUserComponent,
		CaseModalComponent,
		CaseListComponent,
		ActionTypeNamePipe,
		TitleRankStepTermPipe,
		NewsStoryListComponent,
		AHRWrapperComponent,
		PositionFilterComponent,
		CommitteesComponent,
		SelectANRPersonModalComponent,
		CaseStatePipe,
		AcademicCaseListComponent,
		AcademicCaseDetailComponent,
		ProgressionTableWorkshopModalComponent,
		ReviewRequirementsWorkshopModalComponent,
		ProgressionTableComponent,
		AdvancementSelectorModalComponent,
		NewAcademicModalComponent,
		HtmlContentModalComponent,
		AcademicReportSubmissionComponent,
		PdfviewerModalComponent,
		AHRSettingsComponent,
		TimeServedTableComponent,
		ReviewerDashboardComponent,
		ReviewerCaseDetailComponent,
		CaseProgressBarComponent,
		CaseFilesComponent,
		ReviewerCaseListComponent,
		ReviewerCaseNotSelected,
		TermTableComponent,
		ProposedActionComponent,
  		SpoofUserComponent
	],
	entryComponents: [
		ActivityCollaborationConfirmationModalComponent,
		ActivityEditorModalComponent,
		ActivityTypeMenuModalComponent,
		AHROnboardingModalComponent,
		AuthenticationModalComponent,
		NewAcademicModalComponent,
		NewActivityModalComponent,
		NewThemeModalComponent,
		ProjectModalComponent,
		ClienteleGroupModalComponent,
		CountyFteModalComponent,
		MultiStateProjectModalComponent,
		ConditionChangeFteModalComponent,
		NonANRCollaboratorModalComponent,
		SelectANRPersonModalComponent,
		CaseModalComponent,
		ProgressionTableWorkshopModalComponent,
		ReviewRequirementsWorkshopModalComponent,
		AdvancementSelectorModalComponent,
		HtmlContentModalComponent,
		PdfviewerModalComponent,
		ReviewerDashboardComponent,
	],
	providers: [
		ActivityModalService,
		AuthGuardService,
		AppSettings,
		GoogleAnalyticsService,
		ConsoleService,
		ProjectModalService,
		ActionTypeNamePipe,
		TitleRankStepTermPipe,
		TooltipService,
		/*
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true
		},*/
		{
			/* This block allows us to pre-load server configuration
			** before the application actually starts.
			** For reference:  https://stackoverflow.com/q/39033835/943730
			**/
			provide: APP_INITIALIZER,
			useFactory: APP_INITIALIZER_FACTORY,
			deps: APP_INITIALIZER_DEPS,
			multi: true
		},
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory
		},
		/*{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory
		},*/
		
		/*{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory
		},*/

		
		
		MsalService,
		//MsalGuard,
		MsalBroadcastService,
	]
})
export class MainCoreModule { }