import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'html-content-modal',
	templateUrl: './html-content-modal.component.html',
	styleUrls: ['./html-content-modal.component.scss']
})
export class HtmlContentModalComponent implements OnInit {
	html = "";
	title = "";
	wrapperClasses = "";
	constructor(
		private readonly dialogRef: MatDialogRef<HtmlContentModalComponent>,
		@Inject(MAT_DIALOG_DATA) data: any
	) {
		this.html = data.html;
		this.title = data.title;
		this.wrapperClasses = data.wrapperClasses;
	}

	ngOnInit() {
	}

	public static open(md: MatDialog, title: string, html: string, width: string = '800px', wrapperClasses: string = ''): MatDialogRef<HtmlContentModalComponent> {
		return md.open(HtmlContentModalComponent, {
			width,
			data: {
				title,
				html,
				wrapperClasses
			}
		});
	}

	public close() {
		
	}
}