<div mat-dialog-title>
	{{title}}
</div>
<mat-dialog-content>
	<form [formGroup]="form" novalidate>
		<div class="{{formGroupClass}}">
			<label class="{{labelClass}}" for="project-modal-name">Name</label>
			<div class="{{formControlClass}}">
				<textarea autoGrow
					class="form-control form-control-for-name"
					formControlName="name"
					id="project-modal-name"
					placeholder="Project Name"
					aria-label="Name"></textarea>
			</div>
		</div>

		<div class="{{formGroupClass}}">
			<label class="{{labelClass}}" [matTooltip]="themeToolTip[meritAndPromotionHR]">
				Theme
				<div class="{{labelAnnotationClass}} text-xs">
					<i class="fa fa-fw fa-info-circle"></i>
					{{requiredText[meritAndPromotionHR]}}
				</div>
			</label>
			<div class="{{formControlClass}}" [matTooltip]="selectedTheme" [matTooltipShowDelay]="500">
				<select formControlName="themeId" class="form-control">
					<option *ngFor="let t of themes" [ngValue]="t.id">
						{{t.name}}
					</option>
				</select>
			</div>
		</div>

		<div class="{{formGroupClass}}">
			<label class="{{labelClass}}">Start Date</label>
			<div class="{{formControlClass}} input-group input-group-sm">
				<input [matDatepicker]="picker" placeholder="mm/dd/yyyy" class="form-control form-control-sm"
					formControlName="startDate">
				<mat-datepicker #picker startView="year" [touchUi]="touchEnabled"></mat-datepicker>
				<mat-datepicker-toggle [for]="picker" class="input-group-addon"></mat-datepicker-toggle>
			</div>
		</div>

		<div class="{{formGroupClass}}">
			<label class="{{labelClass}}" [matTooltip]="StaticText.ProgramAreaToolTip" for="project-modal-pa">
				Program Area 
				<div class="{{labelAnnotationClass}} text-xs">
					<i class="fa fa-fw fa-info-circle"></i>
					(Required)
				</div>
			</label>
			<div class="{{formControlClass}}">
				<select formControlName="programAreaId" class="form-control" id="project-modal-pa">
					<option *ngFor="let pa of programAreas" [ngValue]="pa.id" [disabled]="pa.disabled" [class]="programAreaClass(pa.disabled)">
						{{pa.name}} <span *ngIf="pa.disabled" class="text-xs">(No longer active)</span>
					</option>
				</select>
			</div>
		</div>

		<div class="{{formGroupClass}}">
			<label class="{{labelClass}}">
				<span [matTooltip]="descriptionToolTip[meritAndPromotionHR]">
					Description
					<div class="{{labelAnnotationClass}} text-xs">
						<i class="fa fa-fw fa-info-circle"></i>
						(Optional)
					</div>
				</span>
				
			</label>
			<div class="{{formControlClass}}">
				<textboxio formControlName="description"></textboxio>
			</div>
		</div>

		<div class="{{formGroupClass}}">
			<label class="{{labelClass}}" [matTooltip]="rolesToolTip[meritAndPromotionHR]" for="project-modal-pa">
				Role(s)
				<div class="{{labelAnnotationClass}} text-xs">
					<i class="fa fa-fw fa-info-circle"></i>
					{{requiredText[meritAndPromotionHR]}}
				</div>
			</label>
			<div class="{{formControlClass}}">
				<textarea autoGrow class="form-control form-control-sm form-control-border-bottom-only"
					formControlName="role"
					placeholder="Project Role" aria-label="Name"></textarea>
			</div>
		</div>		

		<div class="{{formGroupClass}}">
			<label class="{{labelClass}}">
				Tags
				<div class="{{labelAnnotationClass}} text-xs">
					<span class="text-muted">({{form.value.tags.count}})</span>
				</div>
			</label>
			<div class="{{formControlClass}}">
				<tag-picker
					formControlName="tags"
					[tagTypes]="availableTagTypes"
					controlClass="form-control-sm"></tag-picker>
			</div>
		</div>

		<div class="{{formGroupClass}}">
			<label class="{{labelClass}}">
				ANR Collaborators
				<div class="{{labelAnnotationClass}} text-xs">
					<span class="text-muted">({{form.value.ANRCollaborators.count}})</span>
				</div>
			</label>
			<div class="{{formControlClass}}">
				<user-picker
					formControlName="ANRCollaborators"
					enableNonANRCollaborator="true"
					(newNonAnrCollaboratorRequest)="nonANRCollaborators.create($event)"
					controlClass="form-control-sm"></user-picker>
			</div>
		</div>

		<div class="{{formGroupClass}}">

			<button class="btn btn-sm btn-secondary pull-right" (click)="nonANRCollaborators.create()" *ngIf="!wideLayout">
				+ Add New
			</button>
			<label class="{{labelClass}}">
				Non-ANR Collaborators
				<div class="{{labelAnnotationClass}} text-xs">
					<span class="text-muted">({{nonANRCollaboratorCount}})</span>
				</div>
			</label>
			<div class="{{formControlClass}}">
				<non-anr-collaborator-form-control 
					formControlName="nonANRCollaborators"
					#nonANRCollaborators="NonANRCollaboratorFormControl"></non-anr-collaborator-form-control>
				<button class="btn btn-sm btn-secondary pull-right" (click)="nonANRCollaborators.create()" *ngIf="wideLayout">
					+ Add New
				</button>
				<div style="clear:both;"></div>
			</div>
		</div>
		
		<div class="{{formGroupClass}}">
			<label class="{{labelClass}}" [matTooltip]="StaticText.ProjectSupportAmountDurationToolTip">
				Support Amount/Duration
				<div class="{{labelAnnotationClass}} text-xs">
					<i class="fa fa-fw fa-info-circle"></i>
					{{requiredText[meritAndPromotionHR]}}
				</div>				
			</label>
			<div class="{{formControlClass}}">
				<textarea autoGrow class="form-control form-control-sm form-control-border-bottom-only"
					formControlName="supportAmountDuration"
					placeholder="Support Amount/Duration"></textarea>
			</div>			
		</div>
		<div class="{{formGroupClass}}">
			<label class="{{labelClass}}">
				Support Source
				<div class="{{labelAnnotationClass}} text-xs">
					<i class="fa fa-fw fa-info-circle"></i>
					{{requiredText[meritAndPromotionHR]}}
				</div>				
			</label>
			<div class="{{formControlClass}}">
				<textarea autoGrow class="form-control form-control-sm form-control-border-bottom-only"
					formControlName="supportSource"
					placeholder="Support Source"></textarea>
			</div>				
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<button (click)="archive()" 
		class="btn btn-link text-danger float-left" 
		[title]="StaticText.ArchiveProject"
		*ngIf="canArchive()">
		<i class="fa fa-fw fa-archive"></i>
		Archive (Delete)
	</button>
	<button (click)="cancel()" class="btn btn-link" [disabled]="closing">
		Cancel
	</button>
	<button (click)="save()" class="btn btn-primary" [disabled]="closing">
		Save
	</button>
</mat-dialog-actions>
