<div class="container">
	<form [formGroup]="form" class="float-right">
		<div [ngSwitch]="predicates.length" class="text-muted text-sm">
			<div *ngSwitchCase="0">
				Showing all
			</div>
			<div *ngSwitchDefault>
				Only showing news 

				<span *ngFor="let p of predicates; first as first">
					{{p.includeWord}} 
					<strong>{{p.getValueLabel()}}</strong><span *ngIf="!first && predicates.length > 1">,</span>
				</span>

				<button class="btn btn-sm btn-link ml-3" (click)="showAllStories()">
					Show all
				</button>
			</div>
		</div>
	</form>

	<h5 class="mb-3">
		Recent happenings in Program Review
	</h5>

	<div class="list-group text-sm">
		<div class="list-group-item" *ngFor="let story of stories">
			<span *ngFor="let e of story.elements" [ngSwitch]="e.case">
				<ng-container *ngSwitchCase="'By'">
					<a
						class="href"
						(click)="selectAllStoriesBy(e.user)">{{e.user.name}}</a>

					<span *ngIf="!!e.onBehalfOf.value">
						(on behalf of 
						<a
							class="href"
							(click)="selectAllStoriesOnBehalfOf(e.onBehalfOf.value)">{{e.onBehalfOf.value}}</a>)
					</span>
				</ng-container>

				<span *ngSwitchCase="'Text'">
					{{e.text}}
				</span>

				<a *ngSwitchCase="'Academic'"
					class="href"
					(click)="viewDetails(e.academicId)">
					{{e.academic.name}}
				</a>

				<span *ngSwitchCase="'Supervisor'">
					{{e.supervisor.name}}
				</span>
			</span>
			<div class="float-right">
				<span class="text-muted" [title]="story.timestamp | date : 'EEEE, MMMM d yyyy @ h:mm:ss a'">
					{{story.timestamp | timeAgo}}
				</span>
			</div>
		</div>
	</div>
</div>