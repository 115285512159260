import { Injectable } from '@angular/core';
import { AppVersionService as SVC } from '../domain';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../app-settings';
declare function require(moduleName: string): any;
const { version: _ui } = require('../../../package.json');

@Injectable()
export class AppVersionService implements SVC {
	private _server = '0.0.0.0';
	constructor(
		private appSettings: AppSettings,
		private http: HttpClient
	){ }

	public config(): Promise<null> { 
		return this.http.get<any>(this.appSettings.APIURL + '/version')
			.toPromise()
			.then(data => {
				this._server = data.version;
				return null;
			});
   	}
	public get uiVersion() { return _ui; }
	public get serverVersion(){ return this._server; }
}
