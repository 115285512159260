<ng-container *ngIf="countiesInitialized">
	<div [formGroup]="form">
		<input type="hidden" formControlName="statewide">
		<div class="btn-group">
			<button class="btn btn-sm" [ngClass]="isStatewideSelected() ? 'btn-primary': 'btn-secondary'" (click)="selectStatewide()">Statewide</button>
			<button class="btn btn-sm" [ngClass]="isCountiesSelected() ? 'btn-primary': 'btn-secondary'" (click)="selectCounties()">Counties</button>
		</div>
		<ng-container *ngIf="isCountiesSelected()">
			<div formGroupName="countyOptions">
				<div *ngFor="let c of counties">
					<label>
						<input type="checkbox" [formControlName]="c.id"> {{c.name}}
					</label>
				</div>
			</div>
		</ng-container>
	</div>
</ng-container>