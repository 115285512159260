<mat-form-field>
	<div class="input-group" [ngClass]="inputGroupClass">
		<input type="text"
			#input
			matInput
			class="form-control"
			[ngClass]="controlClass"
			placeholder="Search Projects and Themes"
			[matAutocomplete]="auto"
			[formControl]="projectThemeSearchControl"
			aria-label="Search Projects and Themes" role="search">
		<div class="input-group-addon" (click)="clear()" [hidden]="!selectedParent">
			<i class="fa fa-times" style="cursor: pointer;"></i>
		</div>
	</div>
</mat-form-field>


<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith">
	<!--<mat-option [value]=null>None</mat-option>-->
	<ng-container *ngFor="let p of parents">
		<mat-option [value]="p" [matTooltip]="p.name">
				{{p.name}}
				<small class="text-muted">({{ActivityParentType[p.type]}})</small>
		</mat-option>
	</ng-container>
</mat-autocomplete>