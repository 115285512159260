<div class="wrapper">

	<div class="alert alert-info" *ngIf="hasInstructions()">
		<span [innerHTML]="getInstructions()"></span>
	</div>

	<ul class="nav nav-tabs mb-4">
		<li class="nav-item" *ngFor="let link of navLinks">
			<a [routerLink]="link.url"
			class="nav-link"
			[class.active]="urlIsActive(link.url)">
				{{link.label}}
			</a>
		</li>
	</ul>

	<div class="row">
		<div class="col-md-9">
			<div class="alert alert-warning">
				Don't forget to select the items you wish to export!
			</div>
			<router-outlet></router-outlet>
		</div>
	
		<div class="col-md-3" style="margin-top:-1em;">
			<div style="position: sticky; top:0; padding-top:1em;">
				<div class="btn-group mb-3" *ngIf="canExpand()">
					<button (click)="expandAll(true)" class="btn btn-secondary btn-sm">
						Expand All
					</button>
					<button (click)="expandAll(false)" class="btn btn-secondary btn-sm">
						Collapse All
					</button>
					<button (click)="selectAll(true)" class="btn btn-secondary btn-sm">
						Select All
					</button>
				</div>
				<!--
				<label class="checkbox" *ngIf="canTrim()">
					<input type="checkbox" 
						[(ngModel)]="trim"
						(change)="trimChanged()">
					De-select unreachable items
				</label>
				-->
				<table class="table table-bordered text-sm">
					<thead>
						<tr>
							<th colspan="2">
								Selected Dossier Items
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th style="width:60%;">Themes</th>
							<td>{{dto(trim).themeIds.length}}</td>
						</tr>
						<tr>
							<th>Projects</th>
							<td>{{dto(trim).projectIds.length}}</td>
						</tr>
						<tr>
							<th>Activities</th>
							<td>{{dto(trim).activityIds.length}}</td>
						</tr>
					</tbody>
				</table>
				<span style="font-weight: bold;">Dossier Export</span>
				<div class="card">
					<div class="mb-3">
						<div [ngStyle]="{'padding-top': !requirementsMet() ? '30px' : '10px' }" ></div>
						<label class="text-muted text-sm">
							Activities to include in the dossier export:
						</label>
						<!-- <select class="form-control" [(ngModel)]="exportPeriod">
							<option [ngValue]="o.value" *ngFor="let o of exportPeriodOptions">
								{{o.label}}
							</option>
						</select> -->
						<label class="text-muted text-sm">
						 <input type="checkbox" [(ngModel)]="allPeriodType"(change)="typeChanged()">
							All time
						</label>
						
						<div style="display:flex; justify-content: center;">
							<label class="text-muted text-sm" style="padding-right: 4rem;">
								Start Date
							</label>
							<label class="text-muted text-sm" style="padding-right: 3rem;">
								End Date
							</label>
					    </div>
						<div style="display:flex; justify-content: center;">
							
							<div class="col-6 input-group input-group-sm no-left-padding" style="padding-right:20px !important;">								
								<input [disabled]="this.allPeriodType" [matDatepicker]="startDatePicker" placeholder="mm/dd/yyyy" class="form-control form-control-sm no-padding"
								[(ngModel)]="startDate" style="width: 80px;" (dateChange)="dateChange('startDate',$event)">
								<mat-datepicker #startDatePicker startView="year" [touchUi]="touchEnabled"></mat-datepicker>
								<mat-datepicker-toggle [for]="startDatePicker" class="input-group-addon no-padding mat-icon-overide"></mat-datepicker-toggle>
							</div>						
							<div class="col-6 input-group input-group-sm no-left-padding">						
								<input [disabled]="this.allPeriodType" [matDatepicker]="endDatePicker" placeholder="mm/dd/yyyy" class="form-control form-control-sm no-padding"
								[(ngModel)]="endDate" style="width: 80px;" (dateChange)="dateChange('startDate',$event)">
								<mat-datepicker #endDatePicker startView="year" [touchUi]="touchEnabled"></mat-datepicker>
								<mat-datepicker-toggle [for]="endDatePicker" class="input-group-addon no-padding mat-icon-overide"></mat-datepicker-toggle>
							</div>
						</div>
					</div>
	
					<div *ngIf="requirementsMet()" class="mb-3">
						<i class="fa fa-check green-check"></i>
						All requirements met
					</div>
					<button style="width: 231px;" class="btn btn-primary btn-block btn-block-wrap" (click)="downloadDoc()" [disabled]="!this.startDate && !this.endDate && !this.allPeriodType " [hidden]="!dossierDownloadButtonVisible">
						<i class="fa fa-fw fa-cloud-download"></i>
						Export Selected Years to Dossier in Word
					</button>
				</div>
				<div *ngIf="!requirementsMet()" class="mb-3">
					<i class="fa fa-warning"></i>
					{{dossier.ExportStatus.Message}}
				</div>
				<div style="padding-top: 10px;"></div>
				<span style="font-weight: bold;">Other Exports</span>
				<div class="card container-box">
					<div style="padding-top: 10px;"></div>
					<button style="width: 231px;" class="btn btn-primary btn-block btn-block-wrap" (click)="downloadThemesAndProjectsDoc()" [disabled]="saving">
						<i class="fa fa-fw fa-cloud-download"></i>
						Export ALL Themes and Projects to Word
					</button>	
					<button style="width: 231px;" class="btn btn-success btn-block btn-block-wrap" (click)="downloadExcel()" [disabled]="saving">
						<i class="fa fa-fw fa-cloud-download"></i>
						Export ALL FTE, CRC, and Activities to Excel
					</button>
				</div>

				<academic-report-submission 
					type="ThemesProjectsActivitiesANRMMP"
					reportName="organizational"
					calendarName="federal fiscal year"
					dueDateWarning="Submissions after this date *will not* be included in the final report."
					btnClass="btn-block btn-secondary btn-block-wrap my-3"
					[textType]="academicReportSubmissionTextType">
						<i class="fa fa-fw fa-send"></i>
				</academic-report-submission>

				<!--
				<button class="btn btn-secondary btn-block" (click)="submitFinalReport()" disabled style="white-space: normal;">
					<i class="fa fa-fw fa-send"></i>
					Submit for ANR Annual Organizational Reporting
				</button>
				-->
			</div>
		</div>
	</div>
</div>