export class ChildSelector<T> {
	constructor(
		private readonly _children: T[],
		private readonly _childIsSelected: (child: T) => boolean,
		private readonly _setChildSelected: (child: T, selected: boolean) => void) 
	{ }

	get all(): boolean {
		return this._children.length > 0 && this._children.every(this._childIsSelected);
	}
	set all(selected: boolean) {
		this._children.forEach(c => this._setChildSelected(c, selected));
	}
	get total()         { return this._children.length; }
	get totalSelected() { return this._children.filter(this._childIsSelected).length; }

	get state(): 'all' | 'none' | 'mixed' {
		let anySelected = false;
		let anyNotSelected = false;
		this._children.forEach(c => {
			let selected = this._childIsSelected(c);
			anyNotSelected = anyNotSelected || !selected;
			anySelected    = anySelected    ||  selected;
		});
		return anySelected && anyNotSelected
			? 'mixed'
			: anySelected
				? 'all'
				: 'none';
	}
}	
