import { Case, Position, Action, CaseState, TermId, Reviewer, LettersOfEvaluationNotRequired } from "../../domain/types/ahr";
import { USERS } from "./USERS";
import { Option, PositionId } from "../../domain/types";

export const CASES: Case[] = [
    new Case(
        "07dce586-5985-40f5-ba70-a9b0e94590a1",
        1,
        USERS[0].firstName + ' ' + USERS[0].lastName,
        USERS[0].lastName + ', ' + USERS[0].firstName,
        new Position(
            "Advisor",
            "Associate",
            "I",
            "1st Term",
            new PositionId(1,1,1,Option.create(TermId.Term1)),
            "Advisor / Associate / I"
        ),
        CaseState.AwaitingProposal,
        "2017-2018",
        2018,
        Option.create(),
        Option.create(),
        [],
        Option.create(),
        Option.create(),
        Option.create(),
        [],
        [],
        new Map<string, Reviewer[]>(),
        [],
        new LettersOfEvaluationNotRequired()
        ,[]
        ,[],
        ''
    ),
    new Case(
        "07dce586-5985-40f5-ba70-a9b0e94590a2",
        2,
        USERS[1].firstName + ' ' + USERS[1].lastName,
        USERS[1].lastName + ', ' + USERS[1].firstName,
        new Position(
            "CE Specialist",
            "Assistant",
            "II",
            "2nd Term",
            new PositionId(1,1,1,Option.create(TermId.Term1)),
            "CE Specialist / Assistant / II"
        ),
        CaseState.AwaitingProposal,
        "2017-2018",
        2018,
        Option.create(),
        Option.create(),
        [],
        Option.create(),
        Option.create(),
        Option.create(),
        [],
        [],
        new Map<string, Reviewer[]>(),
        [],
        new LettersOfEvaluationNotRequired()
        ,[]
        ,[],
        ''
    ),
    new Case(
        "07dce586-5985-40f5-ba70-a9b0e94590a3",
        3,
        USERS[2].firstName + ' ' + USERS[2].lastName,
        USERS[2].lastName + ', ' + USERS[2].firstName,
        new Position(
            "Professional Researcher",
            "Full Title",
            "IV",
            "1st Term",
            new PositionId(1,1,1,Option.create(TermId.Term1)),
            "Professional Researcher / Full Title / IV"
        ),
        CaseState.AwaitingProposal,
        "2017-2018",
        2018,
        Option.create(),
        Option.create(),
        [],
        Option.create(),
        Option.create(),
        Option.create(),
        [],
        [],
        new Map<string, Reviewer[]>(),
        [],
        new LettersOfEvaluationNotRequired()
        ,[]
        ,[],
        ''
    ),
];