<div mat-dialog-title>
	{{title}}
</div>
<mat-dialog-content>
	<form [formGroup]="form" novalidate>
		<div class="form-group row">
			<label class="col-form-label bold-label col-4" for="titleId">Academic</label>
			<div class="col-8">
				<user-picker
					[filterIds]="filterAcademics()"
					formControlName="user"
					selectSingle="true"
					controlClass="form-control-sm col-12"></user-picker>
			</div>
		</div>

		<div class="form-group row">
			<label class="col-form-label bold-label col-4" for="statusId">Status</label>
			<div class="col-8">
				<select class="form-control form-control-sm" formControlName="statusId">
					<option *ngFor="let s of statuses" [value]="s.id">
						{{s.name}}
					</option>
				</select>
			</div>
		</div>

		<div class="form-group row">
			<label class="col-form-label bold-label col-4" for="titleId">Title</label>
			<div class="col-8">
				<select class="form-control form-control-sm"
					(change)="changeTitle()"
					formControlName="titleId">
					<option *ngFor="let t of titles" [value]="t.id">
						{{t.name}}
					</option>
				</select>
			</div>
		</div>

		<div class="form-group row">
			<label class="col-form-label bold-label col-4" for="rankId">Rank</label>
			<div class="col-8">
				<select class="form-control form-control-sm"
					(change)="changeRank()" 
					formControlName="rankId">
					<option *ngFor="let r of titleRankAndStep.availableRanks" [value]="r.id">
						{{r.name}}
					</option>
				</select>
			</div>
		</div>

		<div class="form-group row">
			<label class="col-form-label bold-label col-4" for="stepId">Step</label>
			<div class="col-8">
				<select class="form-control form-control-sm" 
					formControlName="stepId">
					<option *ngFor="let s of titleRankAndStep.availableSteps" [value]="s.id">
						Step {{s.number}}
					</option>
				</select>
			</div>
		</div>

		<div class="form-group row">
			<label class="col-form-label bold-label col-4" for="termId">Term</label>
			<div class="col-8">
				<select class="form-control form-control-sm" 
					formControlName="termId">
					<option *ngFor="let t of terms" [value]="t.id">
						{{t.name}}
					</option>
				</select>
			</div>
		</div>

		<div class="form-group row">
			<label class="col-form-label bold-label col-4" for="supervisors">Supervisors</label>
			<div class="col-8">
				<user-picker
					formControlName="supervisors"
					controlClass="form-control-sm col-12"></user-picker>
			</div>
		</div>

		<div class="form-group row">
			<label class="col-form-label bold-label col-4" for="appointmentUnits">Units</label>
			<div class="col-8">
				<unit-picker
					formControlName="appointmentUnits"
					controlClass="form-control-sm col-12"></unit-picker>
			</div>
		</div>

		<div class="form-group row">
			<label class="col-form-label bold-label col-4" for="payrollCampusId">Payroll Campus</label>
			<div class="col-8">
				<select class="form-control form-control-sm" 
					formControlName="payrollCampusId">
					<option *ngFor="let c of campuses" [value]="c.id">{{c.abbreviation}}</option>
				</select>
			</div>
		</div>

		<div class="form-group row">
			<label class="col-form-label bold-label col-4" for="hr">M&amp;P</label>
			<div class="col-8">
				<select class="form-control form-control-sm" 
					formControlName="hr">
					<option [value]="HumanResources.ANR">ANR</option>
					<option [value]="HumanResources.Campus">Campus</option>
				</select>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<button (click)="cancel()" class="btn btn-link" [disabled]="closing">
		Cancel
	</button>
	<button (click)="save()" class="btn btn-primary" [disabled]="closing">
		Save
	</button>
</mat-dialog-actions>
