import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivitySubTypeId, HumanResources } from '../domain';
import { AppSettings } from '../app-settings';
import { ConsoleService } from './console.service';
import { ToolTip } from '../domain/types/tool-tip';

@Injectable()
export class TooltipService {
    private _tooltips: ToolTip[];
    public StaticText: any;
    

    /* StaticText pattern usage
    StaticText.StaticMethod
    StaticText.getDescriptionToolTipBySubTypeId(subTypeId)
    StaticText.getSpecialistActivityCountToolTipBySubTypeId(subTypeId)
    StaticText.YearlyFTETooltip(workingTowardsPhrase: string, includeExample: boolean = true)
    */

    constructor(
        private http: HttpClient,
        private appSettings: AppSettings,
		private console: ConsoleService,
    ){	
        //this._tooltips = new ReplaySubject<ToolTip[]>(1);
    }

    public init() {
        this.loadTooltips();
    }

    get tooltips(): ToolTip[] {
		return this._tooltips;
	}

    public tooltip(key: string) {
        let tooltip = this._tooltips.find(tt => tt.id == key);
        return tooltip == null ? "" : tooltip.name;
    }

    public activitytooltip(name: string, meritAndPromotionHR: HumanResources, subTypeId: number){
        return this.tooltip(name + "." + this.mapMeritAndPromotionsHRToString(meritAndPromotionHR) + "." + ActivitySubTypeId[subTypeId]);
    }

    public tooltipHR(name: string, meritAndPromotionHR: HumanResources){
        return this.tooltip(name + "." + this.mapMeritAndPromotionsHRToString(meritAndPromotionHR));
    }

    public mapMeritAndPromotionsHRToString(meritAndPromotionHR: HumanResources){
        if(meritAndPromotionHR == HumanResources.ANR) {
            return "ANRM&P";
        }
        return "CampusM&P";
    }

    public getDescriptionToolTipBySubTypeId(subTypeId: number) {
        // ActivitySubTypeId.ANRM&P.DigitalMedia
        return this.tooltip("ActivitySubTypeId.ANRM&P." + ActivitySubTypeId[subTypeId]);
    }
    
    public getSpecialistActivityCountToolTipBySubTypeId(subTypeId: number) {
        // ActivitySubTypeId.CampusM&P.DigitalMedia
        return this.tooltip("ActivitySubTypeId.CampusM&P." + ActivitySubTypeId[subTypeId]);
    }

    public YearlyFTETooltip(workingTowardsPhrase: string, includeExample: boolean = true) {
        let example = includeExample 
            ? '\n\nFor example, a Specialist with a Cooperative Extension appointment of 0.75 will report a total of 100% for that portion of their FTE.'
            : '';
        return 'The percentage of time you spent over the past state fiscal year ' + workingTowardsPhrase + '.\n\nThis percentage will be converted into true FTE figures behind the scenes using your actual appointment FTE.' + example;
    }

    loadTooltips() {
		this.http.get(this.appSettings.APIURL + '/tooltip/')
			.subscribe(
				json => {
                    this.StaticText = json;
                    let tooltips = [];
                    for (var key of Object.keys(json)) {
                        tooltips.push({"id": key, "name": json[key] });
                    }
					this._tooltips = tooltips;
				}
				,() => this.console.log('Could not load Tooltips.')
			);
	}
}