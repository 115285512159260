import { Output } from "@angular/core";

export class UserPreference {
    constructor(
        public readonly key: string,
        public readonly fieldName: string,
        public readonly defaultValue: string
    ){}
}

export class UserPreferences {
    static keys = {
        activityUseModal: "activities-use-modal-flag",
        closeAfterActivitySave: "close-after-activity-save",
        wideModal: "wide-activity-modal",
        dossierExpandedState: "dossier-expanded-state"
    }
    static fields = [
        new UserPreference(UserPreferences.keys.activityUseModal, "activityUseModal", 'false'),
        new UserPreference(UserPreferences.keys.closeAfterActivitySave, "closeAfterActivitySave", 'true'),
        new UserPreference(UserPreferences.keys.wideModal, "wideModal", 'true'),
        new UserPreference(UserPreferences.keys.dossierExpandedState, "dossierExpandedState", 'true'),
    ]
}