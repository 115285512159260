<hr>
<footer>
	<div class="container">
		<div class="row">
			<div class="col-md-6">
				<div class="media">
					<img class="d-flex mt-2" src="assets/UCANR_rect.png" width="250" alt="UC ANR">
				</div>
				<div class="mt-2">
					<pre>v{{app.uiVersion}} (UI) &bull; v{{app.serverVersion}} (Server)</pre>
				</div>
			</div>
			<div class="col-md-6">
				<div class="media">
					<div class="media-body text-right">
						&copy; {{year}} Regents of the University of California 
						<p>
							<a href="http://ucanr.edu/sites/anrstaff/Diversity/Affirmative_Action/Resources/Policy-related_downloads/" target="_blank">
								Nondiscrimination Statement
							</a>
							&bull;
							<a href="http://ucanr.edu/Accessibility/" target="_blank">
								Accessibility
							</a>
							<br>
							<a href="https://ucanr.co1.qualtrics.com/jfe/form/SV_4HsqwQf0AkAnYaN" target="_blank">
								<i class="fa fa-fw fa-comment"></i>
								Give Feedback
							</a>

							&bull;

							<a href="http://ucanr.edu/sites/projectboardhelp/" target="_blank">
								<i class="fa fa-fw fa-question-circle"></i>
								Help
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
