<div class="container-fluid">
	<div class="mb-2 mx-2">
		<span class="h5 mb-0">
			ANR M&amp;P Academics
		</span>
		<button
			class="btn btn-primary btn-sm"
			(click)="openNewAcademicModal()">
				+ Add an Academic
		</button>
		<button
			class="btn btn-primary btn-sm"
			style="margin-left:1em;"
			(click)="exportAcademicsToExcel()">
				Export Academics
		</button>		
        <button
			class="btn btn-primary btn-sm"
			style="margin-left:1em;"
			(click)="exportWFAreportToExcel()">
			   WFA Report
		</button>	
		<button
			class="btn btn-primary btn-sm"
			style="margin-left:1em;"
			(click)="exportEligibilityListToExcel()">
				Eligibility List
		</button>	
		<div style="float:right;">
			<button
				class="btn btn-sm btn-secondary"
				(click)="wfaSSO()">
					WFA
			</button>
			<button
				class="btn btn-secondary btn-sm ml-1"
				(click)="openProgressionTableWorkshopModal()">
					View progression tables
			</button>

			<button
				class="btn btn-secondary btn-sm ml-1"
				(click)="openReviewRequirementsWorkshopModal()">
					View review requirements
			</button>
		</div>
	</div>

	<hr>

	<div style="position: sticky; top:113px; left:0; right:0; z-index:9; background: #f3f4fc; border-bottom: 1px solid #ccc;">
		<div class="list-group mb-1" [formGroup]="filterForm">
			<div class="list-group-item" style="border:none; background: none;">
				<div class="row text-sm">
					<div class="col-md-2">
						<input type="text"
							placeholder="Filter by name"
							class="form-control form-control-sm form-control-original"
							formControlName="userName"
							[autofocus]>
						<br>
						Showing {{cases.length}} of {{allCases.length}} 
					</div>
					<div class="col-md-3 column-title">
						<select formControlName="currentPositionHid" class="form-control form-control-sm form-control-original">
							<option *ngFor="let p of AllPositions" [ngValue]="p.hid">
								{{p.name}}
							</option>
						</select>
					</div>
					<div class="col-md-1 column-title">
						<a title="Filter: status"
							href
							(click)="$event.preventDefault()"
							class="href-only-on-hover" 
							[matMenuTriggerFor]="statusMenu">
								<i class="fa fa-fw fa-filter"></i>
								{{filterCountState}} of {{AllCaseStates.length}}
						</a>

						<mat-menu #statusMenu="matMenu" formGroupName="state" overlapTrigger="false">
							<label 
								*ngFor="let s of AllCaseStates; let index=index"
								mat-menu-item
								class="checkbox"
								style="display:block;"
								(click)="$event.stopPropagation()">
									<input type="checkbox" [formControl]="filterForm.controls['state'].controls[index]">
									{{s | caseState}}
							</label>
						</mat-menu>
					</div>
					<div class="col-md-2 column-title">
						<a title="Filter: Eligible actions"
							href
							(click)="$event.preventDefault()"
							class="href-only-on-hover" 
							[matMenuTriggerFor]="eligibilityMenu">
								<i class="fa fa-fw fa-filter"></i>
								{{filterCountEligibility}} of {{AllActionTypes.length}}
						</a>

						<mat-menu #eligibilityMenu="matMenu" formGroupName="eligibility" overlapTrigger="false">
							<label
								*ngFor="let at of AllActionTypes"
								mat-menu-item
								class="checkbox"
								style="display:block;"
								(click)="$event.stopPropagation()">
									<input type="checkbox" [formControlName]="ActionType[at]">
									{{at | actionTypeName}}
							</label>
						</mat-menu>
					</div>
					<div class="col-md-2 column-title">
						<a title="Filter: proposed action"
							href
							(click)="$event.preventDefault()"
							class="href-only-on-hover" 
							[matMenuTriggerFor]="proposalMenu">
								<i class="fa fa-fw fa-filter"></i>
								{{filterCountProposal}} of {{AllProposals.length}}
						</a>

						<mat-menu #proposalMenu="matMenu" formArrayName="proposal" overlapTrigger="false">
							<label
								*ngFor="let p of AllProposals; let index=index"
								mat-menu-item
								class="checkbox"
								style="display:block;"
								(click)="$event.stopPropagation()">
									<input type="checkbox" [formControl]="filterForm.controls['proposal'].controls[index]">
									<span [ngSwitch]="p.hasValue()">
										<span *ngSwitchCase="true">
											{{p.value | actionTypeName}}
										</span>
										<span *ngSwitchCase="false">
											None Yet
										</span>
									</span>
							</label>
						</mat-menu>

						<a title="Filter: Exceptional Advancement Timing"
							href
							(click)="$event.preventDefault()"
							class="href-only-on-hover ml-3" 
							[matMenuTriggerFor]="exceptionalTimingMenu">
								<i class="fa fa-fw fa-filter"></i>
								{{filterCountExceptionalTiming}} of {{AllExceptionalTimings.length}}
						</a>

						<mat-menu #exceptionalTimingMenu="matMenu" formArrayName="exceptionalTiming" overlapTrigger="false">
							<label
								*ngFor="let t of AllExceptionalTimings; let index=index"
								mat-menu-item
								class="checkbox"
								style="display:block;"
								(click)="$event.stopPropagation()">
									<input type="checkbox" [formControl]="filterForm.controls['exceptionalTiming'].controls[index]">
									{{t.label}}
							</label>
						</mat-menu>
					</div>
					<div class="col-md-2 column-title text-right">
						<button (click)="resetFilters()" class="btn btn-sm btn-secondary">
							Reset Filters
						</button>
					</div>
				</div>
			</div>
		</div>

					
		<div class="list-group" [formGroup]="filterForm">
			<div class="list-group-item" style="border:none; background:none;">
				<div class="row text-sm">
					<div class="col-md-2 column-title">
						<sort-column-header 
							[column]="sortColumns.name"
							[(currentSorting)]="currentSorting"></sort-column-header>
					</div>
					<div class="col-md-3 column-title">
						<sort-column-header 
							[column]="sortColumns.position"
							[(currentSorting)]="currentSorting"></sort-column-header>
					</div>
					<div class="col-md-1 column-title">
						<sort-column-header 
							[column]="sortColumns.state"
							[(currentSorting)]="currentSorting"></sort-column-header>
					</div>
					<div class="col-md-2 column-title text-center">
						<sort-column-header class="pull-left" 
							[column]="sortColumns.eligibleFor"
							[(currentSorting)]="currentSorting"></sort-column-header>
					</div>
					<div class="col-md-2 column-title text-center">
						<sort-column-header class="pull-left"
							[column]="sortColumns.proposed"
							[(currentSorting)]="currentSorting"></sort-column-header>
					</div>
					<div class="col-md-1 column-title text-center">
						<sort-column-header class="pull-left"
							[column]="sortColumns.programYear"
							[(currentSorting)]="currentSorting"></sort-column-header>
					</div>
					<div class="col-md-1 column-title text-center">
						<sort-column-header
							[column]="sortColumns.supervisorCount"
							[(currentSorting)]="currentSorting"></sort-column-header>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="list-group text-sm">
		<div class="list-group-item" 
		*ngFor="let case of cases; let i = index;"
		[ngStyle]="i == 0 ? {'border-radius':0, 'border-top': 'none'} : {}">
			<div class="row">
				<div class="col-md-2">
					<a class="href" (click)="viewDetails(case)">{{case.userName}}</a>
				</div>
				<div class="col-md-3 text-xs">
					<ng-container *ngFor="let e of case.currentPosition.elements; let first = first;" >
						<span *ngIf="e.name!=='' && !first">/</span>
						<a
							href
							class="href-only-on-hover"
							(click)="filterPosition(e.hid); $event.preventDefault();">
								{{e.name}}
						</a>
					</ng-container>
					<span *ngIf="!!case.currentPosition.term">
						/ {{case.currentPosition.term}}
					</span>
				</div>
				<div class="col-md-1 text-xs">
					<a
						href
						class="href-only-on-hover" 
						(click)="filterForState(case.state); $event.preventDefault();">
							{{case.state | caseState}}
					</a>
				</div>
				<div *ngIf="isNonAcademic(case); else academicEligibility" class="col-md-2 text-xs">
					<a href
						class="href-only-on-hover" 
						(click)="filterEligibility('NotApplicable'); $event.preventDefault();">{{'NotApplicable' | actionTypeName}}</a>

				</div>
				<ng-template #academicEligibility>
					<div class="col-md-2 text-xs">
						<span *ngFor="let a of case.eligibleActions; let first = first;"><span [hidden]="first">, </span><a href
							class="href-only-on-hover" 
							(click)="filterEligibility(a.type); $event.preventDefault();">{{a.type | actionTypeName}}</a></span>
					</div>
				</ng-template>
				<div class="col-md-2 text-xs" [ngSwitch]="case.proposedAction.hasValue()">
					<div *ngSwitchCase="true">						
						<ng-container [ngSwitch]="IsIndefiniteStatusOnly(case)">
							<ng-container *ngSwitchCase="true">
								<a href
									class="href-only-on-hover" 
									(click)="filterForTerm(case.proposedAction.value.endingPosition.term); $event.preventDefault();"
								>Indefinite Status</a>
							</ng-container>
							<ng-container *ngSwitchCase="false">
								<a
									href
									class="href-only-on-hover" 
									(click)="filterForProposedAction(case.proposedAction.value.type); $event.preventDefault();"
								>{{case.proposedAction.value.type | actionTypeName}}</a> 
								<a *ngIf="!!case.proposedAction.value.exceptionalTiming.value"
									href
									class="href-only-on-hover" 
									(click)="filterForExceptionalTiming(case.proposedAction.value.exceptionalTiming.value); $event.preventDefault();"
								>({{exceptionalTimingLabel(case.proposedAction.value.exceptionalTiming.value)}})</a>
								<span *ngIf="indefiniteStatusProposed(case)">
									<a href
										class="href-only-on-hover" 
										(click)="filterForTerm(case.proposedAction.value.endingPosition.term); $event.preventDefault();"
									>(Indefinite Status)</a>
								</span>
							</ng-container>
						</ng-container>
					</div>
					<div *ngSwitchCase="false" class="text-muted">
						<div *ngIf="isNonAcademic(case); else NoneYet">
							<a
								href
								class="href-only-on-hover" 
								(click)="filterForProposedAction('NotApplicable'); $event.preventDefault();">
								N/A
							</a>
						</div>
						<ng-template #NoneYet>
							<a
								href
								class="href-only-on-hover" 
								(click)="filterForProposedAction(); $event.preventDefault();">
								None Yet
							</a>							
						</ng-template>
					</div>
				</div>

				<ng-container [ngSwitch]="isNonAcademic(case)">
					
					<ng-container *ngSwitchCase="true">
					  	<div class="col-md-1 text-center">
							—
					  	</div>
					  	<div class="col-md-1 text-center">
							—
					  	</div>
					</ng-container>
					
					<ng-container *ngSwitchDefault>
					  	<div class="col-md-1 text-center">
							{{case.year}}
					  	</div>
					  	<div class="col-md-1 text-center">
							{{case.supervisors.length}}
					  	</div>
					</ng-container>

				</ng-container>

			</div>
		</div>
	</div>
</div>