import { ActivitySubTypeId } from ".";

export class StaticText {
    public static ArchiveActivity = "When you archive an Activity, it will no longer appear in your Dossier Export, nor will it be available to ANR for organizational reporting.  It is possible to recover an archived Activity.  Should you need to do this, send a request for assistance to help@ucanr.edu";
    public static ArchiveClienteleGroup = "When you archive a Clientele Group, you will no longer be able to record activity contacts nor A.R.E. Effort for the group.  It is possible to recover an archived Clientele Group.  Should you need to do this, send a request for assistance to help@ucanr.edu";
    public static ArchiveProject = "When you archive a Project, all linked Activities will also be archived.  Archived items will no longer appear in your Dossier Export, nor will they be available to ANR for organizational reporting.  It *is* possible to recover archived items.  Should you need to do this, send a request for assistance to help@ucanr.edu";
    public static ArchiveTheme = "When you archive a Theme, all linked Projects and Activities will also be archived.  Archived items will no longer appear in your Dossier Export, nor will they be available to ANR for organizational reporting.  It *is* possible to recover archived items.  Should you need to do this, send a request for assistance to help@ucanr.edu";
    
    public static ProgramAreaToolTip = "Program Areas are the broad subject matters currently covered by ANR’s Program Team umbrella structure. In Project Board, assigning a Program Area to Extension Activities and Projects enables ANR to aggregate as best as possible the unique work that academics do in the field for accountability and advocacy purposes.";
    public static ThemeNameToolTip = "Subject matter around which the program is organized.\n\nTip: Although not required, you may use existing ANR terms such as Strategic Initiatives, Program Team names, Public Values, Condition Changes, etc. You are welcome to develop your own.";
    public static ThemeNameToolTipCampusMP = "Subject matter around which the program is organized and for which goals are created (directly from E-Book).\n\nTip: Although not required, you may use existing ANR terms such as Strategic Initiatives, Program Team names, Public Values, Condition Changes, etc. You are welcome to develop your own.";
    public static ThemeBackgroundToolTip = "Consider including the following components when describing your theme:\n\n • Clientele: People or groups of people that a program aims to serve.\n • Goals: The purpose toward which an effort is directed.\n • Inputs: What is invested? Faculty, staff, students, infrastructure, federal, state, and private funds, time, knowledge, etc.";
    public static ThemeMethodsToolTipCampusMP = "Articulate ‘Methods,’ ‘Outcomes’ and ‘Impacts’ that were achieved during this reporting year. If preferred, you can create program/project sub-headings. Try to articulate the particular method to which an outcome/impact relates, otherwise it can be confusing for reviewers to match up outcomes/impacts with methods." +
        //"\n\n Refer to your Project Summary and Extension Activities table when writing this sections. You should not duplicate the information that is included in these tables. Consider including the following components when describing your theme:" +
        "\n\n Methods (Activities/Outputs): Research/creative and extension activities to reach goals. Products created through such activities (meetings, trainings, extension programs, curricula, webinars, publications, etc.)." + 
        "\n\n Outcomes/Impacts (include number of people, acres, and other units affected when possible):" +
        "\n\n \to Change in learning measured (knowledge, attitude, or skills)" +
        "\n \to Change in action measured (behavior or practice)" + 
        "\n \to Change in policy or decision-making measured (science-based information applied to decision- making or results from policy engagement)" +
        "\n \to Change or potential change in condition (social/health, economic, environmental, or physical)";
    public static ThemeMethodsToolTip = "Articulate ‘Methods,’ ‘Outcomes’ and ‘Impacts’ that were achieved during this reporting year. If preferred, you can create program/project sub-headings. Try to articulate the particular method to which an outcome/impact relates, otherwise it can be confusing for reviewers to match up outcomes/impacts with methods."+
        "\n\n Refer to your Project Summary and Extension Activities table when writing this sections. You should not duplicate the information that is included in these tables. Consider including the following components when describing your theme:" +
        "\n\n • Methods (Activities/Outputs): Research/creative and extension activities to reach goals. Products created through such activities (meetings, trainings, extension programs, curricula, webinars, publications, etc.)." +
        "\n • Outcomes/Impacts (include number of people, acres, and other units affected when possible):" +
        "\n\n \to Change in learning measured (knowledge, attitude, or skills)"+
        "\n \to Change in action measured (behavior or practice)" +
        "\n \to Change in policy or decision-making measured (science-based information applied to decision- making or results from policy engagement)" +
        "\n \to Change or potential change in condition (social/health, economic, environmental, or physical)";
    public static ThemeOutcomeTagsToolTip = "If possible, select at least one anticipated condition change and one measured outcome type from the lists provided."
        "\n\n • Condition changes represent broad environmental, health or economic benefits at a societal level (recognizing that UC ANR may be only one contributor towards these long-term outcomes). Selecting one or more condition changes will enable administration to know how the efforts and outcomes reported in this theme relate to changing conditions in California and beyond."
        "\n • Outcome types represent short, medium, and long-term changes that occurred as a result of the work described in your theme. Selecting one or more outcome types enables administration to know what level of outcome has been actually measured/articulated.";

    public static ThemePeerReviewedPublicationsToolTip = 
    "Peer-reviewed scholarly journal publications. A peer review involves means reviewed anonymously (aka \"blind review\") by subject matter experts or scientific panels with the possibility of rejection. Peer-reviewed scholarly journal articles are those published in searchable, peer-reviewed journals and periodical with articles written by researchers and experts in a specific discipline, aimed at other researchers in a field.\n\nExamples: Professional society journals, California Agriculture research articles, Journal of Extension Feature or Research in Brief articles.";
    public static ThemePopularPressPublicationsToolTip = "Definition: Articles targeted to clientele and/or layperson.\nExamples: Newsletter articles, newspaper articles, UC Delivers, trade journals, magazines, web-based articles and extensive/substantial blog post similar in complexity to a newsletter article, non-peer reviewed curricula for primary clientele, etc.";
    public static ThemeOtherPeerReviewedPublicationsToolTip = "A peer-review involves a blind review of your work and a refereed editorial process (with possibility of rejection) leading to publication.\n\nExamples: UC ANR Publications, UC Integrated Pest Management Publications, UC IPM Pest Management Guidelines, peer review curricula, books and monograph chapters, peer-reviewed videos.";

    public static ProjectThemeTooltipCampusMP = "Link this project to one of your existing themes";
    public static ProjectThemeTooltipANRMP = "The project and all its linked activities will not appear in your dossier export until a Theme is specified for this project";
    public static ProjectThemeRequiredTooltip = "This project is missing a theme link.";
    public static ProjectThemeRequiredForDossierTooltip = "This project is missing a theme link. Until you specify a Theme for this project, the project and all its linked activities will not appear in your dossier export";
    public static ProjectRoleRequiredTooltip = "This project is missing a role.";
    public static ProjectRoleRequiredForDossierTooltip = "This project is missing a role. Until you specify a Role for this project, the project and all its linked activities will not appear in your dossier export";

    public static YearlyFTETooltip(workingTowardsPhrase: string, includeExample: boolean = true){
        let example = includeExample 
            ? '\n\nFor example, a Specialist with a Cooperative Extension appointment of 0.75 will report a total of 100% for that portion of their FTE.'
            : '';
        return 'The percentage of time you spent over the past state fiscal year ' + workingTowardsPhrase + '.\n\nThis percentage will be converted into true FTE figures behind the scenes using your actual appointment FTE.' + example;
    }

    public static ActivityRoles = "Example Roles:\n" + ([
        "Principal Investigator",
        "Co-Investigator",
        "Organizer",
        "Presenter",
        "Collaborator"
    ].map(str => "- " + str).join("\n"));

    public static ProjectRoleToolTipANRMP = "The project and all its linked activities will not appear in your dossier export until a Role is specified for this project\n\n" + StaticText.ActivityRoles;
    public static ProjectRoleToolTipCampusMP = StaticText.ActivityRoles;
    public static UserDefinedConditionChangeTooltip = "It is highly recommended that you select an existing condition change from the list. Only you will see your user-defined condition changes in Project Board. ANR will periodically review user-defined condition changes and modify as needed.";

    public static getDescriptionToolTipBySubTypeId(subTypeId: number): string {

        switch(subTypeId){
            
            case(ActivitySubTypeId.DigitalMedia):
                return "Provide a brief description, purpose, and your effort.";
            case(ActivitySubTypeId.EducationalPresentation):
                return "Provide the meeting or event name.";
            case(ActivitySubTypeId.EvidenceOfProfessionalCompetence):
                return "Provide activity name and/or a brief description of the purpose and your effort.";
            case(ActivitySubTypeId.ExternalCollaboration): // displays as: Ongoing collaboration with other agency or organization
                return "Provide a brief description, purpose, and your effort.";
            case(ActivitySubTypeId.GenericNonAcademic): // Extension Activity Delivered by Your Staff
                return "Provide a brief description along with the name of the event.";
            case(ActivitySubTypeId.IndividualClienteleContacts):
                return "Provide a brief description.";
            case(ActivitySubTypeId.IndividualOutreach): // A.R.E. Individual Efforts
                return 'Provide a brief description, including who conducted the efforts. Examples: "Individual interactions at farmers markets, fairs, etc. by Master Gardener Volunteers" or "Individual interactions at farmers markets, fairs, etc. by me."';
            case(ActivitySubTypeId.MassMediaOutreach): // A.R.E. Mass Media Efforts
                return 'Provide a brief description, including who conducted the efforts. Examples: "Social media event marketing posts by Community Education Specialists" or "Social media event marketing posts by me."';
            case(ActivitySubTypeId.MediaOutletProgramOrInterview):
                return "Provide a name of media organization or publication.";
            case(ActivitySubTypeId.MeetingOrganized):
                return "Provide the activity name and a brief description (3-day workshop, weeklong course, field day, etc.)";
            case(ActivitySubTypeId.NewsletterOutreach):  // was renamed to: Promotional Material Effort
                return 'Provide a brief description, including who conducted the efforts. Examples: "Newsletters and flyers distributed by 4-H volunteers" or "Newsletters and flyers distributed by me."';
            case(ActivitySubTypeId.OtherAppliedResearchOrCreative):
                return "Provide a brief description, purpose, and your effort.";
            case(ActivitySubTypeId.OtherExtension):
                return "Provide a brief description, purpose, and your effort.";
            case(ActivitySubTypeId.OtherOutreach):
                return "Provide a brief description, including who conducted the efforts.";
            case(ActivitySubTypeId.PersonalLetterOutreach):
                return 'Provide a brief description, including who conducted the efforts. Examples: "Email event invitations sent by California Naturalists" or "Email event invitations sent by me."';
            case(ActivitySubTypeId.PolicyEngagement):
                return "Provide a brief description, purpose, and your effort.";
            case(ActivitySubTypeId.ProfessionalDevelopmentAndTraining):
                return "Provide activity name and/or a brief description of the purpose and your effort.";
            case(ActivitySubTypeId.PublicService):
                return "Provide activity name and a brief description of the purpose.";
            case(ActivitySubTypeId.Research):
                return "Provide a brief description, purpose, and your effort.";
            case(ActivitySubTypeId.TrainTheTrainer):
                return "Provide the activity name and a brief description (3-day workshop, weeklong course, etc.)";
            case(ActivitySubTypeId.UniversityService):
                return "Provide activity name and a brief description of the purpose.";
            case(ActivitySubTypeId.WebsiteOrSocialMedia):
                return "Provide a brief description, purpose, and your effort. You may include statistics here (# of views/interactions).";
            default: 
                return 'Please provide a detailed yet brief description.';
        }
    }

    public static getSpecialistActivityCountToolTipBySubTypeId(subTypeId: number): string {

        switch(subTypeId){
            
            case(ActivitySubTypeId.DigitalMedia):{
                return "Development of non-peer reviewed videos, applications, software, or other audio/visual educational products, recorded webinars, others. Report print materials as part of your non-peer reviewed publications.";
            }
            case(ActivitySubTypeId.EducationalPresentation):{
                return "Oral presentations and posters that you deliver at meetings organized by someone else.";
            }
            case(ActivitySubTypeId.ExternalCollaboration):{ // displays as: Ongoing collaboration with other agency or organization
                return "Extension of knowledge and information via ongoing collaborations.";
            }
            case(ActivitySubTypeId.MediaOutletProgramOrInterview):{
                return "Radio, television or other mass media outlet programs/interviews in which you deliver significant educational content via a media outlet program or interview.";
            }
            case(ActivitySubTypeId.MeetingOrganized):{
                return "Classes, short courses, demonstrations, field days, fairs, webinars, Extension meetings, etc. that you organize.";
            }
            case(ActivitySubTypeId.PolicyEngagement):{
                return "Extension of knowledge and information to clientele during any step in policy development and implementation, such as building relationships/leading coalitions; equipping decision-makers with the data they need to develop successful policies; monitoring and assessing formal decision-making by proper authorities; informing policy or regulatory compliance options and actions; etc. Report policy briefs and white papers as part of your non-peer reviewed publications. Anything counted here should not be duplicated in other activity types or publications.";
            }
            case(ActivitySubTypeId.TrainTheTrainer):{
                return "Trainings you deliver to professionals who then extend information. This does NOT include volunteer capacity building.";
            }
            case(ActivitySubTypeId.UniversityService):{
                return "";
            }
            case(ActivitySubTypeId.WebsiteOrSocialMedia):{
                return "Management of specific sites/platforms/accounts you use to extend knowledge and information.";
            }
            
            default: {
                return '';
            }
        }
    }
}