export function getStateFiscalYearsOfInterest(): number[] {
	 let years = [];
	 let y=2018;
	//Remember, in JS, month is off by 1.  1 = February.
	//0,1 = Jan 1st
	while(new Date(y,0,1) <= new Date()){
		years.push(y);
		y++;
	}
	return years;
}