import { IdAndName, Option, Title, Rank, Step } from "../../domain";

export class TitleRankAndStepSelector {
  private _titleId: number;
  private _rankId: number | null;
  private _stepId: number | null;

  private _ranks: Rank[] = [];
  private _steps: Step[] = [];
  get availableRanks(){ return this._ranks; }
  get availableSteps(){ return this._steps; }

  constructor(
    public readonly titles: Title[],
    startingTitleId: number | null,
    startingRankId: number | null,
    startingStepId: number | null
  ){
    this._titleId = startingTitleId;
    this._rankId = startingRankId;
    this._stepId = startingStepId;
    this.updateAvailables();
  }

  get titleId(){ return this._titleId; }
  get rankId(){ return this._rankId; }
  get stepId(){ return this._stepId; }

  set titleId(id: any){
    this._titleId = parseInt(id) || null;
    this._rankId = null;
    this._stepId = null;
    this.updateAvailables();
  }

  set rankId(id: any){
    this._rankId = parseInt(id) || null;
    this._stepId = null;
    this.updateAvailables();
  }

  set stepId(id: any){
    this._stepId = parseInt(id) || null;
  }

  private updateAvailables(){
    if(!!this._titleId){
      let title = this.titles.filter(t => t.id == this._titleId)[0];
      this._ranks = title.ranks;
    } else {
      this._ranks = [];
    }
    if(!!this._rankId){
      let rank = this._ranks.filter(r => r.id == this._rankId)[0];
      this._steps = rank.steps;
    } else {
      this._steps = [];
    }
  }

  get title(): IdAndName {
    return this.titles.filter(t => t.id == this._titleId).map(t => new IdAndName(t.id, t.name))[0]; 
  }
  get rank(): Option<IdAndName> { 
    return Option.create<IdAndName>(
      this._ranks
      .filter(r => r.id == this._rankId)
      .map(r => new IdAndName(r.id, r.name))[0]); 
  }
  get step(): Option<IdAndName> { 
    return Option.create<IdAndName>(
      this._steps
      .filter(s => s.id == this._stepId)
      .map(s => new IdAndName(s.id, s.name))[0]); 
  }
}