<div mat-dialog-title>
	Select an ANR Person
</div>

<mat-dialog-content style="min-height: 300px;" [formGroup]="form">
	<div class="row">
		<div class="col-md-5">
			<user-picker 
				formControlName="user"
				enableNonANRCollaborator="false"></user-picker>
		</div>

		<div class="col-md-7">
			<p>
				<i class="fa fa-fw fa-info-circle"></i>
				Search the ANR Directory by typing someone's
			</p>
			<ul>
				<li>name</li>
				<li>username</li>
				<li>email address</li>
			</ul>
			<p>
				For example, you may find the fictional ANR Person &quot;Jane Doe&quot; as follows:
			</p>
			<table class="table table-sm">
				<tr>
					<th>Search</th>
					<th>Description</th>
				</tr>
				<tr *ngFor="let pair of [
					{search:'Jane Doe'      , description:'full name'},
					{search:'J D'           , description:'initials'},
					{search:'Jane D'        , description:'first name, last initial'},
					{search:'J Doe'         , description:'first initial, last name'},
					{search:'jdoe'          , description:'username'},
					{search:'jdoe@ucanr.edu', description:'email'}
				]">
					<td>&quot;{{pair.search}}&quot;</td>
					<td>{{pair.description}}</td>
				</tr>

			</table>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button (click)="cancel()" class="btn btn-link">
		Cancel
	</button>
	<button (click)="save()" class="btn btn-primary">
		Continue with selection
	</button>
</mat-dialog-actions>