import { CORE_ISSUES } from './CORE_ISSUES';
import { 
	STRATEGIC_INITIATIVE_TAGS as SI,
	PROGRAM_AREA_TAGS as PA,
	USER_DEFINED_TAGS as UD
} from './TAGS'
import { USERS } from './USERS'
import { THEMES } from './THEMES';
import { Project, Theme, Option } from '../../domain';

var id = 1;


function someTheme(index: number): Option<Theme> {
	return Option.create<Theme>(THEMES[index]);
}

export const PROJECTS: Project[] = [
    new Project(
        id++,
        'Caregiver Training',
        new Date("2013-03-10T09:51:34.3269056-07:00"),
        'In-Home Supportive Service Pubic Authority caregivers are county paid to provide the most vulnerable population with quality assistance in their homes.  UCCE conducts caregiving training program to provide caregivers with knowledge and skill  in the area of activities of daily living, health promotion and job skills.  The training sessions were conducted within a two-hour time frame.  A combination of instructional methods (i.e. lecture, small group discussion, handouts and visual aids) were used to enhance the learning process.  During this period, 251 caregivers participated in the trainings.',
		someTheme(1),
        CORE_ISSUES[4],
        'Project Role',
        false,
        '',
        [
        ],
        [
            USERS[0],
            USERS[3],
            USERS[6],
        ],
        [],
        12,
        "Ongoing",
        new Date("2017-05-17T09:51:34.3269056-07:00"),
        new Date("2017-05-17T09:51:34.3269056-07:00"),
        "",
        ""
    ),
    new Project(
        id++,
		'Family and Child Nutrition Practices of High Risk Parents',
        new Date("2015-08-01T09:51:34.3269056-07:00"),
		'The project assesses dietary, health and wellness, and lifestyle practices of high risk mothers who parent small children. The goal is to determine the impact of thier dietary practices on the eating practices of the child/children. The objective is to use the new knowledge to adapt curricula to achieve a better educational outcome for positive nutrition and wellness behavior.',
		someTheme(0),
        CORE_ISSUES[3],
        '',
        true,
        'Texas A&M',
        [
            PA[1],
        ],
        [
            USERS[5],
            USERS[2],
        ],
        [],
        8,
        "Active",
        new Date("2017-05-11T09:51:34.3269056-07:00"),
        new Date("2017-05-11T09:51:34.3269056-07:00"),
        "",
        ""
    ),
    new Project(
        id++,
		'Weed management research',
        new Date("2016-05-10T09:51:34.3269056-07:00"),
		'Invasive plants are weeds of natural areas. These non-native plants invade natural or wild areas and displace the native vegetation. Without native vegetation, native fauna do not survive. Several native bird species, especially threatened and endangered species (T&E) are at risk because of invasion by non-native plants. Removing these plants from these sensitive environments without damage to the native vegetation is very difficult. My research focuses upon field evaluation of weed control methods that are effective, safe, within reasonable cost limits, and practical.',
		Option.create<Theme>(),
        CORE_ISSUES[12],
        'Rolled a 3',
        false,
        '',
        [
            SI[1],
            SI[4],
        ],
        [
            USERS[4],
            USERS[3],
        ],
        [],
        33,
        "Completed",
        new Date("2017-05-09T09:51:34.3269056-07:00"),
        new Date("2017-05-09T09:51:34.3269056-07:00"),
        "",
        ""
    ),
    new Project(
        id++,
		'Biological Conservation Symposium',
        new Date("2016-05-10T09:51:34.3269056-07:00"),
		'The objective of this project is to extend current rangeland research to conservation agencies and organizations to result in conservation strategies. We compiled research and created a binder. The binder included the CD produced in the previous reporting period.',
		Option.create<Theme>(),
        CORE_ISSUES[17],
        'Rolling and rolling and rolling along the miles...',
        false,
        '',
        [
            SI[1]
        ],
        [
            USERS[3],
        ],
        [],
        12,
        "Completed",
        new Date("2017-05-09T09:51:34.3269056-07:00"),
        new Date("2017-05-09T09:51:34.3269056-07:00"),
        "",
        ""
    ),
    new Project(
        id++,
		'Short Courses',
        new Date("2016-05-10T09:51:34.3269056-07:00"),
		'The objective of this project is to provide information to landowners on impacts of management activities to local surface runoff and groundwater. Landowners will be encouraged to adopt best management practices to minimize degradation of water quality. Workshops were at various locations and with several different cooperating agencies to convey this information.',
		someTheme(3),
        CORE_ISSUES[19],
        'Rolling down hills is fun!',
        false,
        '',
        [
            SI[3]
        ],
        [
            USERS[3],
        ],
        [],
        10,
        "Completed",
        new Date("2017-05-09T09:51:34.3269056-07:00"),
        new Date("2017-05-09T09:51:34.3269056-07:00"),
        "",
        ""
    ),
    new Project(
        id++,
		'Forest Ecology',
        new Date("2016-05-10T09:51:34.3269056-07:00"),
		'My program continues to explore new and innovative means of addressing the issue of sustainability in woodlands and forests.',
		someTheme(4),
        CORE_ISSUES[10],
        'Roll some dough... For cookies, for pie, for pizza and cinnamon rolls!',
        false,
        '',
        [
            SI[1]
        ],
        [
            USERS[3],
        ],
        [],
        3,
        "Completed",
        new Date("2017-05-09T09:51:34.3269056-07:00"),
        new Date("2017-05-09T09:51:34.3269056-07:00"),
        "",
        ""
    )
];


/*
Rice Straw
Research and education on the utilzation of rice straw as a livestock feed.
P
Sustainable Use of Natural Resources

Healthy Homes
In response to the South Sacramento Community Initiative to enhance the quality of life within the community, UCCE Sacramento County conducts the Healthy Homes Progam.  The program aims to help families identify adn address environmental hazards around their homes and to increase parents awareness of asthma triggers. The collaboration between UCCE and the Lao Famiy Community may lead to a healthier community.  Seventy-two Hmong participated in the Healthy Homes Program.  They learned about asthma triggers- such as furniture polish, cleansers, bleach, pesticides - and how to reduce these agents in the home.  The Hmong health educator reports that 78% of participants implement preventative measures learned in the course.  The Hmong families benefit from increased knowledge and potential reduction in the number of asthma-related conditions among children
E
Community Development

Make it Safe Serve it Safe
A six County Collaboratine Effort: The goal is to improve safe food handling knowledge and practices of volunteers and employees of Community based organizations by training the trainer how to prepare, serve, and store food safely.
G
Food Safety

Expanded Food and Nutrition Education
A Statewide Program: The goals is to improve the dietary, money magement, food safety, and physical activities of low income families with children
F
Family and Consumer Well Being

Food Stamp Nutrition Education
A statewide program: The goal is to Improve the nutrition knowledge and skills of food stamp recipients to selectand prepare low cost nutritious food.
F
Family and Consumer Well Being

*/
