import { Component, ComponentFactoryResolver, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
	Academic,
	Activity,
	ActivityParent,
	ActivityService,
	ActivitySubType,
	ActivitySubTypeId,
	ActivityTypeCollection,
	ActivityValuePresence,
	AuthenticatedIdentity,
	AuthenticationService,
	CurrentAcademicUser,
	DossierService,
	HumanResources,
	Option,
	Project,
	ProjectService,
	SortedSet,
	Tag,
	TagType,
	ThemeTagTypes
} from '../../domain';
import { Theme, ThemePublicationType } from '../../domain/types/dossier';
import { ConsoleService } from '../../widgets/console.service';
import { TooltipService } from '../../widgets/tooltip.service';
import { ActivityModalService } from '../activity-modal.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { NewActivityModalComponent } from '../new-activity-modal/new-activity-modal.component';
import { NewThemeModalComponent } from '../new-theme-modal/new-theme-modal.component';
import { ProjectModalComponent } from '../project-modal/project-modal.component';

@Component({
	selector: 'app-themes',
	templateUrl: './themes.component.html',
	styleUrls: ['./themes.component.scss']
})
export class ThemesComponent implements OnInit {
	public StaticText: any;
	Presence = ActivityValuePresence;
	availableTagTypes: TagType[] = ThemeTagTypes;
	activityTypes: ActivityTypeCollection = null;
	activitySubTypes: ActivitySubType[] = [];
	checked = true;
	abbreviatedActivities = false;
	user: CurrentAcademicUser;
	publicationTypes;
	meritAndPromotionHR = HumanResources.ANR;

	@ViewChildren("pubTypeUI") pubTypeUIs: QueryList<ElementRef>;

	constructor(
		actSvc: ActivityService,
		authSvc: AuthenticationService,
		private svc: DossierService,
        private router: Router,
		private matDialog: MatDialog,
		private snackBar: MatSnackBar,
		private projectService: ProjectService,
		private activityModals: ActivityModalService,
		private console: ConsoleService,
		private cfr: ComponentFactoryResolver,
		public tooltipSvc: TooltipService,
		private gaService: GoogleAnalyticsService
	) {
		this.StaticText = tooltipSvc.StaticText;
		this.publicationTypes = [
			{
				id: ThemePublicationType.PeerReviewed,
				name: "Peer-reviewed Scholarly Journal Publications",
				tooltip: this.StaticText['ThemePeerReviewedPublicationsToolTip']
			},
			{
				id: ThemePublicationType.OtherPeerReviewed,
				name: "Other Peer-reviewed Publications",
				tooltip: this.StaticText['ThemeOtherPeerReviewedPublicationsToolTip']
			},
			{
				id: ThemePublicationType.PopularPress,
				name: "Popular Press Publications",
				tooltip: this.StaticText['ThemePopularPressPublicationsToolTip']
			}
		];
		//cfr.resolveComponentFactory(InlineEditorComponent).c
		// filter out Activity sub types which are not connectable to a Theme
		this.activityTypes = actSvc.getActivityTypes()
			.filter(at => at.subTypes.filter((st: ActivitySubType) => st.properties['Parent'] != this.Presence.NA).length > 0);

		this.activitySubTypes = this.activityTypes.getSubTypesForCampusHr();

		authSvc.currentIdentity.subscribe(identity => 
			identity.doWithValue((i: AuthenticatedIdentity) => 
				i.user.roles.academic.doWithValue((a: Academic) =>
				{
					this.meritAndPromotionHR = a.meritAndPromotionHr;
					this.abbreviatedActivities = a.meritAndPromotionHr == HumanResources.Campus;
					if(a.meritAndPromotionHr == HumanResources.Campus){
						//TODO: remove magic numbers & dates
						this.campusYearId = 2019;
						this.campusYearDateRange = "10/1/18 to 9/30/19";
					}
				})));

	}

	TagType = TagType;

	ngAfterViewInit(){
		this.pubTypeUIs.changes.subscribe((els: QueryList<ElementRef>) => {
			this.console.log('pubTypeUIs:',els);
		})
	};

	hasInstructions() {
		if (this.getInstructions().length > 0) {
			return true;
		} 
		return false;
	}

	getInstructions(): string {
		return this.tooltipSvc.tooltip("ThemeInstructions." + this.tooltipSvc.mapMeritAndPromotionsHRToString(this.meritAndPromotionHR));
	}

	totalActivityCount(t: Theme): number {
		return Array.from(t.activityCounts.values()).reduce((a,b) => a + b, 0);
	}

	campusYearId: number = null;
	campusYearDateRange: string = null;
	themes: Theme[] = undefined;
	themeNames = {};
	themeSituations = {};
	themeNarratives = {};
	tagControls: { [themeId: number] : FormControl } = {};
	themePublications = {};
	themeActivityCounts: { [themeId: number] : { [activitySubTypeId: number] : number} } = {};

	newTheme(){
		this.matDialog.open(NewThemeModalComponent, {
			width:'500px',
			disableClose: true,
			data: { },
			hasBackdrop: true
		}).afterClosed().subscribe(result => {
			if(result == 'saved'){
				this.gaService.eventEmitter("Theme Created", "Theme", "Create");
				this.loadThemes();
			}
		});
	}

	saveThemeTags(theme: Theme, tags: SortedSet<Tag>): () => Promise<boolean> {
		return () => {
			this.console.log('saving theme tags for id = ' + theme.id);
			let tagDtos = tags.toArray().map(t => t.toDto());
			this.tagControls[theme.id].disable();
			return new Promise((resolve,reject) => 
				this.svc.tagTheme(theme.id, tagDtos).then(result => 
					result.matchDo(
						_ => {
							this.snackBar.open('Theme tags updated', null, {duration:3000});
							this.gaService.eventEmitter("Theme Tags Updated", "Theme Tags", "Update");
							theme.tags = tags.toArray();
							resolve(true)
							this.tagControls[theme.id].enable();
						},
						msg => {
							this.snackBar.open('Theme tags not updated: ' + msg, null, {duration:10000});
							resolve(false);
							this.tagControls[theme.id].enable();
						})));
		};
	}

	confirmEditStart(): () => Promise<boolean> {
		return () => new Promise((resolve,reject) => resolve(
			window.confirm('Rename Theme? If this is a new theme, consider archiving and creating a new theme.')
		));
	}

	saveThemeName(theme: Theme, name: string): () => Promise<boolean> {
		return () => {
			this.console.log('saving theme name for id = ' + theme.id);
			return new Promise((resolve,reject) => 
				this.svc.renameTheme(theme.id, name).then(result => 
					result.matchDo(
						_ => {
							this.snackBar.open('Theme name updated', null, {duration:3000});
							this.gaService.eventEmitter("Theme Name Updated", "Theme Name", "Update");
							theme.name = name;
							this.loadThemes();
							resolve(true)
						},
						msg => {
							this.snackBar.open('Theme name not updated: ' + msg, null, {duration:10000});
							resolve(false);
						})));
		};
	}

	saveThemeSituation(theme: Theme, situation: string): (() => Promise<boolean>) {
		return () => {
			this.console.log('saving theme situation for id = ' + theme.id);
			return new Promise((resolve,reject) => 
				this.svc.situateTheme(theme.id, situation).then(result => 
					result.matchDo(
						_ => {
							this.snackBar.open('Changes saved', null, {duration:3000});
							this.gaService.eventEmitter("Theme Situation Updated", "Theme Situation", "Update");
							theme.situation = situation;
							this.loadThemes();
							resolve(true)
						},
						msg => {
							this.snackBar.open('Unable to save your changes:' + msg, null, {duration:10000});
							resolve(false);
						})));
		};
	}

	saveThemeNarrative(theme: Theme, narrative: string): () => Promise<boolean> {
		return () => {
			this.console.log('saving theme narrative for id = ' + theme.id);
			return new Promise((resolve,reject) => 
				this.svc.narrateTheme(theme.id, narrative).then(result => 
					result.matchDo(
						_ => {
							this.snackBar.open('Changes saved', null, {duration:3000});
							this.gaService.eventEmitter("Theme Narrative Updated", "Theme Narrative", "Update");
							theme.narrative = narrative;
							this.loadThemes();
							resolve(true)
						},
						msg => {
							this.snackBar.open('Unable to save your changes:' + msg, null, {duration:10000});
							resolve(false);
						})));
		};
	}

	saveThemePublications(theme: Theme, pt: ThemePublicationType, pubs: string): () => Promise<boolean> {
		return () => {
			this.console.log(`saving theme ${pt} publications for id = ${theme.id}`);
			return new Promise((resolve,reject) => 
				this.svc.saveThemePubs(theme.id, pt, pubs).then(result => 
					result.matchDo(
						_ => {
							this.snackBar.open('Changes saved', null, {duration:3000});
							this.gaService.eventEmitter("Theme Publications Updated", "Theme Publications", "Update");
							theme.publications[pt] = pubs;
							this.loadThemes();
							resolve(true)
						},
						msg => {
							this.snackBar.open('Unable to save your changes:' + msg, null, {duration:10000});
							resolve(false);
						})));
		};
	}

	saveThemeActivityCounts(theme: Theme, counts: { [activitySubTypeId: number] : number }): () => Promise<boolean> {
		return () => {
			this.console.log('saving theme activity counts for id = ' + theme.id, counts);
			return new Promise((resolve,reject) => 
				this.svc.countThemeActivities(theme.id, counts).then(result => 
					result.matchDo(
					_ => {
						this.snackBar.open('Changes saved', null, {duration:3000});
						this.gaService.eventEmitter("Theme Activity Counts Updated", "Theme Activity Counts", "Update");
						Array.from(theme.activityCounts.keys()).forEach(key =>
							theme.activityCounts.set(key, counts[key] || 0));
						this.loadThemes();
						resolve(true);
					},
					msg => {
						this.snackBar.open('Unable to save your changes:' + msg, null, {duration:10000});
						resolve(false);
					})));
		};
	}

	newProject(theme: Theme){
		let wideModalInfo = this.projectService.getWideModalSettings();

		this.matDialog.open(ProjectModalComponent, {
			width: wideModalInfo.width.toString() + 'px',
			disableClose: true,
			data: {
			   	title:'Adding a Project',
				theme: theme,
				wideLayout: wideModalInfo.useWideLayout
		  	},
			hasBackdrop: true
		}).afterClosed().subscribe(result => {
			if(!isNaN(result)){
				this.gaService.eventEmitter("Theme Project Created", "Theme Project", "Create");
				this.loadThemes();
			}
		});
	}

	newActivity(theme: Theme){
		let wideModalInfo = this.activityModals.getWideActivityModalSettings(); 

		let activity = Activity.blankForSubType(ActivitySubTypeId.MeetingOrganized);
		activity.parent = Option.create<ActivityParent>(ActivityParent.fromTheme(theme));
		this.matDialog.open(NewActivityModalComponent, {
			disableClose: true,
			width: wideModalInfo.width.toString() + 'px',
			data: {
				activity: activity,
				activityTypes: this.activityTypes,
				wideLayout: wideModalInfo.useWideLayout
			},
			hasBackdrop: true
		}).afterClosed().subscribe(result => {
			if(!isNaN(result)){
				this.gaService.eventEmitter("Theme Activity Created", "Theme Activity", "Create");
				this.loadThemes();
			}
		});
	}

	viewProject(p: Project){
        this.router.navigate(['/academic/projects', p.id]);
	}

	viewActivity(a: Activity){
		this.router.navigate(['/academic/activities', a.id]);
	}

	ngOnInit() {
		this.loadThemes();
	}

	objectEqualsMap(o: { [key: number]: number }, map: Map<number,number>): boolean {
		for(var key of Array.from(map.keys())){
			if(!(key in o) || o[key] != map.get(key)){
				return false;
			}
		}
		return true;
	}

	private loadThemes(){
		this.svc.getThemes().then(themes => {
			themes.forEach(t => {
				this.console.log("theme: ", t);
				this.themeNames[t.id] = t.name;
				this.themeSituations[t.id] = t.situation;
				this.themeNarratives[t.id] = t.narrative;
				this.tagControls[t.id] = new FormControl(new SortedSet<Tag>(t.tags.slice(0)));
				this.themePublications[t.id] = t.publications;
				this.resetEditableActivityCountsFor(t);
			});
			this.themes = themes;
		});
	}

	resetEditableActivityCountsFor(t: Theme) {
		//this.themeActivityCounts[t.id] = new Map<ActivitySubTypeId, number>();
		let o = {};
		Array.from(t.activityCounts.keys()).forEach(key =>
			o[key] = t.activityCounts.get(key));
		this.themeActivityCounts[t.id] = o;
	}

	canArchive(theme: Theme) {
		return !!theme;
	}

	archive(theme: Theme) {
		if(window.confirm(this.StaticText.ArchiveTheme + '\n\nArchive this Theme? \nAre you sure you wouldn\'t like to simply rename this Theme?')){
			this.svc
				.archive(theme.id)
				.then(result => result.matchDo(
					_ => {
						let snackBarRef = this.snackBar.open('Theme Archived', 'Undo', {duration: 60 * 1000});
						this.gaService.eventEmitter("Theme Archived", "Theme", "Archive");
						snackBarRef.onAction().subscribe(() => {
							this.console.log('Theme unarchive triggered!');
							this.unarchive(theme);
						});
						//this.router.navigateByUrl('/themes/');
						this.loadThemes();
					},
					errorMsg => {
						this.snackBar.open('Unable to Archive Theme: ' + errorMsg, null, {duration: 10 * 1000});
					}));
		}
	}

	unarchive(theme: Theme) {
		this.svc
			.unarchive(theme.id)
			.then(result => result.matchDo(
				_ => {
					this.snackBar.open('Theme Restored', null, {duration: 3000});
					this.gaService.eventEmitter("Theme Restored", "Theme", "Restore");
					//this.router.navigateByUrl('/themes/');
					this.loadThemes();
				},
				errorMsg => {
					this.snackBar.open('Unable to Restore Theme: ' + errorMsg, null, {duration: 3000});
				}));
	}
}
