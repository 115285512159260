import { HasHashString } from './sorted-set';

export enum TagType
{
	StrategicInitiative = 20,
	Commodity = 30,
	UserDefined = 40,
	Statewide = 50,
	ConditionChange = 60,
	UserDefinedConditionChange = 61,
	ResearchExtension = 62,
	ProgramArea = 70,
	OutcomeType = 80
}

export const AllTagTypes: TagType[] = [
	TagType.StrategicInitiative,
	TagType.Commodity,
	TagType.Statewide,
	TagType.ConditionChange,
	TagType.UserDefinedConditionChange,
	TagType.ResearchExtension,
	TagType.ProgramArea,
	TagType.UserDefined,
	TagType.OutcomeType
];


export function GetAllTagTypesExcept(excludedTypes: TagType[]): TagType[] 
{
	return AllTagTypes.filter(tt => excludedTypes.indexOf(tt) === -1);
}

export const ThemeTagTypes: TagType[] =
	[TagType.ConditionChange,TagType.UserDefinedConditionChange,TagType.OutcomeType]

export const ActivityTagTypes: TagType[] =
	GetAllTagTypesExcept(ThemeTagTypes);

export const ProjectTagTypes: TagType[] =
	ActivityTagTypes.slice(0);



export class TagDto implements HasHashString {
	public readonly hashString: string;
	constructor(
		public type: string,
		public id: number)
	{
		this.hashString = `TagDto-${type}-${id}`;
	}
}

export function TagTypeNamePlural(type: TagType): string {
	switch(type){
		case TagType.StrategicInitiative:             return 'Strategic Initiatives';
		case TagType.Commodity:                       return 'Commodities';
		case TagType.UserDefined:                     return 'User-defined Tags';
		case TagType.Statewide:			              return 'State-wide Programs and Institutes';
		case TagType.ResearchExtension:               return 'Research and Extension Center';
		case TagType.ConditionChange:	              return 'Condition Changes';
		case TagType.UserDefinedConditionChange:	  return 'User-defined Condition Changes';
		case TagType.ProgramArea:                     return 'Program Areas';
		case TagType.OutcomeType:                     return 'Outcome Types';
	}
}

export function TagTypeName(type: TagType): string {
	switch(type){
		case TagType.StrategicInitiative:        return 'Strategic Initiative';
		case TagType.Commodity:                  return 'Commodity';
		case TagType.UserDefined:                return 'User-defined';
		case TagType.Statewide:			         return 'State-wide Program or Institute';
		case TagType.ResearchExtension:          return 'Research and Extension Center';
		case TagType.ConditionChange:	         return 'Condition Change';
		case TagType.UserDefinedConditionChange: return 'User-defined Condition Change';
		case TagType.ProgramArea:                return 'Program Area';
		case TagType.OutcomeType:                return 'Outcome Type';
	}
}

export class Tag implements HasHashString {
	public readonly hashString: string;
	public readonly typeName: string;
    public constructor(
        public readonly type: TagType,
		public readonly id: number,
		public readonly name: string)
	{
		this.hashString = `Tag-${type}-${id}`;
		this.typeName = TagTypeName(type);
	}

	public toDto(): TagDto {
		return new TagDto(TagType[this.type], this.id);
	}

	public static fromJson(o: any): Tag {
		return new Tag(
			<TagType>o.type,
			<number>o.id,
			<string>o.name);
	}
}
