import { ActivityType, ActivityTypeCollection, ActivityTypeId, CurrentAcademicUser, Option } from ".";

export class ActivityCategory {
	constructor(
		public readonly id: number,
		public readonly label: string,
		public readonly activityTypes: ActivityTypeCollection
	) { }

	public static createForAcademicUser(types: ActivityTypeCollection, cau: CurrentAcademicUser): ActivityCategory[] {
		return this.create(types);
	}

	public static createForAcademicUserOption(types: ActivityTypeCollection, cau: Option<CurrentAcademicUser>): ActivityCategory[] {
		return this.create(types);
	}

	public static create(types: ActivityTypeCollection): ActivityCategory[] {
		function cat(id: number, definitions): ActivityCategory {
			var pairs = definitions 
			.map(p => ({type: types.getTypeById(p.id), label: p.label}));
			return new ActivityCategory(
				id,
				pairs.map(p => p.label).join(''),
				new ActivityTypeCollection(pairs.map(p => p.type)));
		}
		return [
			cat(0, [
				{id: ActivityTypeId.Extension,                  label: 'Extension, '},
				{id: ActivityTypeId.Outreach,                   label: 'Effort, '},
				{id: ActivityTypeId.ExtensionAndOrOutreach,     label: ''},
				{id: ActivityTypeId.Other,                      label: ''},
				{id: ActivityTypeId.AppliedResearchAndCreative, label: 'Research, Creative'}
			]),
			cat(1, [
				{id: ActivityTypeId.UniversityAndPublicService, label: 'University and Public Service'}
			]),
			cat(2, [
				{id: ActivityTypeId.ProfessionalCompetence,     label: 'Professional Competence'}
			])
		];
	}
}

export enum ActivityCategoryId 
{
	Outreach = 0,
	Service = 1,
	Competence = 2
}