import { Component, OnInit, Input } from '@angular/core';
import { Option, SubmissionOpportunity, AcademicReportSubmissionService, SubmissionType } from '../../domain';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { timeAgo } from '../../widgets/time-ago.fn';
import { ConsoleService } from 'app/widgets/console.service';

export class AcademicReportSubmissionTextType {
	public static ReviewAndCompile = "ReviewAndCompile";
	public static FTE = "FTE";
	public static Default = "";
}

@Component({
	selector: 'academic-report-submission',
	templateUrl: './academic-report-submission.component.html',
	styleUrls: ['./academic-report-submission.component.scss']
})
export class AcademicReportSubmissionComponent implements OnInit {
	opportunity: Option<SubmissionOpportunity>;
	tooltipText: string;
	submittedText: string;
	submittedIconClass: string;
	toSubmitText: string;
	toSubmitIconClass: string;
	displayButton: boolean = true;
	@Input() type: SubmissionType = SubmissionType.ThemesProjectsActivitiesCampusMMP;
	@Input() calendarName: string = "federal fiscal year";
	@Input() dueDateWarning: string = "";
	@Input() btnClass: string = "";
	@Input() reportName: string = "";
	@Input() disabled: boolean = false;
	@Input() textType: string = AcademicReportSubmissionTextType.Default;

	constructor(
		private readonly datePipe: DatePipe,
		private readonly svc: AcademicReportSubmissionService,
		private readonly snackbar: MatSnackBar,
		private readonly console: ConsoleService
	) {
		this.opportunity = Option.create<SubmissionOpportunity>();
		this.tooltipText = "";
	}

	ngOnInit(){
		this.svc.getSubmissionOpportunity(this.type).subscribe(op => {
			this.opportunity = Option.create(op);
			this.tooltipText = this.getTooltipText(op);

			this.setButtonText(op);
		});
	}

	private setButtonText(op: SubmissionOpportunity) {

		//Don't display the submit button for Campus MP between 1/1/20xx 12:00:00 AM & 8/31/20xx 11:59:59 PM
		if (this.type == SubmissionType.ThemesProjectsActivitiesCampusMMP){
			
			const now = new Date();
			const currentYear = now.getFullYear();

			// 01/01/20xx @ 12:00:00 AM
    		const startDate = new Date(currentYear, 0, 1, 0, 0, 0);

			// 08/31/20xx @ 11:59:59 PM
			const endDate = new Date(currentYear, 7, 31, 11, 59, 59);

			this.console.log("Now: ", now);
			this.console.log("Campus MP Submission not available between " + startDate + " and " + endDate);
			
			this.displayButton = !(now >= startDate && now <= endDate);
		}
				
		switch(this.textType){
			case(AcademicReportSubmissionTextType.ReviewAndCompile):
				this.toSubmitText = "Submit for ANR Annual Organizational Reporting";
				this.toSubmitIconClass = "fa fa-fw fa-send";
				this.submittedText = "Submitted for " + op.federalFiscalYear.label + " organizational reporting";
				this.submittedIconClass = "fa fa-fw fa-check-square-o";
				break;
			case(AcademicReportSubmissionTextType.FTE):
				this.toSubmitText = "Submit";
				this.toSubmitIconClass = "fa fa-fw fa-send";
				this.submittedText = "Submitted";
				this.submittedIconClass = "fa fa-fw fa-check-square-o";
				break;				
			default: 
				this.toSubmitText = "Submit for " + op.federalFiscalYear.label;
				this.toSubmitIconClass = null;
				this.submittedText = "You submitted for " + op.federalFiscalYear.label;
				this.submittedIconClass = "fa fa-fw fa-check-square-o";
		}
	}

	private getTooltipText(o: SubmissionOpportunity): string {
		return !!o.submittedAt
			? 'You have already submitted'
			: 'You have not yet submitted';
	}

	get submitted(){
		return this.opportunity
		.match(
			() => false,
			(o: SubmissionOpportunity) => !!o.submittedAt);
	}

	submit(){
		this.opportunity.matchDo(
			() => {},
			(o: SubmissionOpportunity) => {
				let submissionDate = null;

				if (this.type == SubmissionType.ThemesProjectsActivitiesCampusMMP)
					submissionDate = this.datePipe.transform(o.federalFiscalYear.submissionsDueBy)

				if (this.type == SubmissionType.ThemesProjectsActivitiesANRMMP) {
					let tempDate = new Date(o.federalFiscalYear.submissionsDueBy);
					tempDate.setDate(9); //Set display to 12/9 but still allow submission until 12/31 - See PROJB-127 & PROJ-278
					submissionDate = this.datePipe.transform(tempDate);
				}

				let msg = 
					!!o.submittedAt
					? `You submitted ${timeAgo(o.submittedAt)}`
						+ ` (on ${this.datePipe.transform(o.submittedAt)}`
						+ ` at ${this.datePipe.transform(o.submittedAt,'h:mm:ss aa')})`
						+ '\n\n'
						+ 'Re-submit?'
					: this.reportName==="FTE"
						? `Submit your changes?`
						: `Submit for the ${o.federalFiscalYear.label} ${this.calendarName}?`;
				let dueDateMsg = 
					this.reportName==="FTE"
					? null
					: `Your due date for ${this.reportName} reporting is ${submissionDate}.`;
				msg += '\n\n' + [
					'Note:',
					dueDateMsg,
					this.dueDateWarning
				].filter(r => r!=null).join('\n');

				if(!confirm(msg)){
					return;
				}
				this.svc.submit(this.type, o.federalFiscalYear.id).then(result => {
					result.matchDo(
						_ => {
							this.snackbar.open('Submitted successfully.  Thank you!');
						},
						(error: string) => {
							this.snackbar.open('Unable to submit: ' + error);
						});
					}
				)
			});
	}
}