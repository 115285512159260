<!--
<div mat-dialog-title>
	Confirm
</div>
-->
<mat-dialog-content>
  <p>{{message}}</p>
</mat-dialog-content>
<mat-dialog-actions>
	<button class="btn btn-primary" [mat-dialog-close]="true">
		Yes
	</button>
	<button class="btn btn-secondary" [mat-dialog-close]="false">
		No
	</button>
</mat-dialog-actions>