import { ProgressionTable, ProgressionTableRank, ProgressionTableStep } from "app/domain";
import { Component, OnChanges, Input, Output, EventEmitter, ElementRef, ViewChild, ViewChildren, QueryList, HostListener, OnInit } from "@angular/core";
import { ConsoleService } from "app/widgets/console.service";
import { TitleRankStepAndTermId } from "../../../domain";

export class PositionClickEvent {
	constructor(
		public readonly table: ProgressionTable,
		public readonly rank: ProgressionTableRank,
		public readonly step: ProgressionTableStep,
		public readonly nextStep: boolean // Used for Above Scale
	){}
}

@Component({
  selector: 'progression-table',
  templateUrl: './progression-table.component.html',
  styleUrls: ['./progression-table.component.scss']
})
export class ProgressionTableComponent implements OnChanges, OnInit {
	constructor(
		private readonly console: ConsoleService
	){}

	@Input() data: ProgressionTable;
	@Output() positionClick: EventEmitter<PositionClickEvent> = new EventEmitter<PositionClickEvent>();
	@Input() selectedStep: ProgressionTableStep;
	@Input() currentPosition: TitleRankStepAndTermId;

	@ViewChild('canvasContainer') canvasContainer: ElementRef;
	@ViewChild('canvas') canvas: ElementRef;
	@ViewChildren('step') steps: QueryList<ElementRef>;

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.console.log('window:resize',event);
		this.tryDrawPromotionPaths();
	}

	ngOnInit(){
		setTimeout(() => this.tryDrawPromotionPaths(), 10);
	}

	ngOnChanges(){
		this.tryDrawPromotionPaths();
	}

	ngAfterViewInit(){
		this.steps.changes.subscribe(_ => 
			setTimeout(() => this.tryDrawPromotionPaths(), 50));
	}

	isLastRank(rank) {
		return this.data.lastRank().id == rank.id; 
	}

	isCurrentPositionLastStepOrAboveScale() {
		return this.currentPosition != undefined && this.currentPosition.stepId >= this.data.lastStep().id;
	}

	isCurrentPositionAboveScale() {
		return this.currentPosition != undefined && this.currentPosition.stepId > this.data.lastStep().id;
	}

	isSelectedPositionAboveScale() {
		return this.selectedStep != undefined && this.selectedStep.id > this.data.lastStep().id;
	}

	isAboveScaleSelected() {
		return (this.currentPosition != undefined && this.currentPosition.stepId > this.data.lastStep().id) 
			|| (this.selectedStep != undefined && this.selectedStep.id > this.data.lastStep().id);
	}

	showAboveScale(rank) {
		return this.isLastRank(rank) && (this.isCurrentPositionLastStepOrAboveScale() || this.isAboveScaleSelected());
	}

	nextStepClick_(){
		let lastStep = this.data.lastStep();
		let nextStep = new ProgressionTableStep(
			lastStep.id,
			"Next Step",
			lastStep.relationships,
			lastStep.durationInMonths
		);
		
		this.console.log("Next Step: ", nextStep);

		this.positionClick.emit(new PositionClickEvent(this.data,this.data.lastRank(),nextStep,true));
	}

	positionClick_(rank,step){
		this.positionClick.emit(new PositionClickEvent(this.data,rank,step,false));
	}

	tryDrawPromotionPaths(){
		if(!this.steps || !this.data){
			return;
		}
		let elementByRankStep = new Map<string,any>();
		this.steps.forEach(s => elementByRankStep.set(s.nativeElement.dataset.rankStep, s.nativeElement));
		let t = this.data;
		let paths = t.overlappingSteps.map(os => ({
			a: elementByRankStep.get(os.a.rankId + '/' + os.a.stepId),
			b: elementByRankStep.get(os.b.rankId + '/' + os.b.stepId)
		}))
		.map(path => ({
			a: centerOfWithRespectTo(path.a, this.canvasContainer.nativeElement),
			b: centerOfWithRespectTo(path.b, this.canvasContainer.nativeElement)
		}));
		//this.console.log('promotion paths',paths);
		let canvas = this.canvas.nativeElement;
		canvas.height = this.canvasContainer.nativeElement.offsetHeight;
		let ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		ctx.strokeStyle = '#999999';
		ctx.lineWidth = 1;
		paths.forEach(path => {
			let a_b_midpoint_x = path.a.right + (path.b.left - path.a.right)/2;
			ctx.beginPath();
			ctx.moveTo(path.a.right, path.a.centerY);
			ctx.lineTo(a_b_midpoint_x, path.a.centerY);
			ctx.lineTo(a_b_midpoint_x, path.b.centerY);
			ctx.lineTo(path.b.left,  path.b.centerY);
			ctx.stroke();
		});

		function centerOfWithRespectTo(a,container){
			let parent = a;
			let x = 0;
			let y = 0;
			while(parent != container){
				x += parent.offsetLeft;
				y += parent.offsetTop;
				parent = parent.offsetParent;
			}
			let centerX = x + a.offsetWidth/2;
			let centerY = y + a.offsetHeight/2;
			let right = x + a.offsetWidth;
			return {
				left: x + 1,
				right,
				top: y,
				centerX,
				centerY
			};
		}
	}
} 