import {Page, Activity, ActivitySpecification, SavedSearch, SavedSearchDto } from '../types/explore-whats-happening';
import { Result, Tag, TagDto } from '../types';
import { Observable } from 'rxjs';
import { Project } from '../types/explore-whats-happening/project';
import { Theme } from '../types/explore-whats-happening/theme';

export abstract class ExploreWhatsHappeningService {
    public abstract usedTags(): Observable<Tag[]>;
    public abstract explore(spec: ActivitySpecification, pageNumber: number): void;
    public abstract exploreProject(spec: ActivitySpecification, pageNumber: number): void;
    public abstract exploreTheme(spec: ActivitySpecification, pageNumber: number): void;
    public abstract currentActivityPage(): Observable<Page<Activity>>;
    public abstract currentProjectPage(): Observable<Page<Project>>;
    public abstract currentThemePage(): Observable<Page<Theme>>;
    public abstract savedSearches(): Observable<SavedSearch[]>;
    public abstract saveSearch(search: SavedSearchDto): Promise<Result<SavedSearch,string>>;
    public abstract exploreSavedSearch(searchId: number, pageNumber: number): void;
    public abstract exploreSavedSearchProject(searchId: number, pageNumber: number): void;
    public abstract exploreSavedSearchTheme(searchId: number, pageNumber: number): void;
    public abstract updateSavedSearch(searchId: number, search: SavedSearchDto): Promise<Result<SavedSearch,string>>;
    public abstract deleteSavedSearch(searchId: number): Promise<Result<null,string>>;
    public abstract downloadExploreReport(spec: ActivitySpecification):Observable<any>;
}