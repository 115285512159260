import { ProgramArea, Result, Project, ProjectDto, WideModal } from '../types';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

export abstract class ProjectService {
    abstract getWideModalSettings(): WideModal;
    abstract readonly projects: Observable<Project[]>;
    abstract getProject(id: number): Observable<Project>;
	abstract create(data: ProjectDto): Promise<Result<number,string>>;
    abstract update(id: number, data: ProjectDto): Promise<Result<number,string>>;
    abstract archive(id: number): Promise<Result<number, string>>;
    abstract unarchive(id: number): Promise<Result<number, string>>;
    abstract getAllProgramAreas(): Promise<ProgramArea[]>;
    abstract loadProjects(): void;
    abstract newProjectModal(matDialog: MatDialog): void;
    abstract editProjectInModal(matDialog: MatDialog, project: Project): void;
}
