import { LandGrantInstitution } from '../../domain';

export const LAND_GRANT_INSTITUTIONS: LandGrantInstitution[] = [
	 new LandGrantInstitution( 1,'AK','University of Alaska, Fairbanks','LandGrant')
	,new LandGrantInstitution( 2,'AL','Alabama A&M University','LandGrant')
	,new LandGrantInstitution( 3,'AL','Auburn University','LandGrant')
	,new LandGrantInstitution( 4,'AL','Tuskegee University','LandGrant')
	,new LandGrantInstitution( 5,'AR','University of Arkansas Pine Bluff','LandGrant')
	,new LandGrantInstitution( 6,'AR','University of Arkansas','LandGrant')
	,new LandGrantInstitution( 7,'AZ','University of Arizona','LandGrant')
	,new LandGrantInstitution( 8,'CA','University of California','LandGrant')
	,new LandGrantInstitution( 9,'CO','Colorado State University','LandGrant')
	,new LandGrantInstitution(10,'CT','University of Connecticut','LandGrant')
	,new LandGrantInstitution(11,'DC','University of the District of Columbia','LandGrant')
	,new LandGrantInstitution(12,'DE','Delaware State College','LandGrant')
	,new LandGrantInstitution(13,'DE','University of Delaware','LandGrant')
	,new LandGrantInstitution(14,'FL','Florida A&M University','LandGrant')
	,new LandGrantInstitution(15,'FL','University of Florida','LandGrant')
	,new LandGrantInstitution(16,'GA','Fort Valley State College','LandGrant')
	,new LandGrantInstitution(17,'GA','University of Georgia','LandGrant')
	,new LandGrantInstitution(18,'GU','University of Guam','LandGrant')
	,new LandGrantInstitution(19,'HI','University of Hawaii','LandGrant')
	,new LandGrantInstitution(20,'IA','Iowa State University','LandGrant')
	,new LandGrantInstitution(21,'ID','University of Idaho','LandGrant')
	,new LandGrantInstitution(22,'IL','University of Illinois','LandGrant')
	,new LandGrantInstitution(23,'IN','Purdue University','LandGrant')
	,new LandGrantInstitution(24,'KS','Kansas State University','LandGrant')
	,new LandGrantInstitution(25,'KY','Kentucky State University','LandGrant')
	,new LandGrantInstitution(26,'KY','University of Kentucky','LandGrant')
	,new LandGrantInstitution(27,'LA','Louisana State University','LandGrant')
	,new LandGrantInstitution(28,'LA','Southern University','LandGrant')
	,new LandGrantInstitution(29,'MA','Massachusetts Institute of Technology','LandGrant')
	,new LandGrantInstitution(30,'MA','University of Massachusetts','LandGrant')
	,new LandGrantInstitution(31,'MD','University of Maryland','LandGrant')
	,new LandGrantInstitution(32,'MD','University of Maryland, College Park','LandGrant')
	,new LandGrantInstitution(33,'ME','University of Maine','LandGrant')
	,new LandGrantInstitution(34,'MI','Michigan State University','LandGrant')
	,new LandGrantInstitution(35,'MN','University of Minnesota','LandGrant')
	,new LandGrantInstitution(36,'MO','Lincoln University','LandGrant')
	,new LandGrantInstitution(37,'MO','University of Missouri','LandGrant')
	,new LandGrantInstitution(38,'MS','Alcorn State University','LandGrant')
	,new LandGrantInstitution(39,'MS','Mississippi State University','LandGrant')
	,new LandGrantInstitution(40,'MT','Montana State University-Bozeman','LandGrant')
	,new LandGrantInstitution(41,'NC','North Carolina A&T State University','LandGrant')
	,new LandGrantInstitution(42,'NC','North Carolina State University','LandGrant')
	,new LandGrantInstitution(43,'ND','North Dakota State University','LandGrant')
	,new LandGrantInstitution(44,'NE','University of Nebraska','LandGrant')
	,new LandGrantInstitution(45,'NH','University of New Hampshire','LandGrant')
	,new LandGrantInstitution(46,'NJ','Rutgers University','LandGrant')
	,new LandGrantInstitution(47,'NM','New Mexico State University','LandGrant')
	,new LandGrantInstitution(48,'NV','University of Nevada, Reno','LandGrant')
	,new LandGrantInstitution(49,'NY','Cornell University','LandGrant')
	,new LandGrantInstitution(50,'OH','Ohio State University','LandGrant')
	,new LandGrantInstitution(51,'OK','Langston University','LandGrant')
	,new LandGrantInstitution(52,'OK','Oklahoma State University','LandGrant')
	,new LandGrantInstitution(53,'OR','Oregon State University','LandGrant')
	,new LandGrantInstitution(54,'PA','Pennsylvania State University','LandGrant')
	,new LandGrantInstitution(55,'PR','University of Puerto Rico','LandGrant')
	,new LandGrantInstitution(56,'RI','University of Rhode Island','LandGrant')
	,new LandGrantInstitution(57,'SC','Clemson University','LandGrant')
	,new LandGrantInstitution(58,'SC','South Carolina State University','LandGrant')
	,new LandGrantInstitution(59,'SD','South Dakota State University','LandGrant')
	,new LandGrantInstitution(60,'TN','Tennessee State University','LandGrant')
	,new LandGrantInstitution(61,'TN','University of Tennessee','LandGrant')
	,new LandGrantInstitution(62,'TX','Prairie View A&M University','LandGrant')
	,new LandGrantInstitution(63,'TX','Texas A&M University','LandGrant')
	,new LandGrantInstitution(64,'UT','Utah State University','LandGrant')
	,new LandGrantInstitution(65,'VA','Virginia Polytechnic Institute & State University','LandGrant')
	,new LandGrantInstitution(66,'VA','Virginia State University','LandGrant')
	,new LandGrantInstitution(67,'VI','University of the Virgin Islands','LandGrant')
	,new LandGrantInstitution(68,'VT','University of Vermont','LandGrant')
	,new LandGrantInstitution(69,'WA','Washington State University','LandGrant')
	,new LandGrantInstitution(70,'WI','University of Wisconsin-Madison','LandGrant')
	,new LandGrantInstitution(71,'WV','West Virginia State College','LandGrant')
	,new LandGrantInstitution(72,'WV','West Virginia University','LandGrant')
	,new LandGrantInstitution(73,'WY','University of Wyoming','LandGrant')
];
