import { Component, Input, ElementRef, ViewChild } from '@angular/core';

@Component({
	selector: 'empty-state',
	templateUrl: './empty-state.component.html',
	styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {
	@Input() imgSrc: string = "";
	@Input() title: string = "";
	@Input() description: string = "";
	@ViewChild('content') content: ElementRef;
}
