import { Component, HostListener, OnDestroy, Injectable, Directive } from '@angular/core';
import { AuthenticationService, Authenticated, TokenInfo, CurrentUser  } from '../../domain';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from '../../app-settings';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConsoleService } from '../../widgets/console.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

export enum AuthType {
	Main = 1,
	Prototype = 2
}

@Directive()
@Injectable()
export class AuthModalSettings {
    constructor(
		public readonly loginType: AuthType,
    ){ }
}

@Component({
    selector: 'app-authentication-modal',
    templateUrl: './authentication-modal.component.html',
    styleUrls: ['./authentication-modal.component.scss']
})
export class AuthenticationModalComponent implements OnDestroy {
	private returnUrl: string = '';
	private otherTab: Window;
	message: string = "You are not currently logged-in to the ANR Project Board.";

	constructor(		
		public dialogRef: MatDialogRef<AuthenticationModalComponent>,
		private appSettings: AppSettings,
		private router: Router,
		private console: ConsoleService,
		private authSvc: AuthenticationService,
		private snackBar: MatSnackBar,

		private authService: MsalService,
		private msalBroadcastService: MsalBroadcastService,

		private authModalSettings: AuthModalSettings,
		private gaService: GoogleAnalyticsService,
		route: ActivatedRoute) 
	{
		this.console.log("Authentication Modal Component Initialized");
		this.returnUrl = route.snapshot.queryParams['returnUrl'] || '/';
   	}

	isIframe = false;
	loginDisplay = false;

	ngOnInit() {
		this.isIframe = window !== window.parent && !window.opener;
		this.msalBroadcastService.msalSubject$
			.pipe(
				filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
			)
			.subscribe((result: EventMessage) => {
				console.log(result);
			});
	}

    logIn() {
		/*
		// for debugging and clearing of temp values or "transaction in progress" error
		this.authService.instance.handleRedirectPromise()
			.then(res=>{
				console.log(res)
			})
			.catch(err => {
				console.error(err);
			});
		*/
		//this.authService.loginRedirect();
	
		let requestObject = {
			scopes: [
				'user.read'
			],
			protectedResourceMap: [
				['https://graph.microsoft.com/v1.0/me', ['user.read']],
				['api://db5009ab-297d-4e1b-bb49-52eef780148d/api_user_access', ['api://api_user_access']]
			],
			popupWindowAttributes: {
				popupPosition: {
					top: 10,
    				left: window.screen.width / 2
				}
			}
		};

		// MSAL Authentication
		this.authService.loginPopup(requestObject)
			.subscribe({
				next: (result) => {
				this.console.log("Authentication Result: ",result);
				this.authService.acquireTokenSilent(result).subscribe((success) => {
					this.console.log(success);
				});

				this.authSvc.tryAuthenticateViaAzureAD(result)
					.then(success => {
						if(success) {
							this.gaService.eventEmitter("Authentication Success", "Authentication", "Success");
							this.dialogRef.close();
						} else {
							// login failed message
							this.snackBar.open("Login Not Successful", null, {duration: 6000});
						}
						
					});
				
				},
				error: (error) => console.log(error)
			});
		
		/*
		// original Login code
		if(this.authModalSettings.loginType == AuthType.Prototype){
			this.router.navigateByUrl('authenticate');
			this.dialogRef.close();
		} else {
			let url = this.appSettings.APIURL + '/authentication/begin?returnUrl=' + this.returnUrl;
			this.otherTab = window.open(url);
		}
		*/
    }

	@HostListener('window:message', ['$event'])
	onMessage(e) {
		if (e.origin !== this.appSettings.APIURL) {
			return;
		}
		if (!!this.otherTab) {
			let data = JSON.parse(e.data);
			this.console.log('AUTH DATA FROM SERVER:', data);
			this.authSvc.accept(Authenticated.fromJson(data));
			this.gaService.eventEmitter("Authentication Success", "Authentication", "Success");
			this.otherTab.close();
			this.dialogRef.close();
		}
	}

	ngOnDestroy() {
		this.console.log("Auth Modal Component Destroyed");
	}

	private static instance: MatDialogRef<AuthenticationModalComponent>;
	public static open(matDialog: MatDialog) {
		//console.log("open start", matDialog.openDialogs);
		// the dialog should be a singleton... so that only a single dialog is opened
		if(!!AuthenticationModalComponent.instance && AuthenticationModalComponent.instance.id == "authentication-modal") {
			//console.log("instance exists", AuthenticationModalComponent.instance);
			return AuthenticationModalComponent.instance;
		}
		
		// this fixes the instance where the authentication modal is not flushed to the screen when a modal is currently open. from: https://www.netwoven.com/2017/11/07/resolve-multiple-concurrent-angular-material-2-modal-dialog-show-issue-internet-explorer/
		matDialog.openDialogs.pop();

		//console.log("instance does not exist opening dialog");
		AuthenticationModalComponent.instance = matDialog.open(AuthenticationModalComponent, {
			id: "authentication-modal",
			disableClose: true,
			width: '500px',
			hasBackdrop: true
		});
		//console.log("After open");
		AuthenticationModalComponent.instance.afterClosed().subscribe(result => {
			AuthenticationModalComponent.instance = null;
			//console.log("Authentication Modal Closed.");
		});
		return AuthenticationModalComponent.instance;
	}
}