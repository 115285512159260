export class Lookup<TKey,TValue> implements Iterable<[TKey,TValue[]]>{
    [Symbol.iterator](): Iterator<[TKey, TValue[]]> {
        // This special method allows use via NgFor and other looping constructs.
        return this.pairs[Symbol.iterator]();
    }

    constructor(private readonly pairs: Map<TKey, TValue[]>){ }

    public get(key: TKey): TValue[] {
        return this.pairs.has(key)
            ? this.pairs.get(key)
            : [];
    }

    public forEach(action: (key: TKey, values: TValue[]) => void): void {
        for(let [key,value] of this.pairs){
            action(key,value);
        }
    }

    public static fromArray<TKey,TStartValue,TValue>(
        array: TStartValue[],
        keySelector: (value: TStartValue) => TKey,
        valueSelector: (value: TStartValue) => TValue
    ): Lookup<TKey, TValue>{
        let pairs = new Map<TKey, TValue[]>();
        array.forEach(v => {
            let key = keySelector(v);
            let value = valueSelector(v);
            if(pairs.has(key)){
                pairs.get(key).push(value);
            } else {
                pairs.set(key, [value]);
            }
        });
        return new Lookup(pairs);
    }
}