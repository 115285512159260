<section-header
	sectionTitle="Project Board"
	navPath="/explore"
	containerClass="container"
	[navLinks]="navLinks"></section-header>

<div class="container">
	<explore-whats-happening></explore-whats-happening>
</div>

<anr-footer></anr-footer>