import { Tag } from "..";
import { Academic } from './academic';
export class Project
{
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly description: string,
        public readonly collaborators: string[],
        public readonly nonANRCollaborators: string[],
        public readonly owner: Academic,
        public readonly tags: Tag[],
        public readonly humanReadableDate: string,
        public readonly startDate: Date,
        public readonly role: string,
        public readonly projectlastUpdatedDate: Date,
    ){}

    public static fromJson(o: any): Project {
        return new Project(
            o.id,
            o.name,
            o.description,
            o.collaborators,
            o.nonANRCollaborators,
            Academic.fromJson(o.owner),
            (<any[]>o.tags).map(Tag.fromJson),
            o.humanReadableDate,
            o.startDate,
            o.role,
            o.projectlastUpdatedDate);
    }
}