<ul class="nav nav-tabs nav-tabs-transparent mb-4">
	<li *ngFor="let category of categories" class="nav-item">
		<!-- the href below makes the anchor tab-indexable by the browser -->
		<a id="tab{{category.id}}" 
			class="nav-link"
			href="javascript:void(0)" 
			[class.active]="category == categories.currentItem" 
			(focus)="tabFocused($event)"
			(click)="categories.moveTo(category)">
				{{category.label}}
		</a>
	</li>
</ul>

<div class="row" id="ActivityLinks" #ActivityLinksMenu>
	<div class="col-md-8" [ngStyle]="{'column-count': layoutColumnCount, 'column-gap.rem': 2}">
		<div *ngFor="let type of categories.currentItem.activityTypes.all" style="display:inline-block; width:100%; margin-bottom:.75rem">
			<div class="media">
				<activity-type-icon
					style="width:1em;"
					[activityTypeId]="type.id"
					class="pull-left mr-3 text-muted"></activity-type-icon>
				<div class="media-body">
					<div style="font-weight: 500;">
						{{type.name}}
					</div>
					<div *ngFor="let st of type.subTypes; let isFirst = first;" style="margin-top: 0.5em;">
					<!-- the href below makes the anchor tab-indexable by the browser -->
						<a *ngIf="st.allowNewActivities"
							class="href activity-type"
							href="javascript:void(0)"
							(click)="subTypeClick.emit(st)" 
							(mouseenter)="subTypeHover(st)"
							(focusin)="subTypeHover(st)"
							(mouseout)="subTypeHoverExit()"
							(focusout)="subTypeHoverExit()"
							(focus)="focusedSubType(st,ActivityLink)"
							#ActivityLink>
								{{st.name}}
						</a>
						<a *ngIf="!st.allowNewActivities"
							class="activity-type text-muted"
							href="javascript:void(0)"
							(mouseenter)="subTypeHover(st)"
							(focusin)="subTypeHover(st)"
							(mouseout)="subTypeHoverExit()"
							(focusout)="subTypeHoverExit()"
							(focus)="focusedSubType(st,ActivityLink)"
							#ActivityLink>
								{{st.name}} (Disabled)
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-4" style="column-count:1; column-gap:2rem;">
		<h6>Activity Type Information</h6>
		<ng-container *ngIf="!!activityTypeInfo; else NoActivityType">
			<ng-container [ngSwitch]="activityTypeInfo" *ngFor="let type of activityTypes.all">
				<ng-container *ngSwitchCase="type.id">
					<ng-container *ngIf="!!definitions.get(type.id)">
						<!--<div class="info-title">{{type.name}}</div>-->
						<div class="info-body">
							{{definitions.get(type.id)}}
						</div>
					</ng-container>
				</ng-container>
			</ng-container>
		</ng-container>
		<ng-template #NoActivityType>
			<div class="info-body">
				Move cursor over Activity Type to see more information.
			</div>
		</ng-template>


		<ng-container *ngIf="!!activitySubTypeInfo">
			<ng-container [ngSwitch]="activitySubTypeInfo.id">
				<ng-container *ngSwitchCase="ActivitySubTypeId.GenericNonAcademic">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body">
						There is no need to duplicate information already entered by staff/volunteers into other systems:
						<ul>
							<li>4-H Online</li>
							<li>CalFresh System</li>
							<li>California Naturalist VMS</li>
							<li>Master Food Preserver VMS</li>
							<li>Master Gardener VMS</li>
							<li>WebNEERS (EFNEP)</li>
						</ul>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Populates Extension Activity tables in dossier export.</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.MeetingOrganized">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>
							Classes, short courses, demonstrations, field days, fairs, webinars, Extension meetings, etc. that you organize.
							If you had additional roles besides organizer, note them in the "roles" field.
						</p>
						<p>
							Tip: An Extension Activity is directly related to your own program clientele. If this activity did not serve your clientele,
							 consider putting it in University/Public Service or Evidence of Professional Competence.
						</p>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Populates Extension Activity tables in dossier export.</li>
							<li>Audience demographics included in Civil Rights Compliance analysis.</li>
							<li>Organizational reporting of Extension Activities and Extension Contacts.</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.TrainTheTrainer">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>
							Trainings you deliver to professionals who then extend information.
							For volunteer capacity building, use another activity type (e.g., Public Service, Meeting Organized).
						</p>
						<p>
							Tip: An Extension Activity is directly related to your own program clientele.
							If this activity did not serve your clientele, consider putting it in University/Public Service or Evidence of Professional Competence.
						</p>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Populates Extension Activity tables in dossier export.</li>
							<li>Audience demographics included in Civil Rights Compliance analysis.</li>
							<li>Organizational reporting of Extension Activities and Extension Contacts.</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.EducationalPresentation">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>
							Oral presentations and posters that you deliver at meetings organized by someone else.
							If you provided supervision or support for a staff member, use the "Extension Activity Delivered by Your Staff" type.
						</p>
						<p>
							Tip: An Extension Activity is directly related to your own program clientele.
							 If this activity did not serve your clientele, consider putting it in University/Public Service or Evidence of Professional Competence.
						</p>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Populates Extension Activity tables in dossier export.</li>
							<li>Audience demographics included in Civil Rights Compliance analysis.</li>
							<li>Organizational reporting of Extension Contacts.</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.ExternalCollaboration">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>
							Extension of knowledge and information via ongoing collaborations.
							Clientele served may be the collaborating organizations/agencies or the individuals reached by the collaboration,
							depending on the nature of your position/program/clientele.
						</p>
						<p>
							Tip: An Extension Activity is directly related to your own program clientele.
							If this activity did not serve your clientele, consider putting it in University/Public Service or Evidence of Professional Competence.
						</p>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Populates Extension Activity tables in dossier export.</li>
							<li>Audience demographics included in Civil Rights Compliance analysis.</li>
							<li>Organizational reporting of Extension Contacts.</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.PolicyEngagement">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>
							Extension of knowledge and information to clientele during any step in policy development and implementation,
							such as building relationships/leading coalitions;
							equipping decision-makers with the data they need to develop successful policies;
							monitoring and assessing formal decision-making by proper authorities;
							informing policy or regulatory compliance options and actions; etc.
							Report policy briefs and white papers in your non-peer reviewed publications list. Anything listed here should not be duplicated in other activity types or publications.
						</p>
						<p>
							Tip: An Extension Activity is directly related to your own program clientele.
							If this activity did not serve your clientele, consider putting it in University/Public Service or Evidence of Professional Competence.
						</p>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Populates Extension Activity tables in dossier export.</li>
							<li>Audience demographics included in Civil Rights Compliance analysis.</li>
							<li>Organizational reporting of Extension Contacts.</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.OtherExtension">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>
							As much as possible, do not use this activity type.
							Try your best to find another activity type that encompasses this activity.
							If you must use this activity type, use it sparingly.
						</p>
						<p>
							Tip: An Extension Activity is directly related to your own program clientele.
							If this activity did not serve your clientele,
							consider putting it in University/Public Service or Evidence of Professional Competence.
						</p>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Populates Extension Activity tables in dossier export.</li>
							<li>Audience demographics included in Civil Rights Compliance analysis.</li>
							<li>Organizational reporting of Extension Contacts.</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.IndividualClienteleContacts">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>
							Direct extension via technical assistance or one-on-one interactions to your own program clientele where there is a significant educational exchange.
							Consider lumping several individual clientele contacts of one clientele group into one activity record for more efficient reporting and analysis.
						</p>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Does NOT populate Extension Activity tables in the dossier export; instead summarize in your Program Summary Narrative.</li>
							<li>Audience demographics included in Civil Rights Compliance analysis.</li>
							<li>Organizational reporting of Extension Contacts.</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.WebsiteOrSocialMedia">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>
							Management of specific sites/platforms/accounts you use to extend knowledge and information.
							You may list statistics in the description field.
							You may list the number of posts or blog in the instances field.
							Extensive and substantial posts should be listed in non-peer reviewed publications.
						</p>
						<p>
							Tip: An Extension Activity is directly related to your own program clientele.
							If this activity did not serve your clientele,
							consider putting it in University/Public Service or Evidence of Professional Competence.
						</p>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Populates Extension Activity tables in dossier export.</li>
							<li>Targeted demographic groups included in Civil Rights Compliance analysis.</li>
							<li>Organizational reporting of Extension Activities.</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.MediaOutletProgramOrInterview">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>
							Radio, television or other mass media outlet programs/interviews in which you deliver significant educational content via a media outlet program or interview.
							Alternatively, if significant educational content was not delivered and the activity was used an invitation to potential clientele,
							consider using the A.R.E. Effort method, "mass media."
						</p>
						<p>
							Tip: An Extension Activity is directly related to your own program clientele. If this activity did not serve your clientele, consider putting it in University/Public Service or Evidence of Professional Competence.
						</p>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Populates Extension Activity tables in dossier export.</li>
							<li>Targeted demographic groups included in Civil Rights Compliance analysis.</li>
							<li>Organizational reporting of Extension Activities.</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.Research">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>
							Basic and applied research, program evaluation, and needs assessment activities.							
						</p>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Does NOT populate Extension Activity tables in dossier export; instead, summarize in your Program Summary Narrative.</li>
							<li>NOT included in Civil Rights Compliance analysis.</li>
							<li>Organizational reporting of Extension Activities.</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.DigitalMedia">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>
							Development of non-peer reviewed videos, applications, software, or other audio/visual educational products, recorded webinars, others.
							Print materials should be listed in non-peer reviewed publications.
						</p>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Populates Extension Activity tables in dossier export.</li>
							<li>NOT included in Civil Rights Compliance analysis.</li>
							<li>Organizational reporting of Extension Activities.</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.EducationalMaterials">
						<div class="info-title">{{activitySubTypeInfo.name}}</div>
						<div class="info-body text-sm">
							<p>
								This activity type has been removed.  Per the E-Book, Educational Materials should actually go in your bibliography.
							</p>
						</div>
					</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.OtherAppliedResearchOrCreative">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>
							As much as possible, do not use this activity type.
							Try your best to find another activity type that encompasses this activity.
							If you must use this activity type, use it sparingly.
						</p>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Does NOT populate Extension Activity tables in dossier export; instead, summarize in your Program Summary Narrative.</li>
							<li>NOT included in Civil Rights Compliance analysis.</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.IndividualOutreach">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<span [innerHTML]="tooltipSvc.tooltip('AddActivity.IndividualEffort')"></span>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.MassMediaOutreach">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>
							Mass media to invite potential clientele to participate in programs.
							Examples include press releases, public service announcements, radio and/or television appearances, social media,
							and other web-based avenues. Can be in electronic and print outlets.
							Consider lumping several mass media efforts related to one clientele group into one activity record for more efficient reporting and analysis.
							If this activity included delivery of significant educational content,
							consider using one of the "Extension and/or A.R.E. Effort" activity types so that it will be included in both dossier tables and Civil Rights Compliance analysis. 
						</p>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Does NOT populate Extension Activity tables in dossier export; instead, summarize in your Program Summary Narrative.</li>
							<li>
								Included in Civil Rights Compliance analysis.
							</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.NewsletterOutreach"> <!-- now Promotional Material Effort -->
						<div class="info-title">{{activitySubTypeInfo.name}}</div>
						<div class="info-body text-sm">
							<p>
								Promotional material to invite potential clientele to participate in programs. Examples include newsletters, posters, and flyers 
								distributed in a "mass mailing" type of process. Can be in hardcopy or electronic format. Consider lumping several promotion materials 
								efforts related to one clientele group into one activity record for more efficient reporting and analysis. Newsletters with significant 
								educational content should be reported in non-peer reviewed publications. 
							</p>
							<p>
								How this information will be used:
							</p>
							<ul>
								<li>Does NOT populate Extension Activity tables in dossier export; instead, summarize in your Program Summary Narrative.</li>
								<li>
									Included in Civil Rights Compliance analysis.
								</li>
							</ul>
						</div>
					</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.PersonalLetterOutreach">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>
							Personal letters to invite individuals from potential clientele groups to participate in programs,
							informing them of dates and times of program activities and specific invitations for them to attend and participate.
							Can be in hardcopy or electronic format.
							Consider lumping several personal letter efforts related to one clientele group into one activity record for more efficient reporting and analysis.
						</p>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Does NOT populate Extension Activity tables in dossier export; instead, summarize in your Program Summary Narrative.</li>
							<li>
								Included in Civil Rights Compliance analysis.
							</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.OtherOutreach">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>
							Examples include community groups, joint activities, membership drives, volunteer recruitment.
							These other effort methods are not federally approved for civil rights compliance,
							but can be helpful in demonstrating a good faith effort toward reaching parity. 
						</p>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Does NOT populate Extension Activity tables in dossier export; instead, summarize in your Program Summary Narrative.</li>
							<li>
								Included in Civil Rights Compliance analysis.
							</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.ExtensionByYourStaff"><!-- ????? -->
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>
							Efforts in supporting the Extension Activities of your staff/volunteers.
							Consider lumping several activities into one activity record for more efficient reporting and analysis. 
						</p>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Populates a separate table in your dossier export, per E-Book policy.</li>
							<li>Audience demographics included in Civil Rights Compliance analysis.</li>
							<li>Organizational reporting of Extension Activities and Extension Contacts.</li>
						</ul>
						<p>
							Special Instructions for the following Statewide Programs: UC 4-H, UC CalFresh Nutrition Education, California Naturalist, EFNEP, UC Master Food Preserver, UC Master Gardener:
							There is no need to duplicate participant demographics of Extension Activities delivered by staff/volunteers/partners if those demographics are already being entered in other Statewide Program online reporting systems (4hOnline, PEARS, WEBNEERS, VMS). Civil Rights Compliance analysis and organizational reporting will utilize data directly from those Statewide Programs' systems!
						</p>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.UniversityService">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>
							University service may occur at the local, division, state, national, or international level. 
						</p>
						<p>Examples:</p>
						<ul>
							<li>Advocacy efforts</li>
							<li>Committee service (which years)</li>
							<li>Workgroup chair, treasurer, secretary, etc.</li>
							<li>Leadership in strategic initiative activities and program teams</li>
						</ul>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Populates University and Public Service tables in dossier export.</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.PublicService">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>
							Public service should involve activities and events in which professional expertise is used to benefit groups or efforts outside the University.
						</p>
						<p>Examples:</p>
						<ul>
							<li>Serving on external boards, commissions or councils</li>
							<li>Participating in community events or fairs</li>
							<li>Leadership of non-University collaborative groups, councils</li>
						</ul>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Populates University and Public Service tables in dossier export.</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.ProfessionalDevelopmentAndTraining">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>Training activity examples:</p>
						<ul>
							<li>Workgroup and non-workgroup training activities</li>
							<li>Attendance at conferences, symposia and workshops</li>
							<li>Administrative or technology trainings</li>
						</ul>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Populates Professional Competence tables in dossier export.</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="ActivitySubTypeId.EvidenceOfProfessionalCompetence">
					<div class="info-title">{{activitySubTypeInfo.name}}</div>
					<div class="info-body text-sm">
						<p>Activities that reflect your professional standing.</p>
						<p>Examples:</p>
						<ul>
							<li>Conferences, meetings/trainings that you organized for professionals or colleagues (including workgroups)</li>
							<li>Professional society presentations and/or offices held</li>
							<li>Presentations you were invited to give due to your professional competence</li>
							<li>Books or journals edited, articles reviewed or refereed</li>
							<li>Webinars developed for statewide and/or nationwide peers</li>
							<li>Sabbatical/special leaves</li>
						</ul>
						<p>
							How this information will be used:
						</p>
						<ul>
							<li>Populates Professional Competence tables in dossier export.</li>
						</ul>
					</div>
				</ng-container>
			</ng-container>
		</ng-container>
	</div>
</div>
