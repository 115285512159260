import {
   	ClienteleGroupDetail,
   	ClienteleGroupDemographic,
   	ClienteleGroupSource,
   	ClienteleGroupType,
	ContactMetrics,
	DemographicInfo,
	ParityRange,
	ParityExtreme,
	ICountySelection,
	Option,
	ClienteleGroupDemographicCompliance
} from '../../domain';

import { COUNTIES } from './COUNTIES';
import { DEMOGRAPHIC_TYPES } from './DEMOGRAPHIC_TYPES';

export const CLIENTELE_GROUP_SOURCES: ClienteleGroupSource[] =
[
	new ClienteleGroupSource(10,'US Census'),
	new ClienteleGroupSource(20,'Ag Census'),
	new ClienteleGroupSource(30,'Ag Commissioner\'s Pesticide User List'),
	new ClienteleGroupSource(40,'CBEDS (California Basic Educational Data System)'),
	new ClienteleGroupSource(50,'Other')
];

function todayPlusDays(n){
	var d = new Date();
	if(!n){
		return d;
	}
	d.setDate(d.getDate() + n);
	return d;
}

/*
export class ContactMetrics
{
	constructor(
		public readonly contacts: number,
		public readonly totalContacts: number,
		public readonly contactsRatio: number){ }
}
*/

export const CLIENTELE_GROUPS: ClienteleGroupDetail[] =
	[
		new ClienteleGroupDetail(
			1,
			ClienteleGroupType.Individual,
			'Group A',
			0,
			'These are my comments',
			CLIENTELE_GROUP_SOURCES[0],
			22,
			todayPlusDays(-5),
			ICountySelection.CountySelection([COUNTIES[0]], false),
			true,
			true,
			new Map<string, ClienteleGroupDemographic>(
				new DemographicInfo(DEMOGRAPHIC_TYPES)
				.allDemographics
				.map(d => 
					new ClienteleGroupDemographic(
						d.id,
						new ContactMetrics(20, 50, .5),
						new ContactMetrics(20, 50, .5),
						new ParityRange(
							new ParityExtreme( 80, 0.4),
							new ParityExtreme(120, 0.6)
						),
						Option.create(
							new ClienteleGroupDemographicCompliance(
								true,
								false,
								true))))
				.map(d => [d.id, d] as [string, ClienteleGroupDemographic])),
			{})
		,new ClienteleGroupDetail(
			2,
			ClienteleGroupType.Family,
			'Group B',
			0,
			'These are my comments',
			CLIENTELE_GROUP_SOURCES[0],
			22,
			todayPlusDays(-5),
			ICountySelection.CountySelection([COUNTIES[0]], false),
			false,
			true,
			new Map<string, ClienteleGroupDemographic>(
				new DemographicInfo(DEMOGRAPHIC_TYPES)
				.allDemographics
				.map((d,index) => 
					new ClienteleGroupDemographic(
						d.id,
						new ContactMetrics(20, 50, .5),
						new ContactMetrics(20, 50, .5),
						new ParityRange(
							new ParityExtreme( 80, 0.4),
							new ParityExtreme(120, 0.6)
						),
						index == 0
						? Option.create<ClienteleGroupDemographicCompliance>()
						: Option.create(new ClienteleGroupDemographicCompliance(true, true, true))))
				.map(d => [d.id, d] as [string, ClienteleGroupDemographic])),
			{})
		,new ClienteleGroupDetail(
			3,
			ClienteleGroupType.Organization,
			'Group C',
			0,
			'These are my comments',
			CLIENTELE_GROUP_SOURCES[0],
			0,
			null,
			ICountySelection.CountySelection([COUNTIES[0]], false),
			true,
			true,
			new Map<string, ClienteleGroupDemographic>(
				new DemographicInfo(DEMOGRAPHIC_TYPES)
				.allDemographics
				.map(d => 
					new ClienteleGroupDemographic(
						d.id,
						new ContactMetrics(20, 50, .5),
						new ContactMetrics(20, 50, .5),
						new ParityRange(
							new ParityExtreme( 80, 0.4),
							new ParityExtreme(120, 0.6)
						),
						Option.create(new ClienteleGroupDemographicCompliance(true, true, true))))
				.map(d => [d.id, d] as [string, ClienteleGroupDemographic])),
			{})
	];
