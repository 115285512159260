
<div class="alert alert-info" *ngIf="hasInstructions()">
	<span [innerHTML]="getInstructions()"></span>
</div>

<div>
	<div *ngIf="!themes">
		Loading...
	</div>

	<empty-state *ngIf="!!themes && themes.length == 0"
	imgSrc="/assets/empty/theme.png"
	title="You don&apos;t have any themes yet"
	description="A Theme is a way to organize your Projects and Activities on your Dossier.">
		<button class="btn btn-primary btn-sm"
			(click)="newTheme()">
			+ Add Theme
		</button>
	</empty-state>

	<div *ngIf="!!themes && themes.length > 0">
		<div>
			<span class="h5" style="margin-bottom: 0;">Themes</span>
			<button class="btn btn-primary btn-sm" style="margin-left:1em;" (click)="newTheme()">
				+ Add Theme
			</button>
		</div>
		<br>
		<div class="row">
			<div class="col-md-12 mb-3" *ngFor="let t of themes">
				<div class="card project-card" nohotkey>
					<div class="card-header">
						<div class="font-weight-semi-bold">
							<inline-editor 
								[edited]="themeNames[t.id] != t.name" 
								[saveFn]="saveThemeName(t, themeNames[t.id])"
								[editStartFn]="confirmEditStart()"
								(transitionToEdit)="nameInput.focus(); nameInput.select();" 
								(cancel)="themeNames[t.id] = t.name"
								#nameEditor>
								<div readonly>
									{{t.name}}
									<em [hidden]="!!t.name">
										Click or Tap to edit
									</em>
								</div>
								<div editor>
									<input 
										type="text" 
										[(ngModel)]="themeNames[t.id]" 
										#nameInput 
										class="form-control" 
										[disabled]="nameEditor.saving">
									<div [matTooltip]="StaticText.ThemeNameToolTip">
										<i class="fa fa-fw fa-info-circle"></i>
									</div>
								</div>
							</inline-editor>
						</div>
						<div class="text-muted text-xs mt-2">
							<span>
								Last Edited:
								<span [ngSwitch]="!!t.lastUpdated">
									<span *ngSwitchCase="true" class="font-weight-semi-bold">{{t.lastUpdated | date}}</span>
									<span *ngSwitchCase="false">N/A</span>
								</span>
							</span>
							<button (click)="archive(t)" 
								class="btn btn-link btn-sm text-danger float-right" 
								[title]="StaticText.ArchiveTheme"
								*ngIf="canArchive(t)">
								<i class="fa fa-fw fa-archive"></i>
								Archive (Delete)
							</button>
						</div>
					</div>
					<div class="card-block">
						<div class="card-text">
							<div class="mb-3">
								<div class="project-label" [matTooltip]="StaticText.ThemeBackgroundToolTip">
									Background, Clientele, Goals, Inputs
									<i class="fa fa-fw fa-info-circle"></i>
								</div>
								<div class="mt-1 text-sm">
									<inline-editor 
										[edited]="themeSituations[t.id] != t.situation" 
										[saveFn]="saveThemeSituation(t, themeSituations[t.id])" 
										(cancel)="themeSituations[t.id] = t.situation"
										#situationEditor>
										<div readonly>
											<div [innerHtml]="t.situation"></div>
											<em [hidden]="!!t.situation">
												Click or Tap to edit
											</em>
										</div>
										<div editor *ngIf="situationEditor.state === 'editing'">
											<textboxio 
												[(content)]="themeSituations[t.id]"
												#situationInput></textboxio>
										</div>
									</inline-editor>
								</div>
							</div>
							<div class="mb-3">
								<div class="project-label" [matTooltip]="StaticText.ThemeMethodsToolTip">
									Methods, Outcomes, Impacts
									<i class="fa fa-fw fa-info-circle"></i>
								</div>
								<div class="mt-1 text-sm">
									<inline-editor 
										[edited]="themeNarratives[t.id] != t.narrative" 
										[saveFn]="saveThemeNarrative(t, themeNarratives[t.id])" 
										(cancel)="themeNarratives[t.id] = t.narrative"
										#narrativeEditor>
										<div readonly>
											<div [innerHtml]="t.narrative"></div>
											<em [hidden]="!!t.narrative">
												Click or Tap to edit
											</em>
										</div>
										<div editor *ngIf="narrativeEditor.state === 'editing'">
											<textboxio 
												[(content)]="themeNarratives[t.id]" 
												#narrativeInput></textboxio>
										</div>
									</inline-editor>
									<div>
										<uc-delivers-story-link></uc-delivers-story-link>
									</div>
								</div>
							</div>
							<div class="mb-3">
								<div class="project-label" [matTooltip]="StaticText.ThemeOutcomeTagsToolTip">
									Outcome Tags ({{t.tags.length}})
									<i class="fa fa-fw fa-info-circle"></i>
								</div>
								<div class="mt-1">
									<inline-editor 
										[saveFn]="saveThemeTags(t, tagControls[t.id].value)" 
										[edited]="!tagControls[t.id].value.containsExactly(t.tags)" 
										(cancel)=" tagControls[t.id].value.resetWith(t.tags)"
										#tagsEditor>
										<div readonly>
											<div [hidden]="!t.tags.length" style="padding:5px 2px;">
												<span *ngFor="let tag of t.tags">
													<tag [value]="tag"></tag>
												</span>
											</div>
											<em [hidden]="!!t.tags.length" class="text-sm">
												Click or Tap to edit
											</em>
										</div>
										<div editor>
											<tag-picker 
											    [formControl]="tagControls[t.id]" 
												[tagTypes]="availableTagTypes"></tag-picker>
										</div>
									</inline-editor>
								</div>
							</div>

							<!-- Removed per PROJB-253 -->
							<!-- <div class="row align-items-center">
								<div class="col-md-7 col-lg-8">
									<mat-menu #projectMenu="matMenu" class="text-sm" [overlapTrigger]="false">
										<button *ngFor="let p of t.projects" mat-menu-item (click)="viewProject(p)">
											{{p.name}}
										</button>
									</mat-menu>
									<button [matMenuTriggerFor]="projectMenu" class="btn btn-collapse-heading">
										{{t.projects.length}} Projects
										<i class="fa fa-fw fa-chevron-down"></i>
									</button>
								</div>
								<div class="col-md-5 col-lg-4">
									<button class="btn btn-secondary btn-round btn-block btn-sm" (click)="newProject(t)">
										+ Add New Project
									</button>
								</div>
							</div>

							<div class="row mt-3 align-items-center" *ngIf="!abbreviatedActivities">
								<div class="col-md-7 col-lg-8">
									<mat-menu #activityMenu="matMenu" class="text-sm" [overlapTrigger]="false">
										<button *ngFor="let a of t.themeActivities" mat-menu-item (click)="viewActivity(a)">
											{{a.name}}
										</button>
									</mat-menu>
									<button [matMenuTriggerFor]="activityMenu" class="btn btn-collapse-heading">
										{{t.themeActivities.length}} Theme Activities
										<i class="fa fa-fw fa-chevron-down"></i>
									</button>
								</div>
								<div class="col-md-5 col-lg-4">
									<button class="btn btn-secondary btn-round btn-block btn-sm" (click)="newActivity(t)">
										+ Add New Activity
									</button>
								</div>
							</div> -->

							<div class="mt-3" *ngIf="abbreviatedActivities">
								<div class="mb-3" *ngFor="let pt of publicationTypes">
									<div class="project-label" [matTooltip]="pt.tooltip">
										{{pt.name}}
										<i class="fa fa-fw fa-info-circle"></i>
									</div>
									<div class="mt-1 text-sm">
										<inline-editor 
											[edited]="themePublications[t.id][pt.id] != t.publications[pt.id] || true" 
											[saveFn]="saveThemePublications(t, pt.id, themePublications[t.id][pt.id])" 
											(cancel)="themePublications[t.id][pt.id] = t.publications[pt.id]"
											#pubsEditor>
											<div readonly>
												<div [innerHtml]="t.publications[pt.id]"></div>
												<em [hidden]="!!t.publications[pt.id]">
													Click or Tap to edit
												</em>
											</div>
											<div editor *ngIf="pubsEditor.state === 'editing'">
												<textboxio 
													[(content)]="themePublications[t.id][pt.id]" 
													#pubsInput></textboxio>
											</div>
										</inline-editor>
									</div>
								</div>
								<!--
								<div class="mb-3" *ngFor="let pt of publicationTypes" #pubTypeUI>
									{{pt.id}}
								</div>
								-->
							</div>
							<div class="card mt-3" *ngIf="abbreviatedActivities">
								<div class="card-header text-sm">
									Cooperative Extension Activities Count: {{totalActivityCount(t)}}
								</div>
								<inline-editor 
									[noOuterBorder]="true"
									[saveFn]="saveThemeActivityCounts(t, themeActivityCounts[t.id])" 
									[edited]="!objectEqualsMap(themeActivityCounts[t.id], t.activityCounts)" 
									(cancel)="resetEditableActivityCountsFor(t)">
									<div readonly class="card-block">
										<div class="row align-items-center">
											<div class="col-md-6 text-xs mb-2" *ngFor="let ast of activitySubTypes">
												<div class="media">
													<input class="form-control form-control-sm float-left mr-2 text-center" 
													style="width:50px; display:inline-block; background:transparent; border:0;"
													[value]="t.activityCounts.get(ast.id) || 0"
													readonly disabled>
													<div class="media-body" [matTooltip]="tooltipSvc.getSpecialistActivityCountToolTipBySubTypeId(ast.id)">
														{{ast.name}}
														<i class="fa fa-fw fa-info-circle"></i>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div editor class="card-block">
										<div class="row align-items-center">
											<div class="col-md-6 text-xs mb-2" *ngFor="let ast of activitySubTypes">
												<div class="media">
													<input type="number" min="0" step="1"
													class="form-control form-control-sm float-left mr-2 text-center"
													style="width:50px; display:inline-block;"
													[(ngModel)]="themeActivityCounts[t.id][ast.id]">
													<div class="media-body" [matTooltip]="tooltipSvc.getSpecialistActivityCountToolTipBySubTypeId(ast.id)">
														{{ast.name}}
														<i class="fa fa-fw fa-info-circle"></i>
													</div>
												</div>
											</div>
										</div>
									</div>
								</inline-editor>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
