import {
	Activity,
    ActivityCollaborationInvitation,
    ActivityFilterSpecification,
   	ActivityParent,
   	ActivityTypeCollection,
   	ActivityDto,
   	ClienteleGroup,
    ProgramArea,
   	Result,
          ConfirmActivityCollaboration
} from '../types';
import { Observable } from 'rxjs';
import { ActivityParentDto } from '../types/activity-parent-update';

export abstract class ActivityService {
	public abstract config(): Promise<null>;
    public abstract getActivityTypes(): ActivityTypeCollection;
    public abstract getCollaborationInvitations(): Promise<ActivityCollaborationInvitation[]>;
    public abstract confirmCollaboration(cmd: ConfirmActivityCollaboration): Promise<Result<number, string>>;
    public abstract ignoreCollaboration(activityId: number): Promise<Result<null, string>>;
    public abstract getActivity(id: number): Observable<Activity>;
    public abstract get activities(): Observable<Activity[]>;
    public abstract get filteredActivities(): Observable<Activity[]>;
    public abstract getActivitiesByParent(parent: ActivityParent): Observable<Activity[]>;
    public abstract saveActivity(id: number, data: ActivityDto): Promise<Result<number,string>>;
    public abstract archiveActivity(id: number): Promise<Result<number, string>>;
    public abstract unarchiveActivity(id: number): Promise<Result<number, string>>;
    public abstract createActivity(data: ActivityDto): Promise<Result<number,string>>;
    public abstract getAllProgramAreas(): Promise<ProgramArea[]>;
    public abstract getAvailableClienteleGroups(activityFilter:boolean): Promise<ClienteleGroup[]>;
    public abstract updateActivityParent(data: ActivityParentDto): void;
    public abstract getAvailableParents(): Promise<ActivityParent[]>;
    public abstract loadActivities();
    public abstract searchActivities(activityFilter: ActivityFilterSpecification);
}