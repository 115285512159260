import { Input, Component, OnInit, ElementRef, ViewChild, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable ,  merge } from 'rxjs';
import { ConsoleService } from '../console.service';
import { NonANRCollaborator, CollaboratorInstitutionType } from '../../domain';
import { NonANRCollaboratorModalComponent } from '../non-anr-collaborator-modal/non-anr-collaborator-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'non-anr-collaborator-form-control',
  templateUrl: './non-anr-collaborator-form-control.component.html',
  styleUrls: ['./non-anr-collaborator-form-control.component.scss'],
  exportAs: "NonANRCollaboratorFormControl",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: NonAnrCollaboratorFormControlComponent,
			multi: true
		}
	]
})
export class NonAnrCollaboratorFormControlComponent implements OnInit, ControlValueAccessor {
  	value: NonANRCollaborator[] = [];
	CollaboratorInstitutionType = CollaboratorInstitutionType;

  constructor(
    private matDialog: MatDialog,
    private console: ConsoleService
  ) { }

  ngOnInit() {
  }

	public edit(c: NonANRCollaborator){
		NonANRCollaboratorModalComponent
		.open(this.matDialog, c)
		.afterClosed().subscribe(result => {
			if(result instanceof NonANRCollaborator){
        this.value.splice(this.value.indexOf(c), 1, result);
        this._onChange(this.value.slice(0));
				this.console.log('edited non-anr collaborator: ', result);
			} else {
				this.console.log('non-anr collaborator modal closed without a result');
			}
		});
	}

	public create(name: string = null){
		NonANRCollaboratorModalComponent.open(
			this.matDialog,
			new NonANRCollaborator(
				name || "",
				"",
				"",
				null
			)
		).afterClosed().subscribe(result => {
			if(result instanceof NonANRCollaborator){
        		this.value.push(result);
        		this._onChange(this.value.slice(0));
				this.console.log('new non-anr collaborator: ', result);
			} else {
				this.console.log('non-anr collaborator modal closed without a result');
			}
		});
	}

	public remove(c: NonANRCollaborator){
		this.value.splice(this.value.indexOf(c),1);
    this._onChange(this.value.slice(0));
	}
	
	//
	//ControlValueAccessor implementation:
	private _onChange: any = () => {};
	private _onTouch:  any = () => {};
	writeValue(val: NonANRCollaborator[]): void {
		//console.log('writeValue:', val);
		this.value = val || [];
		//this._onChange(val);
	}
	registerOnTouched(fn): void { this._onTouch  = fn; }
  registerOnChange(fn) : void { this._onChange = fn; }
}