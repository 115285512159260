import { QueryList } from '@angular/core';

export class CircularList<T> {
	constructor(private items: QueryList<T>){ 
		items.notifyOnChanges();
		items.changes.subscribe(c => {
			this.clearActiveItem();
		});
	}

	private _activeIndex: number = -1;
	private _activeItem: T | null = null;

	get activeItem(): T | null {
		return this._activeItem;
	}

	get activeItemIndex(): number {
		return this._activeIndex;
	}

	prev(){ this.changeIndexByDelta(-1); }
	next(){ this.changeIndexByDelta( 1); }

	public changeIndexByDelta(delta: number){
		this._activeIndex += delta;
		this._ensureWithinBounds();
	}

	private _ensureWithinBounds(){
		if(this._activeIndex < 0){
			this._activeIndex = this.items.length - 1;
		}
		if(this._activeIndex >= this.items.length){
			this._activeIndex = 0;
		}
		if(0 <= this._activeIndex && this._activeIndex < this.items.length){
			this._activeItem = this.items.toArray()[this._activeIndex];
		} else {
			this.clearActiveItem();
		}
	}

	public clearActiveItem(){
		this._activeItem = null;
		this._activeIndex = -1;
	}
}
