import { Input, Output, Component, EventEmitter } from '@angular/core';
import { Commodity } from '../../domain';

@Component({
    selector: 'commodity-tree',
    templateUrl: './commodity-tree.component.html',
    styleUrls: ['./commodity-tree.component.scss']
})
export class CommodityTreeComponent {
    @Input() commodities: Commodity[] = [];
    @Output() commodityClick: EventEmitter<Commodity> = new EventEmitter<Commodity>();
}
