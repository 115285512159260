
import {debounceTime, startWith} from 'rxjs/operators';
import { Component, ElementRef, Input } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Theme } from '../../domain';
import { THEMES } from '../../demo-data';
import { partition } from 'rxjs';



import * as s from '../../widgets/search';
import { ConsoleService } from '../console.service';

@Component({
	selector: 'theme-picker',
	templateUrl: './theme-picker.component.html',
	styleUrls: ['./theme-picker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ThemePickerComponent,
			multi: true
		}
	]
})
export class ThemePickerComponent implements ControlValueAccessor  {
	selectedTheme: Theme | null = null;
	themes: Theme[] = [];
	searchControl = new FormControl();
	lastTerm = '';
	locations: Location[] = [];
	@Input() controlClass: string = '';
	@Input() inputGroupClass: string = '';
	search = 
		s.ResultsFunction.fromPredicate<Theme>(
				s.Predicate.objectStringProjectors<Theme>([
					o => o.name
				]));

	clear(){
		this.selectedTheme = null;
		this._onChange(null);
		this.searchControl.setValue('');
		this.console.log('clear');
	}

	constructor(private _element: ElementRef,
		private console: ConsoleService) {
		let [selections, termChanges] =
			partition( 
				this.searchControl
					.valueChanges
					.pipe(startWith(null)),
				o => o instanceof Theme);

		selections
			.subscribe(o => {
				let t = <Theme>o;
				this.selectedTheme = t;
				this._onChange(t);
			});

		termChanges.pipe(
			debounceTime(200))
			.subscribe(term => {
				this.lastTerm = term;
				this.themes = this.search(THEMES, term)
			});
	}

	displayWith(theme: Theme): string {
		return theme.name;
	}

	//
	//ControlValueAccessor implementation:
	writeValue(val: any): void {
		if(val) {
			let t = <Theme>val;
			if(t){
				this.selectedTheme = t;
				this.searchControl.setValue(t.name)
			} else if(typeof val == 'string'){
				this.searchControl.setValue(val);
			}
		}
	}
	private _onChange: any = () => {};
	registerOnChange (fn): void { this._onChange = fn; }
	registerOnTouched(fn): void {}
}
