import { Component, Input, PipeTransform, Pipe } from "@angular/core";
import { ActivityDate } from "../../domain";
import { DatePipe } from "@angular/common";

const format: string = 'EEE MMM d, y';

@Component({
    selector: 'activity-date',
    templateUrl: './activity-date.component.html',
    styleUrls: ['./activity-date.component.scss']
})
export class ActivityDateComponent {
    @Input('activityDate') date: ActivityDate;

    constructor(private readonly datePipe: DatePipe){ }

    private fmt(d: Date): string {
        return this.datePipe.transform(d, format);
    }
}

@Pipe({
	name: 'activityDate'
})
export class ActivityDatePipe implements PipeTransform {
    constructor(private readonly datePipe: DatePipe){ }

    private fmt(d: Date): string {
        return this.datePipe.transform(d, format);
    }

    transform(value: any, ...args: any[]) {
        return (<ActivityDate>value).match(
            single => this.fmt(single.date),
            range => this.fmt(range.startDate) + ' - ' + this.fmt(range.endDate),
            ongoing => this.fmt(ongoing.startDate) + ' (Ongoing)');
    }
}