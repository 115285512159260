import { Component, OnInit } from '@angular/core';
import{Router, NavigationEnd} from '@angular/router';
import { AppSettings } from '../app-settings';

declare let gtag: Function;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	constructor(
		public router: Router,
		private appSettings: AppSettings){   
		this.router.events.subscribe(event => {
			if(event instanceof NavigationEnd){
				gtag('config', this.appSettings.googleAnalyticsId, 
					{
						'page_path': event.urlAfterRedirects
					}
				);
			}
		}
	)}

	ngOnInit() {
		
	}
}
