<div class="mb-2" [hidden]="!selectedTags.any()">
    <span *ngFor="let tag of selectedTags">
        <tag [value]="tag" [showX]="true" (clickX)="removeTag(tag); $event.stopPropagation();"></tag>       
    </span>
</div>

<mat-form-field>
    <input type="text" 
        #input   
        matInput      
        [ngClass]="controlClass"
        placeholder="Search tags"
        class="form-control"
        [formControl]="searchControl" 
        [matAutocomplete]="auto"
        aria-label="Search tags" role="search">
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete">
    <div class="btn-area">
        <ng-container *ngIf="tagTypes.length > 1">
            <span *ngFor="let tagType of tagTypes"
                class="tag-type-icon"
                (click)="$event.preventDefault(); input.focus(); $event.stopPropagation(); changeTagType(tagType)">
                <ng-container [ngSwitch]="tagType == lastTagType">
                    <img *ngSwitchCase="false" class="img-responsive" src="/assets/tag-type/{{ TagType[tagType] }}.png" alt="{{ tagType | tagTypeName }}" title="{{ tagType | tagTypeName }}">
                    <img *ngSwitchCase="true"  class="img-responsive" src="/assets/tag-type/{{ TagType[tagType] }}-filled.png"  alt="{{ tagType | tagTypeName }}" title="{{ tagType | tagTypeName }}">
                </ng-container>
            </span>
        </ng-container>
        <div style="padding:0.25rem;" class="text-primary font-weight-bold">
            {{ lastTagType | tagTypeNamePlural }}
        </div>
    </div>

    <ng-container [ngSwitch]="lastTagType">
        <ng-container *ngSwitchCase="TagType.Commodity">

            <ng-container *ngFor="let c of commodities">
                <mat-option [value]="c.tag" (click)="selectTag(c.tag)">
                    {{c.name}}
                </mat-option>

                <ng-container *ngTemplateOutlet="commodityChildren; context:c"></ng-container>
            </ng-container>

            <ng-template #commodityChildren let-children="children">
            
                <div *ngFor="let cc of children" style="margin-left:1em;">

                    <mat-option [value]="cc.tag" (click)="selectTag(cc.tag)">
                        {{cc.name}}
                    </mat-option>

                    <ng-container *ngTemplateOutlet="commodityChildren; context:cc"></ng-container>
                </div>

            </ng-template>

            <div style="padding:1em;" *ngIf="commodities.length == 0" class="text-muted">
                <mat-option [disabled]="true">
                    No commodities found by that name.
                </mat-option>
            </div>
        </ng-container>

        <ng-container *ngSwitchDefault>
            <mat-option *ngFor="let tag of tags" [value]="tag" (click)="selectTag(tag)">
                <ng-container *ngIf="tooltipSvc.tooltip('TagDisplayText.'+tag.hashString); else defaultLabel">
                    {{ tooltipSvc.tooltip('TagDisplayText.'+tag.hashString) }}
                </ng-container>
                <ng-template #defaultLabel>
                    {{tag.name}}
                </ng-template>
            </mat-option>

            <div style="padding:1em;" *ngIf="tags.length == 0" class="text-muted">
                <ng-container *ngIf="!!input.value">
                    <mat-option [disabled]="true">
                        No {{lastTagType | tagTypeNamePlural }} found by that name.
                    </mat-option>
                </ng-container>

                <ng-container *ngIf="!input.value">
                    <mat-option [disabled]="true">
                        Simply start typing to begin defining a new user-defined tag.
                    </mat-option>    
                </ng-container>
                <div *ngIf="mayCreateUserDefinedTagFor(input.value)" class="mt-3">
                    <p>
                        Would you like to create a new user-defined tag for <code>{{input.value}}</code>?
                    </p>
                    <mat-option [value]="input.value" (click)="createUserDefinedTag(input.value); input.value = '';">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        Create user-defined Tag
                    </mat-option>
                </div>
            </div>
        </ng-container>
    </ng-container>
</mat-autocomplete>