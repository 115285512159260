export class ConditionChange {
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly userDefined: boolean = false
    ) { }

	public static fromJson(o: any): ConditionChange {
		return new ConditionChange(o.id, o.name, o.userDefined);
	}
}
