import { Component, Input, Output, EventEmitter, ViewChildren, QueryList, Inject } from '@angular/core';
import {
   	ActivityTypeId,
   	ActivityType,
   	ActivityTypeCollection,
   	ActivitySubType,
   	ActivityService, 
	ActivitySubTypeId,
	ActivityCategory,
	CircularArray
} from '../../domain';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConsoleService } from '../console.service';

@Component({
	selector: 'activity-type-menu-modal',
	templateUrl: './activity-type-menu-modal.component.html',
	styleUrls: ['./activity-type-menu-modal.component.scss']
})
export class ActivityTypeMenuModalComponent {
	public static readonly Width: string = '100vw';
	public static readonly Height: string = '750px';

	@Input() activityTypes: ActivityTypeCollection;
	activityCategories: CircularArray<ActivityCategory>;
	startingActivityCategoryId: number = 0;

	constructor(
		private console: ConsoleService,
		public dialogRef: MatDialogRef<ActivityTypeMenuModalComponent>,
		@Inject(MAT_DIALOG_DATA) data: any) 
	{	
		this.console.log('MAT_DIALOG_DATA',data);
		if(!('activityTypes' in data)){
			throw 'activityTypes REQUIRED';
		}
		if(!('activityCategories' in data)){
			throw 'activityCategories REQUIRED';
		}
		this.activityTypes = data.activityTypes;
		this.activityCategories = data.activityCategories;
		this.startingActivityCategoryId = data.startingActivityCategoryId;
	}

	startNewActivity(subType: ActivitySubType){
		this.console.log("Activity Clicked: ", subType);
		this.dialogRef.close(subType);
	}

	close() {
		this.dialogRef.close();
	}
}