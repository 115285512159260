export class ProgramArea {
    constructor(
        public id: number,
        public name: string,
        public disabled: boolean
    ) { }

	public static fromJson(o: any): ProgramArea {
		return new ProgramArea(o.id, o.name, o.disabled);
	}
}
