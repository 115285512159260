import {
   	ClienteleGroupDetail,
	HistoricalClienteleGroup,
   	ClienteleGroupDto,
   	ClienteleGroupSource,
   	DemographicInfo,
   	Result, 
	County
} from '../types';

export abstract class ClienteleService {
	public abstract config(): Promise<null>;
    public abstract getClienteleGroups(): Promise<ClienteleGroupDetail[]>;
    public abstract getHistoricalClienteleGroups(): Promise<HistoricalClienteleGroup[]>;
	public abstract getHistoricalClienteleGroupDetail(year: number): Promise<ClienteleGroupDetail[]>
    public abstract createGroup(data: ClienteleGroupDto): Promise<Result<number,string>>;
    public abstract updateGroup(id: number, data: ClienteleGroupDto): Promise<Result<number,string>>;
	public abstract getDemographicInfo(): DemographicInfo;
	public abstract getAvailableSources(): Promise<ClienteleGroupSource[]>;
	public abstract getCounties(): Promise<County[]>;
	public abstract getAvailableCounties(): Promise<County[]>;
	public abstract saveComplianceNotes(clienteleGroupId: number, data: ComplianceNotesDto): Promise<Result<null,string>>;
}

export class ComplianceNotesDto {
	constructor(
		public readonly federalFiscalYearId: number,
		public readonly notes: string
	){ }
}