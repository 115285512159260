import { Component, OnInit, Input } from '@angular/core';
import { Activity } from '../../domain';

@Component({
  selector: 'activity-collaboration-flag',
  templateUrl: './activity-collaboration-flag.component.html',
  styleUrls: ['./activity-collaboration-flag.component.scss']
})
export class ActivityCollaborationFlagComponent {
  @Input() activity: Activity;
}