<div mat-dialog-title>
	Select an advancement
</div>

<mat-dialog-content style="min-height:300px;">
	<time-served-table [data]="timeServed" class="mb-3">
	</time-served-table>

	<progression-table 
		[data]="table" 
		(positionClick)="positionClick($event)" 
		[selectedStep]="selectedStep"
		[currentPosition]="currentPosition">
	</progression-table>

	<term-table 
		(termClick)="termClick($event)"
		[currentPosition]="currentPosition"
		[selectedTerm]="selectedTerm">
	</term-table>
</mat-dialog-content>

<mat-dialog-actions>
	<button (click)="cancel()" class="btn btn-link" [disabled]="closing">
		Cancel
	</button>
	<button (click)="select()" class="btn btn-primary" [disabled]="!canSelect() || closing">
		Select
	</button>
</mat-dialog-actions>