import { CurrentUser } from "./user";
import { Option } from ".";

export class HeartbeatResult {
    constructor(
		public readonly authenticatedUser: Option<CurrentUser>,
		public readonly currentApplicationVersion: string){ }

	public static fromJson(o: any): HeartbeatResult {
		return new HeartbeatResult(
			Option.fromJson(o.authenticatedUser, CurrentUser.fromJson),
			o.currentApplicationVersion);
	}
}