import { Component, Inject, Input, OnInit } from '@angular/core';
import { Activity, ActivityParent, ActivityService } from '../../domain';
import { ActivityParentDto } from '../../domain/types/activity-parent-update';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'activity-bulk-update',
  templateUrl: './activity-bulk-update.component.html',
  styleUrls: ['./activity-bulk-update.component.scss']
})
export class ActivityBulkUpdateComponent implements OnInit {
  selectedActivityCount: number=0;
	activities: Activity[] = [];
  activityParent: ActivityParent = null;
  closing = false;
  constructor(public svc: ActivityService,
    public dialogRef: MatDialogRef<ActivityBulkUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) { 
      this.activities = data.activities;
      this.selectedActivityCount =data.selectedActivityCount;
    }

  ngOnInit(): void {
  }
	save(){
    this.closing = true;
    let activityIds = this.activities.filter(x=> x.checked).map(x=> x.id);
    let activityParent = new ActivityParentDto(this.activityParent, activityIds);
    this.svc.updateActivityParent(activityParent);
    setTimeout(() =>
      this.dialogRef.close()
    ,300);
   
 }
 public static open(matDialog: MatDialog,activities: Activity[], selectedActivityCount: number): MatDialogRef<ActivityBulkUpdateComponent>{
  return matDialog.open(ActivityBulkUpdateComponent, {
    width: '600px',
    height:'260px',
    disableClose: false,
    data: {activities,selectedActivityCount },
    hasBackdrop: true,
    autoFocus:false
  });
}
 cancel() {
  this.dialogRef.close();
 } 
}
