import { 
	STRATEGIC_INITIATIVE_TAGS as SI,
	PROGRAM_AREA_TAGS as PA,
	USER_DEFINED_TAGS as UD
} from './TAGS'
import { CLIENTELE_GROUPS } from './CLIENTELE_GROUPS';
import { THEMES } from './THEMES';
import { ACTIVITY_PARENTS, THEME_ACTIVITY_PARENTS, PROJECT_ACTIVITY_PARENTS } from './ACTIVITY_PARENTS';
import { USERS } from './USERS';
import { 
	Activity,
	ActivityDate,
	Single,
	ActivitySubTypeId,
	ActivityParent,
	ClienteleContactCollection,
	ClienteleGroup,
	Option,
	Tag,
	User,
	SortedSet
} from '../../domain';

function todayPlusDays(n): ActivityDate {
	var d = new Date();
	if(!n){
		return new Single(d);
	}
	d.setDate(d.getDate() + n);
	return new Single(d);
}

function someThemeParent(index: number): Option<ActivityParent>{
	return Option.create(THEME_ACTIVITY_PARENTS[index]);
}

function someProjectParent(index: number): Option<ActivityParent>{
	return Option.create(PROJECT_ACTIVITY_PARENTS[index]);
}

function a(
	id: number,
	name: string,
	subTypeId: ActivitySubTypeId,
	parent: Option<ActivityParent>,
	tags: Tag[],
	date: ActivityDate,
	topic: string,
	role: string,
	location: string | null,
    programAreaId : number | null,
	collaborators: User[],
	clienteleGroup: ClienteleGroup | null = null)
{
	return new Activity(
		id,
		subTypeId,
		name,
		date,
		parent,
		topic,
		role,
		location,
        null,
        '',
        null, // repetitions
        null, // instances
        programAreaId,
		Option.create<ClienteleGroup>(clienteleGroup),
		ClienteleContactCollection.blank(),
		[], //TargetedDemographics
        new SortedSet<Tag>(tags),
        [], // invalid reasons
        new SortedSet<User>(collaborators),
        [],
        null, // Internal Attendees
        Option.create(),
        false);
}

export const ACTIVITIES: Activity[] = [
    a(
        68,
        "Konocti Unified School District (Lake)",
        ActivitySubTypeId.ExternalCollaboration,
        someProjectParent(1),
        [
            SI[4]
        ],
        todayPlusDays(3), // "6/11/2017"
		'',
		'',
		null,
        null,
		USERS.slice(0,3)
    ),
    a(
        10,
        "4-H Executive Council Meeting (Lake)",
        ActivitySubTypeId.MeetingOrganized,
        someThemeParent(3),
        [],
        todayPlusDays(6), // new Date("6/14/2017")
		'',
		'',
		null,
        null,
		USERS.slice(0,1)
    ),
    a(
        40,
        "structural pest control operators and individual pest management professionals",
        ActivitySubTypeId.IndividualClienteleContacts,
        someProjectParent(2),
        [
            PA[3],
            SI[3]
        ],
        todayPlusDays(10), //new Date("6/18/2017")
		'',
		'',
		null,
        null,
		USERS.slice(2,4),
		CLIENTELE_GROUPS[0]
    ),
    a(
        60,
        "Workshop Organized by Dr. Jennifer Sowerwine",
        ActivitySubTypeId.EducationalPresentation,
        Option.create<ActivityParent>(),
        [],
        todayPlusDays(10), //new Date("6/18/2017")
		'',
		'',
		null,
        null,
		USERS.slice(1,2)
    ),
    a(
        2,
        "Landscape Pest Identification and diagnosing plant pest problems.",
        ActivitySubTypeId.MeetingOrganized,
        someProjectParent(2),
        [
            SI[1],
            UD[3]
        ],
        todayPlusDays(14), // new Date("6/22/2017")
		'',
		'',
		null,
        null,
		USERS.slice(3,4)
    ),
    a(
        8,
        "Annual Meeting, California Weed Science Society (collaborative, co-author presentation)",
        ActivitySubTypeId.EducationalPresentation,
        someProjectParent(0),
        [
            SI[3]
        ],
        todayPlusDays(17),  // new Date("6/25/2017")
		'',
		'',
		null,
        null,
		USERS.slice(4,5)
    ),
    a(
        56,
        "Bend School Family event",
        ActivitySubTypeId.MeetingOrganized,
        someThemeParent(1),
        [
            SI[4],
            UD[3]
        ],
        todayPlusDays(21), //new Date("6/29/2017")
		'',
		'',
		null,
        null,
		USERS.slice(5,6)
    ),
    a(
        94,
        "Rice growers and pest control advisers",
        ActivitySubTypeId.IndividualClienteleContacts,
        someProjectParent(1),
        [
            SI[2],
            UD[2]
        ],
        todayPlusDays(21), //new Date("6/29/2017")
		'',
		'',
		null,
        null,
		USERS.slice(0,3)
    ),
    a(
        76,
        "\'See and solve: diagnosing landscape plant problems in situ\'",
        ActivitySubTypeId.MeetingOrganized,
        someProjectParent(2),
        [],
        todayPlusDays(22), //new Date("6/30/2017")
		'',
		'',
		null,
        null,
		USERS.slice(5,6)
    ),
    a(
        96,
        "Advanced IPM training, Santa Clara County UCCE Master Gardener program",
        ActivitySubTypeId.EducationalPresentation,
        Option.create<ActivityParent>(),
        [],
        todayPlusDays(23), //new Date("7/1/2017")
		'',
		'',
		null,
        null,
		USERS.slice(5,6)
    ),
    a(
        65,
        "Project results dissemination to Duda Fresh Vegetables",
        ActivitySubTypeId.MeetingOrganized,
        someThemeParent(3),
        [
            PA[4]
        ],
        todayPlusDays(-1), // new Date("6/7/2017")
		'',
		'',
		null,
        null,
		USERS.slice(5,6)
    ),
    a(
        27,
        "Annual Meeting, California Weed Science Society (collaborative, co-author presentation)",
        ActivitySubTypeId.EducationalPresentation,
        someThemeParent(0),
        [],
        todayPlusDays(-2), // new Date("6/6/2017")
		'',
		'',
		null,
        null,
		USERS.slice(0,3)
    ),
    a(
        5,
        "CAPCA Adviser Magazine",
        ActivitySubTypeId.MediaOutletProgramOrInterview,
        someThemeParent(3),
        [
            SI[1],
            UD[2],
            PA[3]
        ],
        todayPlusDays(-5), // new Date("6/3/2017")
		'',
		'',
		null,
        null,
		USERS.slice(2,4)
    ),
    a(
        23,
        "Tehama Educare Head Start Event",
        ActivitySubTypeId.EducationalPresentation,
        Option.create<ActivityParent>(),
        [],
        todayPlusDays(-5), //new Date("6/3/2017")
		'',
		'',
		null,
        null,
		USERS.slice(4,5)
    ),
    a(
        81,
        "UC Food Observer Website and Blog",
        ActivitySubTypeId.MediaOutletProgramOrInterview,
        someThemeParent(0),
        [],
        todayPlusDays(-5), //new Date("6/3/2017")
		'',
		'',
		null,
        null,
		USERS.slice(4,5)
    ),
    a(
        89,
        "Community Club Leader Training",
        ActivitySubTypeId.MeetingOrganized,
        someThemeParent(3),
        [],
        todayPlusDays(-10), // new Date("5/29/2017")
		'',
		'',
		null,
        null,
		USERS.slice(0,5)
    ),
    a(
        75,
        "CoCoSan Sustainable Farms educational program",
        ActivitySubTypeId.EducationalPresentation,
        Option.create<ActivityParent>(),
        [],
        todayPlusDays(-15), // new Date("5/24/2017")
		'',
		'',
		null,
        null,
		USERS.slice(0,1)
    ),
    a(
        83,
        "'Integrated pest management toolkit for family child care homes': http://cchp.ucsf.edu/content/family-child-care-homes ",
        ActivitySubTypeId.EducationalMaterials,
        someProjectParent(2),
        [],
        todayPlusDays(-16), // new Date("5/23/2017")
		'',
		'',
		null,
        null,
		USERS.slice(0,2)
    ),
    a(
        7,
        "CVDRMP Irrigation planning meeting",
        ActivitySubTypeId.EducationalPresentation,
        someProjectParent(3),
        [
            UD[4],
            PA[20]
        ],
        todayPlusDays(-20), // new Date("5/19/2017")
		'',
		'',
		null,
        null,
		USERS.slice(2,3)
    ),
    a(
        29,
        "Advanced Organic Soils Management=Workshops #3 of 3 Workshop Series,",
        ActivitySubTypeId.MeetingOrganized,
        someThemeParent(0),
        [],
        todayPlusDays(-20), // new Date("5/19/2017")
		'',
		'',
		null,
        null,
		USERS.slice(1,4)
    )
];
