import { County, ICountySelection } from './county';
import { Option } from './option';

export enum ClienteleGroupType {
	Individual = 10,
	Family = 20,
	Organization = 30
}
export class ClienteleGroup {
	constructor(
		public id: number,
		public type: ClienteleGroupType,
		public title: string){ }

	public static fromJson(o: any): ClienteleGroup {
		return new ClienteleGroup(
			<number>o.id,
			<ClienteleGroupType>o.type,
			<string>o.title);
	}
}

export class HistoricalClienteleGroup {
	constructor(
		public id: number,
		public federalFiscalYearId: number
	) {}
	
	public static fromJson(o: any): HistoricalClienteleGroup {
		return new HistoricalClienteleGroup(
			<number> o.id,
			<number>o.federalFiscalYearId
		);
	}
}

export class ClienteleGroupDetail {
	constructor(
		public id: number,
		public type: ClienteleGroupType,
		public title: string,
		public federalFiscalYearId: number,
		public description: string,
		public source: ClienteleGroupSource,
		public totalActivityCount: number,
		public lastActivity: Date | null,
		public countySelection: ICountySelection,
		public civilRightsCompliant: boolean,
		public consideredInParity: boolean,
		public demographics: Map<string,ClienteleGroupDemographic>,
		public complianceNotesByFederalFiscalYearId: {[ffyId: number] : string}
	) { }

	public static fromJson(o: any): ClienteleGroupDetail {
		let demos = Object
			.keys(o.demographics)
			.map(key => [key, ClienteleGroupDemographic.fromJson(o.demographics[key])] as [string, ClienteleGroupDemographic]);
		return new ClienteleGroupDetail(
			<number> o.id,
			<ClienteleGroupType> o.type,
			<string>o.title,
			<number>o.federalFiscalYearId,
			<string>o.description,
			<ClienteleGroupSource> o.source,
			<number>o.totalActivityCount,
			o.lastActivity && new Date(o.lastActivity),
			ICountySelection.CountySelection(<County[]>o.counties, o.statewide),
			o.civilRightsCompliant,
			o.consideredInParity,
			new Map<string, ClienteleGroupDemographic>(demos),
			o.complianceNotesByFederalFiscalYearId
		);
	}

	public equals(other: ClienteleGroup): boolean {
		return this.id == other.id;
	}

	simplify(): ClienteleGroup {
		return new ClienteleGroup(this.id, this.type, this.title);
	}

	merge(other: ClienteleGroupDetail){
		this.id = other.id;
		this.type = other.type;
		this.title = other.title;
		this.description = other.description;
		this.source = other.source;
		this.totalActivityCount = other.totalActivityCount;
		this.countySelection = other.countySelection;
		this.civilRightsCompliant = other.civilRightsCompliant;
		this.consideredInParity = other.consideredInParity;
		this.demographics = other.demographics;
	}

	public duplicate(): ClienteleGroupDetail {
		return new ClienteleGroupDetail(
			this.id,
			this.type,
			this.title,
			this.federalFiscalYearId,
			this.description,
			this.source,
			this.totalActivityCount,
			this.lastActivity,
			this.countySelection,
			this.civilRightsCompliant,
			this.consideredInParity,
			this.demographics,
			this.complianceNotesByFederalFiscalYearId
		);
	}
}

export class ClienteleGroupDto {
	public countyIds: number[];
	public statewide: boolean;

	constructor(
        public title: string,
        public typeId: ClienteleGroupType,
        public sourceId: number,
        public otherSourceName: string,
		public description: string,
		public archived: boolean,
		private readonly countySelection: ICountySelection,
		public demographicCounts: {[demographicId: string]: number}
	) {
		this.statewide = countySelection.Statewide;
		this.countyIds = countySelection.Counties.map(c => c.id);
	}
}

export class TargetedOutreach
{
	constructor(
		public readonly id: string,
		public readonly activityCounts: Map<string, number>){ }
}

export class ClienteleGroupDemographic 
{
	constructor(
		public readonly id: string,
		public readonly potential: ContactMetrics,
		public readonly actual: ContactMetrics,
		public readonly parityRange: ParityRange,
		public readonly compliance: Option<ClienteleGroupDemographicCompliance>
	){ }

	public static fromJson(o: any): ClienteleGroupDemographic {
		return new ClienteleGroupDemographic(
			o.id,
			o.potential,
			o.actual,
			o.parityRange,
			Option.fromJson(o.compliance,
				c => new ClienteleGroupDemographicCompliance(
					c.consideredInParity,
					c.demonstratesAllReasonableEfforts,
					c.civilRightsCompliant
				)));
	}
}

export class ClienteleGroupDemographicCompliance {
	constructor(
		public readonly consideredInParity: boolean,
		public readonly demonstratesAllReasonableEfforts: boolean,
		public readonly civilRightsCompliant: boolean){}
}

export class ClienteleGroupSource
{
	constructor(
		public readonly id: number,
		public readonly name: string) {}
}

export class ParityRange
{
	constructor(
		public readonly low: ParityExtreme,
		public readonly high: ParityExtreme){ }
}

export class ParityExtreme
{
	constructor(
		public readonly percentage: number,
		public readonly value: number) { }
}

export class ContactMetrics
{
	constructor(
		public readonly contacts: number,
		public readonly totalContacts: number,
		public readonly contactsRatio: number){ }
}
