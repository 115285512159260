import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { MainModule } from './app/main/main.module';
import { environment } from './environments/environment';

if (environment.enableProdMode) {
	enableProdMode();
}

{
	var favicon = document.createElement('link');
	favicon.id = 'appFavicon';
	favicon.rel = 'shortcut icon';
	favicon.type = "image/x-icon";
	favicon.href = environment.enableProdMode ? 'favicon.ico' : 'favicon-bar-gold.ico';
	document.head.appendChild(favicon);
}

platformBrowserDynamic().bootstrapModule(MainModule);