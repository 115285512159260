<ng-container [ngSwitch]="!!activityCategory ? activityCategory.id : 0">

	<!-- University / Public Service  -->
	<ng-container *ngSwitchCase="1"> 
		<div class="row text-muted sorter-row">
			<div [ngClass]="columnClass('name')">
				<sort-column-header 
					[column]="sortColumns.name"
					[(currentSorting)]="currentSorting"></sort-column-header>
			</div>
			<div [ngClass]="columnClass('subType')">
				<sort-column-header 
					[column]="sortColumns.subTypeId"
					[(currentSorting)]="currentSorting"></sort-column-header>
			</div>
			<div [ngClass]="columnClass('areaOfService')">
				<sort-column-header 
					[column]="sortColumns.areaOfService"
					[(currentSorting)]="currentSorting"></sort-column-header>
			</div>
			<div [ngClass]="columnClass('role')">
				<sort-column-header 
					[column]="sortColumns.role"
					[(currentSorting)]="currentSorting"></sort-column-header>
			</div>
			<div class="text-right text-nowrap" [ngClass]="columnClass('date')">
				<sort-column-header 
					[column]="sortColumns.date"
					[(currentSorting)]="currentSorting"></sort-column-header>
			</div>
		</div>

		<div *ngIf="activities.length == 0"
			class="text-center text-muted"
			style="padding:1rem; font-size:1rem;">
				<i class="fa fa-fw fa-search"></i>
				No Activites Found
		</div>

		<div class="scroll-viewport" [class.fadeToGray]="fadeToGray">
			<div class="fade-out-top"></div>
			<div class="scroll-container scrollbar-thin" 
			[ngStyle]="{
				maxHeight:maxHeight,
				paddingRight: paddingRight,
				paddingLeft: paddingLeft
			}">

				<a *ngFor="let activity of activities"
				class="card activity-card" (click)="activityClick.emit(activity)"
				[class.highlighted]="highlightedActivity == activity">
					<div class="card-block align-items-center">
						<div class="row align-items-center">

							<div [ngClass]="columnClass('name')">
								<div class="media align-items-center">
									<div class="pull-left">
										<activity-type-icon
											[activityTypeId]="types.getTypeIdBySubTypeId(activity.subTypeId)"
											class="mr-3 text-muted"></activity-type-icon>
									</div>
									<div class="media-body">
										<span>{{activity.name}}</span>
										<activity-collaboration-flag [activity]="activity"></activity-collaboration-flag>
									</div>
								</div>
							</div>

							<div [ngClass]="columnClass('subType')">
								<activity-invalid-reason-icon [messages]="activity.invalidReasons"></activity-invalid-reason-icon>
								<span>{{getActivitySubTypeName(activity)}}</span>
							</div>

							<div [ngClass]="columnClass('areaOfService')">
									{{getActivityAreaOfServiceName(activity)}}
							</div>

							<div [ngClass]="columnClass('role')">
								{{getActivityRoleName(activity)}}
							</div>

							<div class="text-right text-nowrap" [ngClass]="columnClass('date')">
								<activity-date [activityDate]="activity.date"></activity-date>
							</div>

						</div>
					</div>
				</a> 
			</div>
			<div class="fade-out-bottom"></div>
		</div>
	</ng-container>

	<!-- Professional Competence and Evidence of Professional Competence and Training  -->
	<ng-container *ngSwitchCase="2"> 
		<div class="row text-muted sorter-row">
			<div></div>
			<div [ngClass]="columnClass('name')">
				<sort-column-header 
					[column]="sortColumns.name"
					[(currentSorting)]="currentSorting"></sort-column-header>
			</div>
			<div [ngClass]="columnClass('subType')">
				<sort-column-header 
					[column]="sortColumns.subTypeId"
					[(currentSorting)]="currentSorting"></sort-column-header>
			</div>
			<div [ngClass]="columnClass('location')">
				<sort-column-header 
					[column]="sortColumns.location"
					[(currentSorting)]="currentSorting"></sort-column-header>
			</div>
			<div class="text-right text-nowrap" [ngClass]="columnClass('date')">
				<sort-column-header 
					[column]="sortColumns.date"
					[(currentSorting)]="currentSorting"></sort-column-header>
			</div>
		</div>

		<div *ngIf="activities.length == 0"
			class="text-center text-muted"
			style="padding:1rem; font-size:1rem;">
				<i class="fa fa-fw fa-search"></i>
				No Activites Found
		</div>

		<div class="scroll-viewport" [class.fadeToGray]="fadeToGray">
			<div class="fade-out-top"></div>
			<div class="scroll-container scrollbar-thin" 
			[ngStyle]="{
				maxHeight:maxHeight,
				paddingRight: paddingRight,
				paddingLeft: paddingLeft
			}">
				<a *ngFor="let activity of activities"
				class="card activity-card" (click)="activityClick.emit(activity)"
				[class.highlighted]="highlightedActivity == activity">
					<div class="card-block align-items-center">
						<div class="row align-items-center">

							<div [ngClass]="columnClass('name')">
								<div class="media align-items-center">
									<div class="pull-left">
										<activity-type-icon
											[activityTypeId]="types.getTypeIdBySubTypeId(activity.subTypeId)"
											class="mr-3 text-muted"></activity-type-icon>
									</div>
									<div class="media-body">
										<span>{{activity.name}}</span>
										<activity-collaboration-flag [activity]="activity"></activity-collaboration-flag>
									</div>
								</div>
							</div>

							<div [ngClass]="columnClass('subType')">
								<activity-invalid-reason-icon [messages]="activity.invalidReasons"></activity-invalid-reason-icon>
								<span>{{getActivitySubTypeName(activity)}}</span>
							</div>

							<div [ngClass]="columnClass('location')">
								{{getLocationName(activity)}}
							</div>

							<div class="text-right text-nowrap" [ngClass]="columnClass('date')">
								<activity-date [activityDate]="activity.date"></activity-date>
							</div>

						</div>
					</div>
				</a> 
			</div>
			<div class="fade-out-bottom"></div>
		</div>
	</ng-container>

	<ng-container *ngSwitchDefault>
		<div class="row text-muted sorter-row">
			<div style="padding-left: 10em;" [ngClass]="columnClass('name')">
				<sort-column-header 
					[column]="sortColumns.name"
					[(currentSorting)]="currentSorting"></sort-column-header>
			</div>
			<div [ngClass]="columnClass('type')">
				<sort-column-header 
					[column]="sortColumns.typeId"
					[(currentSorting)]="currentSorting"></sort-column-header>
			</div>

			<div *ngIf="!hideParent" [ngClass]="columnClass('parent')">
				<sort-column-header 
					[column]="sortColumns.parent"
					[(currentSorting)]="currentSorting"></sort-column-header>
			</div>

			<div *ngIf="!hideTags"   [ngClass]="columnClass('tags')">
				<sort-column-header 
					[column]="sortColumns.tags"
					[(currentSorting)]="currentSorting"></sort-column-header>
			</div>

			<div class="text-right text-nowrap" [ngClass]="columnClass('date')">
				<sort-column-header 
					[column]="sortColumns.date"
					[(currentSorting)]="currentSorting"></sort-column-header>
			</div>
		</div>

		<div *ngIf="activities.length == 0"
			class="text-center text-muted"
			style="padding:1rem; font-size:1rem;">
				<i class="fa fa-fw fa-search"></i>
				No Activites Found
		</div>

		<div class="scroll-viewport" [class.fadeToGray]="fadeToGray">
			<div class="fade-out-top"></div>
			<div class="scroll-container scrollbar-thin" 
			[ngStyle]="{
				maxHeight:maxHeight,
				paddingRight: paddingRight,
				paddingLeft: paddingLeft
			}">  
			<div style="padding-bottom: 1em; padding-left: 56rem;">
				<button  [disabled]="SelectedActivityCount===0" class="btn btn-outline-primary btn-sm" (click)="openBulkUpdateModal()" >
					Update Selected Activites ({{SelectedActivityCount}})
				</button>
			</div>
				<div *ngFor="let activity of activities; let i=index;" style="cursor: pointer;"
				class="card activity-card" [class.highlighted]="highlightedActivity == activity">
					<div class="card-block align-items-center">
						<div class="row align-items-center" >
							<div [ngClass]="columnClass('name')">
								<div class="media align-items-center">
									<div class="align-items-center" *ngIf="!!activity.parent.value" style="padding-right: 1em;">
										<input type="checkbox" [(ngModel)]="activities[i].checked" (click)="selectedActivity(i)" > 
									</div> 
									<div class="pull-left">
										<activity-type-icon
											[activityTypeId]="types.getTypeIdBySubTypeId(activity.subTypeId)"
											class="mr-3 text-muted"></activity-type-icon>
									</div>
									<div class="media-body" (click)="activityClick.emit(activity)">
										<span>{{activity.name}}</span>
										<activity-collaboration-flag [activity]="activity"></activity-collaboration-flag>
								    </div>
								</div>
							</div>

							<div [ngClass]="columnClass('type')" (click)="activityClick.emit(activity)">
								<activity-invalid-reason-icon [messages]="activity.invalidReasons"></activity-invalid-reason-icon>
							    <span>{{getActivityTypeName(activity)}}</span>
							</div>

							<div [ngClass]="columnClass('parent')" *ngIf="!hideParent" (click)="activityClick.emit(activity)">
								<div [ngSwitch]="!!activity.parent.value">
									<div *ngSwitchCase="true">
										<div [ngSwitch]="activity.parent.value.type">
											<div *ngSwitchCase="ActivityParentType.Theme">
												<span>{{activity.parent.value.name}}</span>
												<span class="text-muted text-sm">(Theme)</span>
											</div>
											<div *ngSwitchCase="ActivityParentType.Project">
												<span>{{activity.parent.value.name}}</span>
												<span class="text-muted text-sm">(Project)</span>
											</div>
										</div>
									</div>
									<div *ngSwitchDefault class="text-muted">None</div>
								</div>
							</div>

							<div [ngClass]="columnClass('tags')" *ngIf="!hideTags" (click)="activityClick.emit(activity)">
								<tag-list [tags]="activity.tags" [keyword]="keywordToHighlight"></tag-list>
							</div>

							<div class="text-right text-nowrap" [ngClass]="columnClass('date')" (click)="activityClick.emit(activity)">
								<activity-date [activityDate]="activity.date"></activity-date>
							</div>

						</div>
					</div>
				</div> 
			</div>
			<div class="fade-out-bottom"></div>
		</div>
	</ng-container>
</ng-container>