import { ClienteleGroupType } from './clientele-group';

export class Demographic {
	constructor(
		public readonly id: string,
		public readonly label: string,
		public readonly description: string,
		public readonly canBeTargetedForOutreach: boolean,
		public readonly parityApplies: boolean,
		public readonly includedInSum: boolean) { }

	public static fromJson(o): Demographic {
		return new Demographic(
			o.id,
			o.label,
			o.description,
			o.canBeTargetedForOutreach,
			o.parityApplies,
			o.includedInSum);
	}
}

export class DemographicType {

	public readonly targetedOutreachDemographics: Demographic[];
	constructor(
		public readonly id: string,
		public readonly label: string,
		public readonly demographics: Demographic[],
        public readonly applicableClienteleGroupTypeIds: ClienteleGroupType[])
	{
	   	this.targetedOutreachDemographics = demographics.filter(d => d.canBeTargetedForOutreach);
	}

	public static fromJson(o): DemographicType {
		let ids = <ClienteleGroupType[]>o.applicableClienteleGroupTypeIds;
		return new DemographicType(
			o.id,
			o.label,
			o.demographics.map(Demographic.fromJson),
			ids);
	}
}

export class DemographicInfo {
	public readonly allDemographics: Demographic[];
	public readonly targetedOutreachDemographics: Demographic[];
	public readonly demographicById: Map<string, Demographic>;
	public readonly applicableTypesByClienteleGroupTypeId: Map<ClienteleGroupType, DemographicType[]>;
	constructor(
		public readonly types: DemographicType[])
	{
		this.allDemographics = 
			Array.prototype.concat.apply([],
				types.map(t => t.demographics));

		this.targetedOutreachDemographics = 
			Array.prototype.concat.apply([],
				types.map(t => t.targetedOutreachDemographics));

		this.demographicById = 
			new Map<string, Demographic>(
				this.allDemographics.map(d => [d.id, d] as [string, Demographic]));

		this.applicableTypesByClienteleGroupTypeId =
		   	new Map<ClienteleGroupType, DemographicType[]>(
				[
					ClienteleGroupType.Individual,
				   	ClienteleGroupType.Family,
					ClienteleGroupType.Organization
				].map(cgtid =>  {
					let types = this.types.filter(t => t.applicableClienteleGroupTypeIds.indexOf(cgtid) > -1);
					return [cgtid, types] as [ClienteleGroupType, DemographicType[]]
				}));
	}
}

export enum OutReachTypes {
	internal = 'internal',
	external = 'external',
	noReport = 'noReport'
}
