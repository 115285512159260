import { Subject } from "rxjs";

export class SubjectDictionary<TKey,TValue,TSubject extends Subject<TValue>> {
    private _pairs: Map<TKey, TSubject> = new Map<TKey, TSubject>();
    private _get(key: TKey): TSubject {
        if(this._pairs.has(key)){
            return this._pairs.get(key);
        }
        let s = this.subjectFactory(key);
        this._pairs.set(key, s);
        return s;
    }
    constructor(
        private readonly subjectFactory: (key: TKey) => TSubject
    ){ }

    next(key: TKey, nextValue: TValue): void {
        this._get(key).next(nextValue);
    }
    get(key: TKey): TSubject {
        return this._get(key);
    }
}