import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService as US, UserWithEmplId } from '../domain';
import { AppSettings } from '../app-settings';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


const LOCAL_STORAGE_CLAIMS_KEY = "projectboard.user.claims";
@Injectable()
export class UserService implements US {
	
	constructor(private http: HttpClient, private appSettings: AppSettings) { }

	public config(): Promise<null>{
		return Promise.resolve(null);
	}
	
	public getAvatarUrl(userId: number): string {
		return this.appSettings.APIURL + '/user/Avatar/' + userId;		
	}
	
	public getAvatarUrlAsync(userId: number): Observable<string> {		
		return this.http.get(this.getAvatarUrl(userId), { responseType: 'blob' }).pipe(
			map(blob => URL.createObjectURL(blob))
		);
	}

	public getInitialsAvatarUrl(userId: number) : Observable<string> {
		return this.http.get(`${this.appSettings.APIURL}/user/initialsAvatar/${userId}`, { responseType: 'blob' }).pipe(
			map(blob => URL.createObjectURL(blob))
		);
	}

	public search(nameFragment: string, max: number): Promise<UserWithEmplId[]> {
		let url = this.appSettings.APIURL + '/user/search/?nameFragment=' + (nameFragment || '') + '&max=' + max;
		return this.http.get(url)
			.toPromise()
			.then(json => (<any[]>json['result']).map(UserWithEmplId.fromJson));
	}

	public importFromDirectoryIfNecessary(userId: number): void {
		this.http.post(this.appSettings.APIURL + '/user/import_if_necessary/' + userId, null)
			.toPromise();
	}
	public spoofUser(userId: number): Promise<string> {
		let claimsJson =window.localStorage.getItem(LOCAL_STORAGE_CLAIMS_KEY);
		let authResult =  JSON.parse(claimsJson);
		let ppeUserEmployeeId =  authResult.idTokenClaims["employeeid"]
	  return this.http.post<string>(this.appSettings.APIURL + '/user/spoof-user/?userId=' + userId+ '&ppeUserEmployeeId='+ppeUserEmployeeId,null, { responseType: 'text' as 'json'})
	           .toPromise();
	}
}

