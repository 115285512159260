import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
   	ClienteleGroupDto,
   	ClienteleGroupSource,
   	ClienteleService,
   	ClienteleGroupType,
	ClienteleGroupDetail,
   	DemographicInfo,
   	DemographicType, 
		  County,
		  ICountySelection
} from '../../domain';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ConsoleService } from '../../widgets/console.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { TooltipService } from '../../widgets/tooltip.service';

@Component({
	selector: 'app-clientele-group-modal',
	templateUrl: './clientele-group-modal.component.html',
	styleUrls: ['./clientele-group-modal.component.scss']
})
export class ClienteleGroupModalComponent implements OnInit {
	@Output() reloadClienteleGroups = new EventEmitter();
	private group: ClienteleGroupDetail
	public StaticText: any;
	ClienteleGroupType = ClienteleGroupType;
	form: FormGroup;
	closing = false;
	changed = false;
	title = 'Adding a Clientele Group';
	demographicInfo: DemographicInfo;
	types = [
		ClienteleGroupType.Individual,
		ClienteleGroupType.Family,
		ClienteleGroupType.Organization
	];
	sources: ClienteleGroupSource[] = [];
	selectedCounties: ICountySelection;

	constructor(
		public dialogRef: MatDialogRef<ClienteleGroupModalComponent>,
		private snackBar: MatSnackBar,
		private console: ConsoleService,
		public tooltipSvc: TooltipService,
		private gaService: GoogleAnalyticsService,
		@Inject(MAT_DIALOG_DATA) private data: any,
		
		fb: FormBuilder,
		private svc: ClienteleService)
	{
		this.StaticText = tooltipSvc.StaticText;
		this.demographicInfo = svc.getDemographicInfo();

		if('title' in data){
			this.title = data['title'];
		}

		let demographicCounts = (() => {
			let o = {};
			this.demographicInfo.allDemographics.forEach(d =>
				o[d.id] = 0);
			return fb.group(o);
		})();

		this.form = fb.group({
			title: '',
			description: '',
			archived: false,
			type: '',
			//countyIds: [],
			countySelection: ICountySelection.CountySelection([],false),
			source: '',
			otherSourceName: '',
			demographicCounts: demographicCounts
		});
		this.group = (<ClienteleGroupDetail> data.group);
		if(this.group){let demoCounts = {};

			this.demographicInfo.allDemographics.forEach(d =>
				demoCounts[d.id] = this.group.demographics.has(d.id)
					? this.group.demographics.get(d.id).potential.contacts
					: 0);

			this.selectedCounties = this.group.countySelection

			this.form.setValue({
				title: this.group.title,
				description: this.group.description,
				archived: false,
				type: this.group.type,
				countySelection: this.selectedCounties,
				source: this.group.source.id,
				otherSourceName: this.group.source.name,
				demographicCounts: demoCounts
			});
		}
	}

	get applicableDemographicTypes() {
		let selectedGroupType = <ClienteleGroupType>(+this.form.value.type);
		return this.demographicInfo.applicableTypesByClienteleGroupTypeId.get(selectedGroupType)
	}

	demographicTypeSum(t: DemographicType): number {
		return t.demographics.filter(a => a.includedInSum).reduce((sum, d) =>
			sum + this.form.value.demographicCounts[d.id],
			0);
	}

	demographicTypeSumAll(t: DemographicType): number {
		return t.demographics.reduce((sum, d) =>
			sum + this.form.value.demographicCounts[d.id],
			0);
	}	

	ngOnInit() {
		this.svc.getAvailableSources().then(sources => this.sources = sources);
	}

	canArchive(){
		return !!this.data.group;
	}

	unarchive(){
		let fv = this.form.value;
		fv.archived = false;
		this.save();
	}

	archive(){
		if(window.confirm(this.StaticText.ArchiveClienteleGroup + '\n\nArchive this Clientele Group?')){
			let fv = this.form.value;
			fv.archived = true;
			this.save();
		}
	}

	cancel(){
		if(!this.changed || window.confirm('You may have unsaved changes. Abandon changes?')){
			this.dialogRef.close();
		}
	}

	save(){
		this.closing = true;
		let applicableDemographicIds = 
			Array.prototype.concat.apply(
				[],
				(this.applicableDemographicTypes || []).map(t => t.demographics))
			.map(d => d.id);

		let fv = this.form.value;
		this.console.log("form Value: ", fv);

		this.selectedCounties = fv.countySelection;

		let data = new ClienteleGroupDto(
			fv.title,
			<ClienteleGroupType>parseInt(fv.type),
			parseInt(fv.source),
			'', //TODO: otherSourceName
			fv.description,
			fv.archived,
			fv.countySelection,
			fv.demographicCounts);

		this.console.log('clientele group data:', data);

		if(!!this.data.group){
			this.svc
				.updateGroup(this.data.group.id, data)
				.then(result => result.matchDo(
					_ => {
						if(data.archived) {
							let snackBarRef = this.snackBar.open('Clientele Group archived', 'Undo', {duration: 60 * 1000});
							this.gaService.eventEmitter("Clientele Group Archived", "Clientele Group", "Archive");
							snackBarRef.onAction().subscribe(() => {
								this.console.log('Undo triggered!');
								this.unarchive();
								this.gaService.eventEmitter("Clientele Group Restored", "Clientele Group", "Restore");
								this.console.log("clientele group reload triggered!");
								this.reloadClienteleGroups.emit();
							});
							this.dialogRef.close('archived');
						} else {
							this.snackBar.open('Clientele Group updated', null, {duration: 3000});
							this.dialogRef.close('updated');
						}
					}, 
					errorMsg => {
						this.snackBar.open(errorMsg, null, {duration: 10000});
						this.closing = false;
						if (fv.archived) fv.archived = false;
					}))
		} else {
			this.svc
				.createGroup(data)
				.then(result => result.matchDo(
					_ => {
						this.console.log('success');
						this.snackBar.open('Clientele Group added', null, {duration: 3000});
						this.dialogRef.close('created');
					},
					errorMsg => {
						this.console.log('failure');
						this.snackBar.open(errorMsg, null, {duration: 10000});
						this.closing = false;
					}))
			.catch(err => {
				this.snackBar.open(err.statusText, null, {duration: 10000});
				this.closing = false;
			});
		}
	}
}
