import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from '../app-settings';
import {
   	Activity,
	ActivityType,
	ActivityParent,
	ActivityTypeCollection,
	ActivityDto,
	ClienteleGroup,
	ProgramArea,
	Result,
   	ActivityService as AS
} from '../domain';

import { Observable ,  ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivityCollaborationInvitation, ActivityFilterSpecification, ConfirmActivityCollaboration } from 'app/domain/types';
import { ConsoleService } from '../widgets/console.service';
import { ActivityParentDto } from '../domain/types/activity-parent-update';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ActivityService implements AS {
	private static _activityTypes: ActivityType[];
	private _activities: ReplaySubject<Activity[]>;
	private _filteredActivities: ReplaySubject<Activity[]>;
	private activityFilter: ActivityFilterSpecification;

	constructor(
		private http: HttpClient, 
		private appSettings: AppSettings,
		private console: ConsoleService,
		private readonly snackbar: MatSnackBar
	)  {	
		this._activities = new ReplaySubject<Activity[]>(1);
		this._filteredActivities = new ReplaySubject<Activity[]>(1);
	}

	getActivityTypes(): ActivityTypeCollection {
		return new ActivityTypeCollection(ActivityService._activityTypes);
	}

	config(): Promise<null> {
		return this.http.get(this.appSettings.APIURL + '/activity/types')
			.toPromise()
			.then(data => {
				ActivityService._activityTypes = data as ActivityType[];
				return null;
			});
	}

    getCollaborationInvitations(): Promise<ActivityCollaborationInvitation[]> {
		return this.http.get(this.appSettings.APIURL + '/activity/collaboration_invitations')
			.toPromise()
			.then(json => (<any[]>json).map(ActivityCollaborationInvitation.fromJson));
	}

    confirmCollaboration(cmd: ConfirmActivityCollaboration): Promise<Result<number, string>>{
		return this.http.post(this.appSettings.APIURL + '/activity/confirmed_collaborations', cmd)
			.toPromise()
			.then(json => {
				this.loadActivities();
				return Result.fromJson<number,string>(json, o => o, o => o);
			});
	}

    ignoreCollaboration(activityId: number): Promise<Result<null, string>>{
		return this.http.post(this.appSettings.APIURL + '/activity/ignored_collaborations/' + activityId, null)
			.toPromise()
			.then(json => Result.fromJson<null,string>(json, () => null, o => o));
	}

	createActivity(data: ActivityDto): Promise<Result<number, string>> {
		return this.http.post(this.appSettings.APIURL + '/activity', data)
			.toPromise()
			.then(json => {
				this.loadActivities();
				return this.tryGetActivityFromResultJson(json);
			});
	}

	saveActivity(id: number, data: ActivityDto): Promise<Result<number, string>>{
		return this.http.put(this.appSettings.APIURL + '/activity/' + id, data)
			.toPromise()
			.then(json => {
				this.loadActivities();
				return this.tryGetActivityFromResultJson(json);
			});
	}

	archiveActivity(id: number): Promise<Result<number, string>>{
		return this.http.delete(this.appSettings.APIURL + '/activity/' + id)
			.toPromise()
			.then(json => {
				this.loadActivities();
				return this.tryGetActivityFromResultJson(json);
			});
	}

	unarchiveActivity(id: number): Promise<Result<number, string>>{
		return this.http.put(this.appSettings.APIURL + '/activity/unarchive/' + id, null)
			.toPromise()
			.then(json => {
				this.loadActivities();
				return this.tryGetActivityFromResultJson(json);
			});
	}

	private tryGetActivityFromResultJson(json: any): Result<number,string> {
		return Result
			.fromJson<number,string>(
				json,
				o => <number>o,
				o => <string>o);
	}
	
	getActivity(id: number): Observable<Activity> {
		return this._activities
			.asObservable()
			.pipe(map(activities => activities.find(act => act.id == id)));
	}

	get activities(): Observable<Activity[]> {
		return this._activities.asObservable();
	}

	get filteredActivities(): Observable<Activity[]> {
		return this._filteredActivities.asObservable();
	}

	getActivitiesByParent(parent: ActivityParent): Observable<Activity[]> {
		return this._activities
			.asObservable()
			.pipe(map(activities => 
				activities.filter(act => 
					act.parent.match(
						() => false,
						p => p.hashString == parent.hashString
					))));
	}

	loadActivities() {
		this.http.get(this.appSettings.APIURL + '/activity/')
			.subscribe(
				json => {
					let activities = (<any[]>json).map(Activity.fromJson);
					this._activities.next(activities);
					this.activityFilter ?
						this.searchActivities(this.activityFilter)
						: this._filteredActivities.next(activities);
				}
				,() => this.console.log('Could not load Activities.')
			);
	}

	searchActivities(activityFilter: ActivityFilterSpecification) {
		if (!activityFilter) return null;
		this.activityFilter = activityFilter;
		let httpParams = new HttpParams();
		Object.keys(activityFilter).forEach(function (key) {
			if (activityFilter[key] !=null){
				if (["occurringAfter","occurringBefore"].find(a => a == key)) {
					// date fields
					httpParams = httpParams.append(key, activityFilter[key].toISOString());
				} else if (["withNameMatching","missingInformation","clienteleContactsType","clienteleGroupId"].find(a => a == key)){
					// string or boolean fields
					httpParams = httpParams.append(key, activityFilter[key]);
				} else { 
					// arrays
					activityFilter[key].forEach(n => {
						httpParams = httpParams.append(key, n);	
					});
					
				}
			}
		});

		this.http.get(this.appSettings.APIURL + '/activity/search', {
				params: httpParams
			})
			.subscribe(
				json => this._filteredActivities.next((<any[]>json).map(Activity.fromJson))
				,() => this.console.log('Could not load Activities.')
			);
	}

	getAllProgramAreas(): Promise<ProgramArea[]> {
		return this.http.get(this.appSettings.APIURL + '/tag/program_areas')
			.toPromise()
			.then(json => json as ProgramArea[]);
	}

	getAvailableParents(): Promise<ActivityParent[]> {
		return this.http.get(this.appSettings.APIURL + '/activity/available_parents')
			.toPromise()
			.then(data => (<any[]>data).map(ActivityParent.fromJson));
	}

	getAvailableClienteleGroups(activityFilter:boolean): Promise<ClienteleGroup[]> {
		return this.http.get(this.appSettings.APIURL + '/activity/available_clientele_groups/?activityFilter='+activityFilter)
			.toPromise()
			.then(data => (<any[]>data).map(ClienteleGroup.fromJson));
	}
	updateActivityParent(data: ActivityParentDto): void{
	 this.http.put(this.appSettings.APIURL + '/activity/parent', data)
			.toPromise()
			.then(result=>{
				this.loadActivities();
				this.snackbar.open('Successfully updated activity parent');
			  },
			  err=>{
				const message =  err?.error?? "Server Error";
				this.snackbar.open('Failed to update activity parent: ' + message);
			  });
	}
}