
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppSettings {
	private _apiUrl = '';
	private _loginUrl = '';
	private _enableConsoleLogging: boolean = false;
	private _googleAnalyticsId = '';
	private _WFATenantId = '';
	private _WFAClientId = '';
	private _WFAAuthority = '';
	private _WFARedirectUri = '';
	private _WFAuri='';

	public get APIURL()  : string { return this._apiUrl;   }
	public get loginURL(): string { return this._loginUrl; }
	public get enableConsoleLogging(): boolean { return this._enableConsoleLogging; }
	public get googleAnalyticsId(): string { return this._googleAnalyticsId; }
	public get WFATenantId()  : string { return this._WFATenantId;   }
	public get WFAClientId()  : string { return this._WFAClientId;   }
	public get WFAAuthority()  : string { return this._WFAAuthority;   }
	public get WFARedirectUri()  : string { return this._WFARedirectUri;   }
	public get WFAuri()  : string { return this._WFAuri;   }
	public APIURLOf(path: string): string {
		return this.APIURL + path;
	}

	constructor(private http: HttpClient){ }

	public getSettings() {
		if(this._apiUrl == '') {
			this.init();
		}
		return this;
	}

	public init(): Promise<null> {
		return <Promise<null>>this.http.get("/assets/appsettings.json").pipe(
			map(json => <any>json))
			.toPromise()
			.then(o => {
				this._loginUrl = o.loginURL;
				this._apiUrl = o.APIURL;
				this._enableConsoleLogging = o.enableConsoleLogging;
				this._googleAnalyticsId = o.googleAnalyticsId;
				this._WFATenantId = o.WFATenantId;
				this._WFAClientId = o.WFAClientId;
				this._WFAAuthority = o.WFAAuthority;
				this._WFARedirectUri = o.WFARedirectUri;
				this._WFAuri =o.WFAuri;
			});
	}
}
