import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../app-settings';
import {
	DossierService as Svc,
	Result,
	TagDto,
	UserPreferenceService,
	UserPreferences,
	ActivityService,
	DossierExportPeriodType,
	DossierExportPeriod,
	DossierExportPeriodOption,
	getStateFiscalYearsOfInterest
} from '../domain';

import {
	CreateTheme,
	SelectThemeItems,
	Theme,
	Dossier,
	SelectThemeItem,
	ThemePublicationType
} from '../domain/types/dossier';
import { ConsoleService } from '../widgets/console.service';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { DatePipe } from '@angular/common';

export class LocalStorageRepo<T> {
	private readonly _onSave = new ReplaySubject<T>(1);
	public readonly onSave: Observable<T> = this._onSave.asObservable();

	constructor(
		private readonly key: string,
		private readonly serialize: (value: T) => string,
		private readonly deserialize: (json: string) => T
	){ }

	public save(value: T){
		let json = this.serialize(value);
		window.localStorage.setItem(this.key, json);
	}

	public getOrDefault(defaultValue: T): T
	{
		let json = window.localStorage.getItem(this.key);
		if(!json){
			return defaultValue;
		}
		return this.deserialize(json);
	}
}

@Injectable()
export class DossierService implements Svc {
	private _dossier: BehaviorSubject<Dossier>;
	private _expandAll: BehaviorSubject<boolean>;
	private _trim: BehaviorSubject<boolean>;

	private _period: DossierExportPeriod = {type: DossierExportPeriodType.All}

	constructor(
		private http: HttpClient, 
		private appSettings: AppSettings,
		private activityService: ActivityService,
		private userPreferenceService: UserPreferenceService,
		private console: ConsoleService,
		private readonly datePipe: DatePipe) 
	{ 
		let expandAllPreference = this.userPreferenceService.getDossierExpandedStatePreference();

		this._dossier = <BehaviorSubject<Dossier>>new BehaviorSubject(null);
		this._expandAll = <BehaviorSubject<boolean>>new BehaviorSubject(expandAllPreference);
		this._trim = <BehaviorSubject<boolean>>new BehaviorSubject(false);
		this.activityService.activities.subscribe(_ => {
			this.console.log('activities changed');
			this._loadDossier();
		});
		this.console.log('Starting with dossier export period:', this._period);
	}

	public set dossierPeriod(p: DossierExportPeriod){
		this._period = p;
		this._loadDossier();
	}

	public get dossierPeriod(){
		return this._period;
	}

	private periodParams(): { [param: string]: string }
	{
		const format: string = 'MM/dd/yyyy';
		let params = {
			periodType: DossierExportPeriodType[this._period.type],
		};
		if(this._period.startDate !== null){
			const sartDate = this.datePipe.transform(this._period.startDate, format);
			params['startDate'] = sartDate;
		}
		if(this._period.endDate !== null){
			const endDate = this.datePipe.transform(this._period.endDate, format);
			params['endDate'] = endDate;
		}
		return params;
	}

	private downloadBlob(url: string, params: { [param: string]: string }): Promise<Result<void,string>> {
		function downloadViaDomClick(url: string, filename: string){
			let anchor = window.document.createElement('a');
			anchor.href = url;
			anchor.download = filename;

			document.body.appendChild(anchor);
			anchor.click();
			document.body.removeChild(anchor);
		}

		return new Promise((resolve,reject) => {
			this.http.get(
				url,
				{
					responseType: 'blob',
					observe: 'response',
					params: params
				}
			).subscribe(
				response => {
					downloadViaDomClick(
						window.URL.createObjectURL(response.body),
						response.headers.get('Content-Filename'));
					resolve(Result.success<void,string>(null));
				},
				err => {
					//The PB back end places an error message intended for the user in the body of the error response.  
					//Reference: https://stackoverflow.com/questions/48500822/how-to-handle-error-for-response-type-blob-in-httprequest
					let r = new FileReader();
					r.onloadend = _ => resolve(Result.failure<void,string>(r.result.toString()));
					r.readAsText(err.error);
				});
		});
	}

	public downloadThemesAndProjectsDoc(): Promise<Result<void,string>> {
		return this.downloadBlob(
			this.appSettings.APIURL + '/dossier/themes_and_projects_doc', 
			this.periodParams()
		);
	}

	public downloadDoc(): Promise<Result<void,string>> {
		return this.downloadBlob(
			this.appSettings.APIURL + '/dossier/doc_export', 
			this.periodParams()
		);
	}

	public downloadExcel(): Promise<Result<void,string>>{
		return this.downloadBlob(
			this.appSettings.APIURL + '/dossier/excel_export', 
			this.periodParams()
		);
	}

	public countThemeActivities(themeId: number, counts: { [key: number]: number}): Promise<Result<null,string>>{
		return this.http.put(this.appSettings.APIURL + '/dossier/theme/' + themeId + '/activity_counts', counts)
			.toPromise()
			.then(json => 
				Result.fromJson<null,string>(
					json,
					n => null,
					s => <string>s));
	}

	public renameTheme(themeId: number, name: string): Promise<Result<null,string>>{
		return this.http.put(this.appSettings.APIURL + '/dossier/theme/' + themeId + '/name/?name=' + name, null)
			.toPromise()
			.then(json => 
				Result.fromJson<null,string>(
					json,
					n => null,
					s => <string>s));
	}

	public narrateTheme(themeId: number, narrative: string): Promise<Result<null,string>>{
		return this.http.put(this.appSettings.APIURL + '/dossier/theme/' + themeId + '/narrative', {value: narrative})
			.toPromise()
			.then(json => 
				Result.fromJson<null,string>(
					json,
					n => null,
					s => <string>s));
	}

	public situateTheme(themeId: number, situation: string): Promise<Result<null,string>>{
		return this.http.put(this.appSettings.APIURL + '/dossier/theme/' + themeId + '/situation', {value: situation})
			.toPromise()
			.then(json => 
				Result.fromJson<null,string>(
					json,
					n => null,
					s => <string>s));
	}

	public saveThemePubs(themeId: number, pt: ThemePublicationType, pubs: string): Promise<Result<null,string>>{
		return this.http.put(this.appSettings.APIURL + '/dossier/theme/' + themeId + '/publications/' + pt, {value: pubs})
			.toPromise()
			.then(json => 
				Result.fromJson<null,string>(
					json,
					n => null,
					s => <string>s));
	}

	public createTheme(cmd: CreateTheme): Promise<Result<number,string>> {
		return this.http.post(this.appSettings.APIURL + '/dossier/theme', cmd)
			.toPromise()
			.then(json => Result.fromJson<number,string>(
						json,
						n => <number>n,
						s => <string>s));
	}

	private _dossierLoadedOnce = false;
	public getDossier(): Observable<Dossier> {
		if(!this._dossierLoadedOnce){
			this._loadDossier();
			this._dossierLoadedOnce = true;
		}
		return this._dossier.asObservable();
	}

	public refreshDossier(): void {
		this._loadDossier();
	}

	private _loadDossier() {
		let p = this.periodParams();
		this.console.log('loading dossier with period: ', p);
		this.http.get(this.appSettings.APIURL + '/dossier/', { params: p })
			.subscribe(
				json => this._dossier.next(Dossier.fromJson(<any>json))
				,error => this.console.log('Could not load Dossier.')
			);
	}

    public getThemes(): Promise<Theme[]>{
		return this.http.get(this.appSettings.APIURL + '/dossier/theme')
			.toPromise()
			.then(json => (<any[]>json).map(Theme.fromJson));
	}

	public tagTheme(themeId: number, tags: TagDto[]): Promise<Result<null,string>>{
		return this.http.put(this.appSettings.APIURL + '/dossier/theme/' + themeId + '/tags', tags)
			.toPromise()
			.then(json => 
				Result.fromJson<null,string>(
					json,
					n => null,
					s => <string>s));
	}

	public selectThemeItems(cmd: SelectThemeItems): Promise<Result<null,string>>{
		return this.http.put(this.appSettings.APIURL + '/dossier/selection', cmd)
			.toPromise()
			.then(json => {
				this._loadDossier();
				return Result.fromJson<null,string>(
					json,
					n => null,
					s => <string>s)
			});
	}

	public selectThemeItem(cmd: SelectThemeItem): Promise<Result<null,string>>{
		return this.http.put(this.appSettings.APIURL + '/dossier/select', cmd)
			.toPromise()
			.then(json => {
				//this._loadDossier();
				//effect single item in this._dossier
				return Result.fromJson<null,string>(
					json,
					n => null,
					s => <string>s)
			});
	}

	public archive(themeId: number): Promise<Result<null,string>> {
		return this.http.delete(this.appSettings.APIURL + '/dossier/theme/' + themeId)
			.toPromise()
			.then(json => 
				Result.fromJson<null,string>(
					json,
					n => null,
					s => <string>s));
	}

	public unarchive(themeId: number): Promise<Result<null,string>> {
		return this.http.put(this.appSettings.APIURL + '/dossier/theme/' + themeId + '/unarchive', null)
			.toPromise()
			.then(json => 
				Result.fromJson<null,string>(
					json,
					n => null,
					s => <string>s));
	}

	public getExpand(): Observable<boolean> {
		return this._expandAll.asObservable();
	}
	public getTrim(): Observable<boolean> {
		return this._trim.asObservable();
	}

	public setExpand(expand: boolean) {
		this._expandAll.next(expand);
		this.userPreferenceService.setKeyValue(UserPreferences.keys.dossierExpandedState, (expand ? 'true' : 'false'));
	}

	public selectAll(selection: boolean) {
		this.getDossier().subscribe(dossier => {
			if(!!dossier && !!dossier.Themes){
				dossier.Themes.forEach(t => {
					t.includeInDossier = selection;
					t.projects.forEach(p => { 
						p.includeInDossier = selection;
						p.activities.forEach(a => a.includeInDossier = selection);
					});
					t.themeActivities.forEach(ta => ta.includeInDossier = selection);
				});
				this.selectThemeItems(dossier.dto(false))
					.then(r => this.console.log("select All Complete"))
					.catch(r => this.console.log("Select All Failed: ", r.toString()));
			}
		}).unsubscribe();
	}

	public setTrim(trim: boolean) {
		this._trim.next(trim);
	}
}
