import { Component, OnInit , Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
   	FTEService,
   	ConditionChangeFTE,
   	ConditionChangeFTEDto,
   	ProgramArea,
	ConditionChange,
	TagService,
	Tag
} from '../../../../domain';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ConsoleService } from '../../../../widgets/console.service';
import { Subject, from } from 'rxjs';
import { startWith, combineLatest } from 'rxjs/operators';
import { GoogleAnalyticsService } from '../../../../main-core/google-analytics.service';
import { TooltipService } from '../../../../widgets/tooltip.service';

@Component({
	templateUrl: './condition-change-fte-modal.component.html',
	styleUrls: ['./condition-change-fte-modal.component.scss']
})
export class ConditionChangeFteModalComponent implements OnInit {
	public StaticText: any;
	Object = Object;
	form: FormGroup;
	formArray: FormArray;
	closing = false;
	conditionChangeNameById: {[id: number]: string} = {};
	programAreaNameById: {[id: number]: string} = {};
	_availableProgramAreas: {[controlIndex: number]: ProgramArea[]} = {};
	_availableConditionChanges: {[controlId: string]: ConditionChange[]} = {};

	private _conditionChangeSubject = new Subject<ConditionChange[]>();
	private loadConditionChanges(): Promise<void> {
		return this.svc.getConditionChanges().then(ccs => this._conditionChangeSubject.next(ccs));
	}

	availableProgramAreas(programAreaControlIndex: number): ProgramArea[]{
		return this._availableProgramAreas[programAreaControlIndex] || [];
	}

	programAreaClass(disabled: boolean): string {
		return disabled ? "option-disabled" : "";
	}

	availableConditionChanges(programAreaControlIndex: number, conditionChangeControlIndex: number): ConditionChange[]{
		let key = `${programAreaControlIndex}_${conditionChangeControlIndex}`;
		return this._availableConditionChanges[key] || [];
	}

	constructor(
		public dialogRef: MatDialogRef<ConditionChangeFteModalComponent>,
		private svc: FTEService,
		private tagSvc: TagService,
		private snackBar: MatSnackBar,
		private console: ConsoleService,
		public tooltipSvc: TooltipService,
		private fb: FormBuilder,
		private gaService: GoogleAnalyticsService,
		@Inject(MAT_DIALOG_DATA) data: any)
	{ 
		this.StaticText = tooltipSvc.StaticText;
		{
			let programAreas = [];
			let f = <ConditionChangeFTE[]>data.conditionChangeFte;
			if(f){
				f.forEach(group =>
					programAreas.push(fb.group({
						programAreaId: group.programArea.id,
						pairs: fb.array(
							Array.from(group.pairs.entries()).map(pair => fb.group({
								conditionChangeId: pair[0].id,
								fte: Math.round(pair[1].value * 100) /* From decimal to percentage.  Be sure to fix any rounding errors. */
							})))
					})));
			}

			this.formArray = fb.array(programAreas);
			this.form = fb.group({
				programAreas: this.formArray
			});

			if(programAreas.length == 0){
				this.addProgramAreaRow(null);
			}
		}

		let programAreas =  from(this.svc.getProgramAreas());
		let conditionChanges = this._conditionChangeSubject.asObservable();

		this.form.valueChanges
		.pipe(
			startWith(this.form.value),
			combineLatest(programAreas,conditionChanges)
		)
		.subscribe(([formValue,programAreas,conditionChanges]) => {
			/* This entire block is used to establish the programAreas and conditionChanges that should
			** made available to each control.  Selections are mutually exclusive, meaning that when
			** a control selects Program Area, that same Program Area is not available to another control.
			** Condition change selections are scoped to a program area (ie. no repeats under a program area
			** but other program areas may relate to that condition change). */
			let usedProgramAreaIds: {index:number, id: number}[] = 
				(<any[]>formValue['programAreas'])
				.map(pa => parseInt(pa.programAreaId))
				.map((id,index) => !!id ? {index,id} : undefined)
				.filter(id => !!id);

			(<FormArray>this.form.controls['programAreas']).controls.forEach((programAreaControl, programAreaControlIndex) => {
				let programAreaIdsUsedByOtherControls = 
					new Set<number>(
						usedProgramAreaIds
						.filter(p => p.index != programAreaControlIndex)
						.map(p => p.id));

				let currentProgramAreaID = formValue['programAreas'][programAreaControlIndex].programAreaId;

				this._availableProgramAreas[programAreaControlIndex] = 
					programAreas
						.filter(pa => !programAreaIdsUsedByOtherControls.has(pa.id) 
							&& (!pa.disabled // removes disabled Program areas 
								|| pa.id == currentProgramAreaID) // inlcudes selected program area
							)
						.sort((a,b) => (a.disabled ? 1 : 0) - (b.disabled ? 1 : 0));

				let usedConditionChangeIds: {index:number, id: number}[] = 
					(<any[]>formValue['programAreas'][programAreaControlIndex].pairs)
					.map(pair => parseInt(pair.conditionChangeId))
					.map((id,index) => !!id ? {index,id} : undefined)
					.filter(id => !!id);
				
				(<FormArray>(<FormGroup>programAreaControl).controls.pairs).controls.forEach((_,conditionChangeControlIndex) => {
					let conditionChangeIdsUsedByOtherControls = 
						new Set<number>(
							usedConditionChangeIds
							.filter(p => p.index != conditionChangeControlIndex)
							.map(p => p.id));
					let key = `${programAreaControlIndex}_${conditionChangeControlIndex}`;
					this._availableConditionChanges[key] = 
						conditionChanges.filter(cc => !conditionChangeIdsUsedByOtherControls.has(cc.id));
				});
			});

			this.console.log('re-calculated used program areas via form value change', this._availableProgramAreas, this._availableConditionChanges);
		});

		programAreas.subscribe(pas => {
			let dict = {};
			pas.forEach(pa => dict[pa.id] = pa.name);
			this.programAreaNameById = dict;
		});
		conditionChanges.subscribe(ccs => {
			let dict = {};
			ccs.forEach(cc => dict[cc.id] = cc.name);
			this.conditionChangeNameById = dict;
		});

		this.loadConditionChanges();
	}

	ngOnInit() {
	}

	cancel(){
		this.dialogRef.close();
	}

	defineNewConditionChange(pairs: FormArray){
		let name = prompt('What is the name of the new Condition Change?');
		if(!name){
			return;
		}
		this.tagSvc.createUserDefinedConditionChangeTag(name).then(result =>
			result.matchDo(
				(tag: Tag) => {
					this.loadConditionChanges().then(_ => {
						this.addConditionChangeRow(pairs, tag.id);
					});
				},
				errorMsg => {
					this.snackBar.open('Unable to define new Condition Change: ' +  errorMsg)
				}));
	}

	save(){
		var dtos: ConditionChangeFTEDto[] =  
			Array.prototype.concat.apply(
				[],
				this.form.value.programAreas.map(group =>
				group.pairs.map(pair => 
					new ConditionChangeFTEDto(
						group.programAreaId,
					   	pair.conditionChangeId,
					   	(pair.fte || 0) / 100 // From percentage back to decimal. 
						))));
		/* A null ProgramAreaID or ConditionChangeID would cause a runtime error in the server.  
		** We musn't let them through. */
		let validDtos = dtos.filter(cc => !!cc.programAreaId && !!cc.conditionChangeId);
		this.closing = true;
		this.svc.saveConditionChangeFTE(validDtos)
			.then(result => result.matchDo(
				_ => {
					this.snackBar.open('Condition Change FTE updated', null, {duration: 3000});
					this.gaService.eventEmitter("Condition Change FTE Updated", "Condition Change FTE", "Update");
					this.dialogRef.close('success');
				},
				errorMsg => {
					this.snackBar.open('Error: ' + errorMsg, null, {duration: 3000});
					this.closing = false;
				}))
			.catch(error => {
				this.snackBar.open(error.statusText, null, { duration: 10000 });
				this.closing = false;
			});
	}

	addProgramAreaRow(programAreaId?: number) {
		this.formArray.push(this.fb.group({
			programAreaId: programAreaId,
			pairs: this.fb.array([
				this.fb.group({
					conditionChangeId: null,
					fte: 0
				})/*,
				this.fb.group({
					conditionChangeId: null,
					fte: 0
				}),
				this.fb.group({
					conditionChangeId: null,
					fte: 0
				})*/
			])
		}));
	}

	addConditionChangeRow(pairs: FormArray, conditionChangeId?: number) {
		pairs.push(this.fb.group({
			conditionChangeId: conditionChangeId,
			fte: 0
		}));
	}

	get sum(): number {
		return this.form.value.programAreas.reduce((sum, group) =>
			sum + group.pairs.reduce((sum, pair) => 
				sum + pair.fte,
			   	0),
		   	0);
	}

	get validSum(): boolean {
		return this.sum == 100;
	}
}