import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Activity, ActivityProperty } from '../../domain';

@Component({
	selector: 'activity-invalid-reason-icon',
	templateUrl: './activity-invalid-reason-icon.component.html',
	styleUrls: ['./activity-invalid-reason-icon.component.scss']
})
export class ActivityInvalidReasonIconComponent implements OnChanges {
	constructor() { }

	@Input() messages: string[];
	tooltipText: string = "";
	invalid: boolean = false;

	ngOnChanges(changes: SimpleChanges): void {
		if(!changes.messages){
			return;
		}
		let messages = (<string[]>changes.messages.currentValue);
		if(!!messages){
			messages = messages.map((message,i) => 
					messages.length > 1
						? (i+1) + ". " + message
						: message);
			if(messages.length > 0){
				this.invalid = true;
				this.tooltipText = messages.join("\n");
			}
		}
	}
}