
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService, Option } from '../domain';
import { Observable } from "rxjs";

import { ConsoleService } from 'app/widgets/console.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

	private _token: Option<string> = Option.create();

	constructor(
		private authSvc: AuthenticationService,
		private console: ConsoleService
	) {
		authSvc.currentIdentity.subscribe(i => {
			this._token = i.map(i => i.token);
		});
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this._token.match(
			() => {
				//this.console.log("[NO TOKEN]", req.url.toString());
				/* We care only about the case when the application *has* a token. 
				** A first-time log-in request should be triggered by some other class. */
				return next.handle(req);
			},
			token => {
				let reqWithAuthHeader = req.clone({ setHeaders: { Authorization: "Bearer " + token } });
				return next.handle(reqWithAuthHeader).pipe(tap(
					(event: HttpEvent<any>) => {
						/* We care only about errors, not normal events. */ 
					}, 
					(err: any) => {
						if (err instanceof HttpErrorResponse) {
							/* Occurs after a user is logged in and an unathorized request occurs. 
							** Note - the unauthorized request fails and in some cases re-issuing 
							** the request after login would be good. */
							if (err.status == 401) {
								this.console.log("[Intercepted a 401 (Unauthorized) response]");
								this.authSvc.clearCurrentUser();
							}
						} else {
							this.console.log("err: ", err);
						}
					}));
			});
	}
}