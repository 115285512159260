<button (click)="increment()" class="btn btn-primary" [disabled]="valid ? false : true">
	<i class="fa fa-plus"></i>
</button>

<span style="padding: 0 10px;">
	{{ counterVal }}
</span>

<button (click)="decrement()" class="btn btn-primary">
	<i class="fa fa-minus"></i>
</button>
