import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../app-settings';
import { CurrentUser, CurrentAcademicUser, Option, AuthenticationService as Svc, HeartbeatResult, AuthenticatedIdentity, Authenticated, TokenInfo, UserRoles, None, Academic, PayrollCampus, HumanResources, AcademicTitle, FTE } from '../domain';
import { ReplaySubject, Observable, Subscription, interval } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';

import { ConsoleService } from '../widgets/console.service';
import { AuthenticationResult } from '@azure/msal-browser';
import { resolve } from 'dns';

const HEARTBEAT_INTERVAL_IN_SECONDS = 60;
const LOCAL_STORAGE_KEY = "thank.you.president.lincoln";
const LOCAL_STORAGE_KEY_DATE = "thank.you.president.lincoln.date";
const LOCAL_STORAGE_CLAIMS_KEY = "projectboard.user.claims";

export class AzureAuthDetails {
	constructor(
		public readonly idToken: string,
		public readonly azureADToken: string
	){}
}

@Injectable()
export class AuthenticationService implements Svc {
	private readonly _currentIdentitySubject = new ReplaySubject<Option<AuthenticatedIdentity>>(1);
	public readonly currentIdentity: Observable<Option<AuthenticatedIdentity>>;
	private _currentUser: Option<CurrentUser> = Option.create<CurrentUser>();
	constructor(
		private console: ConsoleService,
		private appSettings: AppSettings,
		private http: HttpClient
	){
		this.currentIdentity = this._currentIdentitySubject.asObservable();
		this.currentIdentity.subscribe(i => {
			this._currentUser = i.map(i => i.user);
		});
	}

	public config(): Promise<null>{
		return Promise.resolve(null);
	}

	public accept(a: Authenticated): void {
		this._currentIdentitySubject.next(Option.create(new AuthenticatedIdentity(a.tokenInfo.token, a.tokenInfo.tokenTimeoutDate, a.user)));
		window.localStorage.setItem(LOCAL_STORAGE_KEY, a.tokenInfo.token);
		window.localStorage.setItem(LOCAL_STORAGE_KEY_DATE, a.tokenInfo.tokenTimeoutDate.toString());
		this.startHeartbeat();
	}

	public tryAuthenticateViaAzureAD(authResult: AuthenticationResult): Promise<boolean> {
		let claimsJson =  JSON.stringify(authResult);
		window.localStorage.setItem(LOCAL_STORAGE_CLAIMS_KEY, claimsJson);
		return new Promise((resolve) => {
			this.http.post(
					this.appSettings.APIURL + '/authentication/accept/',
					new AzureAuthDetails(
						authResult.idToken,
						authResult.accessToken
					)
				)
				.subscribe(
					json =>
					{
						this.accept(Authenticated.fromJson(json));
						this.startHeartbeat();
						resolve(true);
					},
					_ => {
						this.console.log('ERROR authenticating via Azure AD', authResult.account.name, authResult.account.username, authResult.idTokenClaims["employeeid"], authResult.uniqueId);
						resolve(false);
					});
		});
	}

    public tryGetCurrentAcademicUserSync(): Option<CurrentAcademicUser>{
		return this._currentUser
			.bind(cu => cu.tryGetCurrentAcademicUser());
    }

	public clearCurrentUser(): void {
		this._currentIdentitySubject.next(Option.create<AuthenticatedIdentity>());
		window.localStorage.removeItem(LOCAL_STORAGE_KEY);
		window.localStorage.removeItem(LOCAL_STORAGE_KEY_DATE);
		window.localStorage.removeItem(LOCAL_STORAGE_CLAIMS_KEY);
		this.stopHeartbeat();
	}

	public tryAuthenticateViaSecureString(secureString: string): Promise<boolean>{
		return new Promise((resolve) => {
			this.http.get(this.appSettings.APIURL + '/authentication/process/?secureString=' + secureString + '&resultType=json')
				.subscribe(
					json =>
					{
						this.accept(Authenticated.fromJson(json));
						this.startHeartbeat();
						resolve(true);
					},
					_ => {
						this.console.log('ERROR authenticating via securestring');
						resolve(false);
					});
		});
	}

	public tryAuthenticateViaLocalStorage(): Promise<boolean>{
		return new Promise(resolve => {
			let token = window.localStorage.getItem(LOCAL_STORAGE_KEY);
			let tokenTimeoutDate = new Date(window.localStorage.getItem(LOCAL_STORAGE_KEY_DATE));
			if(!token){
				resolve(false);
				return;
			}
			this.http.get(this.appSettings.APIURL + '/authentication/heartbeat',
			{
				headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token })
			})
			.pipe(map(data => HeartbeatResult.fromJson(data)))
			.subscribe(
				heartbeat => {
					heartbeat.authenticatedUser
					.map((cu:CurrentUser) => new Authenticated(new TokenInfo(token, tokenTimeoutDate), cu, heartbeat.currentApplicationVersion))
					.matchDo(
						() => {
							resolve(false);
						},
						(a: Authenticated) =>  {
							this.accept(a);
							this.startHeartbeat();
							resolve(true);
						});
				},
				err => {
					this.console.log('ERROR authenticating token from memory: ', err);
					resolve(false);
				});
		});
	}

	private _findUserByEmployeeId(employeeId): Promise<CurrentUser> {
		return new Promise((resolve) => {
			this.http.get(this.appSettings.APIURL + '/user/employeeid/' + employeeId)
				.subscribe(
					json =>
					{
						resolve(CurrentUser.fromJson(json));
					},
					_ => {
						this.console.log('ERROR getting authenticated user');
						resolve(null);
					});
		});
	}

	private _previousHeartbeat: HeartbeatResult;
	private _heartbeatSubscription: Subscription;
	private startHeartbeat(){
		if(!!this._heartbeatSubscription){
			return; //Only start the heartbeat once, no matter how many times this method is called.
		}
		this.console.info('[Heartbeat STARTED]');
		this._heartbeatSubscription = 
			interval(HEARTBEAT_INTERVAL_IN_SECONDS * 1000)
			.pipe(
				flatMap(_ => {
					this.console.info('[HEARTBEAT]');
					return this.http.get(this.appSettings.APIURL + '/authentication/heartbeat');
				})
			)
			.pipe(map(data => HeartbeatResult.fromJson(data)))
			.subscribe(
				heartbeat => {
					heartbeat.authenticatedUser.matchDo(
						() => {
							this.clearCurrentUser();
						},
						u => {
							//(In case the server responds with an identity differing from the one we have)
							this._currentUser.filter(cu => cu.id != u.id).doWithValue(_ => {
								this.clearCurrentUser();
							});
						});
					if (!!this._previousHeartbeat && this._previousHeartbeat.currentApplicationVersion != heartbeat.currentApplicationVersion) {
						this.console.log("Application Version changed");
					}
					this._previousHeartbeat = heartbeat;
				},
				err => {
					this.console.error('HEARTBEAT ERROR: ', err);
					this.clearCurrentUser();
				});
	}

	private stopHeartbeat(){
		if(!!this._heartbeatSubscription){
			this.console.info('[Heartbeat STOPPED]');
			this._heartbeatSubscription.unsubscribe();
			this._heartbeatSubscription = undefined;
			this._previousHeartbeat = undefined;
		}
	}
}