<div class="d-flex justify-content-between progress-container" style="max-width: 100%;overflow-x: scroll;">
    <div *ngFor="let s of progress.steps; let i = index" class="card" [ngClass]="{
        active: progress.stepActive(s),
        complete: progress.stepCompleted(s)
    }">
        <div class="card-block text-center">
            <!--<div class="fa fa-user-circle" [title]="s" style="font-size:2em;"></div>-->
            <div> {{i+1}}. {{s}} </div>

            <div *ngIf="progress.stepActive(s)" class="text-sm text-success"><strong>Active Step</strong></div>
            <div *ngIf="progress.stepCompleted(s)" class="text-sm text-muted">Step Complete</div>
        </div>
    </div>
</div>
<!--
<pre>{{progress|json}}</pre>
-->