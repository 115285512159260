<!-- Any property in Project is invalid -->
<div *ngIf="property == null">
	<div *ngIf="invalid && messages.size > 0" [matTooltip]="renderAllMessages()">
		<i class="fa fa-warning text-muted"></i>
	</div>
</div>

<!-- specific property is defined -->
<div *ngIf="!!property">
	<div *ngIf="propertyInvalid(property)" [matTooltip]="renderMessageForProperty(property)">
		<i class="fa fa-warning text-muted"></i>
	</div>
</div>