import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as Search from './../../widgets/search';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import {
	ActivityService,
	Activity,
	ActivityFilterSpecification,
	ActivityTypeId,
   	ActivitySubType,
	ActivityParent,
	ActivityParentType,
	ActivityTypeCollection,
	DossierService,
	Option,
	UserPreferenceService,
	ActivityCategory,
	AuthenticationService,
    ActivityCollaborationInvitation,
	ActivityCollaborationOriginal,
	IdAndName,
	Project,
	Theme,
	ProjectService,
	HumanResources,
	AuthenticatedIdentity,
	Academic,
	OutReachTypes
} from '../../domain';
import { ConsoleService } from '../../widgets/console.service';
import { ActivityModalService } from '../activity-modal.service';
import { ActivityCollaborationConfirmationModalComponent, ActivityCollaborationConfirmationModalResponse } from '../activity-collaboration-confirmation-modal/activity-collaboration-confirmation-modal.component';
import { unescape } from 'querystring';
import { formatDate } from '@angular/common';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { TooltipService } from '../../widgets/tooltip.service';
import { ActivityListComponent } from '../../widgets/activity-list/activity-list.component';

enum ActivityListState {
	All,
	UpcomingAndRecent
}

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {
	@Input() tabsVisible = true;
	@Input() parent: ActivityParent;
	@Input() copyActivity: Event;

	private _activities: Observable<Activity[]>;
	private _filteredActivities: Observable<Activity[]>;
	private searchInput = new FormControl();

	filterForm: FormGroup;
	avtivityFilter: ActivityFilterSpecification;

	ActivityParentType = ActivityParentType;
	ActivityTypeId = ActivityTypeId;
	activitiesByTabId: {};
	filteredActivitiesByTabId: {};
	tabs: ActivityCategory[];
	activeTab: ActivityCategory;
	activityTypes: ActivityTypeCollection = new ActivityTypeCollection([]);
	collaborationInvitations: ActivityCollaborationInvitation[] = [];
	themes: Theme[] = undefined;
	projects: Project[] = undefined;
	meritAndPromotionHR = HumanResources.ANR;
	OutreachTypes = OutReachTypes;
	@ViewChild(ActivityListComponent ) activityList: ActivityListComponent ;
	private clienteleContactTypes = [{"id":OutReachTypes.external,"name":"	External Audience"},{"id":OutReachTypes.internal,"name":"Internal / Statewide Program"},{"id":OutReachTypes.noReport,"name":"Choose not to report"}];
    constructor(
		private activityService: ActivityService,
		private matSnackbar: MatSnackBar,
		private matDialog: MatDialog,
		private userPreferenceService: UserPreferenceService,
		private console: ConsoleService,
		private activityModals: ActivityModalService,
		private themeService: DossierService,
		private projectService: ProjectService,
		fb: FormBuilder,
		authService: AuthenticationService,
		private gaService: GoogleAnalyticsService,
		public tooltipSvc: TooltipService
	){
		this.searchInput
			.valueChanges
			.pipe(debounceTime(200))
			.subscribe(e => this.searchTerm = e);

		this.activityTypes = this.activityService.getActivityTypes();
		this.tabs = 
			ActivityCategory.createForAcademicUserOption(
				this.activityTypes,
				authService.tryGetCurrentAcademicUserSync());

		this.activateTab(this.tabs[0]);

		this.filterForm = fb.group({
			withNameMatching: null,
			startDate: null,
			endDate: null,
			combinedActivityTypes: null,
			belongingToAThemeWithIdIn: null,
			belongingToAProjectWithIdIn: null,
			missingInformation: null,
		    clienteleContactsType: "",
			clienteleGroup: null,
		});

		this.filterForm.valueChanges.pipe(
			debounceTime(200))
			.forEach(() => {
				if(this.filterForm.status === 'INVALID')
				{
					this.console.error('Filter Form errors:', this.filterForm.errors);
					return;
				}
				this.activityList.resetBulkUpdate();
				/* StartDate/EndDate could be the reason for the form being in an invalid state.
				** To prevent an exception, we only attempt to resolve the new activity value
				** in the case of a *valid* form state. **/
				var activityFilter = this.getActivityFilterFromForm();
				this.console.info('Activity Filter Changed: form -> activity:', this.filterForm.value, activityFilter);
				this.gaService.eventEmitter("Activity Filter Search", "Activity Filter", "Search");
				this.activityService.searchActivities(activityFilter);
			});

			authService.currentIdentity.subscribe(identity => 
			identity.doWithValue((i: AuthenticatedIdentity) => 
				i.user.roles.academic.doWithValue((a: Academic) =>
				{
					this.meritAndPromotionHR = a.meritAndPromotionHr;
				})));
	}

	hasInstructions() {
		if (this.getInstructions().length > 0) {
			return true;
		} 
		return false;
	}

	getInstructions(): string {
		return this.tooltipSvc.tooltip("ActivityInstructions." + this.tooltipSvc.mapMeritAndPromotionsHRToString(this.meritAndPromotionHR));
	}

	searchTerm = '';
	search = Search.ResultsFunction.fromPredicate(
		Search.Predicate.objectStringProjectors<Activity>(
		[
			a => this.activityTypes.typeName(a),
			a => a.name,
			a => a.tags.map(t => t.name).join(''),
			a => a.parent.match(() => '', v => v.name)
		]));

	states = [
		{
			id: ActivityListState[ActivityListState.UpcomingAndRecent],
			label: 'Upcoming and Recent'
		},
		{
			id: ActivityListState[ActivityListState.All],
			label: 'All'
		}
	];

	viewingActivity: Activity | null = null;

	get activities(): Activity[] {
		return (!!this.activitiesByTabId && this.activeTab.id in this.activitiesByTabId)
			? this.activitiesByTabId[this.activeTab.id]
			: undefined;
	}
	
	get filteredActivities(): Activity[] {
		return (!!this.filteredActivitiesByTabId && this.activeTab.id in this.filteredActivitiesByTabId)
			? this.filteredActivitiesByTabId[this.activeTab.id]
			: undefined;
	}

	private loadCollaborationInvitations(){
		this.activityService.getCollaborationInvitations().then(xs => this.collaborationInvitations = xs);
	}

	private readonly today = new Date();
	get activitiesRecent(){
		let acts = this.activities;
		if(!acts){
			return;
		}
		return acts.filter(a => 
			a.date.match(
				single => single.date < this.today,
				range => range.startDate < this.today && range.endDate < this.today,
				ongoing => ongoing.startDate < this.today
			));
	}
	get activitiesUpcoming(){
		let acts = this.activities;
		if(!acts){
			return;
		}
		return acts.filter(a => 
			a.date.match(
				single => single.date >= this.today,
				range => range.startDate >= this.today || range.endDate >= this.today,
				ongoing => ongoing.startDate >= this.today
			));
	}
	private _currentStateId = ActivityListState.All;
	get currentStateId(){
		return ActivityListState[this._currentStateId];
	}

	activateTab(tab: ActivityCategory){
		this.activeTab = tab;
	}

	switchToStateId(stateId: string){
		this._currentStateId = ActivityListState[stateId];
	}

    ngOnInit() {
		// subscribe to activities in service
		this._activities = !!this.parent 
			? this.activityService.getActivitiesByParent(this.parent) 
			: this.activityService.activities;
		this._filteredActivities = this.activityService.filteredActivities;

		// does http request to get all activities
		this.activityService.loadActivities();

		this.themeService.getThemes().then(themes => {
			this.themes = themes;
		});

		this.projectService.projects.subscribe(p => {
			this.projects = p;
		});

		this.projectService.loadProjects();

		if(!this.parent){
			this.loadCollaborationInvitations();
		}

		this.subscribeForActivityTabs(this._activities).subscribe(byTabId => {this.activitiesByTabId = byTabId; });
		this.subscribeForActivityTabs(this._filteredActivities).subscribe(byTabId => {this.filteredActivitiesByTabId = byTabId; });

		this.console.log('activityTypes (O)',this.activityTypes);
    }

	private subscribeForActivityTabs(activities: Observable<Activity[]>): Observable<{}> {
		return activities.pipe(map(acts => {
			//this.activities = acts;
			let byTabId = {};
			let tabByActivitySubTypeId = {};
			this.tabs.forEach(t => {
				byTabId[t.id] = [];
				t.activityTypes.all.forEach(at => 
						at.subTypes.forEach(st =>
							tabByActivitySubTypeId[st.id] = t));
			});
			this.console.log('tabByActivitySubTypeId (O):',tabByActivitySubTypeId),
			acts.forEach(a => {
				if(!(a.subTypeId in tabByActivitySubTypeId)){
					return;
				}
				byTabId[tabByActivitySubTypeId[a.subTypeId].id].push(a);
			});

			// opens an activity in a modal after collaboration confirmation and the user selected the checkbox to edit the new activity.
			if(!!this.editCollaborationActivityId) {
				let activity = acts.find(a => 
					a.activityCollaborationOriginal
					.getValueOrDefault(new ActivityCollaborationOriginal(0, new IdAndName(0, ""), ""))
					.id == this.editCollaborationActivityId);
				if(!!activity){
					this.openActivityInModal(activity);
					this.editCollaborationActivityId = null;
				}
			}
			this.console.log('activities loaded (O):', acts, this.activitiesByTabId);
			return byTabId;
		}));
	}

	openActivityMenu(){
		this.console.log('current activity category id: ', this.activeTab.id);
		this.activityModals.openActivityMenu(
			this.matDialog,
			Option.create(this.parent),
			this.activeTab.id);
	}

	editActivity(activity: Activity){
		if (this.userPreferenceService.getOpenActivityInModalPreference()){
			this.openActivityInModal(activity);
		} else {
			// opens sidebar for editing
			this.viewingActivity = activity;
		}
	}

	private editCollaborationActivityId: number = null;
	add(collab: ActivityCollaborationInvitation){
		ActivityCollaborationConfirmationModalComponent.open(this.matDialog, collab).afterClosed().subscribe((result: ActivityCollaborationConfirmationModalResponse) =>
		{
			this.console.log("acivity collaboration result: ", result);
			if(!result.confirmed){
				return;
			}
			if(result.editAfterSaving){
				this.editCollaborationActivityId = result.originalActivityId;
				//this.console.log("editCollaborationActivityId:", this.editCollaborationActivityId);
				this.activityService.loadActivities();
			}
			this.loadCollaborationInvitations();
			
		});
	}

	ignore(collab: ActivityCollaborationInvitation){
		this.activityService.ignoreCollaboration(collab.originalActivity.id).then(result => 
			{
				result.matchDo(
					_ => this.matSnackbar.open("Collaboration invitation ignored"),
					msg => this.matSnackbar.open("Unable to ignore collaboration invitation: " + msg));
					this.loadCollaborationInvitations();
			});
	}

	openActivityInModal(activity: Activity){
		this.activityModals.openActivityInModal(this.matDialog, activity);
	}

	startNewActivity(subType: ActivitySubType){
		this.activityModals.startNewActivity(
			this.matDialog, 
			subType, 
			Option.create<ActivityParent>(this.parent));
	}

	startActivityCopy(activity: Activity){
		this.activityModals.startActivityCopy(this.matDialog, activity);
	}

	resetFilters() {
		this.filterForm.setValue({
			withNameMatching: null,
			startDate: null,
			endDate: null,
			combinedActivityTypes: null,
			belongingToAThemeWithIdIn: null,
			belongingToAProjectWithIdIn: null,
			missingInformation: null,
			clienteleContactsType: "",
			clienteleGroup: null
		});
		this.gaService.eventEmitter("Activity Filter Reset", "Activity Filter", "Reset");
        this.activityList.resetBulkUpdate();
	}

	getActivityFilterFromForm() {
		let fv = this.filterForm.value;
		
		let activityTypesSelected = (<string[]>fv.combinedActivityTypes || [])
			.filter(s => s.substr(0,3) == "at_")
			.map(s => +(s.replace("at_", "")));
		let activitySubTypesSelected = (<string[]>fv.combinedActivityTypes || [])
			.filter(s => s.substr(0,3) == "st_")
			.map(s => +s.replace("st_", ""));
		return new ActivityFilterSpecification(
			fv.withNameMatching,
			activityTypesSelected,
			activitySubTypesSelected,
			<Date>fv.startDate,
			<Date>fv.endDate,
			fv.belongingToAThemeWithIdIn,
			fv.belongingToAProjectWithIdIn,
			fv.missingInformation,
			fv.clienteleContactsType,
			fv.clienteleGroup?.id
		); 
	}

	dateChange(type: string, e: MatDatepickerInputEvent<Date>){
		this.console.info('Date Change: (' + type + ')', e.value);
	}
}