import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Project, ProjectService, ActivityParent, ProjectActivityParent, AuthenticationService, HumanResources, ProjectProperty, AuthenticatedIdentity, Academic } from '../../domain';
import { MatDialog } from '@angular/material/dialog';
import { ConsoleService } from '../../widgets/console.service';
import { TooltipService } from '../../widgets/tooltip.service';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {
	public StaticText: any;
	ProjectProperty = ProjectProperty;
	projectAsParent: ActivityParent;
	project: Project;
	tabs = [
		'description',
		'notes',
		'documents',
		'fte'
	];
	activeTab = this.tabs[0];

	abbreviatedActivities = false;

    constructor(
		authSvc: AuthenticationService,
        private projectService: ProjectService,
        private route: ActivatedRoute,
		private matDialog: MatDialog,
		private console: ConsoleService,
		public tooltipSvc: TooltipService
	){ 
		this.StaticText = tooltipSvc.StaticText;
		authSvc.currentIdentity.subscribe(identity => 
			identity.doWithValue((i: AuthenticatedIdentity) => 
				i.user.roles.academic.doWithValue((a: Academic) =>
				{
					this.abbreviatedActivities = a.meritAndPromotionHr == HumanResources.Campus;
				})));
	}

	private projectId: number;

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
			let projectId = +params['id'];
			this.projectId = projectId;
			this.projectService.loadProjects();
			this.projectService.getProject(this.projectId).subscribe(project => {
				this.console.log('project loaded', project);
				// project may be undefined
				if(!!project){
					this.project = project;
					this.projectAsParent = new ProjectActivityParent(project.id, project.name, project.programArea.name);
				}
			});
		});
    }

    editProject(project: Project): void {
		this.projectService.editProjectInModal(this.matDialog, project);
    }
}