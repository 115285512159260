import { Pipe, PipeTransform } from "@angular/core";
import { Case, Position } from "../../domain/types/ahr";

@Pipe({
	name: 'titleRankStepTerm'
})
export class TitleRankStepTermPipe implements PipeTransform {

	transform(value: any, args?: any): any {
		return TitleRankStepTermPipe.PositionToTitleRankStepTerm(<Position>value);
	}

	public static PositionToTitleRankStepTerm(position: Position){
		let out = position.title;
		if(position.rank != ""){
			out = out + ' / ' + position.rank;
		}
		if(position.step != ""){
			out = out + ' / ' + position.step;
		}
		if(position.term != ""){
			out = out + ' / ' + position.term;
		}
		return out;
	}
}