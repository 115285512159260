<input type="text"
	#input
	class="form-control"
	[ngClass]="controlClass"
	placeholder="Search zipcodes, cities, counties"
	[auto-complete]="auto"
	[formControl]="searchControl"
	[displayWith]="displayWith" aria-label="Search zipcodes, cities, counties" role="search">

<auto-complete #auto="auto-complete">
	<auto-complete-option *ngFor="let location of locations" [value]="location">
		{{location.name}}
		<small [ngSwitch]="location.type">
			<span *ngSwitchCase="'County'">
				County
			</span>
			<span *ngSwitchDefault>
				- {{location.parentLocationName.value}}
			</span>
		</small>
	</auto-complete-option>
</auto-complete>
