import { User } from './user';
import { ProgramArea } from './program-area';
import { Tag, TagDto } from './tag';
import { Theme } from './theme';
import { Option } from './option';
import { NonANRCollaborator, NonANRCollaboratorDto } from '.';

export class Project {
    constructor(
		public id: number,
		public name: string,
		public startDate: Date,
		public description: string,
		public theme: Option<Theme>,
		public programArea: ProgramArea,
		public role: string,
		public multistate: boolean,
		public multistateFTEAffiliation: string,
		public tags: Tag[],
		public ANRCollaborators: User[],
		public nonANRCollaborators: NonANRCollaborator[],
		public activityCount: number,
		public status: string,
		public lastActivity: Date,
		public lastUpdated: Date,
		public supportAmountDuration: string,
		public supportSource: string
    ) { }

	public static fromJson(o: any): Project {
		//console.log('Project.fromJson: ', o);
		return new Project(
			o.id,
			o.name,
			new Date(o.startDate),
			o.description,
			Option.fromJson<Theme>(o.theme, Theme.fromJson),
			ProgramArea.fromJson(o.programArea),
			o.role,
			null, //TODO: remove multistate property
			null, //TODO: remove multistate property
			(<any[]>o.tags).map(Tag.fromJson),
			(<any[]>o.ANRCollaborators).map(c => User.fromJson(c.user)),
			(<any[]>o.nonANRCollaborators).map(NonANRCollaborator.fromJson),
			o.activityCount,
			o.status,
			o.lastActivity,
			o.lastUpdated,
			o.supportAmountDuration,
			o.supportSource);
	}

	public static empty(): Project {
		return new Project(
			0,
			"",
			null,
			"",
			null,
			new ProgramArea(-1, "none", false),
			"",
			null, //TODO: remove multistate property
			null, //TODO: remove multistate property
			[],
			[],
			[],
			0,
			null,
			null,
			null,
			"",
			"");
	}
}

export class ProjectDto {
	constructor(
		public name: string,
		public description: string,
		public programAreaId: number | null,
		public role: string,
		public startDate: Date,
		public themeId: number | null,
		public ANRCollaboratorUserIds: number[],
		public nonANRCollaborators: NonANRCollaboratorDto[],
		public tags: TagDto[],
		public newUserDefinedTags: string[],
		public supportAmountDuration: string,
		public supportSource: string) { }
}

export class SimplifiedProject {
	constructor(
		public readonly id: number,
		public readonly name: string){ }
}

export enum ProjectProperty {
	Name = 1,
	Startdate = 2,
	Description = 3,
	Theme = 4,
	ProgramArea = 5,
	Role = 6,
	Tags = 7,
	ANRCollaborators = 8,
	nonANRCollaborators = 9
}