import {HumanResources} from './user';
export class ActivityType {
	constructor(
		public readonly id: ActivityTypeId,
		public readonly name: string,
		public readonly subTypes: ActivitySubType[])
	{ }

	public static fromJson(o): ActivityType {
		return new ActivityType(
			<ActivityTypeId>o.id,
			o.name,
			o.subTypes.map(ActivitySubType.fromJson)
		);
	}
}


export class ActivitySubType {
	constructor(
		public readonly id: ActivitySubTypeId,
		public readonly name: string,
		public readonly nameLabel: string,
		public readonly properties: StringMap<ActivityValuePresence>,
		public readonly appliesToHR: HumanResources[],
		public readonly allowNewActivities: boolean){ }

	public static fromJson(o): ActivitySubType {
		var properties: StringMap<ActivityValuePresence> = {};
		for(var k in o.properties){
			var value: ActivityValuePresence = +ActivityValuePresence[o.properties[k]];
			//console.log(value);
			properties[k] = value;
		}
		return new ActivitySubType(
				<ActivitySubTypeId>o.id,
			   	o.name,
			   	o.nameLabel,
				properties,
				o.appliesToHR 
					? (<number[]>o.appliesToHR).map(hr => <HumanResources>hr)
					: [],
				<boolean>o.allowNewActivities);
	}
}

export enum ActivityValuePresence
{
	Optional, Required, NA
}

export interface StringMap<TValue> {
	[key: string]: TValue;
}

export enum ActivityTypeId 
{
	AppliedResearchAndCreative = 10,
	Extension = 20,
	ExtensionAndOrOutreach = 30,
	Other = 40,
	Outreach = 50,
	ProfessionalCompetence = 60,
	UniversityAndPublicService = 70
}

export enum ActivitySubTypeId 
{
	DigitalMedia = 20,
	EducationalMaterials = 30,
	EducationalPresentation = 40,
	ExternalCollaboration = 50,
	GenericNonAcademic = 60,
	IndividualClienteleContacts = 70,
	IndividualOutreach = 80,
	MassMediaOutreach = 90,
	MediaOutletProgramOrInterview = 100,
	MeetingOrganized = 110,
	NewsletterOutreach = 120, // was renamed to: Promotional Material Effort
	OtherAppliedResearchOrCreative = 130,
	OtherExtension = 140,
	OtherOutreach = 150,
	PersonalLetterOutreach = 160,
	PolicyEngagement = 170,
	Research = 180,
	TrainTheTrainer = 190,
	WebsiteOrSocialMedia = 200,
	ProfessionalDevelopmentAndTraining = 210,
	EvidenceOfProfessionalCompetence = 220,
	UniversityService = 230,
	PublicService = 240
}

export const AllActivityTypeIds: ActivityTypeId[] =
	Object
	.keys(ActivityTypeId)
	.map(k => parseInt(ActivityTypeId[k]))
	.filter(value => !isNaN(value))
	.map(id => <ActivityTypeId>id);

export const AllActivitySubTypeIds: ActivitySubTypeId[] =
	Object
	.keys(ActivitySubTypeId)
	.map(k => parseInt(ActivitySubTypeId[k]))
	.filter(value => !isNaN(value))
	.map(id => <ActivitySubTypeId>id);
