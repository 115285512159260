import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'pdfviewer-modal',
  templateUrl: './pdfviewer-modal.component.html',
  styleUrls: ['./pdfviewer-modal.component.scss']
})
export class PdfviewerModalComponent implements OnInit {
  objectUrl: any = '';
  title: string = '';
	constructor(
		private readonly dialogRef: MatDialogRef<PdfviewerModalComponent>,
		@Inject(MAT_DIALOG_DATA) data: any
	) {
    this.objectUrl = data.objectUrl;
    this.title = data.title;
	}

  ngOnInit() {
  }

	public static open(
    md: MatDialog,
    title: string,
    objectUrl: any,
    width: string = '800px',
    height: string = '90vh',
    wrapperClasses: string = ''
  ): MatDialogRef<PdfviewerModalComponent> {
		return md.open(PdfviewerModalComponent, {
      width,
      height,
			data: {
				title,
				objectUrl,
				wrapperClasses
			}
		});
	}

  public close() {
    
  }
}