import {
	ConditionChange,
   	ConditionChangeFTEDto,
	LandGrantInstitution,
	SimplifiedProject,
	FTEOverview,
	ProgramArea,
   	Result, 
		MultiStateProjectDto
} from '../types';
import { Observable } from 'rxjs';

export class FiscalYear {
	constructor(
		public readonly startDate: Date,
		public readonly endDate: Date
	) {}
}

export abstract class FTEService {
	public abstract get currentStateFiscalYear(): Observable<FiscalYear>;
	public abstract config(): Promise<null>;
    public abstract saveCountyFTE(data: Map<number,number>): Promise<Result<null,string>>;
    public abstract saveMultiStateProject(data: MultiStateProjectDto): Promise<Result<null,string>>;
	public abstract endMultiStateProject(projectId: number, reason: string): Promise<Result<null,string>>;
    public abstract saveConditionChangeFTE(data: ConditionChangeFTEDto[]): Promise<Result<null,string>>;
    public abstract getOverview(): Promise<FTEOverview>;
	public abstract getConditionChanges(): Promise<ConditionChange[]>;
	public abstract getProgramAreas(): Promise<ProgramArea[]>;
	public abstract getLandGrantInstitutions(): Promise<LandGrantInstitution[]>;
	public abstract getProjects(): Promise<SimplifiedProject[]>;
	public abstract getUCCampus(): Promise<Campus[]>;
}

import { Component, Input } from '@angular/core';
import { Campus } from '../types/campus';
@Component({
	selector: 'current-state-fiscal-year',
	template: 
		`<span *ngIf="!!year">{{year.startDate | date:dateFormat}}{{separator}}{{year.endDate | date:dateFormat}}</span>`
})
export class CurrentStateFiscalYear {
	@Input() separator = "-";
	@Input() dateFormat = "yyyy";
	year: FiscalYear = null;
	constructor(svc: FTEService) 
	{ 
		svc.currentStateFiscalYear.subscribe(y => this.year = y);
	}
}