import { Component, Inject, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConsoleService } from '../console.service';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { FTEService, LandGrantInstitution, NonANRCollaborator, LandGrant, Other, Tribal,UCCampus } from '../../domain';
import { Campus } from '../../domain/types/campus';

@Component({
  templateUrl: './non-anr-collaborator-modal.component.html',
  styleUrls: ['./non-anr-collaborator-modal.component.scss']
})
export class NonANRCollaboratorModalComponent {
  form: FormGroup;
  labelClass = 'col-4';
  controlClass = 'col-8';
  closing = false;
  landGrantInstitutions: LandGrantInstitution[] = [];
  tribalInstitutions: LandGrantInstitution[] = [];
  ucCampusList: Campus[]=[];
	constructor(
    private fb: FormBuilder,
    private fteSvc: FTEService,
    private injector: Injector,
		private console: ConsoleService,
		public dialogRef: MatDialogRef<NonANRCollaboratorModalComponent>,
		@Inject(MAT_DIALOG_DATA) data: any) 
	{	
    this.form = fb.group({
      'name': ['',Validators.required],
      'ucCampus': '',
      'lgi': '',
      'otherInstitutionName': [''],
      'emailAddress': '',
      'websiteAddress': ''
    }, {
      validator: validInstitution()
    });
    fteSvc.getLandGrantInstitutions().then(lgis => {
      this.landGrantInstitutions = lgis.filter(i => i.institutionType == "LandGrant" && i.stateId !== 'CA');
      this.tribalInstitutions = lgis.filter(i => i.institutionType == "Tribal");
    });
    fteSvc.getUCCampus().then(uc => {
      this.ucCampusList = uc.filter(p=> p.abbreviation !== 'UCOP');
    });
    if(!!data.collaborator && data.collaborator instanceof NonANRCollaborator){
      let c: NonANRCollaborator = data.collaborator;
      let formValue: any = {
        'name': c.name,
        'emailAddress': c.emailAddress,
        'websiteAddress': c.websiteAddress,
        'lgi': '',
        'ucCampus':'',
        'otherInstitutionName': ''
      };
      if(!!c.institution){
        c.institution.matchDo(
          lg => {
            if(this.landGrantInstitutions.some(lgi => lgi.id == lg.id))
              formValue['lgi'] = lg.id;
          },
          t => {
            if(this.tribalInstitutions.some(lgi => lgi.id == t.id))
              formValue['lgi'] = t.id;
          },
          other => {
            formValue['otherInstitutionName'] = other.institutionName;
          },
          c => {
            if(this.ucCampusList.some(uc => uc.id === c.id))
               formValue['ucCampus']
          }
        )
      }
      this.form.setValue(formValue);
    }
  }

	close() {
    this.dialogRef.close();
    this.closing = true;
  }
  
  save(){
    if(!this.form.valid){
      return;
    }
    this.dialogRef.close(this.getCollaboratorFromForm());
    this.closing = true;
  }
  validateForm(type:string){
    switch(type)
    {
      case 'ucCampus':
          if(!!this.form.controls.ucCampus.value){
            this.form.controls.lgi.disable();
            this.form.controls.otherInstitutionName.disable();
            this.form.controls.lgi.updateValueAndValidity();
            this.form.controls.otherInstitutionName.updateValueAndValidity();
          }
          else{
            this.form.controls.lgi.enable();
            this.form.controls.otherInstitutionName.enable();
            this.form.controls.lgi.updateValueAndValidity();
            this.form.controls.otherInstitutionName.updateValueAndValidity();
          }
          break;
      case 'lgi':
          if(!!this.form.controls.lgi.value){
            this.form.controls.ucCampus.disable();
            this.form.controls.otherInstitutionName.disable();
            this.form.controls.ucCampus.updateValueAndValidity();
            this.form.controls.otherInstitutionName.updateValueAndValidity();
          }    
          else{
            this.form.controls.ucCampus.enable();
            this.form.controls.otherInstitutionName.enable();
            this.form.controls.ucCampus.updateValueAndValidity();
            this.form.controls.otherInstitutionName.updateValueAndValidity();
          }   
          break;
      case 'otherInstitutionName':
          if(!!this.form.controls.otherInstitutionName.value){
            this.form.controls.ucCampus.disable();
            this.form.controls.lgi.disable();
            this.form.controls.ucCampus.updateValueAndValidity();
            this.form.controls.otherInstitutionName.updateValueAndValidity();
          }
          else{
            this.form.controls.ucCampus.enable();
            this.form.controls.lgi.enable();
            this.form.controls.ucCampus.updateValueAndValidity();
            this.form.controls.otherInstitutionName.updateValueAndValidity();
          }
          break;
    }
  }
  private getCollaboratorFromForm(): NonANRCollaborator{
    let fv: any = this.form.value;
    let institution = (() => {
      let landGrant = this.landGrantInstitutions.filter(lgi => lgi.id == fv.lgi)[0];
      let tribal = this.tribalInstitutions.filter(lgi => lgi.id == fv.lgi)[0];
      let ucCampus = this.ucCampusList.filter(uc => uc.id == fv.ucCampus)[0];
      return !!landGrant ? new LandGrant(landGrant.id, landGrant.name, landGrant.stateId)
        : !!tribal ? new Tribal(tribal.id, tribal.name, tribal.stateId) 
        :(!!ucCampus ? new UCCampus(ucCampus.id,ucCampus.abbreviation,ucCampus.name):new Other(fv.otherInstitutionName));
    })();
    return new NonANRCollaborator(
      fv.name,
      fv.emailAddress,
      fv.websiteAddress,
      institution);
  }

  public static open(matDialog: MatDialog, collaborator: NonANRCollaborator = null): MatDialogRef<NonANRCollaboratorModalComponent> {
    return matDialog.open(NonANRCollaboratorModalComponent, {
        disableClose: true,
        hasBackdrop: true,
        width: '600px',
        data: { collaborator: collaborator }
      });
  }
}
function validInstitution(){
  return (group: FormGroup): {[key: string]: any} =>
    {
    if (isNaN(parseInt(group.controls['ucCampus'].value)) && isNaN(parseInt(group.controls['lgi'].value)) && !group.controls['otherInstitutionName'].value) {
      return { institutionMissing: true };
    }
  };
}
