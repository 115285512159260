<div class="mb-2" [hidden]="!selectedUnits.any()">
	<span *ngFor="let unit of selectedUnits" class="badge badge-pill badge-tag mr-2">
		<i class="fa fa-fw fa-times" style="cursor:pointer;"
		(click)="removeUnit(unit)"></i>

		<span title="{{unit.type}} - {{unit.name}}">{{unit.name}}</span>
	</span>
</div>

<mat-form-field>
	<input type="text"
		#input
		matInput
		class="form-control"
		[ngClass]="controlClass"
		placeholder="Search Units"
		[matAutocomplete]="auto"
		[formControl]="searchControl"
		aria-label="Search units" role="search">
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete">
	<ng-container *ngIf="sortedUnits.length === 0" class="mt-3">
		<mat-option [disabled]="true">
			No ANR Unit was found by that name
		</mat-option>
	</ng-container>
	<mat-optgroup *ngFor="let group of unitGroups" [label]="group.name">
		<mat-option *ngFor="let unit of group.units" [value]="unit" (click)="selectUnit(unit)">
			<div class="media" title="{{unit.type}} - {{unit.name}}">
				{{unit.name}}
			</div>
		</mat-option>
	</mat-optgroup>
	
</mat-autocomplete>