export abstract class Option<T> {
	public abstract value: T;

	protected constructor(){}

	public abstract match<TResult>(
		none: () => TResult,
		some: (T) => TResult): TResult;

	public abstract matchDo(
		none: () => void,
		some: (T) => void): void;

	public toFSharp(): any {
		return this.match(
			() => ({'case':"None"}),
			(v:T) => ({'case':"Some","fields":[v]}));
	}

	public abstract map<TResult>(transformFn: (value:T) => TResult): Option<TResult>;
	public abstract bind<TResult>(transformFn: (value:T) => Option<TResult>): Option<TResult>;
	public abstract hasValue(): boolean;
	public abstract getValueOrThrow(): T;
	public abstract getValueOrDefault(defaultValue: T): T;
	public abstract doWithValue(someAction: (T) => void): void;

	public static fromJson<T>(o: any, fromJson: (any) => T): Option<T> {
		return o.hasValue
			? Option.create<T>(fromJson(o._value))
			: Option.create<T>();
	}

	public toJson(toJson: (value: T) => any): any {
		return this.match(
			() => null,
			toJson);
	}

	public toJsonFSharp(toJson: (value: T) => any): any {
		return this.match(
			() => ({case:"None"}),
			value => ({case:"Some",fields:[toJson(value)]}));
	}

	public filter(predicate: (value: T) => boolean): Option<T> {
		return this.match(
			() => this,
			value => predicate(value) ? this : Option.create());
	}

	public static equal<T>(a: Option<T>, b: Option<T>, equal?: (a: T, b: T) => boolean): boolean {
		return a.match(
			() => b.match(
				() => true,
				_ => false),
			(a: T) => b.match(
				() => false,
				(b: T) => !!equal ? equal(a,b) : a == b));
	}

	public equals(other: Option<T>, equal?: (a: T, b: T) => boolean): boolean {
		return Option.equal<T>(this, other, equal);
	}

	public static fromJsonFSharp<T>(o: any, fromJson: (o: any) => T): Option<T>
	{
		return (o == null || o.case != "Some")
			? Option.create<T>()
			: Option.create(fromJson(o.fields[0]));
	}

	public static create<T>(value?: T): Option<T> {
		return !!value
			? new Some<T>(value)
			: new None<T>();
	}

	public static tryGetMultiple<T>(...tryGetFunctions: (() => Option<T>)[]){
		for(var i=0; i<tryGetFunctions.length; i++){
			var option = tryGetFunctions[i]();
			if(option.match(() => false, _ => true)){
				return option;
			}
		}
		return Option.create<T>();
	}
}

export class None<T> extends Option<T> {
	public value = null as T;

	constructor(){
		super();
   	}
	public getValueOrDefault(defaultValue: T): T {
		return defaultValue;
	}
	public match<TResult>(
		none: () => TResult,
		some: (T) => TResult): TResult {
			return none();
	}
	public matchDo(
		none: () => void,
		some: (T) => void): void {
			none();
	}
	public map<TResult>(transformFn: (value:T) => TResult): Option<TResult>
	{
		return new None<TResult>();
	}

	public bind<TResult>(transformFn: (value:T) => Option<TResult>): Option<TResult>
	{
		return new None<TResult>();
	}

	public hasValue(): boolean {
		return false;
	}

	public getValueOrThrow(): T {
		throw "Unable to get value of None";
	}

	public doWithValue(someAction: (T) => void): void 
	{ }
}

export class Some<T> extends Option<T> {
	constructor(public readonly value: T){
		super();
	}
	public getValueOrDefault(defaultValue: T): T {
		return this.value;
	}
	public match<TResult>(
		none: () => TResult,
		some: (T) => TResult): TResult {
			return some(this.value);
	}
	public matchDo(
		none: () => void,
		some: (T) => void): void {
			some(this.value);
	}
	public map<TResult>(transformFn: (value:T) => TResult): Option<TResult>
	{
		return Option.create(transformFn(this.value));
	}

	public bind<TResult>(transformFn: (value:T) => Option<TResult>): Option<TResult>
	{
		return transformFn(this.value);
	}

	public hasValue(): boolean {
		return true;
	}

	public getValueOrThrow(): T {
		return this.value;
	}

	public doWithValue(someAction: (T) => void): void 
	{
		someAction(this.value);
	}
}
