<div mat-dialog-title>{{titleVerb}} an Activity</div>
<mat-dialog-content>
	<activity-form-guts [activity]="activity" 
		[wideLayout]="wideLayout"
		[types]="activityTypes"
		(activityChange)="onActivityChange($event)" 
		(formStatusChange)="onStatusChange($event)"></activity-form-guts>
</mat-dialog-content>
<mat-dialog-actions>
	<button (click)="cancel()" class="btn btn-link" [disabled]="closing">
		Cancel
	</button>
	<button [disabled]="!canSave()" (click)="save()" class="btn btn-primary">
		Save Activity
	</button>
</mat-dialog-actions>
