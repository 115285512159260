import { Component, Input } from '@angular/core';

@Component({
	selector: 'tag-list',
	templateUrl: './tag-list.component.html',
	styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent {
	@Input() max: number = 0;
	@Input() tags: any[] = [];
	@Input() keyword: string = '';
	showAll: boolean = false;
	maxTags = () => this.tags.slice(0, this.max || 0);
}
