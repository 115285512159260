import { ActivityTypeId, ActivitySubTypeId } from '../activity-type';
import { ActivityDate } from '..';

export class Activity {
	constructor(
        public readonly id: number,
        public readonly typeId: ActivityTypeId,
        public readonly subTypeId: ActivitySubTypeId,
        public readonly name: string,
        public readonly date: ActivityDate,
		public includeInDossier: boolean,
		public readonly topic: string,
        public readonly role: string,
        public readonly location: string,
		public readonly areaOfService: string,
		public readonly writtenBy: string,
		public readonly invalidReasons: string[]){ }

	public static fromJson(o: any): Activity {
		return new Activity(
			<number>o.id,
			<ActivityTypeId>o.typeId,
			<ActivitySubTypeId>o.subTypeId,
			<string>o.name,
			ActivityDate.fromJson(o.date),
			<boolean>o.includeInDossier,
			<string>o.topic,
			<string>o.role,
			<string>o.location,
			<string>o.areaOfService,
			<string>o.writtenBy,
			o.invalidReasons
		);
	}
}
