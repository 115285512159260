import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Activity, Single, ActivityService, ActivityType, ClienteleContactCollection, UserPreferenceService, ActivityTypeCollection } from '../../domain';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { ConsoleService } from '../../widgets/console.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { TooltipService } from '../../widgets/tooltip.service';

@Component({
	selector: 'activity-editor',
	templateUrl: './activity-editor.component.html',
	styleUrls: ['./activity-editor.component.scss']
})
export class ActivityEditorComponent implements OnInit {
	@Output() close = new EventEmitter();
	@Output() copyActivity = new EventEmitter<Activity>();
	public StaticText: any;
	private _activity: Activity;
	public data: Activity;
	private changedData: Activity;
	formStatus: string = "VALID";
	activityTypes: ActivityTypeCollection = new ActivityTypeCollection([]);

	@Input() 
	set activity(a) {
		this.console.log('activity was set in activity-editor',a);
		this._activity = a;
		this.data = a.duplicate();
	}
	get activity() {
		return this._activity;
	}

	ngOnInit() {}

	exit(){
		if(this.hasChanges() && window.confirm('You may have unsaved changes. Save changes?')){
			this.save();
		}
		this.close.emit();
	}

	canSave(){
		//this.console.log("Form Status: ", this.formStatus);
		if (this.formStatus == "VALID"){
			return this.hasChanges();		
		}
		return false;
	}

	constructor(
		private svc: ActivityService,
		private userPreferenceService: UserPreferenceService,
		private snackBar: MatSnackBar,
		private matDialog: MatDialog,
		private console: ConsoleService,
		public tooltipSvc: TooltipService,
		private gaService: GoogleAnalyticsService,
	){
		this.StaticText = tooltipSvc.StaticText;
		this.activityTypes = svc.getActivityTypes();
	}

	onActivityChange(a: Activity){
		this.changedData = a;
		this.console.log('activity changed in editor:', a);
	}

	onStatusChange(status: string){
		this.console.log("Form Status Change: ", status);
		this.formStatus = status;
	}

	canCloseActivityOnSave(): boolean {
		return this.userPreferenceService.getCloseAfterActivitySavePreference();
	}

	save(){
		if (this.canSave()){
			this.svc
				.saveActivity(this._activity.id, this.changedData.toDto())
				.then(result => result.matchDo(
					_ => {
						this._activity.patch(this.changedData);
						this.changedData = null;
						this.snackBar.open('Activity updated', null, {duration: 3000});
						this.gaService.eventEmitter("Activity Updated", "Activity", "Update");
						
						if(this.canCloseActivityOnSave()){
							this.exit();
						}
					},
					errorMsg => {
						this.snackBar.open('Activity not saved: ' + errorMsg, null, {duration: 10000});
					}));
		} else {
			this.snackBar.open('Please complete the form with appropriate data.', null, {duration: 3000});
		}
	}

	canCopy(){
		// don't allow copy if there are changes made
		if (!this.canSave()){
			return true;
		}
		return false;
	}

	copy(){
		if(this.canCopy()){
			// clear fields Id, Date, Contacts
			let activityCopy = this._activity.duplicate(); 
			activityCopy.id = 0;
			activityCopy.date = new Single(new Date());
			activityCopy.clienteleContacts = ClienteleContactCollection.blank();

			// TODO: May need to validate if some field values are still valid like: parent, clienteleGroup, tags, collaborators etc. As data definitions change over time these could cause issues.

			this.gaService.eventEmitter("Activity Copied", "Activity", "Copy");

			// close editor and open activity using new-activity-modal
			this.exit();
			this.copyActivity.emit(activityCopy);
		} else {
			this.snackBar.open('Copies may only be made of saved activities.', null, {duration: 3000});
		}
	}

	hasChanges(){
		return !!this.changedData;
	}

	canArchive() {
		return !!this.data;
	}

	archive(){
		if(window.confirm(this.StaticText.ArchiveActivity + '\n\nArchive this Activity?')){
			this.svc
				.archiveActivity(this._activity.id)
				.then(result => result.matchDo(
					_ => {
						let snackBarRef = this.snackBar.open('Activity Archived', 'Undo', {duration: 60 * 1000});
						this.gaService.eventEmitter("Activity Archived", "Activity", "Archive");
						snackBarRef.onAction().subscribe(() => {
							this.console.log('Activity unarchive triggered!');
							this.unarchive();
						});
						this.close.emit();
					},
					errorMsg => {
						this.snackBar.open('Activity not Archived: ' + errorMsg, null, {duration: 10 * 1000});
					}));
		}
	}

	unarchive(){
		this.svc
			.unarchiveActivity(this._activity.id)
			.then(result => result.matchDo(
				_ => {
					this.snackBar.open('Activity Restored', null, {duration: 3000});
					this.gaService.eventEmitter("Activity Restored", "Activity", "Restore");
				},
				errorMsg => {
					this.snackBar.open('Activity not Restored: ' + errorMsg, null, {duration: 10 * 1000});
				}));
	}
}
