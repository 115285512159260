import {
	User,
	CurrentUser,
	UserRoles,
	Academic,
	Option, 
	HumanResources,
	FTE,
	AcademicTitle, 
	PayrollCampus
} from '../../domain';

export const USERS: User[] = [
	new User(1, "Jeffrey","King"),
	new User(2, "Rebecca","Edwards"),
	new User(3, "Debra",  "Hernandez"),
	new User(4, "Patrick","Campbell"),
	new User(5, "Ryan",   "Perez"),
	new User(6, "Sharon", "Lewis"),
	new User(7, "Rachel", "Smith"),
	new User(8, "Dennis", "Robinson"),
	new User(9, "Pat",    "Nelson")
];

export const CURRENT_USERS: CurrentUser[] = 
	USERS.map(u => 
		new CurrentUser(
			u.id,
			u.firstName,
			u.lastName,
			userTypeToRoles(),
			""));

function userTypeToRoles(): UserRoles {
	var blank = new UserRoles(false, Option.create<Academic>(), false, false, false);
	var ucd = new PayrollCampus(3,"UCD", "UC Davis");
	/*
	return new UserRoles(
		false,
		Option.create(new Academic(
			new AcademicTitle(20,"Specialist"),
			ucd,
			new FTE(1.0, 2),
			HumanResources.Campus,
			[

			],
			true)),
		false);
	*/
	return new UserRoles(
		true,
		Option.create(new Academic(
			new AcademicTitle(10,"CE Advisor"),
			ucd,
			new FTE(1.0, 2),
			HumanResources.ANR,
			[

			])),
		false,
		false,
		false);
}