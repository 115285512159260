
import { debounceTime, startWith } from 'rxjs/operators';
import { partition } from 'rxjs';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { 
	ActivityService,
   	ActivityParent,
   	ActivityParentType,
} from '../../domain';




import * as s from '../../widgets/search';

@Component({
	selector: 'activity-parent-picker',
	templateUrl: './activity-parent-picker.component.html',
	styleUrls: ['./activity-parent-picker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ActivityParentPickerComponent,
			multi: true
		}
	]
})
export class ActivityParentPickerComponent implements ControlValueAccessor, OnInit {
	selectedParent: ActivityParent = null;
	ActivityParentType = ActivityParentType;
	private allParents: ActivityParent[] = [];
	parents: ActivityParent[] = [];
	projectThemeSearchControl = new FormControl();
	lastTerm = '';
	locations: Location[] = [];
	@Input() controlClass: string = '';
	@Input() inputGroupClass: string = '';

	parentSearch: (term: string) => ActivityParent[] =
		(() => {
			let search = s.ResultsFunction.fromPredicate<ActivityParent>(
				s.Predicate.objectGetter<ActivityParent>(p => p.name));
			return (term: string) => <ActivityParent[]> search(this.allParents, term);
		})();

	clear(){
		this.selectedParent = null;
		this._onChange(null);
		this.projectThemeSearchControl.setValue('');
	}

	constructor(
		private _element: ElementRef,
		private _svc: ActivityService) 
	{
		this.initProjectThemeObservations();
	}

	ngOnInit(){
		this.allParents = [];
		this._svc.getAvailableParents().then(ps => {
			this.allParents = ps;
			this.findParentsByTerm('');
		});
	}

	private findParentsByTerm(term: string){
		this.parents = this.parentSearch(<string>term);
	}

	private initProjectThemeObservations(){
		let [selections, termChanges] = 
			partition(
				this.projectThemeSearchControl
					.valueChanges
					.pipe(startWith(null)),
				o => o instanceof ActivityParent);

		selections
			.subscribe(o => {
				let p = <ActivityParent>o;
				this.selectedParent = p;
				this._onChange(p);
			});

		termChanges.pipe(
			debounceTime(200))
			.subscribe(term => {
				this.lastTerm = term;
				this.findParentsByTerm(<string>term);
			});
	}

	displayWith(o): string {
		if(!!o){
			return o.name;
		}
		return null;
	}

	//
	//ControlValueAccessor implementation:
	writeValue(val: any): void {
		//console.log("Value Set: ", val);
		if(val) {
			let parent = <ActivityParent>val;
			if(parent){
				this.selectedParent = parent;
				
				this.projectThemeSearchControl.setValue(parent);
				//console.log("Parent Set: ", parent);
			} else if(typeof val == 'string'){
				this.projectThemeSearchControl.setValue(val);
			}
		}
	}
	private _onChange: any = () => {};
	registerOnChange (fn): void { this._onChange = fn; }
	registerOnTouched(fn): void {}
}
