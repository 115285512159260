import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../app-settings';
import { ActivitySpecification, SavedSearch, Activity, Page } from '../domain/types/explore-whats-happening';
import { Tag, Result } from '../domain';
import { ExploreWhatsHappeningService as Svc } from "../domain";
import { Observable, ReplaySubject, Subject, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { SavedSearchDto } from 'app/domain/types/explore-whats-happening/saved-search';
import { ConsoleService } from '../widgets/console.service';
import { Project } from '../domain/types/explore-whats-happening/project';
import { Theme } from '../domain/types/explore-whats-happening/theme';

@Injectable()
export class ExploreWhatsHappeningService implements Svc {
    private _page: Subject<Page<Activity>> = new Subject<Page<Activity>>();
    private _pageProject: Subject<Page<Project>> = new Subject<Page<Project>>();
    private _themePage: Subject<Page<Theme>> = new Subject<Page<Theme>>();
    private _savedSearches: ReplaySubject<SavedSearch[]> = new ReplaySubject<SavedSearch[]>(1);
    private _usedTags: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);
    constructor(
        private readonly http: HttpClient,
        private appSettings: AppSettings,
        private console: ConsoleService
    ){ 
        this.console.log("Explore Whats Happening Service Initialized");
    }

    public usedTags(): Observable<Tag[]> {
        this.http
            .get(this.appSettings.APIURL + "/explore/my_tags")
            .subscribe(json => {
                let tags = (<any[]>json).map(Tag.fromJson);
                this._usedTags.next(tags);
            });
        return this._usedTags.asObservable();
    }

    public currentActivityPage(): Observable<Page<Activity>>{
        return this._page;
    }
    public currentProjectPage(): Observable<Page<Project>>{
        return this._pageProject;
    }
    public currentThemePage(): Observable<Page<Theme>>{
        return this._themePage;
    }
    public explore(spec: ActivitySpecification, pageNumber: number): void {
        this.nextPage(this.http.post(this.appSettings.APIURL + "/explore/?pageNumber=" + pageNumber, spec));
    }
    public exploreProject(spec: ActivitySpecification, pageNumber: number): void {
        this.nextProjectPage(this.http.post(this.appSettings.APIURL + "/explore/Project/?pageNumber=" + pageNumber, spec));
    }
    public exploreTheme(spec: ActivitySpecification, pageNumber: number): void {
        this.nextThemePage(this.http.post(this.appSettings.APIURL + "/explore/Theme/?pageNumber=" + pageNumber, spec));
    }
    public exploreSavedSearch(searchId: number, pageNumber: number): void {
        this.nextPage(this.http.get(this.appSettings.APIURL + "/explore/" + searchId + "?pageNumber=" + pageNumber));
    }
    public exploreSavedSearchProject(searchId: number, pageNumber: number): void {
        this.nextProjectPage(this.http.get(this.appSettings.APIURL + "/explore/Project/" + searchId + "?pageNumber=" + pageNumber));
    }
    public exploreSavedSearchTheme(searchId: number, pageNumber: number): void {
        this.nextThemePage(this.http.get(this.appSettings.APIURL + "/explore/Theme/" + searchId + "?pageNumber=" + pageNumber));
    }
    private nextPage(o: Observable<any>): void
    {
        o.subscribe(json => {
            let page = Page.fromJson(json, Activity.fromJson);
            this._page.next(page);
        });
    }
    private nextProjectPage(o: Observable<any>): void
    {
        o.subscribe(json => {
            let page = Page.fromJson(json, Project.fromJson);
            this._pageProject.next(page);
        });
    }
    private nextThemePage(o: Observable<any>): void
    {
        o.subscribe(json => {
            let page = Page.fromJson(json, Theme.fromJson);
            this._themePage.next(page);
        });
    }
    public savedSearches(): Observable<SavedSearch[]>{
        this.loadSavedSearches();
        return this._savedSearches.asObservable();
    }

    private loadSavedSearches(){
        this.http
            .get(this.appSettings.APIURL + "/explore/savedsearch")
            .subscribe(json => {
                let searches = (<any[]>json).map(SavedSearch.fromJson);
                this._savedSearches.next(searches);
            });
    }

    public saveSearch(search: SavedSearchDto): Promise<Result<SavedSearch,string>> {
        return this.http
            .post(this.appSettings.APIURL + "/explore/savedsearch", search)
            .pipe(map(json => 
                Result.fromJson(json,
                    json => {
                        this.loadSavedSearches();
                        return SavedSearch.fromJson(json);
                    },
                    o => <string>o)))
            .toPromise();
    }

    public updateSavedSearch(searchId: number, search: SavedSearchDto): Promise<Result<SavedSearch,string>> {
        return this.http
            .put(this.appSettings.APIURL + "/explore/savedsearch/" + searchId, search)
            .pipe(map(json => Result.fromJson(json,
                json => {
                    this.loadSavedSearches();
                    return SavedSearch.fromJson(json);
                },
                o => <string>o)))
            .toPromise();
    }

    public deleteSavedSearch(searchId: number): Promise<Result<null,string>> {
        return this.http
            .delete(this.appSettings.APIURL + "/explore/savedsearch/" + searchId)
            .pipe(map(json => Result.fromJson(json,
                () => {
                    this.loadSavedSearches();
                    return null;
                },
                o => <string>o)))
            .toPromise();
    }
    public downloadExploreReport(spec: ActivitySpecification):Observable<any>{
        let sources = [
           this.http.post(this.appSettings.APIURL + "/explore/?pageNumber=1&downloadReport=true", spec),
           this.http.post(this.appSettings.APIURL + "/explore/Project/?pageNumber=1&downloadReport=true", spec),
           this.http.post(this.appSettings.APIURL + "/explore/Theme/?pageNumber=1&downloadReport=true", spec),
          ];
         return forkJoin(sources).pipe(
            map((response => {
                return response;    
            }))
        );
    }
}