<ng-container *ngFor="let c of commodities">

	<auto-complete-option [value]="c">
		<div (click)="commodityClick.emit(c)">
			{{c.name}}
		</div>
	</auto-complete-option>

	<commodity-tree 
		[commodities]="c.children" 
		(commodityClick)="commodityClick.emit($event)" 
		style="margin-left: 1em;"></commodity-tree>
</ng-container>
