
import {take, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserPreferenceService, Project, Theme, WideModal, ProjectService, Result, Option } from '../domain';
import { ProjectModalComponent } from './project-modal/project-modal.component';
import { Observable } from 'rxjs';

@Injectable()
export class ProjectModalService {

  constructor(
		private readonly md: MatDialog,
		private readonly upSvc: UserPreferenceService,
		private readonly projectSvc: ProjectService
  ) { }

	public openNew(theme: Theme = null): MatDialogRef<ProjectModalComponent> {
		return this.open(null, "Adding a Project", theme);
	}
	
	public editProjectInModal(project: Project): MatDialogRef<ProjectModalComponent> {
		return this.open(project, "Editing a Project", null);
	}

	public editProjectById(id: number): Observable<Result<MatDialogRef<ProjectModalComponent>,string>> {
		return this.projectSvc.projects.pipe(
		map(ps => 
				Option.create(ps.find(p => p.id == id))
				.map(project => this.open(project, "Editing a Project", null))),
		map(modalInstanceOption =>
			Result.create<MatDialogRef<ProjectModalComponent>,string>((success,failure) => 
				modalInstanceOption.match(
					() => failure("Cannot find Project by that ID"),
					success))),
		take(1),);
	}

	private open(
		project: Project | null,
		title: string,
		theme: Theme | null): MatDialogRef<ProjectModalComponent> 
	{
    let modalSpec = this.upSvc.getWideModalPreference()
      ? new WideModal(650, true)
      : new WideModal(500, false);
		return this.md.open(ProjectModalComponent, {
			width: modalSpec.width.toString() + 'px',
			disableClose: true,
			hasBackdrop: true,
			data: {
				project,
				wideLayout: modalSpec.useWideLayout,
				title,
				theme
			}
		});
	}
}