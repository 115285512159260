import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'default-logged-in-user',
	templateUrl: './default-logged-in-user.component.html',
	styleUrls: ['./default-logged-in-user.component.scss']
})
export class DefaultLoggedInUserComponent implements OnInit {

	navLinks = [];

	constructor() { }

	ngOnInit() {
	}

}
