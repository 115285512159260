import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../app-settings';
import {
	ConditionChange,
	ConditionChangeFTEDto,
   	LandGrantInstitution,
	SimplifiedProject,
	FTEOverview,
   	Result,
	ProgramArea,
	FTEService as Svc,
	MultiStateProjectDto,
	FiscalYear
} from '../domain';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Campus } from '../domain/types/campus';

@Injectable()
export class FTEService implements Svc {
	constructor(private http: HttpClient, private appSettings: AppSettings) { }

	config(): Promise<null>{
		return Promise.resolve(null);
	}

	public get currentStateFiscalYear(): Observable<FiscalYear>{
		return this.http.get(this.appSettings.APIURLOf("/fte/current_state_fiscal_year"))
		.pipe(map((o:any) => new FiscalYear(o.start, o.end)));
	}

	private mapToObject(m: Map<number,number>): { [key: number]: number } {
		var o = {};
		Array.from(m.entries()).forEach(pair => o[pair[0]] = pair[1]);
		return o;
	}

	public endMultiStateProject(projectId: number, reason: string): Promise<Result<null,string>>{
		let url = this.appSettings.APIURL + '/fte/multi_state_project/' + projectId + '?reason=' + reason;
		return this.http.delete(url)
		.toPromise()
		.then(json =>
			Result.fromJson<null,string>(
				json,
				_ => null,
				msg => <string> msg));
	}

    public saveMultiStateProject(data: MultiStateProjectDto): Promise<Result<null,string>> {
		return this.http.put(this.appSettings.APIURL + '/fte/multi_state_project', data)
		.toPromise()
		.then(json =>
			Result.fromJson<null,string>(
				json,
				_ => null,
				msg => <string> msg));
	}

    saveConditionChangeFTE(data: ConditionChangeFTEDto[]): Promise<Result<null,string>>{
		return this.http.put(this.appSettings.APIURL + '/fte/condition_change_fte', data)
			.toPromise()
			.then(json => 
				Result.fromJson<null,string>(
					json,
					_ => null,
					msg => <string> msg));
	}

    saveCountyFTE(data: Map<number,number>): Promise<Result<null,string>>{
		return this.http.put(this.appSettings.APIURL + '/fte/county_fte', this.mapToObject(data))
			.toPromise()
			.then(json => 
				Result.fromJson<null,string>(
					json,
					_ => null,
					msg => <string> msg));
	}

    getOverview(): Promise<FTEOverview>{
		return this.http.get(this.appSettings.APIURL + '/fte')
			.toPromise()
			.then(FTEOverview.fromJson);
	}


	getConditionChanges(): Promise<ConditionChange[]>{
		return this.http.get(this.appSettings.APIURL + '/tag/condition_changes')
			.toPromise()
			.then(json => (<any[]>json).map(ConditionChange.fromJson));
	}

	getProgramAreas(): Promise<ProgramArea[]>{
		return this.http.get(this.appSettings.APIURL + '/tag/program_areas')
			.toPromise()
			.then(json => (<any[]>json).map(ProgramArea.fromJson));
	}

	getLandGrantInstitutions(): Promise<LandGrantInstitution[]>{
		return this.http.get(this.appSettings.APIURL + '/fte/land_grant_institutions')
			.toPromise()
			.then(json => (<any[]>json).map(LandGrantInstitution.fromJson));
	}
	getUCCampus(): Promise<Campus[]>{
		return this.http.get(this.appSettings.APIURL + '/fte/uc_campus')
		.toPromise()
		.then(json => (<any[]>json).map(Campus.fromJson));
	}
	getProjects(): Promise<SimplifiedProject[]>{
		return this.http.get(this.appSettings.APIURL + '/fte/projects')
			.toPromise()
			.then(json => (<any[]>json).map(o => <SimplifiedProject> o));
	}
}
