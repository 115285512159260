<div class="canvas-container" #canvasContainer>
    <canvas #canvas class="canvas" width="1000" height="100"></canvas>
    <div class="row">
        <div class="col" *ngFor="let r of data.ranks">
            <div class="card">
                <div class="card-header">
                    {{r.name}}
                </div>
                <div class="list-group list-group-sm">
                    <div *ngFor="let s of r.steps" #step class="list-group-item" [attr.data-rank-step]="r.id + '/' + s.id"
                        style="cursor:pointer;" 
                        (click)="positionClick_(r, s)" 
                        [ngClass]="{
                            'active': s.determination == 'Current Step' || s == selectedStep,
                            'step-regression': s.determination == 'Regression',
                            'step-unreachable': s.determination == 'Unreachable'
                        }">
                            <div class="row">
                                <div class="col-md-2 text-center" [ngSwitch]="s.relationships.length == 0">
                                    <span *ngSwitchCase="true">
                                        {{s.id | romanNumeral}}
                                    </span>
                                    <span *ngSwitchCase="false" title="{{r.name}} {{s.id | romanNumeral}} {{s.relationships[0].type}} {{s.relationships[0].withRankAndStep.rank}} {{s.relationships[0].withRankAndStep.step | romanNumeral}}">
                                        <strong>{{s.id | romanNumeral}}</strong>
                                    </span>
                                </div>
                                <div class="col-md-2 text-muted text-sm" title="This step has a duration of {{s.durationInMonths}} months">
                                    {{s.durationInMonths}}
                                </div>
                                <div class="col-md-8 text-sm text-muted">
                                    {{s.determination}}
                                </div>
                            </div>
                            <div *ngIf="s == selectedStep" class="text-sm text-center">
                                <em>Selected</em>
                            </div>      
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="showAboveScale(r)">
                <div class="col above-scale">
                    <div class="card">
                        <div class="list-group list-group-sm">
                            <div class="list-group-item text-center"
                                style="cursor:pointer;"
                                (click)="nextStepClick_()"
                                [ngClass]="{'active': isAboveScaleSelected()}">
                                <span *ngIf="isCurrentPositionAboveScale() && !isSelectedPositionAboveScale()">
                                    {{currentPosition.stepId | romanNumeral}}
                                </span>
                                <span *ngIf="isSelectedPositionAboveScale()">
                                    {{this.selectedStep.id | romanNumeral}}
                                </span>
                                Above Scale
                                <div *ngIf="isCurrentPositionAboveScale() && !isSelectedPositionAboveScale()" class="text-sm text-center">
                                    <em>Current</em>
                                </div>
                                <div *ngIf="isSelectedPositionAboveScale()" class="text-sm text-center">
                                    <em>Selected</em>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>