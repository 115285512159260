
import {take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Option, ActivityService, ActivitySubType, Activity, UserPreferenceService, ActivityCategory, CircularArray, AuthenticationService } from '../domain';
import { ActivityParent, WideModal } from '../domain/types';
import { ActivityTypeMenuModalComponent } from '../widgets/activity-type-menu-modal/activity-type-menu-modal.component';
import { NewActivityModalComponent } from './new-activity-modal/new-activity-modal.component';
import { ConsoleService } from '../widgets/console.service';
import { ActivityEditorModalComponent } from './activity-editor-modal/activity-editor-modal.component';

@Injectable()
export class ActivityModalService {
	private _activityModalWidth = 650;
	private _useWideLayout: boolean;

  	constructor(
		private readonly console: ConsoleService,
		private readonly matDialog: MatDialog,
		private readonly snackbar: MatSnackBar,
		private readonly activityService: ActivityService,
		private readonly userPreferenceService: UserPreferenceService,
		private readonly authService: AuthenticationService
  	) { 
		this.getWideActivityModalSettings();
	}

	getWideActivityModalSettings(): WideModal {
		if(this.userPreferenceService.getWideModalPreference()){
			this._activityModalWidth = 650;
			this._useWideLayout = true;
		} else {
			this._activityModalWidth = 430;
			this._useWideLayout = false;
		}

		return new WideModal(this._activityModalWidth, this._useWideLayout);
	}

	openActivityMenu(matDialog: MatDialog, parent: Option<ActivityParent>, startingActivityCategoryId: number = 0): Promise<number | null>{
		let activityTypes = this.activityService.getActivityTypes();
		let activityCategories = new CircularArray(
			ActivityCategory.createForAcademicUserOption(
				activityTypes,
				this.authService.tryGetCurrentAcademicUserSync()));
        return new Promise((resolve, reject) => {
			matDialog.open(ActivityTypeMenuModalComponent, {
				disableClose: false,
				width: ActivityTypeMenuModalComponent.Width,
				height: ActivityTypeMenuModalComponent.Height,
				//autoFocus: false,
				data: {
					activityTypes,
					activityCategories, 
					startingActivityCategoryId
				},
				hasBackdrop: true
			}).afterClosed().subscribe((subType: ActivitySubType) => {
				if(!!subType){
					this.startNewActivity(matDialog, subType, parent).afterClosed().subscribe(newActivity => {
						resolve(newActivity);
					});
				} else {
					resolve(null);
				}
			});
		});
	}

	startNewActivity(matDialog: MatDialog, subType: ActivitySubType, parent: Option<ActivityParent>): MatDialogRef<NewActivityModalComponent>{
		this.getWideActivityModalSettings(); // this is here to check for changes between each modal use.
		this.console.log("Activity Parent: ", parent);
		let activity = Activity.blankForSubType(subType.id);
		activity.parent = parent;
		return matDialog.open(NewActivityModalComponent, {
			disableClose: true,
			width: this._activityModalWidth.toString() + "px",
			data: {
				activity: activity,
				wideLayout: this._useWideLayout
			},
			hasBackdrop: true
		});
	}

	openActivityInModalById(matDialog: MatDialog, activityId: number){
		this.console.log('Attempting to open modal for activityId: ' + activityId);
		this.activityService.activities.pipe(take(1)).subscribe(activities => {
			let activity = activities.find(a => a.id == activityId);
			if(!activity){
				this.snackbar.open('That activity could not be found');
				return;
			}
			this.openActivityInModal(matDialog, activity);
		});
		this.activityService.loadActivities();
	}

	openActivityInModal(matDialog: MatDialog, activity: Activity){
		this.getWideActivityModalSettings(); // this is here to check for changes between each modal use.
		this.console.log("Activity to load: ", activity);

		let modal = matDialog.open(ActivityEditorModalComponent, {
			disableClose: true,
			width: this._activityModalWidth.toString() + "px",
			data: {
				activity: activity,
				wideLayout: this._useWideLayout
			},
			hasBackdrop: true
		});

		this.console.log('modal',modal);
		
		let subscription = modal.componentInstance
			.copyActivity
			.asObservable()
			.subscribe(activity => this.startActivityCopy(matDialog, activity));
	}

	startActivityCopy(matDialog: MatDialog, activity: Activity){
		this.getWideActivityModalSettings(); // this is here to check for changes between each modal use.
		matDialog.open(NewActivityModalComponent, {
			disableClose: true,
			width: this._activityModalWidth.toString() + "px",
			data: {
				activity: activity,
				purpose: "copy",
				wideLayout: this._useWideLayout
			},
			hasBackdrop: true
		});
	}
}