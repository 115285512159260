<section-header
	sectionTitle="Academic HR"
	navPath="/ahr"
	containerClass="container"
	[navLinks]="navLinks"></section-header>

<div class="pt-3">
	<router-outlet #routeOutlet></router-outlet>
</div>
<anr-footer></anr-footer>
