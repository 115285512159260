<div [ngSwitch]="!!data.value">
    <div *ngSwitchCase="true">
        <div>
            This academic has served
        </div>
        <table class="table-sm">
            <tr>
                <td>
                    {{monthFormat(data.value.monthsInCurrentTitle)}}
                </td>
                <td>
                    in their current title
                </td>
            </tr>
            <tr>
                <td>
                    {{monthFormat(data.value.monthsInCurrentRank)}}
                </td>
                <td>
                    in their current rank
                </td>
            </tr>
            <tr>
                <td>
                    {{monthFormat(data.value.monthsInCurrentStep)}}
                </td>
                <td>
                    in their current step
                </td>
            </tr>
        </table>
    </div>
    <div *ngSwitchCase="false">
        Note: Time served information is not available for this academic
    </div>
</div>