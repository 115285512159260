<div class="mb-2" [hidden]="!selectedUsers.any()">
	<div *ngFor="let user of selectedUsers" class="user-chip">
		<i class="fa fa-fw fa-times ml-1" style="cursor:pointer;"
		(click)="removeUser(user)"></i>

		<div style="display: flex; align-items: center;"> 

			<img appUserAvatar="{{user.id}}" [userName]="user.firstName + ' ' + user.lastName"
				style="width: 40px;"
				class="img-round img-fluid user-avatar">
			
			<div class="chip-name">{{user.firstName}}<br/>{{user.lastName}}</div>
		</div>
	</div>
</div>

<mat-form-field *ngIf="canAddUser()">
	<input type="text"
		#input
		matInput
		class="form-control"
		[ngClass]="controlClass"
		placeholder="Search the ANR directory"
		[matAutocomplete]="auto"
		[formControl]="searchControl"
		aria-label="Search users" role="search">
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete">
	<ng-container *ngIf="users.length === 0" class="mt-3">
		<ng-container [ngSwitch]="!!lastTerm">
			<ng-container *ngSwitchCase="false">
				<mat-option [disabled]="true">
					Search by first name, last name, or email address
				</mat-option>
			</ng-container>
			<ng-container *ngSwitchCase="true">
				<ng-container [ngSwitch]="refreshing">
					<ng-container *ngSwitchCase="true">
						<mat-option [disabled]="true">
							Loading...
						</mat-option>
					</ng-container>
					<ng-container *ngSwitchCase="false">
						<mat-option [disabled]="true">
							No user was found by that name
						</mat-option>
						<div *ngIf="enableNonANRCollaborator" class="px-3 pb-3">
							<p>
								Would you like to add a non-ANR collaborator?
							</p>
							<button class="btn btn-primary" (click)="newNonAnrCollaborator()">
								+ Add a non-ANR Collaborator
							</button>
						</div>
					</ng-container>
				</ng-container>
			</ng-container>
		</ng-container>
	</ng-container>
	<mat-option *ngFor="let user of users" [value]="user" [class.show-ids]="showUserIds">
		
		<div *ngIf="!showUserIds" class="media">
			<div class="pull-left">

				<img appUserAvatar="{{user.id}}" [userName]="user.firstName + ' ' + user.lastName"
					style="width: 40px;"
					class="img-round img-fluid user-avatar-sm mr-2">

			</div>
			<div class="media-body">
				{{user.firstName}} {{user.lastName}}
			</div>
		</div>
		
		<div *ngIf="showUserIds" class="media drop-down-option">
			<div class="pull-left">

				<img appUserAvatar="{{user.id}}" [userName]="user.firstName + ' ' + user.lastName"
					style="width: 40px;"
					class="img-round img-fluid user-avatar-sm mr-2">
					
			</div>
			<div class="media-body">
				<p class="user-name">{{user.firstName}} {{user.lastName}}</p>
				<p class="user-details">UserId: {{user.id}} | EmployeeId: {{user.emplId}}</p>
			</div>
		</div>

	</mat-option>
</mat-autocomplete>