import { ConditionChange } from '../condition-change';
import { ProgramArea } from '../program-area';
import { FTE } from './fte';

export class ConditionChangeFTE
{
	constructor(
		public readonly programArea: ProgramArea,
		public readonly pairs: Map<ConditionChange,FTE>){ }

	public static fromJson(o: any): ConditionChangeFTE[] {
		let pairByProgramAreaId = new Map<number, [ConditionChange,FTE][]>();
		let programAreaById = new Map<number, ProgramArea>();
		(<any[]>o).forEach(o => {
			let pa = ProgramArea.fromJson(o.programArea);
			programAreaById.set(pa.id, pa);
			if(!pairByProgramAreaId.has(pa.id)){
				pairByProgramAreaId.set(pa.id, []);
			}
			pairByProgramAreaId.get(pa.id).push([
				ConditionChange.fromJson(o.conditionChange),
				FTE.fromJson(o.fte)
			]);
		});
		return Array.from(pairByProgramAreaId.entries())
			.map(([paId, pairs]) => 
				new ConditionChangeFTE(
					programAreaById.get(paId),
					new Map<ConditionChange,FTE>(pairs)));
	}
}

export class ConditionChangeFTEDto {
	constructor(
		public programAreaId: number,
		public conditionChangeId: number,
		public fte: number){ }
}
