import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConsoleService } from '../../../widgets/console.service'
import { AHRService, Case } from '../../../domain';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	templateUrl: './academic-case-list.component.html',
	styleUrls: ['./academic-case-list.component.scss']
})
export class AcademicCaseListComponent implements OnInit {
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private svc: AHRService,
		private console: ConsoleService
	){
	}
	selectedCase: Case = null;
	cases: Case[] = null;
	//TODO: make a server call
	resultsVisible = false;

	ngOnInit() {
		combineLatest([
			this.svc.getCasesForCurrentUser(),
			this.route.params.pipe(map((params: Params) => +params['yearId']))
		])
		.subscribe(([cases,yearId]) => {
			this.selectedCase = cases.filter(c => c.yearId == yearId)[0];
			this.cases = cases;
			this.console.log('Cases:', cases);
			if(!this.selectedCase && this.cases.length >= 1){
				this.router.navigate(['program-review',this.cases[0].yearId]);
			}
			this.resultsVisible = this.selectedCase !== null && this.selectedCase !== undefined;
		});
	}
}