import { COUNTIES } from './COUNTIES';
import { LAND_GRANT_INSTITUTIONS } from './LAND_GRANT_INSTITUTIONS';
import { CONDITION_CHANGES } from './CONDITION_CHANGES';
import { PROJECTS } from './PROJECTS';
import { PROGRAM_AREAS } from './PROGRAM_AREAS';

import {
	ConditionChangeFTE,
	ConditionChange,
	CountyAssignment,
	FTE
} from '../../domain';

const PRECISION: number = 2;

export const CONDITION_CHANGE_FTE: ConditionChangeFTE[] = ([
	[ 0, [[0, 0.1], [1, 0.1]]],
	[ 1, [[1, 0.3]]],
	[ 2, [[2, 0.5]]]
] as [number, [number,number][]][]).map(([pa, pairs]) =>
	new ConditionChangeFTE(
		PROGRAM_AREAS[pa],
		new Map<ConditionChange,FTE>(
			pairs.map(([cc, fte]) => 
				[
					CONDITION_CHANGES[cc],
					new FTE(fte, PRECISION)
				] as [ConditionChange, FTE]))));

export const COUNTY_ASSIGNMENTS: CountyAssignment[] = [
	[0, true,  true, 0.8],
	[1, true, false, 0.2]
].map(([countyIndex, director, hq, fte]) => 
	new CountyAssignment(
		COUNTIES[<number>countyIndex],
		<boolean>director,
		<boolean>hq,
		new FTE(<number>fte, PRECISION)));