import { Injectable } from '@angular/core';
import { UserPreferenceService as UPS, UserPreferences } from '../domain';

@Injectable()
export class UserPreferenceService implements UPS {
	constructor() { }

	public config(): Promise<null>{
		return Promise.resolve(null);
	}

	public getOpenActivityInModalPreference(): boolean {
		return this.getKeyValue(UserPreferences.keys.activityUseModal) == null 
			? true 
			: this.getKeyValue(UserPreferences.keys.activityUseModal) == 'true';
	}

	public getCloseAfterActivitySavePreference(): boolean {
		return this.getKeyValue(UserPreferences.keys.closeAfterActivitySave) == null 
			? true 
			: this.getKeyValue(UserPreferences.keys.closeAfterActivitySave) == 'true';
	}

	public getWideModalPreference(): boolean {
		return this.getKeyValue(UserPreferences.keys.wideModal) == null 
			? true 
			: this.getKeyValue(UserPreferences.keys.wideModal) == 'true';
	}

	public getDossierExpandedStatePreference(): boolean {
		return this.getKeyValue(UserPreferences.keys.dossierExpandedState) == null 
			? true 
			: this.getKeyValue(UserPreferences.keys.dossierExpandedState) == 'true';
	}

	public getKeyValue(key: string): string {
		return window.localStorage.getItem(key);
	}

	public setKeyValue(key: string, value: string){
		window.localStorage.setItem(key, value.toString());
	}

}
