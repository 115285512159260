<div class="alert alert-info" *ngIf="hasInstructions()">
	<span [innerHTML]="getInstructions()"></span>
</div>

<h5 class="mb-3">Preferences</h5>

<h6>Activities Preferences</h6>
<div class="form-check">
    <label class="form-check-label">
        <input type="radio" [formControl]="openActivityInModalToggle" [value]="false">
        Edit Activities in Side Panel
    </label>
    <div class="form-check offset-indent" [hidden]="!showCloseSidePanelAfterSave()">
        <label class="form-check-label">
            <input type="checkbox"
                [formControl]="closeAfterActivitySaveToggle"
                [checked]="closeAfterActivitySave"> Close Side Panel After Save
        </label>
    </div>    
</div>
<div class="form-check">
    <label class="form-check-label">
        <input type="radio" [formControl]="openActivityInModalToggle" [value]="true">
        Edit Activities in Pop-up
    </label>
</div>
<div class="form-check">
    <label class="form-check-label">
        <input type="checkbox" [formControl]="wideModalToggle" 
        [checked]="wideModal">
        Use Wide Modals (when available)
    </label>
</div>


