import { ActivityParentIdDto } from "./activity-parent";

export class ActivityParentDto {
constructor(
    public activityParentId: ActivityParentIdDto | null,
    public activityIds: number[]
    )
    {
        
    }
}