<div mat-dialog-title>
	New Academic
</div>

<mat-dialog-content style="min-height: 300px;">
	<form [formGroup]="form">
		<div class="form-group">
			<label>User</label>
			<user-picker 
				[formControl]="usersControl" 
				enableNonANRCollaborator="false" 
				selectSingle="true"
				[showUserIds]="true"></user-picker>
		</div>

		<div class="form-group">
			<label>Start Date</label>
			<input type="date" formControlName="startDate" class="form-control">
		</div>

		<div class="form-group">
			<label>Starting Position</label>
			<select formControlName="positionId" class="form-control">
				<option *ngFor="let p of positions" [ngValue]="p.id">
					{{p.label}}
				</option>
			</select>
		</div>

		<div class="form-group">
			<label>Starting Term</label>
			<select formControlName="term" class="form-control">
				<option *ngFor="let t of terms" [ngValue]="t.value">
					{{t.label}}
				</option>
			</select>
		</div>
	</form>
</mat-dialog-content>

<mat-dialog-actions>
	<button (click)="cancel()" class="btn btn-link">
		Cancel
	</button>
	<button (click)="save()" class="btn btn-primary" [disabled]="!form.valid">
		Save
	</button>
</mat-dialog-actions>