import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AHRService, TermId, Option, SortedSet, User} from '../../../domain';
import { ConsoleService } from '../../../widgets/console.service';

@Component({
	selector: 'new-academic-modal',
	templateUrl: './new-academic-modal.component.html',
	styleUrls: ['./new-academic-modal.component.scss']
})
export class NewAcademicModalComponent {
	form: FormGroup;
	positions: any[];
	terms: any[];
	usersControl: FormControl;

	constructor(
		public dialogRef: MatDialogRef<NewAcademicModalComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private svc: AHRService,
		private console: ConsoleService,
		fb: FormBuilder,
		private sb: MatSnackBar
	) { 
		this.terms = [
			{ label: "None", value: Option.create() },
			{ label: "Term 1", value: Option.create(TermId.Term1) },
			{ label: "Term 2", value: Option.create(TermId.Term2) },
			{ label: "Term 3", value: Option.create(TermId.Term3) },
			{ label: "Indefinite Status", value: Option.create(TermId.IndefiniteStatus) }
		];

		this.positions = (() => {
			let ps = [];
			svc.getAllTitlesRanksAndSteps()
			.forEach(t => {
				if (t.ranks && t.ranks.length > 0) {
					t.ranks.forEach(r => {
						r.steps.forEach(s => {
							ps.push({
								label: `${t.name} / ${r.name} / ${s.name}`,
								id: { titleId: t.id, rankId: r.id, stepId: s.id }
							});
						})							
					});
				} else {
					// Handle titles with no ranks such as Staff Director
					ps.push({
						label: t.name,
						id: { titleId: t.id, rankId: 0, stepId: 0 }
					});
				}
			});
			return ps;
		})();

		this.form = fb.group({
			userId: [null, Validators.required],
			positionId: this.positions[0].id,
			term: this.terms[0].value,
			startDate: [null, Validators.required]
		});

		this.usersControl = new FormControl(new SortedSet<User>([]));
		this.usersControl.valueChanges.subscribe((v: SortedSet<User>) => {
			let user = v.toArray()[0];
			this.console.log(v,user);
			this.form.controls['userId'].setValue(!!user ? user.id : null);
		});

		this.form.valueChanges.subscribe(v => {
			this.console.log(v);
		});
	}

	cancel() {
		this.dialogRef.close();
	}

	save() {
		this.svc.createNewAcademic(
			this.form.value.userId,
			this.form.value.positionId,
			this.form.value.term,
			this.form.value.startDate
		).then(result => {
			result.matchDo(
				() => {
					this.sb.open('Academic saved');
					this.dialogRef.close(this.form.value.userId);
				},
				errorMsg => {
					this.sb.open('Academic not saved: ' + errorMsg);
				}
			)
		});
	}

	public static open(matDialog: MatDialog): MatDialogRef<NewAcademicModalComponent> {
		return matDialog.open(
			NewAcademicModalComponent,
			{
				width: '500px'
			});
	}
}