import { Component, OnInit } from '@angular/core';
import { AHRService } from '../../../domain';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SelectANRPersonModalComponent } from '../../select-anrperson-modal/select-anrperson-modal.component';
import { from } from 'rxjs';
import { flatMap, filter } from 'rxjs/operators';

@Component({
	selector: 'committees',
	templateUrl: './committees.component.html',
	styleUrls: ['./committees.component.scss']
})
export class CommitteesComponent implements OnInit {
	vm: any;
	constructor(
		private readonly svc: AHRService,
		private readonly dialog: MatDialog,
		private readonly snackbar: MatSnackBar
	) {
		svc.committees.subscribe(vm => this.vm = vm);
	}

	addMember(committee){
		SelectANRPersonModalComponent.open(this.dialog, null)
		.pipe(
			filter(userId => !!userId),
			flatMap(userId => from(this.svc.addCommitteeMember(this.vm.id, committee.id, userId)))
		)
		.subscribe(result => {
			result.matchDo(
				_ => {
					//committee.members.push({name: userId, id: userId});
					this.snackbar.open('Committee member added');
				},
				errorMsg => {
					this.snackbar.open('Unable to add committee member: ' + errorMsg);
				}
			)
		});
	}

	removeMember(committee, member){
		this.svc.removeCommitteeMember(this.vm.id, committee.id, member.id).then(result => {
			result.matchDo(
				_ => {
					let ix = committee.members.indexOf(member);
					if(ix > -1){
						committee.members.splice(ix,1);
					}
					this.snackbar.open('Committee member removed');
				},
				errorMsg => {
					this.snackbar.open('Unable to removed committee member: ' + errorMsg);
				}
			)
		});
	}

	ngOnInit() {
	}
}