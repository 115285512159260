<div [hidden]="state != 'viewing'" 
	(click)="edit()"
	class="viewing" 
	title="Edit this"
	tabindex="0">
	<div class="media">
		<div class="media-body">
			{{originalText}}
			<em [hidden]="!!originalText">Click or Tap to edit</em>
		</div>
		<div class="media-object">
			<i class="fa fa-fw fa-pencil"></i>
		</div>
	</div>
</div>

<div [hidden]="state != 'editing'" class="editing">
	<ng-container [ngSwitch]="useTextArea">
		<textarea *ngSwitchCase="true"  [(ngModel)]="editText" class="form-control" #textInput autoGrow></textarea>
		<input    *ngSwitchCase="false" [(ngModel)]="editText" class="form-control" #textInput type="text">
	</ng-container>

	<div class="toolbar">
		<div [hidden]="!!saving">
			<button class="btn btn-sm btn-primary" 
				(click)="save()" 
				[disabled]="!canSave() || saving" 
				title="Save (Enter on the keyboard)">
				Save
			</button>
			<button class="btn btn-sm btn-link" 	
				(click)="cancel()" 
				[disabled]="saving" 
				title="Cancel (Escape on the keyboard)">
				Cancel
			</button>
		</div>
		<div class="mat-spinner-container" [hidden]="!saving">
			<mat-spinner [hidden]="!saving"></mat-spinner>
		</div>
	</div>
</div>
