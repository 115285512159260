<div class="card highlighted" *ngIf="!!data">
	<div class="card-header">
		<button class="btn btn-sm btn-link float-right"
			(click)="exit()"
			style="cursor: pointer;">
				<i class="fa fa-fw fa-times"></i>
				<span class="sr-only">Exit</span>
		</button>
		<div>Editing Activity</div>
	</div>
	<div class="card-block">
		<activity-form-guts
			[activity]="data"
			caller="activity-editor"
			[wideLayout]="false"
			[types]="activityTypes"
			(activityChange)="onActivityChange($event)"
			(formStatusChange)="onStatusChange($event)"></activity-form-guts>
	</div>
	<div class="card-footer text-right">
		<button (click)="archive()" 
			class="btn btn-link btn-sm text-danger float-left" 
			[title]="StaticText.ArchiveActivity"
			*ngIf="canArchive()">
			<i class="fa fa-fw fa-archive"></i>
			Archive (Delete)
		</button>
		<button class="btn btn-link btn-sm" (click)="exit()">
			<span [ngSwitch]="hasChanges()">
				<span *ngSwitchCase="true">
					Cancel
				</span>
				<span *ngSwitchCase="false">
					Close
				</span>
			</span>
		</button>
		<button class="btn btn-success btn-sm" [disabled]="!canCopy()" (click)="copy()">
			Copy
		</button>
		<button class="btn btn-primary btn-sm" [disabled]="!canSave()" (click)="save()">
			Save Activity
		</button>
	</div>
</div>
