import {
   	Component,
   	OnInit,
   	Input,
   	Output,
   	EventEmitter ,
	OnChanges,
	SimpleChanges
} from '@angular/core';

import * as cs from '../column-sort';

import {
   	Activity,
	ActivityParentType,
   	ActivityTypeCollection,
	ActivityService,
	ActivityCategory,
	ActivityCategoryId,
	ActivityValuePresence,
	ActivitySubType,
	ActivityParent,
	ProgramArea
} from '../../domain';
import { ActivityParentDto } from '../../domain/types/activity-parent-update';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TooltipService } from '../tooltip.service';
import { ActivityBulkUpdateComponent } from '../activity-bulk-update/activity-bulk-update.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'activity-list',
	templateUrl: './activity-list.component.html',
	styleUrls: ['./activity-list.component.scss']
})
export class ActivityListComponent implements OnChanges {
	ActivityParentType = ActivityParentType;
	@Input() fadeToGray: boolean = false;
	@Input() activities: Activity[] = [];
	@Input() hideTags: boolean = false;
	@Input() hideParent: boolean = false;
	@Input() keywordToHighlight = '';
	@Input() highlightedActivity: Activity | null = null;
	@Input() maxHeight = '';
	@Input() paddingRight = '';
	@Input() paddingLeft = '';
	@Input() activityCategory: ActivityCategory;
	@Output() activityClick = new EventEmitter<Activity>();
	private _currentSorting: cs.Sorting<Activity,any>;
	private _activityTypes: ActivityTypeCollection;
	types: ActivityTypeCollection;
	SelectedActivityCount: number=0;
	columnClass(id){
		let ht = this.hideTags;
		let hp = this.hideParent;
		switch(id){
			case 'name':   	return ht && hp ? 'col-md-6' : 'col-md-4';
			case 'type':   	return ht && hp ? 'col-md-3' : 'col-md-2';
			case 'subType': return ht && hp ? 'col-md-3' : 'col-md-2';
			case 'role': 	return ht && hp ? 'col-md-3' : 'col-md-2';
			case 'areaOfService': 	return ht && hp ? 'col-md-3' : 'col-md-2';
			case 'date':  	return ht       ? 'col-md-3' : 'col-md-2';
			case 'parent': 	return ht       ? 'col-md-3' : 'col-md-2';
			case 'tags':   	return hp       ? 'col-md-4' : 'col-md-2';
		}
		return '';
	}

	sortColumns = {
		name:   cs.Column.withGetter<Activity,string>(
			'name',   
			a => (a.name || 'zzz').toLowerCase(),
			'Name',          
			cs.Direction.asc),
		date:   cs.Column.withGetter<Activity,Date>(
			'date',
			a => a.date.match(
				single => single.date,
				range => range.endDate,
				ongoing => ongoing.startDate
			),
			'Date',
			cs.Direction.asc),
		parent: cs.Column.withGetter<Activity,string>(
			'parent',
			a => a.parent.match(
				/* Activities with no parent should appear last */
				() => 'ZZZZZZ', 
				p => p.name).toLowerCase(),
			'Theme/Project',
			cs.Direction.asc),
		tags: cs.Column.withGetter<Activity,number>(
			'tags',
			a => a.tags.count,
			'Tags',
		   	cs.Direction.desc),
		typeId: cs.Column.withGetter<Activity,string>(
			'typeId',
			a => this.getActivityTypeName(a).toLowerCase(),
			'Type',
			cs.Direction.asc),
		subTypeId: cs.Column.withGetter<Activity,string>(
			'typeId',
			a => this.getActivityTypeName(a).toLowerCase(),
			'Sub Type',
			cs.Direction.asc),
		role: cs.Column.withGetter<Activity,string>(
			'role',
			a => (a.role || 'zzz').toLowerCase(),
			'Role',
			cs.Direction.asc),
		areaOfService: cs.Column.withGetter<Activity,string>(
			'areaOfService',
			a => (a.areaOfService || 'zzz').toLowerCase(),
			'Org. Level',
			cs.Direction.asc),
		location: cs.Column.withGetter<Activity,string>(
			'location',
			a => (a.location || 'zzz').toLowerCase(),
			'Location',
			cs.Direction.asc),
	};

	constructor(public svc: ActivityService, 
		public tooltipSvc: TooltipService,
		private matDialog: MatDialog){
		this.currentSorting = this.sortColumns.date.getDefaultSorting();
		this.types = this._activityTypes = svc.getActivityTypes();
	}

	get currentSorting(){
		return this._currentSorting;
	}
	set currentSorting(sorting){
		this._currentSorting = sorting;
		this.activities = cs.Sort(this.activities, sorting);
	}

    getActivityTypeName(a: Activity) {
        return this._activityTypes.typeName(a);
	}
	
	getActivitySubTypeName(a: Activity) {
        return this._activityTypes.getSubTypeById(a.subTypeId).name;
	}

	getActivityRoleName(a: Activity) {
        return a.role;
	}

	getActivityAreaOfServiceName(a: Activity) {
        return a.areaOfService;
	}

	getLocationName(a: Activity) {
		return a.location;
	}

	ngOnChanges(changes: SimpleChanges) {
		if('activities' in changes){
			this.activities = cs.Sort(
				<Activity[]> changes.activities.currentValue,
				this.currentSorting);
		}
	}
    selectedActivity(index: number){
		this.activities[index].checked = this.activities[index].checked? false: true;
		this.SelectedActivityCount = this.activities.filter(x => x.checked).length;
	}
	openBulkUpdateModal(){
		ActivityBulkUpdateComponent.open(this.matDialog,this.activities,this.SelectedActivityCount)
		                        .afterClosed().subscribe(()=>{
									this.activities.forEach(x=>x.checked = false);
									this.SelectedActivityCount =0;
								});
	}
	hasInstructions() {
		if (this.getInstructions().length > 0) {
			return true;
		} 
		return false;
	}
	resetBulkUpdate(){
		this.activities.forEach(x=>x.checked = false);
        this.SelectedActivityCount =0;
	}
	getInstructions(): string {
		return this.tooltipSvc.tooltip("ActivityBulckUpdateInstructions");
	}
}
