import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AHRService, ActionType, AuthenticatedIdentity, AuthenticationService, CaseFiles, CaseId, CurrentUser, Option, ReviewFile, ReviewerCase } from 'app/domain';
import { ConsoleService } from 'app/widgets/console.service';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import { combineLatest, partition } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';
import * as s from '../../../widgets/search';
import { ReviewerService } from '../reviewer.service';

@Component({
	template: `
			<empty-state
				imgSrc="/assets/empty/theme.png"
				title="No case selected"
				description="Select a case to get started">
			</empty-state>
`
})
export class ReviewerCaseNotSelected {
  constructor(private reviewerService: ReviewerService) {
    reviewerService.updateAcademicId(null);
  }
}

@Component({
  selector: 'reviewer-case-list',
  templateUrl: './reviewer-case-list.component.html',
  styleUrls: ['./reviewer-case-list.component.scss']
})
export class ReviewerCaseListComponent implements OnInit {
  cases: ReviewerCase[] = [];
  filteredCases: ReviewerCase[] = [];
  selectableCases : ReviewerCase[] = [];
  selectedCaseId: CaseId;
  currentUser: Option<CurrentUser> = Option.create<CurrentUser>();
  searchCase = new FormControl();
  readonly selectAll : string = "Select All";
  readonly unselectAll : string = "Unselect All";
  readonly advText : string = " Advancements";
  readonly aeText : string = " Annual Evals"
	lastTerm = '';
  readonly ADVANCEMENT_CASES : ActionType[] = [ActionType.Merit, ActionType.Promotion];
  readonly AE_CASES : ActionType[] = [ActionType.AnnualEvaluation];
  readonly selectableActionTypes : ActionType[] = [...this.ADVANCEMENT_CASES, ...this.AE_CASES];
  loading = true;
  readonly prcFilterOptions = [
    {label: 'All cases'},
    {label: 'Primary PRC Reviewer'},
    {label: 'Secondary PRC Reviewer'},
    {label: 'Read-Only PRC Reviewer'},
  ];

  selectedFileCount : number = 0;
  selectAdvancementCasesText : string = this.selectAll + this.advText;
  selectAnnualEvaluationCasesText : string = this.selectAll + this.aeText;
  displayCheckboxes : boolean = false;
  reviewerType: string = ""; 
  selected: ReviewerCase | null = null;
  onPageLoad: boolean = true;
  search = 
  s.ResultsFunction.fromPredicate<ReviewerCase>(
      s.Predicate.objectStringProjectors<ReviewerCase>([
        o => o.candidateName
      ]));
  activeFilter: number = 0;

  get selectedCaseIdForMenu(){
    return this.selectedCaseId;
  }
  set selectedCaseIdForMenu(id: CaseId){
    console.log('selected case id:', id);
    let c = this.cases.filter(c => c.id.equals(id))[0];
    if(!!c){
      this.router.navigate(['review', c.id.academicId]);
    }
  }

  constructor(
		private router: Router,
		private route: ActivatedRoute,
    private svc: AHRService,
		private console: ConsoleService,
    private authsvc: AuthenticationService,
    private sb: MatSnackBar,
    private reviewerService: ReviewerService
  ) { 

    authsvc.currentIdentity.subscribe(identity => {
			identity.match(
				() => {
					this.currentUser = Option.create<CurrentUser>();
				},
				(i: AuthenticatedIdentity) => {
					this.currentUser = Option.create<CurrentUser>(i.user);
				});
		});
    let [selections, termChanges] =
			partition( 
				this.searchCase
					.valueChanges
					.pipe(startWith(null)),
				o => o instanceof ReviewerCase);

		selections
			.subscribe(o => {
				let t = <ReviewerCase>o;
				this.selected = t;
				this._onChange(t);
			});

		termChanges.pipe(
			debounceTime(200))
			.subscribe(term => {
				this.lastTerm = term;
				this.filteredCases = this.search(this.filteredCases, term);
        if(this.lastTerm === '' || this.filteredCases.length === 0){
          this.onValChange(this.reviewerType);
        }
			});
  }

  ngOnInit() {
		combineLatest([
      this.svc.getCasesToBeReviewedCurrentUser(),
      this.route.data,
		])
		.subscribe(([cases,data]) => {
      this.cases = cases;
      this.filteredCases = this.filteredCases.length>0?this.filteredCases:cases;
      this.selectableCases = this.cases.filter(c => this.isSelectableCase(c.proposedAction.type));
      this.console.log('data',data);
      this.loading =false;

      this.reviewerService.reviewer$.subscribe((type) => {        
        if (type && type !== this.reviewerType)
          this.selectedCaseId = null;

        this.reviewerType = type;
      });
      
      if (this.currentUser.value.roles.academicHumanResources && this.onPageLoad) {
        this.onPageLoad = false;
        this.onValChange(this.reviewerType);

        if (this.reviewerType == '')
          this.reviewerService.updateReviewer('AHR');
        else 
          this.reviewerService.updateReviewer(this.reviewerType);
      }

      this.reviewerService.currentAcademicId$.subscribe(academicId => {
        if (!!academicId) {
          let c = cases.filter(c => c.id.academicId === academicId)[0];
          if (!!c) this.selectedCaseId = c.id;
        } else {
          this.selectedCaseId = null;
        }
      });

      if(!!data.autoSelectFirstCase && !this.selectedCaseId && cases.length >= 1){
        let c = cases[0];
        let id = c.id;
        this.console.log('selecting case',c);
        this.router.navigate(['review', id.academicId]);  
			}

    });

  }

  isSelectableCase(type:ActionType) {
    return this.selectableActionTypes.includes(type);
  }

  downloadSelectedCases(){

    let p = this.selectedFileCount > 1 ? 's':'';
    this.sb.open('Downloading files from ' + this.selectedFileCount + ' case' + p, null, {duration: 6000});

    let caseFileMap = new Map<ReviewerCase, CaseFiles>();

    var getCaseFiles = (_cases:ReviewerCase[]) => {
      
      const promiseArray = _cases.map(c => {

        return new Promise<Map<ReviewerCase,CaseFiles>>(resolve => {

          this.svc.getCaseFiles(c.id).subscribe(files => {
          
            let filesToDownload = files as CaseFiles;
        
            // only show review files that a reviewer is allowed to see. See: AHRDashboardReadModel.canReadReviewFilesFromReviewStepIds for logic
            filesToDownload.reviewerFiles = filesToDownload.reviewerFiles.filter(file => 
              file.committeeName == "" ||
              filesToDownload.canReadReviewFilesFromCommitteeNames.includes(file.committeeName)
            );            
            
            caseFileMap.set(c, filesToDownload);            
            resolve(caseFileMap);
          });
        });
      });
      
      return Promise.all(promiseArray);
    }

    const getCaseFilesPromise = getCaseFiles(this.selectableCases.filter(c => c.isChecked));

    Promise.all([getCaseFilesPromise]).then(results => {
      this.generateZip(caseFileMap);        
    });

  }

  generateZip(_caseFileMap:Map<ReviewerCase,CaseFiles>) {

    var zip = new JSZip();

    var loadFiles = (_case:ReviewerCase, files:ReviewFile[], foldername:string) => {         

      let filenames=[];

      zip.folder(_case.candidateName);

      const promiseArray = files.map((file, index) => {  
          
        return new Promise(resolve => {
          const fileName = _case.id.periodOfReviewEndingInYear > 2021 ?file.id: file.name;
            this.svc.caseFileObject(_case.id, fileName).subscribe(response => {
              var filename = response.headers.get('Content-Disposition').split("=")[1];
              filename = _case.id.periodOfReviewEndingInYear > 2021 ? filename: file.name;
              filename = filename.replace(/(^"|"$)/g, ''); //remove leading and trailing double quotes
              filenames.push(filename);
              
              //Rename duplicate files
              let duplicateCount = filenames.filter(val => val == filename).length;                
              if (duplicateCount > 1) {
                let fileExt = filename.substring(filename.lastIndexOf("."));
                filename = filename.substring(0, filename.lastIndexOf("."));
                filename = filename+"("+duplicateCount+")"+fileExt;
                filenames.push(filename);
              }
              //this.console.log(filename);
              const blob = new Blob([response.body], {type: response.body.type}); //create the file
              zip.folder(_case.candidateName).folder(foldername).file(filename, blob, {binary:true}); //add the file to folder
              resolve(response);
            });            
          });
      });

      return Promise.all(promiseArray);
    }

    const iterateCasesPromise = Array.from(_caseFileMap).map(([_case, files]) => {
      const candidatePromise = loadFiles(_case, files.candidateFiles, "Candidate Files"); 
      const reviewerPromise = loadFiles(_case, files.reviewerFiles, "Reviewer Files");     
      return Promise.all([_case, candidatePromise, reviewerPromise]);      
    });

    //Set zip filename
    const datepipe: DatePipe = new DatePipe('en-US');
    let formattedDate = datepipe.transform(new Date().toJSON(), 'YYYY.MM.dd_HH.mm.ss');
    let year = _caseFileMap.keys().next().value.id.periodOfReviewEndingInYear;
    let zipFilename = year + '-' + (year+1) + " (" + formattedDate + ")";

    Promise.all(iterateCasesPromise).then(data => {
        zip.generateAsync({type:"blob", compression:"DEFLATE"})
        .then(content => saveAs(content, zipFilename + '.zip'))
        .catch(err => console.log(err));
    }).catch((err) => {
      console.error(err);
    });

  }

  selectAllCases(actionTypes : ActionType[]){
    
    if (this.selectableCases.filter(c => actionTypes.includes(c.proposedAction.type)).every(c => c.isChecked == true))
      this.selectableCases.filter(c => actionTypes.includes(c.proposedAction.type)).forEach(c => { c.isChecked = false });
    else 
      this.selectableCases.filter(c => actionTypes.includes(c.proposedAction.type)).forEach(c => { c.isChecked = true });

    this.countChecked();
  }

  checkedChanged(){
    this.countChecked();
  }

  countChecked(){
    this.selectedFileCount = 0;
    
    this.selectableCases.forEach(c => {if (c.isChecked) this.selectedFileCount = ++this.selectedFileCount;});

    if (this.selectableCases.filter(c => this.ADVANCEMENT_CASES.includes(c.proposedAction.type)).every(c => c.isChecked == true))
      this.selectAdvancementCasesText = this.unselectAll + this.advText;
    else 
      this.selectAdvancementCasesText = this.selectAll + this.advText;

    if (this.selectableCases.filter(c => this.AE_CASES.includes(c.proposedAction.type)).every(c => c.isChecked == true))
      this.selectAnnualEvaluationCasesText = this.unselectAll + this.aeText;
    else 
      this.selectAnnualEvaluationCasesText = this.selectAll + this.aeText;      
  }
  
  onValChange(reviewer: string){    
    if(reviewer === 'Supervisor')
      this.filteredCases = this.cases.filter(x=> x.isReviewer);
    else
      this.filteredCases = this.cases;
  }
  clear() {
    this.selected = null;
    this._onChange(null);
    this.searchCase.setValue('');
    this.onValChange(this.reviewerType);
}
reset(reviewer: string){
  this.selected = null;
  this._onChange(null);
  this.searchCase.setValue('');
  if (this.reviewerType !== reviewer) {
    this.reviewerService.updateReviewer(reviewer);
    this.router.navigate(['/review']);
  }
}
private _onChange: any = () => {  };
displayWith(o): string {
  if (typeof o == 'string'){
      return o;
  } else if (o == null) { // on component initialization o is null
      return '';
  }
  return o.candidateName;
 }
 filterCase(reviewerCase: ReviewerCase){ 
   this.filteredCases =  this.filteredCases.filter(x=> x.candidateName === reviewerCase.candidateName);
 }

  public containsPRCReviewerStep(){
    return this.cases.some(c => c.yourSteps.some(s => s.title.toLowerCase().includes("prc reviewer")));
  }

  filterCasesBy(index: number): void {

    this.activeFilter = index;

    if (index === 0) { //All cases
      this.filteredCases = this.cases;
      return;
    } 

    let filteredTitle = this.prcFilterOptions[index].label;
    //this.console.log(`Item clicked: ${filteredTitle}`);
    this.filteredCases = this.cases.filter(c => c.yourSteps.some(s => s.title.toLowerCase() === filteredTitle.toLowerCase()));

  }

}