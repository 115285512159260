export class County
{
	constructor(
		public readonly id: number,
		public readonly name: string){ }

	public static fromJson(o: any): County {
		return new County(o.id, o.name);
	}
}

export abstract class ICountySelection {
	public abstract Statewide: boolean;
	public abstract Counties: County[];

	public static CountySelection(counties: County[], statewide: boolean): ICountySelection
	{
		if(!!statewide && statewide) { 
			return new StatewideSelected(statewide);
		}
		return new CountiesSelected(counties);
	}

	public duplicate(): ICountySelection {
		return ICountySelection.CountySelection(this.Counties, this.Statewide);
	}
}

export class CountiesSelected implements ICountySelection {
	public readonly Statewide: boolean = false;
	constructor(
		public readonly Counties: County[] = []
	) {}

	public duplicate(): ICountySelection {
		return new CountiesSelected(this.Counties);
	}
}

export class StatewideSelected implements ICountySelection {
	public readonly Counties: County[] = [];
	
	constructor(
		public readonly Statewide: boolean
	) {}

	public duplicate(): ICountySelection {
		return new StatewideSelected(this.Statewide);
	}
}
