export class Campus
{
	constructor(
		public readonly id: number,
        public readonly abbreviation: string,
		public readonly name: string){ }

	public static fromJson(o: any): Campus {
		return new Campus(o.id,o.abbreviation, o.name);
	}
}