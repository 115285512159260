import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'crc',
	templateUrl: './crc.component.html',
	styleUrls: ['./crc.component.scss']
})
export class CRCComponent implements OnInit {

	navLinks = [];

	constructor() { }

	ngOnInit() {
	}

}
