// source: http://resolvethis.com/how-to-create-an-auto-grow-directive-in-angular-2/ 
// and source: https://github.com/stevepapa/ng-autosize/blob/master/src/autosize.directive.ts
import { Directive, HostBinding, HostListener, ElementRef, AfterContentChecked } from '@angular/core';

@Directive({
	selector: 'textarea[autoGrow]',
	exportAs: 'autoGrow'
})
export class AutoGrowDirective implements AfterContentChecked {
	private el: HTMLElement;
	constructor(private element: ElementRef) { 
		this.el = element.nativeElement;
		this.el.style.overflow = 'hidden';
		this.el.style.resize = 'none';
	}

	@HostBinding('attr.rows') private rows: number = 1;
	@HostBinding('attr.maxLines') private maxLines: number = 999;
	@HostListener('input')
	private onInput(){
		this.adjust();
	}
	ngAfterContentChecked(){
		this.adjust();
	}
	adjust(){
		if (this.el.style.height == this.element.nativeElement.scrollHeight + 'px') {
			return;
		}
		this.el.style.height = this.el.scrollHeight + 'px';
	}
}
