import { Component, Input, OnChanges } from '@angular/core';
import { ActivityTypeId } from '../../domain';

@Component({
	selector: 'activity-type-icon',
	templateUrl: './activity-icon.component.html',
	styleUrls: ['./activity-icon.component.scss']
})
export class ActivityIconComponent implements OnChanges {
	@Input() activityTypeId: ActivityTypeId;	
	icon: string = '';

	iconsDictionary = new Map<ActivityTypeId, string>([
		[ActivityTypeId.Extension,                  "fa fa-handshake-o"],
		[ActivityTypeId.Outreach,                   "fa fa-users"],
		[ActivityTypeId.ExtensionAndOrOutreach,     "fa fa-microphone"],
		[ActivityTypeId.AppliedResearchAndCreative, "fa fa-flask"],
		[ActivityTypeId.ProfessionalCompetence,     "fa fa-graduation-cap"],
		[ActivityTypeId.UniversityAndPublicService, "fa fa-university"],
		[ActivityTypeId.Other,                      "fa fa-file-text"]
	].map(pair => <[ActivityTypeId,string]>pair));

	ngOnChanges() {
		this.icon = this.iconsDictionary.get(this.activityTypeId);
	}
}
