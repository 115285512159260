import { Injectable } from '@angular/core';
import {
   	CanActivate,
   	ActivatedRouteSnapshot,
   	RouterStateSnapshot,
	Router,
	ActivatedRoute
} from '@angular/router';
import { AuthenticationService, CurrentUser, AuthenticatedIdentity, AcademicTitleId } from '../domain';
import { ConsoleService } from '../widgets/console.service';
import { AuthenticationModalComponent } from './authentication-modal/authentication-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class AuthGuardService implements CanActivate {
	private authenticated: boolean = false;
	constructor(
		private authSvc: AuthenticationService,
		private console: ConsoleService,
		private matDialog: MatDialog,
		private router: Router,
		snackBar: MatSnackBar,
		private readonly route: ActivatedRoute
	){
		this.console.log('[AuthGuard initialized]');
		this.console.info('[Subscribing to `AuthenticationService.currentIdentity`]');
		authSvc.currentIdentity.subscribe(identity => {
			identity
			.matchDo(
				() => {
					this.authenticated = false;
					this.console.info('[Identity cleared]');
					AuthenticationModalComponent.open(this.matDialog);
				},
				(i: AuthenticatedIdentity) => {
					this.authenticated = true;
					this.console.info('[Detected a new identity] -> ', i);
					setTimeout(() => {
						//calling snackbar in this way avoids an `ExpressionChangedAfterItHasBeenCheckedError`
						snackBar.open(`Hello, ${i.user.firstName}!`, null, {duration: 5000});
					},0);
					this.sendUserOff(i.user);
				});
		});
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return Promise.resolve(this.authenticated)
		.then(success => success ? Promise.resolve(success) : this.tryPassThrough())
		.then(success => success ? Promise.resolve(success) : this.tryFromMemory())
		.then(success => {
			if(!success){
				AuthenticationModalComponent.open(this.matDialog);
			}
			return success;
		});
	}

	private tryPassThrough(): Promise<boolean>{
		return new Promise(resolve => {
			var secureString = window.location.search.replace("?secureString=","").trim();
			if(!secureString) {
				this.console.info("Authentication via SecureString:  NO SECURESTRING DETECTED");
				resolve(false);
			} else {
				this.console.info("Authentication via SecureString starting: ", secureString);
				this.authSvc.tryAuthenticateViaSecureString(secureString)
					.then(success => {
						this.console.info("Authentication via SecureString " + (success ? "SUCCEEDED" : "FAILED"));
						resolve(success);
					})
					.catch(err => {
						this.console.error("Authentication via SecureString ERROR: ", err);
						resolve(false);
					});
			}
		});
	}

	private tryFromMemory(): Promise<boolean> {
		return new Promise(resolve => {
			this.authSvc.tryAuthenticateViaLocalStorage().then(success => {
				this.console.info("Authentication via LocalStorage " + (success ? "SUCCEEDED" : "FAILED"));
				resolve(success);
			})
			.catch(err => {
				this.console.error('Authentication via LocalStorage ERROR: ', err);
				resolve(false);
			});
		});
	}

	private sendUserOff(u: CurrentUser): void {
		let navigateIfNecessary = (routeUrl: string, ...otherAcceptableUrls: string[]) => {
			let acceptableUrls = [routeUrl].concat(otherAcceptableUrls);
			let currentUrlPath = new URL(window.location.href).pathname;
			this.console.info('Attempting to send the user on their way based on their roles', currentUrlPath, acceptableUrls);
			if(acceptableUrls.some(url => currentUrlPath.indexOf(url) == 0)){
				this.console.info('User is already in a relevant part of the application (no need to redirect them)')
				return;
			} else {
				this.console.info('User sent -> ' + routeUrl)
				this.router.navigate([routeUrl]);
			}
		};

		//navigateIfNecessary('/review');
		//return;
		if (u.roles.academicHumanResources) { 
			navigateIfNecessary('/ahr','/academic','/review','/program-review'); 
		}
		else if (u.roles.civilRightsCompliance) { 
			navigateIfNecessary('/crc'); 
		}
		else if (u.roles.academic.hasValue()) { 

			if (u.roles.academic.value.title?.id  == AcademicTitleId.StaffDirector) {
				navigateIfNecessary('/academic','/review'); 
			} else {
				navigateIfNecessary('/academic','/program-review','/review'); 
			}
		}
		else { 
			navigateIfNecessary('/explore','/review');
		}
	}
}