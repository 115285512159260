import { Component, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectDto, NonANRCollaborator, AuthenticationService, AuthenticatedIdentity, Academic, HumanResources } from '../../domain';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
   	ProgramArea,
   	Tag,
   	TagType,
   	Theme,
   	User,
   	SortedSet,
   	Project,
   	ProjectService,
	ProjectTagTypes,
	ActivityParentType,
	ActivityService
} from '../../domain';
import { ConsoleService } from '../../widgets/console.service';
import { Router } from '@angular/router';
import { TooltipService } from '../../widgets/tooltip.service';

@Component({
  templateUrl: './project-modal.component.html',
  styleUrls: ['./project-modal.component.scss']
})
export class ProjectModalComponent {
	public StaticText: any;
	programAreas: ProgramArea[] = [];
	themes: Theme[] = [];
	availableTagTypes: TagType[] = ProjectTagTypes;
	form: FormGroup;
	closing = false;
	changed = false;
	title = 'Adding a Project';

	wideLayout: boolean;
	formGroupClass = "form-group row";
	labelClass = "col-form-label bold-label col-4";
	labelAnnotationClass = "";
	formControlClass = "col-8";
	meritAndPromotionHR = HumanResources.ANR;
	requiredText: { [id:number] : string } = {};
	themeToolTip: { [id:number] : string } = {};
	rolesToolTip: { [id:number] : string } = {};
	descriptionToolTip: { [id:number] : string } = {};
	touchEnabled = true;
	
	constructor(
		private router: Router,
		public dialogRef: MatDialogRef<ProjectModalComponent>,
	   	private snackBar: MatSnackBar,
		@Inject(MAT_DIALOG_DATA) private data: any,
		fb: FormBuilder,
		private svc: ProjectService,
		activitySvc: ActivityService,
		authSvc: AuthenticationService,
		private console: ConsoleService,
		public tooltipSvc: TooltipService
	){
		this.StaticText = tooltipSvc.StaticText;

		this.requiredText[HumanResources.Campus] = "(Required)";
		this.requiredText[HumanResources.ANR] = "(Required for Dossier)";

		this.themeToolTip[HumanResources.Campus] = this.StaticText.ProjectThemeTooltipCampusMP;
		this.themeToolTip[HumanResources.ANR] = this.StaticText.ProjectThemeTooltipANRMP;

		this.rolesToolTip[HumanResources.Campus] = this.StaticText.ProjectRoleToolTipCampusMP;
		this.rolesToolTip[HumanResources.ANR] = this.StaticText.ProjectRoleToolTipANRMP;

		this.descriptionToolTip[HumanResources.Campus] = this.StaticText.ProjectDescriptionToolTipCampusMP;
		this.descriptionToolTip[HumanResources.ANR] = this.StaticText.ProjectDescriptionToolTipANRMP;

		authSvc.currentIdentity.subscribe(identity => {
			identity.doWithValue((i: AuthenticatedIdentity) => 
				i.user.roles.academic.doWithValue((a: Academic) =>
				{
					this.meritAndPromotionHR = a.meritAndPromotionHr;
				}));
		});
		
		svc.getAllProgramAreas().then(pas => {
			let project = (<Project> data.project) != null ? (<Project> data.project) : Project.empty();
			this.programAreas = pas
				.filter(pa => 
					!pa.disabled
					|| project.programArea.id == pa.id)	// show selected program area
				.sort((a,b) => (a.disabled ? 1 : 0) - (b.disabled ? 1 : 0)); 	
		});
		activitySvc.getAvailableParents().then(parents => {
			this.themes = parents
				.filter(p => p.type == ActivityParentType.Theme)
				.map(p => new Theme(p.id, p.name));
		});

		let d = new Date();
		this.form = fb.group({
			name: '',
			description: '',
			programAreaId: null,
			role: '',
			startDate: d,
			theme: null,
			themeId: null,
			tags: new SortedSet<Tag>(),
			ANRCollaborators: new SortedSet<User>(),
			nonANRCollaborators: [],
			supportAmountDuration: '',
			supportSource: ''
		});

		if('wideLayout' in data){
			this.wideLayout = data.wideLayout;
		}

		if('title' in data){
			this.title = data['title'];
		}

		let theme = <Theme> data.theme;
		if(theme){
			this.form.controls['theme'].setValue(theme);
			this.form.controls['themeId'].setValue(theme.id);
		}

		let project = <Project> data.project;
		if(project){
			this.form.setValue({
				name: project.name,
				description: project.description || '',
				programAreaId: project.programArea.id,
				role: project.role,
				startDate: project.startDate,
				themeId: project.theme.match(
					() => null,
					t => t.id),
				theme: project.theme.match(
					() => null,
					t => t),
				tags: new SortedSet<Tag>(project.tags.slice(0)),
				ANRCollaborators: new SortedSet<User>(project.ANRCollaborators.slice(0)),
				nonANRCollaborators: project.nonANRCollaborators.slice(0),
				supportAmountDuration: project.supportAmountDuration,
				supportSource: project.supportSource
			});
		}
		this.console.log('project form value: ',this.form.value);

		this.form.valueChanges.subscribe(_ => {
			this.console.log('form value changed', this.form.value);
			this.changed = true;
		});

		this.setLayout();
	}

	get selectedTheme() {
		let theme = this.themes.find(t => t.id == this.form.value.themeId);
		return theme == null ? "" : theme.name;
	}

	get nonANRCollaboratorCount() { 
		return !!this.form.value.nonANRCollaborators ? this.form.value.nonANRCollaborators.length : 0;
	}

	programAreaClass(disabled: boolean): string {
		return disabled ? "option-disabled" : "";
	}

	setLayout(){
		if (this.wideLayout == null){
			this.wideLayout = false;
		}
		this.console.log("wide Layout(effective): ", this.wideLayout);
		if (this.wideLayout){
			this.formGroupClass = "form-group row";
			this.labelClass = "col-form-label bold-label col-4";
			this.labelAnnotationClass = "";
			this.formControlClass = "col-8";
		} else {
			// use narrow layout
			this.formGroupClass = "form-group";
			this.labelClass = "col-form-label";
			this.labelAnnotationClass = "inline-label-annotation"
			this.formControlClass = "";
		}
	}

	cancel(){
		if(this.changed && window.confirm('You may have unsaved changes. Save changes?'))
		{
			this.save();
		} else {
			this.dialogRef.close();
		}
	}

	canArchive(){
		return !!this.data.project;
	}

	archive(){
		if(!this.data.project){
			return;
		}
		if(window.confirm(this.StaticText.ArchiveProject + '\n\nArchive this Project?')){
			this.closing = true;
			this.svc
				.archive(this.data.project.id)
				.then(result =>
					result.matchDo(
						_ => {
							let snackBarRef = this.snackBar.open('Project Archived', 'Undo', {duration: 60* 1000});
							snackBarRef.onAction().subscribe(() => {
								this.console.log('Project unarchive triggered!');
								this.unarchive();
							});
							// close dialog and redirect to projects page. This navigates away from the project detail page.
							this.dialogRef.close('archived');
							this.router.navigateByUrl('/projects');
						},
						errorMsg => {
							this.snackBar.open(errorMsg, null, {duration: 3000});
							this.closing = false;
						}))
				.catch(error => {
					this.snackBar.open(error.statusText, null, {duration: 10000});
					this.closing = false;
				});
		}
	}

	unarchive(){
		this.svc
			.unarchive(this.data.project.id)
			.then(result => result.matchDo(
				_ => {
					this.snackBar.open('Project Restored', null, {duration: 3000});
					this.router.navigateByUrl('/projects/' + this.data.project.id);
				},
				errorMsg => {
					this.snackBar.open('Unable to Restore Project: ' + errorMsg, null, {duration: 3000});
					this.closing = false;
				}));
	}

	save(){
		let fv = this.form.value;
		let data = new ProjectDto(
			fv.name,
			fv.description,
			fv.programAreaId,
			fv.role,
			fv.startDate,
			fv.themeId,
			fv.ANRCollaborators.toArray().map(c => c.id),
			fv.nonANRCollaborators == null ? [] : (<NonANRCollaborator[]>fv.nonANRCollaborators).map(c => c.toDto()),
			fv.tags.toArray().map(t => t.toDto()),
			[] /* new User-defined tags */,
			fv.supportAmountDuration,
			fv.supportSource);

		this.closing = true;
		if (data.programAreaId === null) {
			this.snackBar.open("Program Area Required", null, { duration: 10000 });
			this.closing = false;
		}

		if (!!this.data.project) {
			if (this.closing) {
				this.svc
					.update(this.data.project.id, data)
					.then(result => result.matchDo(
						projectId => {
							this.snackBar.open('Project updated', null, { duration: 3000 });
							this.dialogRef.close('updated');
						},
						errorMsg => {
							this.snackBar.open(errorMsg, null, { duration: 3000 });
							this.closing = false;
						}))
					.catch(error => {
						this.snackBar.open(error.statusText, null, { duration: 10000 });
						this.closing = false;
					});
			}
			
		} else {
			if (this.closing) {
				this.svc
					.create(data)
					.then(result => result.matchDo(
						projectId => {
							this.snackBar.open('Project added', null, { duration: 3000 });
							this.dialogRef.close(projectId);
						},
						errorMsg => {
							this.snackBar.open(errorMsg, null, { duration: 3000 });
							this.closing = false;
						}))
					.catch(error => {
						this.snackBar.open(error.statusText, null, { duration: 10000 });
						this.closing = false;
					});
			}
		}
	}
}