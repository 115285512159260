
import {debounceTime} from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { ICountySelection, ClienteleService, County } from '../../domain';
import { ConsoleService } from '../console.service';
import { NG_VALUE_ACCESSOR, FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
	selector: 'county-selection-picker',
	templateUrl: './county-selection-picker.component.html',
	styleUrls: ['./county-selection-picker.component.scss'],
 	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: CountySelectionPickerComponent,
		multi: true
	}],
})
export class CountySelectionPickerComponent implements OnInit {
	@Input() countySelection: ICountySelection;
	private selection: ICountySelection = ICountySelection.CountySelection([], false);
	countiesInitialized: boolean = false;
	counties: County[] = [];
	form: FormGroup;
	
	constructor(
		private fb: FormBuilder,
		private svc: ClienteleService,
		private console: ConsoleService) {

		this.svc.getCounties().then(counties => {
			this.counties = counties;
			
			let countyOptions = (() => {
				let o = {};
				this.counties.forEach(c => 
					o[c.id] = false);
				return this.fb.group(o);
			})();
	
			//this.console.log("countyOptions: ", countyOptions);
	
			this.form = this.fb.group({
				statewide: false,
				countyOptions: countyOptions
			});

			if(!!this.countySelection){
				this.selection = this.countySelection.duplicate();
	
				let selectedCounties = {};
				this.counties.forEach(c => 
					selectedCounties[c.id] = this.selection.Counties.findIndex(sc => sc.id == c.id) >= 0
						? true
						: false
				);
	
				this.form.setValue({
					statewide: this.selection.Statewide,
					countyOptions: selectedCounties
				});
			}
			//this.console.log("Form: ", this.form);

			this.form.valueChanges.pipe(
				debounceTime(200))
				.forEach(() => {
					this._onChange(this.getCountySelectionFromForm());
				});

			this.countiesInitialized = true;
		});
	}

	ngOnInit() {}

	selectStatewide(){
		this.selection.Statewide = true;
		this.form.patchValue({ statewide: this.selection.Statewide });
		this._onChange(ICountySelection.CountySelection([], this.selection.Statewide));
	}

	selectCounties() {
		this.selection.Statewide = false;
		this.form.patchValue({ statewide: this.selection.Statewide });
		this._onChange(ICountySelection.CountySelection([], this.selection.Statewide));
	}

	isStatewideSelected(): boolean {
		return !!this.selection && this.selection.Statewide;
	}

	isCountiesSelected(): boolean {
		return !!this.selection && this.selection.Statewide == false;
	}

	getCountySelectionFromForm(): ICountySelection {
		let fv = this.form.value;
		//this.console.log("changed: ", fv);
		let counties: County[] = [];
		this.counties.forEach(c => {
			if(fv.countyOptions[c.id]) {
				counties.push(c);
			}
		});
		return ICountySelection.CountySelection(counties, this.selection.Statewide);
	}

	private _onChange: any = () => {};
    private _onTouch: any = () => { };
	writeValue(val: ICountySelection): void {
        this.countySelection = val;
	}
	registerOnTouched(fn): void { this._onTouch = fn; }
    registerOnChange(fn): void { this._onChange = fn; }
}


