<div class="container" *ngIf="!!vm">
	<p>Here you can manage the members of each Program Review committee for the {{vm.year-1}}-{{vm.year}}.</p>
	<div class="row">
		<div class="col-md-3" *ngFor="let committee of vm.committees">
			<div class="card">
				<div class="card-header">
					{{committee.name}} ({{committee.members.length}})
				</div>
				<div class="list-group">
					<div class="list-group-item" *ngFor="let m of committee.members">
						<button class="btn btn-outline-danger btn-sm pull-right" (click)="removeMember(committee, m)">
							Remove
						</button>
						{{m.name}}
					</div>
					<div class="list-group-item text-muted" [hidden]="committee.members.length > 0">
						<em>No members yet</em>
					</div>
				</div>
				<div class="card-footer">
					<button class="btn btn-secondary btn-block" (click)="addMember(committee)">
						Add Member
					</button>
				</div>
			</div>
		</div>
	</div>
</div>