import { Component, OnInit } from '@angular/core';
import { PercentPipe } from '@angular/common';
import { FTE, FTEService, FTEOverview, PPEProject, AuthenticationService, AuthenticatedIdentity, Academic, HumanResources } from '../../domain';

import { MatDialog } from '@angular/material/dialog';
import {
	CountyFteModalComponent,
	ConditionChangeFteModalComponent,
	MultiStateProjectModalComponent
} from './modals';
import { ProjectModalService } from '../../main-core/project-modal.service';
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { TooltipService } from '../../widgets/tooltip.service';
import { ConsoleService } from '../../widgets/console.service';
import { AcademicReportSubmissionTextType } from '../academic-report-submission/academic-report-submission.component';

@Component({
	selector: 'app-fte',
	templateUrl: './fte.component.html',
	styleUrls: ['./fte.component.scss']
})
export class FteComponent implements OnInit {
	public StaticText: any;
	fte: FTEOverview;
	editProjectId: number | null;
	meritAndPromotionHR = HumanResources.ANR;
	fteDueDateWarning = "At any time, you may return to Project Board F.T.E., edit, and click submit again.";
	academicReportSubmissionTextType = AcademicReportSubmissionTextType.FTE;
	
	constructor(
		private svc: FTEService,
		authSvc: AuthenticationService,
		private pp: PercentPipe,
		private matDialog: MatDialog,
		private readonly projectModalSvc: ProjectModalService,
		private gaService: GoogleAnalyticsService,
		private console: ConsoleService,
		public tooltipSvc: TooltipService,
		route: ActivatedRoute
	) {
		this.StaticText = tooltipSvc.StaticText;

		route.params.subscribe(params => {
			this.console.log('params', params);
			if(params['editProjectId']){
				this.editProjectId = parseInt(params['editProjectId']);
			}
		});

		authSvc.currentIdentity.subscribe(identity => 
			identity.doWithValue((i: AuthenticatedIdentity) => 
				i.user.roles.academic.doWithValue((a: Academic) =>
				{
					this.meritAndPromotionHR = a.meritAndPromotionHr;
				})));
	}

	ngOnInit() {
		this.initData().then(_ =>
		{
			this.tryEditProjectById(this.editProjectId);
		});
	}

	aesTooltipText = MultiStateProjectModalComponent.aesTooltipText;

	hasInstructions() {
		if (this.getInstructions().length > 0) {
			return true;
		} 
		return false;
	}

	getInstructions(): string {
		return this.tooltipSvc.tooltip("FTEInstructions." + this.tooltipSvc.mapMeritAndPromotionsHRToString(this.meritAndPromotionHR));
	}

	private initData(): Promise<void>{
		return this.svc.getOverview().then(overview => {
			this.fte = overview;
			overview.projects.forEach(p => {
				p['ANRCollaboratorsTooltip'] = p.ANRCollaborators
					.map(c => c.firstName + ' ' + c.lastName)
					.join('\n');
				p['NonANRCollaboratorsTooltip'] = p.NonANRCollaborators
					.map(c => c.name)
					.join('\n');
			});
			this.console.log('fte data loaded:', overview);
		});
	}

	formattedFte(fte: FTE): string {
		let decimalInfo = '1.0-' + fte.precision;
		return this.pp.transform(fte.value, decimalInfo);
	}

	mapKeys<TKey,TValue>(map: Map<TKey,TValue>): TKey[] {
		return Array.from(map.keys());
	}

	editCounty() {
		this.matDialog.open(CountyFteModalComponent, {
			disableClose: true,
			width: '60%',
			data: {
				countyAssignments: this.fte.county
			},
			hasBackdrop: true
		}).afterClosed().subscribe(r => {
			if(r == 'success'){
				this.initData();
			}
		});
	}

	countyFteSum(){
		return this.fte.county.map(c => c.fte.value).reduce((a,b) => a+b);
	}

	editConditionChange() {
		this.matDialog.open(ConditionChangeFteModalComponent, {
			disableClose: true,
			width: '70%',
			data: {
				conditionChangeFte: this.fte.conditionChange
		   	},
			hasBackdrop: true
		}).afterClosed().subscribe(r => {
			if(r == 'success'){
				this.initData();
			}
		});
	}

	newProject(){
		this.projectModalSvc.openNew()
		.afterClosed()
		.subscribe(result => {
			//the modal passes back the id of the new project, if successful.
			if(isNaN(result)){
				return;
			}
			this.gaService.eventEmitter("Multi-State Project Created", "Multi-State Project", "Create");
			this.initData().then(_ => {
				this.tryEditProjectById(result);
			});
		})
	}

	editProject(project: PPEProject) {
		MultiStateProjectModalComponent
		.open(this.matDialog, project)
		.afterClosed().subscribe(success => {
			if(!!success){
				this.initData();
			}
		});
	}

	private tryEditProjectById(id: number){
		let project = this.fte.projects.find(p => p.id == id);
		this.console.log('tryEdit project:', project);
		if(!project){
			return;
		}
		this.editProject(project);
	}

	notifyAHR(){
		alert('notify AHR');
	}
}