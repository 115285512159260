<div mat-dialog-title>
  {{title}}
</div>

<mat-dialog-content [innerHtml]="html" [class]="wrapperClasses">
</mat-dialog-content>

<mat-dialog-actions>
	<button (click)="close()" class="btn btn-link" [mat-dialog-close]="true">
		OK
	</button>
</mat-dialog-actions>