import { User, NonANRCollaborator, Option, NonANRCollaboratorDto } from '../..';
import { FTE } from './fte';

export class PPEProject {
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly NonANRCollaborators: NonANRCollaborator[],
        public readonly ANRCollaborators: User[],
        public readonly multiStateInfo: Option<MultiStateInfo>
    ){}

    public static fromJson(o:any): PPEProject {
        return new PPEProject(
            o.id,
            o.name,
            o.nonANRCollaborators.map(NonANRCollaborator.fromJson),
            o.anrCollaborators.map(User.fromJson),
            Option.fromJson(o.multiStateInfo, MultiStateInfo.fromJson));
    }
}

export class MultiStateInfo {
    constructor(
        public readonly fte: FTE,
        public readonly startDate: Date,
        public readonly endDate: Date,
        public readonly aes: boolean
    ){}

    public static fromJson(o:any): MultiStateInfo {
        return new MultiStateInfo(
            FTE.fromJson(o.fte),
            <Date>o.startDate,
            <Date>o.endDate,
            <boolean>o.aes);
    }
}

export class MultiStateProjectDto
{
	constructor(
		public projectId: number,
		public fte: number,
		public startDate: Date,
		public endDate: Date,
		public aes: boolean,
		public ANRCollaboratorUserIds: number[],
		public NonANRCollaborators: NonANRCollaboratorDto[]
	) { }
}