import { Component, OnInit, Input } from '@angular/core';
import {
   	ClienteleGroupType,
   	ClienteleGroupDetail,
   	ClienteleService,
	DemographicInfo,
	ComplianceNotesDto,
	getStateFiscalYearsOfInterest,
	AuthenticationService,
	AuthenticatedIdentity,
	Academic,
	HumanResources,
	HistoricalClienteleGroup
} from '../../domain';
import { ClienteleGroupModalComponent } from '../clientele-group-modal/clientele-group-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConsoleService } from '../../widgets/console.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { TooltipService } from '../../widgets/tooltip.service';
import { combineLatest } from 'rxjs';

@Component({
	selector: 'app-clientele',
	templateUrl: './clientele.component.html',
	styleUrls: ['./clientele.component.scss']
})
export class ClienteleComponent implements OnInit {
	@Input() reloadClienteleGroups: Event;
	highlightedGroup: ClienteleGroupDetail = null;
	highlightedHistoricalGroup: ClienteleGroupDetail = null;
	ClienteleGroupType = ClienteleGroupType;
	groups: ClienteleGroupDetail[] = undefined;
	historicalGroupList: HistoricalClienteleGroup[] = undefined;
	historicalGroupDetail: ClienteleGroupDetail = undefined;
	demographicInfo: DemographicInfo;
	meritAndPromotionHR = HumanResources.ANR;
	constructor(
		authSvc: AuthenticationService,
		private svc: ClienteleService,
		private matDialog: MatDialog,
		private snackbar: MatSnackBar,
		private readonly gaService: GoogleAnalyticsService,
		private console: ConsoleService,
		public tooltipSvc: TooltipService)
	{
		this.demographicInfo = svc.getDemographicInfo();
		authSvc.currentIdentity.subscribe(identity => 
			identity.doWithValue((i: AuthenticatedIdentity) => 
				i.user.roles.academic.doWithValue((a: Academic) =>
				{
					this.meritAndPromotionHR = a.meritAndPromotionHr;
				})));
	}

	ngOnInit() {
		this.loadGroups();
	}

	hasInstructions() {
		if (this.getInstructions().length > 0) {
			return true;
		} 
		return false;
	}

	getInstructions(): string {
		return this.tooltipSvc.tooltip("ClienteleInstructions." + this.tooltipSvc.mapMeritAndPromotionsHRToString(this.meritAndPromotionHR));
	}

	noteYearsOfInterest: number[] = getStateFiscalYearsOfInterest();

	complianceNotes: { [id: string] : string} = {};

	private loadGroups(){
		// It would be a nicer user experience to preserve their current group selection, if possible.
		let highlightedId = this.highlightedGroup && this.highlightedGroup.id;

		combineLatest([
			this.svc.getClienteleGroups(),
			this.svc.getHistoricalClienteleGroups()
		])
		.subscribe(([gs,hgl]) => {
			this.console.log("Clientele Groups Loaded: ", gs);
			this.console.log("Historical Group List: ", hgl)
			this.groups = gs;
			this.historicalGroupList = hgl;
			this.highlightedGroup = gs.find(g => g.id == highlightedId);
			this.complianceNotes = (() => {
				let dict = {};
				this.noteYearsOfInterest
				.forEach(ffyId => gs
				.forEach(g => {
					dict[g.id + '_' + ffyId] = g.complianceNotesByFederalFiscalYearId[ffyId];
				}));
				return dict;
			})();
		});
	}

	private visibleNotes: {[groupId: number] : boolean} = {};

	toggleNotesVisibilityForGroup(group: ClienteleGroupDetail){
		this.visibleNotes[group.id] = !this.visibleNotes[group.id];
	}

	notesVisibleForGroup(group: ClienteleGroupDetail): boolean {
		return !!this.visibleNotes[group.id];
	}

	saveComplianceNotes(group: ClienteleGroupDetail, ffyId: number, notes: string){
		return () => 
			this.svc.saveComplianceNotes(group.id, new ComplianceNotesDto(ffyId, notes))
			.then(
				result => {
					result.matchDo(
						_ => {
							this.snackbar.open('Compliance Notes saved');
							this.gaService.eventEmitter("Compliance Note Saved", "Compliance Note", "Update");
							group.complianceNotesByFederalFiscalYearId[ffyId] = this.complianceNotes[group.id + '_' + ffyId];
						},
						(errorMsg: string) => this.snackbar.open('Compliance Notes not saved: ' + errorMsg));
				},
				_ => this.snackbar.open('Compliance Notes not saved: Unknown problem'));
	}

	definitions = {
		ARE: 'You demonstrate "All Reasonable Efforts" when you use 3 of the 4 Effort Activity Types for a given demographic.  Academics should always strive to make A.R.E for a demographic, even when in parity, in order to maintain parity into the future.',
		compliant: 'You are Civil Rights Compliant for a given demographic when you have either A) achieved parity for that demographic or B) demonstrated All Reasonable Efforts for that demographic.  In order to be considered Civil Rights Compliant for a given Clientele Group, you must be compliant for all demographics defined within.',
		parity: 'When the percentages of protected races and ethnicity in the actual clientele contacts/program participants mirror (within at least 20 percent) their percentages in the potential clientele/participants (i.e. the baseline), then parity of participation (or parity) has been achieved.'
	};

	newGroup(){
		this.matDialog.open(ClienteleGroupModalComponent, {
			width:'500px',
			disableClose: true,
			data: { title:'Adding a Clientele Group' },
			hasBackdrop: true
		}).afterClosed().subscribe(result => {
			if(result == "created"){
				this.gaService.eventEmitter("Clientele Group Created", "Clientele Group", "Create");
			}
			this.loadGroups();
		});
	}

	editGroup(group: ClienteleGroupDetail){
		let modal = this.matDialog.open(ClienteleGroupModalComponent, {
			width:'500px',
			disableClose: true,
			id: "edit-clientele-group",
			data: {
			   	title:'Editing a Clientele Group',
				group: group
			},
			hasBackdrop: true
		});
		//this.console.log("Clientele Dialog: ", modal);

		// only "Undo" or "Unarchive" calls the reloadClienteleGroups event explicilty
		let subscription = modal.componentInstance
			.reloadClienteleGroups
			.asObservable()
			.subscribe(__ => {
				this.console.log("reload clientele groups");
				// need to wait a short amount of time for the clientele group denormalization process to complete before re-loading the groups. Otherwise the unarchived group will not display.
				// there's probably a better way to do this but for now this works
				setTimeout(() => { this.loadGroups(); }, 500);
			});
		modal.afterClosed().subscribe(result => {
			if(result == "updated"){
				this.gaService.eventEmitter("Clientele Group Updated", "Clientele Group", "Update");
			}
			this.loadGroups();
		});
	}

	highlight(group: ClienteleGroupDetail){

		this.historicalGroupDetail = null;
		this.highlightedHistoricalGroup = null;

		this.highlightedGroup = 
			this.highlightedGroup == group
				? null
				: group;		
	}

	showHistoricalData(historicalGroup: HistoricalClienteleGroup, group: ClienteleGroupDetail) {
		this.highlightedGroup = null;
		this.svc.getHistoricalClienteleGroupDetail(historicalGroup.federalFiscalYearId).then(hg => 
		{			
			this.console.log("Historical Clientele Group: ", hg);
			this.historicalGroupDetail = hg.find(g => g.id == group.id);

			this.highlightedHistoricalGroup = 
				this.highlightedHistoricalGroup != null && 
				this.highlightedHistoricalGroup.id == this.historicalGroupDetail.id && 
				this.highlightedHistoricalGroup.federalFiscalYearId == this.historicalGroupDetail.federalFiscalYearId
					? null
					: this.historicalGroupDetail;
		});
	}

	calculateActualSum(groupId: number, historicalGroup : boolean): number {
		let sum = 0;
		let selectedGroup = historicalGroup ? this.highlightedHistoricalGroup : this.highlightedGroup;

		if (selectedGroup) {

			selectedGroup.demographics.forEach(demographic => {
				sum += demographic.actual.contacts;
			});

			let applicableTypes = this.demographicInfo.applicableTypesByClienteleGroupTypeId.get(selectedGroup.type);

			if (applicableTypes !== undefined)
				return sum/applicableTypes.length;

			return 0;
		}

		return 0;
	}
}
