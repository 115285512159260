export class Column<TItem,TColumnValue> {
	public readonly defaultDirection: Direction;
	public readonly icon: SortIcon;
	private constructor(
		public readonly id: string,
		public readonly getObjectValue: (item: TItem) => TColumnValue,
		public readonly label: string,
		defaultDirection?: Direction,
		icon?: SortIcon)
	{
		this.defaultDirection = defaultDirection || Direction.asc;
		this.icon = icon || SortIcon.Default;
	}

	public getDefaultSorting(): Sorting<TItem,TColumnValue> {
		return new Sorting(this, this.defaultDirection);
	}

	public static withKey(key: string, label: string, defaultDirection?: Direction, icon?: SortIcon){
		return new Column(key, o => o[key], label, defaultDirection, icon); 
	}

	public static withGetter<TItem,TColumnValue>(id: string, getter: (item: TItem) => TColumnValue, label: string, defaultDirection?: Direction, icon?: SortIcon){
		return new Column(id, getter, label, defaultDirection, icon); 
	}
}

export enum SortIcon {
	Default, Alpha, Numeric, Amount
}

export class Sorting<TItem,TColumnValue> {
	public readonly directionString: string;
    constructor(
        public readonly column: Column<TItem,TColumnValue>,
        public readonly direction: Direction
    ) {
		this.directionString = Direction[direction];
	}

	public getNext(nextColumn: Column<TItem,TColumnValue>): Sorting<TItem,TColumnValue> {
		var nextDirection = nextColumn != this.column
			? nextColumn.defaultDirection
			: this.direction == Direction.asc
				? Direction.desc
				: Direction.asc;
		return new Sorting(nextColumn, nextDirection);
	}

	public withDirection(d: Direction): Sorting<TItem,TColumnValue> {
		return d == this.direction
			? this
			: new Sorting(this.column, d);
	}
}

export enum Direction { asc, desc }

export function Sort<T,TValue>(array: T[], sorting: Sorting<T,TValue>): T[] {
	var sortFunc = sorting.direction == Direction.asc
		? sortAsc
		: sortDesc;

	return array.slice(0).sort(sortFunc);

	function sortAsc(a, b) {
		return compare(sorting.column.getObjectValue(a), sorting.column.getObjectValue(b));
	}

	function sortDesc(b, a) {
		return compare(sorting.column.getObjectValue(a), sorting.column.getObjectValue(b));
	}
}

function compare(a, b): number {
	if (a.type === "number") {
		return a - b;
	}
	else if (a.type === "date") {
		return a.date - b.date;
	}
	// String comparison
	if (a > b) {
		return 1;
	}
	else if (a == b) {
		return 0;
	}
	else {
		return -1;
	}
}
