import { Component, OnInit, Input } from '@angular/core';
import { Case, Position, ExceptionalTiming, Option, Action, ReviewerCase, CaseAdvancement, AccelerationType } from '../../../domain';
import { ConsoleService } from '../../../widgets/console.service';

@Component({
	selector: 'proposed-action',
	templateUrl: './proposed-action.component.html',
	styleUrls: ['./proposed-action.component.scss']
})
export class ProposedActionComponent implements OnInit {
	@Input() caseAdvancement: CaseAdvancement = null;
    ExceptionalTiming = ExceptionalTiming;
	AccelerationType = AccelerationType;
    readonly IndefiniteStatus: string = 'Indefinite Status';

    constructor(
        private console: ConsoleService
		) {
        }

	ngOnInit() {
	}

    public IsIndefiniteStatusOnly(){
		var proposedAction = this.caseAdvancement.proposedAction.getValueOrDefault(null);
		return Position.titleRankStepEqual(this.caseAdvancement.currentPosition, proposedAction.endingPosition)
			&& this.indefiniteStatusProposed();
	}

	public indefiniteStatusProposed() {
		var proposedAction = this.caseAdvancement.proposedAction.getValueOrDefault(null);
		return this.caseAdvancement.currentPosition.term != this.IndefiniteStatus && proposedAction.endingPosition.term == this.IndefiniteStatus;
	}
}