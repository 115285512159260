import { Tag, TagType, AllAvailableTags } from '../../domain';
import { COMMODITIES } from './COMMODITIES';
import { PROGRAM_AREAS } from './PROGRAM_AREAS';

export const PROGRAM_AREA_TAGS = 
	sortedByName(
		PROGRAM_AREAS.map(pa =>
			new Tag(TagType.ProgramArea, pa.id, pa.name)));

export const STRATEGIC_INITIATIVE_TAGS = sortedByName([
    new Tag(TagType.StrategicInitiative, 1, "Healthy Food Systems"),
    new Tag(TagType.StrategicInitiative, 2, "Sustainable Natural Ecosystems"),
    new Tag(TagType.StrategicInitiative, 3, "Healthy Families and Communities"),
    new Tag(TagType.StrategicInitiative, 4, "Water Quality, Quantity, and Security"),
    new Tag(TagType.StrategicInitiative, 5, "Endemic and Invasive Pests and Diseases")
]);

export const USER_DEFINED_TAGS = sortedByName([
    new Tag(TagType.UserDefined, 1, "#SaveTheWater"),
    new Tag(TagType.UserDefined, 2, "For Jerry"),
    new Tag(TagType.UserDefined, 3, "My-Rice"),
    new Tag(TagType.UserDefined, 4, "MyPriorities"),
    new Tag(TagType.UserDefined, 5, "Statewide Conference"),
]);

export const STATEWIDE_TAGS = sortedByName([
	new Tag(TagType.Statewide, 2, "Youth, Families, and Communities Statewide Program"),
	new Tag(TagType.Statewide, 7, "Agricultural Issues Center"),
	new Tag(TagType.Statewide, 24, "California 4-H Foundation"),
	new Tag(TagType.Statewide, 50, "Expanded Food Nutrition Education Program (EFNEP)"),
	new Tag(TagType.Statewide, 69, "Integrated Pest Management (IPM) Statewide Program"),
	new Tag(TagType.Statewide, 151, "Sustainable Agricultural Research & Education Program"),
	new Tag(TagType.Statewide, 187, "UC Master Gardener Program"),
	new Tag(TagType.Statewide, 240, "Informatics and GIS (IGIS) Statewide Program"),
	new Tag(TagType.Statewide, 243, "California Institute for Water Resources"),
	new Tag(TagType.Statewide, 244, "California Naturalist Program"),
	new Tag(TagType.Statewide, 246, "Nutrition Policy Institute"),
	new Tag(TagType.Statewide, 252, "UC Master Food Preserver Program")
])

export const CONDITION_CHANGE_TAGS = sortedByName([
	new Tag(TagType.ConditionChange, 1, "Increased workforce retention and competency"),
	new Tag(TagType.ConditionChange, 2, "Equitable, diverse, and culturally competent workplaces"),
	new Tag(TagType.ConditionChange, 3, "Decreased health disparities"),
	new Tag(TagType.ConditionChange, 4, "Increased equity in access to positive built and natural environments"),
	new Tag(TagType.ConditionChange, 5, "Increased equity of youth outcomes"),
	new Tag(TagType.ConditionChange, 6, "Improved economic equity"),
	new Tag(TagType.ConditionChange, 7, "Reduced drivers to climate change"),
	new Tag(TagType.ConditionChange, 8, "Increased preparedness and resilience to climate change"),
	new Tag(TagType.ConditionChange, 9, "Improved 'thriving' in youth, adults, and families"),
	new Tag(TagType.ConditionChange, 10, "Improved community health and wellness"),
	new Tag(TagType.ConditionChange, 11, "Improved mgmt. and use of land"),
	new Tag(TagType.ConditionChange, 12, "Improved mgmt. and use of animals (domestic/wild)"),
	new Tag(TagType.ConditionChange, 13, "Improved air quality"),
	new Tag(TagType.ConditionChange, 14, "Protected and conserved soil quality"),
	new Tag(TagType.ConditionChange, 15, "Improved mgmt. and use of water resources"),
	new Tag(TagType.ConditionChange, 16, "Improved food equity and security"),
	new Tag(TagType.ConditionChange, 17, "Increased food safety"),
	new Tag(TagType.ConditionChange, 18, "Sustainability and viability of agriculture"),
	new Tag(TagType.ConditionChange, 19, "Increased emerging food economies and markets")
])

export const OUTCOME_TYPE_TAGS = sortedByName([
	new Tag(TagType.OutcomeType, 1, "Change in Learning"),
	new Tag(TagType.UserDefined, 2, "Change in Action"),
	new Tag(TagType.UserDefined, 3, "Change in Policy or Decision-Making"),
	new Tag(TagType.UserDefined, 4, "Change in Condition"),
]);
export const RESEARCH_EXTENSION_TAGS = sortedByName([
    new Tag(TagType.ResearchExtension, 1, "Desert"),
    new Tag(TagType.ResearchExtension, 2, "Hansen"),
    new Tag(TagType.ResearchExtension, 3, "Hopland"),
    new Tag(TagType.ResearchExtension, 4, "Intermountain"),
    new Tag(TagType.ResearchExtension, 5, "Kearney"),
	new Tag(TagType.ResearchExtension, 6, "Lindcove"),
    new Tag(TagType.ResearchExtension, 7, "Sierra Foothills"),
    new Tag(TagType.ResearchExtension, 8, "South Coast"),
    new Tag(TagType.ResearchExtension, 9, "Westside"),
    new Tag(TagType.ResearchExtension, 10, "REC System")
]);


export const ALL_AVAILABLE_TAGS: AllAvailableTags =
	new AllAvailableTags(
		COMMODITIES,
		[],
		STRATEGIC_INITIATIVE_TAGS,
		USER_DEFINED_TAGS,
		STATEWIDE_TAGS,
		CONDITION_CHANGE_TAGS,
		RESEARCH_EXTENSION_TAGS,
		PROGRAM_AREA_TAGS,
		OUTCOME_TYPE_TAGS);

export const TAGS: Tag[] =
	Array.prototype.concat.apply([], [
		STRATEGIC_INITIATIVE_TAGS,
		USER_DEFINED_TAGS,
		STATEWIDE_TAGS,
		CONDITION_CHANGE_TAGS,
		PROGRAM_AREA_TAGS,
		OUTCOME_TYPE_TAGS]);

function sortedByName(tags: Tag[]): Tag[] {
	tags.sort((a,b) => 
		a.name < b.name 
			? 1 
			: b.name < a.name 
			? -1 
			: 0);
	return tags;
}
