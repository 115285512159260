import { CoreIssue } from '../../domain';

export const CORE_ISSUES: CoreIssue[] = [
    new CoreIssue( 1, "Childhood Obesity", false),
    new CoreIssue( 2, "Community Development", false),
    new CoreIssue( 3, "Family and Consumer Well Being", false),
    new CoreIssue( 4, "Human Nutritional Status", false),
    new CoreIssue( 5, "Youth Development", false),
    new CoreIssue( 6, "Agricultural Labor", false),
    new CoreIssue( 7, "Biosecurity", false),
    new CoreIssue( 8, "Food Safety", false),
    new CoreIssue( 9, "Food Security", false),
    new CoreIssue(10, "Organic Production", false),
    new CoreIssue(11, "Sustainability and Viability of Agriculture", false),
    new CoreIssue(12, "Waste Management", false),
    new CoreIssue(13, "Invasive Species", false),
    new CoreIssue(14, "Pest Management", false),
    new CoreIssue(15, "Air Quality", false),
    new CoreIssue(16, "Land Use", false),
    new CoreIssue(17, "Soil Quality", false),
    new CoreIssue(18, "Sustainable Use of Natural Resources", false),
    new CoreIssue(19, "Wildland Fire", false),
    new CoreIssue(20, "Water Quality", false),
    new CoreIssue(21, "Water Supply and Allocation", false),
    new CoreIssue(22, "Sustainable Energy", false),
    new CoreIssue(23, "Climate Change", false),
    new CoreIssue(24, "Other Programmatic Activity", false),
    new CoreIssue(25, "Administration", false)
]
