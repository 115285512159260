<div mat-dialog-title>Editing Multi-state Effort</div>
<mat-dialog-content>
  <div [formGroup]="form">
    <div class="row">
      <div class="col-md-6">
        <div class="row form-group">
          <div [class]="labelClass">
            Affiliated Project
          </div>
          <div [class]="controlClass">
            {{project.name}}
          </div>
        </div>

        <div class="row form-group">
          <div [class]="labelClass">
            Start of multi-state work
          </div>
          <div [class]="controlClass">
						<div class="input-group input-group-sm no-left-padding">
              <input class="form-control" formControlName="startDate" [matDatepicker]="startDatePicker" placeholder="mm/dd/yyyy">
							<mat-datepicker #startDatePicker startView="year" [touchUi]="touchEnabled"></mat-datepicker>
							<mat-datepicker-toggle [for]="startDatePicker" class="input-group-addon no-padding mat-icon-overide"></mat-datepicker-toggle>
						</div>
          </div>
        </div>

        <div class="row form-group">
          <div [class]="labelClass">
            Expected end of multi-state work
          </div>
          <div [class]="controlClass">
						<div class="input-group input-group-sm no-left-padding">
              <input class="form-control" formControlName="endDate" [matDatepicker]="endDatePicker" placeholder="mm/dd/yyyy">
							<mat-datepicker #endDatePicker startView="year" [touchUi]="touchEnabled"></mat-datepicker>
							<mat-datepicker-toggle [for]="endDatePicker" class="input-group-addon no-padding mat-icon-overide"></mat-datepicker-toggle>
						</div>
          </div>
        </div>

        <div class="row form-group">
          <div [class]="labelClass">
            Your estimated, yearly, multi-state FTE
          </div>
          <div [class]="controlClass">
            <div class="input-group">
              <input type="number" formControlName="fte"
                min="0" step="1" max="100" class="form-control"
                placeholder="FTE">
              <span class="input-group-addon">%</span>
            </div>
          </div>
        </div>
        
        <div class="row form-group">
          <div class="col-md-12">
            <label>
              One of the collaborators on this project is an
              <strong [matTooltip]="aesTooltipText">AES</strong> 
              academic:
            </label>
          </div>
          <div [class]="labelClass"></div>
          <div [class]="controlClass + ' pt-2'">
            <mat-radio-group formControlName="aes">
              <mat-radio-button [value]="true" color="primary">Yes</mat-radio-button>
              <mat-radio-button [value]="false" color="primary" class="ml-3">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
  
      <div class="col-md-6">
        <div class="card">
          <div class="card-block">
            <label>
              ANR Collaborators
            </label>
            <user-picker
              formControlName="ANRCollaborators"
              enableNonANRCollaborator="true"
              (newNonAnrCollaboratorRequest)="nonANRCollaborators.create($event)"
              controlClass="form-control-sm"></user-picker>
          </div>
        </div>

        <div class="card mt-3">
          <div class="card-block">
            <button class="btn btn-sm btn-secondary pull-right" (click)="nonANRCollaborators.create()">
              + Add New
            </button>
            <label matTooltip="Minimum reporting requirements: 1 C.E. or A.E.S. academic from an out-of-state institution">
              Non-ANR Collaborators
              <i class="fa fa-fw fa-info-circle text-muted"></i>
            </label>
            <non-anr-collaborator-form-control 
              formControlName="NonANRCollaborators"
              #nonANRCollaborators="NonANRCollaboratorFormControl"></non-anr-collaborator-form-control>
          </div>
        </div>
      </div>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
	<button (click)="endEffort()" class="btn btn-danger float-left" [disabled]="closing" *ngIf="canEndEffort()">
		End this multi-state effort
	</button>
	<button (click)="editProject()" class="btn btn-link float-left" [disabled]="closing" *ngIf="canEditProject()">
		Edit this project
	</button>
	<button (click)="cancel()" class="btn btn-link" [disabled]="closing">
		Cancel
	</button>
	<button (click)="save()" class="btn btn-primary" [disabled]="closing">
		Save
	</button>
</mat-dialog-actions>