<div class="alert alert-info" *ngIf="hasInstructions()">
	<span [innerHTML]="getInstructions()"></span>
</div>

<div *ngIf="!fte">
	Loading...
</div>
<div *ngIf="!!fte">
	<!-- <h5 class="my-4">
		Looking back on the 
		<current-state-fiscal-year></current-state-fiscal-year>
		state fiscal year
	</h5> -->
	<div class="row">
		<div class="col-md-5" *ngIf="!!fte.county.length">
			<div class="card">
				<div class="card-header">
					<button class="btn btn-sm btn-link float-right" (click)="editCounty()">
						<i class="fa fa-fw fa-pencil"></i>
						Edit
					</button>
					County FTE
					<!-- <small class="ml-3">
						(<current-state-fiscal-year dateFormat="longDate" separator=" through "></current-state-fiscal-year>)
					</small> -->
				</div>
				<table class="table table-bordered table-flush">
					<thead>
						<tr>
							<th>County</th>
							<th class="text-center">Yearly&nbsp;FTE</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let a of fte.county">
							<td>
								{{a.county.name}}

								<i class="fa fa-fw fa-home"
									[hidden]="!a.isHeadquarters"
									title="Headquarters"></i>

								<i class="fa fa-fw fa-user" 
									[hidden]="!a.isDirector"
									title="County Director"></i>
							</td>
							<td class="text-center"> {{formattedFte(a.fte)}} </td>
						</tr>
						<tr>
							<td colspan="2">
								<academic-report-submission 
									type="CountyFTE"
									reportName="FTE"
									calendarName="state fiscal year"
									[dueDateWarning]="fteDueDateWarning"
									[disabled]="countyFteSum() < .999"
									btnClass="btn-block"
									[textType]=academicReportSubmissionTextType></academic-report-submission>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	
		<div class="col-md-7">
			<div [ngSwitch]="!!fte.conditionChange.length">
				<div *ngSwitchCase="false" class="card">
					<empty-state
						imgSrc="/assets/empty/target.png"
						title="You haven't declared any Condition Change FTE"
						description="Why not do that now?">
							<button class="btn btn-primary btn-sm"
								(click)="editConditionChange()">
								+ Edit Condition Change FTE
							</button>
					</empty-state>
				</div>
				<div *ngSwitchCase="true" class="card">
					<div class="card-header">
						<button class="btn btn-sm btn-link float-right" (click)="editConditionChange()">
							<i class="fa fa-fw fa-pencil"></i>
							Edit
						</button>
						Condition Change FTE
						<!-- <small class="ml-3">
							(<current-state-fiscal-year dateFormat="longDate" separator=" through "></current-state-fiscal-year>)
						</small> -->
					</div>
					<div class="card-block">
						<div class="text-sm text-muted">
							Condition changes represent broad environmental, health, or economic benefits at a societal level, recognizing that UC ANR may be only one contributor towards these long-term outcomes.
						</div>
					</div>
					<table class="table table-bordered table-flush-except-top">
						<thead>
							<tr>
								<th>Program Area</th>
								<th>Condition Change</th>
								<th>Yearly&nbsp;FTE</th>
							</tr>
						</thead>
						<tbody class="text-sm">
							<ng-container *ngFor="let f of fte.conditionChange">
								<tr *ngFor="let cc of mapKeys(f.pairs); let first = first;">
									<td *ngIf="first" [attr.rowspan]="f.pairs.size"> 
										{{f.programArea.name}} <div *ngIf="f.programArea.disabled" class="text-xs">(No longer active)</div>
									</td>
									<td>{{cc.name}}</td>
									<td class="text-center">{{formattedFte(f.pairs.get(cc))}}</td>
								</tr>
							</ng-container>
							<tr>
								<td colspan="3">
									<academic-report-submission 
										type="ConditionChangeFTE"
										reportName="FTE"
										calendarName="state fiscal year"
										[dueDateWarning]="fteDueDateWarning"
										btnClass="btn-block"
										[textType]=academicReportSubmissionTextType></academic-report-submission>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

		</div>
	</div>

	<div [ngSwitch]="!!fte.projects.length">
		<h5 class="my-4">Planning multi-state effort for the future</h5>
		<div *ngSwitchCase="false" class="card">
			<empty-state
				imgSrc="/assets/empty/states.png"
				title="You haven't started any projects"
				description="Would you like to start one now?">
					<button class="btn btn-primary btn-sm"
						(click)="newProject()">
						+ Add a Project
					</button>
			</empty-state>
		</div>
		<div class="card" *ngSwitchCase="true">
			<div class="card-header">
				<button class="btn btn-sm btn-secondary float-right" (click)="newProject()">
					+ Add a Project
				</button>
				Multi-state Effort
			</div>
			<div class="card-block text-sm">
				Multi-state effort involves Cooperative Extension academics from multiple states.
				<br>
				The information you provide here about any multi-state collaborations will assist in maintaining ANR&apos;s federal funding.
			</div>
			<table class="table table-bordered table-flush" style="border-top:1px solid #ddd;">
				<thead>
					<tr>
						<th rowspan="2">Project</th>
						<th colspan="2" class="text-muted text-sm text-center">Collaborators</th>
						<th rowspan="2" style="width:125px;">Multi-state?</th>
						<th rowspan="2" style="width:85px;" class="text-center" [matTooltip]="aesTooltipText">
							AES
							<i class="fa fa-fw fa-info-circle text-muted text-sm"></i>
						</th>
						<th rowspan="2" class="text-center">Multi-state&nbsp;FTE</th>
						<th rowspan="2" style="width:130px;">Start&nbsp;Date</th>
						<th rowspan="2" style="width:130px;">End&nbsp;Date</th>
						<th rowspan="2">&nbsp;</th>
					</tr>
					<tr>
						<th class="text-center" style="width:70px">ANR</th>
						<th class="text-center" style="width:100px;">Non-ANR</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let p of fte.projects">
						<td>{{p.name}}</td>
						<td class="text-center" [matTooltip]="p.ANRCollaboratorsTooltip">{{p.ANRCollaborators.length}}</td>
						<td class="text-center" [matTooltip]="p.NonANRCollaboratorsTooltip">{{p.NonANRCollaborators.length}}</td>
						<ng-container [ngSwitch]="p.multiStateInfo.hasValue()">
							<ng-container *ngSwitchCase="true">
								<td class="text-center">Yes</td>
								<td>{{p.multiStateInfo.value.aes ? 'Yes' : 'No'}}</td>
								<td class="text-center">{{formattedFte(p.multiStateInfo.value.fte)}}</td>
								<td>{{p.multiStateInfo.value.startDate | date}}</td>
								<td>{{p.multiStateInfo.value.endDate | date}}</td>
							</ng-container>
							<ng-container *ngSwitchCase="false">
								<td class="text-center">No</td>
								<td class="table-muted text-muted">N/A</td>
								<td class="table-muted text-muted">N/A</td>
								<td class="table-muted text-muted">N/A</td>
								<td class="table-muted text-muted">N/A</td>
							</ng-container>
						</ng-container>
						<td>
							<button class="btn btn-sm btn-link" 
							(click)="editProject(p)">
								Edit
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>