import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConsoleService } from '../../widgets/console.service';
import { HumanResources, SortedSet, User, HRUnit, Title, AHRService, PayrollCampus, AcademicStatus, HRAcademic, CreateAcademic, IdAndName, AcademicStatuses } from '../../domain';
import { AHRTerms } from '../ahr/terms';
import { TitleRankAndStepSelector } from '../ahr/title-rank-and-step-selector';

@Component({
	selector: 'ahr-onboarding-modal',
	templateUrl: './ahr-onboarding-modal.component.html',
	styleUrls: ['./ahr-onboarding-modal.component.scss']
})
export class AHROnboardingModalComponent {
	title: string = "New Academic";
	titles: Title[] = [];
	campuses: PayrollCampus[] = [];
	statuses: IdAndName[] = [];
	closing = false;
	changed = false;
	form: FormGroup;
	terms = AHRTerms.terms;
	titleRankAndStep: TitleRankAndStepSelector;
	academics: HRAcademic[] = [];
	HumanResources = HumanResources;

	constructor(
		private readonly svc: AHRService,
		public dialogRef: MatDialogRef<AHROnboardingModalComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private snackbar: MatSnackBar,
		fb: FormBuilder,
		private console: ConsoleService
	) { 
		if (!!data.title){
			this.title = data.title;
		}
		if (!!data.acadmeics) {
			this.academics = data.academics;
		} else {
			this.svc.getAllAcademics().then(allAcademics => this.academics = allAcademics);
		}

		this.statuses = AcademicStatuses;

		this.titles = this.svc.getAllTitlesRanksAndSteps();
		this.titleRankAndStep = new TitleRankAndStepSelector(this.titles, 0, 0, 0);

		this.svc.getCampuses().then(cs => this.campuses = cs);

		this.form = fb.group({
			user: new SortedSet<User>(),
			statusId: AcademicStatus.Active,
			titleId: 0,
			rankId: 0,
			stepId: 0,
			termId: 0,
			totalFte: 0,
			payrollCampusId: 3,
			hr: HumanResources.ANR,
			supervisors: new SortedSet<User>(),
			appointmentUnits: new SortedSet<HRUnit>()
		});

		this.form.valueChanges.subscribe(_ => {
			this.console.log('form value changed', this.form.value);
			this.validateUser();
			this.changed = true;
		});
	}

	save(){
		this.closing = true;
		if(this.form.valid){
			let academic = (<SortedSet<User>>this.form.controls['user'].value);
			let termId = this.form.controls['termId'].value;
			this.titleRankAndStep = new TitleRankAndStepSelector(
				this.titles,
				this.form.controls['titleId'].value,
				this.form.controls['rankId'].value,
				this.form.controls['stepId'].value
			);

			let cmd = new CreateAcademic(
				(academic.count > 0) ? academic.toArray()[0].id : 0,
				this.titleRankAndStep.stepId,
				termId == "null" ? null : <number>termId,
				this.form.controls['supervisors'].value.map((s: User) => s.id),
				this.form.controls['appointmentUnits'].value.map((u: HRUnit) => u.id),
				0,
				this.form.controls['statusId'].value,
				this.form.controls['payrollCampusId'].value,
				this.form.controls['hr'].value
			);
			
			/*
			cmd.stepId = this.titleRankAndStep.stepId;
			cmd.supervisorUserIds = this.form.controls['supervisors'].value.map((s: User) => s.id);
			cmd.appointmentUnitIds = this.form.controls['appointmentUnits'].value.map((u: HRUnit) => u.id);
			*/
			this.svc.execute(cmd).then(
				result => result.matchDo(
					_ => 
					{ // success
						this.snackbar.open("Academic Added ");
						this.dialogRef.close();
					},
					errorMsg => 
					{ //failure
						this.snackbar.open("Unable to save changes: " + errorMsg, null, {duration:10000});
						this.closing = false;
					}
				),
				errorMsg =>
				{
					this.snackbar.open("Unable to save changes: " + errorMsg, null, {duration:10000});
					this.closing = false;
				}
			);
		}
	}

	cancel(){
		if(this.changed && window.confirm('You may have unsaved changes. Save changes?'))
		{
			this.save();
		} else {
			this.dialogRef.close();
		}
	}

	changeTitle(): void {
		this.titleRankAndStep = new TitleRankAndStepSelector(
			this.titles,
			this.form.controls['titleId'].value,
			null,
			null
		);
		this.changeRank();
	}

	changeRank(): void {
		this.titleRankAndStep = new TitleRankAndStepSelector(
			this.titles,
			this.form.controls['titleId'].value,
			this.form.controls['rankId'].value,
			null
		);
	}

	validateUser() {
		let userSet = (<SortedSet<User>>this.form.controls['user'].value);
		if (userSet.count >= 1){
			let userId = userSet.toArray()[0].id;
			let match = this.academics.findIndex(ac => ac.userId == userId);
			if (match > -1) { 
				this.form.controls['user'].setValue(new SortedSet<User>());
				this.snackbar.open("Selected User is already an Academic. Cannot select the user twice.", null, {duration:10000});
			}
		}
	}

	filterAcademics(){
		return this.academics.map(a => a.userId);
	}

}
