import { Component, Inject, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DossierService } from '../../domain';
import { CreateTheme } from '../../domain/types/dossier';
import { TooltipService } from '../../widgets/tooltip.service';

@Component({
	selector: 'app-new-theme-modal',
	templateUrl: './new-theme-modal.component.html',
	styleUrls: ['./new-theme-modal.component.scss']
})
export class NewThemeModalComponent {
	public StaticText: any;
	closing = false;
	theme = new CreateTheme('');
	constructor(
		private svc: DossierService,
		public dialogRef: MatDialogRef<NewThemeModalComponent>,
		public tooltipSvc: TooltipService,
		@Inject(MAT_DIALOG_DATA) private data: any,
	   	private snackBar: MatSnackBar) {
			   
		this.StaticText = tooltipSvc.StaticText;

	}

	@HostListener('keydown.enter')
	save(){
		this.closing = true;
		this.svc.createTheme(this.theme).then(result =>
			result.matchDo(
				newThemeId => {
					this.snackBar.open('Created', null, {duration: 3000});
					this.dialogRef.close('saved');
				},
				errorMsg => {
					this.snackBar.open('Theme not created: ' + errorMsg, null, {duration: 10000});
					this.closing = false;
				}), 
			errorMsg => {
				this.snackBar.open('Theme not created: ' + errorMsg, null, {duration: 10000});
				this.closing = false;
			});
	}

	@HostListener('keydown.escape')
	cancel(){
		this.closing = true;
		this.dialogRef.close('cancelled');
	}
}
