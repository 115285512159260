import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'pop-over',
	templateUrl: './pop-over.component.html',
	styleUrls: ['./pop-over.component.scss']
})
export class PopOverComponent implements OnInit {

	menuOpen: boolean = false;

	constructor() { }

	ngOnInit() {
	}

	toggleMenu() {
		if(this.menuOpen) this.menuOpen = false;
		else this.menuOpen = true;
	}

}
