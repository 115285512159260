import { Component } from '@angular/core';
import { AppVersionService } from '../../domain';

@Component({
  selector: 'anr-footer',
  templateUrl: './anr-footer.component.html',
  styleUrls: ['./anr-footer.component.scss']
})
export class AnrFooterComponent {
	year: number = 0;
	constructor(public app: AppVersionService) 
	{
		this.year = new Date().getFullYear();
	}
}