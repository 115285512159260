import { Component, OnInit } from '@angular/core';
import { AHRService } from '../../domain/data-access-interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'ahr-settings',
  templateUrl: './ahr-settings.component.html',
  styleUrls: ['./ahr-settings.component.scss']
})
export class AHRSettingsComponent implements OnInit {

  settings = [];

  constructor(
    private readonly svc: AHRService
    ,private readonly sb: MatSnackBar
  ) { }

  ngOnInit() {
    this.svc.getSettings().then(settings => {
      this.settings = settings;
    });
  }

  changeCandidateResults(s,visible){
    if(!window.confirm('Are you sure?')){
      return;
    }
    let ss = {...s, resultsVisibleToCandidates: visible};
    this.svc.saveSettings(ss).then(result => 
      result.match(() => {
        this.sb.open("Settings saved", null, {duration: 3000});
        s.resultsVisibleToCandidates = visible;
      },
      error => {
        this.sb.open("Settings not saved: " + error, null, {duration: 3000});
      }));
  }
}