export class ClienteleContactCollection {
	constructor(private _contacts: Map<string,number>) 
	{ }

	public toMap(): Map<string,number> {
		return duplicateMap(this._contacts);
	}

	public toObject(): any {
		return toObject(this._contacts);
	}

	public any(): boolean {
		return Array.from(this._contacts.values()).some(n => n > 0);
	}

	public get(key: string): number {
		return this._contacts.get(key);
	}

	public equals(other: ClienteleContactCollection): boolean {
		if(this._contacts.size != other._contacts.size){
			return false;
		}
		for(let k in this._contacts){
			if(this._contacts[k] != other._contacts[k]){
				return false;
			}
		}
		return true;
	}

	public duplicate(): ClienteleContactCollection {
		return new ClienteleContactCollection(this.toMap());
	}

	public static blank(){
		return new ClienteleContactCollection(new Map<string,number>());
	}

	public static fromMap(m: Map<string,number>): ClienteleContactCollection {
		return new ClienteleContactCollection(duplicateMap(m));
	}

	public static fromObject(o: any): ClienteleContactCollection {
		return new ClienteleContactCollection(toMap(o));
	}

	public static fromJson(o: any): ClienteleContactCollection {
		return new ClienteleContactCollection(toMap(o));
	}
}

function duplicateMap(a: Map<string,number>): Map<string,number> {
	let b = new Map<string, number>();
	for(let k of Array.from(a.keys())){
		b.set(k, a.get(k));
	}
	return b;
}

function toObject(a: Map<string,number>): any {
	let b = {};
	for(let k of Array.from(a.keys())){
		b[k] = a.get(k);
	}
	return b;
}

function toMap(a: any): Map<string, number> {
	let b = new Map<string,number>();
	for(var k in a){
		b.set(k.toString(), a[k]);
	}
	return b;
}
