export class ToolTip {
    constructor(
        public id: string,
        public name: string
    ) { }

	public static fromJson(o: any): ToolTip {
		return new ToolTip(
			<string>o.id,
			<string>o.name);
	}
}
