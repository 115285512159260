import { Component, Pipe, PipeTransform } from '@angular/core';
import { AHRService, CircularArray, Option } from '../../../domain';
import { Case, ActionType, CaseState, ActionSituation, Action, AllExceptionalTimings, ExceptionalTiming, Position } from '../../../domain/types/ahr';
import { ConsoleService } from '../../../widgets/console.service';
import { MatDialog } from '@angular/material/dialog';
import { CaseModalComponent, CaseModalNavigator } from '../case-modal/case-modal.component';
import * as cs from '../../../widgets/column-sort';
import { TitleRankStepTermPipe } from '../title-rank-step-term.pipe';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { Subject, combineLatest } from '../../../../../node_modules/rxjs';
import { map, startWith, filter, take, skip } from 'rxjs/operators';
import { ProgressionTableWorkshopModalComponent } from '../progression-table-workshop-modal/progression-table-workshop-modal.component';
import { NewAcademicModalComponent } from '../new-academic-modal/new-academic-modal.component';
import { ReviewRequirementsWorkshopModalComponent } from '../review-requirements-workshop-modal/review-requirements-workshop-modal.component';
import { GoogleAnalyticsService } from '../../../main-core/google-analytics.service';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';

type CaseSortFn = (cases:Case[]) => Case[];
interface PositionName {
	hid: string,
	name: string
}

@Pipe({
  name: 'caseState'
})
export class CaseStatePipe implements PipeTransform {
	transform(value: any, ...args: any[]) {
		let state = <CaseState> value;
		switch(state){
			case CaseState.AwaitingProposal:
				return 'Awaiting Proposal';
			case CaseState.Complete:
				return 'Complete';
			case CaseState.ReadyToSendToCandidate:
				return 'Ready to send to candidate'
			case CaseState.WithCandidate:
				return 'With candidate';
			case CaseState.InAppeal:
				return 'In appeal';
			case CaseState.ReadyToApproveDeferral:
				return 'Ready To Approve Deferral';
			case CaseState.NotApplicable:
				return 'N/A';
			default:
				return '';
		}
	}
}

@Component({
	selector: 'case-list',
	templateUrl: './case-list.component.html',
	styleUrls: ['./case-list.component.scss']
})
export class CaseListComponent {
	private userToken;
	private userClaims;
	ActionType = ActionType;
	ActionSituation = ActionSituation;
	ExceptionalTiming = ExceptionalTiming;
	AllExceptionalTimings = AllExceptionalTimings;
	CaseState = CaseState;
	AllPositions: PositionName[] = [];
	AllCaseStates = 
    [
		CaseState.AwaitingProposal,
		CaseState.ReadyToSendToCandidate,
		CaseState.WithCandidate,
		CaseState.Complete, 
		CaseState.InAppeal,
	    CaseState.ReadyToApproveDeferral,
		CaseState.NotApplicable
	];
	AllActionTypes = [
		ActionType.Merit,
		ActionType.Promotion,
		ActionType.AnnualEvaluation,
		ActionType.Goals,
		ActionType.Deferral,
		ActionType.FiveYearReview,
		ActionType.NotApplicable
	];
	AllProposals: Option<ActionType>[] = 
		this.AllActionTypes
			.map(Option.create)
			.concat([Option.create<ActionType>()]);
	AllActionSituations = [
		{
			label: "Normal",
			value: ActionSituation.Normal
		},
		{
			label: "Acceleration",
			value: ActionSituation.Acceleration
		},
		{
			label: "13-month rule",
			value: ActionSituation.MonthRule13
		},
		{
			label: "24-month rule",
			value: ActionSituation.MonthRule24
		},
		{
			label: "18-month rule",
			value: ActionSituation.MonthRule18
		},
		{
			label: "30-month rule",
			value: ActionSituation.MonthRule30
		}		
	];
	allCases: Case[] = [];
	cases: Case[] = [];
	private _currentSorting: cs.Sorting<Case,any>;
	private sortFn: Subject<CaseSortFn> = new Subject(); 

	get currentSorting(){
		return this._currentSorting;
	}
	set currentSorting(sorting){
		this._currentSorting = sorting;
		this.sortFn.next(cases => cs.Sort(cases, sorting));
	}

	get filterCountState(){
		let f = (<FormGroup>this.filterForm.controls['state']);
		let count = 0;
		for(let k in f.value){
			if(f.value[k]){
				count++;
			}
		}
		return count;
	}

	get filterCountEligibility(){
		let f = (<FormGroup>this.filterForm.controls['eligibility']);
		let count = 0;
		for(let k in f.value){
			if(f.value[k]){
				count++;
			}
		}
		return count;
	}

	get filterCountProposal(){
		return (<FormArray>this.filterForm.controls['proposal'])
		.value
		.filter(v => !!v)
		.length;
	}

	get filterCountExceptionalTiming(){
		return (<FormArray> this.filterForm.controls['exceptionalTiming'])
		.value
		.filter(v => !!v)
		.length;
	}

	ngOnInit(){
		setTimeout(() => 
			//this.openProgressionTableWorkshopModal(),
			//this.openReviewRequirementsWorkshopModal(),
		0);
	}

	openNewAcademicModal(){
		let modal = NewAcademicModalComponent.open(this.matDialog);
		let newUserId = modal.afterClosed();
		/* It's convenient for the user to see the newly-added academic in the details modal
		** immedately after saving.  To do this, we have to await a combination of new values. 
		** The existing 'cases' value must be ignored, if the next 'latest' value is to contain
		** the new case. */
		combineLatest(
			this.svc.cases.pipe(skip(1)),
			newUserId
		)
		.pipe(take(1))
		.subscribe(([cases,userId] : [Case[], number]) => {
			if(!userId){
				this.console.log('New academic modal closed without new acadmic being created.');
				return;
			}
			this.console.log('New academic: userId=' + userId);
			this.gaService.eventEmitter("Academic Added", "Academic", "Create");
			var newCase = cases.filter(c => c.userId == userId)[0];
			if(!newCase){
				this.console.log("New academic's case not found");
				return;
			}
			this.viewDetails(newCase);
		});
	}

	openProgressionTableWorkshopModal() {
		ProgressionTableWorkshopModalComponent.open(this.matDialog);
	}

	openReviewRequirementsWorkshopModal() {
		ReviewRequirementsWorkshopModalComponent.open(this.matDialog);
	}

	wfaSSO() {
		this.svc.wfaSSO(false);
	}

	filterForm: FormGroup;

	sortColumns = {
		name: cs.Column.withGetter<Case,string>(
			'name',   
			c => (c.userNameLastFirst || 'zzz').toLowerCase(),
			'Name',          
			cs.Direction.asc),
		position: cs.Column.withGetter<Case,string>(
			'position',   
			c => (TitleRankStepTermPipe.PositionToTitleRankStepTerm(c.currentPosition) || 'zzz').toLowerCase(),
			'Current Position',          
			cs.Direction.asc),
		state: cs.Column.withGetter<Case,string>(
			'state',   
			c => c.state.toString().toLowerCase(),
			'Status',          
			cs.Direction.asc),
		eligibleFor: cs.Column.withGetter<Case,string>(
			'eligibleFor',   
			c => (c.eligibleActions.map(a => a.type.toString()).join(", ") || 'zzz').toLowerCase(),
			'Eligible For',          
			cs.Direction.asc),
		proposed: cs.Column.withGetter<Case,string>(
			'proposed',   
			c => (c.proposedAction.map(a => a.type).getValueOrDefault(null) || 'zzz').toLowerCase(),
			'Proposed Action',          
			cs.Direction.asc),
		supervisorCount: cs.Column.withGetter<Case,number>(
			'supervisorCount',   
			c => c.supervisors.length,
			'Supervisors',          
			cs.Direction.asc),
		programYear: cs.Column.withGetter<Case,number>(
			'programYear',   
			c => c.yearId,
			'Program Year',          
			cs.Direction.asc),
	}

	constructor(
		private svc: AHRService,
		private console: ConsoleService,
		private matDialog: MatDialog,
		private gaService: GoogleAnalyticsService,
		private fb: FormBuilder,
		private readonly datePipe: DatePipe,
	) { 
		this.AllPositions = (() => 
		{
			var ps: PositionName[] = [
				{hid: null, name: 'All Positions'}
			];
			svc.getAllTitlesRanksAndSteps()
			.forEach(t => {
				ps.push({
					hid: t.id.toString(),
					name: t.name
				});
				t.ranks.forEach(r => {
					ps.push({
						hid: `${t.id}/${r.id}`,
						name: `${t.name} / ${r.name}`
					});
					r.steps.forEach(s => {
						ps.push({
							hid: `${t.id}/${r.id}/${s.id}`,
							name: `${t.name} / ${r.name} / ${s.name}` 
						});
					});
				});
			});
			return ps;
		})();

		this.filterForm = (() => {
			var eligibility = this.fb.group({});
			this.AllActionTypes.forEach(a => {
				eligibility.addControl(ActionType[a], new FormControl(true));
			});
			var proposal = this.fb.array(this.AllProposals.map(_ => true));
			var state = this.fb.array(this.AllCaseStates.map(_ => true));
			var proposalActionSituation = this.fb.array(this.AllActionSituations.map(_ => true));
			var exceptionalTiming = this.fb.array(AllExceptionalTimings.map(_ => true));
			return this.fb.group({
				userName: null,
				eligibility: eligibility,
				proposal: proposal,
				state: state,
				currentPositionHid: null,
				proposalActionSituation: proposalActionSituation,
				exceptionalTiming: exceptionalTiming,
				term: null
			});
		})();

		let filterChanges = this.filterForm.valueChanges
			.pipe(startWith(this.filterForm.value))
			.pipe(map(value => {
				let eligibleActionTypes = this.AllActionTypes.filter(a => value.eligibility[ActionType[a]]);
				let proposedActionTypes = this.AllProposals.filter((p,index) => value.proposal[index]);
				let states = this.AllCaseStates.filter((s,index) => value.state[index]);
				let proposalActionSituation = this.AllActionSituations
					.filter((s,index) => value.proposalActionSituation[index])
					.map(s => s.value);
				let exceptionalTiming = 
					AllExceptionalTimings
					.filter((_,index) => value.exceptionalTiming[index])
					.map(t => t.value);
				return {
					userName: value.userName,
					eligibleActionTypes,
					proposedActionTypes,
					states,
					proposalActionSituation,
					currentPositionHid: value.currentPositionHid,
					exceptionalTiming,
					term: value.term
				};
		}));

		combineLatest(
			this.svc.cases,
			filterChanges,
			this.sortFn)
		.subscribe(([cases, filters, sortFn]) => {
			this.allCases = cases;
			//this.console.log('cases:' , cases);
			this.console.log('filters: ', filters);
			let eligibleActionTypes = new Set(filters.eligibleActionTypes);
			let exceptionalTiming = new Set(filters.exceptionalTiming);
			let term = filters.term as string;
			let states = new Set(filters.states);
			this.cases = sortFn(this.allCases
				.filter(c => !filters.currentPositionHid || c.currentPosition.stepHid.indexOf(filters.currentPositionHid) == 0)
				.filter(c => states.has(c.state))
				.filter(c => (c.eligibleActions.some(a => eligibleActionTypes.has(a.type)) || (this.isNonAcademic(c) && eligibleActionTypes.has(ActionType.NotApplicable))))
				.filter(c => (filters.proposedActionTypes.some(p => p.equals(c.proposedAction.map(a => a.type)) || (this.isNonAcademic(c) && p.equals(Option.create(ActionType.NotApplicable))))))
				.filter(c => c.proposedAction.match(
					() => true,// Cases without proposals should not be discarded by this filter alone.
					(p: Action) => p.exceptionalTiming.match(
						() => exceptionalTiming.has(null),
						(t: ExceptionalTiming) => exceptionalTiming.has(t)))
				)
				.filter(c => {
					if(!filters.term){
						return true;
					}
					return c.proposedAction.match( // Filter by term or "Indefinite Status"
						() => true,// Cases without proposals should not be discarded by this filter alone.
						(a: Action) => a.endingPosition.term == term
					)
				})
				.filter(c => {
					if(!filters.userName){
						return true;
					}
					let name = c.userName.toLowerCase();
					let term = filters.userName.toLowerCase();
					if(name.indexOf(term) > -1){
						return true;
					} 
					let name_lf = c.userNameLastFirst.toLowerCase();
					return name_lf.indexOf(term) > -1;
				}));
		});

		//This init of sorting must occur AFTER subscribing!
		this.currentSorting = this.sortColumns.name.getDefaultSorting();
	}

	resetFilters(){
		var eligibility = {};
		this.AllActionTypes.forEach(a => eligibility[ActionType[a]] = true);

		return this.filterForm.setValue({
			userName: null,
			eligibility: eligibility,
			proposal: this.AllProposals.map(_ => true),
			state: this.AllCaseStates.map(_ => true),
			proposalActionSituation: this.AllActionSituations.map(_ => true),
			exceptionalTiming: this.AllExceptionalTimings.map(_ => true),
			currentPositionHid: null,
			term: null
		});
	}

	filterForState(state: CaseState){
		let newValues = this.AllCaseStates.map(s => state == s);
		this.console.log('newStateControlValues:', newValues);
		(<FormArray>this.filterForm.controls['state']).setValue(newValues);
	}

	filterPosition(hid: string){
		this.filterForm.controls['currentPositionHid'].setValue(hid);
	}

	filterEligibility(actionType: ActionType = null){
		let newValues = {};
		this.AllActionTypes.forEach(at => newValues[ActionType[at]] = at == actionType);
		this.console.log('newEligibilityControlValues:', newValues);
		(<FormGroup>this.filterForm.controls['eligibility']).setValue(newValues);
	}

	filterForExceptionalTiming(t: ExceptionalTiming){
		let newValues = this.AllExceptionalTimings.map(et => et.value == t);
		this.console.log('new exceptionalTiming values:', newValues);
		(<FormArray>this.filterForm.controls['exceptionalTiming']).setValue(newValues);
	}

	filterForTerm(term: string){
		this.filterForm.controls['term'].setValue(term);
	}

	exceptionalTimingLabel(t: ExceptionalTiming){
		switch(t){
			case ExceptionalTiming.Acceleration: return 'accelerated';
			case ExceptionalTiming.MonthRule13: return '13-mo rule';
			case ExceptionalTiming.MonthRule24: return '24-mo rule';
			case ExceptionalTiming.MonthRule18: return '18-mo rule';
			case ExceptionalTiming.MonthRule30: return '30-mo rule';
		}
	}

	filterForProposedAction(actionType: ActionType = null){
		let newValues = this.AllProposals.map(at => 
			at.match(
				() => !actionType,
				(at: ActionType) => at == actionType));
		this.console.log('newProposalControlValues:', newValues);
		(<FormArray>this.filterForm.controls['proposal']).setValue(newValues);
	}

	viewDetails(c: Case): void{
		this.gaService.eventEmitter("View Case Details", "Case", "View");
		var cases = new CircularArray(this.cases);
		cases.moveTo(c);
		var modal = CaseModalComponent.open(this.matDialog, c, new CaseModalNavigator(
			() => cases.hasLinearPrev(),
			() => cases.hasLinearNext(),
			modalInstance => {
				//alert('prev');
				this.console.log(modalInstance);
				cases.movePrev();
			},
			modalInstance => {
				//alert('next');
				this.console.log(modalInstance);
				cases.moveNext();
			}));

		cases.changes.subscribe(newCase => {
			modal.componentInstance.viewCase(newCase);
		})	
	}

	public IsIndefiniteStatusOnly(c: Case){
		var proposedAction = c.proposedAction.getValueOrDefault(null);
		return Position.titleRankStepEqual(c.currentPosition, proposedAction.endingPosition)
			&& this.indefiniteStatusProposed(c);
	}

	public indefiniteStatusProposed(c: Case) {
		var proposedAction = c.proposedAction.getValueOrDefault(null);
		return c.currentPosition.term != 'Indefinite Status' && proposedAction.endingPosition.term == 'Indefinite Status';
	}

	public exportAcademicsToExcel(){
		this.svc.downloadAcademicExcel();
	}	

	public exportWFAreportToExcel(){
		this.svc.downloadWFAReportExcel();
	}

	public exportEligibilityListToExcel(){
        let caseList = this.cases.map(x=> {
           const caseObj:any ={
             candidateName: x.userName,
			 candidateEmail: x.userEmail,
			 candidateTitle: x.currentPosition?.label,
			 candidateEligibleActions: x.eligibleActions?.map( y=> y.type === 'Goals' ?  y.type +':N/A' :
				                       y.type + `:`+this.datePipe.transform(y.truePeriodOfReview.value?.start, 'MMMM d, yyyy')+`-`
									  +this.datePipe.transform(y.truePeriodOfReview.value?.end, 'MMMM d, yyyy')).join(', '),
			 supervisorList: x.supervisors?.map(p => p.details?.name +`:`+ p.details?.emailAddress).join(', ')
		   }
			return caseObj;
		});
        let fileName = `Eligibility List.xlsx`;
		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(caseList);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Eligibility List');
		XLSX.writeFile(wb, fileName);
	}

	public isNonAcademic(c: Case) : boolean {
		const nonAcademicTitle = this.svc.getAllTitlesRanksAndSteps().find(t => t.name === 'Staff Director');
		return c.currentPosition.id.titleId === nonAcademicTitle?.id;
	}
}