import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../app-settings';

import {
   	ClienteleGroupDetail,
	HistoricalClienteleGroup,
   	ClienteleGroupDto,
   	ClienteleGroupSource,
   	DemographicInfo,
	DemographicType,
   	Result,
	ClienteleService as Svc,
	County,
	ComplianceNotesDto
} from '../domain';
import { ConsoleService } from '../widgets/console.service';

@Injectable()
export class ClienteleService implements Svc {
	private _demoInfo: DemographicInfo;
	constructor(
		private http: HttpClient, 
		private appSettings: AppSettings,
		private console: ConsoleService){ }

	public config(): Promise<null>{
		return this.http.get(this.appSettings.APIURL + '/clientele/demographics')
			.toPromise()
			.then(data => {
				let demoInfo = new DemographicInfo(data as DemographicType[]);
				this._demoInfo = demoInfo;
				return null;
			});
	}

	public saveComplianceNotes(clienteleGroupId: number, data: ComplianceNotesDto): Promise<Result<null,string>>{
		return this.http.put(this.appSettings.APIURL + '/clientele/group/' + clienteleGroupId + '/compliance_notes', data)
			.toPromise()
			.then(json => Result.fromJson<null,string>(
				json,
				o => null,
				o => <string>o));
	}

    public getClienteleGroups(): Promise<ClienteleGroupDetail[]>{
		return this.http.get(this.appSettings.APIURL + '/clientele/group')
			.toPromise()
			.then(json => {
				return (<any[]> json).map(ClienteleGroupDetail.fromJson);
			});
	}

	public getHistoricalClienteleGroups(): Promise<HistoricalClienteleGroup[]>{
		return this.http.get(this.appSettings.APIURL + '/clientele/historical_groups')
			.toPromise()
			.then(json => {
				return (<any[]> json).map(HistoricalClienteleGroup.fromJson);
			});
	}	

	public getHistoricalClienteleGroupDetail(year: number): Promise<ClienteleGroupDetail[]>{
		return this.http.get(this.appSettings.APIURL + '/clientele/historical_group_detail/' + year)
			.toPromise()
			.then(json => {
				return (<any[]> json).map(ClienteleGroupDetail.fromJson);
			});
	}	

    public createGroup(data: ClienteleGroupDto): Promise<Result<number,string>>{
		this.console.log(data);
		return this.http.post(this.appSettings.APIURL + '/clientele/group', data)
			.toPromise()
			.then(json => Result.fromJson<number,string>(
				json,
				o => <number>o,
				o => <string>o));
	}

    public updateGroup(id: number, data: ClienteleGroupDto): Promise<Result<number,string>>{
		this.console.log(data);
		return this.http.put(this.appSettings.APIURL + '/clientele/group/' + id, data)
			.toPromise()
			.then(json => Result.fromJson<number,string>(
				json,
				o => <number>o,
				o => <string>o));
	}

	public getDemographicInfo(): DemographicInfo {
		return this._demoInfo;
	}

	public getAvailableSources(): Promise<ClienteleGroupSource[]>{
		return this.http.get(this.appSettings.APIURL + '/clientele/source')
			.toPromise()
			.then(json => {
				this.console.log('json:',json);
				return <ClienteleGroupSource[]> json;
			});
	}

	public getCounties(): Promise<County[]>{
		return this.http.get(this.appSettings.APIURL + '/clientele/appointed_county')
			.toPromise()
			.then(json => {
				return <County[]> json;
			});
	}

	public getAvailableCounties(): Promise<County[]>{
		return this.http.get(this.appSettings.APIURL + '/clientele/available_county')
			.toPromise()
			.then(json => {
				return <County[]> json;
			});
	}
}