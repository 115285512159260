import { Component, OnInit } from '@angular/core';
import { AHRService, NewsStory, UserIdAndName, newsStoryIsByUserId, newsStoryIsOnBehalfOf } from '../../../domain';
import { ConsoleService } from '../../../widgets/console.service';
import { MatDialog } from '@angular/material/dialog';
import { CaseModalComponent } from '../case-modal/case-modal.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map, first } from 'rxjs/operators';

export interface StorySpec {
	readonly by?: UserIdAndName;
	readonly onBehalfOf?: string;
}

@Component({
	selector: 'news-story-list',
	templateUrl: './news-story-list.component.html',
	styleUrls: ['./news-story-list.component.scss']
})
export class NewsStoryListComponent implements OnInit {
	stories: NewsStory[];

	form: FormGroup;

	constructor(
		private svc: AHRService,
		private console: ConsoleService,
		private matDialog: MatDialog,
		fb: FormBuilder
	) { 
		this.form = fb.group({
			by: null,
			onBehalfOf: null
		});
	}

	selectAllStoriesBy(user: UserIdAndName){
		this.form.controls['by'].setValue(user);
	}

	selectAllStoriesOnBehalfOf(user: String){
		this.form.controls['onBehalfOf'].setValue(user);
	}
	
	showAllStories(){
		this.form.controls['by'].setValue(null);
		this.form.controls['onBehalfOf'].setValue(null);
	}

	predicates = [];

	ngOnInit() {
		combineLatest(
			this.svc.newsStories,
			this.form.valueChanges)
		.subscribe(([stories, spec]: [NewsStory[], StorySpec]) => {
			this.predicates =
			[
				{
					include: !!spec.by,
					fn: (s: NewsStory) => newsStoryIsByUserId  (s, spec.by.id),
					includeWord: 'from',
					getValueLabel: () => spec.by.name
				},
				{
					include: !!spec.onBehalfOf,
					fn: (s: NewsStory) => newsStoryIsOnBehalfOf(s, spec.onBehalfOf),
					includeWord: 'on behalf of',
					getValueLabel: () => spec.onBehalfOf
				}
			]
			.filter(p => p.include);

			if(this.predicates.length == 0){
				this.stories = stories;
				return;
			}
			let fns = this.predicates.map(p => p.fn);
			this.stories = stories.filter(s => fns.every(fn => fn(s)));
		});
		this.showAllStories();
	}

	viewDetails(caseId: string): void{
		this.svc.cases
			.pipe(map(cases => cases.find(c => c.id == caseId)))
			.pipe(first())
			.subscribe(c =>
				{
					if(!c){
						alert('Academic not found by that ID'); 
					} else {
						CaseModalComponent.open(this.matDialog,c);
					}
				});
	}
}