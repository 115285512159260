import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { ActivitySubTypeId, DossierService } from '../../../domain';
import { ConsoleService } from '../../../widgets/console.service';
import { Observable } from 'rxjs';
import { Activity, Dossier, SelectThemeItems, SelectThemeItem, DossierIdType } from '../../../domain/types/dossier';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivityModalService } from '../../../main-core/activity-modal.service';

@Component({
	selector: 'activities',
	templateUrl: './activities.component.html',
	styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {
	saving: boolean;
	ActivitySubTypeId = ActivitySubTypeId;
	subTypeId: ActivitySubTypeId | null = null;
	activities: Activity[] = undefined;
	dossier: Dossier;
	activitiesAll: boolean = false;
	DossierIdType = DossierIdType;

	constructor(
		private svc: DossierService,
		private route: ActivatedRoute,
		private snackBar: MatSnackBar,
		private console: ConsoleService,
		private activityModals: ActivityModalService,
		private matDialog: MatDialog
	) {
	}

	activityClick(activity: Activity, clickEvent: MouseEvent){
		this.activityModals.openActivityInModalById(this.matDialog, activity.id);
		clickEvent.preventDefault();
		clickEvent.stopPropagation();
	}

	ngOnInit() {
		this.route.data.subscribe((data: Data) => {
			this.subTypeId = <ActivitySubTypeId> data['activitySubTypeId'];
		});

		this.svc.getDossier().subscribe(dossier => {
			this.dossier = dossier;
			//this.console.log("Dossier: ", this.dossier);
			if(!!this.dossier && !!this.dossier.IndependentActivities){
				this.activities = this.dossier.IndependentActivities.get(this.subTypeId);
				if (this.activities.every(a => a.includeInDossier)){
					this.activitiesAll = true;
				}
				//this.console.log("Independant Activities: ", this.activities);
			}
		});
	}

	toggleAll(): void {
		this.console.log("activitiesAll: ", this.activitiesAll);
		if(!!this.activities){
			this.activities.forEach(a => a.includeInDossier = this.activitiesAll);
		}
		this.save();
	}

	totalSelected(): number{
		return !!this.activities
			? this.activities.filter(a => a.includeInDossier).length
			: 0;
	}

	checkWhetherAllSelected(){
		if(!this.activities){
			return false;
		}
		if(this.totalSelected() == this.activities.length){
			this.activitiesAll = true;
		} else {
			this.activitiesAll = false;
		}
	}

	save(){
		this.checkWhetherAllSelected();
		//this.console.log("save triggered");
		this.saving = true;
		let data = this.dossier.dto(false);
		this.svc.selectThemeItems(data)
			.then(result => result.matchDo(
				_ => {
					this.snackBar.open('Selections saved', null, {duration: 5000});
					this.saving = false;
				},
				errorMsg => {
					this.snackBar.open('Selections NOT saved: ' + errorMsg, null, {duration: 10000});
					this.saving = false;
				}))
			.catch(error => {
				this.snackBar.open(error.statusText, null, {duration: 10000});
				this.saving = false;
			});
	}

	saveSingleChange(isSelected: boolean, id: number, idType: DossierIdType){
		this.checkWhetherAllSelected();
		this.saving = true;
		this.svc.selectThemeItem(new SelectThemeItem(isSelected, id, idType))
			.then(result => result.matchDo(
				_ => {
					this.snackBar.open('Selection saved', null, {duration: 5000});
					this.saving = false;
				},
				errorMsg => {
					this.snackBar.open('Selection NOT saved: ' + errorMsg, null, {duration: 10000});
					this.saving = false;
				}
			))
			.catch(error => {
				this.snackBar.open(error.statusText, null, {duration: 10000});
				this.saving = false;
			});
	}
}
