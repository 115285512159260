<div *ngIf="state === 'viewing'" 
	(click)="edit()"
	class="viewing" 
	[ngClass]="noOuterBorder ? 'viewing-no-outer-border' : ''"
	title="Edit this"
	tabindex="0">
	<div class="media">
		<div class="media-body">
			<ng-content select="[readonly]"></ng-content>
		</div>
		<div class="media-object">
			<i class="fa fa-fw fa-pencil"></i>
		</div>
	</div>
</div>

<div *ngIf="state === 'editing' || saving" class="editing">
	<ng-content select="[editor]"></ng-content>

	<div class="toolbar" [ngClass]="'toolbar-' + toolbarPosition">
		<div [hidden]="!!saving">
			<button class="btn btn-sm btn-primary" 
				(click)="save()" 
				[disabled]="!edited || saving" 
				title="Save (Enter on the keyboard)">
				Save
			</button>
			<button class="btn btn-sm btn-link" 	
				(click)="cancel()" 
				[disabled]="saving" 
				title="Cancel (Escape on the keyboard)">
				Cancel
			</button>
		</div>
		<div class="mat-spinner-container" [hidden]="!saving">
			<mat-spinner [hidden]="!saving"></mat-spinner>
		</div>
	</div>
</div>