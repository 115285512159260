
import { debounceTime, startWith } from 'rxjs/operators';
import { ActivityService, ClienteleGroup, ClienteleGroupType } from '../../domain';
import { Component, ElementRef, Input } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { partition } from 'rxjs';

import * as s from '../../widgets/search';
import { ConsoleService } from '../console.service';

@Component({
    selector: 'clientele-group-picker',
    templateUrl: './clientele-group-picker.component.html',
    styleUrls: ['./clientele-group-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ClienteleGroupPickerComponent,
            multi: true
        }
    ]
})
export class ClienteleGroupPickerComponent implements ControlValueAccessor {
    constructor(
        private _element: ElementRef,
        private console: ConsoleService, 
        private _svc: ActivityService)
    {
        this.initObservations();
    }

	ClienteleGroupType = ClienteleGroupType;

    selected: ClienteleGroup | null = null;
    clienteleGroups: ClienteleGroup[] = [];
    searchControl = new FormControl();
    lastTerm = '';
    @Input() controlClass: string = '';
    @Input() inputGroupClass: string = '';
    @Input() activityFilter: boolean = false;
    search =
    s.ResultsFunction.fromPredicate<any>(
        s.Predicate.objectKeys(['title']));

    clear() {
        this.selected = null;
        this._onChange(null);
        this.searchControl.setValue('');
    }

    private initObservations() {
        let [selections, termChanges] =
            partition(
                this.searchControl
                    .valueChanges
                    .pipe(startWith(null)),
                o => o instanceof ClienteleGroup);

        selections
            .subscribe(o => 
			{
                var cg = <ClienteleGroup> o;
                this.selected = o;
                this._onChange(o);
            });

        termChanges.pipe(
            debounceTime(200))
            .subscribe(term =>
				this._svc.getAvailableClienteleGroups(this.activityFilter).then(cgs => 
				{
					this.lastTerm = term;
					this.clienteleGroups = this.search(cgs, term);
				}));
    }

    displayWith(o): string {
        if (typeof o == 'string'){
            return o;
        } else if (o == null) { // on component initialization o is null
            return '';
        }
        return o.title + " (" + ClienteleGroupType[o.type] +")";
    }

    //
    //ControlValueAccessor implementation:
    writeValue(val: any): void {
        if (val) {
            let cg = <ClienteleGroup>val;
            if (cg) {
                this.console.log("Clientele-group-picker: writeValue(Clientele Group)");
                this.selected = cg;
                this.searchControl.setValue(cg);
            } else if (typeof val == 'string') {
                this.console.log("Clientele-group-picker: writeValue(string)");
                this.searchControl.setValue(val);
            } else {
                this.console.log("Clientele-group-picker: writeValue(other)");
                this.clear();
            }
        } else {
            this.console.log("Clientele-group-picker: writeValue(no value)");
            this.clear();
        }
    }
    private _onChange: any = () => {  };
    registerOnChange(fn): void { this._onChange = fn; }
    registerOnTouched(fn): void { }
}
