import { Component } from '@angular/core';
import { AuthenticationService, CurrentUser, Option, HumanResources, AuthenticatedIdentity, Academic, OperationsService, DossierService, AcademicTitleId } from '../../domain';
import { Router } from '@angular/router';
import { ConsoleService } from '../../widgets/console.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GoogleAnalyticsService } from '../google-analytics.service';

@Component({
	selector: 'user-menu',
	templateUrl: './user-menu.component.html',
	styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent {
	HR = HumanResources;
	currentUser: Option<CurrentUser> = Option.create<CurrentUser>();
	navLinks: any[] = [];
	loginReportAccess:string[]=["10001885","10604634","10001223","10249725","50000026","50000034","20001","12225","100030","200987"];
	isAcademic: boolean = false;
	isCampusMPAcademic: boolean = false;
	isStaffDirector: boolean = false;
	menuOpen: boolean = false;
	showUsageDownloadButton = false;
	showReadModelResetButton = false;
	showPacketMovementButton = false;
	showCommandButton = false;
	showLoginDownloadButton = false;
	constructor(
		private authSvc: AuthenticationService,
		private router: Router,
		private operationsSvc: OperationsService,
		private console: ConsoleService,
		private dossierSvc: DossierService,
		private gaService: GoogleAnalyticsService,
		private snackbar: MatSnackBar
	) {

		authSvc.currentIdentity.subscribe(identity => {
			let claimsJson =window.localStorage.getItem("projectboard.user.claims");
			let authResult =  JSON.parse(claimsJson);
			let employeeId =  authResult.idTokenClaims["employeeid"];
			identity.match(
				() => {
					this.currentUser = Option.create<CurrentUser>();
					this.navLinks = [];
				},
				(i: AuthenticatedIdentity) => {
					this.currentUser = Option.create<CurrentUser>(i.user);
					this.showUsageDownloadButton = i.user.roles.programPlanningAndEvaluation || i.user.roles.academicHumanResources;
					this.showReadModelResetButton = i.user.roles.sysAdmin;
					this.showPacketMovementButton = i.user.roles.sysAdmin;
					this.showCommandButton = i.user.roles.sysAdmin;
					this.showLoginDownloadButton = this.loginReportAccess.indexOf(employeeId) !== -1;//|| i.user.roles.academicHumanResources 
					this.isCampusMPAcademic = i.user.roles.academic.map(a => a.meritAndPromotionHr === HumanResources.Campus).getValueOrDefault(false);
					this.isStaffDirector = i.user.roles.academic.map(a => a.title.id == AcademicTitleId.StaffDirector).getValueOrDefault(false);
					this.initNavLinks(i.user);
				});
		});
	}

	excelExport(){
		this.snackbar.open('Preparing download...');
		this.dossierSvc.downloadExcel().then(r => r.matchDo(
			_ => {
				this.snackbar.open('FTE Export is downloading');
				this.gaService.eventEmitter("FTE Export Downloaded", "FTE Export", "Download");
			},
			errorMessage => this.snackbar.open(errorMessage)));
	}

	wordExport(){
		this.snackbar.open('Preparing download...');
		this.dossierSvc.downloadThemesAndProjectsDoc().then(r => r.matchDo(
			_ => {
				this.snackbar.open('Themes and Projects Export is downloading');
				this.gaService.eventEmitter("Themes and Projects Export Downloaded", "Themes and Projects Export", "Download");
			},
			errorMessage => this.snackbar.open(errorMessage)));
	}

	resetReadModels(){
		this.operationsSvc.resetReadModels();
	}

	tryMovePacket(){
		var id = prompt('Enter Academic GUID');
		if(!id){
			return;
		}
		this.operationsSvc.tryMovePacket(id);
	}

	askForCommand(){
		var id = prompt('Enter Academic Aggregate ID');
		if(!id){
			return;
		}
		var json = prompt('Enter COMMAND for aggregate ' + id);
		if(!json){
			return;
		}
		let cmd = JSON.parse(json);
		this.operationsSvc.executeCommand(id,cmd).then(result => result.matchDo(_ => alert('OK'), error => alert(error)));
	}
	public downloadUserLoginReport(){
		this.operationsSvc.downloadUserLoginReportExcel();
	}	
	downloadUsageReport(){
		this.operationsSvc.downloadUsageReport();
		this.gaService.eventEmitter("Usage Report Downloaded", "Usage Report", "Download");
	}

	logOut(){
		this.gaService.eventEmitter("Logout", "Logout", "Success");
		this.authSvc.clearCurrentUser();
		this.router.navigateByUrl('/');
	}

	urlActive(path: string) {
		return this.router.url.includes(path);
	}

	private initNavLinks(cu: CurrentUser){
		let isAcademic = this.isAcademic = cu.roles.academic.hasValue();

		this.navLinks = [
			{ 
				url: '/ahr',
				label: 'Academic HR',
				iconClass: '',
				visible: cu.roles.academicHumanResources 
			},
			/*
			{ 
				url: '/ahr_old',
				label: 'Academic HR (old)',
				iconClass: '',
				visible: cu.roles.academicHumanResources 
			},
			*/
			{
				url: '/crc',
				label: 'Civil Rights Compliance',
				iconClass: '',
				visible: cu.roles.civilRightsCompliance
			},
			{
				url: '/academic',
				label: 'Project Board',
				iconClass: '',
				visible: isAcademic || cu.roles.academicHumanResources
			},
			{
				url: '/program-review',
				label: 'Academic Program Review',
				iconClass: 'fa fa-briefcase',
				visible: cu.roles.academic.match(
					() => false,
					(a: Academic) => a.meritAndPromotionHr == HumanResources.ANR && !this.isStaffDirector)
			},
			{
				url: '/review',
				label: 'Reviewer Dashboard',
				iconClass: 'fa fa-comments',
				visible: true //TODO: cu.hasCurrentYearReview() or similar
			},
			{
				url: '/admin-proxy',
				label: 'Admin Proxy',
				iconClass: '',
				visible: cu.roles.programPlanningAndEvaluation
			},
		].filter(l => l.visible);
	}
}