import { Pipe, PipeTransform } from "@angular/core";
import { ActionType } from "../../domain/types/ahr";

@Pipe({name: 'actionTypeName'})
export class ActionTypeNamePipe implements PipeTransform {
	transform(value: any, args?: any): any {
		let action = <ActionType>value;
		switch(action){
			case ActionType.AnnualEvaluation:
				return "Annual Evaluation";
			case ActionType.NotApplicable:
				return "N/A";
			default: 
				return ActionType[action];
		}
	}
}