import { Tag, TagType } from './tag';

export class Commodity {
	readonly tag: Tag;
    constructor(
        public readonly id: number,
        public readonly name: string,
		public readonly children: Commodity[]
    ) { 
    	this.tag = new Tag(
			TagType.Commodity,
			this.id,
			this.name);
    }

	public toTag(): Tag {
		return new Tag(
			TagType.Commodity,
			this.id,
			this.name);
	}

	public static fromJson(o: any): Commodity {
		return new Commodity(
			o.id,
			o.name,
			(<any[]>o.children).map(Commodity.fromJson));
	}
}
