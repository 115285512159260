import {
	Component,
	HostListener,
	Input,
	ElementRef,
	EventEmitter
} from '@angular/core';

@Component({
	selector: 'auto-complete-option',
	templateUrl: './auto-complete-option.component.html',
	styleUrls: ['./auto-complete-option.component.scss']
})
export class AutoCompleteOptionComponent {
	@Input() value: any;

	private readonly activeClassName = 'auto-complete-option-active';
	_selected: boolean = false;
	private _active: boolean = false;
	get active() { return this._active; }
	set active(a: boolean) { 
		if(a){
			this._element.nativeElement.classList.add(this.activeClassName);
		} else {
			this._element.nativeElement.classList.remove(this.activeClassName);
		}
		this._active = a; 
	}
	select: EventEmitter<AutoCompleteOptionComponent> = new EventEmitter<AutoCompleteOptionComponent>();

	constructor(public _element: ElementRef){ }

	@HostListener('click',['$event'])
	click(e){
		this.select.emit(this);
		e.preventDefault();
		e.stopPropagation();
	}
}
