
import { debounceTime, startWith } from 'rxjs/operators';
import { Component, ElementRef, Input } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Location } from '../../domain';
import { LOCATIONS } from '../../demo-data';
import { partition } from 'rxjs';




import * as s from '../../widgets/search';

@Component({
	selector: 'location-picker',
	templateUrl: './location-picker.component.html',
	styleUrls: ['./location-picker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: LocationPickerComponent,
			multi: true
		}
	]
})
export class LocationPickerComponent implements ControlValueAccessor {
	searchControl = new FormControl();
	lastTerm = '';
	locations: Location[] = [];
	@Input() controlClass: string = '';
	search = 
		s.ResultsFunction.fromPredicate<Location>(
			s.Predicate.objectStringProjectors<Location>([
				o => o.name,
				o => o.parentLocationName.match(() => false, n => n)
			]));

	constructor(private _element: ElementRef) {
		let [locationSelections, termChanges] = 
			partition(
				this.searchControl
					.valueChanges
					.pipe(startWith(null)),
				o => o instanceof Location);

		locationSelections
			.subscribe(o => {
				let location = <Location>o;
				this._onChange(location);
			});

		termChanges.pipe(
			debounceTime(200))
			.subscribe(term => {
				this.lastTerm = term;
				this.locations = this.search(LOCATIONS, term)
			});
	}
	
	displayWith(loc): string {
		return loc.name;
	}

	//
	//ControlValueAccessor implementation:
	writeValue(val: any): void {
		if(val) {
			let loc = <Location>val;
			if(loc){
				this.searchControl.setValue(loc.name)
			} else if(typeof val == 'string'){
				this.searchControl.setValue(val);
			}
		}
	}
	private _onChange: any = () => {};
	registerOnChange (fn): void { this._onChange = fn; }
	registerOnTouched(fn): void {}
}
