import {
   	Component,
   	OnInit,
   	Input,
   	Output,
   	EventEmitter,
   	AfterViewInit,
   	AfterContentInit,
	OnDestroy,
   	NgZone,
   	ViewChild,
   	ElementRef  
} from '@angular/core';
declare var textboxio: any;
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConsoleService } from '../console.service';
import { InlineEditorService } from '../inline-editor/inline-editor.component';

@Component({
	selector: 'textboxio',
	templateUrl: './textboxio.component.html',
	styleUrls: ['./textboxio.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: TextboxioComponent,
			multi: true
		}
	]
})
export class TextboxioComponent implements AfterViewInit, AfterContentInit, OnDestroy, ControlValueAccessor {
	private readonly defaultContent: string = '<p><br></p>';
	private _textbox: any = null;
	private _content: string = this.defaultContent;
	private _disabled: boolean = false;
	@Input() set disabled(d: boolean){
		this.console.log('set disabled:', d);
		this._disabled = d;
	}

	constructor(private _zone: NgZone,
				private console: ConsoleService,
				private inlineEditorService: InlineEditorService
				){ }

	@ViewChild('container', { read: ElementRef }) set container(c: any){
		this.console.log('container div updated:', c);
		if(c){
			this.init(c.nativeElement);
		}
	}

	@Output()
	contentChange : EventEmitter<string> = new EventEmitter<string>();

	focus(){
		this.console.log('focus!');
		if(!this._textbox){
			return;
		}
		this._textbox.focus();
	}

	select(){
		if(!this._textbox){
			return;
		}
		this.console.log('textboxio.select');
	}

	@Input()
	set content(c: string) {
		/* We want to prevent unnecessary updates of the textbox.io instance. 
		** If there is actually no change, do nothing. */
		if(c !== this._content){
			this.console.log('content.setter: change detected', c);
			this._content = !!c ? c : this.defaultContent;
			if(this._textbox){
				this.console.log('content.setter: passing the new content on to textboxio');
				this._textbox.content.set(this._content);
			} else {
				this.console.log('content.setter: textboxio is not ready to receive the new content');
			}
		} else {
			this.console.log('content.setter: no actual change detected', c);
		}
	}

	ngOnChanges          (){ 
		//this.console.log('TextboxioComponent:OnChanges'); 
	}
	ngOnInit             (){ 
		//this.console.log('TextboxioComponent:OnInit'); 
	}
	ngDoCheck            (){ 
		//this.console.log('TextboxioComponent:DoCheck'); 
	}
	ngAfterContentInit   (){ 
		//this.console.log('TextboxioComponent:AfterContentInit'); 
	}
	ngAfterContentChecked(){ 
		//this.console.log('TextboxioComponent:AfterContentChecked'); 
	}
	ngAfterViewInit      (){ 
		//this.console.log('TextboxioComponent:AfterViewInit'); 
	}
	ngAfterViewChecked   (){ 
		//this.console.log('TextboxioComponent:AfterViewChecked'); 
	}
	ngOnDestroy          (){ this.console.log('TextboxioComponent:OnDestroy');
	                         //This destroys the textboxio instance.
		                     this._textbox.restore();
							 this.inlineEditorService.setPendingChanges(false); }

	private init(element: any){
		this._textbox = this.createTextBox(element);
		this.inlineEditorService.setPendingChanges(true);
		this._textbox.events.loaded.addListener(() => {
			// this is stealing focus from other elements after the user select them. If this is needed later it should be implemented as a directive.
			//this._textbox.focus();
		});
		this._textbox.events.dirty.addListener(() => {
			this.console.log('TextboxioComponent: textboxio.events.dirty')
		});
		this._textbox.events.change.addListener(() => 
			this._zone.run(() => { 
				/* Similar to `$rootScope.$apply(callback)` in angular 1.x,
				** `zone.run` causes this callback to be executed *within* the angular zone.
				** Without it or something similar (options: https://stackoverflow.com/a/34829089/943730),
			   	** the event emitted below would not trigger normal change detection in client components.
				** Reference: https://angular.io/api/core/NgZone#run */
				this.flushContent();
				this.console.log('TextboxioComponent: textboxio.events.change');
			}));
		this._textbox.content.set(this._content);
	}

	flushContent(){
		let c = this._textbox.content.get();
		this._content = c;
		this.contentChange.emit(c);
		this._onChange(c);
		this._textbox.content.setDirty(false);
	}

	//
	//ControlValueAccessor implementation:
	writeValue(val: any): void {
		this.console.log('writeValue:', val);
		if(typeof val == 'string') {
			this.content = <string>val;
		} else {
			throw ('expected value of type <string> but a value of type <' + (typeof val) + '>');
		}
	}
	private _onChange: any = () => {};
	registerOnChange (fn): void { this._onChange = fn; }
	registerOnTouched(fn): void {}

	private createTextBox(element: any){
		return textboxio.replace(element, {
			//Reference: https://docs.ephox.com/display/tbio/configuration
			basePath : '/assets/textboxio-client/textboxio',
			images: {
				allowLocal: false
			},
			css: {
				documentStyles: "body { font-family: Helvetica, Arial, sans-serif; } ",
				styles: [
					{rule: 'p'},
					{rule: 'h1', text: 'Heading 1'},
					{rule: 'h2', text: 'Heading 2'},
					{rule: 'h3', text: 'Heading 3'},
					{rule: 'h4', text: 'Heading 4'},
					{rule: 'pre', text: 'Pre-formatted'}
				]
			},
			codeview: {
				enabled: false
			},
			paste: {
				style: 'clean', // Inline CSS styling is stripped from pasted content.
				enableFlashImport: false
			},
			ui: {
				fonts: [ 'Helvetica', 'Arial', 'sans-serif' ],
				toolbar: {
					contextual: [
						//'image-tools',
						//'table-tools'
					],
					//Reference: https://docs.ephox.com/display/tbio/Command+Item+IDs
					items: [
						{
							label: 'Ease of Use',
							items: [
								'fullscreen'
							]
						},
						'undo',
					   	'style',
						{
							label: 'Emphasis',
							items: [
								'bold',
								'italic',
								'underline'
							]
						},
						{
							label: 'Lists',
							items: [
								'ul',
								'ol'
							]
						},
						{
							label: 'Extras',
							items: [
								{
									id: 'extras',
									label: 'Extras',
									icon: '/assets/textboxio-client/textboxio/resources/custom-icons/caret-down.png',
									//icon: '',
									items: [
										'alignment',
										'indent',
										'outdent',
										'superscript',
										'subscript',
										'removeformat',
										'specialchar',
										'wordcount',
										'find',
										'accessibility',
										/*
										{
											id : 'myCommand',
											text : 'my custom command', 
											//icon: './path/to/my/image.png',
											action : () => {
												this._textbox.content.insertHtmlAtCursor('<em>This is a test</em>');
												//alert('hello world');
											}
										}
										*/
									]
								}
							]
						},
					]
				}
			}
		});
	}
}
