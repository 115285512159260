import { Component, OnInit, OnDestroy } from '@angular/core';
import { AHRService } from '../../../domain';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'ahr-wrapper',
	templateUrl: './ahr-wrapper.component.html',
	styleUrls: ['./ahr-wrapper.component.scss']
})
export class AHRWrapperComponent implements OnInit, OnDestroy{

	constructor(
		private readonly svc: AHRService,
		private readonly matDialog: MatDialog
	){ }

	ngOnInit(): void {
		this.svc.subscribeToNotifications();
	}

	ngOnDestroy(): void {
		this.svc.unsubscribeToNotifications();
	}

	navLinks = [
		{
			url: 'cases',
			label: 'Academics',
			iconClass: ''
		},
		/*
		{
			url: 'committees',
			label: 'Committees',
			iconClass: 'fa fa-users'
		},
		*/
		{
			url: 'news',
			label: 'News',
			iconClass: 'fa fa-newspaper-o'
		},
		{
			url: 'settings',
			label: 'Settings',
			iconClass: 'fa fa-cog'
		},
	];
}