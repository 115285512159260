import { Tag, TagType } from './tag';
import { Commodity } from './commodity';

export class AllAvailableTags {
	constructor(
		public readonly Commodities: Commodity[],
		public readonly StrategicInitiatives: Tag[],
		public readonly UserDefined: Tag[],
		public readonly Statewide: Tag[],
		public readonly ConditionChange: Tag[],
		public readonly UserDefinedConditionChange: Tag[],
		public readonly ResearchExtension: Tag[],
		public readonly ProgramArea: Tag[],
		public readonly OutcomeType: Tag[],
) { }

    get(t: TagType): Tag[] {
        switch (t) {
            case TagType.ConditionChange:                return this.ConditionChange;
            case TagType.UserDefinedConditionChange:     return this.UserDefinedConditionChange;
			case TagType.ProgramArea: 		             return this.ProgramArea;
            case TagType.Statewide:                      return this.Statewide;
            case TagType.StrategicInitiative:            return this.StrategicInitiatives;
			case TagType.ResearchExtension:              return this.ResearchExtension;
			case TagType.UserDefined:                    return this.UserDefined;
			case TagType.OutcomeType:                    return this.OutcomeType;
            default: return [];
        }
    }

	public static fromJson(o: any): AllAvailableTags {
		return new AllAvailableTags(
			(<any[]>o.commodities).map(Commodity.fromJson),
			deserializeTags(o.strategicInitiatives),
			deserializeTags(o.userDefined),
			deserializeTags(o.statewideProgramsAndInstitutes),
			deserializeTags(o.conditionChanges),
			deserializeTags(o.userDefinedConditionChanges),
			deserializeTags(o.researchExtension),
			deserializeTags(o.programAreas),
			deserializeTags(o.outcomeTypes));

		function deserializeTags(json: any): Tag[] {
			if (json == undefined) return [];
			return (<any[]>json).map(Tag.fromJson);
		}
	}
}	