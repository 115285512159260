import { Injectable } from '@angular/core';

@Injectable()
export class ConsoleService {
	private _debugMode: boolean = true;

	constructor(){
		//console.warn("Console Service Constructor");
	}

	init(debugMode: boolean){
		this._debugMode = debugMode;
		//console.log("Console Service debugmode: ", this._debugMode);
	}

	public log(...args: any[]) {
		if(this._debugMode){
			console.log.apply(console, args);	
		}
	}

	public info(...args: any[]) {
		if(this._debugMode){
			console.info.apply(console, args);	
		}
	}

	public error(...args: any[]) {
		if(this._debugMode){
			console.error.apply(console, args);	
		}
	}

	public warn(...args: any[]) {
		if(this._debugMode){
			console.warn.apply(console, args);	
		}
	}

	public table(args: any[]) {
		if(this._debugMode){
			console.table(args);	
		}
	}
}