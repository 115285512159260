import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReviewerService {

  private academicIdSubject = new BehaviorSubject<string | null>(null);
  public currentAcademicId$ = this.academicIdSubject.asObservable();
  
  private reviewerSubject = new BehaviorSubject<string>('');
  public reviewer$ = this.reviewerSubject.asObservable();

  constructor() { }

  updateAcademicId(id: string | null) {
    this.academicIdSubject.next(id);
  }

  updateReviewer(reviewer: string) {
    this.reviewerSubject.next(reviewer);
  }

}
