import { Component } from '@angular/core';
import { ConsoleService } from '../../widgets/console.service';
import { UserPreferences, UserPreferenceService } from '../../domain';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Academic, AuthenticatedIdentity, AuthenticationService, HumanResources } from '../../domain';
import { TooltipService } from '../../widgets/tooltip.service';


@Component({
	selector: 'user-preference',
	templateUrl: './user-preference.component.html',
	styleUrls: ['./user-preference.component.scss']
})
export class UserPreferenceComponent {
    form: FormGroup;
    openActivityInModal: boolean;
    closeAfterActivitySave: boolean;
    wideModal: boolean;
    meritAndPromotionHR = HumanResources.ANR;

    openActivityInModalToggle: FormControl;
    closeAfterActivitySaveToggle: FormControl;
    wideModalToggle: FormControl;

    // local storage keys
    constructor(
        private console: ConsoleService,
        private svc: UserPreferenceService,
        private snackBar: MatSnackBar,
        fb: FormBuilder,
        authSvc: AuthenticationService,
        public tooltipSvc: TooltipService
    ){
        this.openActivityInModal = svc.getOpenActivityInModalPreference();
        this.closeAfterActivitySave = svc.getCloseAfterActivitySavePreference();
        this.wideModal = svc.getWideModalPreference();
        this.console.log("Close Activity After Save: ", this.closeAfterActivitySave);

        this.openActivityInModalToggle = new FormControl(this.openActivityInModal);
        this.openActivityInModalToggle.valueChanges.subscribe(checked => {
            let activityUseModal = <boolean>checked;
            this.svc.setKeyValue(UserPreferences.keys.activityUseModal, activityUseModal.toString());
            this.openActivityInModal = activityUseModal;
            this.snackBar.open("Preferences Saved", null, { duration: 3000 });
        });
        
        this.closeAfterActivitySaveToggle = new FormControl(this.closeAfterActivitySave);
        this.closeAfterActivitySaveToggle.valueChanges.subscribe(checked => {
			let closeAfterActivitySave = <boolean>checked;
			this.svc.setKeyValue(UserPreferences.keys.closeAfterActivitySave, closeAfterActivitySave.toString());
            this.closeAfterActivitySave = closeAfterActivitySave;
            this.snackBar.open("Preferences Saved", null, { duration: 3000 });
        });
        
        this.wideModalToggle = new FormControl(this.wideModal);
        this.wideModalToggle.valueChanges.subscribe(checked => {
			let wideModal = <boolean>checked;
			this.svc.setKeyValue(UserPreferences.keys.wideModal, wideModal.toString());
            this.wideModal = wideModal;
            this.snackBar.open("Preferences Saved", null, { duration: 3000 });
		});

		authSvc.currentIdentity.subscribe(identity => 
			identity.doWithValue((i: AuthenticatedIdentity) => 
				i.user.roles.academic.doWithValue((a: Academic) =>
				{
					this.meritAndPromotionHR = a.meritAndPromotionHr;
				})));
    }

    hasInstructions() {
		if (this.getInstructions().length > 0) {
			return true;
		} 
		return false;
	}

	getInstructions(): string {
		return this.tooltipSvc.tooltip("PreferencesInstructions." + this.tooltipSvc.mapMeritAndPromotionsHRToString(this.meritAndPromotionHR));
	}

    toggleCloseActivitySetting(e){
        e.stopPropagation();
        this.closeAfterActivitySave = !this.closeAfterActivitySave;
        this.svc.setKeyValue(UserPreferences.keys.closeAfterActivitySave, this.closeAfterActivitySave.toString());
    }

    showCloseSidePanelAfterSave() : boolean {
        if (this.openActivityInModal) return false;
        return true;
    }
}