import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavigationEnd, ActivatedRoute, Router, RouterState } from '@angular/router';
import { DossierService, AuthenticationService, AuthenticatedIdentity, Academic, HumanResources, DossierExportPeriodType, DossierExportPeriod, DossierExportPeriodOption, Result} from '../../domain';
	
import { Dossier, SelectThemeItems, } from '../../domain/types/dossier';
import { ConsoleService } from '../../widgets/console.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AcademicReportSubmissionTextType } from '../academic-report-submission/academic-report-submission.component';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { TooltipService } from '../../widgets/tooltip.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
	selector: 'review-and-compile',
	templateUrl: './review-and-compile.component.html',
	styleUrls: ['./review-and-compile.component.scss']
})
export class ReviewAndCompileComponent implements OnInit {
	private activeRoute: RouterState;
	dossier: Dossier;
	trim = false;
	saving = false;
	dossierDownloadButtonVisible: boolean = true;
	academicReportSubmissionTextType = AcademicReportSubmissionTextType.ReviewAndCompile;
	meritAndPromotionHR = HumanResources.ANR;
	startDate: Date;
	endDate: Date;
	touchEnabled = false;
	allPeriodType = true;
	constructor(
		private router: Router,
		private console: ConsoleService,
		private svc: DossierService,
		private readonly snackbar: MatSnackBar,
		private readonly gaService: GoogleAnalyticsService,
		authSvc: AuthenticationService,
		public tooltipSvc: TooltipService
	) {

		this.router.events.subscribe(e => {
			if(NavigationEnd.prototype.isPrototypeOf(e)){
				this.activeRoute = this.router.routerState;

			}
		});

		authSvc.currentIdentity.subscribe(i =>
			i.doWithValue((i: AuthenticatedIdentity) => 
				i.user.roles.academic.doWithValue((a: Academic) => { 
					this.dossierDownloadButtonVisible = a.meritAndPromotionHr == HumanResources.ANR;
					this.meritAndPromotionHR = a.meritAndPromotionHr;
				})));
	}

	navLinks = [
		{
			url: 'themes',
			label: 'Themes'
		},
		{
			url: 'university-service',
			label: 'University Service'
		},
		{
			url: 'public-service',
			label: 'Public Service'
		},
		{
			url: 'professional-development',
			label: 'Professional Development and Training'
		},
		{
			url: 'professional-competences',
			label: 'Evidence of Professional Competence'
		}
	];

	trimmableNavLinks = [this.navLinks[0].url];
	expandableNavLinks = [this.navLinks[0].url];

	hasInstructions() {
		if (this.getInstructions().length > 0) {
			return true;
		} 
		return false;
	}

	getInstructions(): string {
		return this.tooltipSvc.tooltip("ReviewAndCompileInstructions." + this.tooltipSvc.mapMeritAndPromotionsHRToString(this.meritAndPromotionHR));
	}

	urlIsActive(url: string){
		if(!this.activeRoute){
			return false;
		}
		return this.activeRoute.snapshot.url.indexOf(url) > -1;
	}

	ngOnInit() {
		this.svc.getDossier().subscribe(dossier => {
			this.dossier = dossier;
		});
	}

	requirementsMet() {
		if(!!this.dossier) {
			if(this.dossier.ExportStatus.InvalidActivityCount > 0 || this.dossier.ExportStatus.InvlidReasonCount > 0) {
				return false;
			}
		}
		return true;
	}

	trimChanged(){
		this.svc.setTrim(this.trim);
	}

	submitFinalReport(){
		alert('Coming Soon');
	}

	 get exportPeriod(){
	 	return this.svc.dossierPeriod;
	 }

	set exportPeriod(v: DossierExportPeriod){
		this.svc.dossierPeriod = v;
	}

	downloadDoc(){
		this.svc.downloadDoc().then(r => r.matchDo(
			_ => {
				this.snackbar.open('Dossier Export is downloading');
				this.gaService.eventEmitter("Dossier Export Downloaded", "Dossier Export", "Download");
			},
			errorMessage => this.snackbar.open(errorMessage,null,{duration:5000})));
	}

	downloadThemesAndProjectsDoc(){
		this.svc.downloadThemesAndProjectsDoc().then(r => r.matchDo(
			_ => {
				this.snackbar.open('Themes and Projects Export is downloading');
				this.gaService.eventEmitter("Themes and Projects Export Downloaded", "Themes and Projects Export", "Download");
			},
			errorMessage => this.snackbar.open(errorMessage,null,{duration:5000})));
	}

	downloadExcel(){
		this.svc.downloadExcel().then(r => r.matchDo(
			_ => {
				this.snackbar.open('Excel Export is downloading');
				this.gaService.eventEmitter("Excel Export Downloaded", "Excel Export", "Download");
			},
			errorMessage => this.snackbar.open(errorMessage,null,{duration:5000})));
	}

	dto(trim: boolean): SelectThemeItems {
		if (!!this.dossier){
			return this.dossier.dto(trim);	
		}
		return new SelectThemeItems(trim, [], [], []);
	}

	canExpand(): boolean {
		let canExpand = false;
		this.expandableNavLinks.forEach(link => {
			if(this.activeRoute.snapshot.url.indexOf(link) >= 0){
				canExpand = true;
			}
		});
		return canExpand;
	}

	canTrim(): boolean {
		let canTrim = false;
		this.trimmableNavLinks.forEach(link => {
			if(this.activeRoute.snapshot.url.indexOf(link) >= 0){
				canTrim = true;
			}
		});
		return canTrim;
	}

	expandAll(expand: boolean): void {
		this.svc.setExpand(expand);
	}

	selectAll(select: boolean): void {
		this.svc.selectAll(true);
		this.snackbar.open('Selection saved', null, {duration: 5000});
	}
	typeChanged(): void {
     this.startDate = null;
	 this.endDate = null;
	 if(this.allPeriodType){
        const allType: DossierExportPeriod = {type: DossierExportPeriodType.All};
		this.svc.dossierPeriod = allType ;
	 }
	  
	}
	dateChange(type: string, e: MatDatepickerInputEvent<Date>){
		this.allPeriodType = false;
		if (this.startDate !== null && this.endDate !== null){
			const betweenType: DossierExportPeriod = {type: DossierExportPeriodType.Only, startDate: this.startDate, endDate: this.endDate};
			this.svc.dossierPeriod = betweenType; 
		}		
		else if (this.startDate !== null && this.endDate === null){
			const onOrAfter: DossierExportPeriod = {type: DossierExportPeriodType.OnOrAfter, startDate: this.startDate};
			this.svc.dossierPeriod = onOrAfter; 
		}	
		else if (this.startDate === null && this.endDate !== null){
			const onOrBefore: DossierExportPeriod = {type: DossierExportPeriodType.OnOrBefore, endDate: this.endDate};
			this.svc.dossierPeriod = onOrBefore; 
		}		     
	}
}
