import { Tag, TagDto } from './tag';
import { Theme } from './theme';
import { ClienteleGroup } from './clientele-group';
import { ClienteleContactCollection } from './clientele-contact-collection';
import { SortedSet } from './sorted-set';
import { User } from './user';
import { Option } from './option';
import { ActivityParent, ActivityParentType, ActivityParentIdDto } from './activity-parent';
import { ActivitySubTypeId } from './activity-type';
import { ActivityDate, Single, Range, Ongoing } from './activity-date';
import { NonANRCollaborator, NonANRCollaboratorDto, IdAndName, OutReachTypes } from '.';

export class ActivityDto {
	constructor(
		public subTypeId: ActivitySubTypeId,
		public name: string,
		public startDate: Date,
		public endDate: Date,
		public topic: string,
		public role: string,
		public location: string | null,
		public areaOfService: string | null,
		public writtenBy: string,
		public repetitions: number | null,
		public instances:   number | null,
		public programAreaID: number | null,
		public clienteleGroupId: number | null,
		public clienteleContacts: any, //ie. Object<string, number>
		public targetedOutreachDemographics: string[],
		public anrCollaboratorUserIds: number[],
		public nonANRCollaborators: NonANRCollaboratorDto[],
		public internalAttendees: number | null,
		public activityParentId: ActivityParentIdDto | null,
		public tags: TagDto[],
		public newUserDefinedTags: string[]) { }
}

export class Activity {
    constructor(
        public id: number,
		public subTypeId: ActivitySubTypeId,
        public name: string,
        public date: ActivityDate,
        public parent: Option<ActivityParent>,
		public topic: string,
		public role: string,
		public location: string | null,
		public areaOfService: string | null,
		public writtenBy: string,
		public repetitions: number | null,
		public instances:   number | null,
		public programAreaId: number | null,
		public clienteleGroup: Option<ClienteleGroup>,
		public clienteleContacts: ClienteleContactCollection,
		public targetedOutreachDemographics: string[],
		public tags: SortedSet<Tag>,
		public invalidReasons: string[] = null,
		public ANRCollaborators: SortedSet<User> = null,
		public nonANRCollaborators: NonANRCollaborator[] = null,
		public internalAttendees: number | null = null,
		public readonly activityCollaborationOriginal: Option<ActivityCollaborationOriginal>,
		public checked?: boolean|false
	)
	{
		if(invalidReasons == null){
			this.invalidReasons = [];
		}
		if(ANRCollaborators == null){
			this.ANRCollaborators = new SortedSet<User>();
		}
		if(nonANRCollaborators == null){
			this.nonANRCollaborators = [];
		}
	}

	public static fromJson(o: any): Activity {
		return new Activity (
			<number> o.id,
			<ActivitySubTypeId>o.subTypeId,
			o.name,
			ActivityDate.fromJson(o.date),
			Option.fromJson<ActivityParent>(o.parent, ActivityParent.fromJson),
			o.topic,
			o.role,
			<string | null>o.location,
			<string | null>o.areaOfService,
			o.writtenBy,
			<number | null>o.repetitions,
			<number | null>o.instances,
			<number|null>o.programAreaID,
			Option.fromJson(o.clienteleGroup, ClienteleGroup.fromJson),
			ClienteleContactCollection.fromJson(o.clienteleContacts),
			<string[]> o.targetedDemographics,
			new SortedSet<Tag>((<any[]>o.tags).map(Tag.fromJson)),
			o.invalidReasons,
			new SortedSet<User>((<any[]>o.ANRCollaborators).map(c => User.fromJson(c.user))),
			(<any[]>o.nonANRCollaborators).map(NonANRCollaborator.fromJson),
			o.internalAttendees,
			Option.fromJson(o.activityCollaborationOriginal, ActivityCollaborationOriginal.fromJson),
			o.checked);
	}

	public toDto(): ActivityDto {
		var dto = new ActivityDto (
			this.subTypeId,
			this.name,
			null,
			null,
			this.topic,
			this.role,
			this.location,
			this.areaOfService,
			this.writtenBy,
			this.repetitions,
			this.instances,
			!!this.programAreaId ? this.programAreaId : null,
			this.clienteleGroup.match(
				() => null,
				cg => cg.id),
			this.clienteleContacts.toObject(),
			this.targetedOutreachDemographics,
			this.ANRCollaborators.toArray().map(c => c.id),
			this.nonANRCollaborators.map(c => c.toDto()),
			this.internalAttendees,
			this.parent.match(
				() => null,
				p  => new ActivityParentIdDto(p.id, p.type)),
			this.tags.toArray().map(t => t.toDto()),
			[]);//TODO: newUserDefinedTags (REMOVE)
		this.date.matchDo(
			single => {
				dto.startDate = single.date;
				dto.endDate = single.date;
			},
			range => {
				dto.startDate = range.startDate;
				dto.endDate = range.endDate;
			},
			ongoing => {
				dto.startDate = ongoing.startDate;
			});
		return dto;
	}

	public static blankForSubType(subTypeId: ActivitySubTypeId): Activity {
		return new Activity(
			0,
			subTypeId,
			'',
			new Single(new Date()),
			Option.create<ActivityParent>(),
			'',
			'',
			null,
			null,
			'', // writtenBy
			1, 	// repetitions
			1,  // instances
			null,
			null,
			ClienteleContactCollection.blank(),
			[],
			new SortedSet<Tag>(),
			[],
			new SortedSet<User>(),
			[],
			null,	// Internal Attendees
			Option.create(),
			false);
	}

	public duplicate(): Activity {
		return new Activity(
			this.id,
			this.subTypeId,
			this.name,
			this.date.duplicate(),
			this.parent,
			this.topic,
			this.role,
			this.location,
			this.areaOfService,
			this.writtenBy,
			this.repetitions,
			this.instances,
			this.programAreaId,
			this.clienteleGroup,
			this.clienteleContacts.duplicate(),
			this.targetedOutreachDemographics.slice(0),
			this.tags.duplicate(),
			[],
			this.ANRCollaborators.duplicate(),
			this.nonANRCollaborators.slice(0),
			this.internalAttendees,
			Option.create(),
			this.checked);
	}

	public patch(data: Activity){
		this.subTypeId = data.subTypeId;
		this.name = data.name;
		this.date = data.date;
		this.parent = data.parent;
		this.topic = data.topic;
		this.role = data.role;
		this.programAreaId = data.programAreaId;
		this.clienteleGroup = data.clienteleGroup;
		this.clienteleContacts = data.clienteleContacts.duplicate();
		this.targetedOutreachDemographics = data.targetedOutreachDemographics.slice(0);
		this.location = data.location;
		this.areaOfService = data.areaOfService;
		this.writtenBy = data.writtenBy;
		this.repetitions = data.repetitions;
		this.instances = data.instances;
		this.tags = data.tags.duplicate();
		this.invalidReasons = data.invalidReasons;
		this.ANRCollaborators = data.ANRCollaborators.duplicate();
		this.nonANRCollaborators = data.nonANRCollaborators.slice(0);
		this.internalAttendees = data.internalAttendees;
	}

	public equals(other: Activity): boolean {
		return this.subTypeId == other.subTypeId
			&& this.name == other.name
			&& this.parent.match(
				() => other.parent.match(
					 () => true,
					 _  => false),
				a => other.parent.match(
					() => false,
					b => a.type == b.type && a.id == b.id))
			&& this.date == other.date
			&& this.topic == other.topic
			&& this.role == other.role
			&& this.location == other.location
			&& this.areaOfService == other.areaOfService
			&& this.writtenBy == other.writtenBy
			&& this.repetitions == other.repetitions
			&& this.instances == other.instances
			&& this.programAreaId == other.programAreaId
			&& this.clienteleGroup.match(
				() => other.clienteleGroup.match(
					 () => true,
					 _  => false),
				a => other.clienteleGroup.match(
					() => false,
					b => a.equals(b)))
			&& this.clienteleContacts.equals(other.clienteleContacts)
			//TODO: && this.targetedOutreachDemographics = data.targetedOutreachDemographics.slice(0);
			&& this.tags.equals(other.tags)
			&& this.ANRCollaborators.equals(other.ANRCollaborators)
			&& this.internalAttendees == other.internalAttendees
			&& NonANRCollaborator.arraysEqual(this.nonANRCollaborators, other.nonANRCollaborators);
	}
}

export enum ActivityProperty
{
	Parent = 1,
	Topic = 2,
	Role = 3,
	Location = 4,
	Collaborators = 5,
	ClienteleContacts = 6,
	TargetedOutreach = 7, //mutually exclusive with ClienteleContacts
	Tags = 8,
	AreaOfService = 9,
	InstanceCount = 10,
	RepetitionCount = 11,
	WrittenBy = 12
}

export class ActivityCollaborationOriginal {
	constructor(
		public readonly id: number,
		public readonly owner: IdAndName,
		public readonly title: string
	) {}

	public static fromJson(o: any): ActivityCollaborationOriginal {
		return new ActivityCollaborationOriginal(
			<number>o.id,
			IdAndName.fromJson(o.owner),
			<string>o.title
		)
	}
}

export class ActivityFilterSpecification {
    constructor(
		public readonly withNameMatching: string,
		public readonly withActivityTypeIn: number[],
		public readonly withActivitySubTypeIn: number[],
        public readonly occurringAfter: Date, 
		public readonly occurringBefore: Date,
		public readonly belongingToAThemeWithIdIn: number[],
		public readonly belongingToAProjectWithIdIn: number[],
        public readonly missingInformation: boolean,
		public readonly clienteleContactsType: OutReachTypes,
		public readonly clienteleGroupId: number,
    ){}

    public static fromJson(o: any): ActivityFilterSpecification {
        return new ActivityFilterSpecification(
			<string>o.withNameMatching,
			<number[]>o.withActivityTypeIn,
			<number[]>o.withActivitySubTypeIn,
			<Date>o.occurringAfter,
			<Date>o.occurringBefore,
			<number[]>o.belongingToAThemeWithIdIn,
			<number[]>o.belongingToAProjectWithIdIn,
			<boolean>o.missingInformation,
			<OutReachTypes>o.clienteleContactsType,
			<number>o.clienteleGroup
		);
    }
}
