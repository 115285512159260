// https://blog.thoughtram.io/angular/2016/07/27/custom-form-controls-in-angular-2.html
import { Component, OnInit, forwardRef, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

export function createCounterRangeValidator(maxVal, minVal) {
	return (c: FormControl) => {
		let err = {
			rangeError: {
				given: c.value,
				max: maxVal || 10,
				min: minVal || 0
			}
		};

		return (c.value > +maxVal || c.value < +minVal) ? err: null;
	}
}

@Component({
  selector: 'counter-input',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CounterComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => CounterComponent),
			multi: true
		}
	]
})
export class CounterComponent implements ControlValueAccessor, OnChanges {
	propogateChange:any = () => {};
	validateFn:any = () => {};

	@Output() counterValChange = new EventEmitter<number>();
	_counterVal: number;
	@Input() counterRangeMax;
	@Input() counterRangeMin;
	@Input() valid;

	constructor() { }

	get counterVal() {
		return this._counterVal;
	}

	set counterVal(val) {
		this._counterVal = val;
		this.propogateChange(val);
		this.counterValChange.emit(val);
	}

	ngOnChanges(inputs) {
		if (inputs.counterRangeMax || inputs.counterRangeMin) {
			this.validateFn = createCounterRangeValidator(this.counterRangeMax, this.counterRangeMin);
			this.propogateChange(this.counterVal);
		}
	}

	writeValue(val: any): void {
		//console.log('writeValue:', val);
		this._counterVal = val;
		this.propogateChange(val);
		this.counterValChange.emit(val);
	}

	registerOnChange(fn): void {
		this.propogateChange = fn;
	}

	registerOnTouched(): void {}

	increment(): void {
		this.counterVal++;
	}

	decrement(): void {
		if (this.counterVal > 0) {
			this.counterVal--;
		}
	}

	validate(c: FormControl) {
		return this.validateFn(c);
	}

}
