<section-header
	sectionTitle="Academic HR"
	navPath="/ahr_old"
	containerClass=""
	[navLinks]="navLinks"></section-header>

<div class="container-fluid pt-3">
	<div>
		<button class="btn btn-primary btn-sm" (click)="openAHROnboardingModal()">
			+ New Academic
		</button>
	</div>

	<div class="list-group">
		<div class="list-group-item" style="border:none; background:none;">
			<div class="row">
        		<div class="col-md-1">
					Name
				</div>
        		<div class="col-md-2">
					Title / Rank / Step
				</div>
				<div class="col-md-1 text-center">
					Term
				</div>
				<div class="col-md-1 text-center">
					Status
				</div>
				<div class="col-md-2">
					Supervisor(s)
				</div>
				<div class="col-md-3">
					Appointment(s)
				</div>
				<div class="col-md-1 text-right">
				</div>
			</div>
		</div>
	</div>

	<div class="list-group text-sm" [formGroup]="ahrForm">
		<div class="list-group-item" 
			formArrayName="academics" 
			*ngFor="let a of academics; let i = index;">

			<div [ngSwitch]="!!cmds[a.userId]">
				<div *ngSwitchCase="true" class="row" [formGroupName]="i">
         			<div class="col-md-1">
						{{a.fullName}}
					</div>
					<div class="col-md-2">
						<select class="form-control form-control-sm"
							(change)="changeTitle(a)"
							formControlName="titleId">
							<option *ngFor="let t of titles" [value]="t.id">
								{{t.name}}
							</option>
						</select>
						<select class="form-control form-control-sm"
							(change)="changeRank(a)" 
							formControlName="rankId">
							<option *ngFor="let r of titleRankAndStepByUserId[a.userId].availableRanks" [value]="r.id">
								{{r.name}}
							</option>
						</select>
						<select class="form-control form-control-sm" 
							formControlName="stepId">
							<option *ngFor="let s of titleRankAndStepByUserId[a.userId].availableSteps" [value]="s.id">
								Step {{s.number}}
							</option>
						</select>
					</div>
					<div class="col-md-1">
						<select class="form-control form-control-sm" 
							formControlName="termId">
							<option *ngFor="let t of terms" [value]="t.id">
								{{t.name}}
							</option>
						</select>
					</div>
					<div class="col-md-1">
						<select class="form-control form-control-sm" 
							formControlName="statusId">
							<option *ngFor="let s of statuses" [value]="s.id">
								{{s.name}}
							</option>
						</select>
					</div>
					<div class="col-md-2">
						<user-picker
							formControlName="supervisors"
							controlClass="form-control-sm col-md-12"></user-picker>
					</div>
					<div class="col-md-3">
						<unit-picker
							formControlName="appointmentUnits"
							controlClass="form-control-sm col-md-12"></unit-picker>
					</div>
					<div class="col-md-1 text-right">
						<button class="btn btn-block btn-primary" (click)="save(a)" [disabled]="saving[a.userId]">
							Save
						</button>
						<button class="btn btn-sm btn-block btn-link" (click)="cancel(a)" [disabled]="saving[a.userId]">
							Cancel
						</button>
					</div>
				</div>

				<div *ngSwitchCase="false" class="row">
					<div class="col-md-1">
						{{a.fullName}}
					</div>
					<div class="col-md-2">
						{{a.title.name}} / {{!!a.rank.value ? a.rank.value.name : '?'}} / {{!!a.step.value ? a.step.value.name : '?'}}
					</div>
					<div class="col-md-1 text-center">
						{{!!a.term.value ? a.term.value.name : 'N/A'}}
					</div>
					<div class="col-md-1 text-center">
						{{a.status.name}}
					</div>
					<div class="col-md-2">
						<div *ngFor="let s of a.supervisors.toArray()">
							{{s.firstName}} {{s.lastName}}
						</div>
					</div>
					<div class="col-md-3">
						<div *ngFor="let u of a.appointmentUnits.toArray()">
							{{u.name}}
						</div>
					</div>
					<div class="col-md-1 text-right">
						<button class="btn btn-sm btn-block btn-secondary" (click)="edit(a)">
							Edit
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<anr-footer></anr-footer>