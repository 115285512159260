<span *ngIf="displayButton">
  <span [ngSwitch]="!!opportunity.value">
    <span *ngSwitchCase="false">
      Loading...
    </span>
    <span *ngSwitchCase="true" [title]="tooltipText">
      <button class="btn {{btnClass}} {{submitted ? 'btn-success' : 'btn-primary'}}" (click)="submit()"
      [ngSwitch]="submitted"
      [disabled]="disabled">
        <ng-container *ngSwitchCase="true">
          <i *ngIf="submittedIconClass" [class]="submittedIconClass"></i>
          {{submittedText}}
        </ng-container>
        <ng-container *ngSwitchCase="false">
          <i *ngIf="toSubmitIconClass" [class]="toSubmitIconClass"></i>
          {{toSubmitText}}
        </ng-container>
      </button>
    </span>
  </span>
</span>