import { Component, OnInit, EventEmitter, Input, Output, Inject  } from '@angular/core';
import { Activity, Single, ActivityService, ActivityType, ClienteleContactCollection, ActivityTypeCollection, ActivityDate } from '../../domain';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NewActivityModalComponent } from '../new-activity-modal/new-activity-modal.component';
import { Observable } from 'rxjs';
import { ConsoleService } from '../../widgets/console.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { TooltipService } from '../../widgets/tooltip.service';

@Component({
	selector: 'activity-editor-modal',
	templateUrl: './activity-editor-modal.component.html',
	styleUrls: ['./activity-editor-modal.component.scss']
})
export class ActivityEditorModalComponent implements OnInit {
	@Output() close = new EventEmitter();
	@Output() copyActivity = new EventEmitter<Activity>();
	public StaticText: any;
	private _activity: Activity;
	private data: Activity;
	private changedData: Activity;
	wideLayout: boolean;
	formStatus: string = "VALID";
	closing = false;
	activityTypes: ActivityTypeCollection = new ActivityTypeCollection([]);
	@Input() 
	set activity(a) {
		this.console.log('activity was set (activity-editor-modal.component): ',a);
		this._activity = a;
		this.data = a.duplicate();
	}
	get activity() {
		return this._activity;
	}

	ngOnInit() {
		this.console.log("use wide layout (activity-editor-modal.component): ", this.wideLayout);
	}

	exit(){
		if(this.hasChanges() && window.confirm('You may have unsaved changes. Save changes?')){
			this.save();
		}
		this.dialogRef.close();
	}

	canSave(){
		//this.console.log("Form Status (activity-editor-modal.component): ", this.formStatus);
		if (this.formStatus == "VALID"){
			return this.hasChanges();
		}
		return false;
	}

	constructor(
		public dialogRef: MatDialogRef<ActivityEditorModalComponent>,
		private svc: ActivityService,
		private matDialog: MatDialog,
		private snackBar: MatSnackBar,
		private console: ConsoleService,
		public tooltipSvc: TooltipService,
		private gaService: GoogleAnalyticsService,
		@Inject(MAT_DIALOG_DATA) data: any
	){ 
		this.StaticText = this.tooltipSvc.StaticText;

		this.activity = data.activity;
		this.wideLayout = data.wideLayout;

		if (!!data.activityTypes){
			this.activityTypes = data.activityTypes
		} else {
			this.activityTypes = svc.getActivityTypes();
		}
	}

	onActivityChange(a: Activity){
		this.changedData = a;
		this.console.log('activity changed in editor (activity-editor-modal.component):', a);
	}

	onStatusChange(status: string){
		this.console.log("Form Status Change (activity-editor-modal.component): ", status);
		this.formStatus = status;
	}

	save(){
		if (this.canSave()){
			if (this.changedData.date.isBlank()){
				this.snackBar.open('Date is required.', null, {duration: 3000});
			} else {
				this.svc
				.saveActivity(this._activity.id, this.changedData.toDto())
				.then(result => result.matchDo(
					_ => {
						this._activity.patch(this.changedData);
						this.changedData = null;
						this.snackBar.open('Activity updated', null, {duration: 3000});
						this.gaService.eventEmitter("Activity Updated", "Activity", "Update");
					},
					errorMsg => {
						this.snackBar.open('Activity not saved: ' + errorMsg, null, {duration: 10000});
					}));
			}
		} else {
			this.snackBar.open('Please complete the form with appropriate data.', null, {duration: 3000});
		}
	}

	canCopy(){
		// don't allow copy if there are changes made
		if (!this.canSave()){
			return true;
		}
		return false;
	}

	copy(){
		if(this.canCopy()){
			// clear fields Id, Date, Contacts
			let activityCopy = this._activity.duplicate(); 
			activityCopy.id = 0;
			activityCopy.date = new Single(new Date());
			activityCopy.clienteleContacts = ClienteleContactCollection.blank();

			// TODO: May need to validate if some field values are still valid like: parent, clienteleGroup, tags, collaborators etc. As data definitions change over time these could cause issues.

			this.gaService.eventEmitter("Activity Copied", "Activity", "Copy");

			// close editor and open activity using new-activity-modal
			this.copyActivity.emit(activityCopy);
			this.exit();
		} else {
			this.snackBar.open('Copies may only be made of saved activities.', null, {duration: 3000});
		}
	}

	hasChanges(){
		return !!this.changedData;
	}

	cancel(){
		this.dialogRef.close();
	}

	canArchive() {
		return !!this.data;
	}

	archive(){
		if(window.confirm(this.StaticText.ArchiveActivity + '\n\nArchive this Activity?')){
			this.svc
				.archiveActivity(this._activity.id)
				.then(result => result.matchDo(
					_ => {
						let snackBarRef = this.snackBar.open('Activity Archived', 'Undo', {duration: 60 * 1000});
						this.gaService.eventEmitter("Activity Archived", "Activity", "Archive");
						snackBarRef.onAction().subscribe(() => {
							this.console.log('Activity unarchive triggered!  (activity-editor-modal.component)');
							this.unarchive();
						});
						this.dialogRef.close('archived');
					},
					errorMsg => {
						this.snackBar.open('Unable to Archive Activity: ' + errorMsg, null, {duration: 10 * 1000});
					}));
		}
	}

	unarchive(){
		this.svc
			.unarchiveActivity(this._activity.id)
			.then(result => result.matchDo(
				_ => {
					this.snackBar.open('Activity Restored', null, {duration: 3000});
					this.gaService.eventEmitter("Activity Restored", "Activity", "Restore");
				},
				errorMsg => {
					this.snackBar.open('Unable to Restore Activity: ' + errorMsg, null, {duration: 10 * 1000});
				}));
	}
}
