
import {distinctUntilChanged, debounceTime, startWith} from 'rxjs/operators';
import { Input, Component, OnInit, ElementRef, ViewChild, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { UserService, UserWithEmplId, SortedSet } from '../../domain';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { partition } from 'rxjs';
import { ConsoleService } from '../console.service';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
	selector: 'user-picker',
	templateUrl: './user-picker.component.html',
	styleUrls: ['./user-picker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: UserPickerComponent,
			multi: true
		}
	]
})
export class UserPickerComponent implements OnInit, AfterViewInit {
ngAfterViewInit(): void {
		this.console.log('autoComplete: ', this.autoComplete);
	}
	@Output()
	public readonly newNonAnrCollaboratorRequest: EventEmitter<string> = new EventEmitter();
	@Input() controlClass: string = '';
	@Input() enableNonANRCollaborator: boolean = false;
	@Input() selectSingle: boolean = false;
	@Input() filterIds: number[] = [];
	@Input() updateUserTableFromDirectory: boolean = true;
	@Input() showUserIds = false;
	users = [];
	searchControl = new FormControl();
	@ViewChild('auto') autoComplete: MatAutocomplete;
	selectedUsers: SortedSet<UserWithEmplId> = new SortedSet<UserWithEmplId>();
	lastTerm: string = '';
	@ViewChild('input') input: ElementRef;
	formLabelClass = "col-4";
	formControlClass = "col-8";

	newNonAnrCollaborator(){
		this.newNonAnrCollaboratorRequest.emit(this.searchControl.value);
		this.autoComplete.showPanel = false;
	}

	removeUser(user: UserWithEmplId): void {
		if(this.selectedUsers.remove(user)){
			this._onChange(this.selectedUsers);
			this._refreshAvailableUsers();
		}
	}

	displayWith(user){
		return user.firstName + ' ' + user.lastName;
	}

	canAddUser(): boolean {
		return !this.selectSingle 
			|| this.selectedUsers.count == 0;
	}

	ngOnInit() { }

		constructor(
		private console: ConsoleService,
		private readonly svc: UserService
	){
	
		let [selections, termChanges] = 
			partition(
				this.searchControl
					.valueChanges
					.pipe(startWith(null)),
				o => o instanceof UserWithEmplId);

		selections
			.subscribe(o => {
				let user = <UserWithEmplId>o;
				if(this.canAddUser()){
					if(this.selectedUsers.add(user)){
						this._onChange(this.selectedUsers);
						if (this.updateUserTableFromDirectory)
							this.svc.importFromDirectoryIfNecessary(user.id);
					}
				}
				this.input.nativeElement.value = '';
				this.searchControl.setValue('');
				this._refreshAvailableUsers();
				this.input.nativeElement.blur();
			})

		termChanges.pipe(
			debounceTime(200),
			distinctUntilChanged(),)
			.subscribe(term => {
				this.lastTerm = term;
				if (this.canAddUser()) {
					this.console.log('refreshing available users');
					this._refreshAvailableUsers();
				} else {
					this.users = [];
				}
			});
	}

	refreshing = false;
	private _refreshAvailableUsers(): void {
		this.refreshing = true;
		this.svc
			.search(this.lastTerm, 10)
			.then(users => {
				this.refreshing = false;
				this.users = users.filter(user => !this.selectedUsers.contains(user))
				if (this.filterIds.length > 0){
					this.users = users.filter(user => !this.filterIds.includes(user.id));
				}
			});
	}
	
	//
	//ControlValueAccessor implementation:
	private _onChange: any = () => {};
	private _onTouch:  any = () => {};
	writeValue(val: SortedSet<UserWithEmplId>): void {
		//console.log('writeValue:', val);
		this.selectedUsers = val;
		//this._onChange(val);
	}
	registerOnTouched(fn): void { this._onTouch  = fn; }
	registerOnChange(fn) : void { this._onChange = fn; }
}
