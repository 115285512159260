export enum ActivityDateType {
	Single = "Single",
	Range = "Range",
	Ongoing = "Ongoing"
}

export abstract class ActivityDate {

	constructor(public readonly typeId: string){}

	public static fromJson(json: any): ActivityDate {
		//console.log('ActivityDate.fromJson: ', json);
		switch(json.typeId){
			case "Single":
				return new Single(
					new Date(json.date));
			case "Range":
				return new Range(
					new Date(json.start),
					new Date(json.end));
			case "Ongoing":
				return new Ongoing(
					new Date(json.start));
		}
		throw ("unexpected ActivityDate.typeId: " + json.typeId);
	}

	public static create(start: Date, end: Date, activityDateType: ActivityDateType): ActivityDate {
		switch(activityDateType){
			case ActivityDateType.Single:
				return new Single(start);
			case ActivityDateType.Range:
				return new Range(start,end);
			case ActivityDateType.Ongoing:
				return new Ongoing(start);
		}
	}
	
	public abstract match<T>(
		single: (single: Single) => T,
		range: (range: Range) => T,
		ongoing: (ongoing: Ongoing) => T): T;

	public abstract matchDo(
		single: (single: Single) => void,
		range: (range: Range) => void,
		ongoing: (ongoing: Ongoing) => void): void;

	public abstract duplicate(): ActivityDate;

	public abstract isBlank(): boolean;
}

export class Single extends ActivityDate {
	constructor(public readonly date: Date) {
		super("Single");
	}

	public duplicate(): ActivityDate
	{
		return new Single(this.date);
	}

	public match<T>(
		single: (single: Single) => T,
		range: (range: Range) => T,
		ongoing: (ongoing: Ongoing) => T): T 
	{
		return single(this);
	} 

	public matchDo(
		single: (single: Single) => void,
		range: (range: Range) => void,
		ongoing: (ongoing: Ongoing) => void): void 
	{
		single(this);
	}

	public isBlank(): boolean {
		return !this.date;
	}
}

export class Range extends ActivityDate {
	constructor(
		public readonly startDate: Date,
		public readonly endDate: Date
	) {
		super("Range");
	}

	public duplicate(): ActivityDate
	{
		return new Range(this.startDate, this.endDate);
	}

	public match<T>(
		single: (single: Single) => T,
		range: (range: Range) => T,
		ongoing: (ongoing: Ongoing) => T): T 
	{
		return range(this);
	} 

	public matchDo(
		single: (single: Single) => void,
		range: (range: Range) => void,
		ongoing: (ongoing: Ongoing) => void): void 
	{
		range(this);
	}

	public isBlank(): boolean {
		return !this.startDate || !this.endDate;
	}
}

export class Ongoing extends ActivityDate {
	constructor(public readonly startDate: Date) {
		super("Ongoing");
	}

	public duplicate(): ActivityDate
	{
		return new Ongoing(this.startDate);
	}

	public match<T>(
		single: (single: Single) => T,
		range: (range: Range) => T,
		ongoing: (ongoing: Ongoing) => T): T 
	{
		return ongoing(this);
	} 

	public matchDo(
		single: (single: Single) => void,
		range: (range: Range) => void,
		ongoing: (ongoing: Ongoing) => void): void 
	{
		ongoing(this);
	}

	public isBlank(): boolean {
		return !this.startDate;
	}
}