import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../app-settings';
import { AllAvailableTags, Tag, TagType, TagService as SVC, Result } from '../domain';

@Injectable()
export class TagService implements SVC {
	constructor(private http: HttpClient, private appSettings: AppSettings){ }

	getAllAvailableTags(): Promise<AllAvailableTags> {
		return this.http.get(this.appSettings.APIURL + '/tag')
		.toPromise()
		.then(AllAvailableTags.fromJson);
	}

	search(term: string, max: number): Promise<Tag[]> {
		return this.http.get(this.appSettings.APIURL + '/tag/search?term='+ term + '&max=' + max)
		.toPromise()
		.then(json => (<any[]>json).map(Tag.fromJson));
	}

	createUserDefinedTags(tagName: string): Promise<Result<Tag,string>> {
		return this.http.post(this.appSettings.APIURL + '/tag/user_defined?name=' + tagName, {})
			.toPromise()
			.then(json => Result.success<Tag,string>(Tag.fromJson(json)));
	}

	createUserDefinedConditionChangeTag(tagName: string): Promise<Result<Tag, string>> {
		return this.http.post(this.appSettings.APIURL + '/tag/user_defined_condition_change?name=' + tagName, {})
			.toPromise()
			.then(json => Result.fromJson(json, Tag.fromJson, str => str));
	}
}