import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../domain';

@Pipe({
	name: 'userAvatarUrl'
})
export class UserAvatarUrlPipe implements PipeTransform {
	constructor(private svc: UserService){ }

	transform(value: any, args?: any): any {
		if(typeof value === 'number'){
			return this.svc.getAvatarUrl(value);
		}
		if('id' in value){
			return this.svc.getAvatarUrl(value.id);
		}
		return '/NOT-FOUND';
	}
}
