import { Activity } from './activity';
import { ChildSelector } from '../child-selector';

export class Project
{
	public readonly activitySelector: ChildSelector<Activity>;
	constructor(
		public readonly id: number,
		public readonly name: string,
		public readonly startDate: Date,
		public includeInDossier: boolean,
		public readonly role: string,
		public readonly activities: Activity[],
		public readonly lastActivity: Date | null,
		public readonly lastUpdated: Date | null
	) 
	{ 
		this.activitySelector = new ChildSelector<Activity>(
			this.activities,
			a => a.includeInDossier,
			(a,s) => a.includeInDossier = s);
	}

	public static fromJson(o: any): Project {
		return new Project(
			<number>o.id,
			<string>o.name,
			<Date>o.startDate,
			<boolean>o.includeInDossier,
			<string>o.role,
			(<any[]>o.activities).map(Activity.fromJson),
			<Date|null>o.lastActivity,
			<Date|null>o.lastUpdated);
	}
}
