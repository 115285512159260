import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
	Column,
	Direction,
	Sort,
	Sorting
} from '../column-sort';

@Component({
	selector: 'sort-column-header',
	templateUrl: './sort-column-header.component.html',
	styleUrls: ['./sort-column-header.component.scss'],
	host: { '(click)': 'next()' }
})
export class SortColumnHeaderComponent {
	@Input() column: Column<any,any>;
	@Input() currentSorting: Sorting<any,any>;
	@Output() currentSortingChange = new EventEmitter<Sorting<any,any>>();

    get direction() {
        return this.currentSorting.column == this.column
            ? Direction[this.currentSorting.direction]
			: null;
	}

	next() {
		var nextSorting = this.currentSorting.getNext(this.column);
		this.currentSortingChange.emit(nextSorting);
	}
}
