import {
   	Input,
   	Output,
   	EventEmitter,
   	Component,
	HostListener,
	AfterViewChecked,
	OnInit,
	Injectable
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class InlineEditorService {
	private pendingChanges = new BehaviorSubject<boolean>(false);
	public pendingChanges$ = this.pendingChanges.asObservable();

	setPendingChanges(pendingChange: boolean) {
		console.log("Textboxio has Pending Changes:", pendingChange);
		this.pendingChanges.next(pendingChange);
	}
}

@Component({
	selector: 'inline-editor',
	templateUrl: './inline-editor.component.html',
	styleUrls: ['./inline-editor.component.scss']
})
export class InlineEditorComponent implements AfterViewChecked, OnInit {
	@Input() saveFn: (() => Promise<boolean>);
	@Input() editStartFn: (() => Promise<boolean>);
	@Input() edited: boolean = false;
	@Output() private transitionToEdit: EventEmitter<null> = new EventEmitter<null>();
	@Output('cancel') private onCancel: EventEmitter<null> = new EventEmitter<null>();
	@Input() toolbarPosition: 'left' | 'right' = 'left';
	@Input() noOuterBorder: boolean = false;
	
	private _transitioningToEdit = false;
	private _hasPendingChanges: boolean = false;

	_state: 'viewing' | 'editing' | 'saving' = 'viewing';
	get state() { return this._state; }
	get saving() { return this._state === 'saving'; }

	constructor(private inlineEditorService: InlineEditorService){}

	edit(){

		if (this._hasPendingChanges) {
			alert("You may have unsaved changes. Please save or cancel before proceeding.");
			return;
		}

		if(!!this.editStartFn && typeof(this.editStartFn) == 'function'){
			this.editStartFn().then(success => {
				if(!success) {
					this.cancel();
				} else {
					this._state = 'editing';
					this._transitioningToEdit = true;
				}
			});
		} else {
			this._state = 'editing';
			this._transitioningToEdit = true;			
		}
	}

	ngOnInit() {
		this.inlineEditorService.pendingChanges$.subscribe((pendingChanges) => {
			this._hasPendingChanges = pendingChanges;
		})
	}

	ngAfterViewChecked(){
		// The things a client would want to do after transitiong the edit state
		// would likely rely on the edit state (ie. and the DOM elements to be used to do the editing)
		// being rendered and ready to go.
		if(this._transitioningToEdit){
			this.transitionToEdit.emit();
			this._transitioningToEdit = false;
		}
	}

	save(){
		if(!this.saveFn || typeof(this.saveFn) !== 'function'){
			alert('saveFn must be a function that returns a Promise');
			return;
		}
		this._state = 'saving';
		this.saveFn().then(success => {
			if(!success) {
				this.cancel();
			} else {
				this._state = success === true ? 'viewing' : 'editing';
			}
		});
	}

	cancel(){
		this._state = 'viewing';
		this.onCancel.emit();
	}

	//Keyboard support
	@HostListener('keydown.enter')
	private onPressedEnter(){
		if(this._state == 'viewing'){
			this.edit();
			return;
		}
		if(this._state == 'saving' || !this.edited){
			return;
		}
		this.save();
	}
	@HostListener('keydown.escape')
	private onPressedEscape(){
		if(this._state != 'editing'){
			return;
		}
		this.cancel();
	}
}
