
import { distinctUntilChanged, debounceTime, startWith } from 'rxjs/operators';
import { Input, Component, OnInit, OnChanges, ElementRef, ViewChild } from '@angular/core';
import { AllAvailableTags, Commodity, Tag, TagType, AllTagTypes, TagService, SortedSet } from '../../domain';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LEFT_ARROW, RIGHT_ARROW } from '@angular/cdk/keycodes';
import { ConsoleService } from '../../widgets/console.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { partition } from 'rxjs';
import { TooltipService } from '../../widgets/tooltip.service';

//Old Material source https://github.com/angular/material2/blob/1cfce8d9ab047d447465bd4e233fd26893830328/src/lib/autocomplete/autocomplete.md

@Component({
    selector: 'tag-picker',
    templateUrl: './tag-picker.component.html',
    styleUrls: ['./tag-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: TagPickerComponent,
            multi: true
        }
    ],
    host: {
        '(keydown)': '_handleKeydown($event)',
    }
})
export class TagPickerComponent implements OnInit, OnChanges, ControlValueAccessor {
    @Input() controlClass: string = '';
    @Input() tagTypes: TagType[] = AllTagTypes;

    availableTags: AllAvailableTags;
    commodities: Commodity[] = [];
    searchControl = new FormControl("");
    tags: Tag[] = [];
    TagType = TagType;
    selectedTags: SortedSet<Tag> = new SortedSet<Tag>();
    lastTagType: TagType = TagType.StrategicInitiative;
    lastTerm: string = '';
    showAddTagBtn: boolean = false;
    @ViewChild('input') input: ElementRef;

    constructor(
        private readonly svc: TagService, 
        private console: ConsoleService,
        private snackbar: MatSnackBar,
        public tooltipSvc: TooltipService
    ) {
        const [tagSelections, termChanges] =
            partition(
                this.searchControl
                    .valueChanges.pipe(startWith(null)),
                o => o instanceof Tag);

        tagSelections
            .subscribe(o => this.selectTag(<Tag>o));

        termChanges.pipe(
            debounceTime(200),
            distinctUntilChanged(),)
            .subscribe(term => {
                this.lastTerm = term;
                this.refreshAvailableTags();
            });
    }

    showEvent(e: any){
        this.console.log("Event: ", e);
    }

    removeTag(tag: Tag): void {
        if (this.selectedTags.remove(tag)) {
            this._onChange(this.selectedTags);
            this.refreshAvailableTags();
        }
    }

    changeTagType(tagType: TagType) {
        this.lastTagType = tagType;
        this.refreshAvailableTags();
    }

    selectTag(tag: Tag){
        if (this.selectedTags.add(tag)) {
            this._onChange(this.selectedTags);
        }
        this.input.nativeElement.value = '';
        this.searchControl.setValue('');
        this.refreshAvailableTags();
        this.input.nativeElement.blur();
    }

    mayCreateUserDefinedTagFor(name: string) {
        return (this.lastTagType == TagType.UserDefined || this.lastTagType == TagType.UserDefinedConditionChange)
            && !!name 
            && this.tags.length == 0
            && this.filterTagsWithTerm(TagType.UserDefined, name, true).length == 0;
    }

    createUserDefinedTag(name: string) {
        let promise = 
            this.lastTagType == TagType.UserDefinedConditionChange
            ? this.svc.createUserDefinedConditionChangeTag(name)
            : this.svc.createUserDefinedTags(name);
        promise
        .then(result => 
            result.matchDo(
                newTag => {
                    this.selectedTags.add(newTag);
                    this.refreshAvailableTags();
                    this.input.nativeElement.blur();
                },
                errorMsg => {
                    this.snackbar.open('Unable to create user-defined tag: ' + errorMsg);
                }))
        .catch(error => {
            this.snackbar.open('Unable to create user-defined tag (unknown reason)');
            this.console.log("Error: ", error);
        });
        this.lastTerm = '';
        this.input.nativeElement.blur();
    }

    public refreshAvailableTags(){
        if(!this.availableTags){
            return;
        }
        const lowerTerm = this.lastTerm && this.lastTerm.toLowerCase();
        if(this.lastTagType == TagType.Commodity){
            this.commodities = this.filterCommoditiesWithTerm(lowerTerm);
        } else {
            this.tags = this.filterTagsWithTerm(this.lastTagType, lowerTerm, false);
        }
    }

    private filterTagsWithTerm(tt: TagType, term: string, mayBeSelectedAlready: boolean){
        return this.availableTags.get(tt)
            .filter(tag => !term || this.searchVal(tag).toLowerCase().indexOf(term) > -1)
            .filter(tag => mayBeSelectedAlready || !this.selectedTags.contains(tag));
    }

    private searchVal(tag:Tag){
        if (this.tooltipSvc.tooltip('TagDisplayText.'+ tag.hashString)) {
			return `${this.tooltipSvc.tooltip('TagDisplayText.'+ tag.hashString)}`;
		}
		return tag.name;
    }

    private filterCommoditiesWithTerm(searchTerm: string): Commodity[] {        
        return !searchTerm 
            ? this.availableTags.Commodities
            : choose(this.availableTags.Commodities, filterRecursive);

        function filterRecursive(c: Commodity): Commodity | null {
            let filteredChildren = choose(c.children, filterRecursive);
            return filteredChildren.length > 0 || c.name.toLowerCase().indexOf(searchTerm) > -1
               ? new Commodity(c.id, c.name, filteredChildren)
               : null;
        }

        function choose<T>(
            items: T[],
            chooseFn: (item: T) => T | null): T[]
        {
            return items
                .map(chooseFn)
                .filter(item => item != null);
        }
    }

    displayWith(tag) {
        return tag.name;
    }

    ngOnChanges(changes){
        if('tagTypes' in changes){
            let tts = (<TagType[]>changes.tagTypes.currentValue);
            this.lastTagType = tts[0];
            this.console.log('tagTypes changed:', tts, tts[0]);
            this.refreshAvailableTags();
        }
    }

    ngOnInit() {
        this.svc.getAllAvailableTags().then((allAvailableTags) => {
            this.availableTags = allAvailableTags;
            // refresh tags onInit so the component knows it has tags to display. Note - ngOnInit runs last after constructor
            this.refreshAvailableTags();
        });
    }
    
    // ControlValueAccessor implementation:
    private _onChange: any = () => { };
    private _onTouch: any = () => { };
    writeValue(val: SortedSet<Tag>): void {
        // this.console.log('writeValue:', val);
        this.selectedTags = val;
        // this._onChange(val);
    }
    registerOnTouched(fn): void { this._onTouch = fn; }
    registerOnChange(fn): void { this._onChange = fn; }

    _handleKeydown(event: KeyboardEvent): void {
        if (event.keyCode === LEFT_ARROW) {
            this.console.log("Left Arrow");
            this._selectTagTypeLeft();
            event.stopPropagation();
        } else if (event.keyCode === RIGHT_ARROW) {
            this.console.log("Right Arrow");
            this._selectTagTypeRight();
            event.stopPropagation();
        }
    }

    private _selectTagTypeLeft(){
        // get current or default tag type index
        let index = this.tagTypes.indexOf(this.lastTagType);

        // get tagtype of previous index
        let newTagType = this.tagTypes[index-1 >= 0 ? index-1 : this.tagTypes.length-1];

        // set new tag type
        this.changeTagType(newTagType);
    }

    private _selectTagTypeRight(){
        // get current or default tag type index
        let index = this.tagTypes.indexOf(this.lastTagType);

        // get tagtype of next index
        let newTagType = this.tagTypes[index+1 <= this.tagTypes.length-1 ? index+1 : 0];

        // set new tag type
        this.changeTagType(newTagType);
    }
}
