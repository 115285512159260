import { Injectable } from '@angular/core';

export interface SearchPredicate<T> {
	(term: string, item: T): boolean;
}

export interface StringProjector<T> {
	(item: T): string;
}

export interface SearchResultsFunction<T> {
	(items: T[], term: string): T[];
}

export class SearchContext<T> {
	private getResults: SearchResultsFunction<T>;
	constructor(public items: T[], private predicate: SearchPredicate<T>){
		this.getResults = ResultsFunction.fromPredicate(predicate);
	}
	results(term: string): T[] {
		return this.getResults(this.items, term);
	}
}

export namespace ResultsFunction {
	export function fromPredicate<T>(predicate: SearchPredicate<T>): SearchResultsFunction<T>{
		var filter = predicateToFilter(predicate);
		return (items: T[], term: string) => 
			items.filter(item => filter(term, item));
	}
}

function predicateToFilter<T>(predicate: SearchPredicate<T>): SearchPredicate<T> {
	return function(term: string, item: T){
		if(!term){
			return true;
		}
		return predicate(term.toLowerCase(), item);
	};
}

export namespace Predicate {
	export function strings(): SearchPredicate<string> {
		return (term, str) => {
			return str == null
				? false
				: str.toLowerCase().indexOf(term) > -1;
		};
	}

	export function objectKey(key: string){
		var getter = obj => obj[key];
		return function(term, item){
			var text = getter(item);
			return text == null
				? false
				: text.toString().toLowerCase().indexOf(term) > -1;
		};
	}

	export function objectKeys(keys: string[]){
		var getters = keys.map(key => obj => obj[key]);
		return function(term, item){
			for(let i=0;i<getters.length;i++){
				let getter = getters[i];
				let text = getter(item);
				if(text != null && text.toString().toLowerCase().indexOf(term) > -1){
					return true;
				}
			}
			return false;
		};
	}

	export function objectGetter<T>(stringFn: StringProjector<T>): SearchPredicate<T> {
		return function(term, item){
			var text = stringFn(item);
			return text == null
				? false
				: text.toString().toLowerCase().indexOf(term) > -1;
		};
	}

	export function objectStringProjector<T>(stringFn: StringProjector<T>): SearchPredicate<T> {
		return function(term, item){
			var text = stringFn(item);
			return text == null
				? false
				: text.toString().toLowerCase().indexOf(term) > -1;
		};
	}

	export function objectStringProjectors<T>(stringProjectors: StringProjector<T>[]): SearchPredicate<T> {
		var getters = stringProjectors;
		return function(term, item){
			for(let i=0;i<getters.length;i++){
				let getter = getters[i];
				let text = getter(item);
				if(text != null && text.toString().toLowerCase().indexOf(term) > -1){
					return true;
				}
			}
			return false;
		};
	}
}
