<div mat-dialog-title>Bulk Activity Update</div>
<mat-dialog-content>
	<div class="row align-items-center">
		<div class="col-4">
			<label class="col-form-label">
				Theme / Project 
				<div class="text-xs">
					(Required for Dossier)
				</div>
			</label>
		</div>
		<div style="width: 300px;">
				<activity-parent-picker
						[(ngModel)]="activityParent"
						controlClass="form-control-sm"
						inputGroupClass="input-group-sm"></activity-parent-picker>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button (click)="cancel()" class="btn btn-link" [disabled]="closing">
		Cancel
	</button>
	<button [disabled]="!activityParent" (click)="save()" class="btn btn-primary">
		Save {{selectedActivityCount}} Activities
	</button>
</mat-dialog-actions>
