import { Component, OnInit, OnDestroy } from '@angular/core';
import { ExploreWhatsHappeningService, Tag, SortedSet, ActivityTagTypes, TagType, HumanResources, AuthenticationService, AuthenticatedIdentity, Academic } from '../../domain';
import { SavedSearch, Page, Activity, ActivitySpecification, SavedSearchDto } from '../../domain/types/explore-whats-happening';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConsoleService } from '../../widgets/console.service';
import { TooltipService } from '../../widgets/tooltip.service';
import { Project } from '../../domain/types/explore-whats-happening/project';
import { Theme } from '../../domain/types/explore-whats-happening/theme';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'explore-whats-happening',
  templateUrl: './explore-whats-happening.component.html',
  styleUrls: ['./explore-whats-happening.component.scss']
})
export class ExploreWhatsHappeningComponent implements OnDestroy {
	introductorySearchPerformed = false;
	searches: SavedSearch[] = [];
	currentPage: Page<Activity> = null;
	currentProjectPage: Page<Project> = null;
	currentThemePage: Page<Theme> = null;
	activities: Activity[] = null;
	projects: Project[] = null;
	themes: Theme[] = null;
	downloadActivities: Activity[] = null;
	downloadProjects: Project[] = null;
	downloadThemes: Theme[] = null;
	spec: ActivitySpecification = null;
	form: FormGroup;
	ActivityTagTypes: TagType[] = ActivityTagTypes;
	defaultSearches: [string,ActivitySpecification][] = null;
	meritAndPromotionHR = HumanResources.ANR;
	exploreType: string = 'Activity';
	loading: boolean = false;
  	constructor(
		private readonly svc: ExploreWhatsHappeningService,
		authSvc: AuthenticationService,
		fb: FormBuilder,
		private snackBar: MatSnackBar,
		private console: ConsoleService,
		public tooltipSvc: TooltipService,
		private readonly datePipe: DatePipe,
  	) {
		this.console.log("Explore Whats Happening Component - Initialized");
		this.form = fb.group({
			keyword: '',
			tags: new SortedSet<Tag>()
		});
		svc.usedTags().subscribe(tags => {
			this.form.controls['tags'].setValue(new SortedSet(tags));
			this.defaultSearches = [
				["All", new ActivitySpecification('', [])],
				["Your Tags", new ActivitySpecification('', tags)]
			];
			if(!this.introductorySearchPerformed){
				this.spec = new ActivitySpecification('', tags.slice(0));
				this.svc.explore(this.spec, 1);
				this.svc.exploreProject(this.spec, 1);
				this.svc.exploreTheme(this.spec, 1);
				this.introductorySearchPerformed = true;
			}
		});
		svc.savedSearches().subscribe(searches => {
			this.searches = searches;
		});
		svc.currentActivityPage().subscribe(page => {
			this.currentPage = page;
			
			if(this.activities == null){
				this.activities = [];
			}

			page.items.forEach(item => this.activities.push(item));
		});
		svc.currentProjectPage().subscribe(page => {
			this.currentProjectPage = page;			
			if(this.projects == null){
				this.projects = [];
			}
			page.items.forEach(item => this.projects.push(item));
		});
		svc.currentThemePage().subscribe(page => {
			this.currentThemePage = page;			
			if(this.themes == null){
				this.themes = [];
			}
			page.items.forEach(item => this.themes.push(item));
		});
		authSvc.currentIdentity.subscribe(identity => 
			identity.doWithValue((i: AuthenticatedIdentity) => 
				i.user.roles.academic.doWithValue((a: Academic) =>
				{
					this.meritAndPromotionHR = a.meritAndPromotionHr;
				})));
	}

	hasInstructions() {
		if (this.getInstructions().length > 0) {
			return true;
		} 
		return false;
	}

	getInstructions(): string {
		return this.tooltipSvc.tooltip("ExploreInstructions." + this.tooltipSvc.mapMeritAndPromotionsHRToString(this.meritAndPromotionHR));
	}

	ngOnDestroy() {
		this.activities = null;
		this.projects = null;
		this.themes = null;
		this.console.log("Explore Whats Happening Component - Destroyed");
	}

	nextPageExists(){
		if(this.exploreType === 'Activity'){
		  if(!this.spec || !this.currentPage){
		 	return false;
		   }
		  return this.currentPage.pageNumber < this.currentPage.totalPageCount;
		}
		else if(this.exploreType === 'Project')
		  return this.nextProjectPageExists();
		else if(this.exploreType === 'Theme')
		   return this.nextThemePageExists();
	}
	nextProjectPageExists(){
		if(!this.spec || !this.currentProjectPage){
			return false;
		}
		return this.currentProjectPage.pageNumber < this.currentProjectPage.totalPageCount;
	}
	nextThemePageExists(){
		if(!this.spec || !this.currentThemePage){
			return false;
		}
		return this.currentThemePage.pageNumber < this.currentThemePage.totalPageCount;
	}
	nextPage(){
		if(!this.spec || !this.currentPage){
			return;
		}
		if(this.exploreType === 'Activity')
		  this.svc.explore(this.spec, this.currentPage.pageNumber+1);
	    else if(this.exploreType === 'Project')
		  this.nextProjectPage()
		else if(this.exploreType === 'Theme')
		   this. nextThemePage();
	}
	nextProjectPage(){
		if(!this.spec || !this.currentProjectPage){
			return;
		}
		this.svc.exploreProject(this.spec, this.currentProjectPage.pageNumber+1);
	}
	nextThemePage(){
		if(!this.spec || !this.currentThemePage){
			return;
		}
		this.svc.exploreTheme(this.spec, this.currentThemePage.pageNumber+1);
	}
	explore(type: string){
		this.exploreType = type;
		this.exploreWith(this.formValueToSpec());
	}
	private formValueToSpec(): ActivitySpecification {
		return new ActivitySpecification(
			this.form.value.keyword,
			(<SortedSet<Tag>>this.form.value.tags).toArray());
	}

	private formValueFromSpec(spec: ActivitySpecification, exploreType: string): void {
		this.form.setValue({
			keyword: spec.matchingKeyword,
			tags: new SortedSet(spec.havingAtLeastOneTagOf)
		});
		this.exploreType = exploreType;
	}

  	saveCurrentSearch(){
    	let name = prompt("Please name your search");
    	let trimmedName = (name || '').trim();
    	if(trimmedName.length == 0){
      		return;
    	}
    	var data = new SavedSearchDto(trimmedName, this.formValueToSpec());

		this.svc.saveSearch(data).then(result => 
		result.matchDo(
			savedSearch => {
				this.snackBar.open("Search saved", null, {duration:10000});
			},
			errorMessage => {
				this.snackBar.open("Search not saved: " + errorMessage, null, {duration:10000});
			}));
	}

	exploreWithSavedSearch(s: SavedSearch){
		if(this.exploreType === 'Activity'){
			this.activities = null;
			this.currentPage = null;
			this.formValueFromSpec(s.spec,'Activity');
			this.svc.exploreSavedSearch(s.id, 1);
		}
		else if(this.exploreType === 'Project'){
			this.projects =null;
			this.currentProjectPage = null;
			this.formValueFromSpec(s.spec,'Project');
			this.svc.exploreSavedSearchProject(s.id, 1);
		}
		else if(this.exploreType === 'Theme'){
			this.themes =null;
			this.currentThemePage = null;
			this.formValueFromSpec(s.spec,'Theme');
			this.svc.exploreSavedSearchTheme(s.id, 1);
		}
		this.spec = s.spec;
	}

	exploreWith(spec: ActivitySpecification){
		if(this.exploreType === 'Activity')
		{
			this.spec = spec;
			this.activities = null;
			this.currentPage = null;
			this.formValueFromSpec(spec,'Activity');
			this.svc.explore(this.spec, 1);
		}
		else if(this.exploreType === 'Project')
		this.exploreWithProject(this.formValueToSpec());
		else if(this.exploreType === 'Theme')
		this.exploreWithTheme(this.formValueToSpec());
	}
	exploreWithProject(spec: ActivitySpecification){
		this.spec = spec;
		this.projects = null;
		this.currentProjectPage = null;
		this.formValueFromSpec(spec,'Project');
		this.svc.exploreProject(this.spec, 1);
	}
	exploreWithTheme(spec: ActivitySpecification){
		this.spec = spec;
		this.themes = null;
		this.currentThemePage = null;
		this.formValueFromSpec(spec,'Theme');
		this.svc.exploreTheme(this.spec, 1);
	}
	toHTML(input) : any {
		return new DOMParser().parseFromString(input, "text/html").documentElement.textContent;
	}
	public exportToExcel(){
		let startdate = null;
		let endDate = null;
		let activityList = [];
		if(this.downloadActivities && this.downloadActivities.length >0){
          activityList = this.downloadActivities.map(x=> {
			x.date.matchDo(
				single => {
					startdate = single.date;
					endDate = single.date;
				},
				range => {
					startdate = range.startDate;
					endDate = range.endDate;
				},
				ongoing => {
					endDate = ongoing.startDate;
				});
           const activityObj:any ={
             ActivityOwnerName: x.owner?.name,
			 Email: x.owner?.email,
			 AcademicTitle : x.owner?.title,
			 ActivityName: x.name,
			 ActivityType: x.type,
			 ActivitySubType: x.subType,
			 Role: x.role,
			 Topic: x.topic,
			 Location: x.location,
			 Startdate:	startdate,
			 EndDate:endDate,
			 ActivitylastUpdatedDate: x.activitylastUpdatedDate,
			 ActivityDateType: x.activityDateType
		   }
			return activityObj;
		});
	  }
        let fileName = `ExploreReport.xlsx`;
		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(activityList);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Activity Report');
		const projectList = this.exportProjectToExcel();
		const wsProject: XLSX.WorkSheet = XLSX.utils.json_to_sheet(projectList);
		XLSX.utils.book_append_sheet(wb, wsProject, 'Project Report');
		const themeList = this.exportThemeToExcel();
		const wsTheme: XLSX.WorkSheet = XLSX.utils.json_to_sheet(themeList);
		XLSX.utils.book_append_sheet(wb, wsTheme, 'Theme Report');

		XLSX.writeFile(wb, fileName);
	}
	public exportProjectToExcel(){
	  let projectList = [];
	  if(this.downloadProjects && this.downloadProjects.length > 0){
         projectList = this.downloadProjects.map(x=> {
           const projectObj:any ={
             ProjectOwnerName: x.owner?.name,
			 Email: x.owner?.email,
			 AcademicTitle : x.owner?.title,
			 ProjectName: x.name,
			 Description: this.toHTML(x.description),
			 Role: x.role,
			 Startdate:x.startDate,
			 ProjectlastUpdatedDate: x.projectlastUpdatedDate
		   }
			return projectObj;
		});
	  }
	  return projectList;
	}
	public exportThemeToExcel(){
	  let themeList = [];
      if(this.downloadThemes && this.downloadThemes.length>0){
          themeList = this.downloadThemes.map(x=> {
           const themeObj:any ={
             ThemeOwnerName: x.owner?.name,
			 Email: x.owner?.email,
			 AcademicTitle : x.owner?.title,
			 ThemeName: x.name,
			 ThemelastUpdatedDate: x.themelastUpdatedDate
		   }
			return themeObj;
		});
	  }
	  return themeList;
	}
	public downloadReport(){
		this.downloadActivities = [];
		this.downloadProjects = [];
		this.downloadThemes = [];
		this.loading =true;
		this.svc.downloadExploreReport(this.formValueToSpec()).subscribe(response => {
		  this.loading =false;
		  let actvities = Page.fromJson(response[0], Activity.fromJson);
		  actvities.items.forEach(item => this.downloadActivities.push(item));
		  let projects = Page.fromJson(response[1], Project.fromJson);
		  projects.items.forEach(item => this.downloadProjects.push(item));
		  let themes = Page.fromJson(response[2], Theme.fromJson);
		  themes.items.forEach(item => this.downloadThemes.push(item));
		  this.exportToExcel();
		},err =>{
			this.loading =false;
			this.snackBar.open("Download Failed", null, {duration:10000});
		});
	}
}