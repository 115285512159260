import { Component, OnInit } from '@angular/core';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { ConsoleService } from '../../widgets/console.service';

@Component({
	templateUrl: './user-roles-switch.component.html',
	styleUrls: ['./user-roles-switch.component.scss']
})
export class UserRolesSwitchComponent implements OnInit {
	loading = true;

	constructor(
    	router: Router,
		private readonly console: ConsoleService
	) { 
		router.events.subscribe((event: RouterEvent) => {
			this.navigationInterceptor(event);
		})
	}

	ngOnInit() {
		this.console.log('User Roles switch initialized');
	}
	  
	// Shows and hides the loading spinner during RouterEvent changes
  	private navigationInterceptor(event: RouterEvent): void {
		if (event instanceof NavigationStart) 
		{
    		this.loading = true
		}
		
		if (event instanceof NavigationEnd
			|| event instanceof NavigationCancel
			|| event instanceof NavigationError) 
		{
			this.loading = false;
    	}
  }
}