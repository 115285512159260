import { Option } from "../option";
import { ProgressionTable } from "app/domain";
import { IdAndName } from "./hr-academic";
import { TitleRankAndStepId } from "../..";
import { ActionTypeNamePipe } from "../../../main-core/ahr/action-type-name.pipe";
import { TitleRankStepAndTermId } from "../../data-access-interfaces";

export enum ActionType {
	Merit = "Merit",
	Promotion = "Promotion",
	Goals = "Goals",
	AnnualEvaluation = "AnnualEvaluation",
	Deferral = "Deferral",
	FiveYearReview   = "FiveYearReview",
	NotApplicable = "NotApplicable"
}

export enum TermId {
	Term1="Term1",
	Term2="Term2",
	Term3="Term3",
	IndefiniteStatus="IndefiniteStatus"
}


export function nextTerm(t: TermId, hasTermChanged: boolean){
	// if term was manually changed don't auto incrememt the term
	if (!hasTermChanged) {
		switch(t){
			case TermId.Term1: return TermId.Term2;
			case TermId.Term2: return TermId.Term3;
			case TermId.Term3: return TermId.Term3; // changed to NOT auto increment to indefinite status as Indefinite Status is now selectable
			case TermId.IndefiniteStatus: return TermId.IndefiniteStatus;
		}
		throw("Unexpected TermId: " + t);
	} 
	return t;
}

export class PositionId {
	constructor(
		public readonly titleId: number,
		public readonly rankId: number,
		public readonly step: number,
		public readonly term: Option<TermId>
	){}
	public static fromJson(o: any): PositionId {
		return new PositionId(
			o.titleId,
			o.rankId,
			o.step,
			Option.fromJsonFSharp(o.term, o => <TermId>o.case));
	}
	public static equal(a: PositionId, b: PositionId): boolean {
		return a.titleId == b.titleId
			&& a.rankId == b.rankId
			&& a.step == b.step
			&& Option.equal(
				a.term,
				b.term,
				(aValue,bValue) => aValue == bValue);
	}
	public toTitleRanksAndStepId() {
        return new TitleRankAndStepId(this.titleId, this.rankId, this.step);
    }
}

export class PositionElement {
	constructor(
		public readonly hid : string,
		public readonly name: string
	){ }
}

export class ReviewStep {
	constructor(
		public readonly id: string,
		public readonly shortName: string,
		public readonly longName: string
	){ }
	public static fromJson(o: any): ReviewStep {
		let id = 
			o.id.case == "Committee"
				? o.id.fields[0].case
				: o.id.case;

		return new ReviewStep(id, o.shortName, o.longName);
	}
}

export class Reviewer {
	constructor(
		public readonly userId: number,
		public readonly userName: string,
		public readonly type: ReviewerType
	){ }
}

export enum ReviewerType {
	Primary   = "Primary",
	Secondary = "Secondary"
}

export class CandidateDocument {
	constructor(
		public readonly name: string,
		public readonly description: string,
		public readonly requirement: string
	){ }
	public static fromJson(o:any): CandidateDocument {
		var r = '';
		switch(o.requirement.case){
			case 'Require':
				r = `Required, exactly ${o.requirement.fields[0]}`;
				break;
			case 'RequireBetween':
				r = `Required, between ${o.requirement.fields[0]} and ${o.requirement.fields[1]}`;
				break;
			case 'AllowUpTo':
				r = `Optional, up to ${o.requirement.fields[0]}`;
				break;
		}
		return new CandidateDocument(o.name, o.description, r);
	}
}

export class Position {
	public readonly titleHid: string;
	public readonly rankHid: string;
	public readonly stepHid: string;
	public readonly elements: PositionElement[];
	constructor(
		public readonly title: string,
		public readonly rank: string,
		public readonly step: string,
		public readonly term: string,
		public readonly id: PositionId,
		public readonly label: string
	){
		this.titleHid = `${id.titleId}`;
		this.rankHid  = `${id.titleId}/${id.rankId}`;
		this.stepHid  = `${id.titleId}/${id.rankId}/${id.step}`;
		this.elements = [
			new PositionElement(`${id.titleId}`, title),
			new PositionElement(`${id.titleId}/${id.rankId}`, rank),
			new PositionElement(`${id.titleId}/${id.rankId}/${id.step}`, step),
		];
	}
	public static fromJson(o: any): Position {
		return new Position(o.title, o.rank, o.step, o.term, PositionId.fromJson(o.id),o.label);
	}

	public static equal(a: Position, b: Position): boolean {
		return PositionId.equal(a.id, b.id);
	}

	public static diff(a: Position, b: Position): PositionDiff {
		return new PositionDiff(
			a.title != b.title,
			a.rank != b.rank,
			a.step != b.step,
			a.term != b.term
		);
	}

	public static titleRankStepEqual(a: Position, b: Position): boolean {
		return a.title == b.title && a.rank == b.rank && a.step == b.step;
	}
}

export class PositionDiff {
	constructor(
		public readonly title: boolean,
		public readonly rank: boolean,
		public readonly step: boolean,
		public readonly term: boolean,
	) {}
}

export enum ActionSituation {
	Normal = "Normal",
	Acceleration = "Acceleration",
	MonthRule13 = "MonthRule13",
	MonthRule24 = "MonthRule24",
	MonthRule18 = "MonthRule18",
	MonthRule30 = "MonthRule30"
}

export enum ExceptionalTiming {
	MonthRule13 = "MonthRule13",
	MonthRule24 = "MonthRule24",
	MonthRule18 = "MonthRule18",
	MonthRule30 = "MonthRule30",
	Acceleration = "Acceleration"
}
export interface ExceptionalTimingOption 
{
	readonly value: ExceptionalTiming;
	readonly label: string;
}

export const AllExceptionalTimings: ExceptionalTimingOption[] =
[
	{label: "Normal",        value: null},
	{label: "Acceleration",  value: ExceptionalTiming.Acceleration},
	{label: "13-month rule", value: ExceptionalTiming.MonthRule13},
	{label: "24-month rule", value: ExceptionalTiming.MonthRule24},
	{label: "18-month rule", value: ExceptionalTiming.MonthRule18},
	{label: "30-month rule", value: ExceptionalTiming.MonthRule30}
];

export class TruePeriodOfReview {
	constructor(
		public readonly start: Date,
		public readonly end: Date
	){}

	public static fromJson(o: any): TruePeriodOfReview {
		return new TruePeriodOfReview(o.start, o.end);
	}
}

export enum AccelerationType {
	MultiStep = "MultiStep",
	InTime = "InTime"
}

export class Action {
	constructor(
		public readonly type: ActionType,
		public readonly endingPosition: Position,
		public readonly exceptionalTiming: Option<ExceptionalTiming>,
		public readonly truePeriodOfReview: Option<TruePeriodOfReview>,
		public readonly accelerationType: Option<AccelerationType>
	){}
	public static fromJson(o: any): Action {
		let timing = 
			Option.fromJsonFSharp(
				o.exceptionalAdvancementTiming,
				t => {
					switch(t.case){
						case 'MonthRule':
							switch(t.fields[0]) {
								case 13: 
									//console.log('13 MonthRule:',t);
									return ExceptionalTiming.MonthRule13;
								case 24:
									//console.log('24 MonthRule:',t);
									return ExceptionalTiming.MonthRule24;
								case 18:
									return ExceptionalTiming.MonthRule18;
								case 30:
									return ExceptionalTiming.MonthRule30;
								default: throw `Unexpected MonthRule: ${t.fields[0]}`
							}
						case 'Acceleration':
							return ExceptionalTiming.Acceleration;
						default: 
							throw `Unexpected exceptional timing: ${o.case}`;
					}
				});
		return new Action(
			actionTypeFromJson(o.type),
			Position.fromJson(o.endingPosition),
			timing,
			Option.fromJsonFSharp(o.truePeriodOfReview, TruePeriodOfReview.fromJson),
			Option.fromJsonFSharp(o.accelerationType, o => <AccelerationType> o.case)
		)
	}

	public static equal(a: Action, b: Action): boolean {
		return a.type == b.type && Position.equal(a.endingPosition, b.endingPosition);
	}
}

function actionTypeFromJson(o: any): ActionType {
	let id = o.case;
	return ActionType[ActionType[id]];
}

export enum CaseState 
{
    AwaitingProposal = "AwaitingProposal",
    ReadyToSendToCandidate = "ReadyToSendToCandidate",
	WithCandidate = "WithCandidate",
	InReview = "InReview",
	Complete = "Complete",
	InAppeal = "InAppeal",
	ReadyToApproveDeferral = "ReadyToApproveDeferral",
	NotApplicable = "NotApplicable"
}

function CaseStateFromJson(o: any): CaseState {
	let type = (!!o && o.case) || "";
	if(!(type in CaseState)){
		console.error(o);
		throw "Unexpected case state";
	}
	return <CaseState>type;
}
export enum TaskStatus 
{
    Submitted = "Submitted",
    Approved = "Approved",
	InProgress = "In Progress",
	NotStarted = "Not Started",
	Completed = "Completed",
	Closed = "Closed",
	Recalled = "Recalled"
}

function TaskStatusFromJson(o: any): TaskStatus {
	let type = (!!o && o.case) || "";
	if(!(type in TaskStatus)){
		console.error(o);
		throw "Unexpected case state";
	}
	return <TaskStatus>type;
}

export class CaseAdvancement {
    constructor(
        public readonly proposedAction: Option<Action>,
        public readonly currentPosition: Position,
    ){}
}

export class LettersOfEvaluationRequired {
	public readonly type: string = 'Required';
	constructor(
		public readonly message: string
	) {}
}
export class LettersOfEvaluationNotRequired {
	public readonly type: string = 'NotRequired';
}
export class LettersOfEvaluationUnknown {
	public readonly type: string = 'Unknown';
}

export class Supervisor {
	constructor(
		readonly details: IdAndName,
		readonly reviewParticipation: string){

	}
	public static fromJson(o:any): Supervisor {
		return new Supervisor(
			<IdAndName>o.item1,
			o.item2.case);
	}

}

export class Case {
	hasPreviousYear = this.reviewSteps.length > 0
	constructor(
		public readonly id: string, // GUID
		public readonly userId: number,
		public readonly userName: string,
		public readonly userNameLastFirst: string,
		public readonly currentPosition: Position,
		public readonly state: CaseState,
		public readonly year: string,
		public readonly yearId: number,
		public readonly proposedAction: Option<Action>,
		public readonly result: Option<string>,
		public readonly eligibleActions: Action[],
		public readonly progressionTable: Option<ProgressionTable>,
		public readonly timeServed: Option<TimeServed>,
		public readonly startDates: Option<StartDates>,
		public readonly supervisors: Supervisor[],
		public readonly reviewSteps: ReviewStep[],
		public readonly reviewersByStepId: Map<string, Reviewer[]>,
		public readonly candidateDocuments: CandidateDocument[],
		public readonly lettersOfEvaluation: LettersOfEvaluationRequired | LettersOfEvaluationNotRequired | LettersOfEvaluationUnknown,
		public readonly countyAssignments: IdAndName[],
		public readonly reviewStepMetadata: ReviewStepMetaData[],
		public readonly userEmail: string
	){ }
	public static fromJson(o: any): Case {
		var lettersOfEvaluation = new LettersOfEvaluationUnknown();
		switch(o.lettersOfEvaluation.case){
			case 'Required':
				lettersOfEvaluation = new LettersOfEvaluationRequired(o.lettersOfEvaluation.fields[0].message);
				break;
			case 'NotRequired':
				lettersOfEvaluation = new LettersOfEvaluationNotRequired();
				break;
		}
		return new Case(
			o.id,
			o.userId,
			o.userName,
			o.userNameLastFirst,
			Position.fromJson(o.currentPosition),
			CaseStateFromJson(o.state),
			o.year,
			o.yearId,
			Option.fromJsonFSharp(o.proposedAction, Action.fromJson),
			Option.fromJsonFSharp(o.result, r => r),
			(<any[]>o.eligibleActions).map(Action.fromJson),
			Option.fromJsonFSharp(o.progressionTable, ProgressionTable.fromJson),
			Option.fromJsonFSharp(o.timeServed, TimeServed.fromJson),
			Option.fromJsonFSharp(o.startDates, StartDates.fromJson),
			(<any[]>o.supervisors).map(Supervisor.fromJson),
			(<any[]>o.reviewSteps).map(ReviewStep.fromJson),
			new Map<string, Reviewer[]>(
				(<any[]>o.reviewSteps).map(rs => {
					let reviewers = (<any[]>rs.reviewers).map(r => 
						new Reviewer(
							r.userId,
							r.name,
							r.priority));
					return [rs.id.case, reviewers] as [string, Reviewer[]];
				})),
			(<any[]>o.candidateDocumentRequirements).map(CandidateDocument.fromJson),
			lettersOfEvaluation,
			(<any[]>o.countyAssignments).map(o => new IdAndName(o.id, o.name, o.emailAddress)),
			(<any[]>o.reviewStepMetadata).map(ReviewStepMetaData.fromJson),
			o.userEmail
		);
	}
	public asCaseAdvancement() {
		return new CaseAdvancement(
			this.proposedAction, 
			this.currentPosition);
	}

	public hasTermChanged() {
		if(this.currentPosition.id.term.hasValue()
			&& this.proposedAction.hasValue()
			&& this.proposedAction.value.endingPosition.id.term.hasValue){
			return Case.isProposedTermDifferentFromCurrentTerm(this.currentPosition, this.proposedAction.value.endingPosition.id.term.value);
		}
		return false;
	}

	public static isProposedTermDifferentFromCurrentTerm(currentPosition: Position, proposedterm: TermId) {
		if(currentPosition.id.term.hasValue()
			&& proposedterm != null) {
				return currentPosition.id.term.value != proposedterm;
			}
		return false;
	}
	public static priorityToReviewerType(reviewer): ReviewerType{
		if(reviewer != null && reviewer.priority != null){
			switch(reviewer.priority){
				case 1:
					return ReviewerType.Primary;
				case 2:
					return ReviewerType.Secondary;
			}
		}
		return ReviewerType.Primary;
	}
}

export class StartDates {
	constructor(
		public readonly currentTitle: Date,
		public readonly currentRank: Date,
		public readonly currentStep: Date
	){}

	public static fromJson(o: any): StartDates {
		return new StartDates(
			o.currentTitle,
			o.currentRank,
			o.currentStep);
	}
}

export class TimeServed {
	constructor(
		public readonly monthsInCurrentTitle: number,
		public readonly monthsInCurrentRank: number,
		public readonly monthsInCurrentStep: number
	){}

	public static fromJson(o: any): TimeServed {
		return new TimeServed(
			o.monthsInCurrentTitle,
			o.monthsInCurrentRank,
			o.monthsInCurrentStep);
	}
}
export class ReviewStepMetaData{

	constructor(
	public readonly taskName: string,
	public readonly status: TaskStatus, 
	public readonly startDate: Date, 
	public readonly endDate: Date ,
	public readonly assignee: string,  
	public readonly assigneeId: string, 
	public readonly taskId: number,
	public readonly formName: string  
	){}
	public static fromJson(o: any): ReviewStepMetaData {
		return new ReviewStepMetaData(
			o.taskName,
			o.status,
			o.startDate,
			o.endDate,
			o.assignee,
			o.assigneeId,
			o.taskId,
			o.taskName);
	}
}