import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Option, User, SortedSet } from '../../domain';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
	templateUrl: './select-anrperson-modal.component.html',
	styleUrls: ['./select-anrperson-modal.component.scss']
})
export class SelectANRPersonModalComponent implements OnInit {
	closing = false;
	currentUser: Option<User> = Option.create<User>();
	form: FormGroup;

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: any,
		fb: FormBuilder,
		private readonly dialogRef: MatDialogRef<SelectANRPersonModalComponent>
	) {
		this.form = fb.group({
			'user': new SortedSet<User>([])
		});
		this.form.controls['user'].valueChanges
		.pipe(map(v => <SortedSet<User>>v))
		.subscribe(users =>{
			this.currentUser = Option.create(users.toArray()[0])
		});
	}

	ngOnInit() { }

	public static open(dialog: MatDialog, userId?: number): Observable<null|number> {
		return dialog.open(SelectANRPersonModalComponent, {
			width: '1200px',
			disableClose: false,
			data: {
				userId: userId  
			},
			hasBackdrop: true
		})
		.afterClosed()
		.pipe(map(result => {
			let newUserId = parseInt(result);
			return isNaN(newUserId)
				? null
				: newUserId;
		}));
	}

	save(){
		this.closing = true;
		this.dialogRef.close(this.currentUser.map(u => u.id).getValueOrDefault(null));
	}

	cancel(){
		this.closing = true;
		this.dialogRef.close();
	}
}