 <form [formGroup]="form" nohotkey>
<div class="mb-2">
  <div style="display: flex; justify-content: space-between;">
    <h2>
      Explore What&apos;s Happening in Cooperative Extension
    </h2>
  </div>
  <p class="alert alert-info" *ngIf="hasInstructions()">
    <span *ngIf="exploreType !== 'Theme'" [innerHTML]="getInstructions()"></span>
    <span *ngIf="exploreType === 'Theme'">Narratives are considered sensitive data. Please reach out to the academic for more information.</span>
  </p>
</div>

<div class="row">
  <div class="col-3">
      <div class="card">
        <div class="card-header">
          Search Criteria     
        </div>
        <div class="card-block">
          <p class="text-sm">
            You may search by tag;  items tagged with at least one of your selected tags will be included.
            By default, we have selected all of the tags that are relevant to you.
          </p>
          <div [ngSwitch]="!!defaultSearches">
            <div *ngSwitchCase="true">
              <tag-picker
                formControlName="tags" 
                [tagTypes]="ActivityTagTypes"
                controlClass="form-control-sm"></tag-picker>

              <p class="text-sm mt-3">
                You may also search by keyword:
              </p>

              <input type="text"
                formControlName="keyword"
                class="form-control form-control-sm"
                placeholder="Keyword">
            </div>
            <div *ngSwitchCase="false">
              <i class="fa fa-refresh fa-spin"></i>
              Loading...
            </div>
          </div>
        </div>
        <div class="card-footer" [hidden]="!defaultSearches">
          <button class="btn btn-primary btn-block" (click)="explore('Activity')" type="submit">
            Search Activities
          </button>
          <button class="btn btn-primary btn-block" (click)="explore('Project')" type="submit">
            Search Projects
          </button>
          <button class="btn btn-primary btn-block" (click)="explore('Theme')" type="submit">
            Search Themes
          </button>
          <button class="btn btn-secondary btn-block" [disabled]="loading"  (click)="downloadReport()">
            Download Report
          </button>
          <div *ngIf="loading">
            <i class="fa fa-fw fa-spin fa-refresh"></i>
            Downloading...
         </div>
          <button class="btn btn-secondary btn-block" (click)="saveCurrentSearch()">
            Save this Search
          </button>
        </div>
      </div>
  </div>

  <div class="col-6">
    <div class="card" *ngIf="exploreType === 'Activity'">
      <div class="card-header">
        Activities
        <span *ngIf="!!currentPage" class="text-muted">
          ({{currentPage.totalItemCount}})
        </span>
      </div>
      <ng-container [ngSwitch]="!!activities">
        <div *ngSwitchCase="false" class="card-block">
          <i class="fa fa-refresh fa-spin"></i>
          Loading...
        </div>
        <ng-container *ngSwitchCase="true">
          <div [hidden]="activities.length > 0" class="text-center text-muted py-5">
            Your search yielded no results.  Please try a different search criteria.
          </div>
          <div class="list-group">
            <div class="list-group-item" *ngFor="let a of activities">

              <div class="row text-sm">
                <div class="col-9">
                  {{a.type}} / {{a.subType}}
                </div>
                <div class="col-3 text-right" title="{{a.date | activityDate}}">
                  {{a.humanReadableDate}}
                </div>
              </div>

              <div class="h6 py-3" style="line-height:1.5em;">
                <keyword-highlight [keyword]="!!spec ? spec.matchingKeyword : ''" [text]="a.name"></keyword-highlight>
              </div>

              <tag-list [tags]="a.tags"></tag-list>

              <div class="media mt-3">

                  <img appUserAvatar="{{a.owner.id}}" [userName]="a.owner.name"
                    style="width: 40px;"
                    class="img-round img-fluid user-avatar-sm mr-2">                
                
                  <div class="media-body">
                  <div>{{a.owner.name}}</div>
                  <div class="text-sm">{{a.owner.title}}</div>
                  <!-- TODO: location -->
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="card" *ngIf="exploreType === 'Project'">
      <div class="card-header">
        Projects
        <span *ngIf="!!currentProjectPage" class="text-muted">
          ({{currentProjectPage.totalItemCount}})
        </span>
      </div>
      <ng-container [ngSwitch]="!!projects">
        <div *ngSwitchCase="false" class="card-block">
          <i class="fa fa-refresh fa-spin"></i>
          Loading...
        </div>
        <ng-container *ngSwitchCase="true">
          <div [hidden]="projects.length > 0" class="text-center text-muted py-5">
            Your search yielded no results.  Please try a different search criteria.
          </div>
          <div class="list-group">
            <div class="list-group-item" *ngFor="let a of projects">

              <div class="row text-sm">
                <!-- <div class="col-9">
                  {{a.name}} 
                </div> -->
                <div style="margin-left: 26rem;" class="col-3" title="{{a.startDate | date: 'EEE, MMM d, y'}}">
                  {{a.humanReadableDate}}
                </div>
              </div>

              <div class="h6 py-3" style="line-height:1.5em;">
                <keyword-highlight [keyword]="!!spec ? spec.matchingKeyword : ''" [text]="a.name"></keyword-highlight>
              </div>

              <tag-list [tags]="a.tags"></tag-list>

              <div class="media mt-3">

                  <img appUserAvatar="{{a.owner.id}}" [userName]="a.owner.name"
                    style="width: 40px;"
                    class="img-round img-fluid user-avatar-sm mr-2">                
                
                  <div class="media-body">
                  <div>{{a.owner.name}}</div>
                  <div class="text-sm">{{a.owner.title}}</div>
                  <!-- TODO: location -->
                </div>
              </div>
              <mat-expansion-panel hideDescription>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                     Description
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <keyword-highlight [keyword]="!!spec ? spec.matchingKeyword : ''" [text]="a.description"></keyword-highlight>
              </mat-expansion-panel>
              <mat-expansion-panel hideCollaborators style="margin-top: 0.3em;">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Collaborators
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>{{a.collaborators.join(', ')}}, {{a.nonANRCollaborators.join(', ')}}</p>
              </mat-expansion-panel>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="card" *ngIf="exploreType === 'Theme'">
      <div class="card-header">
        Themes
        <span *ngIf="!!currentThemePage" class="text-muted">
          ({{currentThemePage.totalItemCount}})
        </span>
      </div>
      <ng-container [ngSwitch]="!!themes">
        <div *ngSwitchCase="false" class="card-block">
          <i class="fa fa-refresh fa-spin"></i>
          Loading...
        </div>
        <ng-container *ngSwitchCase="true">
          <div [hidden]="themes.length > 0" class="text-center text-muted py-5">
            Your search yielded no results.  Please try a different search criteria.
          </div>
          <div class="list-group">
            <div class="list-group-item" *ngFor="let a of themes">

              <div class="row text-sm">
                <div style="margin-left: 26rem;" class="col-3" title="{{a.createdDate | date: 'EEE, MMM d, y'}}">
                  {{a.humanReadableDate}}
                </div>
              </div>

              <div class="h6 py-3" style="line-height:1.5em;">
                <keyword-highlight [keyword]="!!spec ? spec.matchingKeyword : ''" [text]="a.name"></keyword-highlight>
              </div>

              <tag-list [tags]="a.tags"></tag-list>

              <div class="media mt-3">

                <img appUserAvatar="{{a.owner.id}}" [userName]="a.owner.name"
                  style="width: 40px;"
                  class="img-round img-fluid user-avatar-sm mr-2">   

                <div class="media-body">
                  <div>{{a.owner.name}}</div>
                  <div class="text-sm">{{a.owner.title}}</div>
                  <!-- TODO: location -->
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <button class="btn btn-secondary btn-block mt-3" (click)="nextPage()" [hidden]="!nextPageExists()">
      Load More
    </button>
  </div>
  <div class="col-3">
    <div class="card">
      <div class="card-header">
        Your Saved Searches
      </div>
      <ul class="list-group">
        <li class="list-group-item"
          *ngFor="let s of defaultSearches"
          (click)="exploreWith(s[1])"
          style="cursor:pointer;">
          <span class="pull-right">
            <span
              class="badge badge-default"
              title="This is a default search.  It was created for your convenience.">
                Default
            </span>
            <i class="fa fa-chevron-right text-muted ml-3"></i>
          </span>
            {{s[0]}} 
        </li>
        <li class="list-group-item"
          *ngFor="let s of searches"
          (click)="exploreWithSavedSearch(s)"
          style="cursor:pointer;">
            <i class="fa fa-chevron-right text-muted pull-right"
              style="position:relative; top:4px;"></i>
            {{s.name}}
        </li>
      </ul>
    </div>
  </div>

</div>
</form>