import { Option, CurrentUser, CurrentAcademicUser }  from '../types';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthenticationResult } from '@azure/msal-browser';

export class AuthenticatedIdentity {
    constructor(
        public readonly token: string,
        public readonly tokenTimeoutDate: Date,
        public readonly user: CurrentUser
    ){}
}

export class TokenInfo {
    constructor(
        public readonly token: string,
        public readonly tokenTimeoutDate: Date,
    ){}
    public static fromJson(o: any): TokenInfo {
		return new TokenInfo(
			o.token,
			o.tokenTimeoutDate);
	}
}

export class Authenticated {
    constructor(
        public readonly tokenInfo: TokenInfo,
        public readonly user: CurrentUser,
        public readonly currentApplicationVersion: string
	){}
	public static fromJson(o: any): Authenticated {
		return new Authenticated(
			TokenInfo.fromJson(o.tokenInfo),
			CurrentUser.fromJson(o.user),
			o.currentApplicationVersion);
	}
}

@Injectable()
export abstract class AuthenticationService {
    public abstract get currentIdentity(): Observable<Option<AuthenticatedIdentity>>;
    public abstract accept(a: Authenticated): void;
    public abstract clearCurrentUser(): void;
    public abstract tryAuthenticateViaAzureAD(authResult: AuthenticationResult): Promise<boolean>
    public abstract tryGetCurrentAcademicUserSync(): Option<CurrentAcademicUser>;
    public abstract tryAuthenticateViaSecureString(secureString: string): Promise<boolean>;
    public abstract tryAuthenticateViaLocalStorage(): Promise<boolean>;
}