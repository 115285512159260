<div *ngIf="!activities">
	Loading...
</div>
<div *ngIf="!!activities && activities.length == 0">
	0 Activities
</div>
<div *ngIf="!!activities && activities.length > 0">
	<label class="checkbox">
		<input type="checkbox" id="activities-all" style="margin-top:0.4em;" 
			class="mr-2" 
			(change)="toggleAll()"
			[(ngModel)]="activitiesAll">
		{{totalSelected()}} 
		of 
		{{activities.length}} 
		Activities Selected
	</label>
</div>

<div class="activities list-group" *ngIf="!!activities">
	<div *ngFor="let a of activities"
		class="activity list-group-item text-sm {{a.includeInDossier ? '' : 'text-muted'}}">

		<ng-container *ngIf="a.subTypeId == ActivitySubTypeId.UniversityService || a.subTypeId == ActivitySubTypeId.PublicService">
			<div class="row" style="width:100%;">
				
				<div class="col-md-4">
					<label class="checkbox">
						<input type="checkbox"
							(change)="saveSingleChange(a.includeInDossier, a.id, DossierIdType.ActivityId)"
							[(ngModel)]="a.includeInDossier">
						<activity-type-icon 
							[activityTypeId]="a.typeId" 
							style="color:#999; margin-right:0.5em;"></activity-type-icon>
						<a class="href" (click)="activityClick(a,$event)">
							{{a.name}}
						</a>
					</label>
				</div>
			
				<div class="col-md-2">
					{{a.areaOfService}}
				</div>
	
				<div class="col-md-4">
					<activity-invalid-reason-icon [messages]="a.invalidReasons"></activity-invalid-reason-icon>
					{{a.role}}
				</div>

				<div class="col-md-2 text-right text-nowrap">
					<activity-date [activityDate]="a.date"></activity-date>
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="a.subTypeId == ActivitySubTypeId.EvidenceOfProfessionalCompetence || a.subTypeId == ActivitySubTypeId.ProfessionalDevelopmentAndTraining">
			<div class="row" style="width:100%;">
				<div class="col-md-6">
					<label class="checkbox">
						<input type="checkbox"
							(change)="saveSingleChange(a.includeInDossier, a.id, DossierIdType.ActivityId)"
							[(ngModel)]="a.includeInDossier">
						<activity-type-icon 
							[activityTypeId]="a.typeId" 
							style="color:#999; margin-right:0.5em;"></activity-type-icon>
						<a class="href" (click)="activityClick(a,$event)">
							{{a.name}}
						</a>
					</label>
				</div>
	
				<div class="col-md-4">
					<activity-invalid-reason-icon [messages]="a.invalidReasons"></activity-invalid-reason-icon>
					{{a.location}}
				</div>

				<div class="col-md-2 text-right text-nowrap">
					<activity-date [activityDate]="a.date"></activity-date>
				</div>
			</div>
		</ng-container>
	</div>
</div>
