import { Component, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FTEService, ProjectService, PPEProject, MultiStateInfo, SortedSet, MultiStateProjectDto, User, NonANRCollaborator } from '../../../../domain';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConsoleService } from '../../../../widgets/console.service';
import { ProjectModalComponent } from '../../../project-modal/project-modal.component';
import { ProjectModalService } from '../../../project-modal.service';
import { GoogleAnalyticsService } from '../../../../main-core/google-analytics.service';

@Component({
  selector: 'multi-state-project-modal',
  templateUrl: './multi-state-project-modal.component.html',
  styleUrls: ['./multi-state-project-modal.component.scss']
})
export class MultiStateProjectModalComponent {
	form: FormGroup;
	project: PPEProject = null;
	closing: boolean = false;
	readonly controlClass = 'col-md-6';
	readonly labelClass = 'col-md-6';
	aesTooltipText = MultiStateProjectModalComponent.aesTooltipText;
	touchEnabled = true;

	constructor(
		private svc: FTEService,
		private projectModalSvc: ProjectModalService,
		public dialogRef: MatDialogRef<MultiStateProjectModalComponent>,
		private projectSvc: ProjectService,
		private fb: FormBuilder,
		private console: ConsoleService,
		private sb: MatSnackBar,
		private gaService: GoogleAnalyticsService,
		@Inject(MAT_DIALOG_DATA) data: any,
	) { 
		let p = this.project = <PPEProject> data.project;
		this.initFormValue(p);
	}

	initFormValue(p: PPEProject){
		let formData = 
			p.multiStateInfo.match(
				() => ({
					startDate: new Date(),
					endDate: new Date(),
					aes: false,
					fte: 0
				}),
				(ms: MultiStateInfo) => ({
					startDate: new Date(ms.startDate),
					endDate: new Date(ms.endDate),
					aes: ms.aes,
					fte: Math.round(ms.fte.value * 100) /* From decimal to percentage.  Be sure to fix any rounding errors. */
				}));

		formData['ANRCollaborators'] = new SortedSet(p.ANRCollaborators.slice(0));
		formData['NonANRCollaborators'] = [
			/*We wrap in another array so that the builder interprets our array as the full value.*/
			p.NonANRCollaborators.slice(0)
		];
		this.form = this.fb.group(formData);
		this.console.log('project:', p);
		this.console.log('p.NonANRCollaborators:',p.NonANRCollaborators);
		this.console.log('formData:', formData);
	}

	canEndEffort(){
		return !!this.project && this.project.multiStateInfo.hasValue();
	}

	endEffort(){
		if(!confirm('End multi-state effort for this project?  Are you sure?')){
			return;
		}
		let reason = prompt("Why do you wish to end your effort fort this multi-state project?");
		if(!reason){
			return;
		}
		this.svc.endMultiStateProject(this.project.id, reason).then(result =>
			result.matchDo(
				_ => {
					this.dialogRef.close('success');
					this.sb.open('Saved');
					this.gaService.eventEmitter("Multi-State Project Ended", "Multi-State Project", "End");
				},
				errorMsg => {
					this.sb.open('Not Saved: ' + errorMsg, null, {duration:10000});
				}
			));
	}

	canEditProject(){
		return true;
	}

	editProject(){
		this.projectModalSvc.editProjectById(this.project.id).subscribe(result =>
			result.matchDo(
				(modal: MatDialogRef<ProjectModalComponent>) => {
					modal.afterClosed().subscribe(result => 
					this.console.log('Project modal closed: ', result));
				},
				errorMsg => {
					alert(errorMsg);
				}));
	}

	cancel(){ 
		this.dialogRef.close();
	}

	save (){ 
		let v = this.form.value;
		this.console.log('form value: ', v);
		let dto = new MultiStateProjectDto(
			this.project.id,
			(v.fte||0)/100, //percentage back to decimal
			v.startDate,
			v.endDate,
			v.aes,
			(<SortedSet<User>>v.ANRCollaborators).toArray().map(u => u.id),
			(<NonANRCollaborator[]>v.NonANRCollaborators || []).map(c => c.toDto()));
		this.console.log('dto',dto);
		this.svc.saveMultiStateProject(dto).then(result =>
			result.matchDo(
				_ => {
					this.dialogRef.close('success');
					this.sb.open('Saved');
					this.gaService.eventEmitter("Multi-State Project Updated", "Multi-State Project", "Update");
				},
				errorMsg => {
					this.sb.open('Not Saved: ' + errorMsg, null, {duration:10000});
				}
			));
	}

  public static open(matDialog: MatDialog, project: PPEProject): MatDialogRef<MultiStateProjectModalComponent>{
		return matDialog.open(MultiStateProjectModalComponent, {
			disableClose: true,
			hasBackdrop: true,
			width: '70%',
			data: { project }
		});
  }

  public static readonly aesTooltipText: string = "Agriculture Experiment Station.  When your multi-state project has an AES collaborator, it can also be claimed as integrated.";
}