
import { Component, OnInit, Inject  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AHRService,  Option, ComprehensiveReviewRequirements } from '../../../domain';
import { FormBuilder, FormGroup } from '@angular/forms';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { ConsoleService } from '../../../widgets/console.service';
import { Rank, Step, Title } from '../../../domain/types/ahr/hr-academic';
import { title } from 'process';

@Component({
  selector: 'review-requirements-workshop-modal',
  templateUrl: './review-requirements-workshop-modal.component.html'
})
export class ReviewRequirementsWorkshopModalComponent implements OnInit {
	titles;
	requirements: Option<ComprehensiveReviewRequirements> = Option.create<ComprehensiveReviewRequirements>();
	form: FormGroup;
	showDocumentDescriptions = false;
	showSimpleRequirements = true;
	aboveScaleName = "Above Scale";

	public static open(matDialog: MatDialog): MatDialogRef<ReviewRequirementsWorkshopModalComponent>{
		return matDialog.open(ReviewRequirementsWorkshopModalComponent, {
			width: '1250px',
			disableClose: true,
			data: { },
			hasBackdrop: true
		});
	}

	constructor(
		public dialogRef: MatDialogRef<ReviewRequirementsWorkshopModalComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private svc: AHRService,
		fb: FormBuilder,
		private readonly console: ConsoleService
	){ 
		this.form = fb.group({
			'position': '1/1/1',

		});

		this.titles = this.svc.getAllTitlesRanksAndSteps();

		// Add Above Scale examples
		this.titles.forEach((title: Title) => {
			let lastRank = title.ranks[title.ranks.length-1];
			let lastStep = lastRank.steps[lastRank.steps.length-1];
			title.ranks[title.ranks.length-1].steps.push(
				new Step(
					lastStep.id+1, 
					lastStep.number+1, 
					this.aboveScaleName))
		});

		this.form.valueChanges
		.pipe(debounce(() => interval(50)))
		.subscribe(_ => this.loadRequirements());
	}

	loadRequirements(){
		let p = this.form.value.position.split('/');
		this.svc.getReviewRequirements(p[0], p[1], p[2]).then(requirements => {
			this.console.log(requirements);
			this.requirements = Option.create(requirements);
		});
	}

	clear(){
		this.requirements = Option.create();
	}

	ngOnInit() {
		this.loadRequirements();
	}

	aboveScale(step: Step) {
		if(step.name == this.aboveScaleName) return "(" + step.name + ")";
		return "";
	}
}