import {
   	County
} from '../../domain';

export const COUNTIES: County[] =
[
	new County(1, "Alameda"),
	new County(2, "Alpine"),
	new County(3, "Amador"),
	new County(4, "Butte"),
	new County(5, "Calaveras"),
	new County(6, "Colusa"),
	new County(7, "Contra Costa"),
	new County(8, "Del Norte"),
	new County(9, "El Dorado"),
	new County(10, "Fresno"),
	new County(11, "Glenn"),
	new County(12, "Humboldt"),
	new County(13, "Imperial"),
	new County(14, "Inyo"),
	new County(15, "Kern"),
	new County(16, "Kings"),
	new County(17, "Lake"),
	new County(18, "Lassen"),
	new County(19, "Los Angeles"),
	new County(20, "Madera"),
	new County(21, "Marin"),
	new County(22, "Mariposa"),
	new County(23, "Mendocino"),
	new County(24, "Merced"),
	new County(25, "Modoc"),
	new County(26, "Mono"),
	new County(27, "Monterey"),
	new County(28, "Napa"),
	new County(29, "Nevada"),
	new County(30, "Orange"),
	new County(31, "Placer"),
	new County(32, "Plumas"),
	new County(33, "Riverside"),
	new County(34, "Sacramento"),
	new County(35, "San Benito"),
	new County(36, "San Bernardino"),
	new County(37, "San Diego"),
	new County(38, "San Francisco"),
	new County(39, "San Joaquin"),
	new County(40, "San Luis Obispo"),
	new County(41, "San Mateo"),
	new County(42, "Santa Barbara"),
	new County(43, "Santa Clara"),
	new County(44, "Santa Cruz"),
	new County(45, "Shasta"),
	new County(46, "Sierra"),
	new County(47, "Siskiyou"),
	new County(48, "Solano"),
	new County(49, "Sonoma"),
	new County(50, "Stanislaus"),
	new County(51, "Sutter"),
	new County(52, "Tehama"),
	new County(53, "Trinity"),
	new County(54, "Tulare"),
	new County(55, "Tuolumne"),
	new County(56, "Ventura"),
	new County(57, "Yolo"),
	new County(58, "Yuba"),
	new County(59, "Shasta-Lassen")
];
