import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgressionTableStep, ProgressionTable, Option, TimeServed, TermId } from '../../../domain';
import { ConsoleService } from '../../../widgets/console.service';
import { PositionClickEvent } from '../progression-table/progression-table.component';
import { TermClickEvent } from '../term-table/term-table.component';
import { TitleRankStepAndTermId } from "../../../domain";

@Component({
	selector: 'advancement-selector-modal',
	templateUrl: './advancement-selector-modal.component.html',
	styleUrls: ['./advancement-selector-modal.component.scss']
})
export class AdvancementSelectorModalComponent {
	table: ProgressionTable;
	timeServed: Option<TimeServed>;
	selectedStep: ProgressionTableStep;
	selectedTerm: TermId;
	currentPosition: TitleRankStepAndTermId;
	selectedPosition: TitleRankStepAndTermId;
	closing = false;

	constructor(
		public dialogRef: MatDialogRef<AdvancementSelectorModalComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private console: ConsoleService
	) {
		this.table = data.table;
		this.currentPosition = data.currentPosition;
		this.selectedPosition = data.selectedPosition;
		this.selectedStep = data.selectedStep;
		this.timeServed = data.timeServed;
		this.selectedTerm = null;
		if(!!this.selectedPosition){
			this.selectedTerm = this.selectedPosition.termId;
		}
	}

	public static open(
		matDialog: MatDialog,
		table: ProgressionTable,
		currentPosition: TitleRankStepAndTermId,
		selectedPosition: TitleRankStepAndTermId,
		timeServed: Option<TimeServed>
	): MatDialogRef<AdvancementSelectorModalComponent>{
		let selectedStep = null;
		if(!!selectedPosition){
			let selectedRank = table.ranks.filter(r => r.id == selectedPosition.rankId)[0];
			selectedStep = selectedRank.steps.filter(s => s.id == selectedPosition.stepId)[0];
		}
		return matDialog.open(AdvancementSelectorModalComponent, {
			width: '1200px',
			disableClose: false,
			data: {
				table,
				currentPosition,
				selectedPosition,
				selectedStep,
				timeServed
			},
			hasBackdrop: true
		});
	}

	canSelect(){
		return !!this.selectedPosition && (!!this.selectedStep || !!this.selectedTerm); 
	}

	select(){
		this.dialogRef.close(this.selectedPosition);
		this.closing = true;
	}

	cancel(){
		this.dialogRef.close();
		this.closing = true;
	}



	positionClick(e: PositionClickEvent) {
		if(e.step.determination == 'Regression'){
			alert('That is a regression. Please choose an advancement.');
		//} else if(e.step.determination == 'Current Step'){
			//alert('Cannot advance to the current rank and step. Please choose an advancement.');
		} else if(e.step.determination == 'Next Step'){
			let newStep = this.currentPosition.stepId + 1;
			if(this.currentPosition.stepId < this.table.lastStep().id) {
				newStep = this.table.lastStep().id + 1;
			}
			this.selectedStep = new ProgressionTableStep(
				newStep,
        		"Next Step",
        		e.step.relationships,
        		e.step.durationInMonths);
			this.selectedPosition = new TitleRankStepAndTermId(
				e.table.id,
				e.rank.id,
				newStep,
				this.selectedTerm);
			this.console.log("Next Position: ", this.selectedPosition);
		} else {
			this.selectedStep = e.step;
			this.selectedPosition = new TitleRankStepAndTermId(
				e.table.id,
				e.rank.id,
				e.step.id,
				this.selectedTerm);
			this.console.log("Selected Position: ", this.selectedPosition);
		}
	}

	termClick(e: TermClickEvent){
		//this.console.log("Term Click Event: ", e);
		this.selectedTerm = e.termId;
		this.selectedPosition = new TitleRankStepAndTermId(
			this.selectedPosition.titleId,
			this.selectedPosition.rankId,
			this.selectedPosition.stepId,
			e.termId);
		/*	
		if((e.termId == TermId.IndefiniteStatus || e.termId == TermId.Term3)
			&& this.currentPosition.termId == TermId.Term3) {
				this.selectedTerm = e.termId;
				this.selectedPosition = new TitleRankStepAndTermId(
					this.selectedPosition.titleId,
					this.selectedPosition.rankId,
					this.selectedPosition.stepId,
					e.termId);
		} else {
			alert('Only Indefinite Status is selectable when Term 3 is current.');
		}*/
		//this.console.log("Selected Term: ", this.selectedTerm);
		//this.console.log("Selected Position: ", this.selectedPosition);
	}
}