import {Location} from '../../domain';
export const LOCATIONS = [
    {
        "type": "ZipCode",
        "id": 90001,
        "name": "90001",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90002,
        "name": "90002",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90003,
        "name": "90003",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90004,
        "name": "90004",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90005,
        "name": "90005",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90006,
        "name": "90006",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90007,
        "name": "90007",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90008,
        "name": "90008",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90009,
        "name": "90009",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90010,
        "name": "90010",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90011,
        "name": "90011",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90012,
        "name": "90012",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90013,
        "name": "90013",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90014,
        "name": "90014",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90015,
        "name": "90015",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90016,
        "name": "90016",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90017,
        "name": "90017",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90018,
        "name": "90018",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90019,
        "name": "90019",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90020,
        "name": "90020",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90021,
        "name": "90021",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90022,
        "name": "90022",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90023,
        "name": "90023",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90024,
        "name": "90024",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90025,
        "name": "90025",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90026,
        "name": "90026",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90027,
        "name": "90027",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90028,
        "name": "90028",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90029,
        "name": "90029",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90030,
        "name": "90030",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90031,
        "name": "90031",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90032,
        "name": "90032",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90033,
        "name": "90033",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90034,
        "name": "90034",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90035,
        "name": "90035",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90036,
        "name": "90036",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90037,
        "name": "90037",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90038,
        "name": "90038",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90039,
        "name": "90039",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90040,
        "name": "90040",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90041,
        "name": "90041",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90042,
        "name": "90042",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90043,
        "name": "90043",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90044,
        "name": "90044",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90045,
        "name": "90045",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90046,
        "name": "90046",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90047,
        "name": "90047",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90048,
        "name": "90048",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90049,
        "name": "90049",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "ZipCode",
        "id": 90050,
        "name": "90050",
        "parentLocationName": {
            "_value": "Los Angeles (Los Angeles County)",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 1,
        "name": "Alameda",
        "parentLocationName": {
            "_value": "Alameda County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 2,
        "name": "Albany",
        "parentLocationName": {
            "_value": "Alameda County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 3,
        "name": "Berkeley",
        "parentLocationName": {
            "_value": "Alameda County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 4,
        "name": "Castro Valley",
        "parentLocationName": {
            "_value": "Alameda County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 5,
        "name": "Dublin",
        "parentLocationName": {
            "_value": "Alameda County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 6,
        "name": "Emeryville",
        "parentLocationName": {
            "_value": "Alameda County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 7,
        "name": "Fremont",
        "parentLocationName": {
            "_value": "Alameda County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 8,
        "name": "Hayward",
        "parentLocationName": {
            "_value": "Alameda County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 9,
        "name": "Livermore",
        "parentLocationName": {
            "_value": "Alameda County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 10,
        "name": "Newark",
        "parentLocationName": {
            "_value": "Alameda County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 11,
        "name": "Oakland",
        "parentLocationName": {
            "_value": "Alameda County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 12,
        "name": "Piedmont",
        "parentLocationName": {
            "_value": "Alameda County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 13,
        "name": "Pleasanton",
        "parentLocationName": {
            "_value": "Alameda County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 14,
        "name": "San Leandro",
        "parentLocationName": {
            "_value": "Alameda County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 15,
        "name": "San Lorenzo",
        "parentLocationName": {
            "_value": "Alameda County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 16,
        "name": "Sunol",
        "parentLocationName": {
            "_value": "Alameda County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 17,
        "name": "Union City",
        "parentLocationName": {
            "_value": "Alameda County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 18,
        "name": "Kirkwood",
        "parentLocationName": {
            "_value": "Alpine County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 19,
        "name": "Markleeville",
        "parentLocationName": {
            "_value": "Alpine County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 20,
        "name": "Amador City",
        "parentLocationName": {
            "_value": "Amador County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 21,
        "name": "Drytown",
        "parentLocationName": {
            "_value": "Amador County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 22,
        "name": "Fiddletown",
        "parentLocationName": {
            "_value": "Amador County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 23,
        "name": "Ione",
        "parentLocationName": {
            "_value": "Amador County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 24,
        "name": "Jackson",
        "parentLocationName": {
            "_value": "Amador County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 25,
        "name": "Kit Carson",
        "parentLocationName": {
            "_value": "Amador County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 26,
        "name": "Martell",
        "parentLocationName": {
            "_value": "Amador County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 27,
        "name": "Pine Grove",
        "parentLocationName": {
            "_value": "Amador County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 28,
        "name": "Pioneer",
        "parentLocationName": {
            "_value": "Amador County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 29,
        "name": "Plymouth",
        "parentLocationName": {
            "_value": "Amador County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 30,
        "name": "River Pines",
        "parentLocationName": {
            "_value": "Amador County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 31,
        "name": "Sutter Creek",
        "parentLocationName": {
            "_value": "Amador County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 32,
        "name": "Volcano",
        "parentLocationName": {
            "_value": "Amador County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 33,
        "name": "Bangor",
        "parentLocationName": {
            "_value": "Butte County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 34,
        "name": "Berry Creek",
        "parentLocationName": {
            "_value": "Butte County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 35,
        "name": "Biggs",
        "parentLocationName": {
            "_value": "Butte County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 36,
        "name": "Chico",
        "parentLocationName": {
            "_value": "Butte County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 37,
        "name": "Clipper Mills",
        "parentLocationName": {
            "_value": "Butte County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 38,
        "name": "Durham",
        "parentLocationName": {
            "_value": "Butte County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 39,
        "name": "Feather Falls",
        "parentLocationName": {
            "_value": "Butte County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 40,
        "name": "Forbestown",
        "parentLocationName": {
            "_value": "Butte County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 41,
        "name": "Forest Ranch",
        "parentLocationName": {
            "_value": "Butte County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 42,
        "name": "Gridley",
        "parentLocationName": {
            "_value": "Butte County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 43,
        "name": "Magalia",
        "parentLocationName": {
            "_value": "Butte County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 44,
        "name": "Nelson",
        "parentLocationName": {
            "_value": "Butte County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 45,
        "name": "Oroville",
        "parentLocationName": {
            "_value": "Butte County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 46,
        "name": "Palermo",
        "parentLocationName": {
            "_value": "Butte County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 47,
        "name": "Paradise",
        "parentLocationName": {
            "_value": "Butte County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 48,
        "name": "Richvale",
        "parentLocationName": {
            "_value": "Butte County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 49,
        "name": "Stirling City",
        "parentLocationName": {
            "_value": "Butte County",
            "hasValue": true
        }
    },
    {
        "type": "City",
        "id": 50,
        "name": "Altaville",
        "parentLocationName": {
            "_value": "Calaveras County",
            "hasValue": true
        }
    },
    {
        "type": "County",
        "id": 1,
        "name": "Alameda",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 2,
        "name": "Alpine",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 3,
        "name": "Amador",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 4,
        "name": "Butte",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 5,
        "name": "Calaveras",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 6,
        "name": "Colusa",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 7,
        "name": "Contra Costa",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 8,
        "name": "Del Norte",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 9,
        "name": "El Dorado",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 10,
        "name": "Fresno",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 11,
        "name": "Glenn",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 12,
        "name": "Humboldt",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 13,
        "name": "Imperial",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 14,
        "name": "Inyo",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 15,
        "name": "Kern",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 16,
        "name": "Kings",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 17,
        "name": "Lake",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 18,
        "name": "Lassen",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 19,
        "name": "Los Angeles",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 20,
        "name": "Madera",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 21,
        "name": "Marin",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 22,
        "name": "Mariposa",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 23,
        "name": "Mendocino",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 24,
        "name": "Merced",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 25,
        "name": "Modoc",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 26,
        "name": "Mono",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 27,
        "name": "Monterey",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 28,
        "name": "Napa",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 29,
        "name": "Nevada",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 30,
        "name": "Orange",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 31,
        "name": "Placer",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 32,
        "name": "Plumas",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 33,
        "name": "Riverside",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 34,
        "name": "Sacramento",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 35,
        "name": "San Benito",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 36,
        "name": "San Bernardino",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 37,
        "name": "San Diego",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 38,
        "name": "San Francisco",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 39,
        "name": "San Joaquin",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 40,
        "name": "San Luis Obispo",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 41,
        "name": "San Mateo",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 42,
        "name": "Santa Barbara",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 43,
        "name": "Santa Clara",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 44,
        "name": "Santa Cruz",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 45,
        "name": "Shasta",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 46,
        "name": "Sierra",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 47,
        "name": "Siskiyou",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 48,
        "name": "Solano",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 49,
        "name": "Sonoma",
        "parentLocationName": {
            "hasValue": false
        }
    },
    {
        "type": "County",
        "id": 50,
        "name": "Stanislaus",
        "parentLocationName": {
            "hasValue": false
        }
    }
].map(Location.fromJson);
