import { Pipe, PipeTransform } from '@angular/core';
import { ActivityTypeId, ActivityService, ActivityTypeCollection } from '../domain';

@Pipe({
  name: 'activityTypeName'
})
export class ActivityTypeNamePipe implements PipeTransform {
  private _activityTypes: ActivityTypeCollection;

  constructor(private svc: ActivityService) 
  { 
    this._activityTypes = svc.getActivityTypes();
  }

  transform(value: any, args?: any): any {
    return this._activityTypes.getTypeById(<ActivityTypeId>value).name;
  }

}
