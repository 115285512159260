<table class="table table-bordered">
  <tr *ngFor="let c of value">
    <td>
      <button class="btn btn-sm btn-link text-danger pull-right" (click)="remove(c)">
        <i class="fa fa-times"></i>
      </button>

      <a (click)="edit(c)" class="href">
        {{c.name}}
      </a>

      <div class="text-sm pl-3">
        <div [ngSwitch]="c.institution.type">
          <div *ngSwitchCase="CollaboratorInstitutionType.LandGrant">
            {{c.institution.name}} ({{c.institution.stateId}})
          </div>
          <div *ngSwitchCase="CollaboratorInstitutionType.Tribal">
            {{c.institution.name}}
          </div>
          <div *ngSwitchCase="CollaboratorInstitutionType.Other">
            {{c.institution.institutionName}}
          </div>
          <div *ngSwitchCase="CollaboratorInstitutionType.Campus">
            {{c.institution.name}}
          </div>
        </div>
        <div *ngIf="!!c.websiteAddress">
          <i class="fa mr-2 fa-link text-muted"></i>
          <a [href]="c.websiteAddress" target="_blank">
            {{c.websiteAddress}}
          </a>
        </div>
        <div *ngIf="!!c.emailAddress">
          <i class="fa mr-2 fa-envelope text-muted"></i>
          <a href="mailto:{{c.emailAddress}}">
            {{c.emailAddress}}
          </a>
        </div>
      </div>
    </td>
  </tr>
</table>