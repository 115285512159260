import { Component, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConsoleService } from '../../widgets/console.service';
import { ActivityCollaborationInvitation, ActivityService, ClienteleGroup, ConfirmActivityCollaboration, ActivityParent, ActivityParentType, OriginalActivity, ActivityCollaborationInvitationConfirmed } from '../../domain';
import { ActivityModalService } from '../activity-modal.service';

@Component({
	templateUrl: './activity-collaboration-confirmation-modal.component.html',
	styleUrls: ['./activity-collaboration-confirmation-modal.component.scss']
})
export class ActivityCollaborationConfirmationModalComponent {
	invite: ActivityCollaborationInvitation;
	dto: ConfirmActivityCollaboration;
	clienteleGroups: ClienteleGroup[] = [];
	parents: ActivityParent[] = [];
	saving: boolean = false;
	editAfterSaving: boolean = false;
	ActivityParentType = ActivityParentType;

	constructor(
		public dialogRef: MatDialogRef<ActivityCollaborationConfirmationModalComponent>,
		private snackBar: MatSnackBar,
		private console: ConsoleService,
		private svc: ActivityService,
		private activityModalService: ActivityModalService,
		@Inject(MAT_DIALOG_DATA) data: any
	)
	{
		svc.getAvailableClienteleGroups(false).then(cgs => {
			this.console.log("ClienteleGroups: ", cgs);
			this.clienteleGroups = cgs;
		});
		svc.getAvailableParents().then(ps => this.parents = ps);
		this.invite = data.invite;
		this.dto = new ConfirmActivityCollaboration(this.invite.originalActivity.id, "", null, null);
		this.console.log("invite: ", this.invite);
		
	}

	public static open(matDialog: MatDialog, invite: ActivityCollaborationInvitation): MatDialogRef<ActivityCollaborationConfirmationModalComponent>
	{
		return matDialog.open(ActivityCollaborationConfirmationModalComponent,{
			width:'500px',
			hasBackdrop: true,
			data: {
				invite
			}
		});
	}

	cancel(){
		this.dialogRef.close(new ActivityCollaborationConfirmationModalResponse(false, false, null));
	}

	save(){
		this.saving = true;
		this.svc.confirmCollaboration(this.dto).then(result =>
			result.matchDo(
				(newActivityId: ActivityCollaborationInvitationConfirmed) => {
					this.snackBar.open("Activity added")
					this.dialogRef.close(new ActivityCollaborationConfirmationModalResponse(true, this.editAfterSaving, newActivityId.activityId));
					this.saving = false;
				},
				msg => {
					this.snackBar.open("Unable to add activity: " + msg)
					this.saving = false;
				}
			));
	}
}

export class ActivityCollaborationConfirmationModalResponse 
{
	constructor(
		public readonly confirmed: boolean,
		public readonly editAfterSaving: boolean,
		public readonly originalActivityId: number
	){}
}