<ng-container [ngSwitch]="editMode">
	<ng-container *ngSwitchCase="false">
		<table class="table">
			<thead>
				<tr>
					<th>{{nameLabel}}</th>
					<th>{{descriptionLabel}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let apa of academicProfessionalAssociations;">
					<td>{{apa.name}}</td>
					<td>{{apa.description}}</td>
				</tr>
			</tbody>
			<tfoot>
				<tr>
					<td colspan="2">
						<button class="btn btn-primary"
							(click)="changeEditMode(true)">
							<i class="fa fa-pencil"></i>
							Edit
						</button>
					</td>
				</tr>
			</tfoot>
		</table>
	</ng-container>
	<ng-container *ngSwitchCase="true">
		<table class="table" nohotkey>
				<thead>
					<tr>
						<th>{{nameLabel}}</th>
						<th colspan="2">{{descriptionLabel}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let apa of academicProfessionalAssociations; index as i;">
						<td>
							<textarea autoGrow class="form-control"
								[(ngModel)]="apa.name"
								placeholder="Name"></textarea>  
						</td>
						<td>
							<textarea autoGrow class="form-control"
								[(ngModel)]="apa.description"
								placeholder="Description"></textarea>
						</td>
						<td>
							<button class="btn btn-link btn-sm text-danger float-right"
								(click)="deleteRow(i)">
								<i class="fa fa-times"></i>
							</button>
						</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<td colspan="3">
							<button class="btn btn-link"
								(click)="cancel()">
								Cancel
							</button>
							<button class="btn btn-primary"
								(click)="save()">
								Save
							</button>
							<button class="btn btn-success"
								(click)="addRow()">
								<i class="fa fa-plus"></i>
								Add Row
							</button>
						</td>
					</tr>
				</tfoot>
			</table>
	</ng-container>
</ng-container>
