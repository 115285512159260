<div [ngSwitch]="!!currentUser.value">
	<div *ngSwitchCase="true">
		<div class="media" (click)="menuOpen = !menuOpen">
			<div class="pull-left">

				<img appUserAvatar="{{currentUser.value.id}}" [userName]="currentUser.value.firstName + ' ' + currentUser.value.lastName" [showTooltip]="false"
					style="width: 25px;"
					class="img-round img-fluid mr-2">

			</div>
			<div class="media-body" style="cursor:pointer;">
				{{currentUser.value.firstName}}
				<i class="fa fa-fw fa-caret-down"></i>
			</div>
		</div>
		<div class="menu-container" [hidden]="!menuOpen" (click)="menuOpen = false">
			<div class="menu">
				<div class="caret"></div>
				<div class="card">
					<div class="card-block text-left">
						<div class="media mb-3">
							<div class="pull-left">

								<img appUserAvatar="{{currentUser.value.id}}" [userName]="currentUser.value.firstName + ' ' + currentUser.value.lastName" [showTooltip]="false"
									style="width: 90px;"
									class="img-round img-fluid mr-4">

							</div>
							<div class="media-body">
								<div>
									{{currentUser.value.firstName}}
									{{currentUser.value.lastName}}
								</div>
								<div *ngIf="!!currentUser.value.roles.academic.value">
									<span class="badge badge-default">
										{{currentUser.value.roles.academic.value.title.name}}
									</span>
									<div class="text-sm text-muted">
										<span [ngSwitch]="currentUser.value.roles.academic.value.meritAndPromotionHr">
											<div *ngSwitchCase="HR.ANR">
												<!--
												<div>
													{{currentUser.value.roles.academic.value.totalFTE.value}} Total FTE
												</div>
												-->
												<div>
													M+P with ANR
												</div>
											</div>
											<div *ngSwitchCase="HR.Campus">
												<!--
												<div>
													{{currentUser.value.roles.academic.value.totalFTE.value}} CE FTE
												</div>
												-->
												<div>
													M+P with {{currentUser.value.roles.academic.value.payrollCampus.name}}
												</div>
											</div>
										</span>
									</div>
								</div>
							</div>
						</div>

						<!--
						<div class="text-center">
							<a href="/academic/profile"
							class="btn btn-secondary btn-block">
								<i class="fa fa-fw fa-user"></i>
								Profile
							</a>
						</div>
						-->

						<nav class="nav flex-column">
							<a *ngFor="let l of navLinks" 
								[routerLink]="l.url"
								class="nav-link" 
								[class.active]="urlActive(l.url)">
									{{l.label}}
							</a>
						</nav>
						
						<nav class="nav flex-column">
							<a href="https://ucanr.edu/portal/modules/dirbibliography.cfm"
								(click)="$event.stopPropagation()"
								class="nav-link"
								target="_blank">
									ANR Bibliography
							</a>
						</nav>

						<div class="text-center mt-2" *ngIf="isAcademic">
							<a routerLink="/academic/preferences"
							class="btn btn-secondary btn-block">
								<i class="fa fa-fw fa-cog"></i>
								Preferences
							</a>
						</div>

						<div class="text-center mt-2">
							<a href="https://ucanr.edu/portal/"
							(click)="$event.stopPropagation()"
							class="btn btn-secondary btn-block"
							target="_blank">
								<i class="fa fa-fw fa-id-card"></i>
								ANR Portal
							</a>
						</div>

						<div class="text-center mt-2">
							<a href="https://ucanr.co1.qualtrics.com/jfe/form/SV_4HsqwQf0AkAnYaN"
							(click)="$event.stopPropagation()"
							class="btn btn-outline-primary btn-block"
							target="_blank">
								<i class="fa fa-fw fa-comment"></i>
								Give Feedback
							</a>
						</div>

						<div class="text-center mt-2">
							<a href="http://ucanr.edu/sites/projectboardhelp/"
							(click)="$event.stopPropagation()"
							class="btn btn-outline-success btn-block"
							target="_blank">
								<i class="fa fa-fw fa-question-circle"></i>
								Help
							</a>
						</div>

						<div class="text-center mt-2" *ngIf="isCampusMPAcademic">
							<a href=""
							(click)="$event.preventDefault(); $event.stopPropagation(); wordExport()"
							class="btn btn-primary btn-block"
							target="_blank">
								<i class="fa fa-fw fa-download"></i>
								Export Themes and Projects
							</a>
						</div>

						<div class="text-center mt-2" *ngIf="isCampusMPAcademic">
							<a href=""
							(click)="$event.preventDefault(); $event.stopPropagation(); excelExport()"
							class="btn btn-success btn-block"
							target="_blank">
								<i class="fa fa-fw fa-download"></i>
								Export Activities and FTE 
							</a>
						</div>

						<div class="text-center mt-2" *ngIf="showReadModelResetButton">
							<button
							(click)="resetReadModels()"
							class="btn btn-secondary btn-block">
								<i class="fa fa-fw fa-refresh"></i>
								Reset Read Models
							</button>
						</div>

						<div class="text-center mt-2" *ngIf="showPacketMovementButton">
							<button
							(click)="tryMovePacket()"
							class="btn btn-secondary btn-block">
								<i class="fa fa-fw fa-arrow-right"></i>
								Try Move Packet
							</button>
						</div>

						<div class="text-center mt-2" *ngIf="showCommandButton">
							<button
							(click)="askForCommand()"
							class="btn btn-secondary btn-block">
								<i class="fa fa-fw fa-terminal"></i>
								Execute Command
							</button>
						</div>

						<div class="text-center mt-2" *ngIf="showUsageDownloadButton">
							<button
							(click)="downloadUsageReport()"
							class="btn btn-secondary btn-block">
								<i class="fa fa-fw fa-download"></i>
								Download Usage Report
							</button>
						</div>
						<div class="text-center mt-2" *ngIf="showLoginDownloadButton">
							<button
							(click)="downloadUserLoginReport()"
							class="btn btn-secondary btn-block">
								<i class="fa fa-fw fa-download"></i>
								Download Login Report
							</button>
						</div>

					</div>
					<div class="card-footer text-right">
						<a (click)="logOut()" class="btn btn-secondary">
							<span class="fa	fa-fw fa-sign-out"></span>
							Log out
						</a>
					</div>
				</div>
			</div>
		</div>

	</div>
	<div *ngSwitchCase="false">
		<a routerLink="/">
			Log in
		</a>
	</div>
</div>
