<div mat-dialog-title [hotkey]="navigationHotkeys">
	<div *ngIf="!isNonAcademic(case)" class="pull-right mt-3 mr-3">
		<button 
			class="btn btn-primary" 
			[hidden]="!canPropose()"
			[matMenuTriggerFor]="proposalMenu">
				Propose an action
		</button>

		<mat-menu #proposalMenu="matMenu">
			<button 
				mat-menu-item 
				*ngFor="let t of actionProposalTypes"
				(click)="propose(t.value)">
					{{t.label}}
			</button>
		</mat-menu>

		<button class="btn btn-primary ml-2" 
			(click)="sendToCandidate()"
			[hidden]="!canSendToCandidate()"
			matTooltip="Send the case to the candidate when you are ready for them to upload their case materials">
				Send case to candidate
		</button>

		<button class="btn btn-danger ml-2" 
			(click)="recall()"
			[hidden]="!canRecall()"
			matTooltip="Recall the case from the candidate when you wish to adjust their proposed action.  This will archive their case in wfa">
				Recall case 
		</button>

		<button class="btn btn-primary ml-2" 
			(click)="approveDeferral()"
			[hidden]="!canApproveDeferral()"
			matTooltip="Approve Deferral for this candidate. This will close the review and disallow changes till next cycle.">
				Approve Deferral 
		</button>

		<button class="btn btn-success ml-2" 
			(click)="approve()"
			[hidden]="!canApprove()"
			matTooltip="Approve this person's proposed advancement">
				{{approvalLabel}}
		</button>
        <button class="btn btn-danger ml-2" 
			(click)="denyFiveyearReview()"
			[hidden]="!canDenyFiveyearReview()"
			matTooltip="Deny this person's proposed five year review">
			    {{denialLabel}}
		</button>
		<button 
			class="btn btn-danger ml-2" 
			[hidden]="!canDenyProposedAction()"
			[matMenuTriggerFor]="denialMenu"
			matTooltip="Deny this person's proposed advancement.  You will have the option to specify a secondary action.">
				{{denialLabel}}
		</button>

		<button 
			class="btn btn-danger ml-2" 
			[hidden]="!canDenyResult()"
			[matMenuTriggerFor]="denialMenu"
			matTooltip="Deny the decided result for this person's actions.  You will have the option to specify a secondary action.">
				Deny Result
		</button>

		<mat-menu #denialMenu="matMenu">
			<button 
				mat-menu-item 
				*ngFor="let o of denialOptions"
				(click)="deny(o.value)">
					{{o.label}}
			</button>
		</mat-menu>

		<button class="btn btn-warning ml-2" 
			(click)="appealResult()"
			[hidden]="!canAppealResult()"
			matTooltip="Appeal the result so a different one can be assigned">
				Appeal Result
		</button>
	</div>
	<div class="media">
		<div class="pull-left">
			<a href="{{case.userId | userAvatarUrl}}?download=false" target="_blank">

				<img appUserAvatar="{{case.userId}}" [userName]="case.userName"
					style="width:60px; border:1px solid #ddd;"
					class="img-round img-fluid user-avatar-sm mr-3">
			</a>
		</div>
		<div class="media-body">
			<div>{{case.userName}}</div>
			<div class="text-sm">{{case.currentPosition | titleRankStepTerm}}</div>
		</div>
		<div *ngIf="!isNonAcademic(case)" class="pull-right case-year">{{case.year}}</div>
	</div>
	<ul class="nav nav-tabs mt-3" style="margin-bottom:-13px; font-size:0.75em; font-weight:normal;">
		<li *ngFor="let tab of tabs" class="nav-item">
			<a class="nav-link" href="javascript:void(0)"
			[class.active]="tab == activeTab"
			(click)="activateTab(tab)">
				{{tab.label}}
			</a>
		</li>
	</ul>
</div>

<style>
	.table-border-top-none tr:first-child th,
	.table-border-top-none tr:first-child td {
		border-top: none;
	}
</style>


<mat-dialog-content style="min-height: 300px;">
	<div class="row">
		<div class="col-7" [ngSwitch]="activeTab.id">
			<ng-container *ngSwitchCase="'candidate_docs'">
				<p>
					<i class="fa fa-fw fa-info-circle"></i>
					These are the document requirements that the candidate would see in wfa
					based on the current proposed action.
				</p>
				
				<table class="table table-bordered table-sm text-sm">
					<tr>
						<th>Document</th>
						<th>Requirement</th>
					</tr>
					<tr *ngFor="let d of case.candidateDocuments" [title]="d.description">
						<td>
							{{d.name}}
						</td>
						<td>
							{{d.requirement}}
						</td>
					</tr>
					<tr>
						<td>
							Letters of Evaluation
						</td>
						<td [ngSwitch]="case.lettersOfEvaluation.type">
							<div *ngSwitchCase="'Required'">
								Required 
								<button
									class="btn btn-sm btn-link text-xs"
									(click)="preview(case.lettersOfEvaluation.message)">
										Preview Request
								</button>
							</div>
							<div *ngSwitchDefault>
								Not Required
							</div>
						</td>
					</tr>
				</table>
			</ng-container>
			<ng-container *ngSwitchCase="'county_assignments'">
				<div class="list-group">
					<div class="list-group-item text-muted" *ngIf="!case.countyAssignments.length">
						No assignments yet
					</div>
					<div class="list-group-item" *ngFor="let a of case.countyAssignments; trackBy:getId">
						{{a.name}}
						<button class="btn btn-link btn-sm float-right"
						(click)="removeCountyAssignment(a)">
							Remove
						</button>
					</div>
					<div class="list-group-item">
						<form class="form-inline">
							<select class="form-control form-control-original col mr-3"
							[(ngModel)]="selectedCountyId"
							[ngModelOptions]="{standalone: true}">
								<option value="" disabled selected>
									Select a County
								</option>
								<option *ngFor="let c of availableCounties" [value]="c.id">
									{{c.name}}
								</option>
							</select>
							<button class="btn btn-primary col" [disabled]="!selectedCountyId"
							(click)="addCountyAssignment(selectedCountyId); selectedCountyId = undefined;">
								Add Assignment
							</button>
						</form>
					</div>
				</div>
			</ng-container>
			<ng-container *ngSwitchCase="'supervisors'">
				<table class="table table-sm">
					<tr *ngFor="let s of case.supervisors; let index=index">
						<td>
							{{s.details.name}}
							<!--
							<br>
							{{supervisorReviewParticipationLabelById[s.reviewParticipation]}}
							-->
							<div class="text-sm text-muted">
								{{n_ary(index+1)}}
							</div>
						</td>
						<td>
							<label class="form-check-label">
								<select class="form-control text-sm" (change)="supervisorReviewParticipationChange(index, $event)">
									<option *ngFor="let o of supervisorReviewParticipationOptions" [value]="o.id" [selected]="s.reviewParticipation === o.id">
										{{o.label}}
									</option>
								</select>
							</label>
						</td>
						<td>
							<button class="btn btn-link btn-sm" (click)="promoteSupervisor(s,index)">
								Promote
							</button>
						</td>
						<td>
							<button class="btn btn-link btn-sm" (click)="demoteSupervisor(s,index)">
								Demote
							</button>
						</td>
						<td>
							<button class="btn btn-link btn-sm" (click)="removeSupervisor(s,index)">
								Remove
							</button>
						</td>
					</tr>
				</table>
				<user-picker 
					[formControl]="usersControl"
					enableNonANRCollaborator="false"
					selectSingle="true"
					[showUserIds]="true"></user-picker>
				<div class="form-check form-check-tight">
					<label class="form-check-label" *ngFor="let o of supervisorReviewParticipationOptions">
						<input class="form-check-input" 
							name="supervisorInvolvement"
							type="radio"
							[value]="o.id"
							[formControl]="supervisorReviewInvolvementControl">
						{{o.label}} - {{o.description}}
					</label>
					
				</div>
				<button 
					class="btn btn-secondary"
					(click)="addSupervisor(usersControl.value.toArray()[0], supervisorReviewInvolvementControl.value); usersControl.value.clear()"
					[disabled]="usersControl.value.toArray().length != 1">
						Add Supervisor
				</button>
			</ng-container>
			<ng-container *ngSwitchCase="'case'">
				<table *ngIf="!isNonAcademic(case)" class="table table-border-top-none">
					<tr>
						<th>Status</th>
						<td>
							{{case.state | caseState}}
						</td>
					</tr>

					<tr>
						<th>WFA</th>
						<td *ngIf="!wfaCase.value" class="text-muted text-sm">
							This person does not yet have a case in WFA
						</td>
						<td *ngIf="!!wfaCase.value">
							<div>
								<a [href]="wfaUrl" 
									target="_blank" 
									class="btn btn-sm btn-outline-primary">
										View case in WFA
								</a>
							</div>
						</td>
					</tr>
					<ng-container [ngSwitch]="case.eligibleActions.length">
						<tr *ngSwitchDefault>
							<th>
								Eligible Action(s)
							</th>
							<td>
								<table class="table table-sm">
									<tr>
										<th style="width:50%;">Type</th>
										<th>Period of Review</th>
									</tr>
									<tr *ngFor="let a of case.eligibleActions">
										<td>
											{{a.type | actionTypeName}}
										</td>
										<td>
											<small [ngSwitch]="!!a.truePeriodOfReview.value">
												<ng-container *ngSwitchCase="true">
													{{a.truePeriodOfReview.value.start | date}} - {{a.truePeriodOfReview.value.end | date}}
												</ng-container>
												<ng-container *ngSwitchCase="false">
													N/A
												</ng-container>
											</small>
										</td>
									</tr>
								</table>
							</td>
						</tr>
					</ng-container>

					<tr>
						<th>Proposed Action</th>
						<td [ngSwitch]="!!case.proposedAction.value">
							<div *ngSwitchCase="true">
								<table class="table table-sm">
									<tr>
										<td style="width:50%;">
											<proposed-action [caseAdvancement]="case.asCaseAdvancement()"></proposed-action>
										</td>
										<td>
											<small [ngSwitch]="!!case.proposedAction.value.truePeriodOfReview.value">
												<ng-container *ngSwitchCase="true">
													{{case.proposedAction.value.truePeriodOfReview.value.start | date}} - {{case.proposedAction.value.truePeriodOfReview.value.end | date}}
												</ng-container>
												<ng-container *ngSwitchCase="false">
													N/A
												</ng-container>
											</small>
										</td>
									</tr>
								</table>
							</div>
							<div *ngSwitchCase="false" class="text-muted">
								None Yet
							</div>
						</td>
					</tr>
					<tr>
						<th>Proposed&nbsp;Position</th>
						<td [ngSwitch]="!!case.proposedAction.value">
							<div *ngSwitchCase="true">
								{{case.proposedAction.value.endingPosition | titleRankStepTerm}}
							</div>
							<div *ngSwitchCase="false" class="text-muted">
								N/A
							</div>
						</td>
					</tr>
					<tr *ngIf="case.reviewSteps.length > 0">
						<th title="These are the review steps the case would go through in wfa">
							Review Steps
							<i class="fa fa-fw fa-info-circle"></i>
						</th>
						<td>
							<span *ngFor="let s of case.reviewSteps; let index=index"
								class="mr-2"
								[title]="s.longName">
									{{index+1}}.&nbsp;{{s.shortName}}<span *ngIf="case.reviewersByStepId.has(s.id)">:
										<span *ngFor="let r of case.reviewersByStepId.get(s.id);let index=index"><span [hidden]="index == 0">,</span> {{r.userName}} ({{r.type}})</span>
									</span>
							</span>
						</td>
					</tr>
					<tr>
						<th>Time Served</th>
						<td>
							<time-served-table [data]="case.timeServed"></time-served-table>
						</td>
					</tr>
					<tr>
						<th>Start dates</th>
						<td [ngSwitch]="!!case.startDates.value">
							<div class="text-muted" *ngSwitchCase="false">
								None on record
							</div>
							<table class="table table-sm" *ngSwitchCase="true">
								<tr *ngFor="let row of [
									{
										key: 'currentTitle',
										label: 'Current Title'
									},
									{
										key: 'currentRank',
										label: 'Current Rank'
									},
									{
										key: 'currentStep',
										label: 'Current Step'
									}
								]" >
									<td>{{row.label}}</td>
									<td>{{case.startDates.value[row.key] | date : 'MMMM d, yyyy'}}</td>
								</tr>
							</table>
						</td>
					</tr>
				</table>
				<table *ngIf="isNonAcademic(case)" class="table table-border-top-none">
					<tr>
						<th>Start dates</th>
						<td [ngSwitch]="!!case.startDates.value">
							<div class="text-muted" *ngSwitchCase="false">
								None on record
							</div>
							<table class="table table-sm" *ngSwitchCase="true">
								<tr *ngFor="let row of [
									{
										key: 'currentTitle',
										label: 'Current Title'
									}
								]" >
									<td>{{row.label}}</td>
									<td>{{case.startDates.value[row.key] | date : 'MMMM d, yyyy'}}</td>
								</tr>
							</table>
						</td>
					</tr>
					<tr><th></th><td></td></tr> <!-- added to create a border -->
				</table>

			</ng-container>
		</div>

		<div class="col-5">
			<div class="list-group text-sm">
				<div class="mb-2">
					<strong class="text-muted text-sm">
						History
					</strong>
				</div>
				<div [hidden]="!!news">
					Loading history...
				</div>
				<div class="list-group-item" *ngFor="let story of news">
					<span *ngFor="let e of story.elements" [ngSwitch]="e.case">
						<span *ngSwitchCase="'By'">
							{{e.user.name}}
							<span *ngIf="!!e.onBehalfOf.value">
								(on behalf of {{e.onBehalfOf.value.name}})
							</span>
						</span>
						<span *ngSwitchCase="'Text'">
							{{e.text}}
						</span>
						<span *ngSwitchCase="'Academic'">
							this person
						</span>
						<span *ngSwitchCase="'Supervisor'">
							{{e.supervisor.name}}
						</span>
					</span>
					<div class="float-right">
						<span class="text-muted" [title]="story.timestamp | date : 'EEEE, MMMM d yyyy @ h:mm:ss a'">
							{{story.timestamp | timeAgo}}
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions [hotkey]="navigationHotkeys">
	<style>
		.hidden-until-hovered {
			opacity: 0;
		}
		.hidden-until-hovered:hover {
			opacity: 1;
		}
	</style>
	<div class="btn-group float-left">
		<button class="btn btn-secondary"
			[disabled]="!navigator.hasPrev()"
			(click)="navigator.goPrev(this)"
			[title]="'Previous case' + (!navigator.hasPrev() ? ' (Disabled; you are viewing the first case)' : '')">
				<i class="fa fa-fw fa-chevron-left"></i>
		</button>
		<button class="btn btn-secondary"
			[disabled]="!navigator.hasNext()"
			(click)="navigator.goNext(this)"
			[title]="'Next case' + (!navigator.hasNext() ? ' (Disabled; you are viewing the last case)' : '')">
				<i class="fa fa-fw fa-chevron-right"></i>
		</button>
	</div>
	<code
		style="float: left; top: 4px; position: relative;"
		class="text-sm text-muted hidden-until-hovered">
			ID = {{case.id}}
	</code>

	<button (click)="close()" class="btn btn-link">
		Close
	</button>
</mat-dialog-actions>