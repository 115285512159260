<h5 *ngIf="tabsVisible" class="mb-3">Activities</h5>

<div>
	<div class="mb-4" *ngIf="collaborationInvitations.length > 0">
		<mat-accordion >
			<mat-expansion-panel>		  	
				
				<mat-expansion-panel-header class="collaboration-header">
					<mat-panel-title style="display: flex; align-items: center;">
						<i class="fa fa-fw fa-info-circle mr-2"></i>You have been added as a collaborator
					</mat-panel-title>
				</mat-expansion-panel-header>

				<div class="list-group" >
					<div class="list-group-item text-sm" style="border-radius: 0;">
						For each activity, you may choose to either ignore the collaboration or add it to your own activity list,
						allowing you to specify your role(s) and clientele, and include it in your dossier.
					</div>
					<div class="list-group-item" *ngFor="let invite of collaborationInvitations">
						<div class="row">
							<div class="col-md-6">
								<h6>{{invite.originalActivity.name}}</h6>
							</div>
							<div class="col-md-2 text-center text-sm">
								<activity-date [activityDate]="invite.originalActivity.date"></activity-date>
							</div>
							<div class="col-md-3 text-right">
								<button class="btn btn-outline-danger btn-sm mr-1" (click)="ignore(invite)">
									Ignore
								</button>
								<button class="btn btn-outline-success btn-sm" (click)="add(invite)">
									Add to Activities
								</button>
							</div>
						</div>
						<div class="text-sm">
							<em>Added by</em>
							<div class="media mt-1">

								<img appUserAvatar="{{invite.addedByUser.id}}" [userName]="invite.addedByUser.name"
									style="width: 40px;"
									class="img-round img-fluid user-avatar-sm mr-2">

								<div class="media-body">
									<div>{{invite.addedByUser.name}}</div>
									<div class="text-sm">{{invite.addedByUser.academicTitle}}</div>
									<!-- TODO: location -->
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</mat-expansion-panel>
		</mat-accordion>	
	</div>

	<ul class="nav nav-tabs nav-tabs-transparent mb-4" *ngIf="tabsVisible">
		<li *ngFor="let tab of tabs" class="nav-item">
			<a class="nav-link" href="javascript:void(0)" [class.active]="tab == activeTab" (click)="activateTab(tab)">
				{{tab.label}}
			</a>
		</li>
	</ul>

	<div [class.hasParent]="!!parent">
		<div *ngIf="!activities">
			Loading...
		</div>

		<div *ngIf="!!activities && activities.length == 0">
			<empty-state
				imgSrc="/assets/empty/activities.png"
				title="{{!!parent ? 'This ' + ActivityParentType[parent.type] +' doesn\'t' : 'You don\'t'}} have any activities yet"
				description="Why not add one now?">
					<button class="btn btn-primary btn-sm"
						(click)="openActivityMenu($event)">
						+ Add Activity
					</button>
			</empty-state>
		</div>

		<div *ngIf="!!activities && activities.length > 0">
			<form [formGroup]="filterForm" novalidate nohotkey>
				<div class="row" style="margin-bottom:1rem;">
					<div class="col-md-4 align-items-center">
						<span class="h5" style="margin-bottom:0;" *ngIf="!tabsVisible">
							Activities
						</span>
						<button class="btn btn-primary btn-sm" style="margin-left:1em;"
							(click)="openActivityMenu($event)">
							+ Add Activity
						</button>
					</div>

					<div class="col-md-3">
						<div class="icon-addon addon-sm">
							<input type="search"
								id="search_activities"
								placeholder="Search Activities"
								class="form-control form-control-sm"
								formControlName="withNameMatching" 
								aria-label="Search Activities" 
								role="search">
							<span class="fa fa-search"></span>
						</div>
					</div>

					<div class="col-md-5 text-right">
						<ul class="nav nav-text justify-content-end">
							<li *ngFor="let state of states" class="nav-item">
								<a class="nav-link"
								[class.active]="state.id == currentStateId"
								(click)="switchToStateId(state.id)">
									{{state.label}}
								</a>
							</li>
						</ul>
						<!--
						<br>
						<button class="btn btn-sm btn-secondary" type="button">
							<i class="fa fa-fw fa-filter"></i>
							Filter Activities
						</button>
						-->
					</div>
				</div>

				<div class="px-2">
					<div class="row">
						<div class="col-md-4">
							<label for="startDate" class="text-xs">Start Date</label>
							<div class="input-group input-group-sm no-left-padding">
								<input [matDatepicker]="startDatePicker" placeholder="mm/dd/yyyy" class="form-control form-control-sm no-padding"
									formControlName="startDate"
									(dateChange)="dateChange('startDate',$event)">
								<mat-datepicker #startDatePicker startView="year" [touchUi]="touchEnabled"></mat-datepicker>
								<mat-datepicker-toggle [for]="startDatePicker" class="mat-icon-sm"></mat-datepicker-toggle>
							</div>
						</div>

						<div class="col-md-4">
							<label for="endDate" class="text-xs">End Date</label>
							<div class="input-group input-group-sm no-left-padding">
								<input [matDatepicker]="endDatePicker" placeholder="mm/dd/yyyy" class="form-control form-control-sm no-padding"
									formControlName="endDate"
									(dateChange)="dateChange('endDate',$event)">
								<mat-datepicker #endDatePicker startView="year" [touchUi]="touchEnabled"></mat-datepicker>
								<mat-datepicker-toggle [for]="endDatePicker" class="mat-icon-sm"></mat-datepicker-toggle>
							</div>
						</div>

						<div class="col-md-4">
							<div class="form-check form-check-tight pull-left">
								<div><label class="text-xs">&nbsp;</label></div>
								<label class="form-check-label text-sm mt-2">
									<input class="form-check-input" 
										type="checkbox"
										formControlName="missingInformation">
										Missing Information
								</label>
							</div>
							<div class="pull-right">
								<div><label class="text-xs">&nbsp;</label></div>
								<button class="btn btn-outline-danger btn-sm"
									(click)="resetFilters()">Reset Filters</button>
							</div>
						</div>

						<div class="col-md-4">
							<label class="text-xs">
								Activity Type
							</label>
							<div class="">
								<mat-select formControlName="combinedActivityTypes" class="form-control" multiple>
									<ng-container *ngFor="let at of activityTypes.all">
										<mat-option [value]="'at_' + at.id">{{at.name}}</mat-option>
										<mat-option *ngFor="let st of at.subTypes" [value]="'st_' + st.id" class="pl-5">{{st.name}}</mat-option>
									</ng-container>
								</mat-select>
							</div>
						</div>

						<div class="col-md-4">
							<label class="text-xs">
								Theme(s)
							</label>
							<div class="">
								<mat-select formControlName="belongingToAThemeWithIdIn" class="form-control" multiple>
									<mat-option *ngFor="let theme of themes" [value]="theme.id">
										{{theme.name}}
									</mat-option>
								</mat-select>
							</div>
						</div>
						<div class="col-md-4">
							<label class="text-xs">
								Project(s)
							</label>
							<div class="">
								<mat-select formControlName="belongingToAProjectWithIdIn" class="form-control" multiple>
									<mat-option *ngFor="let p of projects" [value]="p.id">
										{{p.name}}
									</mat-option>
								</mat-select>
							</div>
						</div>
						<div class="col-md-4" *ngIf="this.activeTab.id === 0">
							<label class="text-xs">
								Audience Attendance
							</label>
						  <div class="">
							<select class="form-control" formControlName="clienteleContactsType">	
								<option value="">Select</option>							
								<option *ngFor="let clientele of clienteleContactTypes" [value]="clientele.id">
								  {{clientele.name}}
								</option>
							  </select>
						  </div>
						</div>
						<div class="col-md-4" style="padding-right: 0.3em;" *ngIf="this.activeTab.id === 0 && this.filterForm.value.clienteleContactsType === OutreachTypes.external">				
							<label class="text-xs">
								Clientele Group
							</label>
							<div>
								<clientele-group-picker activityFilter="true" formControlName="clienteleGroup"></clientele-group-picker>
							</div>
						</div>
					</div>
				</div>
			</form>

			<hr>

			<div class="row">
				<div [ngClass]="!!viewingActivity ? 'col-md-8' : 'col-md-12'">
					<div [ngSwitch]="currentStateId">
						<div *ngSwitchCase="'All'">

							<activity-list
								[activities]="!!filteredActivities ? search(filteredActivities, searchTerm) : undefined"
								[activityCategory]="activeTab"
								[highlightedActivity]="viewingActivity"
								[keywordToHighlight]="searchTerm"
								[hideTags]="!!viewingActivity"
								[hideParent]="!!parent"
								[fadeToGray]="!!parent"
								paddingRight="0.5rem"
								paddingLeft="0.5rem"
								(activityClick)="editActivity($event)"></activity-list>

						</div>
						<div *ngSwitchCase="'UpcomingAndRecent'">

							<div class="hr-heading">
								<span>Upcoming</span>
							</div>

							<activity-list
								[activities]="!!activities ? search(activitiesUpcoming, searchTerm) : undefined"
								[activityCategory]="activeTab"
								[highlightedActivity]="viewingActivity"
								[keywordToHighlight]="searchTerm"
								[hideTags]="!!viewingActivity"
								[hideParent]="!!parent"
								[fadeToGray]="!!parent"
								maxHeight="500px"
								paddingRight="0.5rem"
								paddingLeft="0.5rem"
								(activityClick)="editActivity($event)"></activity-list>

							<div class="hr-heading" style="margin-top:2em;">
								<span>Recent</span>
							</div>

							<activity-list
								[activities]="!!activities ? search(activitiesRecent, searchTerm) : undefined"
								[activityCategory]="activeTab"
								[highlightedActivity]="viewingActivity"
								[keywordToHighlight]="searchTerm"
								[hideTags]="!!viewingActivity"
								[hideParent]="!!parent"
								[fadeToGray]="!!parent"
								maxHeight="500px"
								paddingRight="0.5rem"
								paddingLeft="0.5rem"
								(activityClick)="editActivity($event)"></activity-list>
						</div>
					</div>
				</div>

				<div class="col-md-4" *ngIf="!!viewingActivity">
					<activity-editor
						[activity]="viewingActivity"
						(copyActivity)="startActivityCopy($event)"
						(close)="viewingActivity = null"></activity-editor>
				</div>
			</div>
		</div>
	</div>
</div>
