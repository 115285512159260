import { HRUnit } from "../../domain";

export const HRUNITS: HRUnit[] = [
    new HRUnit(1,	"Cooperative Extension Office",	"Alameda County"),
    new HRUnit(2,	"Cooperative Extension Office",	"Amador County"),
    new HRUnit(3,	"Cooperative Extension Office",	"Antelope Valley/Lancaster Office"),
    new HRUnit(4,	"Cooperative Extension Office",	"Butte County"),
    new HRUnit(5,	"Cooperative Extension Office",	"Calaveras County"),
    new HRUnit(6,	"Cooperative Extension Office",	"Colusa County"),
    new HRUnit(7,	"Cooperative Extension Office",	"Contra Costa County"),
    new HRUnit(8,	"Cooperative Extension Office",	"Del Norte County Office"),
    new HRUnit(9,	"Cooperative Extension Office",	"El Dorado County"),
    new HRUnit(10,	"Cooperative Extension Office",	"Fresno County"),
    new HRUnit(11,	"Cooperative Extension Office",	"Glenn County"),
    new HRUnit(12,	"Cooperative Extension Office",	"Humboldt County Office"),
    new HRUnit(13,	"Cooperative Extension Office",	"Imperial County"),
    new HRUnit(14,	"Cooperative Extension Office",	"Indio Office"),
    new HRUnit(15,	"Cooperative Extension Office",	"Inyo-Mono Counties"),
    new HRUnit(16,	"Cooperative Extension Office",	"Kern County"),
    new HRUnit(17,	"Cooperative Extension Office",	"King City Office"),
    new HRUnit(18,	"Cooperative Extension Office",	"Kings County"),
    new HRUnit(19,	"Cooperative Extension Office",	"Lake County"),
    new HRUnit(20,	"Cooperative Extension Office",	"Lassen County"),
    new HRUnit(21,	"Cooperative Extension Office",	"Los Angeles County"),
    new HRUnit(22,	"Cooperative Extension Office",	"Madera County"),
    new HRUnit(23,	"Cooperative Extension Office",	"Marin County"),
    new HRUnit(24,	"Cooperative Extension Office",	"Mariposa County"),
    new HRUnit(25,	"Cooperative Extension Office",	"Mendocino County"),
    new HRUnit(26,	"Cooperative Extension Office",	"Merced County"),
    new HRUnit(27,	"Cooperative Extension Office",	"Modoc County"),
    new HRUnit(28,	"Cooperative Extension Office",	"Monterey County"),
    new HRUnit(29,	"Cooperative Extension Office",	"Napa County"),
    new HRUnit(30,	"Cooperative Extension Office",	"Nevada County Office"),
    new HRUnit(31,	"Cooperative Extension Office",	"Orange County - UC Cooperative Extension"),
    new HRUnit(32,	"Cooperative Extension Office",	"Palo Verde Office"),
    new HRUnit(33,	"Cooperative Extension Office",	"Placer-Nevada Counties"),
    new HRUnit(34,	"Cooperative Extension Office",	"Riverside County"),
    new HRUnit(35,	"Cooperative Extension Office",	"Sacramento County"),
    new HRUnit(36,	"Cooperative Extension Office",	"San Benito County"),
    new HRUnit(37,	"Cooperative Extension Office",	"San Bernardino County"),
    new HRUnit(38,	"Cooperative Extension Office",	"San Diego County"),
    new HRUnit(39,	"Cooperative Extension Office",	"Nutrition Office"),
    new HRUnit(40,	"Cooperative Extension Office",	"San Joaquin County"),
    new HRUnit(41,	"Cooperative Extension Office",	"San Luis Obispo County"),
    new HRUnit(42,	"Cooperative Extension Office",	"San Mateo-San Francisco Counties"),
    new HRUnit(43,	"Cooperative Extension Office",	"Santa Barbara County"),
    new HRUnit(44,	"Cooperative Extension Office",	"Santa Clara County"),
    new HRUnit(45,	"Cooperative Extension Office",	"Santa Cruz County"),
    new HRUnit(46,	"Cooperative Extension Office",	"Shasta County"),
    new HRUnit(47,	"Cooperative Extension Office",	"Shasta-Lassen Office"),
    new HRUnit(48,	"Cooperative Extension Office",	"Siskiyou County"),
    new HRUnit(49,	"Cooperative Extension Office",	"Solano County"),
    new HRUnit(50,	"Cooperative Extension Office",	"Sonoma County"),
    new HRUnit(51,	"Cooperative Extension Office",	"Stanislaus County"),
    new HRUnit(52,	"Cooperative Extension Office",	"Sutter-Yuba Counties"),
    new HRUnit(53,	"Cooperative Extension Office",	"Tehama County"),
    new HRUnit(54,	"Cooperative Extension Office",	"Trinity County Cooperative Extension"),
    new HRUnit(55,	"Cooperative Extension Office",	"Tulare County"),
    new HRUnit(56,	"Cooperative Extension Office",	"Tulelake Office"),
    new HRUnit(57,	"Cooperative Extension Office",	"Tuolumne County"),
    new HRUnit(58,	"Cooperative Extension Office",	"Ventura County"),
    new HRUnit(59,	"Cooperative Extension Office",	"Yolo County"),
    new HRUnit(60,	"Cooperative Extension Office",	"Plumas Sierra"),
    new HRUnit(61,	"Cooperative Extension Office",	"San Diego North County Office"),
    new HRUnit(62,	"Cooperative Extension Office",	"Central Sierra Cooperative Extension"),
    new HRUnit(63,	"Cooperative Extension Office",	"Santa Barbara - Goleta Office"),
    new HRUnit(64,	"Cooperative Extension Office",	"Fresno-Madera Cooperative Extension"),
    new HRUnit(65,	"Cooperative Extension Office",	"Capital Corridor MCP Cooperative Extension"),
    new HRUnit(66,	"Research and Extension Center", "Desert Research & Extension Center"),
    new HRUnit(67,	"Research and Extension Center", "Elkus Ranch"),
    new HRUnit(68,	"Research and Extension Center", "Hopland Research & Extension Center"),
    new HRUnit(69,	"Research and Extension Center", "Intermountain Research & Extension Center"),
    new HRUnit(70,	"Research and Extension Center", "Kearney Agricultural Research & Extension Center"),
    new HRUnit(71,	"Research and Extension Center", "Lindcove Research & Extension Center"),
    new HRUnit(72,	"Research and Extension Center", "Sierra Foothill Research & Extension Center"),
    new HRUnit(73,	"Research and Extension Center", "South Coast Research & Extension Center"),
    new HRUnit(74,	"Research and Extension Center", "West Side Research & Extension Center"),
    new HRUnit(75,	"Research and Extension Center", "Hansen Agricultural Research and Extension Center"),
    new HRUnit(76,	"Statewide Program/Institute",	"Youth, Families, and Communities Statewide Program"),
    new HRUnit(77,	"Statewide Program/Institute",	"Agricultural Issues Center"),
    new HRUnit(79,	"Statewide Program/Institute",	"Expanded Food Nutrition Education Program (EFNEP)"),
    new HRUnit(80,	"Statewide Program/Institute",	"Integrated Pest Management (IPM) Statewide Program"),
    new HRUnit(81,	"Statewide Program/Institute",	"Sustainable Agriculture Research & Education Program"),
    new HRUnit(82,	"Statewide Program/Institute",	"UC Master Gardener Program"),
    new HRUnit(83,	"Statewide Program/Institute",	"Informatics and GIS (IGIS) Statewide Program"),
    new HRUnit(84,	"Statewide Program/Institute",	"California Institute for Water Resources"),
    new HRUnit(85,	"Statewide Program/Institute",	"California Naturalist Program"),
    new HRUnit(86,	"Statewide Program/Institute",	"Nutrition Policy Institute"),
    new HRUnit(87,	"Statewide Program/Institute",	"UC Master Food Preserver Program"),
    new HRUnit(88,	"Campus Department",	"Agricultural & Resource Economics"),
    new HRUnit(89,	"Campus Department",	"Plant & Microbial Biology"),
    new HRUnit(90,	"Campus Department",	"Center for Forestry"),
    new HRUnit(91,	"Campus Department",	"Environmental Science, Policy & Management"),
    new HRUnit(92,	"Campus Department",	"Dean's Office"),
    new HRUnit(93,	"Campus Department",	"Plant Sciences"),
    new HRUnit(94,	"Campus Department",	"Agricultural & Resource Economics"),
    new HRUnit(95,	"Campus Department",	"Biological & Agricultural Engineering"),
    new HRUnit(96,	"Campus Department",	"Animal Science"),
    new HRUnit(97,	"Campus Department",	"Human Ecology"),
    new HRUnit(98,	"Campus Department",	"Environmental Science & Policy"),
    new HRUnit(99,	"Campus Department",	"Food Science & Technology"),
    new HRUnit(100,	"Campus Department",	"Land, Air & Water Resources"),
    new HRUnit(101,	"Campus Department",	"Nutrition"),
    new HRUnit(102,	"Campus Department",	"Plant Pathology"),
    new HRUnit(103,	"Campus Department",	"Entomology & Nematology"),
    new HRUnit(104,	"Campus Department",	"Viticulture & Enology"),
    new HRUnit(105,	"Campus Department",	"Wildlife, Fish & Conservation Biology "),
    new HRUnit(106,	"Campus Department",	"Population Health & Reproduction"),
    new HRUnit(107,	"Campus Department",	"Botany & Plant Sciences"),
    new HRUnit(108,	"Campus Department",	"Entomology"),
    new HRUnit(109,	"Campus Department",	"Nematology"),
    new HRUnit(110,	"Campus Department",	"Plant Pathology & Microbiology"),
    new HRUnit(111,	"Campus Department",	"Environmental Sciences"),
];