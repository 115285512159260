import { ConditionChangeFTE } from './condition-change-fte';
import { CountyAssignment } from './county-assignment';
import { PPEProject } from '.';

export class FTEOverview {
	constructor(
		public readonly conditionChange: ConditionChangeFTE[],
		public readonly county: CountyAssignment[],
		public readonly projects: PPEProject[]) { }

	public static fromJson(o: any): FTEOverview {
		return new FTEOverview(
			ConditionChangeFTE.fromJson(o.conditionChange),
		   	o.county.map(CountyAssignment.fromJson),
		   	o.projects.map(PPEProject.fromJson));
	}
}