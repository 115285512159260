<div class="row">
    <div *ngFor="let s of [
        {label:'Candidate Files',key:'candidateFiles',visible: candidateFilesVisible},
        {label:'Reviewer Files', key:'reviewerFiles',visible: reviewerFilesVisible}
    ]" class="col">
        <div class="p1">
            <h6>
                {{s.visible && !candidateFiles?s.label:''}}
            </h6>
            <div [ngSwitch]="s.visible">
                <div *ngSwitchCase="true">
                    <div *ngIf="!files || !files[s.key] || files[s.key].length === 0" class="text-muted">
                        No files found
                    </div>
                    <div *ngFor="let f of files[s.key]">   
                        <input *ngIf="displayCheckboxes" class="form-check-input" id="checkboxNoLabel" [(ngModel)]="f.isChecked" (ngModelChange)="checkedChanged()" type="checkbox" value={{f.id}} >
                        <a href (click)="handleFileClick($event,f)">
                            <i class="fa fa-fw fa-file-o"></i>
                            {{f.name}}
                        </a>
                    </div>
                </div>
                <div *ngIf="!candidateFiles">
                    <div *ngSwitchCase="false">
                        Sorry, you may not view these files at this time.
                    </div>
                </div>

            </div>
        </div>
    </div>  
</div>

<div class="row" *ngIf="displayCheckboxes">
    <br/>
    <button type="button" class="btn btn-outline-primary btn-sm btn-block mr-1" (click)="selectAllFiles()">{{selectOption}}</button>
    <button type="button" class="btn btn-outline-primary btn-sm btn-block" [disabled]="selectedFileCount === 0" (click)="downloadSelectedFiles()">
        Download <span *ngIf="selectedFileCount > 0">({{selectedFileCount}})</span>
    </button>
</div>