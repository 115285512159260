<div mat-dialog-title>
  Progression Table Worksheet
  <button matDialogClose class="btn btn-secondary pull-right">
    &times;
  </button>
</div>

<mat-dialog-content style="min-height: 300px;">
  <form [formGroup]="form">
    <div class="row">
      <div class="col text-center">
        <div class="form-group">
          <select formControlName="position" class="form-control">
            <ng-container *ngFor="let title of titles">
              <ng-container *ngFor="let rank of title.ranks">
                <option *ngFor="let step of rank.steps" [value]="title.id + '/' + rank.id + '/' + step.id">
                  {{title.name}} / {{rank.name}} / {{step.id | romanNumeral}}
                </option>
              </ng-container>
            </ng-container>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <div class="form-group">
          <label class="mr-2">Months served in current <strong>title</strong>:</label>
          <input type="number" formControlName="monthsServedInCurrentTitle" style="width:50px; text-align:center;" min="0">
        </div>
      </div>
      <div class="col text-center">
        <div class="form-group">
          <label class="mr-2">Months served in current <strong>rank</strong>:</label>
          <input type="number" formControlName="monthsServedInCurrentRank" style="width:50px; text-align:center;" min="0">
        </div>
      </div>
      <div class="col text-center">
        <div class="form-group">
          <label class="mr-2">Months served in current <strong>step</strong>:</label>
          <input type="number" formControlName="monthsServedInCurrentStep" style="width:50px; text-align:center;" min="0">
        </div>
      </div>
    </div>
  </form>


  <div [ngSwitch]="!!table.value">
    <div *ngSwitchCase="false">
      No title selected
    </div>
    <div *ngSwitchCase="true">
      <progression-table 
        [data]="table.value" 
        (positionClick)="positionClick($event)" 
        [selectedStep]="selectedStep"
        [currentPosition]="currentPosition">
      </progression-table>

      <term-table 
        (termClick)="termClick($event)"
        [currentPosition]="currentPosition"
        [selectedTerm]="selectedTerm">
      </term-table>
    </div>
  </div>
  
</mat-dialog-content>