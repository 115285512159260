import { Component, OnInit } from '@angular/core';
import { TooltipService } from '../../../widgets/tooltip.service';

@Component({
  selector: 'reviewer-dashboard',
  templateUrl: './reviewer-dashboard.component.html',
  styleUrls: ['./reviewer-dashboard.component.scss']
})
export class ReviewerDashboardComponent implements OnInit {
  constructor(public tooltipSvc: TooltipService) { }

  ngOnInit() {
  }
  getInstructions(): string {
		return this.tooltipSvc.tooltip("WFAPBIngtigrationInstructions.REVIEWER");
	}
  hasInstructions() {
		if (this.getInstructions().length > 0) {
			return true;
		} 
		return false;
	}
}