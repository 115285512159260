
<div *ngIf="!!currentPosition.termId" 
    class="row mt-4 justify-content-center"> 
    <div class="btn-group">
        <div *ngFor="let term of termSteps" 
            class="btn btn-lg"
            (click)="termClick_(term)"
            [ngClass]="{
                'btn-secondary': term.state == 'none' || term.state == 'Regression',
                'step-regression': term.state == 'Regression',
                'btn-primary': term.state == 'Current' || term.state == 'Proposed'
            }">
            <div>{{term.name}}</div>
            <div *ngIf="term.state == 'Current' || term.state == 'Proposed'" class="text-sm text-center">{{term.state}}</div>
        </div>
    </div>
</div>
