<section-header
    sectionTitle="Academic Program Review - Reviewer Dashboard"
    navPath="/review"
    containerClass="container"
    [navLinks]="[]"></section-header>
<div class="alert alert-info"  *ngIf="hasInstructions()">
    <span [innerHTML]="getInstructions()"></span>	
</div>
<div id="wrapper" class="container-fluid">
    <div class="row">
        <div class="col-lg-4 mb-3">
            <reviewer-case-list></reviewer-case-list>
        </div>
        <div class="col-md-12 col-lg-8">
			<router-outlet></router-outlet>
        </div>
    </div>
</div>