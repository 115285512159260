<div mat-dialog-title>
	New Theme
</div>
<mat-dialog-content>
	<p [matTooltip]="StaticText.ThemeNameToolTip">
		What should we call your new Theme?
		<i class="fa fa-fw fa-info-circle"></i>
	</p>
	<div>
		<div class="form-group">
			<input type="text"
				[autofocus]="true"
				class="form-control"
				[(ngModel)]="theme.name"
				placeholder="Theme Name"
				aria-label="Name">
		</div>

		<!--
		<div class="form-group">
			<textarea autoGrow
				class="form-control"
				formControlName="description"
				placeholder="Enter your description"
				aria-label="Description"></textarea>
		</div>

		<div class="form-group">
			<label>
				Tags
				<span class="text-muted">({{form.value.tags.count}})</span>
			</label>
			<tag-picker
				formControlName="tags"
				[tagTypes]="availableTagTypes"
				controlClass="form-control-sm"></tag-picker>
		</div>
		-->

	</div>
	<p class="text-sm text-muted">
		(You&apos;ll have the opportunity to provide more information shortly.)
	</p>
</mat-dialog-content>
<mat-dialog-actions>
	<button (click)="cancel()" class="btn btn-link" [disabled]="closing">
		Cancel
	</button>
	<button (click)="save()" class="btn btn-primary" [disabled]="closing">
		Save
	</button>
</mat-dialog-actions>
