export class CoreIssue {
    constructor(
        public id: number,
        public name: string,
        public disabled: boolean
    ) { }

	public static fromJson(o: any): CoreIssue {
		return new CoreIssue(o.id, o.name, o.disabled);
	}
}
