export class NonANRCollaborator {
    constructor(
        public readonly name: string,
        public readonly emailAddress: string,
        public readonly websiteAddress: string,
        public readonly institution: CollaboratorInstitution
    ){}

    public static arraysEqual(a: NonANRCollaborator[], b: NonANRCollaborator[]): boolean {
        if(!a || !b || a.length != b.length){
            return false;
        }
        for(var i=0; i<a.length; i++){
            if(!a[i].equals(b[i])){
                return false;
            }
        }
        return true;
    }

    public static fromJson(o: any): NonANRCollaborator {
        return new NonANRCollaborator(
            o.name,
            o.emailAddress && o.emailAddress.value,
            o.websiteAddress && o.websiteAddress.value,
            CollaboratorInstitution.fromJson(o.institution)
        )
    }

    public duplicate(): NonANRCollaborator {
        return new NonANRCollaborator(
            this.name,
            this.emailAddress,
            this.websiteAddress,
            this.institution);
    }

    public equals(other: NonANRCollaborator): boolean {
        return this.name == other.name
        && this.emailAddress == other.emailAddress
        && this.websiteAddress == other.websiteAddress
        && this.institution.equals(other.institution);
    }

    public toDto(): NonANRCollaboratorDto {
        var lgi_id: number = null;
        var otherInstitutionName: string = null;
        this.institution.matchDo(
            lg => { if(lg.type == CollaboratorInstitutionType.LandGrant) lgi_id = lg.id; },
            t => { if(t.type == CollaboratorInstitutionType.Tribal) lgi_id = t.id; },
            o => { otherInstitutionName = o.institutionName; },
            u => { if(u.type == CollaboratorInstitutionType.Campus) lgi_id = u.id; });
        return new NonANRCollaboratorDto(
            this.name,
            this.emailAddress,
            this.websiteAddress,
            lgi_id,
            this.institution.type.valueOf(),
            otherInstitutionName);
    }
}

export class NonANRCollaboratorDto {
    constructor(
        public readonly name: string,
        public readonly emailAddress: string,
        public readonly websiteAddress: string,
        public readonly landGrantInstitutionId: number | null,
        public readonly institutionType: number | null,
        public readonly otherInstitutionName: string | null
    ){}
}

export enum CollaboratorInstitutionType 
{
    LandGrant = 1,
    Other = 2,
    Tribal = 3,
    Campus = 4
}

export abstract class CollaboratorInstitution
{
    constructor(
        public readonly type: CollaboratorInstitutionType
    ){}

    public abstract match<T>(
        landGrant: (landGrant: LandGrant) => T,
        tribal: (tribal: Tribal) => T,
        other: (other: Other) => T,
        campus:(campus: UCCampus) => T
    ) : T;

    public abstract matchDo(
        landGrant: (landGrant: LandGrant) => void,
        tribal: (tribal: Tribal) => void,
        other: (other: Other) => void,
        campus:(campus: UCCampus) => void
    ) : void;

    public equals(other: CollaboratorInstitution): boolean {
        return this.match(
            lg => 
                other.match(
                    lg2 => lg.id == lg2.id && lg.type == CollaboratorInstitutionType.LandGrant,
                    t => false,
                    o2 => false,
                    c=> false),
            t => 
                other.match(
                    lg2 => false,
                    t2 => t.id == t2.id && t.type == CollaboratorInstitutionType.Tribal,
                    o2 => false,
                    c=>false),
            o =>
                other.match(
                    lg2 => false,
                    t => false,
                    o2 => o.institutionName == o2.institutionName && o.type == CollaboratorInstitutionType.Other,
                    c=> false),
            u =>
                other.match(
                     lg2 => false,
                     t => false,
                     o2=> false,
                     c => u.id == c.id && u.type == CollaboratorInstitutionType.Campus));
    }

    public static fromJson(o: any): CollaboratorInstitution 
    {
        switch(parseInt(o.type)){
            case 1:
                return new LandGrant(
                    o.institution.id,
                    o.institution.name,
                    o.institution.stateId);
            case 2:
                return new Other(o.institutionName);
            case 3:
                return new Tribal(
                    o.institution.id,
                    o.institution.name,
                    o.institution.stateId);
            case 4:
                return new UCCampus(
                    o.institution.id,
                    o.institution.abbreviation,
                    o.institution.name);
            default:
                throw ('Unexpected CollaboratorInstitution.type: ' + o.type);
        }
    }
}

export class LandGrant extends CollaboratorInstitution
{
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly stateId: string
    ){
        super(CollaboratorInstitutionType.LandGrant);
    }

    public match<T>(
        landGrant: (landGrant: LandGrant) => T,
        tribal: (tribal: Tribal) => T,
        other: (other: Other) => T,
        campus: (campus: UCCampus) => T): T
    {
        return landGrant(this);
    }

    public matchDo(
        landGrant: (landGrant: LandGrant) => void,
        tribal: (tribal: Tribal) => void,
        other: (other: Other) => void,
        campus: (campus: UCCampus) => void): void 
    {
        landGrant(this);
    }
}

export class Tribal extends CollaboratorInstitution
{
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly stateId: string
    ){
        super(CollaboratorInstitutionType.Tribal);
    }

    public match<T>(
        landGrant: (landGrant: LandGrant) => T,
        tribal: (tribal: Tribal) => T,
        other: (other: Other) => T,
        campus: (campus: UCCampus) => T): T
    {
        return tribal(this);
    }

    public matchDo(
        landGrant: (landGrant: LandGrant) => void,
        tribal: (tribal: Tribal) => void,
        other: (other: Other) => void,
        campus: (campus: UCCampus) => void): void 
    {
        tribal(this);
    }
}

export class Other extends CollaboratorInstitution
{
    constructor(public readonly institutionName: string)
    {
        super(CollaboratorInstitutionType.Other);
    }

    public match<T>(
        landGrant: (landGrant: LandGrant) => T,
        tribal: (tribal: Tribal) => T,
        other: (other: Other) => T,
        campus: (campus: UCCampus) => T): T 
    {
        return other(this);
    }

    public matchDo(
        landGrant: (landGrant: LandGrant) => void,
        tribal: (tribal: Tribal) => void,
        other: (other: Other) => void,
        campus: (campus: UCCampus) => void): void 
    {
        other(this);
    }
}
export class UCCampus extends CollaboratorInstitution
{
    constructor(
        public readonly id: number,
        public readonly abbreviation: string,
        public readonly name: string
    ){
        super(CollaboratorInstitutionType.Campus);
    }

    public match<T>(
        landGrant: (landGrant: LandGrant) => T,
        tribal: (tribal: Tribal) => T,
        other: (other: Other) => T,
        campus: (campus: UCCampus) => T): T
    {
        return campus(this);
    }

    public matchDo(
        landGrant: (landGrant: LandGrant) => void,
        tribal: (tribal: Tribal) => void,
        other: (other: Other) => void,
        campus: (campus: UCCampus) => void): void 
    {
        campus(this);
    }
}