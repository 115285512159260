import { 
    AcademicReportSubmissionService as SVC, 
    SubmissionOpportunity, 
    SubmissionType,
    AuthenticationService } from '../domain/data-access-interfaces';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'app/app-settings';
import { ConsoleService } from 'app/widgets/console.service';
import { Result } from 'app/domain';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';


@Injectable()
export class AcademicReportSubmissionService implements SVC {
    private _subjects: Map<SubmissionType, Subject<SubmissionOpportunity>>;

	constructor(
		private http: HttpClient, 
		private appSettings: AppSettings,
        private console: ConsoleService, 
        auth: AuthenticationService
    )  {
        let allTypes = [
            SubmissionType.ConditionChangeFTE,
            SubmissionType.CountyFTE,
            SubmissionType.ThemesProjectsActivitiesANRMMP,
            SubmissionType.ThemesProjectsActivitiesCampusMMP
        ];

        this._subjects = new Map(
            allTypes.map(t => 
            [
                t,
                new ReplaySubject<SubmissionOpportunity>(1)
            ] as [SubmissionType, Subject<SubmissionOpportunity>]));

        allTypes.forEach(t => this._load(t));

        auth.currentIdentity.subscribe(_ => 
            allTypes.forEach(t => this._load(t)));
    }

    public getSubmissionOpportunity(type:SubmissionType): Observable<SubmissionOpportunity> {
        return this._subjects.get(type).asObservable();
    }

    public submit(type:SubmissionType, ffyId: number): Promise<Result<null,string>>{
        return this.http.post(this.appSettings.APIURL + `/academic_report_submission/${type}/${ffyId}`,null)
        .pipe(map(json => 
            Result.fromJson(
                json, 
                _ => {
                    this._load(type);
                    return null;
                },
                (errorMsg: string) => errorMsg)))
        .toPromise();
    }

    private _load(type:SubmissionType): void {
		this.http.get(this.appSettings.APIURL + `/academic_report_submission/${type}`)
        .pipe(map(SubmissionOpportunity.fromJson))
        .subscribe(o => {
            this._subjects.get(type).next(o);
        });
    }
}