import { Option } from '../../domain';
import { NewsStory, UserIdAndName, NewsStoryElementBy, NewsStoryElementAcademic, NewsStoryElementText } from "../../domain";

function dp(date: string): Date {
    return new Date(Date.parse(date));
}

function text(t: string): NewsStoryElementText {
    return new NewsStoryElementText(t);
}

function academic(id: string, user: UserIdAndName): NewsStoryElementAcademic {
    return new NewsStoryElementAcademic(id, user);
}

function by(user: UserIdAndName): NewsStoryElementBy {
    return new NewsStoryElementBy(user, Option.create<String>());
}

export const NEWSSTORIES:NewsStory[] = [
    {
        "timestamp": dp("2018-08-08T12:33:37-07:00"),
        "elements": [
            by({
                "id": -12,
                "name": "Nicholas Rodriguez"
            }),
            text("proposed a merit for"),
            academic(
                "07dce586-5985-40f5-ba70-a9b0e94590a1",
                {
                    "id": 1,
                    "name": "Jeffrey King"
                }),
        ]
    },
    {
        "timestamp": dp("2018-08-08T12:33:37-07:00"),
        "elements": [
            by({
                "id": 2,
                "name": "Rebecca Edwards"
            }),
            text("proposed a merit for"),
            academic(
                "07dce586-5985-40f5-ba70-a9b0e94590a1",
                {
                    "id": 1,
                    "name": "Jeffrey King"
                }),
        ]
    },
    {
        "timestamp": dp("2018-08-08T12:33:37-07:00"),
        "elements": [
            by({
                "id": 2,
                "name": "Rebecca Edwards"
            }),
            text("hired"),
            academic(
                "07dce586-5985-40f5-ba70-a9b0e94590a1",
                {
                    "id": 1,
                    "name": "Jeffrey King"
                }),
        ]
    }
];