//Libraries:
import { TimeAgoPipe } from './time-ago.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { DecimalPipe, PercentPipe } from '@angular/common';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';

import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule, MatButtonToggle } from '@angular/material/button-toggle';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
//App:
import { ActivityFormGutsComponent } from './activity-form-guts/activity-form-guts.component';
import { ActivityIconComponent } from './activity-icon/activity-icon.component';
import { ActivityListComponent } from './activity-list/activity-list.component';
import { ActivityParentPickerComponent } from './activity-parent-picker/activity-parent-picker.component';
import { ActivityTypeMenuComponent } from './activity-type-menu/activity-type-menu.component';
import { ActivityTypeMenuModalComponent } from './activity-type-menu-modal/activity-type-menu-modal.component';
import { AnrFooterComponent } from './anr-footer/anr-footer.component';
import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { AutoCompleteDirective } from './auto-complete/auto-complete.directive';
import { AutoCompleteOptionComponent } from './auto-complete/auto-complete-option.component';
import { AutoGrowDirective } from './auto-grow.directive';
import { AutofocusDirective } from './autofocus.directive';
import { ClienteleGroupPickerComponent } from './clientele-group-picker/clientele-group-picker.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { ColorService } from './color-picker/color.service';
import { CommodityTreeComponent } from './commodity-tree/commodity-tree.component';
import { CounterComponent } from './counter/counter.component';
import { DotComponent } from './dot/dot.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { ErrorSrcDirective } from './error-src.directive';
import { HotkeyDirective, NoHotkeyDirective } from './hotkey.directive';
import { InlineEditorComponent } from './inline-editor/inline-editor.component';
import { InlineTextEditorComponent } from './inline-text-editor/inline-text-editor.component';
import { KeywordHighlightComponent } from './keyword-highlight/keyword-highlight.component';
import { KeywordUnderlineComponent } from './keyword-underline/keyword-underline.component';
import { LocationPickerComponent } from './location-picker/location-picker.component';
import { NonANRCollaboratorModalComponent } from './non-anr-collaborator-modal/non-anr-collaborator-modal.component';
import { NumericDirective } from './numeric.directive';
import { RomanNumeralPipe } from './roman-numeral.pipe';
import { SortColumnHeaderComponent } from './sort-column-header/sort-column-header.component';
import { SortIconComponent } from './sort-icon/sort-icon.component';
import { TagComponent } from './tag/tag.component';
import { TagListComponent } from './tag-list/tag-list.component';
import { TagPickerComponent } from './tag-picker/tag-picker.component';
import { TagTypeNamePipe, TagTypeNamePluralPipe } from './tag-type-name.pipe';
import { TextboxioComponent } from './textboxio/textboxio.component';
import { ThemePickerComponent } from './theme-picker/theme-picker.component';
import { UserAvatarUrlPipe } from './user-avatar-url.pipe';
import { UserPickerComponent } from './user-picker/user-picker.component';
import { NonAnrCollaboratorFormControlComponent } from './non-anr-collaborator-form-control/non-anr-collaborator-form-control.component';
import { ActivityInvalidReasonIconComponent } from './activity-invalid-reason-icon/activity-invalid-reason-icon.component';
import { ActivityDateComponent, ActivityDatePipe } from './activity-date/activity-date.component';
import { ActivityTypeNamePipe } from './activity-type-name.pipe';
import { ActivitySubTypeNamePipe } from './activity-sub-type-name.pipe';
import { CountySelectionComponent } from './county-selection/county-selection.component';
import { CountySelectionPickerComponent } from './county-selection-picker/county-selection-picker.component';
import { ProjectInvalidReasonIconComponent } from './project-invalid-reason-icon/project-invalid-reason-icon.component';
import { ActivityCollaborationFlagComponent } from './activity-collaboration-flag/activity-collaboration-flag.component';
import { UnitPickerComponent } from './unit-picker/unit-picker.component';
import { ConfirmModalComponent, ConfirmModalService } from './confirm-modal/confirm-modal.component';
import { ClienteleContactSummaryComponent } from './clientele-contact-summary/clientele-contact-summary.component';
import { PopOverComponent } from './pop-over/pop-over.component';
import { CurrentStateFiscalYear } from '../domain';
import { ActivityBulkUpdateComponent } from './activity-bulk-update/activity-bulk-update.component';
import { UserAvatarDirective } from './user-avatar.directive';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,

		MatAutocompleteModule,
		MatChipsModule,
		MatDialogModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatNativeDateModule,
		MatRadioModule,
		MatTooltipModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatButtonToggleModule,
		
	],
	exports: [
		ActivityCollaborationFlagComponent,
		ActivityDateComponent,
		ActivityDatePipe,
		ActivityFormGutsComponent,
		ActivityIconComponent,
		ActivityInvalidReasonIconComponent,
		ActivityListComponent,
		ActivityParentPickerComponent,
		ActivityTypeMenuComponent,
		ActivityTypeMenuModalComponent,
		ActivityTypeNamePipe,
		ActivitySubTypeNamePipe,
		AnrFooterComponent,
		AutoCompleteComponent,
		AutoCompleteDirective,
		AutoCompleteOptionComponent,
		AutoGrowDirective,
		AutofocusDirective,
		ClienteleContactSummaryComponent,
		ClienteleGroupPickerComponent,
		CountySelectionComponent,
		CountySelectionPickerComponent,
		ColorPickerComponent,
		CommodityTreeComponent,
		CounterComponent,
		CurrentStateFiscalYear,
		DotComponent,
		EmptyStateComponent,
		InlineTextEditorComponent,
		ErrorSrcDirective,
		KeywordHighlightComponent,
		KeywordUnderlineComponent,
		LocationPickerComponent,
		HotkeyDirective,
		NoHotkeyDirective,
		NonAnrCollaboratorFormControlComponent,
		NumericDirective,
		ProjectInvalidReasonIconComponent,

		MatDialogModule,
		MatExpansionModule,
		MatIconModule,
		MatMenuModule,
		MatSnackBarModule,
		MatRadioModule,
		MatTooltipModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatAutocompleteModule,
		MatChipsModule,
		MatDatepickerModule,
		MatButtonToggleModule,
		MatButtonToggle,

		RomanNumeralPipe,
		SortColumnHeaderComponent,
		SortIconComponent,
		TagComponent,
		TagListComponent,
		TagPickerComponent,
		ThemePickerComponent,
		UserAvatarUrlPipe,
		TagTypeNamePipe,
		TagTypeNamePluralPipe,
		TimeAgoPipe,
		UnitPickerComponent,
		UserPickerComponent,
		InlineEditorComponent,
		TextboxioComponent,
		PopOverComponent,
		UserAvatarDirective,
	],
	declarations: [
		ActivityCollaborationFlagComponent,
		ActivityDateComponent,
		ActivityDatePipe,
		ActivityFormGutsComponent,
		ActivityIconComponent,
		ActivityInvalidReasonIconComponent,
		ActivityListComponent,
		ActivityParentPickerComponent,
		ActivitySubTypeNamePipe,
		ActivityTypeMenuComponent,
		ActivityTypeMenuModalComponent,
		ActivityTypeNamePipe,
		AnrFooterComponent,
		AutoCompleteComponent,
		AutoCompleteDirective,
		AutoCompleteOptionComponent,
		AutoGrowDirective,
		AutofocusDirective,
		ClienteleContactSummaryComponent,
		ClienteleGroupPickerComponent,
		ColorPickerComponent,
		CommodityTreeComponent,
		ConfirmModalComponent,
		CounterComponent,
		CountySelectionComponent,
		CountySelectionPickerComponent,
		CurrentStateFiscalYear,
		DotComponent,
		EmptyStateComponent,
		ErrorSrcDirective,
		HotkeyDirective,
		InlineEditorComponent,
		InlineTextEditorComponent,
		KeywordHighlightComponent,
		KeywordUnderlineComponent,
		LocationPickerComponent,
		NoHotkeyDirective,
		NonANRCollaboratorModalComponent,
		NonAnrCollaboratorFormControlComponent,
		NumericDirective,
		ProjectInvalidReasonIconComponent,
		RomanNumeralPipe,
		SortColumnHeaderComponent,
		SortIconComponent,
		TagComponent,
		TagListComponent,
		TagPickerComponent,
		TagTypeNamePipe,
		TagTypeNamePluralPipe,
		TextboxioComponent,
		ThemePickerComponent,
		TimeAgoPipe,
		UnitPickerComponent,
		UserAvatarUrlPipe,
		UserPickerComponent,
		PopOverComponent,
  		ActivityBulkUpdateComponent,
  		UserAvatarDirective,
	],
	providers: [
		ColorService,
		ConfirmModalService,
		DecimalPipe,
		PercentPipe,
		DatePipe,
		TimeAgoPipe
	],
	entryComponents: [
		ConfirmModalComponent
   	]
})
export class WidgetsModule { }
