<div>
    <section-header
    sectionTitle="Academic Program Review"
    navPath="/program-review"
    containerClass="container"
    [navLinks]="[]"></section-header>

    <div style="margin-top: 20px;">
        <div class="col-md-3">
            <user-picker 
            [formControl]="usersControl"
            (enableNonANRCollaborator)="false"
            [selectSingle]="true"
            [updateUserTableFromDirectory]="false"
            [showUserIds]="true"></user-picker>
        </div>
       <div style="margin-top: 10px;margin-left: 1em;">
        <div>
            <button class="btn btn-primary" style="width:7em;" [disabled]="usersControl.value.toArray().length != 1" (click)="Spoof()" type="submit">
                Admin Proxy
              </button>
        </div>
       </div>
        <spna *ngIf="message">{{message}}</spna>
    </div>
    <div style="margin-top: 14em;">
        <anr-footer></anr-footer>
    </div>
 
</div>

