import { HumanResources, PayrollCampus, Option, FTE, SortedSet, User, HasHashString } from "..";

export class HRUnit implements HasHashString {
	public readonly hashString: string;
    constructor(
        public readonly id: number,
        public readonly type: string,
        public readonly name: string
    ){
        this.hashString = id.toString();
    }
    public static fromJson(o: any): HRUnit {
        return new HRUnit(
            o.id,
            o.type,
            o.name
        );
    }
}

export class IdAndName {
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly emailAddress?: string
    ){}
    
    public static fromJson(o: any): IdAndName {
        return new IdAndName(
            <number>o.id,
            <string>o.name,
            <string>o.emailAddress);
    }
}

export class Title {
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly ranks: Rank[]
    ) {}

    public static fromJson(o: any): Title {
        return o;
    }
}

export class Rank {
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly steps: Step[]
    ) {}
}

export class Step {
    constructor(
        public readonly id: number,
        public readonly number: number,
        public readonly name: string
    ) {}
}

export class HRAcademic {
    constructor(
        public readonly userId: number,
        public readonly fullName: string,
        public readonly status: IdAndName,
        public readonly title: IdAndName,
        public readonly rank: Option<IdAndName>,
        public readonly step: Option<IdAndName>,
        public readonly term: Option<IdAndName>,
        public readonly supervisors: SortedSet<User>,
        public readonly appointmentUnits: SortedSet<HRUnit>,
        public readonly totalFte: FTE,
        public readonly hr: HumanResources,
        public readonly payrollCampus: PayrollCampus
    ){}
    public static fromJson(o: any): HRAcademic {
        return new HRAcademic(
            o.userId,
            o.fullName,
            o.status,
            o.title,
            Option.fromJson(o.rank, ob => ob),
            Option.fromJson(o.step, ob => ob),
            Option.fromJson(o.term, ob => ob),
            new SortedSet<User>(
                o.supervisors.map(u => User.fromJson(u))
            ),
            new SortedSet<HRUnit>(
                o.appointmentUnits.map(u => HRUnit.fromJson(u))
            ),
            FTE.fromJson(o.totalFte),
            o.hr,
            PayrollCampus.fromJson(o.payrollCampus)
        );
    }
}

export interface HRCommandPattern<T> {
    ChangeStep: (cmd: ChangeStep) => T,
    ChangeTerm: (cmd: ChangeTerm) => T,
    SetSupervisors: (cmd: SetSupervisors) => T,
    SetAcademic: (cmd: SetAcademic) => T,
    CreateAcademic: (cmd: CreateAcademic) => T
}

export abstract class HRCommand {
    public match<T>(pattern: HRCommandPattern<T>): T
    {
        if(this instanceof ChangeStep){
            return pattern.ChangeStep(this);
        }
        if(this instanceof ChangeTerm){
            return pattern.ChangeTerm(this);
        }
        if(this instanceof SetSupervisors){
            return pattern.SetSupervisors(this);
        }
        if(this instanceof SetAcademic){
            return pattern.SetAcademic(this);
        }
        if(this instanceof CreateAcademic){
            return pattern.CreateAcademic(this);
        }
        throw "Unexpected subclass of HRCommand";
    }
}

export class ChangeStep extends HRCommand {
    constructor(
        public userId: number,
        public stepId: number)
    {
        super();
    }
}

export class ChangeTerm extends HRCommand {
    constructor(
        public userId: number,
        public termId: number | null)
    {
        super();
    }
}

export class SetSupervisors extends HRCommand {
    constructor(
        public academicUserId: number,
        public supervisorUserIds: number[])
    {
        super();
    }
}

export class SetAcademic extends HRCommand {
    constructor(
        public userId: number,
        public stepId: number,
        public termId: number | null,
        public supervisorUserIds: number[],
        public appointmentUnitIds: number[],
        public totalFte: number,
        public statusId: number,
        public payrollCampusId: number,
        public hr: HumanResources)
    {
        super();
    }
}

export class CreateAcademic extends HRCommand {
    constructor(
        public userId: number,
        public stepId: number,
        public termId: number | null,
        public supervisorUserIds: number[],
        public appointmentUnitIds: number[],
        public totalFte: number,
        public statusId: number,
        public payrollCampusId: number,
        public hr: HumanResources)
    {
        super();
    }
}

export enum AcademicStatus {
    Active = 10,
    Emeritus = 20,
    Retired = 30,
    Resigned = 40
}

export const AcademicStatuses: IdAndName[] =
[
    AcademicStatus.Active,
    AcademicStatus.Emeritus,
    AcademicStatus.Retired,
    AcademicStatus.Resigned
].map(id => new IdAndName(id, AcademicStatus[id]));