<div *ngIf="!!case" class="card">
    <div class="card-block">
        <div class="row">
            <div class="col">
                <div class="media">
                    <div class="pull-left">
                        <a href="{{case.userId | userAvatarUrl}}?download=false" target="_blank">

                            <img appUserAvatar="{{case.userId}}" [userName]="case.candidateName" [showTooltip]="false" [checkChanges]="true"
                                style="width:60px; border:1px solid #ddd;"
                                class="img-round img-fluid user-avatar-sm mr-3">
                        </a>
                    </div>
                    <div class="media-body">
                        <div>{{case.candidateName}}</div>
                        <div class="text-sm">{{case.currentPosition | titleRankStepTerm}}</div>
                    </div>
                </div>
            </div>
            <div class="col">
                <table class="table-sm">
                    <tbody>
                        <tr>
                            <th>Proposed Action</th>
                            <td>
                                <proposed-action [caseAdvancement]="case.asCaseAdvancement()"></proposed-action>
                            </td>
                        </tr>
                        <tr>
                            <th>Period of Review</th>
                            <td [ngSwitch]="!!case.periodOfReview.value">
                                <div *ngSwitchCase="true">
                                    {{case.periodOfReview.value.start | date:'mediumDate' }} -
                                    {{case.periodOfReview.value.end   | date:'mediumDate' }} 
                                </div>
                                <div *ngSwitchCase="false">
                                    N/A
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <hr>

        <case-progress-bar [progress]="case.progress"></case-progress-bar>

        <hr>

        <ng-container *ngIf="reviewerCanSeeCaseHistory()"> <!-- Show Case History - Only Supervisors can see case history -->
            <mat-accordion multi>
                <mat-expansion-panel 
                    *ngFor="let caseId of case.caseHistory" 
                    [expanded]="caseHistoryIsFirst(caseId.periodOfReviewEndingInYear)" 
                    (opened)="loadCaseFilesIfNeeded(caseId)"
                    class="no-box-shadow">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{caseId.periodOfReviewEndingInYear}}-{{caseId.periodOfReviewEndingInYear+1}} Program Review Files
                        </mat-panel-title>
                    </mat-expansion-panel-header>
              
                    <div class="panel-content">
                        <case-files [files]="caseFiles[caseId.periodOfReviewEndingInYear]" [caseId]="caseId" *ngIf="caseFilesExist(caseId.periodOfReviewEndingInYear)"
                            [candidateFilesVisible]="true"
                            [reviewerFilesVisible]="true"
                            [candidateName]="case.candidateName"
                        ></case-files>
    
                        <div *ngIf="!caseFilesExist(caseId.periodOfReviewEndingInYear)">Loading...</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-container>

        <ng-container *ngIf="!reviewerCanSeeCaseHistory()"> <!-- only show this years files - Supervisors and AVP can see case history -->
            <mat-accordion multi>
                <mat-expansion-panel 
                    [expanded]="true" 
                    class="no-box-shadow">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{case.id.periodOfReviewEndingInYear}}-{{case.id.periodOfReviewEndingInYear+1}} Program Review Files
                        </mat-panel-title>
                    </mat-expansion-panel-header>
              
                    <div class="panel-content">
                        <case-files [files]="caseFiles[case.id.periodOfReviewEndingInYear]" [caseId]="case.id" *ngIf="!!caseFiles[case.id.periodOfReviewEndingInYear]"
                            [candidateFilesVisible]="true"
                            [reviewerFilesVisible]="true"
                            [candidateName]="case.candidateName"                            
                        ></case-files>

                        <div *ngIf="!caseFiles[case.id.periodOfReviewEndingInYear]">Loading...</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-container>
        
        <hr>

        <div class="row">
            <ng-container *ngIf="currentUser.value.roles.academicHumanResources && reviewerType=='AHR'">
                <div class="col-md-6">
                    <span class="mt-3" style="font-weight:bold;">
                        AHR Admin
                    </span>
                    <br>
                    <div class="text-muted text-sm">
                        You are <strong>not</strong> required to upload a review for this candidate.
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!currentUser.value.roles.academicHumanResources || reviewerType!='AHR'">

                <div *ngFor="let s of case.yourSteps" class="col-md-6">
                    <span [ngSwitch]="!!s.requiredReviewDocument.value" class="mt-3" style="font-weight:bold;">
                        <span *ngSwitchCase="false" class="text-muted text-sm">
                            {{s.title}}
                        </span>
                        <span *ngSwitchCase="true">
                            <strong>{{s.requiredReviewDocument.value.name}}</strong>
                        </span>
                    </span>
                    <span class="badge ml-2" [ngClass]="{
                        'badge-default': (s.status === 'Complete') || (s.status === 'Upcoming'),
                        'badge-success': (s.status === 'Active')
                    }">{{s.status}}</span>

                    <br>

                    <div [ngSwitch]="!!s.requiredReviewDocument.value" class="mt-3">
                        <div *ngSwitchCase="false" class="text-muted text-sm">
                            You are <strong>not</strong> required to upload a review for this candidate.
                        </div>
                        <div *ngSwitchCase="true">
                            <div [innerHtml]="s.requiredReviewDocument.value.instructionsHTML"></div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>



        <div *ngIf="!wfaCase.value" class="text-muted text-sm">
        </div>
        <div *ngIf="!!wfaCase.value && !isReadOnlyStep()">
            <hr>
            <span>
                <a (click)="navigateToWFA(wfaCase.value.url)"
                    target="_blank" 
                    class="btn btn-outline-primary">
                    Upload and Submit Your Review
                </a>
            </span>
        </div>

        <hr>

        <div *ngIf="!wfaCase.value" class="text-muted text-sm">
            <a href="https://ucanr.edu/sites/ProjectBoardHelp/User_Manual_-_APR_Reviewer_Instructions/" 
                    target="_blank" 
                    class="btn btn-sm btn-link">
                    User manual for Supervisors/Reviewers: Accessing candidate materials and uploading review comments
                </a>
            
        </div>
    </div>
</div>