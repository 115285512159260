import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConsoleService } from "app/widgets/console.service";
import { TermId, PositionId } from "app/domain";
import { Advancement, TitleRankAndStepId, Option, TitleRankStepAndTermId } from "../../../domain";

export class TermClickEvent {
	constructor(
		public readonly termId: TermId,
	){}
}

@Component({
	selector: 'term-table',
	templateUrl: './term-table.component.html',
	styleUrls: ['./term-table.component.scss']
})
export class TermTableComponent implements OnInit {

	termSteps = [
		{
			id: TermId.Term1,
			name: "Term 1",
			state: "none"
		},
		{
			id: TermId.Term2,
			name: "Term 2",
			state: "none"
		},
		{
			id: TermId.Term3,
			name: "Term 3",
			state: "none"
		},
		{
			id: TermId.IndefiniteStatus,
			name: "Indefinite Status",
			state: "none"
		}
	];

	@Input() currentPosition: TitleRankStepAndTermId;
	@Input() selectedTerm: TermId;
	@Output() termClick: EventEmitter<TermClickEvent> = new EventEmitter<TermClickEvent>();

	constructor(
		private readonly console: ConsoleService
	) { 

	}

	ngOnInit() {
		this.updateTermStates();
	}

	termClick_(term: any) {
		this.console.log("Term Clicked: ", term);
		this.termClick.emit(new TermClickEvent(term.id));
	}

	ngOnChanges(){
		this.updateTermStates();
	}

	updateTermStates() {
		this.console.log("updateTermStates Current Term: ", this.currentPosition.termId);
		this.console.log("updateTermStates Selected Term: ", this.selectedTerm);
		let termMatched = false;
		this.termSteps.forEach(term => {
			term.state = "none";
			if (term.id == this.currentPosition.termId) {
				term.state = "Current";
				termMatched = true;
			} else if (term.id == this.selectedTerm) {
				term.state = "Proposed";
			} else if (!termMatched) {
				// term has not beem matvhed yet so this is a regression or old state
				term.state = "Regression";
			}
		});
	}
}
