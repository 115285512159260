<div *ngIf="!themes">
	Loading...
</div>

<div *ngIf="!!themes && themes.length == 0">
	You haven&apos;t added any themes yet
</div>

<div *ngIf="!!themes && themes.length > 0">
	<div *ngFor="let t of themes" class="theme-wrapper {{t.includeInDossier ? '' : 'text-muted'}}">
		<div class="theme-content mat-depth-z1">
			<div class="media">
				<input type="checkbox" id="theme-{{t.id}}" style="margin-top:0.4em;" 
					class="mr-2" 
					(change)="saveSingleChange(t.includeInDossier, t.id, DossierIdType.ThemeId)"
					[(ngModel)]="t.includeInDossier">
				<div class="media-body">
					<label class="font-weight-bold mb-2" for="theme-{{t.id}}">
						{{t.name}}
					</label>
					<button class="btn btn-primary btn-sm pull-right"
						(click)="openProjectModal(t)">
						+ Add Project
					</button>
					<div>
						<button class="btn btn-sm btn-link text-xs" style="color:#999; padding-left:0;"
							(click)="toggleExpanded('themeSituation', t.id)">
							Background, Clientele, Goals, Inputs
							<i class="fa fa-fw {{toggleIcon('themeSituation',t.id)}}"></i>
						</button>
						<div class="text-sm" [hidden]="!expanded.themeSituation[t.id]"> 
							<inline-editor 
								[edited]="themeSituations[t.id] != t.situation" 
								[saveFn]="saveSituation(t, themeSituations[t.id])" 
								(transitionToEdit)="situationInput.focus()" 
								(cancel)="themeSituations[t.id] = t.situation"
								#situationEditor>
								<div readonly style="padding-left:1em;">
									<div [innerHtml]="t.situation"></div>
									<em [hidden]="!!t.situation">
										Click or Tap to edit
									</em>
								</div>
								<div editor *ngIf="situationEditor.state === 'editing'">
									<textboxio 
										[(content)]="themeSituations[t.id]" 
										#situationInput></textboxio>
								</div>
							</inline-editor>
						</div>
					</div>
					<div>
						<button class="btn btn-sm btn-link text-xs" style="color:#999; padding-left:0;"
							(click)="toggleExpanded('themeNarrative', t.id)">
							Methods, Outcomes, Impacts
							<i class="fa fa-fw {{toggleIcon('themeNarrative',t.id)}}"></i>
						</button>
						<div class="text-sm" [hidden]="!expanded.themeNarrative[t.id]">
							<inline-editor 
								[edited]="themeNarratives[t.id] != t.narrative" 
								[saveFn]="saveNarrative(t, themeNarratives[t.id])" 
								(transitionToEdit)="narrativeInput.focus()" 
								(cancel)="themeNarratives[t.id] = t.narrative"
								#narrativeEditor>
								<div readonly style="padding-left:1em;">
									<div [innerHtml]="t.narrative"></div>
									<em [hidden]="!!t.narrative">
										Click or Tap to edit
									</em>
								</div>
								<div editor *ngIf="narrativeEditor.state === 'editing'">
									<textboxio 
										[(content)]="themeNarratives[t.id]" 
										#narrativeInput></textboxio>
								</div>
							</inline-editor>
							<div [hidden]="!t.narrative">
								<uc-delivers-story-link></uc-delivers-story-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="list-group theme-child-list">
			<div class="list-group-item {{t.projectSelector.state == 'none' ? 'text-muted' :''}}"
				style="clear:both;">
				<div [ngSwitch]="t.projects.length">
					<div *ngSwitchCase="0">
						0 Projects
					</div>
					<div *ngSwitchDefault>
						<button class="btn btn-link btn-sm list-toggle" 
							(click)="toggleExpanded('themeProjects', t.id)">
							<i class="fa fa-fw {{toggleIcon('themeProjects',t.id)}}"></i>
						</button>
						<label class="checkbox">
							<input type="checkbox"
								(change)="save()"
								[(ngModel)]="t.projectSelector.all"
								[disabled]="!t.includeInDossier"
								[indeterminate]="t.projectSelector.state == 'mixed'">
							{{t.projectSelector.totalSelected}} 
							of 
							{{t.projectSelector.total}} 
							Projects Selected
						</label>
					</div>
				</div>
				<div class="theme-projects" [hidden]="!expanded.themeProjects[t.id]">
					<div *ngFor="let p of t.projects"
							class="project-wrapper {{p.includeInDossier ? '' : 'text-muted'}}">

						<div class="project-content">
							<input type="checkbox" id="project-{{p.id}}" style="margin-top:0.4em;" 
								class="mr-2" 
								(change)="saveSingleChange(p.includeInDossier, p.id, DossierIdType.ProjectId)"
								[disabled]="!t.includeInDossier"
								[(ngModel)]="p.includeInDossier">
							<label class="font-weight-bold mb-2" for="project-{{p.id}}">
								<button (click)="editProject(p.id)" class="btn btn-sm btn-link">
									{{p.name}}
								</button>
							</label>
							<button class="btn btn-primary btn-sm pull-right mr-5"
								(click)="newActivityForProject(p)">
								+ Add Activity
							</button>
							<button class="btn btn-link btn-sm list-toggle" 
								(click)="toggleExpanded('projectActivities', p.id)">
								<i class="fa fa-fw {{toggleIcon('projectActivities',p.id)}}"></i>
							</button>
						</div>

						<div class="activities list-group" [hidden]="!expanded.projectActivities[p.id]">
							<div class="list-group-item">
								<label class="checkbox">
									<input type="checkbox" 
										(change)="save()"
										[(ngModel)]="p.activitySelector.all"
										[disabled]="!p.includeInDossier"
										[indeterminate]="p.activitySelector.state == 'mixed'">
									{{p.activitySelector.totalSelected}} 
									of 
									{{p.activitySelector.total}} 
									Activities Selected
								</label>
							</div>
							<div *ngFor="let a of p.activities"
								class="activity list-group-item text-sm {{a.includeInDossier ? '' : 'text-muted'}}">
								<div class="row" style="width:100%;">
									<div class="col-md-6">
										<label class="checkbox">
											<input type="checkbox" 
												(change)="saveSingleChange(a.includeInDossier, a.id, DossierIdType.ActivityId)"
												[(ngModel)]="a.includeInDossier"
												[disabled]="!p.includeInDossier">
											<activity-type-icon 
												[activityTypeId]="a.typeId" 
												style="color:#999; margin-right:0.5em;"></activity-type-icon>
											<a class="href" (click)="activityClick(a,$event)">
												{{a.name}}
											</a>
										</label>
									</div>
								
									<div class="col-md-4">
										<activity-invalid-reason-icon [messages]="a.invalidReasons"></activity-invalid-reason-icon>
										{{a.typeId | activityTypeName}}
									</div>

									<div class="col-md-2 text-right text-nowrap">
										<activity-date [activityDate]="a.date"></activity-date>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="list-group-item {{t.themeActivitySelector.state == 'none' ? 'text-muted' :''}}">
				<div [ngSwitch]="t.themeActivities.length">
					<div *ngSwitchCase="0">
						0 Theme Activities
					</div>
					<div *ngSwitchDefault>
						<button class="btn btn-link btn-sm list-toggle" 
							(click)="toggleExpanded('themeActivities', t.id)">
							<i class="fa fa-fw {{toggleIcon('themeActivities',t.id)}}"></i>
						</button>
						<label class="checkbox">
							<input type="checkbox" id="theme-activities-{{t.id}}" style="margin-top:0.4em;" 
								class="mr-2" 
								(change)="save()"
								[(ngModel)]="t.themeActivitySelector.all"
								[disabled]="!t.includeInDossier"
								[indeterminate]="t.themeActivitySelector.state == 'mixed'">
							{{t.themeActivitySelector.totalSelected}} 
							of 
							{{t.themeActivitySelector.total}} 
							Theme Activities Selected
						</label>
					</div>
				</div>
				<div class="activities list-group" [hidden]="!expanded.themeActivities[t.id]">
					<div *ngFor="let a of t.themeActivities"
						class="activity list-group-item text-sm {{a.includeInDossier ? '' : 'text-muted'}}">
						<div class="row" style="width:100%;">
							<div class="col-md-6">
								<label class="checkbox">
									<input type="checkbox"
										(change)="saveSingleChange(a.includeInDossier, a.id, DossierIdType.ActivityId)"
										[(ngModel)]="a.includeInDossier"
										[disabled]="!t.includeInDossier">
									<activity-type-icon 
										[activityTypeId]="a.typeId" 
										style="color:#999; margin-right:0.5em;"></activity-type-icon>
									<a class="href" (click)="activityClick(a,$event)">
										{{a.name}}
									</a>
								</label>
							</div>
						
							<div class="col-md-4">
								<activity-invalid-reason-icon [messages]="a.invalidReasons"></activity-invalid-reason-icon>
								{{a.typeId | activityTypeName}}
							</div>

							<div class="col-md-2 text-right text-nowrap">
								<activity-date [activityDate]="a.date"></activity-date>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>