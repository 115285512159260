import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Tag } from '../../domain';
import { TooltipService } from '../../widgets/tooltip.service';

@Component({
  selector: 'tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent {
	@Input() value: Tag;
	@Input() keyword: string = '';
	@Output() clickX = new EventEmitter<MouseEvent>();
	@Input() showX: boolean = false;

	constructor(public tooltipSvc: TooltipService){}

	tooltipText(): string {
		if (this.tooltipSvc.tooltip('TagDisplayText.'+ this.value.hashString)) {
			return `${this.tooltipSvc.tooltip('TagDisplayText.'+ this.value.hashString)} (${this.value.typeName})`;
		}
		return `${this.value.name} (${this.value.typeName})`;
	}

	valueText(): string {
		if (this.tooltipSvc.tooltip('TagDisplayText.'+ this.value.hashString)) {
			return `${this.tooltipSvc.tooltip('TagDisplayText.'+ this.value.hashString)}`;
		}
		return this.value.name;
	}
}
