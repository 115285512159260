
import { distinctUntilChanged, debounceTime, startWith } from 'rxjs/operators';
import { Input, Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { AHRService, SortedSet, HRUnit } from '../../domain';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConsoleService } from '../console.service';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { partition } from 'rxjs';

export class UnitGroup {
	constructor(
		public readonly name: string,
		public readonly units: HRUnit[]
	){}
}

@Component({
	selector: 'unit-picker',
	templateUrl: './unit-picker.component.html',
	styleUrls: ['./unit-picker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: UnitPickerComponent,
			multi: true
		}
	]
})
export class UnitPickerComponent implements OnInit, AfterViewInit, ControlValueAccessor {
	ngAfterViewInit(): void {
		this.console.log('autoComplete: ', this.autoComplete);
	}
	@Input() controlClass: string = '';
	sortedUnits: HRUnit[] = [];
	unitGroups: UnitGroup[] = [];
	allUnits: HRUnit[] = [];
	searchControl = new FormControl();
	@ViewChild('auto') autoComplete: MatAutocomplete;
	selectedUnits: SortedSet<HRUnit> = new SortedSet<HRUnit>();
	lastTerm: string = '';
	@ViewChild('input') input: ElementRef;
	formLabelClass = "col-4";
	formControlClass = "col-8";

	

	ngOnInit() { 
	}

	constructor(
		private console: ConsoleService,
		private readonly svc: AHRService
	){
		let [selections, termChanges] = 
			partition(
				this.searchControl
					.valueChanges
					.pipe(startWith(null)),
				o => o instanceof HRUnit);

		selections
			.subscribe((unit: HRUnit) => this.selectUnit(unit));

		termChanges.pipe(
			debounceTime(200),
			distinctUntilChanged(),)
			.subscribe(term => {
				this.lastTerm = term;
				this._refreshAvailableUnits();
			});
	}

	removeUnit(unit: HRUnit): void {
		if(this.selectedUnits.remove(unit)){
			this._onChange(this.selectedUnits);
			this._refreshAvailableUnits();
		}
	}

	displayWith(unit){
		return unit.name;
	}

	public selectUnit(unit: HRUnit){
		if(this.selectedUnits.add(unit)){
			//this.console.log("Selected Unit: ", unit);
			this._onChange(this.selectedUnits);
		}
		this.input.nativeElement.value = '';
		this.searchControl.setValue('');
		this._refreshAvailableUnits();
		this.input.nativeElement.blur();
		//this.console.log("Selected Units: ", this.selectedUnits);
	}

	public getShortTypeName(type: string): string {
		return type.slice(0,1);
	}

	private _refreshAvailableUnits(): void {
		// get all units once. We'll filter them locally
		if(this.allUnits.length == 0){
			this.svc
				.getAllANRUnits()
				.then(units => {
					this.allUnits = units;
					this._sortUnits();
				});
		}
		else {
			this._sortUnits();
		}
	}
	
	private _sortUnits(){
		this.sortedUnits = this.allUnits.slice().sort(this._sortByType);

		//for some reason this filter removes ALL units from the filter when a single unit is selected.
		this.sortedUnits = this.sortedUnits.filter(unit => !this.selectedUnits.contains(unit));

		if(!!this.lastTerm && this.lastTerm.length > 0){
			this.sortedUnits = this.sortedUnits.filter(unit => 
				unit.name.toLowerCase().includes(this.lastTerm.toLowerCase())
			);
		}

		let types: string[] = [];
		this.sortedUnits.forEach(unit => {
			if(types.findIndex(a => a == unit.type) == -1) {
				types.push(unit.type);
			}
		});

		this.unitGroups = [];
		types.map(type => {
			this.unitGroups.push(new UnitGroup(
				type,
				this.sortedUnits.slice().filter(unit => unit.type == type)
			));
		});

		//this.console.log("All units: ", this.allUnits);
		//this.console.log("sorted Units: ", this.sortedUnits);
		//this.console.log("types: ", types);
		//this.console.log("unit groups: ", this.unitGroups);
	}

	private _sortByType(a: HRUnit, b: HRUnit): number{
		// sort by type in descending order
		if(a.type.toLowerCase() > b.type.toLowerCase()) {
			return -1;
		}
		else if(a.type.toLowerCase() < b.type.toLowerCase()) {
			return 1;
		}
		else {
			// sort by name in ascending order
			if (a.name.toLowerCase() > b.name.toLowerCase()) {
				return 1;
			}
			else if (a.name.toLowerCase() < b.name.toLowerCase()) {
				return -1;
			}
		}
		return 0;
	}

	//
	//ControlValueAccessor implementation:
	private _onChange: any = () => {};
	private _onTouch:  any = () => {};
	writeValue(val: SortedSet<HRUnit>): void {
		//console.log('writeValue:', val);
		this.selectedUnits = val;
		//this.console.log("Selected Units: ", this.selectedUnits);
		this._refreshAvailableUnits();
		//this._onChange(val);
	}
	registerOnTouched(fn): void { this._onTouch  = fn; }
	registerOnChange(fn) : void { this._onChange = fn; }
}
