import { Commodity } from '../../domain';

export const COMMODITIES: Commodity[] = 
[
	{
		"id": 1,
		"name": "Ag Commodities and Products - General",
		"children": []
	},
	{
		"id": 2,
		"name": "Ag Commodities and Products - Other",
		"children": []
	},
	{
		"id": 3,
		"name": "Animal Commodities and Products",
		"children": [
			{
				"id": 8,
				"name": "Animal Commodities and Products - General",
				"children": []
			},
			{
				"id": 9,
				"name": "Animal Commodities and Products - Other",
				"children": [
					{
						"id": 36,
						"name": "Honey Bees, Honey and Bee Products",
						"children": []
					},
					{
						"id": 43,
						"name": "Beneficial Insects - Other",
						"children": []
					},
					{
						"id": 64,
						"name": "Rabbits",
						"children": []
					},
					{
						"id": 71,
						"name": "Misc. Animal Commodities - Other",
						"children": []
					}
				]
			},
			{
				"id": 14,
				"name": "Cultured Aquatic Animals",
				"children": [
					{
						"id": 34,
						"name": "Cultured Aquatic Animals - General",
						"children": []
					},
					{
						"id": 45,
						"name": "Finfish",
						"children": []
					},
					{
						"id": 62,
						"name": "Freshwater Shellfish",
						"children": []
					},
					{
						"id": 73,
						"name": "Marine Shellfish",
						"children": []
					},
					{
						"id": 88,
						"name": "Cultured Aquatic Animals - Other",
						"children": []
					}
				]
			},
			{
				"id": 15,
				"name": "Poultry",
				"children": [
					{
						"id": 35,
						"name": "Poultry - General",
						"children": []
					},
					{
						"id": 44,
						"name": "Egg-Type Chickens / Eggs / Egg Products",
						"children": []
					},
					{
						"id": 63,
						"name": "Meat-Type Chickens",
						"children": []
					},
					{
						"id": 72,
						"name": "Poultry Meat and Meat Products",
						"children": []
					},
					{
						"id": 90,
						"name": "Turkeys",
						"children": []
					},
					{
						"id": 95,
						"name": "Poultry - Other",
						"children": []
					},
					{
						"id": 104,
						"name": "Ducks and Geese",
						"children": []
					}
				]
			},
			{
				"id": 20,
				"name": "Livestock and Dairy",
				"children": [
					{
						"id": 33,
						"name": "Livestock and Dairy - General",
						"children": []
					},
					{
						"id": 46,
						"name": "Beef Cattle / Beef / Other Cattle Prod",
						"children": []
					},
					{
						"id": 61,
						"name": "Dairy Cattle / Milk / Other Dairy Prod",
						"children": []
					},
					{
						"id": 74,
						"name": "Goats and Goat Products",
						"children": []
					},
					{
						"id": 87,
						"name": "Horses, Ponies, and Mules",
						"children": []
					},
					{
						"id": 96,
						"name": "Sheep, Sheep Meat and Wool",
						"children": []
					},
					{
						"id": 103,
						"name": "Swine, Pork and Pork Products",
						"children": []
					},
					{
						"id": 110,
						"name": "Livestock and Dairy - Other",
						"children": []
					}
				]
			}
		]
	},
	{
		"id": 4,
		"name": "Plant Commodities and Products",
		"children": [
			{
				"id": 7,
				"name": "Plant Cmmdtys and Prod - General",
				"children": []
			},
			{
				"id": 10,
				"name": "Plant Cmmdtys and Prod - Other",
				"children": []
			},
			{
				"id": 13,
				"name": "Field Crops",
				"children": [
					{
						"id": 37,
						"name": "Field Crops - General",
						"children": []
					},
					{
						"id": 42,
						"name": "Sugar Beets",
						"children": []
					},
					{
						"id": 65,
						"name": "Field Crops - Other",
						"children": []
					},
					{
						"id": 70,
						"name": "Forage Crops",
						"children": [
							{
								"id": 158,
								"name": "Forage Crops - General",
								"children": [
									{
										"id": 254,
										"name": "Alfalfa",
										"children": []
									},
									{
										"id": 255,
										"name": "Other Legumes",
										"children": []
									},
									{
										"id": 256,
										"name": "Grasses - General",
										"children": []
									},
									{
										"id": 257,
										"name": "Grasses, Annual",
										"children": []
									},
									{
										"id": 258,
										"name": "Grasses, Perennial",
										"children": []
									},
									{
										"id": 259,
										"name": "Hay",
										"children": []
									},
									{
										"id": 260,
										"name": "Silage and Green Chop",
										"children": []
									},
									{
										"id": 261,
										"name": "Forage Crops - Other",
										"children": []
									}
								]
							}
						]
					},
					{
						"id": 89,
						"name": "Pasture",
						"children": [
							{
								"id": 157,
								"name": "Pasture, General",
								"children": []
							},
							{
								"id": 159,
								"name": "Pasture, Dryland and Seeded",
								"children": []
							},
							{
								"id": 194,
								"name": "Pasture - Irrigated",
								"children": []
							}
						]
					},
					{
						"id": 97,
						"name": "Cotton",
						"children": [
							{
								"id": 156,
								"name": "Cotton - General",
								"children": []
							},
							{
								"id": 160,
								"name": "Cotton, Long Fiber",
								"children": []
							},
							{
								"id": 193,
								"name": "Cotton, Upland",
								"children": []
							},
							{
								"id": 195,
								"name": "Cotton - Other",
								"children": []
							}
						]
					},
					{
						"id": 102,
						"name": "Beans, Dry",
						"children": [
							{
								"id": 155,
								"name": "Beans, Dry - General",
								"children": []
							},
							{
								"id": 161,
								"name": "Beans, Dry - Blackeyed",
								"children": []
							},
							{
								"id": 192,
								"name": "Beans, Dry - Common",
								"children": []
							},
							{
								"id": 196,
								"name": "Beans, Dry - Lima",
								"children": []
							},
							{
								"id": 223,
								"name": "Beans, Dry - Other",
								"children": []
							}
						]
					},
					{
						"id": 108,
						"name": "Oil / Oilseed Crops",
						"children": [
							{
								"id": 149,
								"name": "Oil / Oilseed Crops - General",
								"children": []
							},
							{
								"id": 167,
								"name": "Safflower",
								"children": []
							},
							{
								"id": 186,
								"name": "Oil / Oilseed Crops - Other",
								"children": []
							},
							{
								"id": 202,
								"name": "Cottonseed",
								"children": []
							},
							{
								"id": 218,
								"name": "Jojoba",
								"children": []
							}
						]
					},
					{
						"id": 115,
						"name": "Grains",
						"children": [
							{
								"id": 154,
								"name": "Grains - General",
								"children": []
							},
							{
								"id": 162,
								"name": "Barley",
								"children": []
							},
							{
								"id": 191,
								"name": "Corn, Field",
								"children": []
							},
							{
								"id": 197,
								"name": "Wheat",
								"children": []
							},
							{
								"id": 222,
								"name": "Grains - Other",
								"children": []
							},
							{
								"id": 224,
								"name": "Rice",
								"children": []
							},
							{
								"id": 237,
								"name": "Sorghum, Grain",
								"children": []
							}
						]
					}
				]
			},
			{
				"id": 16,
				"name": "Fruit and Nut Crop",
				"children": [
					{
						"id": 39,
						"name": "Fruit and Nut Crop - General",
						"children": []
					},
					{
						"id": 40,
						"name": "Fruit Crops - General",
						"children": []
					},
					{
						"id": 67,
						"name": "Tree Fruits - General",
						"children": []
					},
					{
						"id": 68,
						"name": "Fruit Crops - Other",
						"children": []
					},
					{
						"id": 92,
						"name": "Berries",
						"children": [
							{
								"id": 151,
								"name": "Berries - General",
								"children": []
							},
							{
								"id": 165,
								"name": "Bushberries",
								"children": []
							},
							{
								"id": 188,
								"name": "Strawberries",
								"children": []
							},
							{
								"id": 200,
								"name": "Berries - Other",
								"children": []
							}
						]
					},
					{
						"id": 93,
						"name": "Grapes",
						"children": [
							{
								"id": 150,
								"name": "Grapes - General",
								"children": []
							},
							{
								"id": 166,
								"name": "Raisin Grapes",
								"children": []
							},
							{
								"id": 187,
								"name": "Table Grapes",
								"children": []
							},
							{
								"id": 201,
								"name": "Wine Grapes",
								"children": []
							},
							{
								"id": 219,
								"name": "Grapes - Other",
								"children": []
							}
						]
					},
					{
						"id": 106,
						"name": "Nut Crops",
						"children": [
							{
								"id": 153,
								"name": "Nut Crops - General",
								"children": []
							},
							{
								"id": 163,
								"name": "Chestnuts",
								"children": []
							},
							{
								"id": 190,
								"name": "Almonds",
								"children": []
							},
							{
								"id": 198,
								"name": "Pecans",
								"children": []
							},
							{
								"id": 221,
								"name": "Pistachios",
								"children": []
							},
							{
								"id": 225,
								"name": "Walnuts",
								"children": []
							},
							{
								"id": 236,
								"name": "Nut Crops - Other",
								"children": []
							}
						]
					},
					{
						"id": 107,
						"name": "Subtropical Fruits",
						"children": [
							{
								"id": 152,
								"name": "Subtropical Fruits - General",
								"children": []
							},
							{
								"id": 164,
								"name": "Citrus - General",
								"children": []
							},
							{
								"id": 189,
								"name": "Grapefruit",
								"children": []
							},
							{
								"id": 199,
								"name": "Lemons",
								"children": []
							},
							{
								"id": 220,
								"name": "Oranges",
								"children": []
							},
							{
								"id": 226,
								"name": "Citrus - Other",
								"children": []
							},
							{
								"id": 235,
								"name": "Avocados",
								"children": []
							},
							{
								"id": 238,
								"name": "Dates",
								"children": []
							},
							{
								"id": 242,
								"name": "Feijoas",
								"children": []
							},
							{
								"id": 243,
								"name": "Figs",
								"children": []
							},
							{
								"id": 246,
								"name": "Kiwis",
								"children": []
							},
							{
								"id": 247,
								"name": "Olives",
								"children": []
							},
							{
								"id": 250,
								"name": "Subtropical Fruits - Other",
								"children": []
							}
						]
					},
					{
						"id": 117,
						"name": "Deciduous Tree Fruits",
						"children": [
							{
								"id": 148,
								"name": "Deciduous Tree Fruits - General",
								"children": []
							},
							{
								"id": 168,
								"name": "Pome Fruits - General",
								"children": []
							},
							{
								"id": 185,
								"name": "Stone Fruits - General",
								"children": []
							},
							{
								"id": 203,
								"name": "Apples",
								"children": []
							},
							{
								"id": 217,
								"name": "Pears",
								"children": []
							},
							{
								"id": 227,
								"name": "Apricots",
								"children": []
							},
							{
								"id": 234,
								"name": "Cherries",
								"children": []
							},
							{
								"id": 239,
								"name": "Nectarines",
								"children": []
							},
							{
								"id": 241,
								"name": "Peaches - General",
								"children": []
							},
							{
								"id": 244,
								"name": "Peaches - Cling",
								"children": []
							},
							{
								"id": 245,
								"name": "Peaches, Freestone",
								"children": []
							},
							{
								"id": 248,
								"name": "Persimmons",
								"children": []
							},
							{
								"id": 249,
								"name": "Plums",
								"children": []
							},
							{
								"id": 251,
								"name": "Prunes",
								"children": []
							},
							{
								"id": 252,
								"name": "Pomegranates",
								"children": []
							},
							{
								"id": 253,
								"name": "Deciduous Tree Fruits - Other",
								"children": []
							}
						]
					}
				]
			},
			{
				"id": 19,
				"name": "Ornamental Crops, Landscape and Turf",
				"children": [
					{
						"id": 38,
						"name": "Aquatic Plants",
						"children": []
					},
					{
						"id": 41,
						"name": "Ornamentals / Landscape / Turf - Other",
						"children": []
					},
					{
						"id": 66,
						"name": "Ornamentals / Landscape / Turf - General",
						"children": []
					},
					{
						"id": 69,
						"name": "Arboreta and Botanical Gardens",
						"children": []
					},
					{
						"id": 91,
						"name": "Bedding / Garden Plants",
						"children": []
					},
					{
						"id": 94,
						"name": "Bulbs, Corms, Rhizomes and Tubers",
						"children": []
					},
					{
						"id": 105,
						"name": "Cut Flowers, Foliage and Greens",
						"children": []
					},
					{
						"id": 109,
						"name": "Ground Covers",
						"children": []
					},
					{
						"id": 116,
						"name": "Landscape - General",
						"children": []
					},
					{
						"id": 118,
						"name": "Potted Plants",
						"children": []
					},
					{
						"id": 121,
						"name": "Trees - Christmas",
						"children": []
					},
					{
						"id": 122,
						"name": "Trees - Ornamental / Landscape / Shade",
						"children": []
					},
					{
						"id": 125,
						"name": "Turf / Sod",
						"children": []
					},
					{
						"id": 126,
						"name": "Woody Shrubs and Ornamental Vines",
						"children": []
					},
					{
						"id": 129,
						"name": "Parks and Urban Green Space",
						"children": []
					}
				]
			},
			{
				"id": 22,
				"name": "Vegetable Crops",
				"children": [
					{
						"id": 29,
						"name": "Artichokes",
						"children": []
					},
					{
						"id": 50,
						"name": "Asparagus",
						"children": []
					},
					{
						"id": 57,
						"name": "Beans, Fresh / Processed",
						"children": []
					},
					{
						"id": 78,
						"name": "Bell Peppers",
						"children": []
					},
					{
						"id": 85,
						"name": "Carrots",
						"children": []
					},
					{
						"id": 98,
						"name": "Celery",
						"children": []
					},
					{
						"id": 101,
						"name": "Vegetable Crops - General",
						"children": []
					},
					{
						"id": 111,
						"name": "Corn, Sweet",
						"children": []
					},
					{
						"id": 114,
						"name": "Mushrooms",
						"children": []
					},
					{
						"id": 119,
						"name": "Okra",
						"children": []
					},
					{
						"id": 120,
						"name": "Peas, Fresh / Processed",
						"children": []
					},
					{
						"id": 123,
						"name": "Potatoes, Irish",
						"children": []
					},
					{
						"id": 124,
						"name": "Potatoes, Sweet",
						"children": []
					},
					{
						"id": 127,
						"name": "Radishes",
						"children": []
					},
					{
						"id": 128,
						"name": "Rhubarb",
						"children": []
					},
					{
						"id": 130,
						"name": "Eggplant",
						"children": []
					},
					{
						"id": 131,
						"name": "Herbs",
						"children": []
					},
					{
						"id": 132,
						"name": "Vegetable Crops - Other",
						"children": []
					},
					{
						"id": 133,
						"name": "Peppers",
						"children": []
					},
					{
						"id": 134,
						"name": "Tomatoes",
						"children": [
							{
								"id": 147,
								"name": "Tomatoes - General",
								"children": []
							},
							{
								"id": 169,
								"name": "Tomatoes - Fresh",
								"children": []
							},
							{
								"id": 184,
								"name": "Tomatoes - Processing",
								"children": []
							}
						]
					},
					{
						"id": 135,
						"name": "Leafy Vegetables",
						"children": [
							{
								"id": 146,
								"name": "Leafy Vegetables - General",
								"children": []
							},
							{
								"id": 170,
								"name": "Lettuce",
								"children": []
							},
							{
								"id": 183,
								"name": "Spinach",
								"children": []
							},
							{
								"id": 204,
								"name": "Leafy Vegetables - Other",
								"children": []
							}
						]
					},
					{
						"id": 136,
						"name": "Alliums",
						"children": [
							{
								"id": 145,
								"name": "Alliums - General",
								"children": []
							},
							{
								"id": 171,
								"name": "Chives and Leeks",
								"children": []
							},
							{
								"id": 182,
								"name": "Garlic",
								"children": []
							},
							{
								"id": 205,
								"name": "Onions",
								"children": []
							},
							{
								"id": 216,
								"name": "Alliums - Other",
								"children": []
							}
						]
					},
					{
						"id": 137,
						"name": "Cucurbits",
						"children": [
							{
								"id": 144,
								"name": "Cucurbits - General",
								"children": []
							},
							{
								"id": 172,
								"name": "Cucumbers",
								"children": []
							},
							{
								"id": 181,
								"name": "Squash - General",
								"children": []
							},
							{
								"id": 206,
								"name": "Squash - Summer",
								"children": []
							},
							{
								"id": 215,
								"name": "Squash - Winter",
								"children": []
							},
							{
								"id": 228,
								"name": "Cucurbits - Other",
								"children": []
							}
						]
					},
					{
						"id": 138,
						"name": "Cole Crops",
						"children": [
							{
								"id": 143,
								"name": "Cole Crops - General",
								"children": []
							},
							{
								"id": 173,
								"name": "Broccoli",
								"children": []
							},
							{
								"id": 180,
								"name": "Brussels Sprouts",
								"children": []
							},
							{
								"id": 207,
								"name": "Cabbage",
								"children": []
							},
							{
								"id": 214,
								"name": "Cauliflower",
								"children": []
							},
							{
								"id": 229,
								"name": "Cole Crops - Other",
								"children": []
							}
						]
					},
					{
						"id": 139,
						"name": "Melons",
						"children": [
							{
								"id": 140,
								"name": "Melons - General",
								"children": []
							},
							{
								"id": 176,
								"name": "Cantaloupes",
								"children": []
							},
							{
								"id": 177,
								"name": "Casaba Melons",
								"children": []
							},
							{
								"id": 210,
								"name": "Crenshaw Melons",
								"children": []
							},
							{
								"id": 211,
								"name": "Honeydew Melons",
								"children": []
							},
							{
								"id": 232,
								"name": "Watermelons",
								"children": []
							},
							{
								"id": 233,
								"name": "Persian Melons",
								"children": []
							},
							{
								"id": 240,
								"name": "Melons - Other",
								"children": []
							}
						]
					}
				]
			}
		]
	},
	{
		"id": 5,
		"name": "Natural Resources and Commodities",
		"children": [
			{
				"id": 6,
				"name": "Natural Resc and Commodities - General",
				"children": []
			},
			{
				"id": 11,
				"name": "Natural Resc and Commodities - Other",
				"children": []
			},
			{
				"id": 12,
				"name": "Water",
				"children": []
			},
			{
				"id": 17,
				"name": "Recreational Resources",
				"children": [
					{
						"id": 32,
						"name": "Recreational Resources - General",
						"children": []
					},
					{
						"id": 47,
						"name": "Wilderness",
						"children": []
					},
					{
						"id": 60,
						"name": "Parks / Campgrounds / Urban Greenspace",
						"children": []
					},
					{
						"id": 75,
						"name": "Recreational Resources - Other",
						"children": []
					}
				]
			},
			{
				"id": 18,
				"name": "Soil and Land",
				"children": [
					{
						"id": 31,
						"name": "Soil and Land - General",
						"children": []
					},
					{
						"id": 48,
						"name": "Soil",
						"children": []
					},
					{
						"id": 59,
						"name": "Land",
						"children": []
					},
					{
						"id": 76,
						"name": "Soil and Land - Other",
						"children": []
					}
				]
			},
			{
				"id": 21,
				"name": "Forest / Range / Wildlife Commodities / Habitats",
				"children": [
					{
						"id": 30,
						"name": "Forest / Range / Wildlife Commodities / Habitats - General",
						"children": []
					},
					{
						"id": 49,
						"name": "Range",
						"children": []
					},
					{
						"id": 58,
						"name": "Forest / Range / Wildlife Commodities / Habitats - Other",
						"children": []
					},
					{
						"id": 77,
						"name": "Wildlife",
						"children": [
							{
								"id": 142,
								"name": "Wildlife - General",
								"children": []
							},
							{
								"id": 174,
								"name": "Wild Birds - Game",
								"children": []
							},
							{
								"id": 179,
								"name": "Wild Birds - Non-Game",
								"children": []
							},
							{
								"id": 208,
								"name": "Wild Animals - Game",
								"children": []
							},
							{
								"id": 213,
								"name": "Wild Animals - Non-Game",
								"children": []
							},
							{
								"id": 230,
								"name": "Wildlife - Other",
								"children": []
							}
						]
					},
					{
						"id": 86,
						"name": "Forest Trees and Products",
						"children": [
							{
								"id": 141,
								"name": "Forest Trees and Products - General",
								"children": []
							},
							{
								"id": 175,
								"name": "Hardwoods",
								"children": []
							},
							{
								"id": 178,
								"name": "Softwoods / Conifers",
								"children": []
							},
							{
								"id": 209,
								"name": "Primary Wood Products",
								"children": []
							},
							{
								"id": 212,
								"name": "Secondary Wood Products",
								"children": []
							},
							{
								"id": 231,
								"name": "Forest Trees and Products - Other",
								"children": []
							}
						]
					}
				]
			},
			{
				"id": 23,
				"name": "Watersheds and River Basins",
				"children": [
					{
						"id": 28,
						"name": "Watersheds and River Basins - General",
						"children": []
					},
					{
						"id": 51,
						"name": "River Basins",
						"children": []
					},
					{
						"id": 56,
						"name": "Watersheds",
						"children": []
					},
					{
						"id": 79,
						"name": "Wetland and Riparian Systems",
						"children": []
					},
					{
						"id": 84,
						"name": "Watersheds and River Basins - Other",
						"children": []
					}
				]
			},
			{
				"id": 24,
				"name": "Atmosphere",
				"children": [
					{
						"id": 27,
						"name": "Atmosphere - General",
						"children": []
					},
					{
						"id": 52,
						"name": "Air",
						"children": []
					},
					{
						"id": 55,
						"name": "Weather",
						"children": []
					},
					{
						"id": 80,
						"name": "Climate",
						"children": []
					},
					{
						"id": 83,
						"name": "Atmosphere - Other",
						"children": []
					}
				]
			},
			{
				"id": 25,
				"name": "Fishery and Marine Commodities and Habitats",
				"children": [
					{
						"id": 26,
						"name": "Fishery / Marine Commodities / Habitats - General",
						"children": []
					},
					{
						"id": 53,
						"name": "Finfish - General",
						"children": []
					},
					{
						"id": 54,
						"name": "Finfish - Freshwater",
						"children": []
					},
					{
						"id": 81,
						"name": "Finfish - Marine",
						"children": []
					},
					{
						"id": 82,
						"name": "Marine Mammals",
						"children": []
					},
					{
						"id": 99,
						"name": "Shellfish - General",
						"children": []
					},
					{
						"id": 100,
						"name": "Shellfish - Freshwater",
						"children": []
					},
					{
						"id": 112,
						"name": "Shellfish - Marine",
						"children": []
					},
					{
						"id": 113,
						"name": "Fishery / Marine Commodities / Habitats - Other",
						"children": []
					}
				]
			}
		]
	}
].map(Commodity.fromJson);
