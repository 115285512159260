import { Observable } from 'rxjs';
import { Result } from '../types';

export class FederalFiscalYear {
  public readonly label: string;
  constructor(
    public readonly id: number,
    public readonly start: Date,
    public readonly end: Date,
    public readonly submissionsDueBy: Date
  ){
    this.label = `${id-1}-${id}`;
  }

  public static fromJson(o: any): FederalFiscalYear {
      return new FederalFiscalYear(
          o.id,
          o.start,
          o.end,
          o.submissionsDueBy);
  }
}

export class SubmissionOpportunity{
  constructor(
    public readonly federalFiscalYear: FederalFiscalYear,
    public readonly submittedAt: Date | null
  ){}

  public static fromJson(o: any): SubmissionOpportunity {
      return new SubmissionOpportunity(
          FederalFiscalYear.fromJson(o.federalFiscalYear),
          o.submittedAt);
  }
}

export enum SubmissionType
{
    ThemesProjectsActivitiesANRMMP = "ThemesProjectsActivitiesANRMMP",
    ThemesProjectsActivitiesCampusMMP = "ThemesProjectsActivitiesCampusMMP",
    CountyFTE = "CountyFTE",
    ConditionChangeFTE = "ConditionChangeFTE"
}

export abstract class AcademicReportSubmissionService {
    public abstract submit(type:SubmissionType, ffyId: number): Promise<Result<null,string>>;
    public abstract getSubmissionOpportunity(type:SubmissionType): Observable<SubmissionOpportunity>;
}