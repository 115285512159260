import { Component, Input, Output, EventEmitter, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { NgStyle } from '@angular/common';
import {
	ActivityCategory,
   	ActivityTypeId,
   	ActivityType,
   	ActivityTypeCollection,
   	ActivitySubType,
	ActivitySubTypeId,
	CircularArray
} from '../../domain'; 
 import { ConsoleService } from '../console.service';
import {
	LEFT_ARROW,
	RIGHT_ARROW,
	UP_ARROW,
	DOWN_ARROW 
} from '@angular/cdk/keycodes';
import { TooltipService } from '../../widgets/tooltip.service';

@Component({
	selector: 'activity-type-menu',
	templateUrl: './activity-type-menu.component.html',
	styleUrls: ['./activity-type-menu.component.scss'],
	host: {
        '(keydown)': '_handleKeydown($event)',
    }
})
export class ActivityTypeMenuComponent {
	@Output() subTypeClick = new EventEmitter<ActivitySubType>();
	@Input() activityTypes: ActivityTypeCollection;
	@Input("activityCategories") categories: CircularArray<ActivityCategory>;
	@Input() startingActivityCategoryId: number = 0;
	@ViewChildren('ActivityLink') activityLinks: QueryList<any>;
	
	private _focusedLinkIndex: number;

	// used to show information about the activity type and sub types when mouse hovered over link
	subTypeHoverActive: boolean = false;
	activityTypeInfo: ActivityTypeId;
	activitySubTypeInfo: ActivitySubType;
	ActivitySubTypeId = ActivitySubTypeId;

	constructor(
		private console: ConsoleService,
		private _elementRef: ElementRef,
		public tooltipSvc: TooltipService
	) {
		this._focusedLinkIndex = 0;
	}

	ngOnInit(){
		this.console.log('menu component:',this);
		this.categories.changes.subscribe(category => {
			setTimeout(() => { 
				if (!this.activityLinks.first){
					return;
				}
				this.activityLinks.first.nativeElement.focus();
				this._focusedLinkIndex = 0;
			}, 0);
		});
		this.categories.tryMoveToIndex(this.startingActivityCategoryId);
	}

	definitions = new Map<ActivityTypeId, string>([
		[ActivityTypeId.Extension, 'An extension activity is directly related to your own program clientele'],
		[ActivityTypeId.UniversityAndPublicService, 'Per the E-Book, Services to the University, ANR, and CE are a critical part of an Academic appointee\'s responsibilities. Contributions to community and beyond are also expectations of the academic appointment, representing UC and the academic discipline.'],
		[ActivityTypeId.ProfessionalCompetence, "Per the E-Book, Competence in the subject matter appropriate to the discipline is fundamental to individual success, and to the success and progress of UC ANR."],
		[ActivityTypeId.AppliedResearchAndCreative, 'Useful for Federal and organizational reporting.'],
		[ActivityTypeId.Outreach, 'All Reasonable Effort is defined as the utilization of three of the four federally approved effort methods to ensure that eligible individuals from protected/underrepresented groups are aware of, invited to participate and benefit from appropriate ANR-CE programs.'],
		[ActivityTypeId.ExtensionAndOrOutreach, 'These activity types will be included in both a) Civil Rights Compliance analysis and b) Extension Activities table section of the dossier export.'],
		[ActivityTypeId.Other, 'Per the E-Book, If you are including educational presentations related to your academic program that you did not directly present yourself but that were delivered by your staff or others, please include these as a separate table from those you delivered personally. Also include your role in these presentations that your staff or others delivered.']
	]);

	subTypeHover(subType: ActivitySubType){
		//this.console.log("Sub Type Hovered: ", subType);
		this.activityTypeInfo = this.activityTypes.getTypeIdBySubTypeId(subType.id);
		this.activitySubTypeInfo = subType;
		this.subTypeHoverActive = true;
	}

	tabFocused(e){
		this.console.log('tab focused:',e);
	}

	subTypeHoverExit(){
		//this.console.log("Hover Exit");
		this.subTypeHoverActive = false;
		setTimeout(()=>{ 
			if(!this.subTypeHoverActive){
				this.activityTypeInfo = null;
				this.activitySubTypeInfo = null;
			}
		}, 3000);
	}

	focusedSubType(subType: ActivitySubType, link: any){
		this._focusedLinkIndex = 
			this.activityLinks.toArray().map(l => l.nativeElement).indexOf(link);
	}

	get layoutColumnCount(): number {
		// 10 items seems to work well with the current layout. If the styles / layout changes, this may need to be changed too.
		return this.categories.currentItem.activityTypes.getSubTypeCount() <= 10
			? 1
			: 2;
	}

	_handleKeydown(event: KeyboardEvent): void {
		switch(event.keyCode){
			case LEFT_ARROW:
				this.categories.movePrev();
				event.stopPropagation();
				break;
			case RIGHT_ARROW:
				this.categories.moveNext();
				event.stopPropagation();
				break;
			case UP_ARROW:
				this._switchSubType(-1);
				event.stopPropagation();
				break;
			case DOWN_ARROW:
				this._switchSubType(1);
				event.stopPropagation();
				break;
		}
	}

	_switchSubType(offset: number) {
		let newIndex = this._focusedLinkIndex + offset;
		if(newIndex > this.activityLinks.length - 1){
			newIndex = 0;
		}
		if(newIndex < 0){
			newIndex = this.activityLinks.length - 1;
		}
		this._focusedLinkIndex = newIndex;
		this.activityLinks.toArray()[this._focusedLinkIndex].nativeElement.focus();
	}
}