export abstract class Result<TSuccess,TFailure> {
    constructor(public readonly success: boolean){ }
	public abstract match<T>(
		success: (TSuccess) => T,
		failure: (TFailure) => T) : T;
	public abstract matchDo(
		success: (TSuccess) => void,
		failure: (TFailure) => void) : void;

	public static create<TSuccess, TFailure>(ctor: (
			s: (s: TSuccess) => Result<TSuccess, TFailure>,
			f: (f: TFailure) => Result<TSuccess, TFailure>
		) => Result<TSuccess, TFailure>): Result<TSuccess, TFailure> 
	{
		return ctor(this.success, this.failure);
	}

	public static success<TSuccess,TFailure>(value: TSuccess): Result<TSuccess,TFailure>
	{
		return new Success<TSuccess,TFailure>(value);
	}

	public static failure<TSuccess,TFailure>(value: TFailure): Result<TSuccess,TFailure>
	{
		return new Failure<TSuccess,TFailure>(value);
	}

	public static fromJson<TSuccess,TFailure>(
		o: any,
		successFromJson: (any) => TSuccess,
		failureFromJson: (any) => TFailure) : Result<TSuccess,TFailure>
	{
		return !!o.success
			? Result.success<TSuccess,TFailure>(successFromJson(o.value))
			: Result.failure<TSuccess,TFailure>(failureFromJson(o.value));
	}

	public static fromJsonFSharp<TSuccess,TFailure>(
		o: any,
		successFromJson: (any) => TSuccess,
		failureFromJson: (any) => TFailure) : Result<TSuccess,TFailure>
	{
		switch(o.case){
			case "Success": return Result.success<TSuccess,TFailure>(successFromJson(o.fields[0]));
			case "Failure": return Result.failure<TSuccess,TFailure>(failureFromJson(o.fields[0]));
			default:
			throw ("Unable to deserialize Result from json.  Invalid case: " + o.case);
		}
	}

	public abstract map<TNewSuccess>(successFn: (TSuccess) => TNewSuccess): Result<TNewSuccess,TFailure>;
	public abstract successOrDefault(defaultSuccessFn: () => TSuccess): TSuccess;
}

export class Success<TSuccess,TFailure> extends Result<TSuccess,TFailure> {
	constructor(public readonly value: TSuccess) {
		super(true);
	}

	public match<T>(
		success: (TSuccess) => T,
		failure: (TFailure) => T
	) : T
	{
		return success(this.value);
	}

	public matchDo(
		success: (TSuccess) => void,
		failure: (TFailure) => void
	) 
	{
		success(this.value);
	}

	public map<TNewSuccess>(successFn: (TSuccess) => TNewSuccess): Result<TNewSuccess,TFailure>
	{
		return new Success<TNewSuccess,TFailure>(successFn(this.value));
	}

	public successOrDefault(defaultSuccessFn: () => TSuccess): TSuccess
	{
		return this.value;
	}
}

export class Failure<TSuccess,TFailure> extends Result<TSuccess,TFailure> {
	constructor(public readonly value: TFailure) {
		super(false);
	}

	public match<T>(
		success: (TSuccess) => T,
		failure: (TFailure) => T
	) : T
	{
		return failure(this.value);
	}

	public matchDo(
		success: (TSuccess) => void,
		failure: (TFailure) => void
	) 
	{
		failure(this.value);
	}

	public map<TNewSuccess>(successFn: (TSuccess) => TNewSuccess): Result<TNewSuccess,TFailure>
	{
		return new Failure<TNewSuccess,TFailure>(this.value);
	}

	public successOrDefault(defaultSuccessFn: () => TSuccess): TSuccess
	{
		return defaultSuccessFn();
	}
}
