import { Observable } from 'rxjs';
import {UserWithEmplId} from '../types';

export abstract class UserService {
	public abstract config(): Promise<null>;
	public abstract getAvatarUrl(userId: number): string;
	public abstract getAvatarUrlAsync(userId: number) : Observable<string>;
	public abstract getInitialsAvatarUrl(userId: number): Observable<string>;
	public abstract search(nameFragment: string, max: number): Promise<UserWithEmplId[]>;
	public abstract importFromDirectoryIfNecessary(userId: number): void;
	public abstract spoofUser(userId: number):  Promise<string> ;

}
