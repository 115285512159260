import {
	Directive,
   	Input,
   	HostBinding,
   	HostListener,
   	ElementRef,
   	AfterContentChecked 
} from '@angular/core';

@Directive({
	selector: '[errorSrc]'
})
export class ErrorSrcDirective {
	constructor(private element: ElementRef){ }
	@Input() errorSrc: string = '';
	@HostListener('error')
	onError(){
		if(this.element.nativeElement.src != this.errorSrc){
			this.element.nativeElement.src = this.errorSrc;
		}
	}
}
