import * as kh from './keywordHighlighter';
import { Component, Input } from '@angular/core';

@Component({
	selector: 'keyword-highlight',
	templateUrl: './keyword-highlight.component.html',
	styleUrls: ['./keyword-highlight.component.scss']
})
export class KeywordHighlightComponent {
	@Input() keyword: string = '';
	@Input() text: string = '';
	segments(){
		let text = this.text
		.replace(/"/g, "\"")
		.replace(/'/g, "'");
		return kh.getKeywordMatchSegments(text, this.keyword);
	}
}
