import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FTEService, CountyAssignment, StaticText } from '../../../../domain';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GoogleAnalyticsService } from '../../../../main-core/google-analytics.service';
import { TooltipService } from '../../../../widgets/tooltip.service';

@Component({
	templateUrl: './county-fte-modal.component.html',
	styleUrls: ['./county-fte-modal.component.scss']
})
export class CountyFteModalComponent implements OnInit {
	public StaticText: any;
	closing = false;
	assignments: CountyAssignment[] = [];
	form: FormGroup;

	constructor(
		public dialogRef: MatDialogRef<CountyFteModalComponent>,
		fb: FormBuilder,
		private svc: FTEService,
		private snackBar: MatSnackBar,
		private gaService: GoogleAnalyticsService,
		public tooltipSvc: TooltipService,
		@Inject(MAT_DIALOG_DATA) data: any)
	{
		this.StaticText = tooltipSvc.StaticText;
		let fg = {};
		let assignments = <CountyAssignment[]>data.countyAssignments;
		if(assignments){
			this.assignments = assignments;
			assignments.forEach(a => {
				fg[a.county.id] = Math.round(a.fte.value * 100); /* From decimal to percentage.  Be sure to fix any rounding errors. */				
			});
		}
		this.form = fb.group(fg);
	}

	ngOnInit() {
	}

	cancel(){
		this.dialogRef.close();
	}

	get sum(): number {
		return this.assignments.reduce((sum,a) => sum + this.form.value[a.county.id], 0);
	}

	get validSum(): boolean {
		return this.sum == 100;
	}

	save(){
		let dto = new Map<number, number>();
		this.assignments.forEach(a => {
			dto.set(
				a.county.id,
				(+this.form.value[a.county.id]) / 100 // From percentage to decimal.
			);
		});
		this.closing = true;
		this.svc.saveCountyFTE(dto).then(result =>
			result.matchDo(
				_ => {
					this.snackBar.open('County FTE updated', null, {duration: 3000});
					this.gaService.eventEmitter("County FTE Updated", "County FTE", "Update");
					this.dialogRef.close('success');
				},
				errorMsg => {
					this.snackBar.open('Error: ' + errorMsg, null, {duration: 3000});
					this.closing = false;
				}));
	}
}
