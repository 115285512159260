import { ActivitySpecification } from ".";
import { TagDto } from "..";

export class SavedSearch {
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly spec: ActivitySpecification
    ){ }

    public static fromJson(o: any): SavedSearch {
        return new SavedSearch(
            o.id,
            o.name,
            ActivitySpecification.fromJson(o.spec)
        );
    }
}

export class SavedSearchDto
{
    constructor(
        public readonly name: string,
        public readonly spec: ActivitySpecification
    ) { }
}