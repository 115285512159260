<div mat-dialog-title>
  Review Requirements
  <button matDialogClose class="btn btn-secondary pull-right">
    &times;
  </button>
</div>

<mat-dialog-content style="min-height: 300px;">
    <p>
        See below for how <strong>candidate documents</strong> will be configured in wfa.
        Consider this the definitive reference.
    </p>
    <p class="text-sm">
        Please note: Project Board table requirements are not considered here.
    </p>
    <div class="row">
      <div class="col">
        <form [formGroup]="form" class="form-inline mb-3">
            <div class="form-group">
                <label class="mr-3">Position: </label>
                <select formControlName="position" class="form-control form-control-original">
                    <ng-container *ngFor="let title of titles">
                        <ng-container *ngFor="let rank of title.ranks">
                            <option *ngFor="let step of rank.steps" [value]="title.id + '/' + rank.id + '/' + step.id">
                            {{title.name}} / {{rank.name}} / {{step.id | romanNumeral}} {{aboveScale(step)}}
                            </option>
                        </ng-container>
                    </ng-container>
                </select>
            </div>
        </form>
      </div>
      <div class="col text-center">
          <label>
            <input type="checkbox" [(ngModel)]="showSimpleRequirements" class="mr-1">
            Simplify the requirements
          </label>
          <label class="ml-3">
            <input type="checkbox" [(ngModel)]="showDocumentDescriptions" class="mr-1">
            Show document descriptions
          </label>
      </div>
    </div>


  <div [ngSwitch]="!!requirements.value">
    <div *ngSwitchCase="false">
      No title selected
    </div>
    <div *ngSwitchCase="true">
        <table class="table table-sm">
            <tr>
                <th>&nbsp;</th>
                <ng-container *ngFor="let a of requirements.value.actions" class="text-center">
                    <ng-container [ngSwitch]="a.allowed">
                        <th *ngSwitchCase="true">
                            {{a.label}}
                        </th>
                        <th *ngSwitchCase="false" title="This action is not possible for this position" class="table-active">
                            {{a.label}}
                        </th>
                    </ng-container>
                </ng-container>
            </tr>
            <tr *ngFor="let dt of requirements.value.documentTypes">
                <td>
                    {{dt.name}}
                    <div *ngIf="showDocumentDescriptions"
                        [innerHtml]="dt.description"
                        class="text-sm"
                        style="width:30em; overflow-x: auto;"></div>
                </td>
                <ng-container *ngFor="let a of requirements.value.actions">
                    <ng-container [ngSwitch]="a.allowed">
                        <ng-container *ngSwitchCase="true">
                            <ng-container [ngSwitch]="!!a.requirements[dt.id]">
                                <td *ngSwitchCase="false" class="text-center table-danger">
                                    No
                                </td>
                                <td *ngSwitchCase="true" class="text-center {{a.requirements[dt.id].required ? 'table-success' : 'table-danger'}}">
                                    <ng-container [ngSwitch]="showSimpleRequirements">
                                        <div *ngSwitchCase="true"
                                            [title]="a.requirements[dt.id].requirementLabel">
                                                {{a.requirements[dt.id].required ? 'Yes' : 'No'}}
                                        </div>
                                        <div *ngSwitchCase="false"
                                            [title]="a.requirements[dt.id].required ? 'Required' : 'Optional'"
                                            class="text-sm">
                                                {{a.requirements[dt.id].requirementLabel}}
                                        </div>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </ng-container>
                        <td *ngSwitchCase="false" class="text-muted text-center table-active" title="N/A">
                            -
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
        </table>
    </div>
  </div>
  
</mat-dialog-content>