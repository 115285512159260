<div class="alert alert-info" *ngIf="hasInstructions()">
	<span [innerHTML]="getInstructions()"></span>
</div>

<div>
	<div *ngIf="!groups">
		Loading...
	</div>
	<empty-state *ngIf="!!groups && groups.length == 0"
		imgSrc="/assets/empty/clientele-group.png"
		title="You haven't yet added any Clientele Groups"
		description="Why not add one now?">
			<button class="btn btn-primary btn-sm" (click)="newGroup()">
				+ Add Clientele Group
			</button>
	</empty-state>
	<div *ngIf="!!groups && groups.length > 0" class="row mb-3">
		<div class="col-md-6">
			<span class="h5" style="margin-bottom: 0;">Clientele Groups ({{groups.length}})</span>
		</div>
	
		<div class="col-md-6 text-right">
			<button class="btn btn-primary btn-sm" (click)="newGroup()">
				+ Add Clientele Group
			</button>
		</div>
	</div>
</div>


<div *ngFor="let group of groups" class="card clientele-group-list-item mb-3">
	<div class="card-block">
		<div class="row">
			<div class="col-md-8">
				<h5>{{group.title}}
					<span class="breyta-label"> ({{group.federalFiscalYearId-1}}-{{group.federalFiscalYearId}})</span>
				</h5> 
				<div class="p" [ngSwitch]="group.totalActivityCount">
					<div *ngSwitchCase="0" class="text-info">
						<i class="fa fa-fw fa-info-circle"></i>
						You haven&apos;t yet entered any contacts for this group
					</div>
					<div *ngSwitchDefault>
						<div [ngSwitch]="group.type">
							<div *ngSwitchCase="ClienteleGroupType.Organization" class="text-info">
								<i class="fa fa-fw fa-info-circle"></i>
								Organization Clientele Groups will be included in the analysis by Affirmative Action
							</div>
							<div *ngSwitchCase="ClienteleGroupType.Individual" class="text-info">
								<i class="fa fa-fw fa-info-circle"></i>
								Individual Clientele Groups will be included in the analysis by Affirmative Action
							</div>
							<div *ngSwitchCase="ClienteleGroupType.Family" class="text-info">
								<i class="fa fa-fw fa-info-circle"></i>
								Family Clientele Groups will be included in the analysis by Affirmative Action
							</div>														
							<!-- <div *ngSwitchDefault>
								<div [ngSwitch]="group.civilRightsCompliant">
									<span *ngSwitchCase="true" class="text-success">
										<i class="fa fa-fw fa-check"></i>
										Civil Rights Compliant
									</span>
									<span *ngSwitchCase="false" class="text-danger">
										<i class="fa fa-fw fa-warning"></i>
										Not Civil Rights Compliant
									</span>
								</div>
							</div> -->
						</div>
					</div>
				</div>

				<div class="p" [hidden]="!group.description">
					<div class="breyta-label">Description / Other Source</div>
					<div>{{group.description}}</div>
				</div>
			</div>
		
			<div class="col-md-4 text-right">
				<button class="btn btn-sm btn-link" (click)="$event.stopPropagation(); editGroup(group)">
					<i class="fa fa-fw fa-pencil"></i>
					Edit
				</button>
			</div>
		</div>

		<div class="row">
			<div class="col-md-3">
				<div class="p">
					<div class="breyta-label">Type</div>
					<div>{{ClienteleGroupType[group.type]}}</div>
				</div>
			</div>

			<div class="col-md-3">
				<div class="p">
					<div class="breyta-label">Source</div>
					<div>{{group.source.name}}</div>
				</div>
			</div>

			<div class="col-md-3">
				<div class="p">
					<div class="breyta-label" [ngSwitch]="group.countySelection.Counties.length">
						<span *ngSwitchCase="1">
							County
						</span>
						<span *ngSwitchDefault>
							Counties
						</span>
					</div>
					<county-selection
						[countySelection]="group.countySelection">
					</county-selection>
				</div>
			</div>
		
			<div class="col-md-3 text-muted text-sm">
				<div>
					{{group.totalActivityCount}} {{group.totalActivityCount !== 1 ? 'Activities' : 'Activity'}}
				</div>
				<div [hidden]="!group.lastActivity">
					Last Activity: {{group.lastActivity | date:'EEE MMM d, y'}}
				</div>
			</div>
		</div>
	</div>

	<a class="card-footer text-center"
	*ngIf="group.type != ClienteleGroupType.Organization"
	[ngSwitch]="notesVisibleForGroup(group)"
	(click)="toggleNotesVisibilityForGroup(group)"
	style="cursor:pointer;">
		<span *ngSwitchCase="true">
			Hide Compliance Notes
			<i class="fa fa-fw fa-caret-up"></i>
		</span>
		<span *ngSwitchCase="false">
			Show Compliance Notes
			<i class="fa fa-fw fa-caret-down"></i>
		</span>
	</a>

	<div [hidden]="!notesVisibleForGroup(group)" style="border-top: 1px solid #ddd;">
		<div class="alert alert-info m-3">
			<div class="media">
				<i class="fa fa-info-circle fa-fw mr-3 mt-2"></i>
				<div class="media-body">
					Please write some notes regarding your compliance for the Affirmative Action Analyst to consider.
					<br>
					You may want to discuss your clientele group parity or A.R.E.
				</div>
			</div>
		</div>
			
		<div class="row">
			<div *ngFor="let ffyId of noteYearsOfInterest" class="col-md-6">
				<div class="p-3">
					<h5>{{ffyId-1}}-{{ffyId}}</h5>
					<inline-editor 
						[edited]="group.complianceNotesByFederalFiscalYearId[ffyId] != complianceNotes[group.id + '_' + ffyId]" 
						[saveFn]="saveComplianceNotes(group, ffyId, complianceNotes[group.id + '_' + ffyId])" 
						(transitionToEdit)="notesInput.focus()" 
						(cancel)="complianceNotes[group.id + '_' + ffyId] = group.complianceNotesByFederalFiscalYearId[ffyId]"
						#notesEditor>
							<div readonly style="padding-left:1em;">
								<div [innerHtml]="complianceNotes[group.id + '_' + ffyId]"></div>
								<em [hidden]="!!group.complianceNotesByFederalFiscalYearId[ffyId]">
									Click or Tap to edit
								</em>
							</div>
							<div editor *ngIf="notesEditor.state === 'editing'">
								<textboxio 
									[(content)]="complianceNotes[group.id + '_' + ffyId]" 
									#notesInput></textboxio>
							</div>
					</inline-editor>
				</div>
			</div>
		</div>
	</div>

	<!--PAST-->

	<ng-container *ngFor="let historicalGroup of historicalGroupList">
		<a class="card-footer text-center" 
		*ngIf="group.id == historicalGroup.id && group.federalFiscalYearId > historicalGroup.federalFiscalYearId"
		[ngSwitch]="highlightedHistoricalGroup != null && historicalGroupDetail != null && highlightedHistoricalGroup.id == historicalGroupDetail.id && highlightedHistoricalGroup.federalFiscalYearId == historicalGroup.federalFiscalYearId && highlightedHistoricalGroup.id == group.id"
		(click)="showHistoricalData(historicalGroup, group)"
		style="cursor:pointer; background: #e0e0e0;">
			<span *ngSwitchCase="true">
				Hide Historical Stats ({{historicalGroup.federalFiscalYearId-1}}-{{historicalGroup.federalFiscalYearId}})
				<i class="fa fa-fw fa-caret-up"></i>
			</span>		
			<span *ngSwitchCase="false">
				Show Historical Stats ({{historicalGroup.federalFiscalYearId-1}}-{{historicalGroup.federalFiscalYearId}})
				<i class="fa fa-fw fa-caret-down"></i>
			</span>
		</a>

		<ng-container *ngIf="group.id == historicalGroup.id && group.federalFiscalYearId > historicalGroup.federalFiscalYearId">
			<table class="table table-bordered table-flush"
			[hidden]="highlightedHistoricalGroup == null || historicalGroupDetail == null || highlightedHistoricalGroup.id != historicalGroup.id || highlightedHistoricalGroup.federalFiscalYearId != historicalGroup.federalFiscalYearId"
			style="border-top: 1px solid #ddd; background: #f0f0f0;">
				<thead>
					<tr *ngIf="group.type != ClienteleGroupType.Organization">
						<th>Demographic</th>
						<th class="text-center">Potential/Baseline</th>
						<th class="text-center">Actual ({{calculateActualSum(group.id, true)}})</th>
						<th class="text-center">Parity Minimum</th>
						<th class="text-center" [matTooltip]="definitions.parity">
							In&nbsp;Parity?
							<i class="fa fa-fw fa-info-circle"></i>
						</th>
						<th class="text-center" [matTooltip]="definitions.ARE">
							A.R.E.?
							<i class="fa fa-fw fa-info-circle"></i>
						</th>
					</tr>
					<tr *ngIf="group.type === ClienteleGroupType.Organization">
						<th>Demographic</th>
						<th class="text-center"># Potential Organizations</th>
						<th class="text-center">Actual Attendees</th>
					</tr>					
				</thead>
				<ng-container *ngIf="historicalGroupDetail != null">
					<tbody *ngFor="let t of demographicInfo.applicableTypesByClienteleGroupTypeId.get(historicalGroupDetail.type)">
						<ng-container *ngIf="group.type != ClienteleGroupType.Organization">
							<tr>
								<th colspan="7">{{t.label}}</th>
							</tr>
							<tr *ngFor="let d of t.demographics">
								<td>
									{{d.label}}
								</td>
								<td class="text-center">
									{{historicalGroupDetail.demographics.get(d.id).potential.contactsRatio | percent:'1.0-2'}}
									<span class="text-sm text-muted">
										({{historicalGroupDetail.demographics.get(d.id).potential.contacts}})
									</span>
								</td>
								<td class="text-center">
									{{historicalGroupDetail.demographics.get(d.id).actual.contactsRatio | percent:'1.0-2'}}
									<span class="text-sm text-muted">
										({{historicalGroupDetail.demographics.get(d.id).actual.contacts}})
									</span>
								</td>
								<ng-container [ngSwitch]="!!historicalGroupDetail.demographics.get(d.id).compliance.value">
									<ng-container *ngSwitchCase="false">
										<td class="text-center text-muted text-sm">
											N/A
										</td>
										<td class="text-center text-muted text-sm">
											N/A
										</td>
										<td class="text-center text-muted text-sm">
											N/A
										</td>
									</ng-container>
									<ng-container *ngSwitchCase="true">
										<td class="text-center">
											{{historicalGroupDetail.demographics.get(d.id).parityRange.low.value | percent:'1.0-2'}}
										</td>
										<td class="text-center">
											{{historicalGroupDetail.demographics.get(d.id).compliance.value.consideredInParity ? 'Yes' : 'No'}}
										</td>
										<td class="text-center">
											{{historicalGroupDetail.demographics.get(d.id).compliance.value.demonstratesAllReasonableEfforts ? 'Yes' : 'No'}}
										</td>
									</ng-container>
								</ng-container>
							</tr>

						</ng-container>
						<ng-container *ngIf="group.type === ClienteleGroupType.Organization">
							<tr *ngFor="let d of t.demographics">									
								<td>
									{{d.label}}
								</td>					
								<td class="text-center">
									{{historicalGroupDetail.demographics.get(d.id).potential.contacts}}
								</td>
								<td class="text-center">
									{{historicalGroupDetail.demographics.get(d.id).actual.contacts}}
								</td>
							</tr>
						</ng-container>
					</tbody>	
				</ng-container>			
			</table>
		</ng-container>
	</ng-container>


	<!--CURRENT--> 
	<!--Based on the ReportingYear table-->
	
	<a class="card-footer text-center"
	[ngSwitch]="highlightedGroup == group"
	(click)="highlight(group)"
	style="cursor:pointer;">
		<span *ngSwitchCase="true">
			Hide Detailed Stats ({{group.federalFiscalYearId-1}}-{{group.federalFiscalYearId}})
			<i class="fa fa-fw fa-caret-up"></i>
		</span>
		<span *ngSwitchCase="false">
			Show Detailed Stats ({{group.federalFiscalYearId-1}}-{{group.federalFiscalYearId}})
			<i class="fa fa-fw fa-caret-down"></i>
		</span>
	</a>

	<table class="table table-bordered table-flush"
	[hidden]="highlightedGroup != group"
	style="border-top: 1px solid #ddd;">
		<thead>
			<tr *ngIf="group.type != ClienteleGroupType.Organization">
				<th>Demographic</th>
				<th class="text-center">Potential/Baseline</th>
				<th class="text-center">Actual ({{calculateActualSum(group.id)}})</th>
				<th class="text-center">Parity Minimum</th>
				<th class="text-center" [matTooltip]="definitions.parity">
					In&nbsp;Parity?
					<i class="fa fa-fw fa-info-circle"></i>
				</th>
				<th class="text-center" [matTooltip]="definitions.ARE">
					A.R.E.?
					<i class="fa fa-fw fa-info-circle"></i>
				</th>
			</tr>
			<tr *ngIf="group.type === ClienteleGroupType.Organization">
				<th>Demographic</th>
				<th class="text-center"># Potential Organizations</th>
				<th class="text-center">Actual Attendees</th>
			</tr>			
		</thead>
		<tbody *ngFor="let t of demographicInfo.applicableTypesByClienteleGroupTypeId.get(group.type)">
			<ng-container *ngIf="group.type != ClienteleGroupType.Organization">
				<tr>
					<th colspan="7">{{t.label}}</th>
				</tr>
				<tr *ngFor="let d of t.demographics">				
					<ng-container [ngSwitch]="d.includedInSum">
						<ng-container *ngSwitchCase="false">
							<td [matTooltip]="'Prefer not to state / Undetermined is not included in any calculation'">
								{{d.label}} <i class="fa fa-fw fa-info-circle"></i> 
							</td>
						</ng-container>
						<ng-container *ngSwitchCase="true">
							<td>
								{{d.label}}
							</td>
						</ng-container>	
					</ng-container>
					<td class="text-center">
						{{group.demographics.get(d.id).potential.contactsRatio | percent:'1.0-2'}}
						<span class="text-sm text-muted">
							({{group.demographics.get(d.id).potential.contacts}})
						</span>
					</td>
					<td class="text-center">
						{{group.demographics.get(d.id).actual.contactsRatio | percent:'1.0-2'}}
						<span class="text-sm text-muted">
							({{group.demographics.get(d.id).actual.contacts}})
						</span>
					</td>
					<ng-container [ngSwitch]="!!group.demographics.get(d.id).compliance.value">
						<ng-container *ngSwitchCase="false">
							<td class="text-center text-muted text-sm">
								N/A
							</td>
							<td class="text-center text-muted text-sm">
								N/A
							</td>
							<td class="text-center text-muted text-sm">
								N/A
							</td>
						</ng-container>
						<ng-container *ngSwitchCase="true">
							<td class="text-center">
								{{group.demographics.get(d.id).parityRange.low.value | percent:'1.0-2'}}
							</td>
							<td class="text-center">
								{{group.demographics.get(d.id).compliance.value.consideredInParity ? 'Yes' : 'No'}}
							</td>
							<td class="text-center">
								{{group.demographics.get(d.id).compliance.value.demonstratesAllReasonableEfforts ? 'Yes' : 'No'}}
							</td>
						</ng-container>
					</ng-container>
				</tr>
			</ng-container>
			<ng-container *ngIf="group.type === ClienteleGroupType.Organization">
				<tr *ngFor="let d of t.demographics">									
					<td>
						{{d.label}}
					</td>					
					<td class="text-center">
						{{group.demographics.get(d.id).potential.contacts}}
					</td>
					<td class="text-center">
						{{group.demographics.get(d.id).actual.contacts}}
					</td>
				</tr>
			</ng-container>
		</tbody>
	</table>

	<!--FUTURE-->

	<ng-container *ngFor="let historicalGroup of historicalGroupList">
		<a class="card-footer text-center" 
		*ngIf="group.id == historicalGroup.id && group.federalFiscalYearId < historicalGroup.federalFiscalYearId"
		[ngSwitch]="highlightedHistoricalGroup != null && historicalGroupDetail != null && highlightedHistoricalGroup.id == historicalGroupDetail.id && highlightedHistoricalGroup.federalFiscalYearId == historicalGroup.federalFiscalYearId && highlightedHistoricalGroup.id == group.id"
		(click)="showHistoricalData(historicalGroup, group)"
		style="cursor:pointer; background: #d9edf7;">
			<span *ngSwitchCase="true">
				Hide Future Stats ({{historicalGroup.federalFiscalYearId-1}}-{{historicalGroup.federalFiscalYearId}})
				<i class="fa fa-fw fa-caret-up"></i>
			</span>		
			<span *ngSwitchCase="false">
				Show Future Stats ({{historicalGroup.federalFiscalYearId-1}}-{{historicalGroup.federalFiscalYearId}})
				<i class="fa fa-fw fa-caret-down"></i>
			</span>
		</a>

		<ng-container *ngIf="group.id == historicalGroup.id && group.federalFiscalYearId < historicalGroup.federalFiscalYearId">
			<table class="table table-bordered table-flush"
			[hidden]="highlightedHistoricalGroup == null || historicalGroupDetail == null || highlightedHistoricalGroup.id != historicalGroup.id || highlightedHistoricalGroup.federalFiscalYearId != historicalGroup.federalFiscalYearId"
			style="border-top: 1px solid #ddd; background: #f0f0f0;">
				<thead>
					<tr *ngIf="group.type != ClienteleGroupType.Organization">
						<th>Demographic</th>
						<th class="text-center">Potential/Baseline</th>
						<th class="text-center">Actual ({{calculateActualSum(group.id, true)}})</th>
						<th class="text-center">Parity Minimum</th>
						<th class="text-center" [matTooltip]="definitions.parity">
							In&nbsp;Parity?
							<i class="fa fa-fw fa-info-circle"></i>
						</th>
						<th class="text-center" [matTooltip]="definitions.ARE">
							A.R.E.?
							<i class="fa fa-fw fa-info-circle"></i>
						</th>
					</tr>
					<tr *ngIf="group.type === ClienteleGroupType.Organization">
						<th>Demographic</th>
						<th class="text-center"># Potential Organizations</th>
						<th class="text-center">Actual Attendees</th>
					</tr>						
				</thead>
				<ng-container *ngIf="historicalGroupDetail != null">
					<tbody *ngFor="let t of demographicInfo.applicableTypesByClienteleGroupTypeId.get(historicalGroupDetail.type)">
						<ng-container *ngIf="group.type != ClienteleGroupType.Organization">
							<tr>
								<th colspan="7">{{t.label}}</th>
							</tr>
							<tr *ngFor="let d of t.demographics">
								<td>
									{{d.label}}
								</td>
								<td class="text-center">
									{{historicalGroupDetail.demographics.get(d.id).potential.contactsRatio | percent:'1.0-2'}}
									<span class="text-sm text-muted">
										({{historicalGroupDetail.demographics.get(d.id).potential.contacts}})
									</span>
								</td>
								<td class="text-center">
									{{historicalGroupDetail.demographics.get(d.id).actual.contactsRatio | percent:'1.0-2'}}
									<span class="text-sm text-muted">
										({{historicalGroupDetail.demographics.get(d.id).actual.contacts}})
									</span>
								</td>
								<ng-container [ngSwitch]="!!historicalGroupDetail.demographics.get(d.id).compliance.value">
									<ng-container *ngSwitchCase="false">
										<td class="text-center text-muted text-sm">
											N/A
										</td>
										<td class="text-center text-muted text-sm">
											N/A
										</td>
										<td class="text-center text-muted text-sm">
											N/A
										</td>
									</ng-container>
									<ng-container *ngSwitchCase="true">
										<td class="text-center">
											{{historicalGroupDetail.demographics.get(d.id).parityRange.low.value | percent:'1.0-2'}}
										</td>
										<td class="text-center">
											{{historicalGroupDetail.demographics.get(d.id).compliance.value.consideredInParity ? 'Yes' : 'No'}}
										</td>
										<td class="text-center">
											{{historicalGroupDetail.demographics.get(d.id).compliance.value.demonstratesAllReasonableEfforts ? 'Yes' : 'No'}}
										</td>
									</ng-container>
								</ng-container>
							</tr>
						</ng-container>
						<ng-container *ngIf="group.type === ClienteleGroupType.Organization">
							<tr *ngFor="let d of t.demographics">									
								<td>
									{{d.label}}
								</td>					
								<td class="text-center">
									{{historicalGroupDetail.demographics.get(d.id).potential.contacts}}
								</td>
								<td class="text-center">
									{{historicalGroupDetail.demographics.get(d.id).actual.contacts}}
								</td>
							</tr>
						</ng-container>	
					</tbody>				
				</ng-container>			
			</table>
		</ng-container>
	</ng-container>

</div>
