import { Component, OnChanges, AfterViewChecked, Input, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { Result } from '../../domain';

@Component({
  selector: 'inline-text-editor',
  templateUrl: './inline-text-editor.component.html',
  styleUrls: ['./inline-text-editor.component.scss']
})
export class InlineTextEditorComponent implements OnChanges, AfterViewChecked {
	originalText: string = '';
	@Input('text') editText: string = '';
	@Input() saveFn: ((text: string) => Promise<boolean>);
	@Input() useTextArea: boolean = false;

	@Output() private textChange: EventEmitter<string> = new EventEmitter<string>();
	saving = false;
	state: 'viewing' | 'editing' = 'viewing';
	private _startEditing: boolean = false;

	@ViewChild('textInput') textInput: any;

	edit(){
		this.state = 'editing';
		this._startEditing = true;
	}

	canSave(){
		return this.editText != this.originalText;
	}

	save(){
		this.saving = true;
		if(!this.saveFn || typeof(this.saveFn) !== 'function' || this.saveFn.length != 1){
			alert('saveFn must be a unary function that returns a Promise');
		}
		this.saveFn(this.editText).then(success => {
			if(success === true){
				this.textChange.emit(this.editText);
				this.originalText = this.editText;
				this.state = 'viewing';
			} 
			this.saving = false;
		});
	}

	cancel(){
		this.state = 'viewing';
		this.editText = this.originalText;
	}

	ngAfterViewChecked(){
		//console.log('inline-text-editor.AfterViewChecked');
		if(this._startEditing){
			this.textInput.nativeElement.focus();
			this.textInput.nativeElement.select();
			this._startEditing = false;
		}
	}

	ngOnChanges(changes){
		//console.log('changes:',changes);
		if('editText' in changes){
			this.originalText = changes['editText'].currentValue;
		}
	}

	//Keyboard support
	@HostListener('keydown.enter')
	private onPressedEnter(){
		if(this.state == 'viewing'){
			this.edit();
			return;
		}
		if(this.saving || !this.canSave()){
			return;
		}
		this.save();
	}
	@HostListener('keydown.escape')
	private onPressedEscape(){
		if(this.state != 'editing' || this.saving){
			return;
		}
		this.cancel();
	}
}
