import { AHRService as svc, CommitteeViewModel, ProgressionTable, PositionFacts, ActionProposal, PurePositionId, DossierFileDescriptor, ComprehensiveReviewRequirements, ProgramReviewSettings, ReviewerCase, CaseDetail, CaseId, PeriodOfReview, CaseProgress, CaseFiles, ReviewForm, ReviewDocumentTemplate, ReviewFile, CandidateFiles, WFACaseInfo } from '../domain/data-access-interfaces/ahr.service';
import { HRCommand, Title, HRAcademic, ChangeStep, ChangeTerm, SetSupervisors, SetAcademic, HRUnit, CreateAcademic, Case, NewsStory, TermId, NewsStoryElementAcademic, Position, PositionId, Action, ActionType } from '../domain/types/ahr';
import { Result, PayrollCampus, Option, AuthenticationService, Lookup, SubjectDictionary} from '../domain';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AppSettings } from '../app-settings';
import { from, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { ConsoleService } from '../widgets/console.service';
import { TooltipService } from '../widgets/tooltip.service';

export enum vendor {
    WFA=1
}

export class vendorPaths {
    public static files = ["files", "wfa-files"];
    public static case = ["wfa_case"];
    public static dossierFiles = ["dossier-files","wfa-files"];
}
    

@Injectable()
export class AHRService implements svc {
    private _signalRConnection: signalR.HubConnection;
    private committeesSubject = 
        new ReplaySubject<CommitteeViewModel>(1);
    private caseSubject =
        new ReplaySubject<Case[]>(1);
    private _caseNewsSubjects = 
        new SubjectDictionary<string,NewsStory[],ReplaySubject<NewsStory[]>>(
            () => new ReplaySubject<NewsStory[]>(1));
    private newsSubject = new ReplaySubject<NewsStory[]>(1);

    private _titles: Title[] = [];
    private _reviewerCases: ReviewerCase[] = null

    private useVendor = vendor.WFA.valueOf();

    constructor(
        private http: HttpClient,
        private appSettings: AppSettings,
        private readonly console: ConsoleService,
        private readonly authSvc: AuthenticationService,
        private tooltipSvc: TooltipService
    ){}

    public getCasesToBeReviewedCurrentUser(): Promise<ReviewerCase[]> {
        if(this._reviewerCases!==null && this._reviewerCases.length>0)
        {
            return Promise.resolve(this._reviewerCases);
        }

        let queryParams = '';

        const cutoffDate = this.tooltipSvc.tooltip("ReviewerDashboard.CutoffDate");

        if (cutoffDate) {
            const dateParts = cutoffDate.trim().split('/');
            if (dateParts.length === 2) {
                const month = parseInt(dateParts[0], 10);
                const day = parseInt(dateParts[1], 10);            
                if (!isNaN(month) && !isNaN(day)) {
                    queryParams = `?month=${month}&day=${day}`;
                }
            }
        }

        return this.http.get(this.appSettings.APIURL + '/program-review/reviewer_cases' + queryParams)
        .pipe(map((cases: any[]) => {
            var cases_ = cases.map(ReviewerCase.fromJson);
            this._reviewerCases = cases_;
            console.log(cases, cases_);
            //return testCases.filter(c => c.id.reviewPeriodEndingInYear === 2020)
            return cases_;
        }))
        .toPromise();
    }

    public getCaseToBeReviewedCurrentUser(id: string): Promise<Option<ReviewerCase>>{
        return this.getCasesToBeReviewedCurrentUser().then(cs => Option.create(cs.filter(c => c.academicId === id)[0]));
    }

    public getCaseFiles(caseId: CaseId): Observable<CaseFiles>{
        let apiUrl = caseId.periodOfReviewEndingInYear > 2021 ? this.appSettings.APIURL + `/program-review/case/${caseId.academicId}/${vendorPaths.files[this.useVendor]}/${caseId.periodOfReviewEndingInYear}` :
                     this.appSettings.APIURL +`/academic-files/reviewer/?academicId=${caseId.academicId}&programYear=${caseId.periodOfReviewEndingInYear}`;
        return this.http.get(apiUrl) // wfa-files
        .pipe(map(json => 
            {

            //Test data
            // if (caseId.academicId == '**'){                
            //     var testCandidateFiles: ReviewFile[] = [
            //         new ReviewFile('a','Position Description',"")
            //         ,new ReviewFile('b','Cover Page',"")
            //         ,new ReviewFile('c','Project Board Export',"")
            //         ,new ReviewFile('d','Bibliography',"")
            //         ,new ReviewFile('e','Sabbatic Leave Report',"")
            //         ,new ReviewFile('f','Letters of Publication Acceptance',"")
            //         ,new ReviewFile('g','Work Plan or Performance Improvement Plan',"")
            //     ];
            //     var testReviewerFiles = [
            //         new ReviewFile('aa','Primary Supervisor Review')
            //         ,new ReviewFile('bb','Secondary Supervisor Review')
            //     ];
            //     var canReadReviewFilesFromCommitteeNames : string[];

            //     return new CaseFiles(testCandidateFiles, testReviewerFiles, canReadReviewFilesFromCommitteeNames);
            // }     

               return CaseFiles.fromJson(json);
            }));
    }

    public caseFileObjectUrl(caseId: CaseId, fileId: string): Observable<any> {
        let url = this.appSettings.APIURL + `/program-review/case/${caseId.academicId}/${vendorPaths.files[this.useVendor]}/${caseId.periodOfReviewEndingInYear}/${fileId}/download`; // wfa-files
        return this.http.get<any>(url, { responseType: "blob" as 'json',observe: 'response'})
        .pipe(map(response => {
            this.console.log(response);
            var filename = response.headers.get('Content-Disposition').split("=")[1];
            filename = filename.replace(/(^"|"$)/g, '');
            const blob = response.body;
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.download = filename;
            anchor.href = url;
            anchor.click();
            //return window.URL.createObjectURL(
            //    new File([response.body], filename, {type: response.body.type} ))
        }));
    }
    public downloadFileFromAzure(caseId: CaseId, fileId: string){
        let url = this.appSettings.APIURL +`/academic-files/download/?academicId=${caseId.academicId}&programYear=${caseId.periodOfReviewEndingInYear}&fileName=${fileId}`;
        return this.http.get(url, { responseType: "blob",observe: 'events'}).subscribe(async (event) => {
            let data = event as HttpResponse <Blob> ;
            const downloadedFile = new Blob([data.body as BlobPart], {
                type: data.body?.type
            });
            if (downloadedFile.type != "") {
            this.downloadViaDomClick(window.URL.createObjectURL(downloadedFile), fileId);
            }
        },
        err => {
            let r = new FileReader();
            r.onloadend = _ => alert('Unable to download file: ' + r.result);
            r.readAsText(err.error);
        }
        );
    }
    public caseFileObject(caseId: CaseId, fileId: string): Observable<any> {
        let url = caseId.periodOfReviewEndingInYear> 2021 ? this.appSettings.APIURL + `/program-review/case/${caseId.academicId}/${vendorPaths.files[this.useVendor]}/${caseId.periodOfReviewEndingInYear}/${fileId}/download`: // wfa-files
                  this.appSettings.APIURL +`/academic-files/download/?academicId=${caseId.academicId}&programYear=${caseId.periodOfReviewEndingInYear}&fileName=${fileId}` ;          
        return this.http.get<any>(url, { responseType: "blob" as 'json', observe: 'response'});
    }
    public dossierFileObject(yearId: number,academicId: string, fileId: string): Observable<any> {
        let url =  yearId >2022 ? this.appSettings.APIURL + `/program-review/case/current_user/${yearId}/${vendorPaths.dossierFiles[this.useVendor]}/download/${fileId}`: // wfa-files
                 this.appSettings.APIURL +`/academic-files/download/?academicId=${academicId}&programYear=${yearId-1}&fileName=${fileId}`; 
        return this.http.get<any>(url, { responseType: "blob" as 'json', observe: 'response'})
    }
    public config(): Promise<null> {
        return this.http.get<Title[]>(this.appSettings.APIURL + '/program-review/titles')
        .toPromise()
        .then(titles => {
            this._titles = titles;
            this.console.log('Titles:', titles);
            return null;
        });
    }

    public getSettings(): Promise<ProgramReviewSettings[]> {
        return this.http.get<ProgramReviewSettings[]>(this.appSettings.APIURL + '/program-review/settings')
        .toPromise();
    }

    public saveSettings(s: ProgramReviewSettings): Promise<Result<void,string>>{
        return this.http.put(this.appSettings.APIURL + '/program-review/settings', s)
        .toPromise()
        .then(o => Result.fromJsonFSharp(o, () => null, err => err));
    }

    public tryGetWFACaseInfo(caseId: string): Promise<Option<WFACaseInfo>> {
        return this.http.get(this.appSettings.APIURL + `/program-review/case/${caseId}/wfa_case`) // wfa
        .toPromise()
        .then(o => Option.fromJsonFSharp(o, WFACaseInfo.fromJson));
    }

    public denyProposedAdvancement(caseId: string, secondaryAction: any, actionType: ActionType): Promise<Result<null,string>>{
        let url = `/proposed-advancement/denial`;
        if(actionType === ActionType.FiveYearReview) url = `/proposed-fiveyearreview/denial`;
        return this.http.post(this.appSettings.APIURL + `/program-review/case/${caseId}${url}`, secondaryAction)
        .toPromise()
        .then(o => Result.fromJsonFSharp(o, _ => null, msg => msg));
    }

    public approveProposedAdvancement(caseId: string, yearId: number, actionType: ActionType): Promise<Result<null,string>>{
         let url = `/proposed-advancement/approval`;
        if(actionType === ActionType.FiveYearReview) url = `/proposed-fiveyearreview/approval`;
        return this.http.post(this.appSettings.APIURL + `/program-review/case/${caseId}${url}`, yearId)
        .toPromise()
        .then(o => Result.fromJsonFSharp(o, _ => null, msg => msg));
    }

    public getDossierFileDescriptorsForCurrentUser(yearId: number, academicId: string): Observable<CandidateFiles>{
        let url =  yearId >2022 ?  this.appSettings.APIURL + `/program-review/case/current_user/${yearId}/${vendorPaths.dossierFiles[this.useVendor]}`:
        this.appSettings.APIURL +`/academic-files/candidate/?academicId=${academicId}&programYear=${yearId}`
        return this.http.get<CandidateFiles>(url) // wfa-files
            .pipe(map(json => {
                return CandidateFiles.fromJson(json);
            }));
    }

    public dossierFileObjectUrl(yearId: number, fileId: string, academicId: string): Observable<any> {
        let url =  yearId >2022 ? this.appSettings.APIURL + `/program-review/case/current_user/${yearId}/${vendorPaths.dossierFiles[this.useVendor]}/download/${fileId}`: // wfa-files
                 this.appSettings.APIURL +`/academic-files/download/?academicId=${academicId}&programYear=${yearId-1}&fileName=${fileId}`; 
        return this.http.get<any>(url, { responseType: "blob" as 'json', observe: 'response'})
        .pipe(map(response => {
            this.console.log(response);
            var filename = yearId >2022 ? response.headers.get('Content-Disposition').split("=")[1]: fileId;
            filename = filename.replace(/(^"|"$)/g, '');
            const blob = response.body;
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.download = filename;
            anchor.href = url;
            anchor.click();
            //return window.URL.createObjectURL(
            //    new File([response.body], filename, {type: response.body.type} ))
        }));
    }

    private alt = true;
    public downloadDossierFile(yearId: number, fileId: string,academicId: string): void {
        if(this.alt){
            this.dossierFileObjectUrl(yearId, fileId, academicId)
            .subscribe(objectUrl =>{
                downloadViaDomClick(objectUrl);
            });
        } else {
            this.http.get<string>(
                this.appSettings.APIURL + `/program-review/case/current_user/${yearId}/${vendorPaths.dossierFiles[this.useVendor]}/${fileId}`) // wfa-files
                .subscribe(url => window.open(url, '_blank'));
        }

		function downloadViaDomClick(url: string, filename: string = ''){
			let anchor = window.document.createElement('a');
            anchor.href = url;
            if(filename != ''){
                anchor.download = filename;
            }

			document.body.appendChild(anchor);
			anchor.click();
			document.body.removeChild(anchor);
		}
    }

    public appealResult(caseId: string): Promise<Result<null,string>>{
        return this.http.post(this.appSettings.APIURL + `/program-review/case/${caseId}/appeal`,null)
        .toPromise()
        .then(o => Result.fromJsonFSharp(o, _ => null, msg => msg));
    }

    public promoteSupervisor(id: string, userId: number): Promise<Result<null,string>>{
        return this.http.put(this.appSettings.APIURL + `/program-review/academic/${id}/supervisor/${userId}/promote`,{})
        .toPromise()
        .then(r => Result.fromJsonFSharp(r, _ => null, msg => msg));
    }
    public demoteSupervisor (id: string, userId: number): Promise<Result<null,string>>{
        return this.http.put(this.appSettings.APIURL + `/program-review/academic/${id}/supervisor/${userId}/demote`,{})
        .toPromise()
        .then(r => Result.fromJsonFSharp(r, _ => null, msg => msg));
    }
    public addSupervisor (id: string, userId: number, reviewParticipationId: string): Promise<Result<null,string>>{
        return this.http.put(this.appSettings.APIURL + `/program-review/academic/${id}/supervisor/${userId}/${reviewParticipationId}`, {})
        .toPromise()
        .then(r => Result.fromJsonFSharp(r, _ => null, msg => msg));
    }
    public removeSupervisor (id: string, userId: number): Promise<Result<null,string>>{
        return this.http.delete(this.appSettings.APIURL + `/program-review/academic/${id}/supervisor/${userId}`)
        .toPromise()
        .then(r => Result.fromJsonFSharp(r, _ => null, msg => msg));
    }

    public removeCountyAssignment(id: string, countyId: number): Promise<Result<null,string>>{
        return this.http.delete(this.appSettings.APIURL + `/program-review/academic/${id}/county_assignment/${countyId}`)
        .toPromise()
        .then(r => Result.fromJsonFSharp(r, _ => null, msg => msg));
    }

    public addCountyAssignment(id: string, countyId: number): Promise<Result<null,string>>
    {
        return this.http.post(this.appSettings.APIURL + `/program-review/academic/${id}/county_assignment/${countyId}`, null)
        .toPromise()
        .then(r => Result.fromJsonFSharp(r, _ => null, msg => msg));
    }

    public createNewAcademic(
        userId: number,
        startingPositionId: PurePositionId,
        term: Option<TermId>,
        startDate: Date
    ): Promise<Result<null,string>> {
        return this.http.post(
            this.appSettings.APIURL + '/program-review/academic',
            {
                userId: userId,
                positionId: startingPositionId,
                term: term.toJsonFSharp(t => ({case:t})),
                startDate: startDate
            })
        .pipe(map(json => Result.fromJsonFSharp(json, _ => null, str => str)))
        .toPromise();
    }

    public getProgressionTable(f: PositionFacts): Promise<Option<ProgressionTable>>{
        return this.http.get(
            this.appSettings.APIURL + '/program-review/progression_table', 
            {params: <{[key:string]: any}>f})
        .pipe(map(json => Option.fromJsonFSharp(json,ProgressionTable.fromJson)))
        .toPromise();
    }

    public getReviewRequirements(titleId: number, rankId: number, stepId: number): Promise<ComprehensiveReviewRequirements>{
        return this.http.get(
            this.appSettings.APIURL + '/program-review/review_requirements', 
            {params: <{[key:string]: any}>{ titleId, rankId, stepId }})
        .pipe(map(ComprehensiveReviewRequirements.fromJson))
        .toPromise();
    }

    public viewCaseInWFA(caseId: string): void {
        let endpoint = '/program-review/wfa_packet_redirect/' + caseId;
        this.redirectToWFA(endpoint);
    }

    public wfaSSO(admin: boolean = false): void {
        let endpoint = admin ? "/wfa_sso_redirect_ahr_admin" : "/wfa_sso_redirect";
        this.redirectToWFA(endpoint);
    }

    private redirectToWFA(endpoint):void {
      this.http.get(this.appSettings.APIURL + endpoint)
        .pipe(map(json => (<string>json)))
        .toPromise()
        .then(url =>{
            const userClaims = localStorage.getItem("projectboard.user.claims");
            if (url) {
            window.open(
                url+'?projectBoard='+encodeURI(userClaims),
                '_blank');
            }
        });;
    }

    public getCasesForCurrentUser(): Observable<Case[]> {
        return this.http.get(this.appSettings.APIURL + '/program-review/case/current_user')
        .pipe(map(json => (<any[]>json).map(Case.fromJson)));
    }

    public getNewsStoriesForCurrentUser(yearId: number): Observable<NewsStory[]>{
        return this.http.get(this.appSettings.APIURL + `/program-review/case/current_user/${yearId}/news`)
        .pipe(map(json => (<any[]>json).map(NewsStory.fromJson)));
    }

    public subscribeToNotifications(): void{
        this.authSvc.currentIdentity
        .pipe(map(ci => ci.map(ci => ci.token)))
        .subscribe(token => 
            token.matchDo(
                () => {
                    this.unsubscribeToNotifications();
                },
                t => {
                    this.connect(t);
                }));

    }
    public get cases(): Observable<Case[]> {
        return this.caseSubject.asObservable();
    }
    public get newsStories(): Observable<NewsStory[]> {
        return this.newsSubject.asObservable();
    }
    public newsStoriesByCaseId(caseId: string): Observable<NewsStory[]>{
        return this._caseNewsSubjects.get(caseId);
    }
    public get committees(): Observable<CommitteeViewModel> {
        return this.committeesSubject.asObservable();
    } 

    private connect(token: string){

        let loadNewsStories = () => {
            this.http.get(this.appSettings.APIURL + '/program-review/news')
            .pipe(map(json => (<any[]>json).map(NewsStory.fromJson)))
            .subscribe(stories => 
            {
                this.newsSubject.next(stories);

                var academicStoryPairs = 
                    stories
                    .map(s => {
                        let academicId =
                            s.elements
                            .map(e => e.case == 'Academic' ? (<NewsStoryElementAcademic>e).academicId : null)
                            .filter(academicId => !!academicId)[0];
                        return [academicId, s] as ([string,NewsStory]);
                    });

                Lookup.fromArray(
                    academicStoryPairs, 
                    ([academicId]) => academicId,
                    ([,story]) => story)
                .forEach((academicId,stories) => 
                    this._caseNewsSubjects.next(academicId, stories));
            });
        }

        let loadCommittees = () =>
            this.http.get(this.appSettings.APIURL + '/program-review/committee/')
            .pipe(map(json => <CommitteeViewModel>json))
            .subscribe(vm => this.committeesSubject.next(vm));

        let loadCases = () =>
            this.http.get(this.appSettings.APIURL + '/program-review/case')
            .pipe(map(json => (<any[]>json).map(Case.fromJson)))
            .subscribe(cases => this.caseSubject.next(cases));

        let options = {accessTokenFactory: () => Promise.resolve(token)};
        let connection = new signalR.HubConnectionBuilder()
            .withUrl(this.appSettings.APIURL + '/program-review/hub', options)
            .configureLogging(signalR.LogLevel.Information)
            .build();

        connection.on('caseUpdated', (caseId: string) => {
            this.console.log('Case Updated: ' + caseId);
            loadCases();
        });
        connection.on('casesRebuilt', () => {
            this.console.log('Cases Rebuilt');
            loadCases();
        });
        connection.on('caseNewsUpdated', (caseId: string) => {
            this.console.log('Case News Updated: ' + caseId);
            loadNewsStories();
        });
        connection.on('caseNewsRebuilt', () => {
            this.console.log('Case News Rebuilt');
            loadNewsStories();
        });
        connection.on('committeesUpdated', () => {
            this.console.log('committees Updated');
            loadCommittees();
        });
        connection.start().then(_ => {
            this.console.info('Connected to SignalR:', connection);
            connection.invoke('echo','Testing 123...').then(result => this.console.log("Response from SignalR.ProgramReviewHub.echo: ", result));
        });
        this._signalRConnection = connection;
        loadCases();
        loadCommittees();
        loadNewsStories();
    }

    public unsubscribeToNotifications(){
        if(!this._signalRConnection){
            return;
        }
        this._signalRConnection.stop();
    }

    public addCommitteeMember(id: string, committeeId: string, userId: number): Promise<Result<void,string>> {
        return this.http.post(this.appSettings.APIURL + '/program-review/committee/' + id + '?committeeId=' + committeeId + '&userId=' + userId, null)
        .toPromise()
        .then(json => Result.fromJsonFSharp<void,string>(json, _ => {
            return null;
        }, str => str));
    }

    public removeCommitteeMember(id: string, committeeId: string, userId: number): Promise<Result<void,string>> {
        return this.http.delete(this.appSettings.APIURL + '/program-review/committee/' + id + '?committeeId=' + committeeId + '&userId=' + userId)
        .toPromise()
        .then(json => Result.fromJsonFSharp<void,string>(json, _ => {
            return null;   
        }, str => str));
    }

    public getCampuses(): Promise<PayrollCampus[]> {
        return this.http.get(this.appSettings.APIURL + '/hr/campus')
        .toPromise()
        .then(json => (<any[]>json).map(PayrollCampus.fromJson));
    }

    public getAllAcademics(): Promise<HRAcademic[]>
    {
        return this.http.get(this.appSettings.APIURL + '/hr/academic')
        .toPromise()
        .then(json => (<any[]>json).map(HRAcademic.fromJson));
    }

    public getAllANRUnits(): Promise<HRUnit[]>
    {
        return this.http.get(this.appSettings.APIURL + '/hr/anr_units')
        .toPromise()
        .then(json => (<any[]>json).map(HRUnit.fromJson));
    }

    public getAllTitlesRanksAndSteps(): Title[]
    {
        return this._titles;
    };

    public execute(cmd: HRCommand): Promise<Result<null,string>>
    {
        return cmd.match<Observable<any>>({
            ChangeStep:     (c: ChangeStep)     => this.http.put(this.appSettings.APIURL + '/hr/step'       , c),
            ChangeTerm:     (c: ChangeTerm)     => this.http.put(this.appSettings.APIURL + '/hr/term'       , c),
            SetSupervisors: (c: SetSupervisors) => this.http.put(this.appSettings.APIURL + '/hr/supervisors', c),
            SetAcademic:    (c: SetAcademic)    => this.http.put(this.appSettings.APIURL + '/hr/academic'   , c),
            CreateAcademic: (c: CreateAcademic) => this.http.post(this.appSettings.APIURL + '/hr/academic'  , c)
        })
        .toPromise()
        .then(json => Result.fromJson<null, string>(json, _ => null, msg => msg));
    };

    public sendToCandidate(caseId: string): Promise<Result<void,string>> {
        return this.http.post(this.appSettings.APIURL + '/program-review/case/' + caseId + '/send-to-candidate', {})
        .toPromise()
        .then(json => Result.fromJsonFSharp(json, _ => null, err => err))
    }

    public recallFromCandidate(caseId: string, reason: string): Promise<Result<void,string>>
    {
        return this.http.post(this.appSettings.APIURL + '/program-review/case/' + caseId + '/recall', {reason})
        .toPromise()
        .then(json => Result.fromJsonFSharp(json, _ => null, err => err))
    }

    public proposeDeferral(caseId: string, reason: string): Promise<Result<void,string>>
    {
        return this.http.post(this.appSettings.APIURL + '/program-review/case/' + caseId + '/propose-deferral', {reason})
        .toPromise()
        .then(json => Result.fromJsonFSharp(json, _ => null, err => err))
    }

    public approveDeferral(caseId: string): Promise<Result<void,string>>
    {
        return this.http.post(this.appSettings.APIURL + '/program-review/case/' + caseId + '/approve-deferral', {})
        .toPromise()
        .then(json => Result.fromJsonFSharp(json, _ => null, err => err))
    }

    public proposeTermReview(caseId: string): Promise<Result<void,string>>
    {
        return this.http.post(this.appSettings.APIURL + '/program-review/case/' + caseId + '/propose-term-review', {})
        .toPromise()
        .then(json => Result.fromJsonFSharp(json, _ => null, err => err))
    }

    public proposeAction(caseId: string, action: ActionProposal): Promise<Result<void,string>>{
        let data = action.toJsonFsharp();
        return this.http.post(this.appSettings.APIURL + '/program-review/case/' + caseId + '/proposal', data)
        .toPromise()
        .then(json => Result.fromJsonFSharp(json, _ => null, err => err));
    }

    public downloadAcademicExcel() : void {
        
        this.http.get(this.appSettings.APIURL + '/hr/academic_excel_export', {responseType: 'blob'})
        .subscribe(
            blob => {
                this.downloadViaDomClick(window.URL.createObjectURL(blob), `AcademicReport.xlsx`);
            },
            err => {
                let r = new FileReader();
                r.onloadend = _ => alert('Unable to download usage report: ' + r.result);
                r.readAsText(err.error);
            }
        );

    }
    public downloadWFAReportExcel() : void {
        
        this.http.get(this.appSettings.APIURL + '/reports/wfaCase_Report', {responseType: 'blob'})
        .subscribe(
            blob => {
                this.downloadViaDomClick(window.URL.createObjectURL(blob), `WFACaseReport.xlsx`);
            },
            err => {
                let r = new FileReader();
                r.onloadend = _ => alert('Unable to download usage report: ' + r.result);
                r.readAsText(err.error);
            }
        );
    }
    public downloadViaDomClick(url: string, filename: string){
        let anchor = window.document.createElement('a');
        anchor.href = url;
        anchor.download = filename;

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);        
    }
    
}