<div class="container"> 
    <h4>
        Settings
    </h4>
    <hr/>

    <table class="table table-bordered" style="background:white;">
        <thead>
            <tr>
                <th>
                    For the period of review ending in
                </th>
                <th colspan="2">Results visible on candidate dashboard</th>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let s of settings">
                <td>{{s.periodOfReviewEndingInYearId}}</td>
                <ng-container [ngSwitch]="!!s.resultsVisibleToCandidates">
                    <ng-container *ngSwitchCase="true">
                        <td>Currently: visible</td>
                        <td class="text-center">
                            <button class="btn btn-sm btn-primary" (click)="changeCandidateResults(s,false)">
                                Hide
                            </button>
                        </td>
                    </ng-container>
                    <ng-container *ngSwitchCase="false">
                        <td>Currently: hidden</td>
                        <td class="text-center">
                            <button class="btn btn-sm btn-danger" (click)="changeCandidateResults(s,true)">
                                Show
                            </button>
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
        </tbody>

    </table>
</div>