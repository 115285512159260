import { TagDto, Tag } from "..";

export class ActivitySpecification {
    constructor(
        public readonly matchingKeyword: string,
        public readonly havingAtLeastOneTagOf: Tag[]
    ){}

    public static fromJson(o: any): ActivitySpecification {
        return new ActivitySpecification(
            o.matchingKeyword,
            (<any[]>o.havingAtLeastOneTagOf).map(Tag.fromJson));
    }

    public toDto(): ActivitySpecificationDto {
        return new ActivitySpecificationDto(
            this.matchingKeyword,
            this.havingAtLeastOneTagOf.map(t => t.toDto()));
    }
}

export class ActivitySpecificationDto {
    constructor(
        public readonly matchingKeyword: string,
        public readonly havingAtLeastOneTagOf: TagDto[]
    ){}
}