import { ActivityDate, ActivityParentIdDto, ClienteleContactCollection } from ".";
import { ClienteleGroupDemographic } from "./clientele-group";

export class ActivityCollaborationInvitation {
    constructor(
        public readonly originalActivity: OriginalActivity,
        public readonly addedByUser: SimpleUser 
    ) {}

    public static fromJson(o: any): ActivityCollaborationInvitation {
        return new ActivityCollaborationInvitation(
            OriginalActivity.fromJson(o.originalActivity),
            SimpleUser.fromJson(o.addedByUser));
    }
}

export class SimpleUser
{
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly academicTitle: string,
        public readonly location: string
    ){}

    public static fromJson(o: any): SimpleUser {
        return new SimpleUser(
            o.id,
            o.name,
            o.academicTitle,
            o.location);
    }
}

export class OriginalActivity
{
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly date: ActivityDate,
        public readonly clienteleContactCount: number,
        public readonly clientele: Clientele
    ){}

    public static fromJson(o: any): OriginalActivity {
        return new OriginalActivity(
            o.id,
            o.name,
            ActivityDate.fromJson(o.date),
            <number>o.clienteleContactCount,
            o.clientele.hasValue ? Clientele.fromJson(o.clientele._value) : Clientele.fromJson(o.clientele));
    }
}

export class Clientele
{
    constructor(
        public readonly groupId: number,
        public readonly groupName: string,
        public readonly groupTypeId: number,
        public readonly contacts: ClienteleContactCollection
    ){}

    public static fromJson(o: any):Clientele {
        return new Clientele(
            o.groupId,
            o.groupName,
            o.groupTypeId,
            ClienteleContactCollection.fromJson(o.contacts)
        );
    }
}

export class ConfirmActivityCollaboration{
  constructor(
    public readonly activityId: number,
    public role: string,
    public clienteleGroupId: number,
    public parentId: ActivityParentIdDto
  ){}
}

export class ActivityCollaborationInvitationConfirmed 
{
    constructor(
        public readonly userId: number,
        public readonly activityId: number,
        public readonly role: string,
        public readonly clienteleGroupId: number,
        public readonly parentId: number
    ){}

    public static fromJson(o: any): ActivityCollaborationInvitationConfirmed {
        return new ActivityCollaborationInvitationConfirmed(o.userId,
            o.activityId,
            o.role,
            o.clienteleGroupId,
            o.parentId
        );
    }
}