import { Option } from '../option';

export type NewsStoryElement = NewsStoryElementBy | NewsStoryElementAcademic | NewsStoryElementText | NewsStoryElementSupervisor
export class NewsStory {
    constructor(
        public readonly timestamp: Date,
        public readonly elements: NewsStoryElement[]
    ){

    }

    public static fromJson(o: any): NewsStory {
        return new NewsStory(
            o.timestamp, 
            (<any[]>o.elements).map(NewsStory.elementFromJson));
    }

    private static elementFromJson(o: any): NewsStoryElement {
        switch(o.case){
            case "Academic"  : return new NewsStoryElementAcademic(o.fields[0], o.fields[1]);
            case "By"        : return new NewsStoryElementBy(
                o.fields[0].user,
                Option.fromJsonFSharp(o.fields[0].onBehalfOf, o => o));
            case "Text"      : return new NewsStoryElementText(o.fields[0]);
            case "Supervisor": return new NewsStoryElementSupervisor(o.fields[0]);
            default:
                throw("Unexpected NewsStoryElement type: " + o.case);
        }
    }
}
export class NewsStoryElementSupervisor {
    public readonly case: string = "Supervisor";
    constructor(
        public readonly supervisor: UserIdAndName
    ){ }
}
export class NewsStoryElementBy {
    public readonly case: string = "By";
    constructor(
        public readonly user: UserIdAndName,
        public readonly onBehalfOf: Option<String>,
    ){ }
}
export class NewsStoryElementAcademic {
    public readonly case: string = "Academic";
    constructor(
        public readonly academicId: string,
        public readonly academic: UserIdAndName
    ){ }
}
export class NewsStoryElementText {
    public readonly case: string = "Text";
    constructor(
        public readonly text: string
    ){ }
}
export class UserIdAndName {
    id: number;
    name: string;
}

export function newsStoryIsByUserId(story: NewsStory, userId: number): boolean {
    return story.elements.some(e =>
        e.case == "By" 
        && userId == (<NewsStoryElementBy>e).user.id);
}

export function newsStoryIsOnBehalfOf(story: NewsStory, userName: string): boolean {
    return story.elements.some(e =>
        e.case == "By" 
        && 
        (<NewsStoryElementBy>e)
            .onBehalfOf.map(obou => obou == userName)
            .getValueOrDefault(false));
}