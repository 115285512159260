<h4 mat-dialog-title class="text-center">
	ANR Project Board
</h4>
<mat-dialog-content>
	<div class="text-center">
		<p class="mb-4">{{message}}</p>
		<img style="max-width:300px;" src="/assets/UCANR_rect.png" class="logo">
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button 
		style="box-sizing: border-box;"
		class="btn btn-primary btn-block btn-lg" 
		(click)="logIn()">Log in</button>
</mat-dialog-actions>