import { Option, PayrollCampus, Result } from "..";
import { HRCommand, Title, HRAcademic, HRUnit, Case, ActionType, NewsStory, IdAndName, TermId, Position, Action, CaseAdvancement, ReviewStepMetaData } from '../types/ahr';
import { Observable } from 'rxjs';

export class PurePositionId {
	constructor(
		public readonly titleId: number,
		public readonly rankId: number,
		public readonly stepId: number
    ){}
}
export class ProgramReviewSettings {
    constructor(
        public readonly periodOfReviewEndingInYearId: number,
        public readonly resultsVisibleToCandidates: boolean
    ){}

    public static fromJson(o: any): ProgramReviewSettings {
        return new ProgramReviewSettings(
            o.periodOfReviewEndingInYearId,
            o.resultsVisibleToCandidates
        );
    }
}

export class WFACaseInfo {
    constructor(
        public readonly id: number,
        public readonly url: string,
        public readonly readUrl: Option<string>

    ){}

    public static fromJson(o: any): WFACaseInfo {
        return new WFACaseInfo(
            o.id,
            o.url,
            Option.fromJsonFSharp(o.readUrl, str => str)
        );
    }
}

export class ReviewDocumentType {
    constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly description: string
    ){}
}

export class ReviewAction {
    constructor(
        public readonly allowed: boolean,
        public readonly action: ActionType,
        public readonly accelerating: boolean, 
        public readonly requirements: {[documentTypeId: string]: CandidateReviewDocument}
    ){}
    public static fromJson(o: any): ReviewAction {
        /* For some reason, JSON.net lower-cases the DU values when serializing to a JSON object.
        ** We have to undo that for easy access. */
        for(var k in o.requirements){
            let K = k[0].toUpperCase() + k.slice(1);
            o.requirements[K] = o.requirements[k];
            delete o.requirements[k];
        }
        return o;
    }
} 

export class CandidateReviewDocument {
    constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly description: string,
        public readonly requirementLabel: string,
        public readonly required: boolean
    ){}
}

export class ComprehensiveReviewRequirements {
    constructor(
        public readonly documentTypes: ReviewDocumentType,
        public readonly actions: ReviewAction[]
    ){ }
    public static fromJson(o: any): ComprehensiveReviewRequirements {
        return new ComprehensiveReviewRequirements(
            o.documentTypes.map(dt => new ReviewDocumentType(dt.id.case, dt.name, dt.description)),
            o.actions.map(ReviewAction.fromJson)
        );
    }
}

export class DossierFileDescriptor {
    public readonly id: string;
    public readonly name: string;
}

export class CaseId {
  constructor(
    readonly academicId: string,
    readonly periodOfReviewEndingInYear: number
  ){}
  public equals(other: CaseId): boolean {
      return !!other 
        && this.academicId === other.academicId
        && this.periodOfReviewEndingInYear === other.periodOfReviewEndingInYear;
  }
  static fromJson(o: any): CaseId {
      return new CaseId(
          o.academicId,
          o.periodOfReviewEndingInYear);
  }
}

export class ReviewDocumentTemplate {
    constructor(
        readonly name: string,
        readonly instructionsHTML: string
    ){}

    static fromJson(o: any): ReviewDocumentTemplate {
        return new ReviewDocumentTemplate(
            o.name,
            o.instructionsHTML);
    }
} 

export class ReviewForm {
    constructor(
        readonly requiredReviewDocument: Option<ReviewDocumentTemplate>
    ){ }

    static fromJson(o: any): ReviewForm {
        return new ReviewForm(
            Option.fromJsonFSharp(o.requiredReviewDocument, ReviewDocumentTemplate.fromJson));
    }
}



export class ReviewerCase {
    get academicId(){ 
        return this.id.academicId;
    }
    get roles(): string {
        return this.yourSteps.map((s: ReviewerStep) => s.title).join(', ');
    }
    constructor(
        readonly id: CaseId,
        readonly caseHistory: CaseId[],
        readonly userId: number,
        readonly candidateName: string,
        readonly currentPosition: Position,
        readonly proposedAction: Action,
        readonly proposedActionName: string,
        readonly periodOfReview: Option<PeriodOfReview>,
        readonly progress: CaseProgress,
        readonly canViewReviewerFiles: boolean,

        readonly yourSteps: ReviewerStep[],
        readonly readyForYourReview: boolean,
        public isChecked : boolean = false,
        readonly isReviewer: boolean =true
    ){
    }

    static fromJson(o: any): ReviewerCase {
        return new ReviewerCase(
            CaseId.fromJson(o.id),
            (<any[]> o.caseHistory).map(CaseId.fromJson),
            o.userId,
            o.candidateName,
            Position.fromJson(o.currentPosition),
            Action.fromJson(o.proposedAction),
            o.proposedActionName,
            Option.fromJsonFSharp(o.periodOfReview,PeriodOfReview.fromJson),
            CaseProgress.fromJson(o.progress),
            o.canViewReviewerFiles,
            (<any[]> o.yourSteps).map(ReviewerStep.fromJson),
            o.readyForYourReview,
            false
        );
    }

    public asCaseAdvancement() {
		return new CaseAdvancement(
			Option.create(this.proposedAction), 
			this.currentPosition);
	}
}
export class ReviewerStep {
    constructor(
        readonly title: string,
        readonly description: string,
        readonly id: string,
        readonly status: string,
        readonly requiredReviewDocument: Option<ReviewDocumentTemplate>,
        readonly readOnlyStep: boolean
    ){}
    static fromJson(o: any): ReviewerStep {
        return new ReviewerStep(
            o.title,
            o.description,
            o.id,
            o.status.case,
            Option.fromJsonFSharp(o.requiredReviewDocument, ReviewDocumentTemplate.fromJson),
            o.readOnlyStep
        );
    }
}
export class PeriodOfReview {
    constructor(
        readonly start: Date,
        readonly end: Date
    ){}

    static fromJson(o: any): PeriodOfReview {
        return new PeriodOfReview(new Date(o.start), new Date(o.end));
    }
}

export class ReviewFile {
    constructor(
        readonly id: string,
        readonly name: string,
        readonly committeeName: string = "",
        public isChecked: boolean = false
    ){

    }
}

export class CandidateFiles {
    constructor(
        public candidateFiles: ReviewFile[],
        public candidateCanReadFiles: string[]
    ){}

    public static fromJson(o: any): CandidateFiles {
		return new CandidateFiles (
            o.candidateFiles,
            o.candidateCanReadFiles)
	}
}

export class CaseFiles {
    constructor(
        readonly candidateFiles: ReviewFile[],
        public reviewerFiles: ReviewFile[],
        readonly canReadReviewFilesFromCommitteeNames: string[]
    ){}

    public static fromJson(o: any): CaseFiles {
		return new CaseFiles (
            o.candidateFiles,
            o.reviewerFiles,
            o.canReadReviewFilesFromCommitteeNames)
	}
}
export class CaseProgress {
    //status = 'In Review';
    toString(){

        let i = this.steps.indexOf(this.activeSteps[0]);
        let n = i+1;

        if(n==0){
            
            let allStepsComplete = this.completedSteps.length > 0 && this.steps.length > 0 &&
                    this.steps.every(value => this.completedSteps.includes(value));
            
            if (allStepsComplete)
                return 'Complete';

            let waitingToBeSent = this.activeSteps.length === 0 && this.completedSteps.length === 0;

            if (waitingToBeSent)
                return 'Waiting to be sent to candidate';

            return this.activeSteps[0] + '*';
        }

        return `Step ${n} of ${this.steps.length} (${this.activeSteps})`;
    }

    stepActive(s): boolean 
    {
        return this.activeSteps.indexOf(s)> -1;
    }

    stepCompleted(s): boolean 
    {
        return this.completedSteps.indexOf(s)> -1;
    }
    

    constructor(
        readonly steps: string[],
        readonly activeSteps: string[],
        readonly completedSteps: string[]
    ){

    }

    static fromJson(o: any): CaseProgress {
        return new CaseProgress(
            o.steps,
            o.activeSteps,
            o.completedSteps
        );
    }
}
export class CaseDetail {
    constructor(
    ){
    }
}
export abstract class AHRService {
    public abstract getCasesToBeReviewedCurrentUser(): Promise<ReviewerCase[]>;
    public abstract getCaseToBeReviewedCurrentUser(id: string): Promise<Option<ReviewerCase>>;
    public abstract getDossierFileDescriptorsForCurrentUser(yearId: number, academicId: string): Observable<CandidateFiles>;
    public abstract getCaseFiles(caseId: CaseId): Observable<CaseFiles>;
    public abstract dossierFileObjectUrl(yearId: number, fileId: string, academicId: string): Observable<any>;
    public abstract caseFileObjectUrl(caseId: CaseId, fileId: string): Observable<any>;
    public abstract caseFileObject(caseId: CaseId, fileId: string): Observable<any>;
    public abstract dossierFileObject(yearId: number,academicId: string, fileId: string): Observable<any>;
    public abstract downloadFileFromAzure(caseId: CaseId, fileId: string);
    public abstract downloadDossierFile(yearId: number, fileId: string, academicId: string): void;
    public abstract config(): Promise<null>;
    public abstract getCampuses(): Promise<PayrollCampus[]>;
    public abstract getSettings(): Promise<ProgramReviewSettings[]>;
    public abstract saveSettings(s: ProgramReviewSettings): Promise<Result<void,string>>;
    public abstract appealResult(caseId: string): Promise<Result<null,string>>;
    public abstract getAllAcademics(): Promise<HRAcademic[]>;
    public abstract getAllTitlesRanksAndSteps(): Title[];
    public abstract execute(cmd: HRCommand): Promise<Result<null,string>>;
    public abstract createNewAcademic(userId: number, startingPositionId: PurePositionId, term: Option<TermId>, startDate: Date): Promise<Result<null,string>>;
    public abstract getAllANRUnits(): Promise<HRUnit[]>;
    public abstract get cases(): Observable<Case[]>;
    public abstract getCasesForCurrentUser(): Observable<Case[]>;
    public abstract getNewsStoriesForCurrentUser(yearId: number): Observable<NewsStory[]>;
    public abstract proposeAction(caseId: string, action: ActionProposal): Promise<Result<void,string>>;
    public abstract sendToCandidate(caseId: string): Promise<Result<void,string>>;
    public abstract recallFromCandidate(caseId: string, reason: string): Promise<Result<void,string>>;
    public abstract proposeDeferral(caseId: string, reason: string): Promise<Result<void,string>>;
    public abstract approveDeferral(caseId: string): Promise<Result<void,string>>;
    public abstract proposeTermReview(caseId: string): Promise<Result<void,string>>;
    public abstract get newsStories(): Observable<NewsStory[]>;
    public abstract newsStoriesByCaseId(caseId: string): Observable<NewsStory[]>;
    public abstract get committees(): Observable<CommitteeViewModel>;
    public abstract addCommitteeMember(id: string, committeeId: string, userId: number): Promise<Result<void,string>>;
    public abstract removeCommitteeMember(id: string, committeeId: string, userId: number): Promise<Result<void,string>>;
    public abstract promoteSupervisor(id: string, userId: number): Promise<Result<null,string>>;
    public abstract demoteSupervisor (id: string, userId: number): Promise<Result<null,string>>;
    public abstract addSupervisor (id: string, userId: number, reviewParticipationId: string): Promise<Result<null,string>>;
    public abstract removeSupervisor (id: string, userId: number): Promise<Result<null,string>>;
    public abstract tryGetWFACaseInfo(caseId: string): Promise<Option<WFACaseInfo>>;
    public abstract denyProposedAdvancement(caseId: string, secondaryAction: any, actionType: ActionType): Promise<Result<null,string>>;
    public abstract approveProposedAdvancement(caseId: string, yearId: number, action: ActionType): Promise<Result<null,string>>;

    public abstract getProgressionTable(facts: PositionFacts): Promise<Option<ProgressionTable>>;
    public abstract getReviewRequirements(titleId: number, rankId: number, stepId: number): Promise<ComprehensiveReviewRequirements>;

    public abstract subscribeToNotifications(): void;
    public abstract unsubscribeToNotifications(): void;
    public abstract wfaSSO(admin: boolean): void;

    public abstract viewCaseInWFA(caseId: string): void;

    public abstract removeCountyAssignment(caseId: string, countyId: number): Promise<Result<null,string>>;
    public abstract addCountyAssignment(caseId: string, countyId: number): Promise<Result<null,string>>;

    public abstract downloadAcademicExcel(): void;
    public abstract downloadWFAReportExcel(): void;
}

export class ActionProposal {
    constructor(
        public readonly type: ActionProposalType,
        public readonly advancement: Advancement
    ){ }

    public toJsonFsharp(): any {
        return this.type == ActionProposalType.AdvancementTo
            ? {
                case: this.type,
                fields: [ this.advancement.toJsonFsharp() ]
            } : {
                case: this.type
            };
    }
}

export class Advancement {
    constructor(
        public readonly position: TitleRankAndStepId,
        public readonly term: Option<TermId>
    ){}
    public toJsonFsharp(): any {
        return {
            positionId: this.position,
            term: this.term.toJsonFSharp(t => ({case:t}))
        };
    }
}

export enum ActionProposalType{
     AnnualEvaluation = "AnnualEvaluation"
    ,Goals            = "Goals"
    ,AdvancementTo    = "AdvancementTo"
    ,TermAdvancement  = "TermAdvancement"
    ,Deferral         = "Deferral"
    ,FiveYearReview   = "FiveYearReview"
}

export class ProgressionTable {
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly ranks: ProgressionTableRank[],
        public readonly overlappingSteps: OverlappingStep[]
    ){ }

    public static fromJson(o: any): ProgressionTable {
        return new ProgressionTable(
            o.id,
            o.name,
            (<any[]>o.ranks).map(ProgressionTableRank.fromJson),
            (<any[]>o.overlappingSteps).map(OverlappingStep.fromJson)
        );
    }

    public lastRank(): ProgressionTableRank {
        return this.ranks[this.ranks.length-1];
    }

    public lastStep(): ProgressionTableStep {
        return this.lastRank().steps[this.lastRank().steps.length-1];
    }
}

export class ProgressionTableRank {
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly steps: ProgressionTableStep[]
    ){ }

    public static fromJson(o: any): ProgressionTableRank {
        return new ProgressionTableRank(
            o.id,
            o.name,
            (<any[]>o.steps).map(ProgressionTableStep.fromJson)
        );
    }
}

export class OverlappingStep {
    constructor(
        public readonly a: RankAndStepId,
        public readonly b: RankAndStepId
    ){}
    public static fromJson(o: any): OverlappingStep{
        return new OverlappingStep(
            RankAndStepId.fromJson(o.a),
            RankAndStepId.fromJson(o.b));
    }
}

export class RankAndStep {
    constructor(
        public readonly rank: string,
        public readonly step: number){}
    public static fromJson(o: any): RankAndStep {
        return new RankAndStep(o.rank, o.step);
    }
}

export class TitleRankAndStepId {
    constructor(
        public readonly titleId: number,
        public readonly rankId: number,
        public readonly stepId: number){}
    public static fromJson(o: any): TitleRankAndStepId {
        return new TitleRankAndStepId(o.titleId, o.rankId, o.stepId);
    }
}

export class TitleRankStepAndTermId {
    constructor(
        public readonly titleId: number,
        public readonly rankId: number,
        public readonly stepId: number,
        public readonly termId: TermId){}
    public static fromJson(o: any): TitleRankStepAndTermId {
        return new TitleRankStepAndTermId(o.titleId, o.rankId, o.stepId, o.termId);
    }
    public toTitleRanksAndStepId() {
        return new TitleRankAndStepId(this.titleId, this.rankId, this.stepId);
    }
}

export class RankAndStepId {
    constructor(
        public readonly rankId: number,
        public readonly stepId: number){}
    public static fromJson(o: any): RankAndStepId {
        return new RankAndStepId(o.rankId, o.stepId);
    }
}

export class ProgressionTableStepRelationship {
    constructor(
        public readonly withRankAndStep: RankAndStep,
        public readonly type: string
    ){}
    public static fromJson(o: any): ProgressionTableStepRelationship {
        return new ProgressionTableStepRelationship(
            RankAndStep.fromJson(o.withRankAndStep),
            ProgressionTableStepRelationshipType.fromJson(o.type)
        );
    }
}

class ProgressionTableStepRelationshipType 
{
    public static fromJson(o: any): string {
        switch(o.case){
            case "Overlaps":        return "overlaps";
            case "IsOverlappedBy":  return "is overlapped by";
            case "PromotesTo":      return "promotes to";
            case "PromotesFrom":    return "promotes from";
            default: return o;
        }
    }
}

export class ProgressionTableStep {
    constructor(
        public readonly id: number,
        public readonly determination: any,
        public readonly relationships: ProgressionTableStepRelationship[],
        public readonly durationInMonths: number
    ){}
    public static fromJson(o: any): ProgressionTableStep {
        return new ProgressionTableStep(
            o.id,
            ProgressionTableStep.determinationFromJson(o.determination),
            (<any[]>o.relationships).map(ProgressionTableStepRelationship.fromJson),
            Option.fromJsonFSharp(o.durationInMonths, j => j).getValueOrDefault(null)
        );
    }

    private static determinationFromJson(o: any): any {
        switch(o.case){
            case 'CurrentStep': return 'Current Step';
            case 'Regression':  return 'Regression';
            case 'Unreachable': return 'Unreachable';
            case 'Advancement': 
                let advancementType = o.fields[0].type.case;
                let advancementSituation = '';
                Option
                .fromJsonFSharp(o.fields[0].exceptionalTiming, o => o)
                .doWithValue(timing => {
                    switch(timing.case){
                        case 'MonthRule':
                            advancementSituation = `${timing.fields[0]}mo`;
                            break;
                        case 'Acceleration':
                            advancementSituation = 'Acc.'
                            break;
                    }
                });
                let eligible = o.fields[0].eligible ? '(E)' : '';
                return (advancementSituation + ' ' + advancementType + ' ' + eligible).trim();
        }
    }
}

export class PositionFacts {
    constructor(
        public readonly titleId: number,
        public readonly rankId: number,
        public readonly stepId: number,
        public readonly monthsServedInCurrentTitle: number,
        public readonly monthsServedInCurrentRank: number,
        public readonly monthsServedInCurrentStep: number
    ){ }
}

export class CommitteeViewModel {
    constructor(
        public readonly year: number,
        public readonly committees: Committee[]
    ){}
}

export class Committee {
    constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly members: IdAndName[]
    ){}
}