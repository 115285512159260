import { County } from '../county';
import { FTE } from './fte';

export class CountyAssignment
{
	constructor(
		public readonly county: County,
		public readonly isDirector: boolean,
		public readonly isHeadquarters: boolean,
		public fte: FTE){ }

	public static fromJson(o: any): CountyAssignment {
		return new CountyAssignment(
			County.fromJson(o.county),
			o.isDirector,
			o.isHeadquarters,
			FTE.fromJson(o.fte));
	}
}
