<div mat-dialog-title>
  {{title}}
</div>

<mat-dialog-content style="display:unset;">
  <embed type="application/json" width="100%" height="100%" [src]="objectUrl">
</mat-dialog-content>

<mat-dialog-actions>
	<button (click)="close()" class="btn btn-link" [mat-dialog-close]="true">
		Close
	</button>
</mat-dialog-actions>