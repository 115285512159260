import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'romanNumeral'
})
export class RomanNumeralPipe implements PipeTransform {
  transform(value: any, args?: any): any {
      return createRomanNumeral(parseInt(value));
  }
}

var numerals: string[][] = [
    ["", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"], // ones
    ["", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC"], // tens
    ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM"], // hundreds
    ["", "M", "MM", "MMM"] // thousands
];

/// <summary>
/// Supports integers between 1 and 9999.  All other integers are returned verbatim.
/// </summary>
/// <param name="n">The integer to be converted to Roman Numerals</param>
/// <returns></returns>
export function createRomanNumeral(n: number): string
{
    if(n <= 0 || n > 9999)
    {
        return n.toString();
    }
    var digits = n.toString().split('').reverse();
    var romanNumeral = "";
    var i = digits.length;
    while (i > 0)
    {
        i--;
        let digit = parseInt(digits[i]);
        romanNumeral += numerals[i][digit];
    }
    return romanNumeral;
}