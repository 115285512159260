<nav class="sticky-top">
	<nav class="navbar navbar-inverse" style="background: #182c52;">
		<div class="row">
			<div class="col-md-6">
				<a class="navbar-brand mb-0" [routerLink]="navPath">
					<img src="assets/UCANR_rect_white.png" width="230"
					title="UNIVERSITY OF CALIFORNIA, Agriculture and Natural Resources" alt="UNIVERSITY OF CALIFORNIA, Agriculture and Natural Resources">
				</a>
			</div>

			<div class="col-md-6 navbar-text text-right" style="color: white;">
				<ul class="nav justify-content-end">
					<!--
					<li class="nav-item">
						<i class="fa fa-question-circle-o" style="float: left; margin: 0 10px; font-size: 1.5em;"></i>
						Help
					</li>

					<li class="nav-item">
						<i class="fa fa-search"></i>&nbsp;
						Search People
					</li>
					-->

					<li class="nav-item">
						<user-menu></user-menu>
					</li>
				</ul>
			</div>
		</div>
	</nav>

	<nav class="navbar" style="background:white; box-shadow: 0 2px 4px #cfd0d7; margin-bottom:1em;">
		<div [class]="containerClass">
			<div class="row">
				<div class="col-lg-2">
					<a class="navbar-brand mb-0" [routerLink]="navPath">
						{{sectionTitle}}
					</a>
				</div>

				<div class="col-lg-10">
					<div style="display:flex; justify-content: center;">
						<ul class="nav nav-ribbon-gold" style="margin-bottom: -8px;" *ngIf="navLinks.length > 0">
							<li class="nav-item" *ngFor="let link of navLinks">
								<ng-container [ngSwitch]="link.url == 'SPECIAL___ACADEMIC_REPORT_SUBMISSION'">

									<ng-container *ngSwitchCase="true">
										<academic-report-submission 
											type="ThemesProjectsActivitiesCampusMMP"
											reportName="organizational"
											calendarName="federal fiscal year"
											dueDateWarning="Submissions after this date *will not* be included in the final report."
											btnClass="btn-sm"
											class="ml-3"
											style="position: relative; top: 8px;"></academic-report-submission>
									</ng-container>

									<a *ngSwitchCase="false"
										#NavigationLink
										[routerLink]="link.url"
										class="nav-link"
										[class.active]="urlIsActive(link.url)">
										<i [ngClass]="link.iconClass"></i>&nbsp;{{link.label}}
									</a>
								</ng-container>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</nav>
</nav>