import { ActivitySubTypeId, AllActivitySubTypeIds } from "..";
import { Theme, Activity, SelectThemeItems } from ".";

export class ExportStatus {
	constructor(
		public readonly InvalidActivityCount: number,
		public readonly InvlidReasonCount: number,
		public readonly Message: string
	) {}
}

export class Dossier {
    constructor(
        public readonly Themes: Theme[],
		public readonly IndependentActivities: Map<ActivitySubTypeId, Activity[]>,
		public readonly ExportStatus: ExportStatus
    ) { }

    public static fromJson(o: any): Dossier {
        //console.log("Dossier.fromJson: ", o);
        return new Dossier(
            (<any[]>o.themes).map(Theme.fromJson),
            new Map<ActivitySubTypeId, Activity[]>(
                AllActivitySubTypeIds.map(
                    id => [
                        id, 
                        (<any[]>o.independentActivities[ActivitySubTypeId[id]]).map(Activity.fromJson) 
                    ] as [ActivitySubTypeId, Activity[]]
                )
			),
			new ExportStatus(o.exportStatus.invalidActivityCount, o.exportStatus.invalidReasonCount, o.exportStatus.message)
        );
    }

    public dto(trim: boolean): SelectThemeItems {
		let themeIds = [];
		let projectIds = [];
		let activityIds = [];
		// add activity ID's from independat activities from dossier since this form does not manage these. For some reason this needs the null check.
		if(!!this.IndependentActivities){
			this.IndependentActivities.forEach(activities => 
			{
				activities.forEach(act => {
					if(act.includeInDossier){
						activityIds.push(act.id);
					}
				});
			});
			this.Themes.forEach(theme => {
				if(theme.includeInDossier){
					themeIds.push(theme.id);
				} else if(trim){
					return;
				}
				theme.themeActivities.forEach(activity => 
				{
					if(activity.includeInDossier){
						activityIds.push(activity.id);
					}
				});
				theme.projects.forEach(project => 
				{
					if(project.includeInDossier){
						projectIds.push(project.id);
					} else if(trim){
						return;
					}
					project.activities.forEach(activity => 
					{
						if(activity.includeInDossier){
							activityIds.push(activity.id);
						}
					});
				});
			});
		}
		return new SelectThemeItems(trim, themeIds, projectIds, activityIds);
	}
}