<div mat-dialog-title>
	Editing Condition Change FTE 
	<!-- <small class="ml-3">
		(<current-state-fiscal-year dateFormat="longDate" separator=" through "></current-state-fiscal-year>)
	</small> -->
</div>

<mat-dialog-content style="padding:0 !important;">
	<div class="my-3 text-sm">
		<div class="row" style="margin:0 !important;">
			<div class="col-6">
				<h5>Instructions</h5>
				<p>
					First, select each of the program areas(s) that you work in. Then for each program area, select the condition changes that you worked towards and enter a percent of time for each condition change.
					The condition change(s) you use should relate to the outcome(s) that you have measured or plan to measure.
					Consider selecting at most 3 condition changes. 
				</p>
			</div>
			<div class="col-6">
				<h5>How this information will be used</h5>
				<p>
					The FTE you provide will determine how you are displayed on UC ANR's Programmatic Footprint Maps. Additionally, the condition changes and aggregated effort associated with each condition change 
					will be used to determine if ANR has sufficient capacity working towards the changes needed to achieve our mission and vision. They can also help you, as an individual, to identify priorities for directing your own effort.
				</p>
			</div>
		</div>

	</div>

	<div>
		<div [formGroup]="form">
			<table class="table table-bordered table-flush-except-top">
				<thead>
					<tr>
						<th>&nbsp;</th>
						<th>
							<span [matTooltip]="StaticText.ProgramAreaToolTip">
								Program Area
								<i class="fa fa-fw fa-info-circle text-muted"></i>
							</span>
						</th>
						<th>
							<span matTooltip="Condition changes represent broad environmental, health, or economic benefits at a societal level, recognizing that UC ANR may be only one contributor towards these long-term outcomes.">
								Condition Change
								<i class="fa fa-fw fa-info-circle text-muted"></i>
							</span>
						</th>
						<th style="width:8em;">
							<span [matTooltip]="tooltipSvc.YearlyFTETooltip('working towards ANR condition changes')">
								Yearly FTE
								<i class="fa fa-fw fa-info-circle text-muted"></i>
							</span>
						</th>
						<th style="width:8em;">&nbsp;</th>
					</tr>
				</thead>
				<tbody formArrayName="programAreas">
					<ng-container *ngFor="let pa of form.get('programAreas')['controls']; index as pa_index" [formGroupName]="pa_index">
						<tr *ngFor="let pair of pa.controls.pairs.controls; index as pair_index">
							<ng-container *ngIf="pair_index == 0">
								<td [attr.rowspan]="pa.controls.pairs.controls.length + 1">
									<button class="btn btn-link text-danger btn-sm"
									(click)="form.controls.programAreas.removeAt(pa_index)"
									matTooltip="Remove this program area and related condition changes">
										Remove
									</button>
								</td>
								<td [attr.rowspan]="pa.controls.pairs.controls.length + 1">
									<select formControlName="programAreaId" class="form-control form-control-sm"
										[title]="programAreaNameById[form.value.programAreas[pa_index].programAreaId] || ''">
										<option [value]="null" disabled>
											- Select a Program Area -
										</option>
										<option *ngFor="let pa of availableProgramAreas(pa_index)" [value]="pa.id" [disabled]="pa.disabled" [class]="programAreaClass(pa.disabled)">
											{{pa.name}} <span *ngIf="pa.disabled">(No longer active)</span>
										</option>
									</select>
								</td>
							</ng-container>
							<ng-container formArrayName="pairs">
								<ng-container [formGroupName]="pair_index">
									<td>
										<select formControlName="conditionChangeId" class="form-control form-control-sm"
										placeholder="Condition Change"
										[title]="conditionChangeNameById[form.value.programAreas[pa_index].pairs[pair_index].conditionChangeId] || ''">
											<option [value]="null" disabled>
												- Select a Condition Change -
											</option>
											<option *ngFor="let cc of availableConditionChanges(pa_index, pair_index)" [value]="cc.id" [title]="cc.name">
												<ng-container *ngIf="cc.userDefined">(User-defined)</ng-container>
												{{cc.name}}
											</option>
										</select>
									</td>
									<td>
										<div class="input-group input-group-sm">
											<input type="number" formControlName="fte"
												min="0" step="1" max="100" class="form-control"
												placeholder="FTE">
											<span class="input-group-addon">%</span>
										</div>
									</td>
									<td class="text-center">
										<button class="btn btn-link text-danger btn-sm" 
										(click)="pa.controls.pairs.removeAt(pair_index)"
										[disabled]="pa.controls.pairs.controls.length == 1"
										matTooltip="Remove this condition change">
											Remove
										</button>
									</td>
								</ng-container>
							</ng-container>
						</tr>
						<tr>
							<td colspan="3">
								<button
								class="btn btn-link btn-sm"
								(click)="addConditionChangeRow(form.controls.programAreas.controls[pa_index].controls.pairs)">
									+ Add a condition change
								</button>

								<button
								class="btn btn-link btn-sm"
								(click)="defineNewConditionChange(form.controls.programAreas.controls[pa_index].controls.pairs)"
								[matTooltip]="StaticText.UserDefinedConditionChangeTooltip">
									+ Define a new condition change
								</button>
							</td>
						</tr>
					</ng-container>
					<tr>
						<td>&nbsp;</td>
						<td colspan="4">
							<button class="btn btn-sm btn-link" (click)="addProgramAreaRow()">
								+ Add a Program Area
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="row">
		<div class="col-md-4 text-left"></div>
		<div class="col-md-4">
			<form class="form-inline">
				<div class="input-group">
					<label class="mr-2">
						Total FTE:
					</label>
					<div class="input-group input-group-white">
						<input type="text" [value]="(sum | number : '1.0-0') + '%'"
						class="form-control" disabled>

						<div class="input-group-addon" [ngSwitch]="validSum">
							<span *ngSwitchCase="true" matTooltip="Great job!">
								<i class="fa fa-fw fa-check text-success"></i>
							</span>
							<span *ngSwitchCase="false" matTooltip="Total FTE must equal 100%">
								<i class="fa fa-fw fa-warning text-warning"></i>
							</span>
						</div>
					</div>
				</div>
			</form>
		</div>

		<div class="col-md-4">
			<button (click)="cancel()" class="btn btn-link" [disabled]="closing">
				Cancel
			</button>
			<button (click)="save()" class="btn btn-primary" [disabled]="closing || !validSum">
				Save
			</button>
		</div>
	</div>
</mat-dialog-actions>
