<form [formGroup]="form" novalidate nohotkey>
	<div class="{{formGroupClass}}">
		<label class="{{labelClass}}" [matTooltip]="getDescriptionToolTip()">
			{{subType.nameLabel}}
			<span class="{{labelAnnotationClass}} text-xs">
				<i class="fa fa-fw fa-info-circle"></i>
				<br>
				(Required)
			</span>
		</label>
		<div class="{{formControlClass}}">
			<textarea autoGrow class="form-control form-control-for-name"
				formControlName="name"
				[placeholder]="subType.nameLabel" aria-label="Name"></textarea>
		</div>
	</div>

	<div class="{{formGroupClass}}">
		<label class="{{labelClass}}">Type</label>
		<div class="{{formControlClass}}">
			<select class="form-control form-control-sm" formControlName="subTypeId" aria-label="Type" role="menu">
				<optgroup *ngFor="let type of types.all" [label]="type.name">
					<option *ngFor="let st of type.subTypes" [ngValue]="st.id" [disabled]="!st.allowNewActivities">
						{{st.name}}
						<ng-container *ngIf="!st.allowNewActivities">(Disabled)</ng-container>
					</option>
				</optgroup>
			</select>
		</div>
	</div>

	<div class="{{formGroupClass}}">
		<label class="{{labelClass}}" matTooltip="If you don't know the exact date (or there isn't one), choose the 1st of the approximate starting month">
			Date 
			<span class="{{labelAnnotationClass}} text-xs">
				<i class="fa fa-fw fa-info-circle"></i>
				<br>
				(Required)
			</span>
		</label>
		<div class="{{formControlClass}}">
			<div class="row">
				<div class="col-4 input-group input-group-sm">
					<select class="form-control form-control-sm align-select-with-date-picker" formControlName="dateTypeId">
						<option [value]="'Single'">Single</option>
						<option [value]="'Range'">Range</option>
						<option [value]="'Ongoing'">Ongoing</option>
					</select>
				</div>
				<ng-container [ngSwitch]="form.value.dateTypeId">
					<ng-container *ngSwitchCase="'Single'">
						<div class="col-4 input-group input-group-sm no-left-padding">
							<input [matDatepicker]="startDatePicker" placeholder="mm/dd/yyyy" class="form-control form-control-sm no-padding"
								formControlName="startDate"
								(dateChange)="dateChange('startDate',$event)">
							<mat-datepicker #startDatePicker startView="year" [touchUi]="touchEnabled"></mat-datepicker>
							<mat-datepicker-toggle [for]="startDatePicker" class="input-group-addon no-padding mat-icon-overide"></mat-datepicker-toggle>
						</div>
					</ng-container>
					<ng-container *ngSwitchCase="'Ongoing'">
						<div class="col-4 input-group input-group-sm no-left-padding">
							<input [matDatepicker]="startDatePicker" placeholder="mm/dd/yyyy" class="form-control form-control-sm no-padding"
								formControlName="startDate"
								(dateChange)="dateChange('startDate',$event)">
							<mat-datepicker #startDatePicker startView="year" [touchUi]="touchEnabled"></mat-datepicker>
							<mat-datepicker-toggle [for]="startDatePicker" class="input-group-addon no-padding mat-icon-overide"></mat-datepicker-toggle>
						</div>
					</ng-container>
					<ng-container *ngSwitchCase="'Range'">
						<div class="col-4 input-group input-group-sm no-left-padding">
							<input [matDatepicker]="startDatePicker" placeholder="mm/dd/yyyy" class="form-control form-control-sm no-padding"
								formControlName="startDate"
								(dateChange)="dateChange('startDate',$event)">
							<mat-datepicker #startDatePicker startView="year" [touchUi]="touchEnabled"></mat-datepicker>
							<mat-datepicker-toggle [for]="startDatePicker" class="input-group-addon no-padding mat-icon-overide"></mat-datepicker-toggle>
						</div>
						<div class="col-4 input-group input-group-sm no-left-padding">
							<input [matDatepicker]="endDatePicker" placeholder="mm/dd/yyyy" class="form-control form-control-sm no-padding"
								formControlName="endDate"
								(dateChange)="dateChange('endDate',$event)">
							<mat-datepicker #endDatePicker startView="year" [touchUi]="touchEnabled"></mat-datepicker>
							<mat-datepicker-toggle [for]="endDatePicker" class="input-group-addon no-padding mat-icon-overide"></mat-datepicker-toggle>
						</div>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</div>

	<div *ngIf="subType.properties['RepetitionCount'] != Presence.NA" class="{{formGroupClass}}">
		<label class="{{labelClass}}" matTooltip="Per the E-book, If you hold large numbers of meetings of similar purpose in which your role remains constant (e.g. you are teaching the same content, facilitating the agenda, etc.) you may consolidate them using a collective time frame, title, description, role, general location (usually your county), and a total number in attendance. Multi-day events with the same participants should have a Repetition Count of 1.">
			Repetition Count 
			<span class="{{labelAnnotationClass}} text-xs">
				<i class="fa fa-fw fa-info-circle"></i>
				<br>
				({{presenceLabel('RepetitionCount')}})
			</span>
		</label>
		<div class="{{formControlClass}}">
			<input class="form-control form-control-sm"
					type="number"
					placeholder="Enter Times Repeated"
					formControlName="repetitions"
					aria-label="Times Repeated"
					role="number">
		</div>
	</div>

	<div *ngIf="subType.properties['InstanceCount'] != Presence.NA" class="{{formGroupClass}}">
		<label class="{{labelClass}}" matTooltip="Per the E-Book, Do not list every individual 'blog and tweet' – summarize.  Example: For Twitter activity with 25 tweets: Instance Count=25">
			Instance Count 
			<span class="{{labelAnnotationClass}} text-xs">
				<i class="fa fa-fw fa-info-circle"></i>
				<br>
				({{presenceLabel('InstanceCount')}})
			</span>
		</label>
		<div class="{{formControlClass}}">
			<input class="form-control form-control-sm"
					type="number"
					placeholder="Enter Number of Instances"
					formControlName="instances"
					aria-label="Number of Instances"
					role="number">
		</div>
	</div>

	<div *ngIf="subType.properties['Parent'] != Presence.NA" class="{{formGroupClass}}">
		<label class="{{labelClass}}">
			Theme / Project 
			<div class="{{labelAnnotationClass}} text-xs">
				({{presenceLabel('Parent')}})
			</div>
		</label>
		<div class="{{formControlClass}}" [matTooltip]="getParentName" [matTooltipShowDelay]="500">
			<activity-parent-picker
					formControlName="parent"
					controlClass="form-control-sm"
					inputGroupClass="input-group-sm"></activity-parent-picker>
		</div>
	</div>

	<ng-container *ngIf="isProjectSelected()">
		<div *ngIf="subType.properties['Parent'] != Presence.NA" class="{{formGroupClass}}">
			<label class="{{labelClass}}" [matTooltip]="StaticText.ProgramAreaToolTip">
				Program Area 
				<span class="{{labelAnnotationClass}} text-xs">
					<i class="fa fa-fw fa-info-circle"></i>
					<br>
					(from linked Project)
				</span>
			</label>
			<div class="{{formControlClass}}">
				<input class="form-control-sm form-control" value="{{form.value.parent.programArea}}" readonly="">
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="!isProjectSelected()">
		<div *ngIf="subType.properties['Parent'] != Presence.NA" class="{{formGroupClass}}">
			<label class="{{labelClass}}" [matTooltip]="StaticText.ProgramAreaToolTip">
				Program Area 
				<span class="{{labelAnnotationClass}} text-xs">
					<i class="fa fa-fw fa-info-circle"></i>
					<br>
					<ng-container *ngIf="isThemeSelected()">
						(Required)
					</ng-container>
				</span>
			</label>
			<div class="{{formControlClass}}">
				<select formControlName="programAreaId" class="form-control">
					<option *ngFor="let pa of programAreas" [ngValue]="pa.id" [disabled]="pa.disabled" [class]="programAreaClass(pa.disabled)">
						{{pa.name}} <span *ngIf="pa.disabled">(No longer active)</span>
					</option>
				</select>
			</div>
		</div>
	</ng-container>

	<div *ngIf="subType.properties['Topic'] != Presence.NA" class="{{formGroupClass}}">
		<label class="{{labelClass}}" matTooltip="Topic will be included in the Extension Activities table in your Dossier.  This may or may not be the same as the meeting name.  For bigger meetings, your presentation might have its own topic.">
			Topic
			<span class="{{labelAnnotationClass}} text-xs">
				<i class="fa fa-fw fa-info-circle"></i>
				<br>
				({{presenceLabel('Topic')}})
			</span>
		</label>
		<div class="{{formControlClass}}">
			<input class="form-control form-control-sm"
				placeholder="Topic Name"
				formControlName="topic"
					type="text"
					aria-label="Topic Name"
					role="text">
		</div>
	</div>

	<div *ngIf="subType.properties['Role'] != Presence.NA" class="{{formGroupClass}}">
		<label class="{{labelClass}}" [matTooltip]="StaticText.ActivityRoles">
			Role(s) 
			<span class="{{labelAnnotationClass}} text-xs">
				<i class="fa fa-fw fa-info-circle"></i>
				<br>
				({{presenceLabel('Role')}})
			</span>
		</label>
		<div class="{{formControlClass}}">
			<textarea autoGrow class="form-control form-control-sm form-control-border-bottom-only"
				formControlName="role"
				placeholder="Your Role" aria-label="Role"></textarea>
		</div>
	</div>

	<div *ngIf="subType.properties['Location'] != Presence.NA" class="{{formGroupClass}}">
		<label class="{{labelClass}}" matTooltip="An informal description of the activity's location.  Note: There is no need to use an address. Examples:  Fresno Farmers Market, Muir Woods, Hopland REC, UC Davis">
			Location 
			<span class="{{labelAnnotationClass}} text-xs">
				<i class="fa fa-fw fa-info-circle"></i>
				<br>
				({{presenceLabel('Location')}})
			</span>
		</label>
		<div class="{{formControlClass}}">
			<input type="text" class="form-control form-control-sm" formControlName="location" placeholder="Location">
		</div>
	</div>

	<div *ngIf="subType.properties['AreaOfService'] != Presence.NA" class="{{formGroupClass}}">
		<label class="{{labelClass}}" matTooltip="Choose the most applicable area of service">
			Org. Level 
			<span class="{{labelAnnotationClass}} text-xs">
				<i class="fa fa-fw fa-info-circle"></i>
				<br>
				({{presenceLabel('AreaOfService')}})
			</span>
		</label>
		<div class="{{formControlClass}}">
			<select class="form-control form-control-sm" formControlName="areaOfService" aria-label="Org Level" role="menu">
				<optgroup *ngFor="let category of Object.keys(getAreasOfService())" [label]="category">
					<option *ngFor="let aos of getAreasOfService()[category]" [ngValue]="aos">
						{{aos}}
					</option>
				</optgroup>
			</select>
		</div>
	</div>

	<div *ngIf="subType.properties['WrittenBy'] != Presence.NA" class="{{formGroupClass}}">
		<label class="{{labelClass}}">
			Interviewed or Written By 
			<div class="{{labelAnnotationClass}} text-xs">
				({{presenceLabel('WrittenBy')}})
			</div>
		</label>
		<div class="{{formControlClass}}">
			<textarea autoGrow class="form-control form-control-sm form-control-border-bottom-only"
				formControlName="writtenBy"
				placeholder="Interviewed or Written By" aria-label="Interviewed or Written By"></textarea>
		</div>
	</div>

	<div *ngIf="subType.properties['Tags'] != Presence.NA" class="{{formGroupClass}}">
		<label class="{{labelClass}}">
			Tags 
			<div class="{{labelAnnotationClass}} text-xs">
				({{presenceLabel('Tags')}})
				<span class="text-muted">({{form.value.tags.count}})</span>
			</div>
		</label>
		<div class="{{formControlClass}}">
			<tag-picker
				formControlName="tags"
				[tagTypes]="availableTagTypes"
				controlClass="form-control-sm"></tag-picker>
		</div>
	</div>

	<ng-container *ngIf="subType.properties['Collaborators'] != Presence.NA">
		<div class="{{formGroupClass}}">
			<label class="{{labelClass}}" matTooltip="Collaboration tip: enter all required data (especially attendance) before adding collaborators.  This will reduce duplication of effort.">
				ANR Collaborators
				<i class="fa fa-fw fa-info-circle"></i>
				<div class="{{labelAnnotationClass}} text-xs">
					<span class="text-muted">({{form.value.ANRCollaborators.count}})</span>
				</div>
			</label>
			<div class="{{formControlClass}}">
				<ng-container [ngSwitch]="isCollaboration()">
					<ng-container *ngSwitchCase="false">
						<user-picker
							formControlName="ANRCollaborators"
							enableNonANRCollaborator="true"
							(newNonAnrCollaboratorRequest)="nonANRCollaborators.create($event)"
							controlClass="form-control-sm"></user-picker>
					</ng-container>
					<ng-container *ngSwitchCase="true">
						<div class="text-sm">
							This activity was added via a collaboration with
						</div>
						<div class="media mb-3 mt-3">
							<div class="pull-left">

								<img appUserAvatar="{{getActivityCollaborationOriginal().owner.id}}" [userName]="getActivityCollaborationOriginal().owner.name"
									style="width: 40px;"
									class="img-round img-fluid user-avatar-sm mr-2">

							</div>
							<div class="media-body text-sm">
								{{getActivityCollaborationOriginal().owner.name}}
								<div class="text-muted">
									{{getActivityCollaborationOriginal().title}}
								</div>
							</div>
						</div>
					</ng-container>
				</ng-container>
			</div>
		</div>

		<div class="{{formGroupClass}}">
			
			<button class="btn btn-sm btn-secondary pull-right" (click)="nonANRCollaborators.create()" *ngIf="!wideLayout">
				+ Add New
			</button>
			<label class="{{labelClass}}">	
				Non-ANR Collaborators
				<div class="{{labelAnnotationClass}} text-xs">
					<span class="text-muted">({{form.value.nonANRCollaborators.length}})</span>
				</div>
			</label>
			<div class="{{formControlClass}}">
				<non-anr-collaborator-form-control 
					formControlName="nonANRCollaborators"
					#nonANRCollaborators="NonANRCollaboratorFormControl"></non-anr-collaborator-form-control>
				<button class="btn btn-sm btn-secondary pull-right" (click)="nonANRCollaborators.create()" *ngIf="wideLayout">
					+ Add New
				</button>
				<div style="clear:both;"></div>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="subType.properties['ClienteleContacts'] != Presence.NA">
		<hr class="hr-card-wide">
		<div class="{{formGroupClass}}">
			
			<label class="{{labelClass}}">
				Audience Attendance
				<span [matTooltip]="ContactsTooltip" class="{{labelAnnotationClass}} text-xs">
					<i class="fa fa-fw fa-info-circle"></i>
					<br>
					(Civil Rights Compliance &amp; Dossier)
				</span>
				<div [matTooltip]="AttendanceTypesTooltip" class="{{labelAnnotationClass}} text-xs">
					<i class="fa fa-fw fa-info-circle"></i>
					Attendance Types
				</div>
				<!-- ({{Presence[subType.properties['ClienteleContacts']]}}) -->
			</label>
			<div class="{{formControlClass}} float-right">
				<mat-button-toggle-group formControlName="clienteleContactsType" vertical>
					<mat-button-toggle [value]="OutreachTypes.external" (click)="changeOutreach(OutreachTypes.external)">
						External Audience
					</mat-button-toggle>
					<mat-button-toggle [value]="OutreachTypes.internal" (click)="changeOutreach(OutreachTypes.internal)">
						Internal / Statewide Program
					</mat-button-toggle>
					<mat-button-toggle [value]="OutreachTypes.noReport" (click)="changeOutreach(OutreachTypes.noReport)">
						Choose not to report
					</mat-button-toggle>
				</mat-button-toggle-group>
			</div>
		</div>

		<div [hidden]="!viewExternalOutreach(form.value.clienteleContactsType)">
			<hr class="hr-dashed" *ngIf="subType.properties['ClienteleContacts'] != Presence.Required"> <!-- Hide the hr when required cuz it's ugly with 2 hr's one after the other -->
			<div class="{{formGroupClass}}">
				
				<label class="{{labelClass}}">
					Clientele Group
				</label>
				<div class="{{formControlClass}}">
					<clientele-group-picker formControlName="clienteleGroup"></clientele-group-picker>
				</div>
			</div>
		</div>

		<div formGroupName="clienteleContacts" *ngIf="isClienteleGroupSelected() && viewExternalOutreach(form.value.clienteleContactsType)" class="{{formGroupClass}}">

			<label class="{{labelClass}}" *ngIf="wideLayout">
				Demographic Groups
			</label>

			<div class="{{formControlClass}}">
				<div *ngFor="let type of applicableDemographicTypes(form.value.clienteleGroup.type)" class="mb-2">
					<div *ngIf="type.id!='40'"[ngSwitch]="type.id" class="">
						<label class="text-sm" *ngSwitchCase="'Ethnicity'" matTooltip="Federal guidance for aggregating race and ethnicity: When recording an ethnically Hispanic contact, count them under the 'Race not listed above' race, regardless of any race they may have claimed.">
							{{type.label}} ({{contactSum(type)}} | {{contactSumAll(type)}})
							<i class="fa fa-fw fa-info-circle" matTooltip="Sum w/o Prefer not to state/Undetermined | Total Sum" ></i>
						</label>
						<label class="text-sm" *ngSwitchDefault>
							{{type.label}} ({{contactSum(type)}} | {{contactSumAll(type)}})
							<i class="fa fa-fw fa-info-circle" matTooltip="Sum w/o Prefer not to state/Undetermined | Total Sum" ></i>
						</label>
					</div>
					<div class="mt-1">
						<div class="form-group form-group-tight" *ngFor="let d of type.demographics">
							<div class="media">
								<div class="pull-left">
									<input class="form-control form-control-sm mr-2 text-center"
										type="number"
										style="width:120px;"
										[formControlName]="d.id"
										min="0"
										numericOnly>
								</div>
								<label class="media-body text-sm">
									<ng-container *ngIf="d.id==='Organization' && tooltipSvc.tooltip('OrganizationCountLabel'); else defaultLabel">
										{{ tooltipSvc.tooltip('OrganizationCountLabel') }}
									</ng-container>
									<ng-template #defaultLabel>
										{{ d.label }}
									</ng-template>
									<i *ngIf="!!d.description"
										class="fa fa-fw fa-info-circle text-muted"
										[matTooltip]="d.description"></i>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div [hidden]="!viewInternalOutreach(form.value.clienteleContactsType)">
			<div class="{{formGroupClass}}">
				<label class="{{labelClass}}">
					Total Number of Attendees
				</label>
				<div class="{{formControlClass}}">
					<input class="form-control form-control-sm"
						type="number"
						formControlName="internalAttendees"
						placeholder="# Attendees" 
						aria-label="Total Number of Attendees"
						min="0"
						role="number"
						numericOnly>
				</div>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="subType.properties['TargetedOutreach'] != Presence.NA">
		<hr class="hr-card-wide">
		<div class="{{formGroupClass}}">
			
			<label [matTooltip]="EffortTooltip" class="{{labelClass}}">
				A.R.E. Effort
				<span class="{{labelAnnotationClass}} text-xs">
					<i class="fa fa-fw fa-info-circle"></i>
					<br>
					(Civil Rights Compliance)
				</span>
				<!-- ({{Presence[subType.properties['TargetedOutreach']]}}) -->
			</label>
			<div class="{{formControlClass}} float-right">
				<mat-button-toggle-group formControlName="targetedOutreachType">
					<mat-button-toggle [value]="OutreachTypes.external" (click)="changeOutreach(OutreachTypes.external)">
						Show
					</mat-button-toggle>
					<mat-button-toggle [value]="OutreachTypes.noReport" (click)="changeOutreach(OutreachTypes.noReport)">
						Hide
					</mat-button-toggle>
				</mat-button-toggle-group>
			</div>
		</div>

		<div [hidden]="!viewExternalOutreach(form.value.targetedOutreachType)">
			<hr class="hr-dashed" *ngIf="subType.properties['TargetedOutreach'] != Presence.Required"> <!-- Hide the hr when required cuz it's ugly with 2 hr's one after the other -->
			<div class="{{formGroupClass}}">
				<label class="{{labelClass}}">
					Clientele Group
				</label>
				<div class="{{formControlClass}}">
					<clientele-group-picker formControlName="clienteleGroup"></clientele-group-picker>
				</div>
			</div>
		</div>

		<div formGroupName="targetedOutreach" *ngIf="isClienteleGroupSelected() && viewExternalOutreach(form.value.targetedOutreachType)" class="{{formGroupClass}}">

			<div [class]="labelClass">
				<label *ngIf="wideLayout">
					Demographic Groups
				</label>
				<div *ngIf="doesDemographicGroupSelectAllApply(form.value.clienteleGroup.type)">
					<div>
						<button class="btn btn-link btn-sm" (click)="selectTargetedOutreachDemographics(true)">
							Select All
						</button>
					</div>
					<div>
						<button class="btn btn-link btn-sm" (click)="selectTargetedOutreachDemographics(false)">
							Select None
						</button>
					</div>
				</div>
			</div>

			<div class="{{formControlClass}}">
				<div *ngFor="let type of applicableDemographicTypes(form.value.clienteleGroup.type)" class="mb-2">
					<ng-container [ngSwitch]="type.label">
						<ng-container *ngSwitchCase="'Other'">
							<div class="text-sm">{{type.label}}</div>
							<div class="ml-2 mt-1">
								<div class="form-check form-check-tight">
									<label class="form-check-label text-sm">
										<input class="form-check-input" 
											type="checkbox"
											disabled="disabled"
											checked="checked">
										Organizations
									</label>
								</div>
							</div>
						</ng-container>
						<ng-container *ngSwitchDefault>
							<div class="text-sm">{{type.label}}</div>
							<div class="ml-2 mt-1">
								<div class="form-check form-check-tight"
								*ngFor="let d of type.targetedOutreachDemographics">
									<label class="form-check-label text-sm">
										<input class="form-check-input" 
											type="checkbox"
											[formControlName]="d.id">
										{{d.label}}
										<i *ngIf="!!d.description"
											class="fa fa-fw fa-info-circle text-muted"
											[matTooltip]="d.description"></i>
									</label>
								</div>
							</div>		
						</ng-container>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-container>
</form>
<!--
<pre>{{form.value|json}}</pre>
-->
