
import {debounceTime} from 'rxjs/operators';
import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import {
	Activity,
	ActivityDate,
   	ActivityType,
   	ActivityTypeCollection,
   	ActivityParent,
   	ActivityParentType,
   	ActivitySubType,
	ActivitySubTypeId,
   	ActivityService,
   	ActivityValuePresence,
   	ActivityProperty,
	ClienteleContactCollection,
	ClienteleGroup,
	ClienteleGroupType,
	CollaboratorInstitutionType,
	DemographicInfo,
	DemographicType,
	Demographic,
	ClienteleService,
	Option,
	ProgramArea,
	SortedSet,
	Tag,
	TagType,
	ActivityTagTypes,
	User,
	NonANRCollaborator,
	ActivityCollaborationOriginal,
	OutReachTypes,
	None,
	ActivityDateType
} from '../../domain';
import { FormGroup, FormControl, FormArray, FormBuilder, Validator, ValidatorFn, AbstractControl } from '@angular/forms';
import { ConsoleService } from '../../widgets/console.service';
import { NonANRCollaboratorModalComponent } from '../non-anr-collaborator-modal/non-anr-collaborator-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatDatepickerInputEvent} from '@angular/material/datepicker';
import { TooltipService } from '../tooltip.service';


@Component({
	selector: 'activity-form-guts',
	templateUrl: './activity-form-guts.component.html',
	styleUrls: ['./activity-form-guts.component.scss']
})
export class ActivityFormGutsComponent implements OnChanges {
	public StaticText: any;
	//tooltipSvc = TooltipService;
	OutreachTypes = OutReachTypes;
	@Input() activity: Activity;
	@Input() caller: string;
	@Input() wideLayout: boolean;
	@Input() types: ActivityTypeCollection;
	@Output() activityChange = new EventEmitter<Activity>();
	@Output() formStatusChange = new EventEmitter<string>();
	
	ContactsTooltip = "Provide the total number in attendance over all repetitions. For multi-day events with a repetition count of 1, provide the total number attendees of the entire event (not for each day).";
	InternalAttendeesTooltip = "Do not report contacts that are internal to ANR (ie. staff, academics). \n\nDo not report contacts already reported in another statewide reporting system. (ie. youth, adult volunteer, contacts from the following programs: 4-H, CalFresh NEP, CalNat, EFNEP, Master Food Preservers, Master Gardeners)";
	AttendanceTypesTooltip = "To report External Audience attendance, click on \"Search Clientele Groups\" then select the clientele group that you want to report against. Attendance by demographic categories is reported for \"individual\" and \"family\" clientele groups is required for civil rights compliance. Civil rights compliance analysis is only performed on external audiences/clientele. Attendance totals populate the dossier export. \n\nTo report Internal / Statewide Program attendance, simply enter the number of attendees. Attendance by demographic groups is not required for individuals that are internal to ANR (e.g., staff, volunteers) and for individuals that are already being reported by demographic group in other reporting systems of Statewide Programs (e.g., 4-H, CalFresh,UC, CalNat, EFNEP, MG, MFP). Civil rights compliance analysis is performed using data outside of Project Board for these groups. Attendance totals populate the dossier export. \n\nIf you do not have attendance data, you can select \"Choose not to report.\" This means that the attendance for this activity will not be included in civil rights compliance analysis or in dossier exports.";
	EffortTooltip = "If this Effort is targeted to a specific demographic in one of your clientele groups please specify, otherwise leave blank.";

	touchEnabled = false;
	formGroupClass = "form-group row";
	labelClass = "col-form-label bold-label col-5";
	labelAnnotationClass = "";
	formControlClass = "col-7";

	programAreas: ProgramArea[] = [];
	availableTagTypes: TagType[] = ActivityTagTypes;
	demographicInfo: DemographicInfo;
	
	get subType(): ActivitySubType {
		return this.types.getSubTypeById(this.form.get('subTypeId').value);
	}


	presenceLabel(key: string): string {
		let p = this.subType.properties[key];
		switch(p){
			case ActivityValuePresence.Required: 
				return "Required for Dossier";
			default:
				return ActivityValuePresence[p];
		}
	}

	Object = Object;
	getAreasOfService = (() => {
		let areasOfService: {[key: number]: {[category: string]: string[]}} = {};
		areasOfService[(ActivitySubTypeId.PublicService)] = {
			"Geographic": [
				"Community",
				"County",
				"Region",
				"State",
				"National",
				"International"
			]
		};
		areasOfService[(ActivitySubTypeId.UniversityService)] = {
			"Geographic": [
				"County",
				"Region",
				"State",
				"National",
				"International"
			],
			"Organizational": [
				"Division-wide",
				"University-wide"
			]
		};
		return () => {
			let st = +this.form.get('subTypeId').value;
			return areasOfService[st] || {};
		};
	})();

	form: FormGroup;
	Presence = ActivityValuePresence;
	CollaboratorInstitutionType = CollaboratorInstitutionType;

	targetedOutreachVisible = false;

	constructor(
		fb: FormBuilder, 
		activitySvc: ActivityService, 
		clienteleSvc: ClienteleService,
		public tooltipSvc: TooltipService,
		private console: ConsoleService,
		private matDialog: MatDialog
	) {
		this.StaticText = tooltipSvc.StaticText;
		activitySvc.getAllProgramAreas().then(pas => { 
			this.programAreas = pas
				.filter(pa => pa.name != "Administration" 
					&& (!pa.disabled 								// removes disabled Program areas
						|| this.activity.programAreaId == pa.id))	// includes selected Program area
				.sort((a,b) => (a.disabled ? 1 : 0) - (b.disabled ? 1 : 0));
		});
		this.demographicInfo = clienteleSvc.getDemographicInfo();

		let targetedOutreach = (() => {
			let g = new FormGroup({});
			this.demographicInfo.targetedOutreachDemographics.forEach(d =>
				g.addControl(d.id, new FormControl(false)));
			return g;
		})();

		let clienteleContacts = (() => {
			let g = new FormGroup({});
			this.demographicInfo.allDemographics.forEach(d =>
				g.addControl(d.id, new FormControl(0)));
			return g;
		})();

		this.form = fb.group({
			subTypeId: '',
			name: '',
			dateTypeId: 'Single',
			startDate: new Date(),
			endDate: null,
			topic: '',
			parent: null,
			programAreaId: null,
			role: '',
			location: null,
			areaOfService: null,
			writtenBy: '',
			repetitions: 1,
			instances: 0,
			tags: new SortedSet<Tag>(),
			ANRCollaborators: new SortedSet<User>(),
			nonANRCollaborators: [],
			clienteleGroup: null,
			targetedOutreachType: new FormControl(OutReachTypes),
			internalAttendees: null,
			targetedOutreach: targetedOutreach,
			clienteleContactsType: new FormControl(OutReachTypes),
			clienteleContacts: clienteleContacts
		});

		this.form.controls['dateTypeId'].valueChanges.forEach(() => {
			switch(this.form.controls['dateTypeId'].value){
				case 'Ongoing':
					this.form.controls['endDate'].setValue(undefined);
					break;
				case 'Single':
					this.form.controls['endDate'].setValue(this.form.controls['startDate'].value);
					break;
			}
		});

		this.form.valueChanges.pipe(
			debounceTime(200))
			.forEach(() => {
				this.formStatusChange.emit(this.form.status);
				if(this.form.status === 'INVALID')
				{
					this.console.error('Form errors:', this.form.errors);
					return;
				}
				/* StartDate/EndDate could be the reason for the form being in an invalid state.
				** To prevent an exception, we only attempt to resolve the new activity value
				** in the case of a *valid* form state. **/
				var activity = this.getActivityFromForm();
				this.console.info('Form Changed: form -> activity:', this.form.value, activity);
				this.activityChange.emit(activity);
			});

		/*this.form.statusChanges
			.debounceTime(200)
			.forEach(() => {
				this.console.log('Form Changed: Status: ', this.form.status);
				this.formStatusChange.emit(this.form.status);
			});*/
	}

	ngOnInit() {
		this.console.log("Wide Layout: ", this.wideLayout);
		this.setLayout();
	}

	setLayout(){
		if (this.wideLayout == null){
			this.wideLayout = false;
		}
		this.console.log("wide Layout(effective): ", this.wideLayout);
		if (this.wideLayout){
			this.formGroupClass = "form-group row";
			this.labelClass = "col-form-label bold-label col-5";
			this.labelAnnotationClass = "";
			this.formControlClass = "col-7";
		} else {
			// use narrow layout
			this.formGroupClass = "form-group";
			this.labelClass = "col-form-label";
			this.labelAnnotationClass = "inline-label-annotation"
			this.formControlClass = "";
		}
	}

	ngOnChanges(changes) {
		if('activity' in changes){
			//let formData = this.getFormDataFromActivity(this.activity);
			
			this.form.setValue(
				this.getFormDataFromActivity(this.activity),
				{emitEvent: false});
		}
	}

	get getParentName() {
		let parent = <ActivityParent|null>this.form.controls.parent.value;
		return parent == null ? "" : parent.name;
	}

	getDescriptionToolTip() {
		return this.tooltipSvc.getDescriptionToolTipBySubTypeId( parseInt(this.form.get('subTypeId').value, 10) );
	}

	getActivityCollaborationOriginal() {
		return this.activity.activityCollaborationOriginal.getValueOrDefault(null);
	}

	isCollaboration() {
		return this.activity.activityCollaborationOriginal.hasValue();
	}

	isProjectSelected(){
		if (!!this.form) {
			//this.console.log('Parent: ', this.form.controls.parent.value);
			let parent = <ActivityParent|null>this.form.controls.parent.value;
			if(!!parent && parent.type == ActivityParentType.Project){
				return true;
			}
		}
		return false;
	}

	programAreaClass(disabled:boolean): string {
		return disabled ? "option-disabled" : "";
	}

	isThemeSelected(){
		if (!!this.form) {
			//this.console.log('Parent: ', this.form.controls.parent.value);
			let parent = <ActivityParent|null>this.form.controls.parent.value;
			if(!!parent && parent.type == ActivityParentType.Theme){
				return true;
			}
		}
		return false;
	}

	isClienteleGroupSelected() {
		if (!!this.form) {
			let cg = <ClienteleGroup|null>this.form.value.clienteleGroup;
			if(!!cg){
				return true;
			}
		}
		return false;
	}

	dateChange(type: string, e: MatDatepickerInputEvent<Date>){
		this.console.info('Date Change: (' + type + ')', e.value);
	}

	onParentChange() {
		this.console.log("P-isThemeSelected: ", this.isThemeSelected());
		if (!!this.form) {
			if (!this.isThemeSelected()){
				this.form.controls.programAreaId.setValue(1);
			} else {
				this.form.controls.programAreaId.setValue(null);
			}
		}

	    return null;
	}

	private getActivityFromForm(){
		let fv = this.form.value;
		let parent  = Option.create<ActivityParent>(<ActivityParent|null>fv.parent);
		let date = null;
		switch(fv.dateTypeId){
			case 'Single':
				date = ActivityDate.create(fv.startDate, fv.startDate, ActivityDateType.Single);
				break;
			case 'Ongoing':
				date = ActivityDate.create(fv.startDate, undefined, ActivityDateType.Ongoing);
				break;
			case 'Range':
				date = ActivityDate.create(fv.startDate, fv.endDate, ActivityDateType.Range);
				break;
		}
		return new Activity(
			this.activity.id,
			fv.subTypeId,
			fv.name,
			date,
			parent,
			fv.topic,
			fv.role,
			fv.location,
			fv.areaOfService,
			fv.writtenBy,
			fv.repetitions,
			fv.instances,
			parent.match(() => <number|null>fv.programAreaId, _ => <number|null>fv.programAreaId),
			Option.create<ClienteleGroup>(fv.clienteleGroup),
			ClienteleContactCollection.fromObject(fv.clienteleContacts),
			this.targetedOutreachDemographicsFromObject(fv.targetedOutreach),
			<SortedSet<Tag>> fv.tags,
			[],
			<SortedSet<User>> fv.ANRCollaborators,
			<NonANRCollaborator[]> fv.nonANRCollaborators,
			fv.internalAttendees,
			Option.create(),
			false
		);
	}

	selectTargetedOutreachDemographics(selected: boolean){
		let newValue = {};
		let to = this.form.value.targetedOutreach;
		for(let k in to){
			newValue[k] = selected;
		}
		this.form.controls['targetedOutreach'].setValue(newValue);
	}

	private targetedOutreachDemographicsFromObject(o){
		let arr = [];
		for(let k in o){
			if(o[k]){
				arr.push(k);
			}
		}
		this.console.log('targetedOutreach from object: ', o, arr);
	   	return arr;	
	}

	private targetedOutreachDemographicsFromArray(arr){
		let data = {};
		this.demographicInfo.targetedOutreachDemographics.forEach(di => 
			data[di.id] = arr.indexOf(di.id) > -1);
		return data;
	}

 	applicableDemographicTypes(selectedGroupType) {
		let sgt = <ClienteleGroupType>(selectedGroupType);
		return this.demographicInfo.applicableTypesByClienteleGroupTypeId.get(sgt);
	}

	doesDemographicGroupSelectAllApply(selectedGroupType) {
		let sgt = <ClienteleGroupType>(selectedGroupType);
		if (sgt == ClienteleGroupType.Organization ) return false;
		return true;
	}

	contactSum(t: DemographicType): number {
		return t.demographics.filter(s => s.includedInSum).reduce((sum, d) =>
				sum + this.form.value.clienteleContacts[d.id], 0);
	}

	contactSumAll(t: DemographicType): number {
		return t.demographics.reduce((sum, d) =>
				sum + this.form.value.clienteleContacts[d.id], 0);
	}	

	viewExternalOutreach(ot: OutReachTypes): boolean {
		return ot == OutReachTypes.external;
	}

	viewInternalOutreach(ot: OutReachTypes): boolean {
		return ot == OutReachTypes.internal;
	}

	clearInternalAttendees() {
		this.console.log("Clearing Internal Attendees.");
		this.form.controls['internalAttendees'].setValue(null);
	}

	clearClienteleContacts() {
		this.console.log("Clearing Clientele Group and Contacts.", this.form.controls['clienteleGroup'].value);
		this.form.controls['clienteleGroup'].setValue(null);
		this.console.log(this.form.controls['clienteleGroup'].value);
		let clienteleContacts = 
			(() => {
				let data = {};
				this.demographicInfo.allDemographics.forEach(di => 
					data[di.id] = 0);
				return data;
			})();
		this.form.controls['clienteleContacts'].setValue(clienteleContacts);
	}

	clearTargetedOutreach() {
		this.console.log("Clearing Clientele Group and Targeted Outreach.");
		this.form.controls['clienteleGroup'].reset(null);
		let targetedOutreach = 
			(() => {
				let data = {};
				this.demographicInfo.targetedOutreachDemographics.forEach(di => 
					data[di.id] = false);
				return data;
			})();
		this.form.controls['targetedOutreach'].setValue(targetedOutreach);
	}

	changeOutreach(ot: OutReachTypes) {
		switch(ot){
			case OutReachTypes.external:
				this.clearInternalAttendees();
				break;
			case OutReachTypes.internal:
				this.clearClienteleContacts();
				this.clearTargetedOutreach();
				break;
			case OutReachTypes.noReport:
				this.clearInternalAttendees();
				this.clearClienteleContacts();
				this.clearTargetedOutreach();
				break;
		}
	}

	private getFormDataFromActivity(d: Activity){
		let targetedOutreach = this.targetedOutreachDemographicsFromArray(d.targetedOutreachDemographics);

		let clienteleContacts = 
			(() => {
				let data = {};
				this.demographicInfo.allDemographics.forEach(di => 
					data[di.id] = d.clienteleContacts.get(di.id) || 0);
				return data;
			})();

		let targetedOutreachRequired = (() => {
			if(!d || !d.subTypeId){
				return false;
			}
			let subType = this.types.getSubTypeById(d.subTypeId);
			return subType.properties['TargetedOutreach'] == ActivityValuePresence.Required;
		})();

		let clienteleContactsRequired = (() => {
			if(!d || !d.subTypeId){
				return false;
			}
			let subType = this.types.getSubTypeById(d.subTypeId);
			return subType.properties['ClienteleContacts'] == ActivityValuePresence.Required;
		})();

		let targetedOutreachExists = d.targetedOutreachDemographics.length > 0;
		let clienteleContactsExists = d.clienteleContacts.any();

		let targetedOutreachTypeId = (() => {
			if (targetedOutreachRequired || targetedOutreachExists) {
				return OutReachTypes.external;
			}
			return OutReachTypes.noReport; 
		})();

		let clienteleContactsTypeId = (() => {
			if (d.internalAttendees != null) {
				return OutReachTypes.internal;	
			} else if (clienteleContactsRequired || clienteleContactsExists) {
				return OutReachTypes.external;
			}
			return OutReachTypes.noReport; 
		})(); 

		let programArea = this.programAreas.find(pa => pa.id == d.programAreaId);

		var fv: any = {
			subTypeId: d.subTypeId,
			name: d.name,
			topic: d.topic,
			role: d.role,
			parent: d.parent.match(() => null, p => p),
			programAreaId: programArea != null && programArea.disabled ? null : d.programAreaId,
			location: d.location,
			areaOfService: d.areaOfService,
			writtenBy: d.writtenBy,
			repetitions: d.repetitions,
			instances: d.instances,
			tags: d.tags,
			ANRCollaborators: d.ANRCollaborators,
			nonANRCollaborators: d.nonANRCollaborators.slice(),
			clienteleGroup: d.clienteleGroup && d.clienteleGroup.match(() => null, cg => cg),
			targetedOutreachType: targetedOutreachTypeId, 
			internalAttendees: d.internalAttendees,
			targetedOutreach: targetedOutreach,
			clienteleContactsType: clienteleContactsTypeId,
			clienteleContacts: clienteleContacts
		};

		d.date.matchDo(
			single => {
				fv.dateTypeId = 'Single';
				fv.startDate = single.date;
				fv.endDate = single.date;
			},
			range => {
				fv.dateTypeId = 'Range';
				fv.startDate = range.startDate;
				fv.endDate = range.endDate;
			},
			ongoing => {
				fv.dateTypeId = 'Ongoing';
				fv.startDate = ongoing.startDate;
				fv.endDate = null;
			});

			this.console.log('activity change. activity -> form:', d, fv);
		return fv;
	}
}
