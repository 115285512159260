
import {filter, debounceTime} from 'rxjs/operators';
import { ViewChild, Component, Inject, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Project, ProjectService, AuthenticationService, HumanResources, AuthenticatedIdentity, Academic } from '../../domain';
import { Router } from '@angular/router';
import * as Search from './../../widgets/search';
import { FormControl } from '@angular/forms';

import { ProjectModalComponent } from '../project-modal/project-modal.component';
import { MatDialog } from '@angular/material/dialog';
import * as cs from '../../widgets/column-sort';
import { ConsoleService } from '../../widgets/console.service';
import { ProjectModalService } from '../project-modal.service';
import { ConfirmModalService } from '../../widgets/confirm-modal/confirm-modal.component';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { TooltipService } from '../../widgets/tooltip.service';

@Component({
	selector: 'app-project-list',
	templateUrl: './project-list.component.html',
	styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit {
	public StaticText: any;
	searchInput = new FormControl();
	projects: Project[] = undefined;
	searchTerm = '';
	showActivities = true;

	meritAndPromotionHR = HumanResources.ANR;
	themeToolTip: { [id:number] : string } = {};
	rolesToolTip: { [id:number] : string } = {};


	_layout: string;
	get layout(){ return this._layout; }
	set layout(value: string){
		this._layout = value;
		this.persistLayout(value);
	}
		
	constructor(
		authSvc: AuthenticationService,
		private projectModalService: ProjectModalService,
		private router: Router,
		private projectService: ProjectService,
		private readonly confirm: ConfirmModalService,
		private matDialog: MatDialog,
		private gaService: GoogleAnalyticsService,
		private console: ConsoleService,
		public tooltipSvc: TooltipService)
	{
		this.StaticText = tooltipSvc.StaticText;
		authSvc.currentIdentity.subscribe(identity => 
			identity.doWithValue((i: AuthenticatedIdentity) => 
				i.user.roles.academic.doWithValue((a: Academic) =>
				{
					this.meritAndPromotionHR = a.meritAndPromotionHr;
				})));

		this.themeToolTip[HumanResources.Campus] = this.StaticText.ProjectThemeRequiredTooltip;
		this.themeToolTip[HumanResources.ANR] = this.StaticText.ProjectThemeRequiredForDossierTooltip;

		this.rolesToolTip[HumanResources.Campus] = this.StaticText.ProjectRoleRequiredTooltip;
		this.rolesToolTip[HumanResources.ANR] = this.StaticText.ProjectRoleRequiredForDossierTooltip;

		this.searchInput
			.valueChanges.pipe(
			debounceTime(200))
			.subscribe(e => this.searchTerm = e);
		this.sortColumns = {
			name:      cs.Column.withKey('name',        'Project Name',  cs.Direction.asc, cs.SortIcon.Alpha),
			startDate: cs.Column.withKey('startDate',   'Start Date',    cs.Direction.desc, cs.SortIcon.Numeric),
			theme: cs.Column.withGetter<Project,string>(
				'theme',
				p => p.theme.match(
					/* Projects with no theme should appear last */
					() => 'ZZZZZZ', 
					t => t.name),
				'Theme',
				cs.Direction.asc,
				cs.SortIcon.Alpha),
			programArea:  cs.Column.withGetter<Project, string>(
				'programArea',
				p => p.programArea.name,
				'Program Area',
				cs.Direction.asc,
				cs.SortIcon.Alpha),
			activityCount: cs.Column.withKey('activityCount', 'Activities', cs.Direction.desc, cs.SortIcon.Amount),
			lastUpdated: cs.Column.withGetter<Project,Date|number>(
				'lastUpdated',
				/* Projects with no recent activity should appear last */
				p => !!p.lastUpdated ? p.lastUpdated : 999999,
				'Last Edited',
				cs.Direction.desc,
				cs.SortIcon.Numeric),
			lastActivity: cs.Column.withGetter<Project,Date|number>(
				'lastActivity',
				/* Projects with no recent activity should appear last */
				p => !!p.lastActivity ? p.lastActivity : 999999,
				'Last Activity',
				cs.Direction.desc,
				cs.SortIcon.Numeric)
		};
		
		this.currentSorting = this.tryGetPersistedSort() || this.sortColumns.name.getDefaultSorting();
		this.layout = this.tryGetPersistedLayout() || 'tiles';

		authSvc.currentIdentity.subscribe(identity => 
			identity.doWithValue((i: AuthenticatedIdentity) => 
				i.user.roles.academic.doWithValue((a: Academic) =>
				{
					this.showActivities = !(a.meritAndPromotionHr == HumanResources.Campus);
				})));
	}

	search = Search.ResultsFunction.fromPredicate(
			Search.Predicate.objectStringProjectors<Project>(
			[
				p => p.name,
				p => p.programArea.name,
				p => p.theme.match(
					() => '',
					t => t.name),
				p => p.tags.map(t => t.name).join('')
			]));

	private _currentSorting: cs.Sorting<Project,any>;
	get currentSorting(){
		return this._currentSorting;
	}
	set currentSorting(sorting){
		this._currentSorting = sorting;
		if(!!this.projects){
			this.projects = cs.Sort(this.projects, sorting);
		}
		this.persistSorting(sorting);
	}

	ngOnInit(): void {
		this.projectService.projects.subscribe(p => {
			this.projects = cs.Sort(p, this.currentSorting);
		});

		this.projectService.loadProjects();
	}

	directions: cs.Direction[] = 
	[
		cs.Direction.asc,
		cs.Direction.desc
	];

	Direction = cs.Direction;

	hasInstructions() {
		if (this.getInstructions().length > 0) {
			return true;
		} 
		return false;
	}

	getInstructions(): string {
		return this.tooltipSvc.tooltip("ProjectInstructions." + this.tooltipSvc.mapMeritAndPromotionsHRToString(this.meritAndPromotionHR));
	}

	onSortColumnChange(columnId: string){
		let column = this.sortColumns[columnId];
		this.currentSorting = this.currentSorting.getNext(column);
	}

	withDirection(d: cs.Direction){
		this.currentSorting = this.currentSorting.withDirection(d);
	}

	Object = Object;

	sortColumns: any; 

	gotoDetail(project: Project): void {
			this.router.navigate(['/academic/projects', project.id]);
	}

	newProject(): void {
		this.projectModalService.openNew()
		.afterClosed()
		.subscribe(result => {
			this.console.log('new project result: ', result);
			if(isNaN(result)){
				return;
			}
			this.gaService.eventEmitter("Project Created", "Project", "Create");
			this.confirm.open('Is this a multi-state project?').pipe(
			filter(isMultiStateProject => isMultiStateProject))
			.subscribe(_ => {
				this.router.navigate(['/academic/fte/project/' + result]);
			});
		});
	}
	
	editProject(project: Project): void {
		this.projectService.editProjectInModal(this.matDialog, project);
	}

	//
	private readonly sortKey = 'project-screen-sort';
	private persistSorting(s: cs.Sorting<Project,any>){
		window.localStorage.setItem(this.sortKey, s.column.id + ':' + cs.Direction[s.direction]);
	}
	private tryGetPersistedSort(): cs.Sorting<Project,any> | null {
		let str = window.localStorage.getItem(this.sortKey) || '';
		let pair = str.split(':');
		if(pair.length != 2){
			return null;
		}
		let column = this.sortColumns[pair[0]];
		let direction = cs.Direction[pair[1]];
		if(!column || direction == undefined){
			return null;
		}
		return new cs.Sorting(column, direction);
	}

	//
	private layoutKey = 'project-screen-layout';
	private tryGetPersistedLayout(): string | null {
		 return window.localStorage.getItem(this.layoutKey);
	}
	private persistLayout(l: string){
		window.localStorage.setItem(this.layoutKey, l);
	}
}
