import { ActivityDate, Tag } from "..";
import { Academic } from './academic';
export class Activity
{
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly type: string,
        public readonly subType: string,
        public readonly date: ActivityDate,
        public readonly owner: Academic,
        public readonly tags: Tag[],
        public readonly humanReadableDate: string,
        public readonly  role: string,
        public readonly  topic: string,
        public readonly  location: string,
        public readonly  activityDateType: string,
        public readonly  activitylastUpdatedDate: Date
    ){}

    public static fromJson(o: any): Activity {
        return new Activity(
            o.id,
            o.name,
            o.type,
            o.subType,
            ActivityDate.fromJson(o.date),
            Academic.fromJson(o.owner),
            (<any[]>o.tags).map(Tag.fromJson),
            o.humanReadableDate,
            o.role,
            o.topic,
            o.location,
            o.activityDateType,
            o.activitylastUpdatedDate);
    }
}