//This implementation was copied from https://stackoverflow.com/a/42745350/943730 
import { Directive, ElementRef, Input, OnInit, AfterViewInit, AfterViewChecked } from '@angular/core';

@Directive({
	selector: '[autofocus]'
})
export class AutofocusDirective implements AfterViewInit//,AfterViewChecked//, OnInit, 
{
	private _autofocus;
	constructor(private el: ElementRef) { }

	//ngOnInit()           { this.tryFocus(); }
	ngAfterViewInit()    { this.tryFocus('afterViewInit'); }
    //ngAfterViewChecked() { this.tryFocus(); }

	private tryFocus(eventName){
		if (this._autofocus || typeof this._autofocus === "undefined"){
			this.el.nativeElement.focus();      
			//For SSR (server side rendering) this is not safe.
			//Use: https://github.com/angular/angular/issues/15008#issuecomment-285141070
			//console.log(eventName + ': focus!');
		} else {
			//console.log(eventName + ': no focus');
		}
	}

	@Input() set autofocus(condition: boolean) {
		this._autofocus = condition != false;
		//console.log('set autofocus:', condition);
	}
}
