import { Activity } from './activity';
import { ActivityType, ActivitySubType, ActivityTypeId, ActivitySubTypeId } from './activity-type';
import { HumanResources } from 'app/domain';

export class ActivityTypeCollection {
        private readonly typeIdBySubTypeId = new Map<ActivitySubTypeId, ActivityTypeId>();;
        private readonly typeNameById = new Map<ActivityTypeId, string>();
        private readonly typeById = new Map<ActivityTypeId, ActivityType>();
        private readonly subTypeById = new Map<ActivitySubTypeId, ActivitySubType>();
        private readonly subTypesForCampusHr: ActivitySubType[] = [];
        private readonly subTypesTotal: number;
        constructor(public readonly all: ActivityType[]) {
                let total = 0;
                if(all != null) {
                        all.forEach(t => {
                                this.typeNameById.set(t.id, t.name);
                                this.typeById.set(t.id, t);
                                t.subTypes.forEach(st => {
                                        this.subTypeById.set(st.id, st);
                                        this.typeIdBySubTypeId.set(st.id, t.id);
                                        total++;
                                });
                        });
                }
                this.subTypesTotal = total;
        }

        getSubTypesForCampusHr(): ActivitySubType[] {
                return Array.prototype.concat.apply([], this.all.map(t => t.subTypes))
                .filter(st => st.appliesToHR.indexOf(HumanResources.Campus) > -1);
        }

        getTypeIdBySubTypeId(subTypeId: ActivitySubTypeId): ActivityTypeId{
                return this.typeIdBySubTypeId.get(subTypeId);
        }

        getTypeById(typeId: ActivityTypeId): ActivityType {
                return this.typeById.get(typeId);
        }

        typeName(a: Activity){
                return this.typeNameById.get(this.typeIdBySubTypeId.get(a.subTypeId));
        }

        getSubTypeById(subTypeId: ActivitySubTypeId): ActivitySubType {
                return this.subTypeById.get(subTypeId);
        }

        filter(predicate: (at: ActivityType) => boolean): ActivityTypeCollection {
                return new ActivityTypeCollection(this.all.filter(predicate));
        }

        getSubTypeCount(): number {
                return this.subTypesTotal;
        }
}
