import { ProgramArea } from '../../domain';

export const PROGRAM_AREAS: ProgramArea[] = [
	{
		"id": 1,
		"name": "Agricultural Production Management Systems"
	},
	{
		"id": 2,
		"name": "Agronomic Crops"
	},
	{
		"id": 3,
		"name": "Air Quality"
	},
	{
		"id": 4,
		"name": "California Communities"
	},
	{
		"id": 5,
		"name": "Climate Change"
	},
	{
		"id": 6,
		"name": "Consumer Food Safety"
	},
	{
		"id": 7,
		"name": "Dairy Production & Food Safety"
	},
	{
		"id": 8,
		"name": "Egg Production & Food Safety"
	},
	{
		"id": 9,
		"name": "Environmental Horticulture"
	},
	{
		"id": 10,
		"name": "Forest & Rangeland Systems"
	},
	{
		"id": 11,
		"name": "Healthy Lifestyles/Healthy Behaviors"
	},
	{
		"id": 12,
		"name": "Meat Production & Food Safety"
	},
	{
		"id": 13,
		"name": "Pest Management"
	},
	{
		"id": 14,
		"name": "Pomology"
	},
	{
		"id": 15,
		"name": "Research-to-Policy"
	},
	{
		"id": 16,
		"name": "Vegetable Crops"
	},
	{
		"id": 17,
		"name": "Viticulture"
	},
	{
		"id": 18,
		"name": "Water Resources"
	},
	{
		"id": 19,
		"name": "Wildlife & Fish"
	},
	{
		"id": 20,
		"name": "Youth Development"
	},
	{
		"id": 21,
		"name": "Youth Science Literacy in Nutrition, Agriculture & Natural Resources"
	},
	{
		"id": 22,
		"name": "Not Listed Above"
	},
	// Administration is supposed to be at the bottom...
	{
		"id": 23,
		"name": "Administration"
	}
].map(ProgramArea.fromJson);
