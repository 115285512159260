import { Injectable } from '@angular/core';

@Injectable()
export class ColorService {
	/* Hiding the colors array behind this getter (and slicing)
	 * prevents other components from changing its contents globally.  */
	public getAll(): string[] {
		return this.colors.slice(0);
	}
	private colors: string[] = [
        'Purple',
        'Red',
        'Green',
        'Blue',
        'Gold',
        'Teal',
        'Orange',
        'DarkRed',
        'Lavendar',
        'Mint',
        'Brown' 
	];
}
