import { HasHashString } from './sorted-set';
import { FTE } from './fte';
import { Option } from './option';

export class User implements HasHashString {
	public readonly hashString: string;
	constructor(
		public readonly id: number,
		public readonly firstName: string,
		public readonly lastName: string)
	{
		this.hashString = id.toString();
	}

	public static fromJson(o: any): User {
		return new User(o.id, o.firstName, o.lastName);
	} 
}

export class UserWithEmplId extends User {

	public readonly emplId: string;

	constructor(
        id: number,
        firstName: string,
        lastName: string,
        emplId: string
	){
        super(id, firstName, lastName);
        this.emplId = emplId;
    }

	public static fromJson(o: any): UserWithEmplId  {
		return new UserWithEmplId(o.id, o.firstName, o.lastName, o.ucpathemployeeid);
	} 
}

export class CurrentUser implements HasHashString {
	public readonly hashString: string;
	constructor(
		public readonly id: number,
		public readonly firstName: string,
		public readonly lastName: string,
        public readonly roles: UserRoles,
		public readonly azureADToken: string)
	{
		this.hashString = id.toString();
	}

	public static fromJson(o: any): CurrentUser {
		return new CurrentUser(o.id, o.firstName, o.lastName, UserRoles.fromJson(o.roles), o.azureADToken);
	} 

	public tryGetCurrentAcademicUser(): Option<CurrentAcademicUser> {
		return this.roles.academic.map(a => 
			new CurrentAcademicUser(
				this.id,
				this.firstName,
				this.lastName, a));
	}
}

export class UserRoles {
	constructor(
		public readonly academicHumanResources: boolean,
		public readonly academic: Option<Academic>,
		public readonly civilRightsCompliance: boolean,
		public readonly programPlanningAndEvaluation: boolean,
		public readonly sysAdmin: boolean
		//TODO: public readonly directorships: Directorship[],
		//TODO: public readonly seniorLeader: boolean,
	){}

	public static fromJson(o: any): UserRoles {
		return new UserRoles(
			<boolean>o.academicHumanResources,
			Option.fromJson(o.academic, Academic.fromJson),
			<boolean>o.civilRightsCompliance,
			<boolean>o.programPlanningAndEvaluation,
			<boolean>o.sysAdmin
		)
	}
}

export class CurrentAcademicUser {
	constructor(
		public readonly id: number,
		public readonly firstName: string,
		public readonly lastName: string,
		public readonly academic: Academic
	){}
}

export class Academic {
	constructor(
		public readonly title: AcademicTitle,
		public readonly payrollCampus: PayrollCampus,
		public readonly totalFTE: FTE,
		public readonly meritAndPromotionHr: HumanResources,
		public readonly appointments: AcademicAppointment[]
	){}
	public static fromJson(o: any): Academic {
		var hr = o.meritAndPromotionHR;
		return new Academic(
			AcademicTitle.fromJson(o.title),
			PayrollCampus.fromJson(o.payrollCampus),
			FTE.fromJson(o.totalFTE),
			<HumanResources>(hr),
			o.appointments.map(AcademicAppointment.fromJson));
	}
}

export enum AcademicTitleId {
	Advisor = 10, 
	CESpecialist = 20, 
	ProjectScientist = 30,
	ResearchSpecialist = 40,
	ProfessionalResearcher = 50,
	AcademicCoordinator = 60,
	AcademicAdministrator = 70,
	ShortTermAppointment = 80,
	StaffDirector = 90
}

export class AcademicTitle {
	constructor(
		public readonly id: number,
		public readonly name: string
	){}
	public static fromJson(o: any): AcademicTitle {
		return new AcademicTitle(o.id, o.name);
	}
}

export class PayrollCampus {
	constructor(
		public readonly id: number,
		public readonly abbreviation: string,
		public readonly name: string
	){}
	public static fromJson(o: any): PayrollCampus {
		return new PayrollCampus(o.id, o.abbreviation, o.name);
	}
}

export enum HumanResources {
	Campus = 0,
	ANR = 1
}

export class UnitType {
	constructor(
		public readonly id: number,
		public readonly name: string
	){}
	public static fromJson(o: any): UnitType {
		return new UnitType(o.id, o.name);
	}
}

export class Unit {
	constructor(
		public readonly id: number,
		public readonly type: UnitType,
		public readonly name: string
	){}
	public static fromJson(o: any): Unit {
		return new Unit(o.id, UnitType.fromJson(o.type), o.name);
	}
}

export class AcademicAppointment {
	constructor(
		public readonly unit: Unit,
		public readonly isHeadquarters: boolean,
		public readonly fte: FTE
	){}
	public static fromJson(o: any): AcademicAppointment {
		return new AcademicAppointment(
			Unit.fromJson(o.unit), 
			o.isHeadquarters,
			FTE.fromJson(o.fte));
	}
}
