import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AHRService, CaseFiles, CaseId, ReviewFile } from '../../../domain';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { ConsoleService } from 'app/widgets/console.service';

@Component({
  selector: 'case-files',
  templateUrl: './case-files.component.html',
  styleUrls: ['./case-files.component.scss']
})
export class CaseFilesComponent implements OnInit {

  @Input() files: CaseFiles;
  @Input() caseId: CaseId;
  @Input() candidateName: string;
  @Input() candidateFiles: boolean = false;

  @Input() candidateFilesVisible: boolean = false;
  @Input() reviewerFilesVisible : boolean = false;
  
  readonly selectAll : string = "Select All";
  readonly unselectAll : string = "Unselect All";

  selectedFileCount : number = 0;
  selectOption : string = this.selectAll;
  displayCheckboxes : boolean = false;

  constructor(
    private svc: AHRService,
    private sb: MatSnackBar,
    private console: ConsoleService
  ) {}

  ngOnInit() {}

  ngOnChanges() {    
    
    this.displayCheckboxes = false;
    
    if (this.files && this.files.candidateFiles && this.files.reviewerFiles) {
      if (this.files.candidateFiles.length == 0 && this.files.reviewerFiles.length > 1) this.displayCheckboxes = true;
      if (this.files.candidateFiles.length > 1 && this.files.reviewerFiles.length == 0) this.displayCheckboxes = true; 
      if (this.files.candidateFiles.length && this.files.reviewerFiles.length) this.displayCheckboxes = true;
    }

    if (this.files && this.files.candidateFiles) {
      if (this.files.candidateFiles.length > 1 && this.candidateFiles) this.displayCheckboxes = true;
    }
  }

  handleFileClick($event,f: ReviewFile){
    $event.preventDefault();
		//this.svc.downloadDossierFile(this.case.yearId, file.id);
		this.sb.open('Downloading file...',null, {duration: 2000});
    if(this.candidateFiles){
      this.download(f);
    }
    else if(this.caseId.periodOfReviewEndingInYear > 2021 ) {
    this.svc.caseFileObjectUrl(this.caseId, f.id).subscribe(url => {
			//this.openUrlInNewTab(url);
		});
   }
   else {
    this.svc.downloadFileFromAzure(this.caseId, f.name);
   }
  }
	download(file: ReviewFile){
		let fileName = this.caseId.periodOfReviewEndingInYear > 2022? file.id : file.name;
		this.svc.dossierFileObjectUrl(this.caseId.periodOfReviewEndingInYear, fileName, this.caseId.academicId).subscribe(url => {
		});
	}
	openUrlInNewTab(url: any){
		let win = window.open('/assets/loading.html');
		win.onload = () => {
			win.location.href = url;
		};
	}

  downloadSelectedFiles(){
    let p = this.selectedFileCount > 1 ? 's':'';
    this.sb.open('Downloading ' + this.selectedFileCount + ' file' + p + ' as a zip...', null, {duration: 2000});
    var zip = new JSZip();
    var loadFiles = (files:ReviewFile[], foldername:string) => {         

      let filenames=[]
      let fileName;
      if(files.length){
         //iterate through each checked box
      const promiseArray = files.filter(item => item.isChecked).map((i, index) => {  
          
        return new Promise(resolve => {
         if(this.candidateFiles){
          fileName = this.caseId.periodOfReviewEndingInYear > 2022 ?i.id: i.name;  
          this.svc.dossierFileObject(this.caseId.periodOfReviewEndingInYear, this.caseId.academicId,fileName).subscribe(response => {
            var filename = response.headers.get('Content-Disposition').split("=")[1];
            filename = this.caseId.periodOfReviewEndingInYear > 2022 ? filename:i.name;
            filename = filename.replace(/(^"|"$)/g, ''); //remove leading and trailing double quotes
            filenames.push(filename);
            
            //Rename duplicate files
            let duplicateCount = filenames.filter(val => val == filename).length;                
            if (duplicateCount > 1) {
              let fileExt = filename.substring(filename.lastIndexOf("."));
              filename = filename.substring(0, filename.lastIndexOf("."));
              filename = filename+"("+duplicateCount+")"+fileExt;
              filenames.push(filename);
            }
            this.console.log(filename);
            const blob = new Blob([response.body], {type: response.body.type}); //create the file
            zip.folder(foldername).file(filename, blob, {binary:true}); //add the file to folder
            resolve(response);
          });
         }
         else{
          fileName = this.caseId.periodOfReviewEndingInYear > 2021 ?i.id: i.name;  
          this.svc.caseFileObject(this.caseId, fileName).subscribe(response => {
            var filename = response.headers.get('Content-Disposition').split("=")[1];
            filename = this.caseId.periodOfReviewEndingInYear > 2021 ? filename:i.name;
            filename = filename.replace(/(^"|"$)/g, ''); //remove leading and trailing double quotes
            filenames.push(filename);
            
            //Rename duplicate files
            let duplicateCount = filenames.filter(val => val == filename).length;                
            if (duplicateCount > 1) {
              let fileExt = filename.substring(filename.lastIndexOf("."));
              filename = filename.substring(0, filename.lastIndexOf("."));
              filename = filename+"("+duplicateCount+")"+fileExt;
              filenames.push(filename);
            }
            this.console.log(filename);
            const blob = new Blob([response.body], {type: response.body.type}); //create the file
            zip.folder(foldername).file(filename, blob, {binary:true}); //add the file to folder
            resolve(response);
          });
         }
            
          });
      });

      return Promise.all(promiseArray);
      }
     
    }
    let zipFilename;
    const candidatePromise = loadFiles(this.files.candidateFiles, "Candidate Files"); 
    const reviewerPromise = loadFiles(this.files.reviewerFiles, "Reviewer Files"); 
    if(this.candidateFiles){// academic program review year Id
      zipFilename = this.candidateName.replace(/ /g, "_") + '_' + (this.caseId.periodOfReviewEndingInYear-1) + '-' + (this.caseId.periodOfReviewEndingInYear);
    }
    else{// reviewer dash board year Id
      zipFilename = this.candidateName.replace(/ /g, "_") + '_' + this.caseId.periodOfReviewEndingInYear + '-' + (this.caseId.periodOfReviewEndingInYear+1);
    }

    //Wait for promises and then create the zip file
    Promise.all([candidatePromise, reviewerPromise]).then(data => {
      //this.console.log(data[0], data[1]);
      zip.generateAsync({type:"blob", compression:"DEFLATE"})
      .then(content => saveAs(content, zipFilename + '.zip'))
      .catch(err => console.log(err));
    }).catch((err) => {
      console.error(err);
    });
  }
  selectAllFiles(){
    if(this.candidateFiles){
      if (this.files.candidateFiles.every(item => item.isChecked == true)){
        this.files.candidateFiles.forEach(item => { item.isChecked = false });
      }
      else{
        this.files.candidateFiles.forEach(item => { item.isChecked = true});
      }
    }
    else {
      if (this.files.candidateFiles.every(item => item.isChecked == true) &&
          this.files.reviewerFiles.every(item => item.isChecked == true)) 
      {
        this.files.candidateFiles.forEach(item => { item.isChecked = false });
        this.files.reviewerFiles.forEach(item => {item.isChecked = false});
      }
      else 
      {
        this.files.candidateFiles.forEach(item => { item.isChecked = true});
        this.files.reviewerFiles.forEach(item => {item.isChecked = true});
      } 
    }
     

    this.countChecked();
  }

  checkedChanged(){
    this.countChecked();
  }

  countChecked(){
    this.selectedFileCount = 0;
    this.files.candidateFiles.forEach(item => {if (item.isChecked) this.selectedFileCount = ++this.selectedFileCount;});
    if(!this.candidateFiles)this.files.reviewerFiles.forEach(item => {if (item.isChecked) this.selectedFileCount = ++this.selectedFileCount;});

    if ((this.files.candidateFiles.every(item => item.isChecked == true) && this.files.reviewerFiles.length &&
        this.files.reviewerFiles.every(item => item.isChecked == true)) ||(this.candidateFiles && this.files.candidateFiles.every(item => item.isChecked == true ))) 
    {
      this.selectOption = this.unselectAll;
    } 
    else 
    {
      this.selectOption = this.selectAll;
    }
  }
}

